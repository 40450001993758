import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Link,Router} from 'react-router-dom'
// import createHistory from 'history/createBrowserHistory'
import Paper from '@material-ui/core/Paper';
import ReactPaginate from 'react-paginate';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import * as actionCreators from '../../store/actions/index'
import swal from 'sweetalert'
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';
import TablePagination from '@material-ui/core/TablePagination'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import Grid from '@material-ui/core/Grid';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import Card from '../Dashboard/Card/Card.js'
import CardHeader from "../Dashboard/Card/CardHeader.js";
import CardIcon from "../Dashboard/Card/CardIcon.js";
import CardBody from '../Dashboard/Card/CardBody.js'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import moment from 'moment'
import Select from '@material-ui/core/Select';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import operatorBulkUploadFile from '../../OperatorBulkUpload.xlsx'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { TruckListing } from '../TruckManagement/TruckListing';


const options = [
    'Truck' , 'Edit'
  ];
export class OperatorListing extends Component {
    state = {
        searchVal : '',
        limit : 10,
        page : 1,
        rowsPerPage : 1,
        // sortObj : {
        //     key : '',
        //     value : ''
        // },
        // page:1,
        // limit:10,
        actionMenuOpen : false,
        addActionData : {},
        anchorEl:null,
        open:false,
        id:'',
        operatorName:'',
        anchorEl_bulk:null,
        placement:'',
        currentSortId:'',
        sortObj : {
            key: '',
            value: ''
        },
        controls: {
            search: {
                value: '',
            }
        },
        searchBy:'selectName',
    }
    componentDidMount()
    {
        //  window.location.reload(false);
        const payload = {
            token : this.props.token,
            page:this.state.page,
            limit:this.state.limit,
            sortName:this.state.sortObj.key,
            sortValue :this.state.sortObj.value,
            searchQuery: this.state.controls.search.value,
            searchByFields : this.state.searchBy,
        }
        this.props.onFetchOperatorHeaders(payload)
        this.props.onFetchOperatorData(payload)

    }
    // inputChangedHadler = (event) =>
    // {
    //     this.setState({searchVal : event.target.value})
    // }
    // onClickSearch = (event) => 
    // {
    //     event.preventDefault()
    //     const searchVal = this.state.searchVal
    //     if(searchVal.length >= 3 || searchVal.length == 0){
    //         const payload = {
    //             token : this.props.token,
    //             page : 1,
    //             limit : this.state.limit,
    //             sortName:this.state.sortObj.key,
    //             sortValue :this.state.sortObj.value,

    //         }
    //     }else{
    //         swal('String must be atleast 3 characters')
    //     }
    // }
    handleChangePage = (newPage) => {
        this.setState({page : newPage})
    }
    handleChangeRowsPerPage = (event) => {
    }
    handleActionMenuClicked = (e,dataAction) => {
        this.props.operatorData.map(data => {
            if(data.operatorName == dataAction.operatorName)
            {
                const actionMenu = !data.actionMenu
                return {...data, ...actionMenu}
            }
            else
            {
                
            }
        })
        this.setState((prevState)=>{
            return ({actionMenuOpen : !prevState.actionMenuOpen, addActionData:dataAction })
        })
    }
    handleClickAway = () => {
        this.setState({actionMenuOpen:false})
    }
    // handleSort = (event, sortBy) => {
    //     let sortObj;
    //     if (this.state.sortObj.key === sortBy.accessor) {
    //         let value = (this.state.sortObj.value === '-desc') ? '-asc' : '-desc';
    //         sortObj = {
    //             key : sortBy.accessor,
    //             value: value
    //         }
    //     } 
    //     else {
    //         sortObj = {
    //             key : sortBy.accessor,
    //             value: '-desc'
    //         }    
    //     }
    //     this.setState({ sortObj: sortObj ,currentSortId:sortBy.accessor});
    //     let payload = {
    //         token: this.props.token,
    //         page:  1,
    //         limit: this.state.limit,
    //         sort: `${sortObj.key}${sortObj.value}`,
    //         filter: JSON.stringify([this.state.filter]),
    //         query: this.state.query
    //     }
    //     this.props.onFetchOperatorData(payload);

    // }
    handleClickMoreVertIcon=(event,id, name)=>{
      this.setState({open:!this.state.open,anchorEl:event.currentTarget,id:id, operatorName:name})
    }
    handleClickAway = () => {
        this.setState({open:false});
    };

    onFileUpload = () => { 
    const payload = {
        token : this.props.token
    }
    const formData = new FormData(); 
    formData.append( 
        "operatorBulkUploadFile", 
        this.state.selectedFile, 
        this.state.selectedFile.name 
    )
    this.props.onFetchOperatorBulkImport(payload,formData);
    this.setState({open_bulk:false})
    }
    onFileChange = (event) => {
        this.setState({ selectedFile: event.target.files[0]},()=>{
            this.onFileUpload()
        });   
    }
    handleClose = (event) => {
    this.setState({open_bulk:false})
    };
    handleClick = (newPlacement) => (event) => {
    this.setState({ anchorEl_bulk:event.currentTarget, newPlacement:newPlacement,open_bulk:!this.state.open_bulk});
    };
    handleClickAwayBulk = () => {
        this.setState({
          open_bulk: false 
        });
      };
    handleDelete = (id) => {
        const payload = {
            id : id,
            sortName:this.state.sortObj.key,
            sortValue :this.state.sortObj.value,
            page:1,
            limit:10,
            token: this.props.token,
            searchByFields : this.state.searchBy,
        }
    const  data= {
        id : id,
    }
        this.props.onDeleteOperator(payload,data)
    }


    onClickExport=()=>{
        let payload = {
            page:1,
            limit:10,
            token: this.props.token,
            sortName:this.state.sortObj.key,
            sortValue :this.state.sortObj.value,
            searchByFields : this.state.searchBy,
        }
        this.props.exportData(payload);
       
    }

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        let payload = {
            token: this.props.token,
            page:  selectedPage + 1,
            limit: this.state.limit,
            sortName:this.state.sortObj.key,
            sortValue :this.state.sortObj.value,
            searchQuery: this.state.controls.search.value,
            searchByFields : this.state.searchBy,
        }
        this.props.onFetchOperatorData(payload)
    };


    handleSortheader = (e,sortBy) => {
        let sortObj
        if (this.state.sortObj.key === sortBy.accessor) {
            let value = (this.state.sortObj.value === 'desc') ? 'asc' : 'desc';
            sortObj = {
                key : sortBy.accessor,
                value: value
            }
        } 
        else {
            sortObj = {
                key : sortBy.accessor,
                value: 'desc'
         }    
         }
 
        this.setState({ sortObj: sortObj ,currentSortId:sortBy.accessor});
        let payload = {
            token: this.props.token,
            sortName:sortObj.key,
         sortValue : sortObj.value,
         page: this.state.page,
         limit: this.state.limit,
         searchQuery: this.state.controls.search.value,
         searchByFields : this.state.searchBy,
        }

        this.props.onFetchOperatorData(payload);
  
      }

      inputChangedHadler = (event, controlName) => {
        const updatedControls = {
            ...this.state.controls,
            [controlName]: {
                ...this.state.controls[controlName],
                value: event.target.value,
            }
        }
        this.setState({ controls: updatedControls });
        if(this.state.searchBy == "OperatorName"){
            if( updatedControls.search.value === ''){
                let payload = {
                    token: this.props.token,
                    searchQuery:'',
                    limit:this.state.limit,
                    page:this.state.page,
                    sortName:this.state.sortObj.key,
                    sortValue :this.state.sortObj.value,
                    searchByFields : this.state.searchBy,
                }
                this.props.onSearchOperterName(payload) 
            }
        }
        if(this.state.searchBy == "selectName"){
            if( updatedControls.search.value === ''){
                let payload = {
                    token: this.props.token,
                    searchQuery:'',
                    limit:this.state.limit,
                    page:this.state.page,
                    sortName:this.state.sortObj.key,
                    sortValue :this.state.sortObj.value,
                    searchByFields : this.state.searchBy,
                }
                this.props.onSearchOperterName(payload) 
            }
        }

        if(this.state.searchBy == 'vehicleNo'){
            if( updatedControls.search.value === ''){
                let payload = {
                    token: this.props.token,
                    searchQuery:'',
                    limit:this.state.limit,
                    page:this.state.page,
                    sortName:this.state.sortObj.key,
                    sortValue :this.state.sortObj.value,
                    searchByFields : this.state.searchBy,
                }
                this.props.onSearchOperterTruckNumber(payload, this.props);
                
            }
        }
      
    }

    handlePropertySearch = (e) => {
        e.preventDefault()
        let searchVal = this.state.controls.search.value
        if(this.state.searchBy =="selectName" || this.state.searchBy == "OperatorName"){
            if (!!searchVal && searchVal.length >= 2) {
                let payload = {
                    token: this.props.token,
                    searchQuery: this.state.controls.search.value,
                    limit:10,
                    page:this.state.page,
                    sortName:this.state.sortObj.key,
                    sortValue :this.state.sortObj.value,
                    searchByFields : this.state.searchBy,
                  
                }
                this.props.onSearchOperterName(payload) 
            } 
            else {
         
                swal("Enter atleast 2 characters")
            }
    
        }
      
        if(this.state.searchBy =="vehicleNo"){
            if(!!searchVal && searchVal.length >= 2){
                let payload = {
                    token : this.props.token,
                    page:this.state.page,
                    limit:this.state.limit,
                    sortName:this.state.sortObj.key,
                    sortValue :this.state.sortObj.value,
                    searchQuery: this.state.controls.search.value,
                    }
                    this.props.onSearchOperterTruckNumber(payload, this.props);
                    // this.props.history.push(`/truck-management/${this.state.id}`)
            }
            else {
         
                swal("Enter atleast 2 characters")
            }
           
        }
     
    }


    handleChangeSearch=(e)=>{
        // if(e.target.value == 'selectName' ){
        //     let payload = {
        //     token : this.props.token,
        //     page:this.state.page,
        //     limit:this.state.limit,
        //     sortName:this.state.sortObj.key,
        //     sortValue :this.state.sortObj.value,
        //     searchQuery: "",
        //     }
        //     this.props.onSearchOperterName(payload);
        // }
        // if(e.target.value == 'OperatorName'){
        //     let payload = {
        //         token : this.props.token,
        //         page:this.state.page,
        //         limit:this.state.limit,
        //         sortName:this.state.sortObj.key,
        //         sortValue :this.state.sortObj.value,
        //         searchQuery: this.state.controls.search.value,
        //         }
        //         this.props.onSearchOperterName(payload);
        // }
        // if(e.target.value == 'vehicleNo'){
            // let payload = {
            //     token : this.props.token,
            //     page:this.state.page,
            //     limit:this.state.limit,
            //     sortName:this.state.sortObj.key,
            //     sortValue :this.state.sortObj.value,
            //     searchQuery: this.state.controls.search.value,
            //     }
            //     this.props.onSearchOperterTruckNumber(payload);
        // }


        this.setState({searchBy:e.target.value, searchByFields:e.target.name,  controls: { search: { value: '',}}, })
    }
    render() {
        
        const checkActionActive = (row) => {
            this.props.operatorData.map(data => {
                
            })
        }

      
        const tableHeaders = this.props.operatorHeaders.map(header => {
                if(header.accessor == "actionButton"){
                    header.header = 'Action'
                }
                // if(header.accessor ==="vehicleOwnerName"){
                //     header.header = ""
                //     header.accessor = ""
                // }
                return <TableCell className={"white-space"}>
                          
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'start',
                                    whiteSpace: 'break-spaces',
                                    height:'3em'
                                }}>
                    { header.header === "Action" ||  header.header === ""? "":
                    <div onClick={(e) =>  this.handleSortheader(e,header)}>
                        <div>{
                            this.state.currentSortId===header.accessor? this.state.sortObj.value ==='asc'?(<ArrowDropDownIcon fontSize="small"/>):(<ArrowDropUpIcon fontSize="small"/>) :(<ArrowDropDownIcon fontSize="small"/>)
                        }
                        </div>  
                    </div>}
                                 {header.header}
                                </div>
                           
                    </TableCell>
            })
          
        const tableData = this.props.operatorData.map(data =>
 
            {
                const id = data.operatorId
                let name = data.name
                return <TableRow>
                    {this.props.operatorHeaders.map(header =>
                        {
                            if(header.accessor == "actionButton"){
                                return (
                                <TableCell>  
                                <IconButton
                                    aria-label="more"
                                    aria-controls="long-menu"
                                    aria-haspopup="true"
                                    onClick={(e)=>this.handleClickMoreVertIcon(e,id, name)}
                                    >
                                    <MoreVertIcon />
                                    </IconButton>
                                     <Popper
                                     open={this.state.open} anchorEl={this.state.anchorEl}>
                                           <ClickAwayListener onClickAway={this.handleClickAway}>
                                        <Paper  style={{boxShadow:"none", border:"1px solid #3dab35"}}>
                                            <MenuList>
                                                <Link to={{ pathname:`/truck-management/${this.state.id}`, state:{ operatorName:this.state.operatorName, searchbyTruck:this.state.searchBy}}}  className='email_link'> <MenuItem >Truck</MenuItem></Link>
                                                <Link to={{ pathname:`/records/${this.state.id}`, state:{ operatorIds:this.state.id, operatorName:this.state.operatorName}}}  className='email_link'> <MenuItem >Loads</MenuItem></Link>
                                                <Link to={`/operator-data/${this.state.id}`} className='email_link'> <MenuItem>Edit</MenuItem></Link>
                                                {localStorage.getItem('phase2') == 'true' && <div>
                                                <MenuItem style = {{color : '#3dab35'}} onClick = { () => {
                                                    swal({ title   : "Are you sure?",
                                                           text    : "Once deleted, you will not be able to recover this data!",
                                                           icon    : "warning",
                                                           buttons : {
                                                           cancel  : 'No',
                                                           confirm : 'Yes'}
                                                    })
                                                    .then((willDelete) => {
                                                        if (willDelete) {
                                                            this.handleDelete(this.state.id);
                                                        } 
                                                    });
                                                }}>Delete</MenuItem></div>}
                                            </MenuList>
                                        </Paper>
                                        </ClickAwayListener>
                                    </Popper>
                                </TableCell>
                                )
                            }
                            else if(header.accessor=='updatedDate'){
                                   return <TableCell  style={{
                                    whiteSpace: "normal",
                                    wordWrap: "break-word"
                                  }}>{ moment(data[header.accessor]).format('DD-MMM-YYYY hh:mm a')}</TableCell>
                               }
                            else{
                                return <TableCell  style={{
                                    whiteSpace: "normal",
                                    // textAlign:'center'
                                    wordWrap: "break-word"
                                  }}>{data[header.accessor]}</TableCell>
                            }
                        })}
                       
                </TableRow>
            })
        return (
            
            <div >
                 {/* {(!this.props.loadingOperatorData)&& <div id="cover-spin"></div>} */}
                <Paper elevation={0}>
                <div className="dashboard_right_content" style={{marginTop:'-4%'}}>
                        <img className="dashboard_right_content_top_icon" src="images/Rectangle.svg" />
                        <div className="dashboard_right_content_top">
                        <h5>Operator Listing</h5>
                            <div className="dashboard_right_content_top_options"> 
                          
                              
                            
                            <Button className="email_link"
                                    ref={this.state.anchorRef}
                                    aria-controls={this.state.open ? 'menu-list-grow' : undefined}
                                    aria-haspopup="true"
                                    onClick={this.handleClick('bottom')}
                                    style={{height:'35px',visibility:'hidden'}}
                            >
                                Bulk Import
                                {
                                    this.state.open ?   <ArrowDropUpIcon /> : <ArrowDropDownIcon/> 
                                }
                                
                            </Button>
                            <Popper open={this.state.open_bulk} anchorEl={this.state.anchorEl_bulk} placement={this.state.placement} transition>
                            {({ TransitionProps }) => (
                                    <ClickAwayListener onClickAway={this.handleClickAwayBulk}>
                                <Paper>
                                    <MenuList>
                                    <MenuItem onChange={this.onFileChange}>
                                        <label style={{ color:'#3dab35',fontSize:'small',align:'left', fontFamily: "'Open Sans', sans-serif"}}> 
                                            <input type="file" style={{display:'none',align:'left'}} /> 
                                          Bulk Upload
                                        </label>
                                    </MenuItem>
                                    <a href={operatorBulkUploadFile} style={{textDecoration:'none', 
                                                                            color:'#3dab35', 
                                                                            fontSize:'small', 
                                                                            fontFamily: "'Open Sans', sans-serif"}} download> 
                                        <MenuItem onClick={this.handleClose}>Download Sample</MenuItem>
                                    </a>
                                    </MenuList>
                                </Paper>
                                </ClickAwayListener>
                            )}
                            </Popper> 
                            <div className="dashboard_right_content_top_search">
                                <div style={{display:'flex'}}>
                                <div>
                                <FormControl>
                            <Select value={this.state.searchBy}
                                onChange={this.handleChangeSearch}
                                name="searchBy"
                                style={{ width: '100%' , border:"1px solid #ccc", borderRadius:'5px'}}>
                                 <MenuItem value={"selectName"}>select...</MenuItem>
                                    <MenuItem value={"OperatorName"}>By Operator Name</MenuItem>
                                    <MenuItem value={"vehicleNo"}>By Truck No.</MenuItem>
                                  
                                
                            </Select>
                             </FormControl>
                                </div>
                                <Paper component="form">
                                    <IconButton aria-label="menu">
                                        <img src="images/search_icon.svg" />
                                    </IconButton>
                                    <InputBase onChange={(event) => this.inputChangedHadler(event, 'search')} disabled={this.state.searchBy== "selectName"? true:false} placeholder={this.state.searchBy == "selectName"? "": this.state.searchBy=="OperatorName"? "Operator Name": this.state.searchBy=="vehicleNo"? "Truck No": ""} />
                                    <Button onClick={this.handlePropertySearch} disabled={this.state.searchBy== "selectName"? true:false} variant="contained" color="primary" type="submit">Search</Button>
                                </Paper>
                                </div>
                            
                            </div>
                            <Button  onClick={this.onClickExport}>Export</Button>
                            <Link  style={{textDecoration:"none", color:"#3dab35"}} to='/operator-management/add/new'><Button className="login_submit" variant="outlined" color="primary">Add New Operator</Button></Link> 
                            </div>
                        </div>
                    </div>
                   
                    {!!this.props.statisticsData&&this.props.statisticsData.map(data=>
                       (      
                    <Grid container spacing={1}>
                        <Grid item xs={3}>
                            <Card style={{"marginTop": "-10%", padding:"2%"}}>
                            <hr style={{border: "1px solid #3DAB35", marginLeft:"5%", width:"30px"}}></hr>  
                            <CardHeader color="warning" stats icon>
                                <CardIcon color="warning" style={{padding: 0}}>
                                <BusinessOutlinedIcon />
                                </CardIcon> 
                                <CardBody>
                                    <h2    style ={{color: "#3dab35",fontSize: "28px",minHeight: "5px",fontWeight: "1000",marginBottom: "10px"}} align="left">{data.operatorCount}</h2>
                                    <h2  style = {{color:"black",fontSize:'small'}} align="left">Operators</h2>
                                </CardBody>          
                            </CardHeader> 
                            </Card>
                        </Grid>   
                        <Grid item xs={3}>
                            <Card style={{"marginTop": "-10%", padding:"2%"}}>
                            <CardHeader color="warning" stats icon>
                                <hr style={{border: "1px solid #3DAB35", marginLeft:"5%", width:"30px"}}></hr>
                                <CardIcon color="warning" style={{padding: 0}}>
                                    <LocalShippingOutlinedIcon  />
                                </CardIcon> 
                                    <CardBody>
                                    <h2 style ={{color: "#3dab35",fontSize: "28px",minHeight: "5px",fontWeight: "1000",marginBottom: "10px"}} align="left">
                                        {data.truckCount}
                                    </h2>
                                    <h2 style = {{color:"black",fontSize:'small'}}  align="left">Total Vehicles</h2>
                                    </CardBody>  
                            </CardHeader>
                            </Card>
                        </Grid>
                        <Grid item xs={3}>
                            <Card style={{"marginTop": "-10%", padding:"2%"}}>
                            <CardHeader color="warning" stats icon>
                                <hr style={{border: "1px solid #3DAB35", marginLeft:"5%", width:"30px"}}></hr>
                                <CardIcon color="warning" style={{padding: 0}}>
                                    <LocalShippingOutlinedIcon  />
                                </CardIcon> 
                                    <CardBody>
                                    <h2 style ={{color: "#3dab35",fontSize: "28px",minHeight: "5px",fontWeight: "1000",marginBottom: "10px"}} align="left">
                                        {data.truckCountLicensed}
                                    </h2>
                                    <h2 style = {{color:"black",fontSize:'small'}}  align="left">Licenced Vehicles</h2>
                                    </CardBody>  
                            </CardHeader>
                            </Card>
                        </Grid>
                        <Grid item xs={3}>
                            <Card style={{"marginTop": "-10%", padding:"2%"}}>
                            <CardHeader color="warning" stats icon>
                                <hr style={{border: "1px solid #3DAB35", marginLeft:"5%", width:"30px"}}></hr>
                                <CardIcon color="warning" style={{padding: 0}}>
                                    <LocalShippingOutlinedIcon  />
                                </CardIcon> 
                                    <CardBody>
                                    <h2 style ={{color: "#3dab35",fontSize: "28px",minHeight: "5px",fontWeight: "1000",marginBottom: "10px"}} align="left">
                                      {data.truckCountUnlicensed}
                                        {/* {data.truckCountLicensed}/{data.truckCountUnlicensed} */}
                                    </h2>
                                    <h2 style = {{color:"black",fontSize:'small'}}  align="left">Non-Licenced Vehicles</h2>
                                    </CardBody>  
                            </CardHeader>
                            </Card>
                        </Grid>
                    </Grid>
                    ))
                  
    }
                   
                    <div className="operator_listing scrollOperator" >
                            <TableContainer component={Paper} style={{paddingLeft:"1%", paddingRight:"1%"}}>
                            <Table stickyHeader aria-label="sticky table" style={{ tableLayout: 'fixed' }}>
                            <colgroup>
+                                <col style={{width:'3vw', padding:"0"}}/>
+                                <col style={{width:'8vw', padding:"0"}}/>
+                                <col style={{width:'7vw', padding:"0"}}/>
+                                <col style={{width:'9vw', padding:"0"}}/>
+                                <col style={{width:'8vw', padding:"0"}}/>
+                                <col style={{width:'10vw', padding:"0"}}/>
+                                <col style={{width:'4.5vw', padding:"0"}}/>
+                                <col style={{width:'6vw', padding:"0"}}/>
+                                <col style={{width:'7vw', padding:"0"}}/>
+                                <col style={{width:'8vw', padding:"0"}}/>
+                                <col style={{width:'7vw',paddingLeft:'0%',paddingRight:'0%'}}/>  
+                            </colgroup>
                            <TableHead >
                               <TableRow >
                                    {tableHeaders}
                               </TableRow>
                            </TableHead>
                            <TableBody>
                            {/* {this.props.operatorData.length== 0 && this.props.loadingOperatorData == true? <TableRow style={{alignItems:'center', fontWeight:'bold', color:'gray' }}>
                                            No Records Found !!</TableRow>: tableData} */}
                                  {tableData}
                            </TableBody>
                            </Table>
                            </TableContainer>
                    </div>
                     
                </Paper>
                {
                        this.props.totalCount && this.props.totalCount != 0 && 
                        <ReactPaginate
                        previousLabel={"Prev"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount = {this.props.totalCount/this.state.limit}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        style={{borderLeftWidth:'0px'}}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}/>
                    }

                {/* {this.state.searchBy=="vehicleNo"? <TruckListing/>:''} */}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        operatorHeaders : state.operatorHeaders.operatorHeaders.concat({header: "Action", accessor: "actionButton", sortable: "false", filter: "true"}),
        operatorData : state.operatorData.operatorData,
        statisticsData:state.statisticsData.statisticsData,
        token : state.auth.token,
        totalCount:state.operatorData.totalCount,
        loadingOperatorData: state.operatorData.loading
    }
}

const mapDispatchToProps = (dispatch)=> {
    return{
        onFetchOperatorHeaders : (payload) => dispatch(actionCreators.fetchOperatorHeaders(payload)),
        onFetchOperatorData : (payload) => dispatch(actionCreators.fetchOperatorData(payload)),
        onFetchOperatorBulkImport: (payload,formData) => dispatch(actionCreators.fetchOperatorBulkImport(payload,formData)),
        onDeleteOperator : (payload, data) => dispatch(actionCreators.deleteoperator(payload, data)),
        exportData: (payload) => dispatch(actionCreators.exportData(payload)),
        onSearchOperterName : (payload)=>dispatch(actionCreators.searchOperterName(payload)),
        onSearchOperterTruckNumber:(payload, props)=>dispatch(actionCreators.searchOperterTruckNumber(payload, props))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OperatorListing)
