import React, { Component } from 'react';
import { connect } from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox';
import '../loads/RecordKeeper.css';
import * as actionsCreators from  '../../../store/actions/index';
import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler';
import axios from '../../../http-config';
import {Link} from 'react-router-dom'
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Card from '@material-ui/core/Card';
import { set } from 'lodash';
import { Grid } from '@material-ui/core';

const GreenCheckbox = withStyles({
    root: {
      color: "green[400] !important",
      '&$checked': {
        color: green[600],
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

class RecordKeeperEdit extends Component {
    state = {
        useremail : '',
        username :  '',
        usernameErr : '',
        useremailErr : '',
        treatmentFacilities:[],
        treatmentFacility:[],
        name:  '',
        email :'',
        id:'',
        isDisabled:false ,
        checkedG: false,
        checkedSelect:false,
        TfArr :[]
    }

    handleSubmit = (e)=>{
        e.preventDefault()
        const err=this.checkValidity()
        if(!err){
            const formData={
                name:this.state.name,
                email:this.state.email
            }
        }    
    }

    componentDidMount=()=>{
    this.props.onfetchEmailManage({ token: this.props.token,id:this.props.match.params.id});
    this.props.onFetchTreatmentListing({ token: this.props.token})
    }

     
    handleChange = (e) =>
    {
        this.setState({
            [e.target.name]:e.target.value
        },()=>{
            this.checkValidity()
        })
    }

    handleTFChange = (e) => {
    this.setState({
        [e.target.name]:e.target.value
    })
    if(e.target.checked){
        this.state.TfArr.push(parseInt(e.target.value))
    }else{
        if(this.state.TfArr && this.state.TfArr.includes(parseInt(e.target.value))){
            var index = this.state.TfArr.indexOf(parseInt(e.target.value));
            if (index !== -1) {
            this.state.TfArr.splice(index, 1);
            }
        }
    }

    }

    checkValidity(value, rules,form) 
    {
        let isError=false;   
        if(!this.state.username)
        {
            isError=true;
            this.setState({usernameErr:"*This field is required"})
        }
        else if(this.state.username.length>0 && this.state.username.length<3)
        {
            isError=true;
            this.setState({usernameErr:"*Name should be atleast 3 characters long"})
        }
        else if(this.state.username && this.state.username.length >=3)
        {
            isError=false
            this.setState({usernameErr: ''})
        }
        if(!this.state.useremail)
        {
            isError=true;
            this.setState({useremailErr:"*This field is required"})
        }
        if(this.state.useremail)
        {
            var mailformat= /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
            if(!this.state.useremail.match(mailformat))
            {
                isError=true;
                this.setState({useremailErr:"*You have entered an invalid email address"})
            }
            else
            {
                isError=false
                this.setState({useremailErr:""})
            }
        }
        return isError
    }


handleChangeCheckedG =(event)=>{
    this.setState( {[event.target.name]: event.target.checked })
    const mapv = this.props.treatmentListing.map((item )=>{
        return parseInt(item.Id)
    })
  this.setState({TfArr:mapv})
  this.setState({checkedSelect:false})

}
onClickSaveAll = () => {
    const err=this.checkValidity()
    if(!err)
    {
        let isDisabled=false
        const payload={
        name:this.state.username,
        email:this.state.useremail,
        token: this.props.token,
        tfIds:this.state.TfArr,
        id:this.props.match.params.id
    }
    this.setState({isAddEnable:false, isDisabled:false, username : '', useremail:''})
    this.setState({checkedSelect:false})
    this.props.onEditEmail(payload,this.props)
}
}

componentWillReceiveProps(nextProps) {
    if(nextProps.users){
        if((nextProps.users[0].tfids && nextProps.users[0].tfids.length) == nextProps.treatmentListing.length){
            this.setState({
                checkedG : true,
                checkedSelect:false
            })
        }else{
            this.setState({
                checkedSelect:true,
                checkedG : false,
            })
        }
    }
    if (nextProps.users) {
       this.setState({
        TfArr : nextProps.users[0] && nextProps.users[0].tfids ? nextProps.users[0].tfids : [],
        username: nextProps.users[0] && nextProps.users[0].name ? nextProps.users[0].name : '',
        useremail: nextProps.users[0] && nextProps.users[0].email ? nextProps.users[0].email : ''
       })
    }
}

handleChangeCheckedSelected =(event)=>{
    this.setState({checkedG: false})
    this.setState( {[event.target.name]: event.target.checked })  
}

   
    render(){
        return(
        <div className="create_ulb">
            <div className="dashboard_top_path">
                <p><img src="images/left-arrow.svg" /> Email Management / <span>Edit Email Management</span></p>
            </div>
            <div className="dashboard_right_content">
                <img className="dashboard_right_content_top_icon" src="images/Rectangle.svg" />
                <div className="dashboard_right_content_top">
                    <h5>Edit Email Management</h5>
                </div>
                <div className="create_ulb_content_outer">
                    {/* <div className="create_ulb_content">
                        <FormControl>
                            <label for="name" >Name</label>
                            <OutlinedInput value={this.state.name} onChange={this.handleChange} name="name" id="name" variant="outlined" 
                            errorText={this.state.nameError}/>
                             <div style={{fontSize:12,color:"red"}}>{this.state.nameError}</div> 
                        </FormControl>
                        <FormControl>
                            <label for="email">Email</label>
                            <TextField id="email" variant="outlined" value={this.state.email} onChange={this.handleChange} name="email" 
                            errorText={this.state.emailError} 
                           />
                           <div style={{fontSize:12,color:"red"}}>{this.state.emailError}</div> 
                        </FormControl>
                   
                        <div className="create_ulb_buttons">
                            <Button className="login_submit" variant="outlined" color="primary">Cancel</Button>
                            <Button className="login_submit" variant="contained" color="primary" onClick={this.handleSubmit}>Save</Button>
                        </div>
                       
                    </div> */}
                         <div className="create_ulb_content">
                             <Grid container spacing={2}>
                                <Grid item xs={6}>
                                <FormControl>
                                    <label className="required" htmlFor="name">Name</label>
                                        <TextField id="username" 
                                                    variant="outlined" 
                                                    placeholder="Enter Name" 
                                                    name="username" 
                                                    value={this.state.username} 
                                                    style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif"}}
                                                    onChange={this.handleChange}/>
                                        <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.usernameErr}</div>
                                </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                <FormControl>
                                    <label htmlFor = "useremail">Email</label>
                                        <TextField id = "useremail" 
                                                    variant="outlined" 
                                                    placeholder="Enter Valid Email Address" 
                                                    name="useremail"
                                                    InputProps={{ style: { fontSize: 12 } }} 
                                                    value={this.state.useremail} 
                                                    onChange={this.handleChange}/>
                                        <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.useremailErr}</div>
                                </FormControl>
                                </Grid>

                                <Grid item xs={6}>
                                <FormControl>
                                     <label htmlFor ="treatmentFacility">Select Treatment Facility</label>
                                    <div style={{display:'flex', justifyContent: "space-between"}}>
                                    <FormControlLabel className="spanLable"
                                            control={<GreenCheckbox checked={this.state.checkedG}  onChange={this.handleChangeCheckedG} name="checkedG" />}
                                            label="All"
                                        />

                                    <FormControlLabel className="spanLable"
                                            control={<GreenCheckbox checked={this.state.checkedSelect} onChange={this.handleChangeCheckedSelected} name="checkedSelect" />}
                                            label="Selected"
                                        />
                                    </div>
                                    {this.state.checkedSelect === true? 
                                    <Card style={{border:"1px solid	rgb(211,211,211)" ,width:"875px"}}>
                                    {this.props.treatmentListing.map((item )=> (
                                        <MenuItem key={item.Id} value={item.Name}>
                                            <Checkbox style = {{color:"#52AC36",fontSize: 12}} 
                                            checked={this.state.TfArr && this.state.TfArr.includes(parseInt(item.Id)) ?true : false}          
                                            value={item.Id} 
                                            onChange={this.handleTFChange} 
                                            name="treatmentFacility"
                                            />
                                            <ListItemText primary={item.Name} />
                                        </MenuItem>
                                        ))}
                                    </Card>
                                :""}
                                
                                </FormControl>
                                </Grid>
                             </Grid>
              
              
              <Grid container spacing={3}>
                    <Grid item xs={12}>
                    <div className="create_ulb_buttons">
                    <Link style={{textDecoration:"none", color:"#3dab35"}} to="/email_manage"><Button className="login_submit" variant="outlined" color="primary">Cancel</Button></Link>
                    <Button className="login_submit" variant="contained" color="primary" onClick={this.onClickSaveAll}>Save</Button>
                </div>
                    </Grid>
              </Grid>
               
                </div>
                </div>
            </div>
        </div>
        )
 }
}


const mapStateToProps = state => {
    return {
        users: state.emailManage.users,
        loading: state.loads.loading,
        token: state.auth.token,
        treatmentListing:state.treatmentListing.treatmentListing
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchTreatmentListing: (payload) => dispatch(actionsCreators.fetchTreatmentListing(payload)),
        onfetchEmailManage: (payload) => dispatch(actionsCreators.fetchEmailByUserId(payload)),
        onEditEmail:(payload,props) => dispatch(actionsCreators.editEmail(payload,props))
    }
}

export default  connect(mapStateToProps, mapDispatchToProps)(RecordKeeperEdit);

