import * as actionTypes from '../actionsTypes';
import axios from '../../../http-config';

export const fetchZoneHeadersSuccess = (response) => {
    return {
        
        type: actionTypes.FETCH_PROPERTYHEADERS_SUCCESS,
        ZoneHeaders:response.data.headers   
    }
}

export const fetchZoneHeadersFail = (error) => {
    return {
        type: actionTypes.FETCH_PROPERTYHEADERS_FAIL,
        error:error
    }
}

export const fetchZoneHeadersStart = () => {
    return {
        type: actionTypes.FETCH_PROPERTYHEADERS_START
    }
}

export const fetchZoneHeaders = (payload) => {
    let token = payload.token;
    return dispatch => {
        dispatch(fetchZoneHeadersStart());
        axios({
            method: 'get',
            url: '/zone/headers',
            headers: {'Authorization': `Bearer ${token}`}
        })
        .then((response) => {
            const fetchedZoneHeadersResponse =response.data        
            dispatch(fetchZoneHeadersSuccess(fetchedZoneHeadersResponse));
        }).catch(error => {
            dispatch(fetchZoneHeadersFail(error));
        })

    }
}
