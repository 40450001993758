import * as actionTypes from '../../actions/actionsTypes';
import { updateObject } from '../../utility';

const initialState = {
    treatmentListing: [],
    loading: false,
    errorTreat:'',
    totalCount:0
}

const fetchTreatmentListingStart = (state, action) => {
    return updateObject(state, {
        loading: action.loading,
    });
}

const fetchTreatmentListingSuccess = (state, action) => {
    return updateObject(state, {
        treatmentListing:action.treatmentListing,
        totalCount:action.totalCount,
        loading:  action.loading,
    });
}

const fetchTreatmentListingFail = (state, action) => {
    return updateObject(state, {
        loading:  action.loading,
        errorTreat:action.error
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_TREATMENTLISTING_START: return fetchTreatmentListingStart(state, action);
        case actionTypes.FETCH_TREATMENTLISTING_SUCCESS: return fetchTreatmentListingSuccess(state, action);
        case actionTypes.FETCH_TREATMENTLISTING_FAIL: return fetchTreatmentListingFail(state, action);
        default: return state;
    }
}

export default reducer;