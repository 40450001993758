import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';

import {
  JsonToExcel
} from 'react-json-excel';
import moment from 'moment';

const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  container: {
   //height: '100%',
  //  maxHeight: "41vh",
    // maxHeight: "auto",
    // overflow: "hidden"
  },

});

export default function StickyHeadTable(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  let tfListing = props.treatmentFacility
  const [tfForTable,setTfForTable] = React.useState(!!tfListing&&tfListing[0].TFId) || 0

  let columnsData = []

  React.useEffect(() => {
    setTfForTable(!!tfListing&&tfListing[0].TFId)
  },[!!tfListing&&tfListing[0].TFId]);

  props.columns.forEach(function(obj){
    let column =  { id: obj, label: obj, minWidth: 170 , align: 'center' }
    columnsData.push(column)
  })

  columnsData = columnsData.filter( colValue => {
      if(colValue.id != '$id' && colValue.id.includes('ParamValue')){
           return colValue   
      }
  })

  columnsData = columnsData.map( colValue => {
     return {
       ...colValue,
       label: colValue.label.split('ParamValue')[1]
     }
  })

  columnsData.unshift({
    id: 'DateEntry', 
    label: 'Date', 
    minWidth: 170 , 
    align: 'center'
  })
  const columns = columnsData
  const rows    = props.rows
  var fields = {}
  const d = !!rows&&rows.map((item)=>{
    if(item.DateEntry){
      fields["DateEntry"] = "Date"
    }
    if(item.ParamValueBOD){
      fields["ParamValueBOD"] = "BOD"
    }
    if(item.ParamValueCOD){
      fields["ParamValueCOD"] = "COD"
    }
    if(item.ParamValueNO3){
      fields["ParamValueNO3"] = "NO3"
    }
    if(item.ParamValuePH){
      fields["ParamValuePH"] = "PH"
    }
    if(item.ParamValueTSS){
      fields["ParamValueTSS"] = "TSS"
    }
    if(item.ParamValueFLRate){
      fields["ParamValueFLRate"] = "FLRate"
    }
    if(item.ParamValueTFLRate){
      fields["ParamValueTFLRate"] = "TFLRate"
    }
    if(item.ParamValueORP){
      fields["ParamValueORP"] = "ORP"
    }
  })


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleTf = (event) => {
    setTfForTable(event.target.value)
    props.handleTableData(event.target.value)
  }

  return (
    <div className="dashboard_new_listing">
    <Paper className={classes.root}>

    <Grid container spacing={3} direction="row">
    <Grid item xs={4} align="left">
    <FormControl>
        <Select style={{marginLeft: `10px`,
        height: `32px`,
        borderRadius: '0px',
        marginTop: '2%',border:'none',color: '#4CAF50',textAlign: 'center'}} name='tfName' value= {tfForTable} placeholder="Select Tf"
        onChange = {handleTf} >
          {tfListing && tfListing.map( tf => {
            return <MenuItem key={tf.TFId} value={tf.TFId}>{tf.Name}</MenuItem>
          })}
        </Select>  
     </FormControl>
    </Grid>
    <Grid item xs={8} align="right">
          <JsonToExcel
            data={rows}
            filename={'sensors data'}
            fields={fields}
            style={{color:"#3DAB35",marginTop:'10px',marginBottom: '10px',marginRight: '10px',postion:'fixed',display:'block',
            border: '1px solid rgb(61,171,53)',
            padding: '5px',
            borderRadius: '4px',
            background: 'border-box'}}
            text="Download Excel"
          />
        
        </Grid>
      </Grid>
      <TableContainer className={classes.container} id="table-c">
      {/* <div style={{color:"rgb(102 102 102)", fontSize:"12px", fontWeight:"bolder",paddingTop:"10px",paddingBottom:"10px"}}>Data</div> */}
        <Table aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, fontWeight:'bolder', fontSize:"12px" }}
                >
                  {/* <div onClick={(e) =>  this.handleSort(e,col)}>
                      <div>{
                            this.state.currentSortId===col.accessor? this.state.sortObj.value ==='-asc'?(<ArrowDropDownIcon fontSize="small"/>):(<ArrowDropUpIcon fontSize="small"/>) :(<ArrowDropDownIcon fontSize="small"/>)
                        }
                        </div>   
                   </div> */}
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row,rowIndex) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={rowIndex}>
                  {columns.map((column,index) => {
                    let value = row[column.id];
                    return (
                      <TableCell key={index} align={column.align} style={{fontSize:"12px"}}>
                        {column.format && typeof value === 'number' ? column.format(value) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
    </div>
  );
}
