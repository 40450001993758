import React, {useState,useEffect} from 'react';
import { connect } from 'react-redux';
import ClusterForm from './clusterForm'
import '../../App.css'
import * as actionsCreators from '../../store/actions/index'
import axios from '../../http-config';
import _ from 'lodash'


function UlbEdit (props) {
    const [singleUlb, setUlb] = useState({}); 
    useEffect(() => {
        const payload = {
                        token : props.token,
                        id    : props.match.params.id
        }
        const url = `ulb/cluster-ulb/${payload.id}`
        axios({
                        method   : 'get',
                        url      : url,
                        headers  : {'Authorization': `Bearer ${payload.token}`}
                   })
                   .then((response) => {
                       setUlb(response.data.data.ulbs[0])
                   })
                   .catch((err) => {
                       
                   })
      },[]);
    
    const handleSubmit = (formdata,SubmitProp)=>{
        props.onEditClusterUlbData(formdata,SubmitProp, props)
    }

        return(
            
            <div className="create_ulb">
            <div className="dashboard_top_path">
                <p><img src="images/left-arrow.svg" /> Cluster Management / <span>Edit Cluster</span></p>
            </div>
            <div className="dashboard_right_content">
                <img className="dashboard_right_content_top_icon" src="images/Rectangle.svg" />
                <div className="dashboard_right_content_top">
                    <h5>Edit Cluster</h5>
                </div>
                <div className="create_ulb_content_outer">
                    <div className="create_ulb_content">
                { 
                 !_.isEmpty(singleUlb)&&(
                     <ClusterForm {...singleUlb} id={props.match.params.id} disabled={true} handleSubmit={handleSubmit}/>
                 )
                }      
                    </div>
                </div>
            </div>
        </div>
                
        )
}



const mapStateToProps = (state,props) => {
    
return {
    token:state.auth.token
}
}

const mapDispatchToProps = dispatch => {
return {
    onEditClusterUlbData: (formdata,SubmitProp, props) => dispatch(actionsCreators.editClusterUlbData(formdata,SubmitProp, props))
}
}

export default  connect(mapStateToProps, mapDispatchToProps)(UlbEdit);

