import * as actionTypes from '../actions/actionsTypes';
import { updateObject } from '../utility';

const initialState = {

    loading: false
}

const fetchExportsStart = (state, action) => {
    return updateObject(state, {
        loading: true,
    });
}

const fetchExportsSuccess = (state, action) => {
    return updateObject(state, {
       
        loading: false,
    });
}

const fetchExportsFail = (state, action) => {
    return updateObject(state, {
        loading: false,
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_EXPORTS_START: return fetchExportsStart(state, action);
        case actionTypes.FETCH_EXPORTS_SUCCESS: return fetchExportsSuccess(state, action);
        case actionTypes.FETCH_EXPORTS_FAIL: return fetchExportsFail(state, action);
        default: return state;
    }
}

export default reducer;