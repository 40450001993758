import React,{Component} from 'react'
import axios from '../../http-config'
import { connect } from 'react-redux';
import * as actionsCreators from  '../../store/actions/index';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import Grid from '@material-ui/core/Grid';
import Card from "./Card/Card.js"
import CardHeader from "./Card/CardHeader.js";
import CardIcon from "./Card/CardIcon.js";
import CardBody from './Card/CardBody.js'
import Button from '@material-ui/core/Button';
import moment from 'moment'
import { TextField } from "@material-ui/core";
import MenuItem from '@material-ui/core/MenuItem';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DatePicker , MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Chart from './Chart'
import LinearProgress from '@material-ui/core/LinearProgress';
const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: "6px",
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor:"#fff",
    border: '1px solid #4CAF50',
    fontSize: 15,
    padding: '7px 26px 7px 20px',
    transition: theme.transitions.create(['border-color']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      'Open Sans',
      'sans-serif',
 
    ].join(','),
    '&:focus ': {
      borderRadius: 4,
          backgroundColor:"#fff !important"
 
    }
  },
}))(InputBase);
class CardMain extends Component{
  constructor(props){
super(props)
      this.myRef = React.createRef(()=>{});

      this.state = {
        filter : {
          'interval':'',
        },
        data:[],
        id:'',
        isDisabled:false,
        interval:'Current Day',
        open:false,
        fromdate:new Date('01,01,2020').toLocaleDateString(),
        todate:new Date(),
        startDate:moment(new Date()).subtract(7,'d').format('YYYY-MM-DD'),
        endDate: new Date(),
        loadsType:1,
        progress:0,
        buffer:10


      }
  }

  componentDidMount=()=>{
      let payload = {
        token: this.props.token,
        fromDate:this.state.startDate,
        todate:moment(this.state.endDate).format('YYYY-MM-DD')
        // filter: JSON.stringify([this.state.filter]),
        // interval:this.state.fromdate,
       
    }
    // let date = new Date()
    // let day = date.getDate().toString()<10 ? "0"+date.getDate().toString(): date.getDate().toString()
    // let month = (date.getMonth()+1).toString() <10 ? "0"+(date.getMonth()+1).toString(): (date.getMonth()+1).toString()
    // let filterDate =  day +'/'+ month+'/' + date.getFullYear().toString()
    // localStorage.setItem('filterDate', filterDate);
    // console.log(payload,"payload00000");
       this.props.onFetchDashboardData(payload)
       this.props.onFetchClusterData(payload)

      //  const progressRef = React.useRef(() => {});
this.myRef.current = () => {
        if (this.state.progress > 100) {
          this.setState({progress:0, buffer:10})
          // setProgress(0);
          // setBuffer(10);
        } else {
          const diff = Math.random() * 10;
          const diff2 = Math.random() * 10;
          this.setState({progress:this.state.progress + diff, buffer:this.state.progress + diff + diff2})
        }
      };

    }

    // async setFilterByDateField(value){
    //   let newDate = new Date()
    //   let currentTimeStamp = newDate.setDate(newDate.getDate())
    //   let timestampNow = new Date(currentTimeStamp).toLocaleDateString()
    //   if(value!=null){
    //     await this.setState({interval: value.title});
    //     switch(value.title){
    //       case '7 Days':
    //           let timeStamplt7 = newDate.setDate(newDate.getDate() - 7)
    //           let lt7Days = new Date(timeStamplt7).toLocaleDateString()
    //           localStorage.setItem('filterDate', lt7Days);            
    //           break;
    //       case '30 Days':
    //           let timeStamplt30 = newDate.setDate(newDate.getDate() - 30)
    //           let lt30Days = new Date(timeStamplt30).toLocaleDateString()
    //           localStorage.setItem('filterDate', lt30Days);
    //           break;
    //       case 'Till Date':
    //         localStorage.setItem('filterDate', '01/01/2020');
    //         break;
    //       default:
    //         localStorage.setItem('filterDate', timestampNow);
    //     }
    //   }else{
    //     await this.setState({interval: 'Current Day'});
    //     localStorage.setItem('filterDate', timestampNow);
    //   }
    //   let payload = {
    //     token: this.props.token,
    //     filter: JSON.stringify([this.state.filter]),
    //     interval:this.state.fromdate
    // }
    // console.log(payload,"payload");
    //   this.props.onFetchDashboardData(payload)
    // }
    openPopupCluster =()=>{
      this.setState({open: true})

    }
    handleClose = () => {
      this.setState({ open:false}) ;
    };
  //   setFromDate =(date)=>{
  //     this.setState({fromdate:date}, )
  // } 
  // setToDate =(date)=>{
  //     this.setState({todate:date},)
  // }
  handleStartDate = (date) => {
    // console.log(date,"fromdate");
    this.setState({startDate:moment(date).format('YYYY-MM-DD')})
    let payload ={
      token: this.props.token,
      fromDate:moment(date).format('YYYY-MM-DD'),
      todate:moment(this.state.endDate).format('YYYY-MM-DD')
    }
    this.props.onFetchDashboardData(payload)
  }

  handleEndDate = (date) => {
    // console.log(date,"endDate==");
    this.setState({endDate:moment(date).format('YYYY-MM-DD')})
    let payload ={
      token: this.props.token,
      fromDate:moment(this.state.startDate).format('YYYY-MM-DD'),
      todate:moment(date).format('YYYY-MM-DD')
    }
    this.props.onFetchDashboardData(payload)
  }
  handleChange = (event)=>{
    this.setState({loadsType:event.target.value})
  }
  render(){
    let data = !!this.props.data.dashboard_data&&this.props.data.dashboard_data
    let no_of_loads = data?data[0].no_of_loads:0
    let no_of_trucks = data?data[0].no_of_trucks:0
    var loadsTypeList =[
      {id:1,name:"Date wise loads"},
    {id:2, name:"Property wise loads"},
  {id:3, name:"TF wise loads"},
  {id:4, name:"ULB/Cluster wise loads"},

    ]
    return(
      <div style={{marginTop:"-1%"}}>
      <Grid container spacing={1} >
        <Grid item xs={2}>
          <Card>
            <hr style={{border: "1px solid #3DAB35", marginLeft:"5%", width:"30px"}}></hr>  
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning"  style= {{width:"50px" , height: "50px",padding:"0px", marginLeft:"2px" }}>
                <BusinessOutlinedIcon  />
              </CardIcon> 
                <CardBody>
                    <h2 className="icon-number"  align="left">{no_of_loads}</h2>
                    <h2  className=" icon-word"  align="left">Loads</h2>
                </CardBody>          
            </CardHeader> 
          </Card>
        </Grid>   
        <Grid item xs={2}>
          <Card>
            <CardHeader color="warning" stats icon>
               <hr style={{border: "1px solid #3DAB35", marginLeft:"5%", width:"30px"}}></hr>
               <CardIcon color="warning" style= {{width:"50px" , height: "50px",padding:"0", }}>
                 <LocalShippingOutlinedIcon  />
               </CardIcon> 
                 <CardBody>
                    <h2 className="icon-number"  align="left">{no_of_trucks}</h2>
                    <h2 className=" icon-word"   align="left">Trucks</h2>
                 </CardBody>  
            </CardHeader>
           </Card>
        </Grid>
        <Grid item xs={3}>
        <Card>
             <CardHeader stats icon>
              <hr style={{border: "1px solid #3DAB35", marginLeft:"1%", width:"30px"}}></hr>
              <h2 style={{color:"#3DAB35",fontSize:"17px",paddingTop: "10px"}} align="left">Filter Loads Type</h2>
             </CardHeader>
               <CardBody>
        <FormControl>
                    <label className="" htmlFor="loadsType" align="left" style={{fontSize:"12px", textAlign:'left', fontWeight:"bold" }}>Loads Types</label>
                    <NativeSelect
                         value={this.state.loadsType}
                         onChange={this.handleChange}
                         input={<BootstrapInput  />}
                         style={{color: '#4CAF50', fontFamily:'Open Sans, sans-serif'}}
                         >
                           {loadsTypeList.map((option) => (
                            <option value={option.id}>{option.name}</option>
                
                                ))}
                         </NativeSelect>
                </FormControl>
                </CardBody>
                </Card>
        </Grid>
        <Grid item xs={3}>

        <Card>
             <CardHeader stats icon>
              <hr style={{border: "1px solid #3DAB35", marginLeft:"1%", width:"30px"}}></hr>
              <h2 style={{color:"#3DAB35",fontSize:"17px",paddingTop: "10px"}} align="left">Filter By</h2>
             </CardHeader>
               <CardBody>
                  <Grid container spacing={0} direction="row">
                    <Grid item xs={6}> 
                    <h2 style = {{fontSize:"12px", marginBottom: "4%" , marginLeft:"5%", textAlign:'left' }}>From Date</h2>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      variant="inline"
                      value={this.state.startDate} 
                      onChange={this.handleStartDate}
                      disableFuture
                      format='dd/MM/yyyy'
                      autoOk
                      style={{width:'95%', borderBottom: 'none'}}
                      InputProps={{ style:{fontSize:'small',height:'35px',marginTop:'1px',border: '1px solid gray', 
                      borderRadius: '4px', fontFamily: 'Open Sans, sans-serif'},disableUnderline: true}}
                      />  
                      </MuiPickersUtilsProvider>                 
                    </Grid>
                    <Grid item xs={6}>
                      <h2 style = {{fontSize:"12px", marginBottom: "4%" , marginLeft:"5%", textAlign:'left' }}>To Date</h2>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      variant="inline"
                      value={this.state.endDate} 
                      onChange={this.handleEndDate}
                      disableFuture
                      format='dd/MM/yyyy'
                      autoOk
                      style={{width:'95%', borderBottom: 'none'}}
                      InputProps={{ style:{fontSize:'small', padding:'4px' ,height:'35px',marginTop:'1px',border: '1px solid gray', 
                      borderRadius: '4px', fontFamily: 'Open Sans, sans-serif'},disableUnderline: true}}
                      />  
                      </MuiPickersUtilsProvider>                    </Grid>
                  </Grid>
               </CardBody>
           </Card>
           {/* <Card>
             <CardHeader stats icon>
              <hr style={{border: "1px solid #3DAB35", marginLeft:"5%", width:"30px"}}></hr>
              <h2 style={{color:"#3DAB35",fontSize:"20px",paddingTop: "10px"}} align="left">Filter By</h2>
             </CardHeader>
               <CardBody>
                  <Grid container spacing={0} direction="row">
                    <Grid item xs={12}>
                     
                      <h2  style = {{fontSize:"12px",  textAlign:'left' }}>From Date</h2>
                      <h2 style = {{fontSize:"12px", marginBottom: "4%" , marginLeft:"31%",  }}>To Date</h2>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
<DatePicker
        variant="inline"
        // inputVariant="outlined"
        value={this.state.fromdate}  
        onChange={this.setFromDate}
        // disablePast
        format='dd MMM YYY'
        autoOk
        style={{width:'53%', borderBottom: 'none'}}
        InputProps={{ style:{fontSize:'small', padding:'4px' ,height:'35px',marginTop:'2px',color: '#3DAB35',border: '1px solid #3DAB35', 
        borderRadius: '4px', fontFamily: 'Open Sans, sans-serif'},disableUnderline: true}}/>
 <span style={{fontSize: 'small',fontFamily: 'Open Sans, sans-serif'}}>&nbsp;&nbsp;to&nbsp;&nbsp;</span> 
        <DatePicker
        variant="inline"
        value={this.state.todate} 
        onChange={this.setToDate} 
        
        minDate={this.state.fromdate}
        format='dd MMM YYY'
        autoOk
        style={{width:'40%'}}
        InputProps={{ style:{fontSize:'small', padding:'4px' ,height:'35px',marginTop:'2px',color: '#3DAB35',border: '1px solid #3DAB35', 
        borderRadius: '4px', fontFamily: 'Open Sans, sans-serif'}, disableUnderline: true}}/>
</MuiPickersUtilsProvider>   
                       <Autocomplete
                          style = {{width: '100%', fontSize:"10px"}}
                          value={this.state.interval==undefined ? 'Current Day':this.state.interval}
                          onChange={(event, value) => this.setFilterByDateField(value)}
                          id="combo-box-demo"
                          options={[{title:"Current Day"},{title:"7 Days"},{title:"30 Days"},{title:"Till Date"}]}
                          getOptionLabel={option => typeof option === 'string' ? option : option.title}
                          renderInput={(params) => <TextField {...params} style = {{width: '100%', fontSize:"12px"}} placeholder="Select" size="small" variant="outlined" />}
                        />
                    </Grid>
                    <Grid item xs={6}>
                      <h2 style = {{fontSize:"12px", marginBottom: "4%" , marginLeft:"5%", textAlign:'left' }}>To Date</h2>
                      <TextField  id="filterDate" disabled style = {{width: '100%', marginLeft:"5%", fontSize:"12px"}} size="small"  variant="outlined" value={localStorage.getItem('filterDate')}  type="text" />
                    </Grid>
                  </Grid>
               </CardBody>
           </Card> */}
        </Grid>
        <Grid item xs={2}>
          <Card>
            <CardHeader>
               <hr style={{border: "1px solid #3DAB35", marginLeft:"5%", width:"30px"}}></hr>
          
                 <CardBody>
                 <Button onClick={this.openPopupCluster} variant="contained" style={{color:'white', backgroundColor:'green'}} type="submit">View Available Cluster</Button>
                 </CardBody>  
            </CardHeader>
           </Card>
        </Grid>

      </Grid>


      <Dialog
        maxWidth={'xs'}
        fullWidth={true} 
    
        open={this.state.open}
        aria-labelledby="responsive-dialog-title">
        <DialogTitle className="text-center border-none " style={{color:'#3dab35'}}><span style={{fontSize:'18px'}}>Available Cluster's Ulb</span></DialogTitle>
        <DialogContent > 
            <div className="create_ulb_content_outer">
                <div className="create_ulb_content_sched">
                    <Grid container spacing={3}>
                       <Grid item xs={12}>
                    { !!this.props.ClusterData&&this.props.ClusterData.map((item)=>{
                    return (
                      <div  style={{display:"flex",padding:'2px', flexDirection:"column"}}>
                        <div style={{display: "flex"}}>
                        <p style={{textAlign:'start'}}>{item.id}</p> . &nbsp;  <p style={{textAlign:'right'}}>{item.clusterUlbName}</p>

                        </div>
                        {/* <div  className="rootBuffer">
                          <LinearProgress variant="buffer" value={this.state.progress} valueBuffer={this.state.buffer} />
                        </div> */}
                      </div>
                     
                    )
                    })}
                       </Grid>
                    </Grid>
                </div>
            </div>      
        </DialogContent>
        <DialogActions className="leftp">
            <Button autoFocus onClick={this.handleClose} style={{
                background:'none',
                color:'#3dab35',
                border:" 1px solid rgba(61, 171, 53, 0.5)",
                }} 
                type="submit">Cancel
            </Button>
        </DialogActions>
    </Dialog>

    <Grid container spacing={1} style={{marginTop:'-33px'}}>
      <Grid item xs={12}>  <Chart loadsTypes={this.state.loadsType}/></Grid>

</Grid>





    </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    data: state.dashboard.dashboard,
    loading: state.loads.loading,
    token: state.auth.token,
    ClusterData: state.ClusterLIstingReducer.clusterData,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchDashboardData: (payload) => dispatch(actionsCreators.fetchDashboard(payload)),
    onFetchClusterData: (payload) => dispatch(actionsCreators.fetchClusterData(payload)),
  }
}

export default  connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(CardMain, axios));
