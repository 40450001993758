import * as actionTypes from '../../actions/actionsTypes'
import { updateObject } from '../../utility'

const initialState = {
    ClusterHeaders   : [],
    loading      : false
}

const fetchClusterStart = ( state, action ) => {
    return updateObject(state, {
        loading : action.loading
    })
}

const fetchClusterSuccess = ( state, action ) => {
    return updateObject(state, {
        ClusterHeaders : action.ClusterHeaders,
        loading    :  action.loading
    })
}

const fetchClusterFail = ( state, action ) => {
    return updateObject(state, {
        loading  : action.loading
    })
}

const ClusterrReducer = ( state = initialState, action ) => {
    switch(action.type){
        case actionTypes.FETCH_CLUSTER_START    : return fetchClusterStart(state, action)
        case actionTypes.FETCH_CLUSTER_SUCCESS  : return fetchClusterSuccess( state, action)
        case actionTypes.FETCH_CLUSTER_FAIL     : return fetchClusterFail(state, action)
        default                                     : return state
    }
}

export default ClusterrReducer;