import * as actionTypes from '../actionsTypes';
import axios from '../../../http-config';
import swal from 'sweetalert';

export const fetchTreatmentListingSuccess = (response) => {
    return {
        type: actionTypes.FETCH_TREATMENTLISTING_SUCCESS,
         treatmentListing:response.data,
         totalCount:response.totalCount,
         loading: true     
    }
}

export const fetchTreatmentListingFail = (error) => {
    return {
        type: actionTypes.FETCH_TREATMENTLISTING_FAIL,
        error:error,
        loading:true
    }
}


export const fetchTreatmentListingStart = () => {
    return {
        type: actionTypes.FETCH_TREATMENTLISTING_START,
        loading:false
    }
}


export const fetchTreatmentListing = (payload) => {
    let token = payload.token;
    
    return async dispatch => {
        dispatch(fetchTreatmentListingStart());
     
        axios({
            method : 'get',
            url : `/treatment_facility/?limit=${payload.limit}&page=${payload.page}&sortColumnName=${payload.sortName}&sortColumnType=${payload.sortValue}`,
            headers :  {'Authorization': `Bearer ${token}`}
        })
        .then((response) => {
        
            const fetchedTreatmentListingResponse = response.data
            dispatch(fetchTreatmentListingSuccess(fetchedTreatmentListingResponse));
            return fetchedTreatmentListingResponse
        })
        .catch((err) => {
            dispatch(fetchTreatmentListingFail(err))
        })
    }
}

export const fetchTreatmentListingIOT = (payload) => {
    let token = payload.token;
    let ulb = payload.ulb
    return async dispatch => {
        dispatch(fetchTreatmentListingStart());
        const response = await  axios({
            method : 'get',
            url : ulb ? `/state_dashboard_user_access_iot/tf/${ulb}` : '/treatment_facility/',
            headers :  {'Authorization': `Bearer ${token}`}
        })
        const fetchdata = response.data.data
        dispatch(fetchTreatmentListingSuccess(response.data));
        return fetchdata
    }
}

export const addTreatmentListing = (payload,props) => {
    let token = payload.token;
    return dispatch => {
        dispatch(fetchTreatmentListingStart());
        const payload1 = {
            tfName              : payload.tfName,
            amount              : payload.amount,
            capacity            : payload.capacity,
            operationalHoursFrom: payload.operationalHoursFrom,
            operationalHoursTo  : payload.operationalHoursTo,
            receiptNumberPrefix : payload.receiptNumberPrefix,
            username            : payload.username,
            email               : payload.email,
            location            : payload.location,
            type                : payload.type,
            locationName        : payload.locationName,
            wardZone            : payload.wardZone
        }
        let url = '/treatment_facility/';
        axios.post(url, payload1,{headers:{
            "Authorization":`Bearer ${token}`
        }})
        .then((response) => {
            dispatch(fetchTreatmentListing({ token: payload.token,limit: 10,page: 1,sortName: '',sortValue: ''}));
            props.history.push('/treatment')
            const fetchedResponse = response.data.success;
                fetchedResponse && swal({
                    title: "You have Successfully added the data!!!",
                    icon: "success",
                    buttons: {
                        confirm: 'Ok'
                    }
                  })
        }, 
        (err) => {
            swal({icon : "error" , title : err.response.data.error[0]["message"]})           
        }).catch(error => {
            dispatch(fetchTreatmentListingFail(error))           
        })
        
    }
}

export const editTreatmentListing = (payload, props) => {
    const id = props.match.params.id
    let token = payload.token;
    return dispatch => {
        dispatch(fetchTreatmentListingStart());
        const payload1 = {
            id                  : id,
            userId              : payload.UserId,
            tfName              : payload.tfName,
            amount              : payload.amount,
            capacity            : payload.capacity,
            operationalHoursFrom: payload.operationalHoursFrom,
            operationalHoursTo  : payload.operationalHoursTo,
            receiptNumberPrefix : payload.receiptNumberPrefix,
            username            : payload.username,
            email               : payload.email,
            location            : payload.location,
            type                : payload.type,
            locationName        : payload.locationName,
            wardZone            : payload.wardZone
        }
        let url = '/treatment_facility/';
        axios.put(url, payload1,{headers:{
            "Authorization":`Bearer ${token}`
        }})
        .then(response => {
            dispatch(fetchTreatmentListing({ token: payload.token,limit: 10,page: 1,sortName: '',sortValue: ''}));
            props.history.push('/treatment')
            const fetchedResponse = response.data.success;
                fetchedResponse && swal({
                    title: "You have Successfully edited the data!!!",
                    icon: "success",
                    buttons: {
                        confirm: 'Ok'
                    }
                  })
        }, (err) => {
            swal({icon : "error" , title : err.response.data.error[0]["message"]})          
        })
        .catch(err => {
            dispatch(fetchTreatmentListingFail(err))
        })
    }
}

export const deleteTreatmentFacility = (payload,payload1) => {
    return (dispatch) => {
        dispatch(fetchTreatmentListingStart());
        let url = '/treatment_facility/';
        axios.delete(url, { data : payload,  headers:{
            "Authorization":`Bearer ${payload1.token}`
        }})
        .then((response) => {
            dispatch(fetchTreatmentListing(payload1));
                swal({
                    title:"Your data has been deleted!",
                    icon: "success",
                })
        }, 
        (err) => {
            swal({icon : "error" , title : err.response.data.error[0]["message"]})         
        })
    }
}


export const searchTFName=(payload)=>{
    return dispatch =>{
        dispatch(fetchTreatmentListingStart());
        const url =`/treatment_facility?name=${payload.searchQuery}&limit=${payload.limit}&page=${payload.page}`
        axios.get(url,{headers:{
            "Authorization":`Bearer ${payload.token}`
        }} ).then(response=>{
            // response && response.data && response.data &&
            // response.data && 
            // response.data.length == 0 && 
            // swal("No Records Found !", {
            //     icon: "warning",
            //   });
            dispatch(fetchTreatmentListingSuccess(response.data));

        },
        (err) => {
            swal({icon : "warning" , title : err.response.data.error[0]["message"]})         
        })
        
        .catch(err=>{ 
            dispatch(fetchTreatmentListingFail(err));
        })
    }
}


export const fetchTreatmentListingReasonView = (payload) => {
    let token = payload.token;
    return async dispatch => {
        dispatch(fetchTreatmentListingStart());
     
        axios({
            method : 'get',
            url : `/tf_reasons?tfId=${payload.tfId}&limit=${payload.limit}&page=${payload.page}&toDate=${payload.toDate}&fromDate=${payload.fromDate}&month=${payload.monthName}&year=${payload.year}`,
            headers :  {'Authorization': `Bearer ${token}`}
        })
        .then((response) => {
        
            const fetchedTreatmentListingResponse = response.data
            dispatch(fetchTreatmentListingSuccess(fetchedTreatmentListingResponse));
            return fetchedTreatmentListingResponse
        })
        .catch((err) => {
            dispatch(fetchTreatmentListingFail(err.response.data.error[0]["message"]))
        })
    }
}
