import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import moment from 'moment'
import { useDispatch } from "react-redux";
import { fetchStateDashboardExcal } from '../../store/actions/stateDashboard'
import { Link } from 'react-router-dom';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
// import RecordKeeper   from "../RecordKeeper/loads/RecordKeeper";
// import {fetchLoads} from '../../store/actions/loads'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Line, Bar } from 'react-chartjs-2';
import axios from '../../http-config';
import swal from 'sweetalert';
// import moment from 'moment/moment';
import BasicTableArea from '../Dashboard/tableArea'
import { Card } from '@material-ui/core';
const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  container: {
    //height: '100%',
    // maxHeight: "41vh",
    // overflow: "hidden"
  },
  containerMobile: {
    marginTop: '0px'
  },
  header: {
    zIndex: '0 !important'
  }
});

export default function StickyHeadTable(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [sortOrder, setSortOrder] = React.useState('')
  const [currentSortId, setCurrentSortId] = React.useState('')
  const [openChart, setOpenChart] = React.useState(false)
  const [chartDash, setChartDash] = React.useState([])
  const [UtlChartDash, setUtlChartDash] = React.useState([])
  const [openMechanism, setOpenMechanism] = React.useState(false)
  const [selectedULBid, setSelectedULBid] = React.useState("")
  const [openClsuterChart, setOpenClsuterChart] = React.useState(false)
  const [ulbNameHistory, setUlbNameHistory] = React.useState([])
  const [utilizationRateInHistory, setutilizationRateInHistory] = React.useState([])
  const [loadHistory, setLoadHistory] = React.useState([])

  function createData(ULB, Area, Count) {
    return { ULB, Area, Count };
  }

  const rows = props.rows.map(row => {
    return {
      ...row,
      // lastUpdated : moment(row.lastUpdated).format('DD-MM-YYYY')
    }

  });

  let columns = props.columns
  columns = window.innerWidth <= 480 ? props.columns.slice(1) : props.columns

  const fixedColumns = props.columns.slice(0, 1)
  // const rows    = props.rows


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleExcalDownload = () => {
    dispatch(fetchStateDashboardExcal(props.payloadDExcal))
  }

  const handleSort = (e, col) => {
    let sortBy
    if (currentSortId === col.id) {
      sortBy = (sortOrder === 'DESC') ? 'ASC' : 'DESC';
    }
    else {
      sortBy = 'DESC';
    }
    setSortOrder(sortBy)
    setCurrentSortId(col.id)
    // props.handleSort(e, col, sortBy)
  }
  const handleOpenChart = (data) => {
    setOpenChart(true)
    if(props.payloadDExcal.category == "2" ) {
      var startDate = moment(props.payloadDExcal.startDate);
      var endDate = moment(props.payloadDExcal.endDate);
      var result = endDate.diff(startDate, 'days');
      let url
      if (result > 30 && result <= 365) {
        url = `/?fromDate=${props.payloadDExcal.startDate}&toDate=${props.payloadDExcal.endDate}&Month=true&ulbId=${data.ULBId}`;
    
      } else if (result > 365) {
        url = `/?fromDate=${props.payloadDExcal.startDate}&toDate=${props.payloadDExcal.endDate}&Year=true&ulbId=${data.ULBId}`;
       
      } else {
        url = `/?fromDate=${props.payloadDExcal.startDate}&toDate=${props.payloadDExcal.endDate}&ulbId=${data.ULBId}`;
   
      }
      if (data.ULBId) {
        axios({
          method: 'get',
          url: '/dashboard' + url,
          headers: { 'Authorization': `Bearer ${props.payloadDExcal.token}` }
        })
          .then((response) => {
            setChartDash(response.data.data)
          }).catch(error => {
  
          })
      }
  
    }else {
      var startDate = moment(props.payloadDExcal.startDate);
      var endDate = moment(props.payloadDExcal.endDate);
      var result = endDate.diff(startDate, 'days');
      let url
      if (result > 30 && result <= 365) {
        url = `/state_dashboard/monthwiseload?startDate=${props.payloadDExcal.startDate}&endDate=${props.payloadDExcal.endDate}&endutilizationRateIn=100&treatmentFacilityType=1&ulbNames=&Month=true&ULBId=${data.ULBId}`;
    
      } else if (result > 365) {
        url = `/state_dashboard/load?startDate=${props.payloadDExcal.startDate}&endDate=${props.payloadDExcal.endDate}&endutilizationRateIn=100&treatmentFacilityType=1&ulbNames=&Year=true&ULBId=${data.ULBId}`;
       
      } else {
        url = `state_dashboard/load?startDate=${props.payloadDExcal.startDate}&endDate=${props.payloadDExcal.endDate}&endutilizationRateIn=100&treatmentFacilityType=1&ulbNames=&Day=true&ULBId=${data.ULBId}`;
   
      }
      if (data.ULBId) {
        axios({
          method: 'get',
          url: url,
          headers: { 'Authorization': `Bearer ${props.payloadDExcal.token}` }
        })
          .then((response) => {
            setUtlChartDash(response.data.data)
          }).catch(error => {
  
          })
      }
    //   let arr = []
    //   let arrUTl = []
    //   let loadArr = []
    //   if(data.ulbName){
    //     arr.push(data.ulbName)
    //     arrUTl.push(data.utilizationRateIn)
    //     loadArr.push(data.noOfLoadsReceived)
    //   }
    //   setUlbNameHistory([...arr])
    //   setutilizationRateInHistory([...arrUTl])
    //   setLoadHistory([...loadArr])
    }
   
  }
  const handleOpenMechanism = (ulbId) => {
    setOpenMechanism(true)
    setSelectedULBid(ulbId)


  }
  const handleCloseMechanism = () => {
    setOpenMechanism(false)
  };

  const handleClusterData = (id) => {
    setOpenClsuterChart(true)
    const url = `/?fromDate=${props.payloadDExcal.startDate}&toDate=${props.payloadDExcal.endDate}&ulbId=${id}`;
    if (id) {
      axios({
        method: 'get',
        url: '/dashboard' + url,
        headers: { 'Authorization': `Bearer ${props.payloadDExcal.token}` }
      })
        .then((response) => {
          setChartDash(response.data.data)
        }).catch(error => {

        })
    }
  }
  const handleCloseClusterData = () => {
    setOpenClsuterChart(false)

  }
  const handleFreqClose = () => {
    setOpenChart(false)
    setChartDash([])
    setUtlChartDash([])
  };

  let datevalue = props.payloadDExcal
  let dashboard_data = !!chartDash && chartDash.dashboard_data

  let ulbCount = 0
  let clusterCount = 0
  let line_chart_labels = []
  let line_chart_data = []
  let loadCount = []
  let UtilizationRate = []
if(props.payloadDExcal.category == "2"){
  if (dashboard_data) {
    dashboard_data[0].date_wise_loads.forEach(function (date_wise_load) {
      if ('Year' in date_wise_load) {
        line_chart_labels.push(date_wise_load.Year)
        line_chart_data.push(date_wise_load.Count)
      } else if ('Month' in date_wise_load) {
        line_chart_labels.push(date_wise_load.Month)
        line_chart_data.push(date_wise_load.Count)

      } else {
        line_chart_labels.push(date_wise_load.DateTime)
        line_chart_data.push(date_wise_load.Count)
      }
    });
  }
  
  if (dashboard_data) {
    dashboard_data[0].ulb_cluster_data.forEach(function (ulb_cluster_data) {
      if (ulb_cluster_data.ULBType === "Host") {
        ulbCount = ulb_cluster_data.Count
      }
      if (ulb_cluster_data.ULBType === "Cluster") {
        clusterCount = clusterCount + parseInt(ulb_cluster_data.Count)
      }
    });
  }

} else  {
  let dashboard_data_history = !!UtlChartDash && UtlChartDash.dashboard_data_history?.[0]?.table_data
  let dashboard_data_history_month = !!UtlChartDash && UtlChartDash.dashboard_data_history?.[0]?.table_data1
  if (dashboard_data_history) {
    dashboard_data_history.body.forEach(function (body) {
      if ('Year' in body) {
        line_chart_labels.push(body.Year)
        loadCount.push(body.LoadCount)
        UtilizationRate.push(body.Utilization)
      } else if ('Month' in body) {
        line_chart_labels.push(body.Month)
        loadCount.push(body.LoadCount)
        UtilizationRate.push(body.Utilization.toFixed(2))

      } else {
        line_chart_labels.push(body.Date)
        loadCount.push(body.LoadCount)
        UtilizationRate.push(body.Utilization)
      }
    });
  }
  if (dashboard_data_history_month) {
    dashboard_data_history_month.forEach(function (body) { 
      if(body == null){  
      }else {
    line_chart_labels.push(body.Month)
        loadCount.push(body.LoadCount)
        UtilizationRate.push(body.Utilization.toFixed(2))
      }
    });
  }
  if (dashboard_data) {
    dashboard_data[0].ulb_cluster_data.forEach(function (ulb_cluster_data) {
      if (ulb_cluster_data.ULBType === "Host") {
        ulbCount = ulb_cluster_data.Count
      }
      if (ulb_cluster_data.ULBType === "Cluster") {
        clusterCount = clusterCount + parseInt(ulb_cluster_data.Count)
      }
    });
  }
}

const dataCls = !!dashboard_data && dashboard_data[0] && dashboard_data[0].ulb_cluster_data ? dashboard_data[0].ulb_cluster_data : []
  let minTick = Math.min(...line_chart_data) - (Math.max(...line_chart_data) * 0.1) < 0 ? 0 : Math.round(Math.min(...line_chart_data) - (Math.max(...line_chart_data) * 0.1))
  let maxTick = Math.ceil(Math.max(...line_chart_data) + (Math.max(...line_chart_data) * 0.1))
  const tickDifference = Math.ceil(maxTick / 10)
  maxTick = Math.max(...line_chart_data) + tickDifference
  minTick = Math.min(...line_chart_data) - tickDifference < 0 ? 0 : Math.round(Math.min(...line_chart_data) - tickDifference)


  // const dataUTl = UtlChartDash && UtlChartDash.dashboard_data_for_utilization && UtlChartDash.dashboard_data_for_utilization.length > 0 ? UtlChartDash.dashboard_data_for_utilization[0].date_wise_utilization : []
  // const ulbNameHistory = []
  // const utilizationRateInHistory = []
  // if (dataUTl !== null) {
  //   dataUTl.forEach(element => {
  //     ulbNameHistory.push(element.ulbName)
  //     utilizationRateInHistory.push(element.utilizationRateIn)
  //   })
  // }

  var lineChartOptions = {
    showScale: true,
    //pointDot: true,
    //showLines: false,
    title: {
      display: true,
      text: 'Date Wise Loads'
    },
    legend: {
      display: true,
      labels: {
        boxWidth: 50,
        fontSize: 12,
        fontColor: 'rgb(115 115 115)',
        padding: 5,
      }
    },
    scales: {
      yAxes: [{
        ticks: {
          //beginAtZero:true,
          autoSkip: true,
          min: minTick,
          maxTicksLimit: tickDifference,
          max: maxTick
        },
        scaleLabel: {
          boxWidth: 50,
          fontSize: 12,
          fontColor: '#3DAB35',
          padding: 10,
          display: true,
          labelString: 'No of Loads',
          fontStyle: "bold",
        }
      }],
      xAxes: [{
        scaleLabel: {
          boxWidth: 50,
          fontSize: 12,
          fontColor: '#3DAB35',
          padding: 10,
          display: true,
          labelString: 'Year / Month / Day',
          fontStyle: "bold",

        }
      }]
    }

  }

  const lineData = {
    labels: line_chart_labels,
    datasets: [
      {
        color: "#3DAB35",
        label: "Collected Loads",
        fill: false,
        lineTension: 0.1,
        borderColor: "#3DAB35",
        pointRadius: 5,
        pointBorderColor: "#3DAB35",
        pointBackgroundColor: "#3DAB35",
        data: line_chart_data,
      }
    ]
  };
  const columnsDataUlb = ["ULBName", "Count", "ULBType"]
  const ULBBarData = {
    labels: ["Host", "Cluster"],
    datasets: [
      {
        label: 'Loads',
        data: [ulbCount, clusterCount],
        backgroundColor: '#52AC36',
        borderWidth: 3,
        width: 100
      }
    ],

  }
  const BarOptions = {
    legend: {
      position: "top",
      align: "center",
      labels: {
        fontColor: 'black',
        fontFamily: 'sans-serif',
        defaultFontSize: 120,
      }
    },
    ticks: {
      autoSkip: false
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true
          },
          scaleLabel: {
            boxWidth: 50,
            fontSize: 15,
            fontColor: '#3DAB35',
            padding: 10,
            display: true,
            labelString: 'No of Loads',
            fontStyle: "bold",

          },
          gridLines: {
            drawOnChartArea: false,
            drawTicks: true
          }

        }
      ],

      xAxes: [{
        barPercentage: 0.6,
        scaleLabel: {
          boxWidth: 50,
          fontSize: 15,
          fontColor: '#3DAB35',
          padding: 10,
          display: true,
          labelString: 'ULB/Cluster Loads',
          fontStyle: "bold",

        },
        gridLines: {
          // display: true,
          // drawBorder: true,
          drawOnChartArea: false,
        },

      }]
    },
  }

  const ULBBarDataHistory = {
    labels:line_chart_labels,
    datasets: [
      {
        label: 'Utilization %',
        data:UtilizationRate ,
        backgroundColor: '#52AC36',
        // borderColor:"#52AC36",
        type: "bar",
        yAxisID: "UtilizationData",
        order: 2
      },
      {
        label: 'Loads',
        data: loadCount,
        borderColor: 'Blue',
        backgroundColor: "white",
        type: "line",
        yAxisID: "loadsData",
        borderWidth: 3,
        order: 1

      }
    ],

  }
  const BarOptionsHistory = {
    legend: {
      position: "top",
      align: "center",
      labels: {
        fontColor: 'black',
        fontFamily: 'sans-serif',
        defaultFontSize: 120,
      }
    },
    ticks: {
      autoSkip: false
    },
    scales: {
      yAxes: [
        {
          id: "UtilizationData",
          position: "left",
          // type: 'linear',
          ticks: {
            beginAtZero: true
          },
          scaleLabel: {
            boxWidth: 50,
            fontSize: 15,
            fontColor: '#3DAB35',
            padding: 10,
            display: true,
            labelString: 'Utilization Rate (in %)',
            fontStyle: "bold",

          },
          gridLines: {
            drawOnChartArea: false,
            drawTicks: true
          }
        },
        {
          gridLines: {
            display: false
          },
          id: "loadsData",
          position: "right",
          // type: 'linear',
          display: true,
          ticks: {
            beginAtZero: true
          },
          dataLabels: {
            enabled: true,
            enabledOnSeries: [1]
          },
          stroke: {
            width: [0, 4]
          },
          scaleLabel: {
            boxWidth: 50,
            fontSize: 15,
            fontColor: '#3DAB35',
            padding: 10,
            display: true,
            labelString: 'Loads',
            fontStyle: "bold",
            opposite: true,

          },
        }
      ],

      xAxes: [{
        barPercentage: (ulbNameHistory.length == 1) ? 0.2 : (ulbNameHistory.length == 2 || ulbNameHistory.length == 3) ? 0.4 : 1,
        scaleLabel: {
          boxWidth: 50,
          fontSize: 15,
          fontColor: '#3DAB35',
          padding: 10,
          display: true,
          labelString: "Year / Month / Day",
          fontStyle: "bold",
          // barPercentage: 0.2
        },
        // barThickness: 35,
        gridLines: {
          // display: true,
          // drawBorder: true,
          drawOnChartArea: false,
        }
      }]
    },
  }

  if (sortOrder === 'DESC') {
    switch (currentSortId) {
      case "lastUpdated":
        rows.sort(function (a, b) {
          return new Date(a.lastUpdated) - new Date(b.lastUpdated);
        });
        break;
      case "quantityOfSeptageReceivedLitres":
        rows.sort(function (a, b) {
          return a.quantityOfSeptageReceivedLitres - b.quantityOfSeptageReceivedLitres;
        });
        break;
      case "noOfLoadsReceived":
        rows.sort(function (a, b) {
          return a.noOfLoadsReceived - b.noOfLoadsReceived;
        });
        break;
      case "utilizationRateIn":
        rows.sort(function (a, b) {
          return a.utilizationRateIn - b.utilizationRateIn;
        });
        break;
        case "ulbName":
          rows.sort(function (a, b) {
            return a.ulbName.localeCompare(b.ulbName);
          });
          break;
          case "designCapacityInLitres":
          rows.sort(function (a, b) {
            return a.designCapacityInLitres - b.designCapacityInLitres;
          });
          break;
      default:
        break;
    }
  }
  if (sortOrder === 'ASC') {
    switch (currentSortId) {
      case "lastUpdated":
        rows.sort(function (a, b) {
          return new Date(b.lastUpdated) - new Date(a.lastUpdated);
        });
        break;
      case "quantityOfSeptageReceivedLitres":
        rows.sort(function (a, b) {
          return b.quantityOfSeptageReceivedLitres - a.quantityOfSeptageReceivedLitres;
        });
        break;
      case "noOfLoadsReceived":
        rows.sort(function (a, b) {
          return b.noOfLoadsReceived - a.noOfLoadsReceived;
        });
        break;
      case "utilizationRateIn":
        rows.sort(function (a, b) {
          return b.utilizationRateIn - a.utilizationRateIn;
        });
        break;
        case "ulbName":
          rows.sort(function (a, b) {
            return b.ulbName.localeCompare(a.ulbName);
          });
          break;
        case "designCapacityInLitres":
          rows.sort(function (a, b) {
            return b.designCapacityInLitres - a.designCapacityInLitres;
          });
          break;
          
      default:
        break;
    }
  }
  return (
    <div className="dashboard_new_listing">

      <Paper className={classes.root}>
        <Grid container spacing={3} direction="row">
          <Grid item xs={12} align="right">
            <Button onClick={handleExcalDownload} variant="outlined" style={{ color: "#3DAB35", marginTop: '10px', marginBottom: '10px', marginRight: '10px', postion: 'fixed', display: 'block' }}>Download Excel</Button>
          </Grid>
        </Grid>
        {window.innerWidth <= 480 ?
          <Grid container spacing={0} direction="row">
            <Grid items xs={6} style={{ paddingLeft: '5px' }}>
              <TableContainer className={classes.containerMobile}>
                <Table stickyHeader aria-label="sticky table" style={{ tableLayout: 'fixed' }}>
                  <TableHead>
                    <TableRow>
                      {fixedColumns && fixedColumns.length > 0 && fixedColumns.map((column) => {
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth, fontWeight: 'bolder', fontSize: "12px" }}
                            className={classes.header}>
                            <div style={{
                              display: 'flex',
                              alignItems: 'start',
                              whiteSpace: 'break-spaces',
                              height: '3em',
                              marginLeft: '-10px',
                            }}>
                              <div onClick={(e) => handleSort(e, column, sortOrder)}>
                                {column.id == "" ? "" :
                                  <div>{
                                    currentSortId === column.id ? sortOrder === 'ASC' ? (<ArrowDropDownIcon fontSize="small" />) : (<ArrowDropUpIcon fontSize="small" />) : (<ArrowDropDownIcon fontSize="small" />)
                                  }
                                  </div>
                                }

                              </div>{column.label == "Utilization Rate In" ? <div>Utilization Rate In %</div> : column.label}
                            </div>
                          </TableCell>
                        )
                      }
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={rowIndex}>
                          {fixedColumns && fixedColumns.length > 0 && fixedColumns.map((column, index) => {
                            const value = row[column.id];
                            if (column.id === "ulbName") {
                              var oneValue = row[column.id];
                              var ulbId = row.ULBId
                              return <TableCell style={{ whiteSpace: "normal", wordWrap: "break-word", fontSize: "12px" }} className={"white-space"}><Link to={{ pathname: `/records/${ulbId}`, startDate: datevalue.startDate, endDate: datevalue.endDate }} style={{ textDecoration: 'none', color: '#3DAB35' }}>{oneValue}</Link></TableCell>
                            }

                            if (column.id === "action") {
                              var ulbChartId = row
                              return <TableCell><Button style={{ color: 'white', backgroundColor: '#3DAB35' }} onClick={() => handleOpenChart(ulbChartId)}>View Chart </Button> </TableCell>
                            }
                            return (
                              <TableCell key={index} align={column.align} style={{ whiteSpace: "normal", wordWrap: "break-word", fontSize: "12px" }} className={"white-space"}>
                                {value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={6} style={{ paddingRight: '5px' }}>
              <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => {
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth, fontWeight: 'bolder', fontSize: "12px" }}
                            className={classes.header}>
                            <div style={{
                              display: 'flex',
                              alignItems: 'start',
                              whiteSpace: 'break-spaces',
                              height: '3em',
                              marginLeft: '-10px',
                              width: "150px"
                            }}>
                              <div onClick={(e) => handleSort(e, column, sortOrder)}>
                                {column.id == "" ? "" :
                                  <div>{
                                    currentSortId === column.id ? sortOrder === 'ASC' ? (<ArrowDropDownIcon fontSize="small" />) : (<ArrowDropUpIcon fontSize="small" />) : (<ArrowDropDownIcon fontSize="small" />)
                                  }
                                  </div>
                                }

                              </div>
                              {column.label}
                            </div>
                          </TableCell>
                        )
                      }
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={rowIndex}>
                          {columns.map((column, index) => {
                            const value = row[column.id];
                            if (column.id === "ulbName") {
                              var oneValue = row[column.id];
                              var ulbId = row.ULBId
                              return <TableCell><Link to={{ pathname: `/records/${ulbId}`, startDate: datevalue.startDate, endDate: datevalue.endDate }} style={{ textDecoration: 'none', color: '#3DAB35' }}>{oneValue}</Link></TableCell>
                            }
                            if (column.id === "action") {
                              var ulbChartId = row
                              return <TableCell><Button style={{ color: 'white', backgroundColor: '#3DAB35' }} onClick={() => handleOpenChart(ulbChartId)}>View Chart </Button> </TableCell>
                            }
                            return (
                              <TableCell key={index} align={column.align} style={{ whiteSpace: "normal", wordWrap: "break-word", fontSize: "12px" }} className={"white-space"}>
                                {value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid> :
          <Grid container spacing={3} direction="row">
            <Grid item xs={12} lg={12}>
              <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => {
                        if (props.payloadDExcal.category == "2") {
                          if (column.id == "utilizationRateIn") {
                            column.label = "% of FS added to STP capacity"
                          }
                        }
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth, fontWeight: 'bolder', fontSize: "12px" }}
                            className={classes.header}>
                            <div style={{
                              display: 'flex',
                              alignItems: 'start',
                              whiteSpace: 'break-spaces',
                              height: '3em',
                              marginLeft: '10px',
                              width: "150px"
                            }}>
                              <div onClick={(e) => handleSort(e, column, sortOrder)}>
                                {column.id == "action" || column.id == "truckCountLicensed" || column.id == "truckCount" || column.id == "operatorCount" ? "" :
                                  <div>{
                                    currentSortId === column.id ? sortOrder === 'ASC' ? (<ArrowDropDownIcon fontSize="small" />) : (<ArrowDropUpIcon fontSize="small" />) : (<ArrowDropDownIcon fontSize="small" />)
                                  }
                                  </div>
                                }
                              </div>{column.label}
                            </div>
                          </TableCell>
                        )
                      }
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={rowIndex}>
                          {columns.map((column, index) => {
                            const value = row[column.id];
                            if (column.id === "ulbName") {
                              var oneValue = row[column.id];
                              var ulbId = row.ULBId
                              return <TableCell>
                                <div style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => handleOpenMechanism(ulbId)}>{oneValue} </div>

                              </TableCell>
                              //  return <TableCell><Link to={{pathname:`/records/${ulbId}`, startDate:datevalue.startDate, endDate:datevalue.endDate }}  style={{textDecoration:'none' ,color:'#3DAB35'}}>{oneValue}</Link></TableCell>
                            }
                            if (props.payloadDExcal.category == "2") {
                              if (column.id == "utilizationRateIn") {
                                column.label = "% of FS added to STP capacity"
                              }
                            }

                            if (column.id === "action") {
                              var ulbChartId = row
                              return <TableCell><Button style={{ color: 'white', backgroundColor: '#3DAB35' }} onClick={() => handleOpenChart(ulbChartId)}>View History </Button> </TableCell>
                            }
                            return (
                              <TableCell key={index} align={column.align} style={{ whiteSpace: "normal", wordWrap: "break-word", fontSize: "12px" }} className={"white-space"}>
                                {value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>

        }
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <div className="setFreq">
        <Dialog
          maxWidth={"md"}
          fullWidth={true}
          open={openChart}
          aria-labelledby="responsive-dialog-title">
          <DialogTitle className="text-center border-none " style={{ color: '#3dab35' }}><span style={{ fontSize: '18px' }}>History</span></DialogTitle>
          <DialogContent >
            <div className="create_ulb_content_outer">
              <div className="create_ulb_content_sched">
                <Grid container spacing={3}>

                  {props.payloadDExcal.category == "2" ? <Grid item xs={12}>
                    <Card height={500}><hr style={{ border: "1px solid #3DAB35", marginLeft: "5%", width: "30px" }}></hr><Line data={lineData} options={lineChartOptions} width={800} height={300} /></Card></Grid>
                    :
                    <Grid item xs={12}>
                      <Bar data={ULBBarDataHistory} options={BarOptionsHistory} width={900} height={300}></Bar>
                    </Grid>}
                </Grid>
              </div>
            </div>
          </DialogContent>
          <DialogActions className="leftp">
            <Button autoFocus onClick={handleFreqClose} style={{
              background: 'none',
              color: '#3dab35',
              border: " 1px solid rgba(61, 171, 53, 0.5)",
            }}
              type="submit">Cancel
            </Button>

          </DialogActions>
        </Dialog>
      </div>

      <div className="setFreq">
        <Dialog
          maxWidth={'sm'}
          fullWidth={true}
          open={openMechanism}
          aria-labelledby="responsive-dialog-title">
          <DialogTitle className="text-center border-none " style={{ color: '#3dab35' }}><span style={{ fontSize: '18px' }}>Cluster Mechanism</span></DialogTitle>
          <DialogContent >
            <div className="create_ulb_content_outer">
              <div className="create_ulb_content_sched">
                <Grid container spacing={3}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <h3>Please choose from the below options </h3>
                    <div style={{ display: "flex", marginTop: "10px" }}>
                      <span>1.</span>  <Link to={{ pathname: `/records/${selectedULBid}`, startDate: datevalue.startDate, endDate: datevalue.endDate }} style={{ textDecoration: 'none', color: '#3DAB35' }}>     <div > Loads</div> </Link>

                    </div>

                    <div style={{ display: "flex", marginTop: "10px" }}>
                      <span>2.</span>
                      <div onClick={() => handleClusterData(selectedULBid)} style={{ color: '#3DAB35', cursor: "pointer" }}> ULB/Cluster Data</div>

                    </div>

                  </div>

                </Grid>
              </div>
            </div>
          </DialogContent>
          <DialogActions className="leftp">
            <Button autoFocus onClick={handleCloseMechanism} style={{
              background: 'none',
              color: '#3dab35',
              border: " 1px solid rgba(61, 171, 53, 0.5)",
            }}
              type="submit">Cancel
            </Button>

          </DialogActions>
        </Dialog>
      </div>

      {/* cluster Graph & table  */}
      <div className="setFreq">
        <Dialog
          maxWidth={'md'}
          fullWidth={true}
          open={openClsuterChart}
          aria-labelledby="responsive-dialog-title">
          <DialogTitle className="text-center border-none " style={{ color: '#3dab35' }}><span style={{ fontSize: '18px' }}>ULB/Cluster's Loads</span></DialogTitle>
          <DialogContent >
            <div className="create_ulb_content_outer">
              <div className="create_ulb_content_sched">
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Paper style={{ height: "500" }}><hr style={{ border: "1px solid #3DAB35", marginLeft: "5%", width: "30px" }}></hr>
                      <Bar data={ULBBarData} options={BarOptions} width={800} height={300} align="center"></Bar></Paper>
                  </Grid>

                  <Grid item xs={12}>
                    <Paper><hr style={{ border: "1px solid #3DAB35", marginLeft: "5%", width: "30px" }}></hr><BasicTableArea rows={dataCls} columnsDataUlb={columnsDataUlb} /></Paper>
                  </Grid>
                </Grid>
              </div>
            </div>
          </DialogContent>
          <DialogActions className="leftp">
            <Button autoFocus onClick={handleCloseClusterData} style={{
              background: 'none',
              color: '#3dab35',
              border: " 1px solid rgba(61, 171, 53, 0.5)",
            }}
              type="submit">Cancel
            </Button>

          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
