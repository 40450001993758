import React from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell'; 
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ReactPaginate from 'react-paginate';
// import Grid from '@material-ui/core/Grid';
import {Link,} from 'react-router-dom'
import Button from '@material-ui/core/Button';
import {connect } from 'react-redux';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import * as actionsCreators from  '../../store/actions/index';
import InputBase from '@material-ui/core/InputBase';
import swal from 'sweetalert';
import { Dialog,  } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';  
import TextField from '@material-ui/core/TextField';
import axios from '../../http-config';

class ZoneListing extends React.Component {
    constructor(props) {
        super(props)
        this.state={
            id:"",
            actionMenuOpen : false,
            addActionData : {},
            anchorEl:null,
            open:false,
            limit:10,
            currentSortId:'',
            sortObj : {
                key: '',
                value: ''
            },
            page:1,
            anchorEl_bulk_area             : null,
            placement_area                : '',
            open_bulk_area                : false,

            filterByType       : "",
            isAll:false,
        filterValue : '',
        controls: {
            search: {
                value: '',
            }
        },


        openPop: false,
        editId:"",
        zoneNameTm                 : "",
            zoneName               : "",
            zoneNameErr            : '',
            zoneNameTmErr          : ''  ,
           ulbOptions              : [],
           ulbName                 : "",

        //zone Edit
        zName :"",
        zTamilName:"",
        zUlbId :"",



        }
    }
    componentDidMount(){

        axios({
            method: 'get',
            url: '/load_template/master_data',
            headers: { 'Authorization': `Bearer ${this.props.token}` }
        })
            .then((response) => {
                if (response.data.data) {
                    this.setState({
                        ulbOptions : response.data.data['master-data'][3].data,
                    })
                }

            })
      let  payload ={
        token:this.props.token,
        page:  this.state.page,
        limit: this.state.limit,
        sortName:this.state.sortObj.key,
        sortValue : this.state.sortObj.value,
        zoneName: this.state.filterValue,
        searchQuery: this.state.controls.search.value,
      }
        this.props.onfetchZoneHeaders(payload)
        this.props.onZonesList(payload);

    }

    handleActionButton = (event,zoneId,zUlbId,  zName  ,zTamilName)=>{
        this.setState({open:!this.state.open,anchorEl:event.currentTarget,zoneId, zUlbId,  zName  ,zTamilName})
    }
    handleClickAwayEdit = () => {
        this.setState({open:false});
        };

    handleClickAway = () => {
        this.setState({open:false});
    };

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        this.setState({page :selectedPage+1})
        let payload = {
            token: this.props.token,
            page:  selectedPage + 1,
            limit: this.state.limit,
            sortName:this.state.sortObj.key,
            sortValue : this.state.sortObj.value,
            zoneName:this.state.filterValue,
        }
        this.props.onZonesList(payload)
    };

    handleSort = (e,sortBy) => {
        let sortObj
        if (this.state.sortObj.key === sortBy.accessor) {
            let value = (this.state.sortObj.value === 'DESC') ? 'ASC' : 'DESC';
            sortObj = {
                key : sortBy.accessor,
                value: value
            }
        } 
        else {
            sortObj = {
                key : sortBy.accessor,
                value: 'DESC'
            }    
            }
    
        this.setState({ sortObj: sortObj ,currentSortId:sortBy.accessor});
        let payload = {
            token: this.props.token,
            sortName:sortObj.key,
            sortValue : sortObj.value,
            page:  this.state.page,
            limit: this.state.limit,
            zonename: this.state.filterValue,
            searchQuery: this.state.controls.search.value
        }
        this.props.onZonesList(payload);
    
          }
          handleClickArea = (newPlacement) => (event) => {
            this.setState({ anchorEl_bulk_area:event.currentTarget, placement_area:newPlacement, open_bulk_area:!this.state.open_bulk_area});
        };
        handleClickAwayBulkArea = () => {
            this.setState({open_bulk_area: false});
        };
   handleExport = () => {
        let payload = {
            token:this.props.token,
            page:  1,
            limit: this.state.limit,
            sortName:this.state.sortObj.key,
            sortValue : this.state.sortObj.value,
      
        }
        this.props.onFetchZoneListingExport(payload)
    }

        // Function to Get Filtered Values on click
        onClickGetFilterVals = (e, colObj) => { 
            this.setState({filterValue: e})
        let payload = {
            token: this.props.token,
            page:  this.state.page,
            limit: this.state.limit,
            zoneName: e,
            sortName:this.state.sortObj.key,
            sortValue :this.state.sortObj.value,
            searchQuery: this.state.controls.search.value
        }
        this.props.onZonesList(payload)
        }   

        inputChangedHadler = (event, controlName) => {
            const updatedControls = {
                ...this.state.controls,
                [controlName]: {
                    ...this.state.controls[controlName],
                    value: event.target.value,
                }
            }
            this.setState({ controls: updatedControls });
            if( updatedControls.search.value === ''){
                let payload = {
                    token: this.props.token,
                    searchQuery:'',
                    limit:this.state.limit,
                    page:this.state.page,
                    zoneName: this.state.filterValue,
                    sortName:this.state.sortObj.key,
                    sortValue :this.state.sortObj.value,
                }
                this.props.onSearchZone(payload, '', '') 
            } 
        }
    
        // Function to handle Zone search 
        handleZoneSearch = (e) => {
            e.preventDefault()
            let searchVal = this.state.controls.search.value
            if (!!searchVal && searchVal.length >= 2) {
                let payload = {
                    token: this.props.token,
                    searchQuery:searchVal,
                    limit:10,
                    page:this.state.page,
                    zoneName: this.state.filterValue,
                    sortName:this.state.sortObj.key,
                    sortValue :this.state.sortObj.value,
                  
                }
                this.props.onSearchZone(payload, '', '') 
            } else {
             
                swal("Enter atleast 2 characters")
            }
        }

        OpenPop = () =>{
            this.setState({
                openPop             :true,
                editId                  :"",
                zoneNameTm              : "",
                zoneName               : "",
                zoneNameErr            : '',
                zoneNameTmErr          : ''  ,
                ulbName                 : "",
                
            })
           
    
        }
        handleopeneditZone = (id,zUlbId,  zName  ,zTamilName ) => {
            this.setState({
                openPop: true,
                editId: id,
                ulbName :zUlbId,
                zoneName   :zName,
                zoneNameTm: zTamilName,
                
            })
        }

        ClosePop = ()=> {
            this.setState({
                openPop             :false,
                editId                  :"",
                zoneNameTm              : "",
                zoneName               : "",
                zoneNameErr            : '',
                zoneNameTmErr          : ''  ,
                ulbName                 : "",
            })
    
        }

        handleSubmit = (e) =>{
            const {t, i18n} = this.props
            let props=this.props

            const err = this.checkValidity()
            let  payload ={
                token:this.props.token,
                page:  this.state.page,
                limit: this.state.limit,
                sortName:this.state.sortObj.key,
                sortValue : this.state.sortObj.value,
                zoneName: this.state.filterValue,
                searchQuery: this.state.controls.search.value,
              }
          if(!err){
            if(this.state.editId != ""){
            const formdata={
                token       : this.props.token,
                id         : this.state.zoneId,
                Name       : this.state.zoneName,
                Name_tn    : this.state.zoneNameTm,
                ulbId    : this.state.ulbName,
              //  ulbId       : this.state.ULBId
            }
        
            this.props.onEditZone(formdata,this.props, payload)
            this.setState({openPop:false})
            }
            else{
                const formdata={
                    token      : this.props.token,
                    ulbId    : this.state.ulbName,
                    Name       : this.state.zoneName,
                    Name_tn    : this.state.zoneNameTm,
                    
                }
        
            this.props.onAddZone(formdata,this.props, payload)
            this.setState({openPop:false})   
            }
        }
        }
      
        handleChange = (e) => {
            this.setState({[e.target.name] : e.target.value}, ()=>{
                this.checkValidity()
            })   
        }

        checkValidity = () => {
            let isError = false
            if(!this.state.zoneName){
                isError=true
                this.setState({zoneNameErr:'*This field is required'})
            }
            // if(!this.state.zoneNameTm){
            //     isError=true
            //     this.setState({zoneNameTmErr : "*This field is required"})
            // }
            return isError
        }


    render() {
        let updatedHeaders = this.props.headers

        // Filtering values while typing  
        let filterValue
        const zoneTypeLists = this.props.Type
        const selectData = !!zoneTypeLists&&zoneTypeLists.map((item,i)=>{
              return  <option key={i} value={item.Id}>
                    {item.Name}
                </option>
                
        })
        const headers =  !!updatedHeaders && updatedHeaders.map(header => {

            let filterType
            if(header.filter == true){
                filterType = 
                <select 
                style={{
                    width: '16px',
                    borderColor:"white",
                    backgroundColor: 'LightGray',
                    outline:"none",
                    display: "flex",
                    alignItems: 'right',   
                  }}
                value={filterValue}
                onChange={(e) => {
                    this.onClickGetFilterVals(e.target.value, header)
                }}
                >
                    <option value="">All</option>
                    {selectData}
                </select>
            }
            {
                return <TableCell key={header.header} className={"white-space"}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        whiteSpace: 'break-spaces',
                        height:'3em'
                        }}>
                             <div onClick={(e) =>  this.handleSort(e,header)}>
                {header.accessor ==='actionButton' || header.accessor === 'Name_tn' ? '' :    <div>{
                this.state.currentSortId===header.accessor? this.state.sortObj.value ==='ASC'?(<ArrowDropDownIcon fontSize="small"/>):(<ArrowDropUpIcon fontSize="small"/>) :(<ArrowDropDownIcon fontSize="small"/>)}
                </div>}
                </div>
                        {header.header}
                        {filterType}
                    </div>
                </TableCell>
              }
          });

        const zones =!!this.props.ZoneListing&& this.props.ZoneListing.map((zone, j)=>{
        const zoneId = zone["Id"]
        const zUlbId = zone["ULBId"]
        const zName = zone["Name"]
        const zTamilName = zone["Name_tn"]
        return <TableRow style={{whiteSpace: 'normal',
        wordWrap: 'break-word'}} key={j}>{
        !!updatedHeaders&&updatedHeaders.map((header, i)=>{
        if(header.accessor=='actionButton'){
         return  <TableCell  key={i}>
            <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={(e)=>this.handleActionButton(e, zoneId, zUlbId, zName, zTamilName)}
            >
            <MoreVertIcon fontSize='small'/>
            </IconButton>
                <Popper
                    open={this.state.open} anchorEl={this.state.anchorEl}>
                    <ClickAwayListener onClickAway={this.handleClickAwayEdit}>
                    <Paper style={{boxShadow:"none", border:"1px solid #3dab35"}}>
                        <MenuList>
                            {/* <Link to={`./edit_zone/${this.state.zoneId}`} className='email_link'> <MenuItem>Edit</MenuItem></Link> */}
                            <MenuItem className='email_link' style={{ color: '#3dab35' }} onClick={() => this.handleopeneditZone(
                                                        this.state.zoneId,
                                                        this.state.zUlbId,
                                                        this.state.zName,
                                                        this.state.zTamilName,                                                   
                                                        )}>     Edit
                                                        
                                                        </MenuItem>
                            <Link to={`./viewWard/${this.state.zoneId}`} className='email_link'> <MenuItem>View Ward</MenuItem></Link>
                        </MenuList>
                        <MenuList>
                        {/* <Link to={`./property_loads/${this.state.propertyId}`} className='email_link'> <MenuItem>Show Loads</MenuItem></Link> */}
                        </MenuList>
                    </Paper>
                    </ClickAwayListener>
                </Popper>
            </TableCell>
        }
        else{
            return  <TableCell style={{
                whiteSpace: "normal",
                wordWrap: "break-word"
                }} key={i} className={"white-space"}>{zone[header.accessor] == null || zone[header.accessor] == 'null' ? ' ' : zone[header.accessor]}</TableCell>
            }
         }) 
        }
        </TableRow>
        }) 
        return (
            <div>
                <div className="dashboard_right_content" style={{marginTop:'-4%'}}>
                    <img className="dashboard_right_content_top_icon" src="images/Rectangle.svg" />
                    <div className="dashboard_right_content_top">
                    <h5> Zone Listing</h5>
                   
                        <div className="dashboard_right_content_top_options">
                            {/*Adding Search option for zone  */}
                        <div className="dashboard_right_content_top_search">
                                <Paper component="form">
                                    <IconButton aria-label="menu">
                                        <img src="images/search_icon.svg" />
                                    </IconButton>
                                    <InputBase onChange={(event) => this.inputChangedHadler(event, 'search')} placeholder="Zone Name" />
                                    <Button onClick={this.handleZoneSearch} variant="contained" color="primary" type="submit">Search</Button>
                                </Paper>
                            </div>

                        {localStorage.getItem('isZoneNotRequired') === 'true'? <Link className="email_link" to='/ward-management'><Button>Ward Listing</Button></Link> :<> 
                            <Link className="email_link" to='/add_zone'><Button>Add Zone</Button></Link> 
                            <div>
                            <Button style={{width:'100%', marginLeft:'10px' }} onClick={this.handleExport}>Export</Button>
                            </div></> }
                        <>
                        <Button className="email_link"
                                ref={this.state.anchorRef}
                                aria-controls={this.state.open ? 'menu-list-grow' : undefined}
                                aria-haspopup="true"
                                onClick={this.handleClickArea('bottom')}
                                style={{height:'35px', marginLeft:'20px'}}>
                              Wards&nbsp;
                                {
                                    this.state.open ?   <ArrowDropUpIcon /> : <ArrowDropDownIcon/> 
                                }
                                
                            </Button>
                            <Popper style={{zIndex:"10"}} open={this.state.open_bulk_area} anchorEl={this.state.anchorEl_bulk_area}  transition>
                            {({ TransitionProps }) => (
                                    <ClickAwayListener onClickAway={this.handleClickAwayBulkArea}>
                                <Paper style={{zIndex:1, border:"1px solid #3dab35"}}>
                                    <MenuList>
                                    <Link className="email_link"  to={`/viewWard/all`} ><MenuItem> UnAssigned Ward</MenuItem> </Link>
                                
                                    <Link className="email_link" to='/add_ward'> <MenuItem >Add Ward</MenuItem></Link>  
                                 
                                    </MenuList>
                                </Paper>
                                </ClickAwayListener>
                            )}
                            </Popper>
                            </>  
                        {/* <Link className="email_link" to='/add_ward'><Button>Add Ward</Button></Link>    */}
                        </div>
                    </div>
                </div>
                <div className="dashboard_listing AreaLIsting scrollward" >
                        <TableContainer component={Paper} style={{paddingLeft:"15px",paddingRight:"15px"}}>
                             <Table stickyHeader aria-label="sticky table" style={{ tableLayout: 'fixed' }}>
                                <TableHead >
                                    <TableRow >
                                        {headers}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                 {zones}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                       {
                this.props.totalCount && this.props.totalCount != 0 && 
                    <ReactPaginate
                    previousLabel={"Prev"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount = {this.props.totalCount/this.state.limit}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    style={{borderLeftWidth:'0px'}}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}/>
                }

              
                    <div className="setFreq">
                    <Dialog
                        maxWidth={'md'}
                        fullWidth={true}
                        // open={true}
                        open={this.state.openPop}
                        aria-labelledby="responsive-dialog-title">
                        <DialogTitle className="text-center border-none " style={{ color: '#3dab35' }}><span style={{ fontSize: '18px' }}>{this.state.editId != "" ? "Edit Zone": "Add New Zone" } </span></DialogTitle>
                        <DialogContent >
                            <div className="create_ulb_content_outer">
                                <div className="create_ulb_content create_ulb_contentPop">
                                    <Grid container spacing={1}>
                                    <Grid item xs={6}>
                <FormControl>
                    <label className="required" htmlFor="ulbName" align="left">ULB Name</label>
                    <Select value={this.state.ulbName} onChange={this.handleChange} name="ulbName">
                                {!!this.state.ulbOptions&&this.state.ulbOptions.map((option) => (
                                    <MenuItem key={option.id}value={option.id}>{option.name}</MenuItem>
                                ))}
                            </Select> 

                        <div  style={{fontSize:12,color:"red"}}>{this.state.ulbNameErr}</div>   
                </FormControl>
            </Grid>

                        <Grid item xs={6}>
                        <FormControl>
                            <label className="required" htmlFor="zoneName">Zone Name</label>
                            <TextField id="zoneName" 
                                    variant="outlined" 
                                    placeholder="Enter Zone Name" 
                                    name="zoneName" 
                                    value={this.state.zoneName} 
                                    onChange={this.handleChange}/>
                            <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.usernameErr}</div>
                        </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                        <FormControl>
                            <label htmlFor="zoneNameTm">Zone Name(Tamil)</label>
                            <TextField id="zoneNameTm" 
                                variant="outlined" 
                                placeholder="மண்டல பெயரை உள்ளிடவும்" 
                                name="zoneNameTm" 
                                value={this.state.zoneNameTm} 
                                onChange={this.handleChange}/>
                            <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.emailErr}</div>
                        </FormControl>
                        </Grid>
                               
                        </Grid>

                                </div>
                            </div>


                        </DialogContent>
                        <DialogActions>
                            {this.state.openPop === true ?
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <div className="create_ulb_buttons" style={{ marginBottom: '10px' }}>
                                            <Button className="login_submit" variant="outlined" color="primary" onClick={this.ClosePop}>Cancel</Button>
                                            <Button className="login_submit" variant="contained" color="primary" onClick={() => this.handleSubmit()}>{this.state.editId != "" ? `Save Changes` : `Save`}</Button>
                                        </div>
                                    </Grid>
                                </Grid> : ""}
                        </DialogActions>
                    </Dialog>
                    </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
        return {
        token: state.auth.token,
        headers:state.ZoneListingreducer.ZoneHeader && state.ZoneListingreducer.ZoneHeader.concat({header: "Action", accessor: "actionButton", sortable: "false", filter: "true"}),
        ZoneListing: state.zoneReduce.zone,
        totalCount:state.zoneReduce.totalCount
       
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onfetchZoneHeaders: (payload) => dispatch(actionsCreators.fetchZoneHeaders(payload)),
        onZonesList : (payload)=>dispatch(actionsCreators.ZonesList(payload)),
        onFetchZoneListingExport :(payload)=>dispatch(actionsCreators.fetchZoneListingExport(payload)),
        onSearchZone:(payload, url,type)=>dispatch(actionsCreators.searchZone(payload, url, type)),
        onEditZone : (formdata,props, payload) => dispatch(actionsCreators.editZone(formdata,props, payload)),
        onAddZone : (payload,props) => dispatch(actionsCreators.addZone(payload,props))
    }
}

export default  connect(mapStateToProps, mapDispatchToProps)(ZoneListing);