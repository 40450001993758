import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';


import { Card ,CardHeader,CardContent , IconButton, Avatar} from '@material-ui/core';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import CardIcon from './Card/CardIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export default function AutoGrid() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs>
         <Card>
            <CardContent>
           
            <CardIcon color="warning">
            <LocalShippingOutlinedIcon />
            </CardIcon>
            
                    <span>Number of Loads</span>
            </CardContent>
         </Card>
        </Grid>
        <Grid item xs>
         <Card>
            <CardContent>
                    <h2>Number of Loads</h2>
            </CardContent>
         </Card>
        </Grid>
        <Grid item xs>
         <Card>
            <CardContent>
                    <h2>Number of Loads</h2>
            </CardContent>
         </Card>
        </Grid>
      
         
      
      </Grid>
    </div>
  );
}
