
import * as actionTypes from '../../actions/actionsTypes';
import { updateObject } from '../../utility';

const initialState = {
    totalCount:'',
    loading: false,
    wards:[]
}

const fetchWardListingStart = (state, action) => {
    return updateObject(state, {
        loading: action.wardload,
    });
}

const fetchWardListingdSucces = (state, action) => {
    return updateObject(state, {
        totalCount:action.totalCount,
        wards:action.WardProperty,
        loading: action.wardload,
    });
}

const fetchWardListingFail = (state, action) => {
    return updateObject(state, {
        loading:  action.wardload,
    })
}



const WardReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_WARD_LISTING_START: return fetchWardListingStart(state, action);
        case actionTypes.FETCH_WARD_LISTING_SUCCESS: return fetchWardListingdSucces(state, action);
        case actionTypes.FETCH_WARD_LISTING_FAIL: return fetchWardListingFail(state, action);
        default: return state;
    }
}

export default WardReducer;