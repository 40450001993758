import React, { Component, useEffect } from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../store/actions/index'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import swal from 'sweetalert'
import moment from 'moment'
import Chip from '@material-ui/core/Chip';
import InputBase from '@material-ui/core/InputBase';
import ReactPaginate from 'react-paginate';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { Grid, } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from '../../http-config'
import { STATEDASHBOARDUSER , ULBADMIN, ADMIN} from '../../../src/config/constant'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      listStyle: 'none',
      padding: theme.spacing(0.5),
      margin: 0,
      height:"150px",
      overflowY:"scroll"
    },
    chip: {
      margin: theme.spacing(0.5),
    },
  }));

export const UserListing = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [id, setId] = React.useState('');
    const [limit,setLimit] = React.useState(10);
    const [page,setPage] = React.useState(1);
    const [sortName, setSortName] = React.useState('')
    const [sortValue, setSortValue] = React.useState('asc')
    const [filterValue , setfilterValue] = React.useState('')
    const [controls, setControls] = React.useState({ search: {
        value: '',
    }})
    const [currentSortId, setCurrentSortId] = React.useState('')
    const [openNewUser, setopenNewUser]= React.useState(false)
    const [username, SetUsername] =  React.useState('')
    const [email , SetEmail] =  React.useState('')
    const [role, setRole] = React.useState('')
    const [ulb, setUlb] = React.useState('')
    const [roleOptions, setRoleOptions] = React.useState([])
    const [ulbOptions, setUlbOptions] = React.useState([])
    const [usernameErr, setusernameErr] = React.useState('')
    const [emailErr, setEmailErr] =  React.useState('')
    const [roleErr, setRoleErr] = React.useState('')
    const [ulbErr, setulbErr] = React.useState('')
    const [roleName, setroleName] =  React.useState('')
    const [dashboard, setdashboard] = React.useState(false)
    const [iotdashboard, setiotdashboard] = React.useState(false)
    const [roleManagement, setroleManagement] = React.useState(false)
    const [clusterManagement, setclusterManagement] = React.useState(false)
    const [stateDashboard, setstateDashboard] = React.useState(false)
    const [WardManagement, setWardManagement] = React.useState(false)
    // const [statedashboard, setstatedashboard] = React.useState('')
    const [ulbManagement, setulbManagement] =  React.useState(false)
    const [userManagement, setuserManagement] = React.useState(false)
    const [propertyManagement, setpropertyManagement] = React.useState(false)
    const [loadsRecords, setloadsRecords] = React.useState(false)
    const [ScheduleProperties, setScheduleProperties] = React.useState(false)
    const [TreatmentFacilities, setTreatmentFacilities] =React.useState(false)
    const [OperatorManagement, setOperatorManagement] = React.useState(false)
    const [isModuleAccess, setIsModuleAccess] = React.useState([])
    const [searchBy , setSearchBy] =  React.useState('selectName')
    const [roleOpen, setroleOpen] = React.useState('')
    const [checkValue, setCheckValue] = React.useState(false)
    const [ selected, setSelected] =  React.useState([])
    const [open_bulk_property, setopen_bulk_property] =React.useState(false)
    const [ openRolePopup, setopenRolePopup] = React.useState(false)
    const [openNewRoleAdd, setopenNewRoleAdd]= React.useState(false)
    const [roleNameAdd, setroleNameAdd]= React.useState("")
    const [accessSite , setAccessSite] = React.useState("")
    const [roleNameAddErr, setroleNameAddErr] = React.useState("")
    const [accessSiteErr, setaccessSiteErr] =  React.useState("")
    const [appAccess, setAppAccess] = React.useState(false)
    const [webAccess, setwebAccess] = React.useState(false)
    const [openIdPop, setopenIdPop] = React.useState('')
    const [edituserName, setedituserName] = React.useState('')
    const [editEmail , SeteditEmail] =  React.useState('')
    const [editRole, seteditRole] = React.useState('')
    const [editUlb, setEditUlb] = React.useState('')
    const [editunAssignable, seteditunAssignable] = React.useState('')
    const [unAssignable, seteunAssignable] = React.useState('')
    const [openEditUser, setopenEditUser]=React.useState(false)
    const [  isPasswordShown, setisPasswordShown] =React.useState(false)
    const [password, setpassword] =React.useState("")
    const [editRoleId, seteditRoleId] =React.useState("")
    const [roleId, setRoleId] = React.useState("")
    const [designation, setDesignation] = React.useState("")
    const [AroleName , setROleName] =React.useState(localStorage.getItem("roleName"))
    const [UserDesignation, setUserDesignation] = React.useState("")
    const [nameSelected, setNameSelected] = React.useState([])
    const classes = useStyles();
    useEffect(() => {
        const roleName = localStorage.getItem("roleName")
        props.onFetchUserHeaders(props.token)
        props.onFetchUserListing({token: props.token,limit:limit,page:page,sortName: sortName,sortValue: sortValue,  searchQuery:controls.search.value,   roleFilter:filterValue , roleName: AroleName})
        props.onFetchRole({sortName: '',sortValue : '',token: props.token,limit:limit,page:page,})


        if(roleName == ULBADMIN){
            axios({
                method: 'get',
                url: `/role/`,
                headers: { 'Authorization': `Bearer ${props.token}` }
            })
                .then((response) => {
                    setRoleOptions(response.data.data.filter((item)=> item.roleId != 6&&item.roleId != 2&&item.roleId != 1 &&item.roleId != 4 &&item.roleId != 8))
                })


            axios({
                method: 'get',
                url: '/load_template/master_data',
                headers: { 'Authorization': `Bearer ${props.token}` }
            })
                .then((response) => {
                    setUlbOptions(response.data.data['master-data'][3].data)
                })
        }
        else{
            axios({
                method: 'get',
                url: `/role/?admin=${true}`,
                headers: { 'Authorization': `Bearer ${props.token}` }
            })
            .then((response) => {
                    setRoleOptions(response.data.data.filter((item)=> 
                    {if(item.name == "State Dashboard"){
                        item.name = "State/Regional Officer"
                    }
                 return  item.roleId == 6 }))
            })
        axios({
            method: 'get',
            url: `ulb`,
            headers: { 'Authorization': `Bearer ${props.token}` }
         })
            .then((response) => {
                setUlbOptions(response.data.data.ulbs)
            })
        }
      
     
    }, [])
    const handleClickMoreVertIcon = (event,id, userName,email, role, ULBId, unAssignable , isModuleAccess, userDesignation, roleId) => {
        setOpen(!open)
        setAnchorEl(event.currentTarget)
        setId(id)
        setedituserName(userName)
        SeteditEmail(email)
        seteditRole(role)
        setEditUlb(ULBId)
        setRoleId(roleId)
        seteditunAssignable(unAssignable)
        setIsModuleAccess(isModuleAccess)
        setUserDesignation(userDesignation)
        
    }
    const handleClickAwayEdit = () => {
        setOpen(false)
    };
    const handleDelete = (id) => {
        const payload = {
            id : id,
            sortName: sortName,
            sortValue: sortValue,
            token: props.token,
            page:  page,
            limit: limit,
            searchQuery:controls.search.value ,
            roleFilter:filterValue,
            roleName: AroleName
            
        }
        const data = {
            id : id, 
        }
        props.onDeleteUser(payload, data, props)
    }

    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setPage(selectedPage + 1)
        let payload = {
            token: props.token,
            page:  selectedPage + 1,
            limit: limit,
            sortName: sortName,
            sortValue: sortValue,
            searchQuery:controls.search.value,
            roleFilter:filterValue,
            roleName:AroleName
        }
        props.onFetchUserListing(payload)
    };

  const  handleSort = (e,sortBy) => {
        let value
        if ( sortName=== sortBy.accessor) {
             value = (sortValue === 'desc') ? 'asc' : 'desc';
            setSortName( sortBy.accessor)
            setSortValue(value)
        } 
        else {
            setSortName( sortBy.accessor)
            setSortValue('desc')
            value = 'desc'
         }
         setCurrentSortId(sortBy.accessor)
        let payload = {
        token: props.token,
        sortName: sortBy.accessor,
        sortValue: value,
         page: page,
         limit: limit,
         searchQuery:controls.search.value,
         roleFilter:filterValue,
         roleName:AroleName

        }

        props.onFetchUserListing(payload);
  
      }


   const handlePropertySearch = (e) => {
        e.preventDefault()
        let searchVal = controls.search.value
        if (!!searchVal && searchVal.length >= 2) {
            let payload = {
                token: props.token,
                searchQuery:searchVal,
                limit:10,
                page:page,
                sortName: sortName,
                sortValue: sortValue,
                roleFilter:filterValue,
                roleName:AroleName

              
            }
            props.onSearchUserName(payload) 
        } else {
         
            swal("Enter atleast 2 characters")
        }
    }
  const  inputChangedHadler = (event, controlName) => {
        const updatedControls = {
            ...controls,
            [controlName]: {
                ...controls[controlName],
                value: event.target.value,
            }
        }
        setControls(updatedControls) ;
        if( updatedControls.search.value === ''){
            let payload = {
                token: props.token,
                searchQuery:'',
                limit:10,
                page:page,
                sortName: sortName,
                sortValue: sortValue,
                roleFilter:filterValue,
                roleName:AroleName

            }
            props.onSearchUserName(payload) 
        }
   
    }

   const ClickGetFilterVals = (e, colObj) => {
        let payload = {
            token: props.token,
            limit:limit,
            page:page,
            sortName: sortName,
            sortValue: sortValue, 
             searchQuery:controls.search.value,
            roleFilter:e,
            roleName:AroleName

        }
      setfilterValue(e)
      props.onFetchUserListing(payload);

       }  
    const handleExport = () => {
        let payload = {
            sortName: sortName,
            sortValue: sortValue,
            token: props.token,
            page:  page,
            limit: limit,
            searchQuery:controls.search.value ,
            roleFilter:filterValue,
            roleName:AroleName

      
        }
        props.onFetchUserListingExport(payload)
    }

    const openPopeditUserDetals = (idss, name, email, role, ulb, unassigned, ModuleAccess, EditUserDesignation, editRoleId)=>{
        setopenEditUser(true)
        setopenIdPop(idss)
        SetUsername(name)
        SetEmail(email)
        setRole(role)
        setUlb(ulb)
        seteunAssignable(unassigned)
        setroleName(role)
        seteditRoleId(editRoleId)
        setdashboard(ModuleAccess.IsDashboardAccess)
        setiotdashboard(ModuleAccess.IsIOTDashboardAccess)
        setroleManagement(ModuleAccess.IsRoleManagementAccess)
        setclusterManagement(ModuleAccess.IsClusterULBManagementAccess)
        setstateDashboard(ModuleAccess.IsStateDashboardAccess)
        setWardManagement(ModuleAccess.IsWardManagementAccess)
        setulbManagement(ModuleAccess.IsULBManagementAccess)
        setpropertyManagement(ModuleAccess.IsPropertyManagementAccess)
        setuserManagement(ModuleAccess.IsUserManagementAccess)
        setloadsRecords(ModuleAccess.IsLoadRecordAccess)
        setTreatmentFacilities(ModuleAccess.IsTreatmentFacilityAccess)
        setScheduleProperties(ModuleAccess.IsSchedulePropertyAccess)
        setOperatorManagement(ModuleAccess.IsOperatorManagementAccess)
        setDesignation(EditUserDesignation)

    
    
    }

    const handleOpenNewUser = ()=>{
        setopenEditUser(true)
        SetUsername('')
        SetEmail('')
        setRole('')
        setUlb('')
        setusernameErr('')
        setEmailErr('')
        setRoleErr('')
        setulbErr('')
        setopenIdPop('')
        setNameSelected([])
        setSelected([])
        setdashboard(false)
        setiotdashboard(false)
        setroleManagement(false)
        setclusterManagement(false)
        setstateDashboard(false)
        setWardManagement(false)
        setulbManagement(false)
        setpropertyManagement(false)
        setuserManagement(false)
        setloadsRecords(false)
        setTreatmentFacilities(false)
        setScheduleProperties(false)
        setOperatorManagement(false)      
        setDesignation("")


    } 
    const handleCloseEdituser =()=>{
        setopenEditUser(false)
        setopenIdPop('')
        SetUsername('')
        SetEmail('')
        setRole('')
        setUlb('')
        setusernameErr('')
        setEmailErr('')
        setRoleErr('')
        setulbErr('')
        setroleName('')
        setNameSelected([])
        setSelected([])
        setdashboard(false)
        setiotdashboard(false)
        setroleManagement(false)
        setclusterManagement(false)
        setstateDashboard(false)
        setWardManagement(false)
        setulbManagement(false)
        setpropertyManagement(false)
        setuserManagement(false)
        setloadsRecords(false)
        setTreatmentFacilities(false)
        setScheduleProperties(false)
        setOperatorManagement(false)
        setDesignation("")
    }
   const  checkValidityUser = () => {
        let isError = false
        if (!username) {
            isError = true
            setusernameErr(`${("This is required*")}`)
            // this.setState({ usernameErr: '*This field is required' })
        }
        else if ((username.length > 0 && username.length < 3) || (username.length > 25)) {
            isError = true
            setusernameErr(`${('*Name should be Minimum 3 characters and Maximum 25 characters')}`)
            // this.setState({ usernameErr: '*Name should be Minimum 3 characters and Maximum 25 characters' })
        }
        else {
            isError = false
            setusernameErr('')
            // this.setState({ usernameErr: '' })
        }
        if (!email) {
            isError = true
            setEmailErr(`${('*This field is required')}`)
            // this.setState({ emailErr: '*This field is required' })
        }
        else if (email) {
            var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
            if (!email.match(mailformat)) {
                isError = true
                setEmailErr(`${('*You have entered invalid email address')}`)
                // this.setState({ emailErr: '*You have entered invalid email address' })
            }
            else {
                isError = false
                setEmailErr('')
                // this.setState({ emailErr: '' })
            }
        }
      
        if (!role) {
            isError = true
            setRoleErr(`${("*This field is required")}`)
            // this.setState({ roleErr: "*This field is required" })
        }
        else {
            isError = false
            setRoleErr("")
            // this.setState({ roleErr: '' })
        }
        if (!(roleName == "State/Regional Officer") && !ulb) {
            isError = true
            setulbErr(`${("*This field is required")}`)
            // this.setState({ ulbErr: "*This field is required" })
        }
        else {
            isError = false
            setulbErr('')
            // this.setState({ ulbErr: '' })
        }


        // if(username){
        //     isError = true
        //     setpasswordErr(`{t("*This field is required")}`)  
        // }else{
        //     isError = false
        //     setpasswordErr('')  
        // }
        // if(username && password){
        //     if(!password.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/)){
        //         isError = true
               
        //             setpasswordErr(`${t("ulbPassErr")}`)
              
        //     }else{
        //         isError = false
        //         setpasswordErr('')  
        //     }
        // }
        return isError
    }
    const handleSubmitAdduser =(e)=>{
        e.preventDefault()
        const err = checkValidityUser()
        if (!err) {
            const formdata = {
                id : openIdPop,
                token: props.token,
                userName: username,
                limit:10,
                page:page,
                email: email,
                ULBList:JSON.stringify(selected),
                roleId:roleId,
                ulbId: !(roleName == "State/Regional Officer") ?ulb : null,
                Designation:designation,
                roleName: AroleName == "Admin" ? AroleName : editRole,
                isModuleAccess: {
                    "IsDashboardAccess":dashboard,
                    "IsPropertyManagementAccess":roleName == "ULB officer"?true:propertyManagement,
                    "IsSchedulePropertyAccess":roleName == "ULB officer"?true:ScheduleProperties,
                    "IsTreatmentFacilityAccess":TreatmentFacilities,
                    "IsOperatorManagementAccess":OperatorManagement,
                    "IsLoadRecordAccess":loadsRecords,
                    "IsWardManagementAccess":WardManagement,
                    "IsIOTDashboardAccess":iotdashboard,
                    "IsStateDashboardAccess":stateDashboard,
                    "IsULBManagementAccess":ulbManagement,
                    "IsRoleManagementAccess":roleManagement,
                    "IsUserManagementAccess":userManagement,
                    "IsClusterULBManagementAccess":clusterManagement
                }
            }
            if(openIdPop != ""){
                props.onEditUser(formdata, props)
                handleCloseEdituser()
            }
            else{
                props.onAddUser(formdata, props)
                handleCloseEdituser()
            }   
    }
}
    const handleCloseAddRole = ()=>{
        setopenNewRoleAdd(false)
        setroleNameAdd('')
        setAccessSite('')
    }

  const  handleChecked = (e) => {
        if(e.target.name =="dashboard"){
            setdashboard(e.target.checked)
        }
        if(e.target.name == "iotdashboard"){
            setiotdashboard(e.target.checked)
        }
        if(e.target.name  == "roleManagement"){
            setroleManagement(e.target.checked)

        }
        if(e.target.name  == "clusterManagement"){
            setclusterManagement(e.target.checked)

        }
        if(e.target.name  == "stateDashboard"){
            setstateDashboard(e.target.checked)

        }
        if(e.target.name  == "WardManagement"){
            setWardManagement(e.target.checked)

        }
        if(e.target.name  == "ulbManagement"){
            setulbManagement(e.target.checked)

        }
        if(e.target.name  == "userManagement"){
            setuserManagement(e.target.checked)

        }
        if(e.target.name  == "propertyManagement"){
            setpropertyManagement(e.target.checked)

        }
        if(e.target.name  == "loadsRecords"){
            setloadsRecords(e.target.checked)

        }
        if(e.target.name  == "ScheduleProperties"){
            setScheduleProperties(e.target.checked)

        }
        if(e.target.name  == "TreatmentFacilities"){
            setTreatmentFacilities(e.target.checked)

        }
        if(e.target.name  == "OperatorManagement"){
            setOperatorManagement(e.target.checked)

        }
        
    }


  const   handleChange = (e) => {
        if (e.target.name == 'role') {
            setRole(e.target.value)
            setroleName(e.target.value.name)
        } 
        if(e.target.name=="username"){
                SetUsername(e.target.value)
            }
        if(e.target.name == "email"){
                SetEmail(e.target.value)
            }
            if(e.target.name == "ulb"){
                setUlb(e.target.value)
            }
        if(e.target.name == "designation"){
            setDesignation(e.target.value)
        }


        
    }

const handleCheckboxClick = (event, id, ulbname) => {
        event.stopPropagation();
        if(event.target.checked == true && id !=""){
        setCheckValue(true)
        }
        else{
            setCheckValue(false)
        }
        const selectedIndex = selected.indexOf(id);
        const selectedNameIndex =nameSelected.indexOf(ulbname)
        let newSelected = [];
        let nameUlb = []
 
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
            nameUlb = nameUlb.concat(nameSelected, ulbname)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
            nameUlb = nameUlb.concat(nameSelected.slice(1))
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
            nameUlb = nameUlb.concat(nameSelected.slice(0, -1))

        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
            nameUlb = nameUlb.concat(nameSelected.slice(0, selectedNameIndex),
            nameSelected.slice(selectedNameIndex+1) )

        }
        setSelected(newSelected)
        setNameSelected(nameUlb)

        
        
    };

//  const  checkValidity = () => {
//         let isError = false
//         if (!this.state.username) {
//             isError = true
//             // this.setState({ usernameErr: '*This field is required' })
//         }
//         else if ((this.state.username.length > 0 && this.state.username.length < 3) || (this.state.username.length > 25)) {
//             isError = true
//             // this.setState({ usernameErr: '*Name should be Minimum 3 characters and Maximum 25 characters' })
//         }
//         else {
//             // this.setState({ usernameErr: '' })
//         }
//         if (this.state.email) {
//             var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
//             if (!this.state.email.match(mailformat)) {
//                 isError = true
//                 // this.setState({ emailErr: '*You have entered invalid email address' })
//             }
//             else {
//                 // this.setState({ emailErr: '' })
//             }
//         }
//         else if (!this.state.email) {
//             // this.setState({ emailErr: '*This field is required' })
//         }
//         if (!this.state.role) {
//             isError = true
//             // this.setState({ roleErr: "*This field is required" })
//         }
//         else {
//             // this.setState({ roleErr: '' })
//         }
//         if (!(this.state.roleName == STATEDASHBOARDUSER) && !this.state.ulb) {
//             // isError = true
//             // this.setState({ ulbErr: "*This field is required" })
//         }
//         else {
//             // this.setState({ ulbErr: '' })
//         }
//         return isError
//     }
const  onClickRemoveFilter = ()=>{
    let payload = {
        token: props.token,
        limit:limit,
        page:1,
        sortName: '',
        sortValue: sortValue, 
         searchQuery:'',
        roleFilter:'',
        roleName:AroleName
    }
    props.onFetchUserListing(payload);
}
    const userHeaders = props.userHeaders.map(header => {
            let filterType;
            if( header.accessor == 'role'){
               localStorage.getItem('roleName') === ADMIN ? header.filter = false : header.filter = true 
            }
            if(header.filter == true && header.accessor == 'role'){
                filterType = 
                <select 
                style={{
                    width: '16px',
                    borderColor:"white",
                    backgroundColor: 'LightGray',
                    outline:"none",
                    display: "flex",
                    alignItems: 'right',   
                  }}
                value={filterValue}
                onChange={(e) => {
                    ClickGetFilterVals(e.target.value , header)
                }}
                >
                    <option value="">&nbsp;All&nbsp;</option>
                    { !!props.roleData&&props.roleData.map(data => {
                        if(data.name == 'Admin'){
                            return ''
                        }
                       return <option  value={data.roleId}>{data.name}</option>
                   })}
                    {/* <option  value={"admin"}>Admin</option>
                    <option  value={"ULBAdmin"}>ULB Admin </option>
                    <option value={"StateDashboard"}>State/Regional Officer</option>
                    <option value={"scheduler"}>Scheduler</option>
                    <option value={"operator"}>Operator</option>
                    <option value={"TFAdmin"}>TF Admin</option> */}
                
                </select>
            }


        return(
            <TableCell key={header.header}>
            <div style={{
                display: 'flex',
                alignItems: 'start',
                whiteSpace: 'break-spaces',
                height: '3em'
            }}>
           {header.header === "Action"? "":
                    <div onClick={(e) => handleSort(e,header)}>
                        <div>{
                            currentSortId===header.accessor? sortValue ==='asc'?(<ArrowDropDownIcon fontSize="small"/>):(<ArrowDropUpIcon fontSize="small"/>) :(<ArrowDropDownIcon fontSize="small"/>)
                        }
                        </div>  
                    </div>}
                {header.header}
                {filterType}
            </div>
                </TableCell>
        )
    })
    
     const userData = props.userData.map(data => {
        const ID = data['userId']
        const userName = data['userName']
        const email = data['email']
        const role= data['role']
        const roleId= data['roleId']
        const ULBId = data['ULBId']
        const unAssignable = data['unAssignable']
        const isModuleAccess = data['isModuleAccess']
        const UserDesignation= data["Designation"]

        return(
            <TableRow key={data.userId}>
                {
                    props.userHeaders.map(header => {
                        if(header.accessor == 'actionButton'){
                            return <TableCell>
                                        <IconButton onClick={(e)=>handleClickMoreVertIcon(e,ID,userName,email, role, ULBId, unAssignable , isModuleAccess , UserDesignation, roleId)}
                                                    aria-label="more"
                                                    aria-controls="long-menu"
                                                    aria-haspopup="true">
                                            <MoreVertIcon fontSize='small'/>  
                                        </IconButton>
                                        <Popper open={open} anchorEl={anchorEl}>
                                            <ClickAwayListener onClickAway={handleClickAwayEdit}>
                                                <Paper style={{boxShadow: "none",border: "1px solid rgb(61, 171, 53)"}}>
                                                    <MenuList>
                                                    <MenuItem onClick={()=>openPopeditUserDetals(id, edituserName, editEmail, editRole, editUlb, editunAssignable, isModuleAccess, UserDesignation, roleId)}>{("Edit")}</MenuItem>

                                                        <MenuItem style = {{color: '#3dab35'}} onClick={() => {
                                                                swal({ title : "Are you sure?",
                                                                        text : "Once deleted, you will not be able to recover this data!",
                                                                        icon : "warning",
                                                                        buttons : {
                                                                        cancel : 'No',
                                                                        confirm: 'Yes'}
                                                                })
                                                                .then((willDelete) => {
                                                                    if (willDelete) {
                                                                        handleDelete(id);
                                                                    } 
                                                                });
                                                            }}>Delete</MenuItem>
                                                    </MenuList>
                                                </Paper>
                                            </ClickAwayListener>
                                        </Popper>
                                </TableCell>
                        }
                        else if(header.accessor=='updatedAt'){
                               return <TableCell style={{
                                whiteSpace: "normal",
                                wordWrap: "break-word"
                              }} key={header.accessor} >{ moment(data[header.accessor]).format('DD-MMM-YYYY')}</TableCell>
                           }
                        else {
                            return (
                                <TableCell style={{
                                    whiteSpace: "normal",
                                    wordWrap: "break-word"
                                  }}>{data[header.accessor]}</TableCell>
                            )
                        }
                    })
                }
            </TableRow>
        )
    })
    return (
        <div>
            <div className="dashboard_right_content" style={{marginTop:'-4%'}}>
                <img className="dashboard_right_content_top_icon" src="images/Rectangle.svg" />
                <div className="dashboard_right_content_top">
                    <h5>User Management</h5>
                    <div className="dashboard_right_content_top_options">
                    <div className="dashboard_right_content_top_search">
                                <Paper component="form">
                                    <IconButton aria-label="menu">
                                        <img src="images/search_icon.svg" />
                                    </IconButton>
                                    <InputBase onChange={(event) => inputChangedHadler(event, 'search')} placeholder="User Name" />
                                    <Button onClick={handlePropertySearch} variant="contained" color="primary" type="submit">Search</Button>
                                </Paper>
                            </div>
                        {/* {ADMIN == localStorage.getItem("roleName") ? "" : <Button style={{width:'100%',}} onClick={handleOpenNewUser}>{("add a New User")}</Button>} */}
                        <div>
                            <Button style={{width:'100%', marginLeft:'10px' }} onClick={handleExport}>Export</Button>
                            </div>
                            <div >
                                <Button onClick={onClickRemoveFilter}>Reset</Button> 
                            </div>
                    </div>
                </div>
            </div>
            
            <div className="ulb_listing scrolluser" >
            {/* {(!props.loadingUser)&& <div id="cover-spin"></div>} */}
                <TableContainer component={Paper} style={{paddingLeft:"15px",paddingRight:"15px"}}>
                    <Table stickyHeader aria-label="sticky table" style={{ tableLayout: 'fixed' }}>
                        <colgroup>
                            <col style={{width:'10%'}}/>
                            <col style={{width:'15%'}}/>
                            <col style={{width:'20%'}}/>
                            <col style={{width:'15%'}}/>
                            <col style={{width:'15%'}}/>
                            <col style={{width:'15%'}}/>
                            <col style={{width:'10%'}}/>  
                        </colgroup>
                        <TableHead >
                            <TableRow >
                            {userHeaders}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                                {/* {userData} */}
                                {userData.length === 0 ? <TableRow style={{textAlign:'center', fontWeight:'bold', fontSize: '13px', }}>
                                            No Records Found !!</TableRow>:userData }
                        </TableBody>
                    </Table>
                </TableContainer>
                {
                props.totalCount && props.totalCount != 0 && 
                    <ReactPaginate
                    previousLabel={"Prev"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                  
                    pageCount = {props.totalCount/limit}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    style={{borderLeftWidth:'0px'}}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}/>
                }
            </div>   


    <div className="setFreq">
      <Dialog
        maxWidth={'md'}
        fullWidth={true} 
        // open={true}
        open={openEditUser}
        aria-labelledby="responsive-dialog-title">
        <DialogTitle className="text-center border-none " style={{color:'#3dab35'}}><span style={{fontSize:'18px'}}>{`${openIdPop != ""?  ("Edit User"): "Add User"}`}</span></DialogTitle>
        <DialogContent > 
        <div className="create_ulb_content_outer">
                    <div className="create_ulb_content create_ulb_contentPop">
                    <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <FormControl>
                                    <label className="required" htmlFor="username">User Name</label>
                                    <TextField id="username"
                                        variant="outlined"
                                        placeholder="Enter User Name"
                                        name="username"
                                        value={username} 
                                        onChange={handleChange} />
                                    <div style={{ fontSize: 12, color: "red", fontFamily: "'Open Sans', sans-serif", marginTop: "1%" }}>{usernameErr}</div>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl>
                                    <label className="required" htmlFor="email">Email</label>
                                    <TextField id="email"
                                        variant="outlined"
                                        placeholder="Enter Email"
                                        name="email"
                                        value={email}
                                        onChange={handleChange} />
                                    <div style={{ fontSize: 12, color: "red", fontFamily: "'Open Sans', sans-serif", marginTop: "1%" }}>{emailErr}</div>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} >

                                <FormControl>
                                    <label className="required" htmlFor="role">Role</label>
                                    {
                                      openIdPop&& unAssignable ?
                                            <TextField variant="outlined"
                                                value={role}
                                                disabled={true}
                                                style={{ width: '99%' }}
                                            />
                                            :
                                            <Select value={role}
                                                onChange={handleChange}
                                                name="role">
                                                {roleOptions.map((role) => (
                                                    <MenuItem value={role}>{role.name}</MenuItem>
                                                ))}
                                            </Select>
                                    }

                                    <div style={{ fontSize: 12, color: "red", fontFamily: "'Open Sans', sans-serif", marginTop: "1%" }}>{roleErr}</div>
                                </FormControl>
                            </Grid>
                                    {role.name == "ULB officer" || role.id == 8? 
                                       <Grid xs={6}>
                                       <FormControl>
                                       <label className="required" htmlFor="designation" align="left">Designation</label>
                                       <TextField id="designation"
                                           variant="outlined"
                                           value={designation}
                                           onChange={handleChange}
                                           name="designation"
                                           placeholder= "Enter designation" />
                                   </FormControl>
                                       </Grid>:""}
                            {roleName == "State/Regional Officer"?<>
                            <Grid item xs={6}>  </Grid>
                            <Grid item xs={6}>
                            
                                <div className="scrollPrent">
                                <FormControl>
                                <label className="required" htmlFor="ulb">ULBs Selection</label>
                                 <div className="hidescroll">

                             
                                 {ulbOptions.map((ulb) => (
                                     <FormControlLabel
                                     control={    <Checkbox
                                        style={{ display:'block', color: 'green', fontFamily: 'Open sans-serif', transform: 'scale(1.2)', width: 48}}
                                           onClick={event =>handleCheckboxClick(event, ulb.ulbId,ulb.name)}
                                           value={checkValue}                                                
                                           />}
                                     label={ulb.name}
                                 />
                                            ))}
                                 </div>
                                 </FormControl>
                                </div>
                            
                            </Grid>

                           {selected.length>0? <Grid item xs={6}>
                            <label  htmlFor="ulb">Selected ULBs Total:  {nameSelected.length}</label>

                            <Paper component="ul" className={classes.root}>
                            {nameSelected.map((data, i) => {
                                return (
                                <li key={i}>
                                    <Chip
                                    label={data}
                                    // onDelete={handleDelete(data)}
                                    className={classes.chip}
                                    />
                                </li>
                                );
                            })}
                          
                            </Paper>
                            </Grid>:""}
                            </> :
                            <>
                                {AroleName == "Admin" ? 
                                <Grid item xs={6}>
                                    <FormControl>
                                        <label className="required" htmlFor="ulb">ULB</label>
                                        <Select value={ulb}
                                            onChange={handleChange}
                                            name="ulb"
                                            disabled={true}
                                            style={{ width: '99%' }}>
                                            {!!ulbOptions && ulbOptions.map((ulbData) => (
                                                <MenuItem value={ulbData.ulbId}>{ulbData.name}</MenuItem>
                                            ))}
                                        </Select>
                                        <div style={{ fontSize: 12, color: "red", fontFamily: "'Open Sans', sans-serif", marginTop: "1%" }}>{ulbErr}</div>
                                    </FormControl>
                                </Grid> : 
                                 <Grid item xs={6}>
                                 <FormControl>
                                     <label className="required" htmlFor="ulb">ULB</label>
                                     <Select value={ulb}
                                         onChange={handleChange}
                                         name="ulb"
                                         style={{ width: '99%' }}>
                                         {!!ulbOptions && ulbOptions.map((ulbData) => (
                                             <MenuItem value={ulbData.id}>{ulbData.name}</MenuItem>
                                         ))}
                                     </Select>
                                     <div style={{ fontSize: 12, color: "red", fontFamily: "'Open Sans', sans-serif", marginTop: "1%" }}>{ulbErr}</div>
                                 </FormControl>
                             </Grid> 
}
                                </>
                            }
    
                            {roleName != undefined &&roleName != "ULB officer"?
                                <Grid item xs={6}>
                                    <div className="scrollPrent">


                                        <FormControl>
                                            <label className="" htmlFor="modules">Modules Selection</label>
                                            <div className="hidescroll">

                                                {roleName == 'ULB Admin' || roleName == 'ULB Moderator' ?

                                                    <>
                                                        <FormControlLabel
                                                            control={<Checkbox style={{ color: 'green', fontFamily: 'Open sans-serif', transform: 'scale(1.5)', width: 48 }} 
                                                            checked={dashboard} onChange={handleChecked} name="dashboard" />}
                                                            label="Dashboard"
                                                        />

                                                        <FormControlLabel
                                                            control={<Checkbox style={{ color: 'green', fontFamily: 'Open sans-serif', transform: 'scale(1.5)', width: 48 }} checked={iotdashboard} onChange={handleChecked} name="iotdashboard" />}
                                                            label="IOT Dashboard"
                                                        />
                                                        <FormControlLabel
                                                            control={<Checkbox style={{ color: 'green', fontFamily: 'Open sans-serif', transform: 'scale(1.5)', width: 48 }} checked={propertyManagement} onChange={handleChecked} name="propertyManagement" />}
                                                            label="Property Management Module"
                                                        />
                                                        <FormControlLabel
                                                            control={<Checkbox style={{ color: 'green', fontFamily: 'Open sans-serif', transform: 'scale(1.5)', width: 48 }} checked={loadsRecords} onChange={handleChecked} name="loadsRecords" />}
                                                            label="Loads Records"
                                                        />

                                                        <FormControlLabel
                                                            control={<Checkbox style={{ color: 'green', fontFamily: 'Open sans-serif', transform: 'scale(1.5)', width: 48 }} checked={ScheduleProperties} onChange={handleChecked} name="ScheduleProperties" />}
                                                            label="Schedule Properties"
                                                        />

                                                        <FormControlLabel
                                                            control={<Checkbox style={{ color: 'green', fontFamily: 'Open sans-serif', transform: 'scale(1.5)', width: 48 }} checked={TreatmentFacilities} onChange={handleChecked} name="TreatmentFacilities" />}
                                                            label="Treatment Facilities"
                                                        />
                                                        <FormControlLabel
                                                            control={<Checkbox style={{ color: 'green', fontFamily: 'Open sans-serif', transform: 'scale(1.5)', width: 48 }} checked={OperatorManagement} onChange={handleChecked} name="OperatorManagement" />}
                                                            label="Operator Management"
                                                        />
                                                        <FormControlLabel
                                                            control={<Checkbox style={{ color: 'green', fontFamily: 'Open sans-serif', transform: 'scale(1.5)', width: 48 }} checked={WardManagement} onChange={handleChecked} name="WardManagement" />}
                                                            label="Ward Management"
                                                        />

                                                        <FormControlLabel
                                                            control={<Checkbox style={{ color: 'green', fontFamily: 'Open sans-serif', transform: 'scale(1.5)', width: 48 }} checked={userManagement} onChange={handleChecked} name="userManagement" />}
                                                            label="User Management"
                                                        />
                                                    </>
                                                    : roleName == 'Admin' ? <>
                                                        <FormControlLabel
                                                            control={<Checkbox style={{ color: 'green', fontFamily: 'Open sans-serif', transform: 'scale(1.5)', width: 48 }} checked={stateDashboard} onChange={handleChecked} name="stateDashboard" />}
                                                            label="State/Regional Officer"
                                                        />


                                                        <FormControlLabel
                                                            control={<Checkbox style={{ color: 'green', fontFamily: 'Open sans-serif', transform: 'scale(1.5)', width: 48 }} checked={ulbManagement} onChange={handleChecked} name="ulbManagement" />}
                                                            label="ULB Management"
                                                        />

                                                        <FormControlLabel
                                                            control={<Checkbox style={{ color: 'green', fontFamily: 'Open sans-serif' , transform: 'scale(1.5)', width: 48}} checked={roleManagement} onChange={handleChecked} name="roleManagement" />}
                                                            label="Role Management"
                                                        />
                                                        <FormControlLabel
                                                            control={<Checkbox style={{ color: 'green', fontFamily: 'Open sans-serif', transform: 'scale(1.5)', width: 48 }} checked={userManagement} onChange={handleChecked} name="userManagement" />}
                                                            label="User Management"
                                                        />
                                                        <FormControlLabel
                                                            control={<Checkbox style={{ color: 'green', fontFamily: 'Open sans-serif', transform: 'scale(1.5)', width: 48 }} checked={clusterManagement} onChange={handleChecked} name="clusterManagement" />}
                                                            label="Cluster Management"
                                                        />
                                                    </>
                                                        : roleName == 'State/Regional Officer' ? <>
                                                            <FormControlLabel
                                                                control={<Checkbox style={{ color: 'green', fontFamily: 'Open sans-serif', transform: 'scale(1.5)', width: 48 }} checked={stateDashboard} onChange={handleChecked} name="stateDashboard" />}
                                                                label="State/Regional Officer"
                                                            />
                                                            <FormControlLabel
                                                                control={<Checkbox style={{ color: 'green', fontFamily: 'Open sans-serif', transform: 'scale(1.5)', width: 48 }} checked={iotdashboard} onChange={handleChecked} name="iotdashboard" />}
                                                                label="IOT Dashboard"
                                                            />

                                                        </>  : roleName == 'Scheduler' ? <>
                                                        <FormControlLabel
                                                            control={<Checkbox style={{ color: 'green', fontFamily: 'Open sans-serif', transform: 'scale(1.5)', width: 48 }} checked={ScheduleProperties} onChange={handleChecked} name="ScheduleProperties" />}
                                                            label="Schedule Properties"
                                                        />
                                                        </>: <span style={{ color: "red" }}>!!! Assigned "Role" doesn't have access to web application module(s). !!!</span>}





                                            </div>
                                        </FormControl>
                                    </div>
                                </Grid> : ""}
                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <div className="create_ulb_buttons">
                                <Button className="login_submit" variant="outlined" color="primary"onClick={handleCloseEdituser}>{("Cancel")}</Button>

                                    <Button className="login_submit" variant="contained" color="primary" onClick={handleSubmitAdduser}>Save</Button>
                                </div>
                            </Grid>
                        </Grid>

                     

                    </div>
                </div>   
        </DialogContent>
    </Dialog>
    </div>
            </div>
    )
}

const mapStateToProps = (state) => {
    return({
        token        : state.auth.token,
        userHeaders  : state.userHeaders.userHeaders.concat({header: "Action", accessor: "actionButton", sortable: "false", filter: "false"}),
        userData     : state.userData.userData,
        totalCount   : state.userData.totalCount,
        loadingUser : !!state && state.userData.loading,
        roleData : state.roleData.roleData,
    })
}

const mapDispatchToProps = (dispatch) => {
    return({
        onFetchUserHeaders : (payload) => dispatch(actionCreators.fetchUserHeaders(payload)),
        onFetchUserListing : (payload) => dispatch(actionCreators.fetchUserListing(payload)),
        onDeleteUser       : (payload, data, props) => dispatch(actionCreators.deleteUser(payload, data, props)),
        onSearchUserName   : (payload) => dispatch(actionCreators.searchUserName(payload)),
        onFetchRole   : (payload) => dispatch(actionCreators.fetchRoleListing(payload)),
        onFetchUserListingExport :(payload)=>dispatch(actionCreators.fetchUserListingExport(payload)),
        onAddUser : (payload,props) => dispatch(actionCreators.addUser(payload,props)),
        onEditUser : (payload,props) => dispatch(actionCreators.editUser(payload,props))


    })
}

export default connect(mapStateToProps, mapDispatchToProps)(UserListing)



