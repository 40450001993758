import * as actionTypes from '../../actions/actionsTypes'
import { updateObject } from '../../utility';

const initialState = {
    operatorHeaders: [],
    totalCount : 0,
    loading : false
}
const fetchOperatorHeadersStart = (state, action) =>
{
    return updateObject(state,{
        loading:true
    })
}
const fetchOperatorHeadersSuccess = (state,action) =>
{
    return updateObject(state,{
        operatorHeaders : action.operatorHeaders,
        loading:false
    })
}
const fetchOperatorHeadersFail = (state, action) =>
{
    return updateObject(state,{
        loading:false
    })
}
const operatorHeaderReducer = (state = initialState, action) =>
{
    switch(action.type)
    {
        case actionTypes.FETCH_OPERATOR_HEADERS_START : return fetchOperatorHeadersStart(state,action)
        case actionTypes.FETCH_OPERATOR_HEADERS_SUCCESS : return fetchOperatorHeadersSuccess(state, action)
        case actionTypes.FETCH_OPERATOR_HEADERS_FAIL : return fetchOperatorHeadersFail(state, action)
        default : return state
    }
}

export default operatorHeaderReducer