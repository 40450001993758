import * as actionTypes from './actionsTypes';
import axios from '../../http-config';
import { Category } from '@material-ui/icons';
import swal from 'sweetalert';

export const fetchStateDashboardSuccess = (response) => {
    return {
        type: actionTypes.FETCH_STATE_DASHBOARD_SUCCESS,
        data: response.data.data,
        id:response
      
    }
}

export const fetchStateDashboardFail = (error) => {
    return {
        type: actionTypes.FETCH_STATE_DASHBOARD_FAIL,
        error:error
    }
}

export const fetchStateDashboardStart = () => {
    return {
        type: actionTypes.FETCH_STATE_DASHBOARD_START
    }
}

export const fetchStateDashboardChartDataSuccess = (response) => {
    return {
        type: actionTypes.FETCH_STATE_DASHBOARD_CHART_DATA_SUCCESS,
        data: response.data.data,
        totalLoadsCount: !!response.data.data.dashboard_data ?response.data.data.dashboard_data[0].table_data.maxNoOfLoadsReceived:0

    }
}

export const fetchStateDashboardChartDataFail = (error) => {
    return {
        type: actionTypes.FETCH_STATE_DASHBOARD_CHART_DATA_FAIL,
        error:error
    }
}

export const fetchStateDashboardChartDataStart = () => {
    return {
        type: actionTypes.FETCH_STATE_DASHBOARD_CHART_DATA_START
    }
}

export const fetchSRegionalStart = () => {
    return {
        type: actionTypes.FETCH_REGIONAL_START,
        loading :true
    }
}

export const fetchSRegionalSuccess = (response) => {
    return {
        type: actionTypes.FETCH_REGIONAL_SUCCESS,
        listing: response.data,
        totalCount: response.totalCount,
        loading :false
    }
}


export const fetchSRegionalFail = (error) => {
    return {
        type: actionTypes.FETCH_REGIONAL_FAIL,
         error:error,
         loading:false
      
    }
}


export const fetchRegionalListing =(payload)=>{
    return dispatch => {
        dispatch(fetchSRegionalStart());
        const url = `user/state_dashboard?limit=${payload.limit}&page=${payload.page}&sortColumnName=${payload.sortName}&sortColumnType=${payload.sortValue}`;
        axios({
            method: 'get',
            url: url,
            headers: {'Authorization': `Bearer ${payload.token}`}
        })
        .then(function (response) {
            const fetchedLoadsResponse = response.data
            dispatch(fetchSRegionalSuccess(fetchedLoadsResponse));
        }).catch(error => {
            dispatch(fetchSRegionalFail(error));
        })
    }
}

export const update_state_regional = (payload) => {
    let token= payload.token
    return (dispatch) => {
        dispatch(fetchSRegionalStart());
        const url = `user/update_state_dashboad`
        axios.put(url, payload, {headers:{"Authorization":`Bearer ${token}`}})
        .then((response) => {
            const fetchedResponse = response.data.success;
            fetchedResponse && swal({
                title: "You have successfully updated the data!!!",
                icon: "success",
                buttons: {
                    confirm: 'Ok'
                }
            })

            dispatch(fetchRegionalListing({token:payload.token, limit:10, page:1, sortName:"", sortValue:""}))
        },
        (err) => {
            swal({icon : "error" , title : err.response.data.error[0]["message"]}) 
        })
        .catch((err) => {
            // dispatch(fetchUserListingFail(err))
        })
    }
}
export const fetchStateDashboard = (payload,filter) => {
    let token = payload.token;
    let interval = payload.interval
        return async dispatch => {
        dispatch(fetchStateDashboardStart());

        //to handle API call when category is STP 
        let FSTPORSTP = payload.category == 1 ? `&startutilizationRateIn=${payload.startutilizationRateIn}&endutilizationRateIn=${payload.endutilizationRateIn}` :''
        //  `&startLoadValues=${payload.startutilizationRateIn}&endLoadValues=${payload.endutilizationRateIn}`
        let tfFacility = payload.category == 0  ? '' : `&treatmentFacilityType=${payload.category}&ulbNames=${payload.ulbSerach}`
        try {
            const response = await axios({
                method: 'get',
                url: filter ? `state_dashboard?startDate=${payload.startDate}&endDate=${payload.endDate}${FSTPORSTP}${tfFacility}${filter} `:
                `state_dashboard?startDate=${payload.startDate}&endDate=${payload.endDate}${FSTPORSTP}${tfFacility}`,
                headers: {'Authorization': `Bearer ${payload.token}`}
            })
            if(response){
                const fetchedEmailManageResponse = response;
                dispatch(fetchStateDashboardSuccess(fetchedEmailManageResponse));
                const data = response.data.data.dashboard_data[0].table_data.body
                if(data.length == 0){
                    swal("No Records Found !", {
                        icon: "warning",
                      });
                }
            }
            return response
        }catch(error){
            dispatch(fetchStateDashboardFail(error));
        }
    }
    
}


export const fetchStateDashboardExcal = (payload)=>{
    return dispatch =>{
        dispatch(fetchStateDashboardStart());
        const url = payload.category == 1 ? `state_dashboard?startDate=${payload.startDate}&endDate=${payload.endDate}&startutilizationRateIn=${payload.startutilizationRateIn}&endutilizationRateIn=${payload.endutilizationRateIn}&treatmentFacilityType=${payload.category}&export=${payload.excalDownload}&ulbNames=${payload.ulbSerach}`:
        `state_dashboard?startDate=${payload.startDate}&endDate=${payload.endDate}&treatmentFacilityType=${payload.category}&export=${payload.excalDownload}&ulbNames=${payload.ulbSerach}`
        // &startLoadValues=${payload.startutilizationRateIn}&endLoadValues=${payload.endutilizationRateIn}
        axios.get(url, { headers: {'Authorization': `Bearer ${payload.token}`}})
        .then(response=>{
            let urlExcal=response.data.data.dashboard_data[0].table_data.data[0].file_path
            window.downloadFile = function (linkExcal) {
                window.open(linkExcal, '_blank');
            }
            window.downloadFile(urlExcal)
          
        }).catch(error=>{
            dispatch(fetchStateDashboardFail(error));
        })
    }
   
}

export const fetchChartData = (payload) => {
    let token = payload.token;
    let interval = payload.interval

    return dispatch => {
        let tfFacility = payload.category == 0  ? '' : `&treatmentFacilityType=${payload.category}`
        axios({
            method: 'get',
            url: payload.category == 1 ? 
            `state_dashboard?topTenValues=true&startDate=${payload.startDate}&endDate=${payload.endDate}&startutilizationRateIn=${payload.startutilizationRateIn}&endutilizationRateIn=${payload.endutilizationRateIn}${tfFacility}&ulbNames=${payload.ulbSerach}`:
            `state_dashboard?topTenValues=true&startDate=${payload.startDate}&endDate=${payload.endDate}&${tfFacility}&ulbNames=${payload.ulbSerach}`,
            headers: {'Authorization': `Bearer ${payload.token}`}
        })
        .then((response) => {
            dispatch(fetchStateDashboardChartDataSuccess(response));
        }).catch(error => {
            dispatch(fetchStateDashboardChartDataFail(error));
        })

    }
}
 
export const fetchStatDashboardChart = (payload) => {
    let interval = payload.interval

    return dispatch => {
        dispatch(fetchStateDashboardStart());
        const url = `/?interval=${interval}`;

        axios({
            method: 'get',
            url: '/dashboard'+url,
            headers: {'Authorization': `Bearer ${payload.token}`}
        })
        .then((response) => {
            // const fetchedEmailManageResponse = response.data;
            dispatch(fetchStateDashboardChartDataSuccess(response));
        }).catch(error => {
            dispatch(fetchStateDashboardChartDataFail(error));
        })

    }
}
export const fetachTopTenData =(payload)=>{
    return dispatch => {
        dispatch(fetchStateDashboard(payload))

        // let tfFacility = payload.category == 0  ? '' : `&treatmentFacilityType=${payload.category}`
        // const utF = payload.category == 1 ? `startutilizationRateIn=${payload.startutilizationRateIn}`: ""
        // axios({
        //     method: 'get',
        //     url: `state_dashboard?startDate=${payload.startDate}&endDate=${payload.endDate}${tfFacility}&topTenValues=true&${utF}`,
        //     headers: {'Authorization': `Bearer ${payload.token}`}
        // })
        // .then((response) => {
        //     dispatch(fetchStateDashboardChartDataSuccess(response));
        // }).catch(error => {
        //     dispatch(fetchStateDashboardChartDataFail(error));
        // })
    }
}

export const fetachBottomTenData =(payload)=>{
    return dispatch => {
        dispatch(fetchStateDashboard(payload))
        // let tfFacility = payload.category == 0  ? '' : `&treatmentFacilityType=${payload.category}`
        // const utF = payload.category == 1 ? `startutilizationRateIn=${payload.startutilizationRateIn}`: ""
        // axios({
        //     method: 'get',
        //     url: `state_dashboard?startDate=${payload.startDate}&endDate=${payload.endDate}${tfFacility}&topTenValues=true&bottom10=true&${utF}`,
        //     headers: {'Authorization': `Bearer ${payload.token}`}
        // })
        // .then((response) => {
        //     dispatch(fetchStateDashboardChartDataSuccess(response));
        // }).catch(error => {
        //     dispatch(fetchStateDashboardChartDataFail(error));
        // })
    }
}

export const fetchStateListingExport = (payload) => {
    let token = payload.token
    const url = `user/state_dashboard?limit=${payload.limit}&page=${payload.page}&sortColumnName=${payload.sortName}&sortColumnType=${payload.sortValue}&export=true`;
    return dispatch=>{
        dispatch(fetchStateDashboardStart());
        axios.get(
            url,
            {headers:{ "Authorization":`Bearer ${token}`
        }}).then(function (response) {
            let link1=response.data.data[0].file_path
            
            window.downloadFile = function (link) {
                window.open(link, '_blank');
            }
            window.downloadFile(link1)
          
        }).catch(error => {
        })
    }
} 



export const deleteUserRegional = (payload, data) => {
    return (dispatch) => {
        dispatch(fetchStateDashboardStart());
        const url = '/user'
        axios.delete(url , { data : data, headers : {   "Authorization":`Bearer ${payload.token}`} })
        .then((response) => {
            swal({
                title:"Your data has been deleted!",
                icon: "success",
            })

            dispatch(fetchRegionalListing({token:payload.token ,limit:payload.limit, page:payload.page, sortName:payload.sortName, sortValue:payload.sortValue}))
        }, 
        (err) => {
            swal({icon : "error" , title : err.response.data.error[0]["message"]})
            dispatch(fetchRegionalListing({token:payload.token, limit:payload.limit, page:payload.page, sortName:payload.sortName, sortValue:payload.sortValue}))

        })
        .catch((err) => {
            dispatch(fetchStateDashboardChartDataFail(err));
        })
    }
}

export const SearchRegionalName = (payload) => {
    return dispatch => {
        // user/state_dashboard?limit=10&page=1&sortColumnName=&sortColumnType=&userName=state_region
        dispatch(fetchStateDashboardStart());
        const url = `user/state_dashboard?userName=${payload.searchQuery}&limit=${payload.limit}&page=${payload.page}&sortColumnName=${payload.sortName}&sortColumnType=${payload.sortValue}`
        axios({
            method: 'get',
            url: url,
            headers: {'Authorization': `Bearer ${payload.token}`}
        })
        .then(function (response) {
            const fetchedLoadsResponse = response.data
            dispatch(fetchSRegionalSuccess(fetchedLoadsResponse));
        }).catch(error => {
            dispatch(fetchSRegionalFail(error));
        })
    }
}


export const addUser = (payload,props) => {
    let token= payload.token
    return (dispatch) => {
        const url = '/user'
        dispatch(fetchStateDashboardStart());
        axios.post(url, payload, { headers : { "Authorization":`Bearer ${token}`}})
        .then((response) => {
            const fetchedResponse = response.data.success;
            fetchedResponse && swal({
                title: "User added successfully!",
                icon: "success",
                buttons: {
                    confirm: 'Ok'
                }
            })
            dispatch(fetchRegionalListing({token:payload.token, limit:10, page:1, sortName:"", sortValue:"",}))
        }, 
        (err) => {
            swal({icon : "error" , title : err.response.data.error[0]["message"]}) 
            dispatch(fetchRegionalListing({token:payload.token, limit:10, page:1, sortName:"", sortValue:""}))

        })
        .catch((err) => {
            swal({icon : "error" , title : err.response.data.error[0]["message"]}) 
            dispatch(fetchSRegionalFail(err));
        })
    }
}