import * as actionTypes from '../actionsTypes'
import axios from '../../../http-config'

export const fetchUserHeadersStart = () => {
    return({
        type   : actionTypes.FETCH_USER_HEADERS_START
    })
}

export const fetchUserHeadersSuccess = (response) => {
    return({
        type         : actionTypes.FETCH_USER_HEADERS_SUCCESS,
        userHeaders  : response.data.headers
        // userHeaders  : response 
    })
}

export const fetchUserHeadersFail = (error) => {
    return({
        type         : actionTypes.FETCH_USER_HEADERS_FAIL,
        error        : error
    })
}

export const fetchUserHeaders = (token) => {
    return (dispatch) => {
        dispatch(fetchUserHeadersStart())
        axios({
            method    : 'get',
            url       : '/user/headers',
            headers   : {'Authorization': `Bearer ${token}`}
        })
        .then((response) => {
            dispatch(fetchUserHeadersSuccess(response.data))
        })
        .catch((err) => {
            dispatch(fetchUserHeadersFail(err))
        })
    }
}