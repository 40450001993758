import * as actionTypes from '../actionsTypes';
import axios from '../../../http-config';

export const fetchWardHeadersSuccess = (response) => {
    return {
        
        type: actionTypes.FETCH_WARD_HEADERS_SUCCESS,
        headers:response.data.headers   
    }
}

export const fetchWardHeadersFail = (error) => {
    return {
        type: actionTypes.FETCH_WARD_HEADERS_FAIL,
        error:error
    }
}

export const fetchWardHeadersStart = () => {
    return {
        type: actionTypes.FETCH_WARD_HEADERS_START
    }
}

export const fetchWardHeaders = (payload) => {
    let token = payload.token;
    return dispatch => {
        dispatch(fetchWardHeadersStart());
        axios({
            method: 'get',
            url: 'ward/headers',
            headers: {'Authorization': `Bearer ${token}`}
        })
        .then((response) => {
            const fetchedWardHeadersResponse =response.data        
            dispatch(fetchWardHeadersSuccess(fetchedWardHeadersResponse));
        }).catch(error => {
            dispatch(fetchWardHeadersFail(error));
        })

    }
}