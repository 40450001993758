import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../store/actions/index'
import { Link } from 'react-router-dom'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import moment from 'moment'
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import "react-datepicker/dist/react-datepicker.css";
import swal from 'sweetalert';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { DatePicker , MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import truckBulkUploadFile from '../../TruckBulkUpload.xlsx'
import axios from '../../http-config';
import InputBase from '@material-ui/core/InputBase';
import NativeSelect from '@material-ui/core/NativeSelect';
import { makeStyles ,withStyles} from '@material-ui/core/styles';

import _ from 'lodash'


const BootstrapInput = withStyles((theme) => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor:"#fff",
      border: '1px solid #000000',
      fontSize: 15,
      padding: '7px 26px 7px 20px',
      transition: theme.transitions.create(['border-color']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        'Open Sans',
        'sans-serif',
   
      ].join(','),
      '&:focus ': {
        borderRadius: 4,
            backgroundColor:"#fff !important"
   
      }
    },
  }))(InputBase);

const  options=[
    {
        "id"  : 1,
        "key" : "Other"
    },
    {
        "id"  : 2,
        "key" : "Vacuum truck with tanker"
    },
    {
        "id"  : 3,
        "key" : "Tractor with tanker"
    },
    {
        "id"  : 4,
        "key" : "Truck with separate tank"
    }
]
const licensedWithULBOptions=[
    {
        "key"    : true,
        "name"   : "Yes"
    },
    {
        "key"    : false,
        "name"   : "No"
    }
]
const gpsInstalledOptions=[
    {
        "key"    : true,
        "name"   : "Yes"
    },
    {
        "key"    : false,
        "name"   : "No"
    }
]
const isDisableTruck=[
    {
        "key"   : false,
        "name"   : "Active"
    },
    {
        "key"    : true,
        "name"   : "In Active"
    }
]
export class TruckListing extends Component {
    constructor(props){
        super(props)
        if(this.props.location.state){
            if(localStorage.getItem('operatorName') == this.props.location.state.operatorName){
            }
            else if(localStorage.getItem('operatorName') != this.props.location.state.operatorName){
                localStorage.setItem('operatorName', this.props.location.state.operatorName)
            }
        }

        else if(!localStorage.getItem('operatorName')){
            localStorage.setItem('operatorName', this.props.location.state.operatorName)
        }
      
    }
    state = {
        isAddEnabled              : false,
        registrationNumber        : '',
        vehicleTypeId             : '',
        licensedWithULB           : false,
        gpsInstalled              : '',
        referenceNumber           : '',
        validity                  : new Date(),
        capacity                  : '',
        isAddEnabled              : false,
        islicensedWithULB         : false,
        registrationNumberErr     : '',
        vehicleTypeIdErr          : '',
        licensedWithULBErr        : '',
        referenceNumberErr        : '',
        validityErr               : '',
        capacityErr               : '',
        startDate                 : new Date(),
        anchorEl                  : null,
        open                      : false,
        id                        : "",
        isDisabled                : false,
        truckRegNo                : '',
        anchorEl_bulk             : null,
        placement                 : '',
        addDisabled               : false,
        editRecord                : false,
        singleTruck               : {},
        truckId                   : '',
        open_onadd                : false,
        anchorEl_onadd            : null,
        open_onedit               : false,
        anchorEl_onedit           : null,
        isDisableTruck               : null,
        sortObj : {
            key: '',
            value: ''
        },
        checkRegistrationNumber:""
    }

    componentDidMount(){
        let props= this.props
        const SearchPath=!!props.location&&!!props.location.state?props.location.state.searchByTruckNo:""
        const payload = {
            token : this.props.token,
            sortName:this.state.sortObj.key,
            sortValue :this.state.sortObj.value,
        }
        if(SearchPath == "searchTruck"){
            const payload = {
                token : this.props.token,
                limit:10,
                page:1,
                searchQuery:this.props.location.state.TruckNo,
                  
                    sortName:this.state.sortObj.key,
                    sortValue :this.state.sortObj.value,
            }
           this.props.onSearchOperterTruckNumber(payload,props)
        }else{
            this.props.onFetchTruckHeaders(payload)
            this.props.onFetchTruckData(payload,props)
        }
       
    }

    checkValidity =()=>{
        let isError=false
        if(!this.state.registrationNumber){
            isError=true
            this.setState({registrationNumberErr:'*required'})
        }
        if(this.state.checkRegistrationNumber.length != 0){
            if(this.state.registrationNumber != ""){
                if(this.state.checkRegistrationNumber.split("-")[0] == "M"){
                    var registrationFormat=/^(?:\M-1*)[A-Za-z0-9]*$/
                    if(!this.state.registrationNumber.match(registrationFormat)){
                        isError=true
                        this.setState({registrationNumberErr:'*Kindly Enter "M-" before Vehicle No,as it is created via App'})
                    }
                 
                    else{
                        this.setState({registrationNumberErr:''})
                    }
                }
    
                else{
                    
                    var registrationFormat=/^(?:\M-1*)[A-Za-z0-9]*$/
                    if(this.state.registrationNumber.substring(0,2) =="M-"){
                        isError=true
                        this.setState({registrationNumberErr:'*Kindly do not Enter "M-" before Vehicle No,as it is not created via App'})
                    }
                 
                    else{
                        this.setState({registrationNumberErr:''})
                     
                    }
                }
            }
        }
      
        else{
            var registrationFormat=/^(?:\M-1*)[A-Za-z0-9]*$/
            if(this.state.registrationNumber.match(registrationFormat)){
                isError=true
                this.setState({registrationNumberErr:'*Kindly do not Enter "M-" before Vehicle No,as it is not created via App'})
            }
         
            else{
                this.setState({registrationNumberErr:''})
            }
        }
        if(!this.state.vehicleTypeId){
            isError=true
            this.setState({vehicleTypeIdErr:'*required'})
        }
        else{
            this.setState({vehicleTypeIdErr:''})
        }
        if(this.state.capacity && this.state.capacity==0){
            this.setState({capacityErr:''})
        }
        else if(this.state.capacity && this.state.capacity< 10 || this.state.capacity > 20000 ){
            isError = true
            this.setState({capacityErr : "10 to 20,000"})
        }
        else if(this.state.capacity && this.state.capacity >= 10 || this.state.capacity <= 20000 ){
            this.setState({capacityErr:''})
        }
        if(this.state.licensedWithULB.length<=0){
            isError=true
            this.setState({licensedWithULBErr:'*required'})
        }
        else{
            this.setState({licensedWithULBErr:''})
        }
        if(this.state.islicensedWithULB === true && this.state.licensedWithULB=== true){
            if(!this.state.validity){
                isError=true
                this.setState({validityErr:'*required'})
            }
            else{
                this.setState({validityErr:''})
            }
        }
        else{
            this.setState({validityErr:''})
        }
        return isError
    }

    handleChange = (event) =>{
        if(event.target.name == 'registrationNumber'){
                const regNo = event.target.value.toUpperCase().replace(/\s/g, "")
                this.setState({[event.target.name]:regNo}, ()=> {
                    this.checkValidity()
                })
        }
        else{
            this.setState({[event.target.name]:event.target.value}
                , ()=> {
                this.checkValidity()
            }
            )
        }
        if(event.target.name== 'licensedWithULB' && event.target.value===false)
        {
           this.setState({islicensedWithULB:false})
        }
        else if(event.target.name== 'licensedWithULB' &&event.target.value === true)
        {
            this.setState({islicensedWithULB:true , validity:new Date()})
        }

        if(event.target.name === 'isDisableTruck' && event.target.value=== null){
            this.setState({truckStatus:false})
        }
        else if(event.target.name === 'isDisableTruck' && event.target.value=== false){
            this.setState({truckStatus:false})
        }
        if(event.target.name === 'isDisableTruck' && event.target.value=== true){
            this.setState({truckStatus:true})
        }
    }

    handleSave=()=>{
          const err = this.checkValidity()  
        if(!err)
        {
            let props=this.props
            const payload = {
                truckId             : this.state.truckId,
                token               : this.props.token,
                registrationNumber  : this.state.registrationNumber,
                vehicleTypeId       : this.state.vehicleTypeId,
                capacity            : this.state.capacity,
                licensedWithULB     : this.state.licensedWithULB,
                gpsInstalled        : this.state.gpsInstalled,
                referenceNumber     : this.state.licensedWithULB?this.state.referenceNumber: null,
                validity            : this.state.licensedWithULB? moment(this.state.validity).format('DD-MMM-YYYY 23:59:00') : null,
                isDisableTruck      : this.state.isDisableTruck,
                sortName:this.state.sortObj.key,
                sortValue :this.state.sortObj.value,
            }
            
            {this.state.editRecord &&
                
                this.props.onEditTruckData(payload,props)
                this.setState({editRecord : false , isDisabled:false,registrationNumber:'' ,vehicleTypeId:'', capacity:'',gpsInstalled:'', licensedWithULB:false, referenceNumber:'',validity:new Date(), islicensedWithULB:false,  isDisableTruck: null})
            }
            {this.state.isAddEnabled &&
                
                this.props.onAddTruckData(payload,props)
                this.setState({isAddEnabled : false , isDisabled:false,registrationNumber:'' ,vehicleTypeId:'', capacity:'',gpsInstalled:'', licensedWithULB:false, referenceNumber:'',validity:new Date(), islicensedWithULB:false, isDisableTruck: null})
            } 
        }
    }

    addNewTruck = () => {
        this.setState({isAddEnabled:true, isDisabled:true, checkRegistrationNumber:""})
    }

    setStartDate =(date)=>{
        this.setState({validity:date},()=> {
            this.checkValidity()
        })
    }

    handleClickMoreVertIcon=(event,id, truckRegNo)=>{
        this.setState({open:!this.state.open, anchorEl:event.currentTarget, id:id, truckRegNo:truckRegNo, editRecord: false})
    }
    handleClickMoreVertIconOnAdd=(event,id)=>{
        this.setState({open_onadd:!this.state.open_onadd,anchorEl_onadd:event.currentTarget})
    }
    handleClickMoreVertIconOnEdit=(event,id)=>{
        this.setState({open_onedit:!this.state.open_onedit,anchorEl_onedit:event.currentTarget})
    }
    onClickCancelAdd =(e) =>{
        this.setState({isAddEnabled:false, isDisabled:false, registrationNumber:'' ,vehicleTypeId:'', capacity:'',gpsInstalled:'', licensedWithULB:false,islicensedWithULB:false ,referenceNumber:'',validity:new Date()})
    }
    onClickCancelEdit =(e) =>{
        this.setState({editRecord:false, isDisabled:false, registrationNumber:'' ,vehicleTypeId:'', capacity:'',gpsInstalled:'', licensedWithULB:false,islicensedWithULB:false, referenceNumber:'',validity:new Date()})
    }
    handleClickAway = () => {
    this.setState({open:false});
    };
    handleClickAwayOnEdit = () => {
        this.setState({open_onedit:false});
        };
    handleClickAwayOnAdd = () =>{
        this.setState({open_onadd:false});
    }

    onFileUpload = () => { 
        let props= this.props
        const payload = {
            token : this.props.token
        }
        const formData = new FormData(); 
        formData.append( 
            "truckBulkUploadFile", 
            this.state.selectedFile, 
            this.state.selectedFile.name 
        )
        this.props.onFetchTruckBulkImport(payload,formData,props);
        this.setState({open_bulk:false})
    }

    onFileChange = (event) => {
        this.setState({ selectedFile: event.target.files[0]},()=>{
            this.onFileUpload()
        });   
    }

    handleClose = (event) => {
    this.setState({open_bulk:false})
    };

    handleClick = (newPlacement) => (event) => {
    this.setState({ anchorEl_bulk:event.currentTarget, newPlacement:newPlacement, open_bulk:!this.state.open_bulk});
    };
    
    handleClickAwayBulk = () => {
        this.setState({open_bulk: false});
    };

    handleDelete = (id) => {
        const payload = {
            id  : id,
            sortName:this.state.sortObj.key,
            sortValue :this.state.sortObj.value,
            token:this.props.token
        }
        this.props.onDeleteTruckData(payload, this.props.token, this.props)
    }
   
    handleCall= (id)=>{
        const payload={
            token:this.props.token
        }
        const url=`/truck/${id}`
        axios({
            method   : 'get',
            url      : url,
            headers  : {'Authorization': `Bearer ${payload.token}`}
       })
       .then((response) => {
           this.setState({singleTruck : response.data.data.trucks[0] ,open_onedit:false},()=>{
            this.handleEdit()
           })
       })
       .catch((err) => {
           
       })
    }
    handleEdit = () => {
        const capacity           = this.state.singleTruck.capacity
        const referenceNumber    = this.state.singleTruck.referenceNumber
        const registrationNumber = this.state.singleTruck.registrationNumber
        const vehicleType        = options.filter(option => option.key == this.state.singleTruck.vehicleType)
        const gpsInstalled       = this.state.singleTruck.gpsInstalled
        const licensedWithULB    = this.state.singleTruck.licensedWithULB
        const validity           =  moment(this.state.singleTruck.validity).format('DD-MMM-YYYY 23:59:00')
        const truckId            = this.state.singleTruck.truckId
        const islicensedWithULB  = this.state.singleTruck.licensedWithULB ? true : false
        const  isDisableTruck= this.state.singleTruck.isDisableTruck =='Active'? false :true
         this.setState({editRecord : true, 
                        isDisabled : true,
                        addDisabled:true, 
                        capacity,
                        referenceNumber,
                        registrationNumber,
                        checkRegistrationNumber:registrationNumber,
                        vehicleTypeId:vehicleType.length>0? vehicleType[0]["id"]: '',
                        gpsInstalled,
                        licensedWithULB, 
                        islicensedWithULB,
                        validity,
                        truckId,
                        isDisableTruck
         }, () => this.checkValidity())
     }
     handleSort = (e,sortBy) => {
        let sortObj
        if (this.state.sortObj.key === sortBy.accessor) {
            let value = (this.state.sortObj.value === 'desc') ? 'asc' : 'desc';
            sortObj = {
                key : sortBy.accessor,
                value: value
            }
        } 
        else {
            sortObj = {
                key : sortBy.accessor,
                value: 'desc'
         }    
         }
 
        this.setState({ sortObj: sortObj ,currentSortId:sortBy.accessor});
        let payload = {
            token: this.props.token,
            sortName:sortObj.key,
         sortValue : sortObj.value,
     
        }

        this.props.onFetchTruckData(payload, this.props);
  
      }
    render() {
        const truckHeaders = this.props.truckHeaders.map((header,i) => {
            return <TableCell key = {i}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'start',
                        whiteSpace: 'break-spaces',
                        height: '3em'
                    }}>
{ header.header === "Action"? "":
                    <div onClick={(e) =>  this.handleSort(e,header)}>
                        <div>{
                            this.state.currentSortId===header.accessor? this.state.sortObj.value ==='asc'?(<ArrowDropDownIcon fontSize="small"/>):(<ArrowDropUpIcon fontSize="small"/>) :(<ArrowDropDownIcon fontSize="small"/>)
                        }
                        </div>  
                    </div>}
                        {header.header}
                    </div>
                </TableCell>
        })


        const truckData =!!this.props.truckData&&this.props.truckData.map(data => {
            const id=data["truckId"]
            const registrationNumber= data["registrationNumber"]
            const truckRegNo=data["registrationNumber"]

            return <TableRow key = {data.id}>
                {!!this.props.truckHeaders&&this.props.truckHeaders.map(header => {
                    if(header.accessor == 'updatedDate'){
                        const updatedAtDate = moment(data[header.accessor]).format('DD-MMM-YYYY hh:mm a');
                        return <TableCell style={{whiteSpace: "normal",wordWrap: "break-word"}}>
                                    {updatedAtDate}
                                </TableCell>
                    }
                    else if(header.accessor === 'isDisableTruck')
                    {
                        if(this.state.editRecord && (data["truckId"] == this.state.id) && (header.accessor == 'isDisableTruck')){
                   
                            return  <TableCell align = "left" style = {{paddingTop:'4px'}}>
                            <FormControl variant="outlined" size="small">
                                <Select value={this.state.isDisableTruck} 
                                        onChange={this.handleChange} 
                                        name="isDisableTruck" 
                                        size="small" 
                                        style={{fontSize:12,width:'100%'}}>
                                        {isDisableTruck.map((option) => (
                                            <MenuItem key={option.id}
                                                        style={{fontSize:12,width:'100%'}} 
                                                        value={option.key}>
                                                        {option.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            {/* {this.state.licensedWithULBErr ? <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%",}}>{this.state.licensedWithULBErr}</div> :<div style={{fontSize:12,color:"white", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>required </div> } */}
                            </FormControl>
                        </TableCell>
                   
                            //     return  <TableCell>
                    //                 <NativeSelect
                    //   input={<BootstrapInput  />}
                    //   onChange={this.handleTruckStatus}
                    //  >
                    //          <option value={false} selected> {"Active"}</option>
                    //          <option value={true}> {"InActive"}</option>       
                    //   </NativeSelect>
                    //             </TableCell>
                    //     }
                    //     else{
                    //         return  <TableCell>
                    //                 <NativeSelect
                    //   input={<BootstrapInput  />}
                    //   onChange={this.handleTruckStatus}
                    //  >
                    //          <option value={false} > {"Active"}</option>
                    //          <option value={true} selected> {"InActive"}</option>       
                    //   </NativeSelect>
                    //             </TableCell>
                        }
                        else
                        {
                            
                            if(data.isDisableTruck==='Active')
                            {
                                return  <TableCell style={{whiteSpace: "normal", wordWrap: "break-word"}}> 
                                            Active
                                        </TableCell>
                            }
                            else
                            {
                                return  <TableCell style={{whiteSpace: "normal", wordWrap: "break-word"}}>
                                            In Active
                                        </TableCell>
                            }
                        }
                    }
                   else if(this.state.editRecord && data["truckId"] == this.state.id && (header.accessor == 'registrationNumber' ))
                    {
                        return  <TableCell style={{paddingTop:'18px',whiteSpace: "normal",wordWrap: "break-word"}}>
                                    <TextField type="text"
                                                id={header.accessor}
                                                variant="outlined"
                                                size = "small"
                                                value={this.state[header.accessor]}
                                                name={header.accessor}
                                                onChange={this.handleChange}
                                                // disabled={this.state.registrationNumber.split("-")[0] == "M" ? true:false }
                                                InputProps={{ style: { fontSize: 12 } }}/>
                                { this.state.registrationNumberErr ? <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.registrationNumberErr}</div> : <div style={{fontSize:12,color:"white", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>required</div>}
                                </TableCell>
                    }
                    else if(this.state.editRecord && data["truckId"] == this.state.id && (header.accessor == 'capacity' ))
                    {
                        return  <TableCell style = {{paddingTop:'18px'}}>
                                    <TextField type="number"
                                                id="capacity"
                                                variant="outlined"
                                                size = "small"
                                                min="0"
                                                step="1"
                                                placeholder = "Capacity"
                                                value={this.state.capacity && Math.max(0, this.state.capacity)}
                                                name="capacity"
                                                onChange={this.handleChange}
                                                InputProps={{ style: { fontSize: 12, } }}/>
                                {this.state.capacityErr ? <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.capacityErr}</div> :  <div style={{fontSize:12,color:"white", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>required</div>}
                                </TableCell>
                    }
                    else if(header.accessor == 'referenceNumber')
                    {
                        if(this.state.editRecord && data["truckId"] == this.state.id && (header.accessor == 'referenceNumber' ))
                        {
                            return  <TableCell align = "left" style = {{paddingTop:'18px'}}>
                                        <TextField type = "text"
                                                id="referenceNumber"
                                                variant="outlined"
                                                size="small"
                                                placeholder="Ref. No"
                                                value={this.state.referenceNumber}
                                                name="referenceNumber"
                                                onChange={this.handleChange}
                                                disabled={(this.state.islicensedWithULB && this.state.licensedWithULB) === true ? false : true}
                                                InputProps={{ style: { fontSize: 12 } }} />
                                    {this.state.referenceNumberErr ? <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.referenceNumberErr}</div> : <div style={{fontSize:12,color:"white", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>required</div>}
                                    </TableCell>
                        }
                        else
                        {
                            if(data.licensedWithULB == true)
                            {
                                    return  <TableCell style={{whiteSpace: "normal", wordWrap: "break-word"}}>
                                                {data[header.accessor]}
                                            </TableCell>
                                }
                                else
                                {
                                    return <TableCell></TableCell>
                                }   
                            }
                    }
                    else if(this.state.editRecord && data["truckId"] == this.state.id && (header.accessor=='vehicleType'))
                    {   
                        return  <TableCell style = {{paddingTop:'18px'}}>
                                    <FormControl variant="outlined" size="small">
                                        <Select value={this.state.vehicleTypeId} 
                                                onChange={this.handleChange} 
                                                name="vehicleTypeId" 
                                                size="small" 
                                                style={{fontSize:12,width:'100%'}}>
                                                {options.map((option) => (
                                                    <MenuItem key={option.id}
                                                            style={{fontSize:12,width:'100%'}} 
                                                            value={option.id}>
                                                            {option.key}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    {this.state.vehicleTypeIdErr ? <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.vehicleTypeIdErr}</div> :  <div style={{fontSize:12,color:"white", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>required</div>}
                                    </FormControl>
                                </TableCell>
                    }
                    
                    else if(header.accessor=='licensedWithULB')
                    {  
                        if(this.state.editRecord && data["truckId"] == this.state.id && (header.accessor=='licensedWithULB'))
                        {
                            return  <TableCell align = "left" style = {{paddingTop:'18px'}}>
                                        <FormControl variant="outlined" size="small">
                                            <Select value={this.state.licensedWithULB} 
                                                    onChange={this.handleChange} 
                                                    name="licensedWithULB" 
                                                    size="small" 
                                                    style={{fontSize:12,width:'100%'}}>
                                                    {licensedWithULBOptions.map((option) => (
                                                        <MenuItem key={option.id}
                                                                    style={{fontSize:12,width:'100%'}} 
                                                                    value={option.key}>
                                                                    {option.name}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        {this.state.licensedWithULBErr ? <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%",}}>{this.state.licensedWithULBErr}</div> :<div style={{fontSize:12,color:"white", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>required </div> }
                                        </FormControl>
                                    </TableCell>
                        }
                        else
                        {
                            if(data.licensedWithULB===true)
                            {
                                return  <TableCell style={{whiteSpace: "normal", wordWrap: "break-word"}}> 
                                            Yes
                                        </TableCell>
                            }
                            else
                            {
                                return  <TableCell style={{whiteSpace: "normal", wordWrap: "break-word"}}>
                                            No
                                        </TableCell>
                            }
                        }
                    }
                    else if(header.accessor == 'gpsInstalled')
                    {  
                        if(this.state.editRecord && data["truckId"] == this.state.id && (header.accessor=='gpsInstalled'))
                        {
                            return  <TableCell align = "left" style = {{paddingTop:'18px'}}>
                                        <FormControl variant="outlined" size="small">
                                            <Select value={this.state.gpsInstalled} 
                                                    onChange={this.handleChange} 
                                                    name="gpsInstalled" 
                                                    size="small" 
                                                    style={{fontSize:12,width:'100%'}}>
                                                    {gpsInstalledOptions.map((option) => (
                                                        <MenuItem key={option.id}
                                                                    style={{fontSize:12,width:'100%'}} 
                                                                    value={option.key}>
                                                                    {option.name}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        {<div style={{fontSize:12,color:"white", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>required </div> }
                                        </FormControl>
                                    </TableCell>
                        }
                        else
                        {
                            if(data.gpsInstalled===true)
                            {
                                return  <TableCell style={{whiteSpace: "normal", wordWrap: "break-word"}}> 
                                            Yes
                                        </TableCell>
                            }
                            else if(data.gpsInstalled===false)
                            {
                                return  <TableCell style={{whiteSpace: "normal", wordWrap: "break-word"}}>
                                            No
                                        </TableCell>
                            }
                            else {
                                return <TableCell></TableCell>
                            }
                        }
                    }
                
                    else if(header.accessor == 'validity')
                    {
                        if(this.state.editRecord && data["truckId"] == this.state.id && header.accessor == 'validity')
                        {
                            return <TableCell align = "left" style = {{paddingTop:'0px'}} >
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <span style={{fontSize: 'small',fontFamily: 'Open Sans, sans-serif'}}>&nbsp;&nbsp;&nbsp;&nbsp;</span> 
                                                <DatePicker
                                                    variant="inline"
                                                    // inputVariant="outlined"
                                                    value={this.state.validity}  
                                                    onChange={this.setStartDate}
                                                    format='dd/MM/yyyy'
                                                    autoOk
                                                    minDate={this.state.startDate}
                                                    minDateMessage=''
                                                    invalidDateMessage=''
                                                    disabled={(this.state.islicensedWithULB  && this.state.licensedWithULB) === true ? false : true}
                                                    style={{width:'100%', borderBottom: 'none'}}
                                                    InputProps={{ style:{fontSize:'small', padding:'8px' ,height:'35px',marginTop:'2px',border: '1px solid rgb(193, 193, 193)', 
                                                    borderRadius:'4px', fontFamily: 'Open Sans, sans-serif'},disableUnderline: true}}/>
                                        </MuiPickersUtilsProvider>    
                                    {this.state.validityErr ? <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.validityErr}</div> : <div style={{fontSize:12,color:"white", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>required</div>}
                                    </TableCell>
                        }
                 
                        else
                        {
                            const validity= moment(data[header.accessor]).format('DD-MMM-YYYY')
                                if(validity !=="Invalid date")
                                {
                                    return  <TableCell style={{whiteSpace: "normal", wordWrap: "break-word"}}>
                                                {validity}
                                            </TableCell>
                                }
                                else
                                {
                                    return <TableCell></TableCell>
                                }   
                        }
                    }
                    else if(header.accessor == 'actionButton')
                    {
                        if(this.state.editRecord && data["truckId"] == this.state.id && header.accessor == 'actionButton')
                        {
                            return  <TableCell align = "left">  
                            <IconButton
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={(e)=>this.handleClickMoreVertIconOnEdit(e)}
                                >
                                <MoreVertIcon />
                                </IconButton>
                                    <Popper
                                    open={this.state.open_onedit} anchorEl={this.state.anchorEl_onedit}>
                                        <ClickAwayListener onClickAway={this.handleClickAwayOnEdit}>
                                    <Paper>
                                        <MenuList>
                                        <MenuItem style={{color:'#3DAB35'}} onClick={this.onClickCancelEdit}>Cancel</MenuItem>
                                            <MenuItem style={{color:'#3DAB35'}} onClick={this.handleSave}>Save</MenuItem>
                                        </MenuList>
                                    </Paper>
                                    </ClickAwayListener>
                                </Popper>
                        </TableCell>
                        }
                        else 
                        {
                            return <TableCell>
                            <IconButton
                                    aria-label="more"
                                    aria-controls="long-menu"
                                    aria-haspopup="true"
                                    onClick={(e)=>this.handleClickMoreVertIcon(e,id,truckRegNo)}
                                    disabled={this.state.editRecord || this.state.isAddEnabled}
                                    >
                                    <MoreVertIcon />
                            </IconButton>
                                <Popper open={this.state.open} anchorEl={this.state.anchorEl}>
                                    <ClickAwayListener onClickAway={this.handleClickAway}>
                                        <Paper>
                                            <MenuList>
                                                <Link className='email_link'><MenuItem onClick={() => this.handleCall(this.state.id)}>Edit</MenuItem></Link>
                                                <Link to = { {pathname : `/truck-docs/${this.props.match.params.id}/${this.state.id}` , state:{truckRegNo : this.state.truckRegNo} }} className='email_link'> <MenuItem>Documents</MenuItem></Link>
                                                {localStorage.getItem('phase2') == 'true' && <div>
                                                <MenuItem style = {{color : ' #3dab35'}} onClick = {() => {
                                                    swal({ title    : "Are you sure?",
                                                           text     : "Once deleted, you will not be able to recover this data!",
                                                           icon     : "warning",
                                                           buttons  : {
                                                           cancel   : 'No',
                                                           confirm  : 'Yes'}
                                                    })
                                                    .then((willDelete) => {
                                                        if (willDelete) {
                                                            this.handleDelete(this.state.id);
                                                        } 
                                                    });
                                                }}>Delete</MenuItem></div>}
                                            </MenuList>
                                        </Paper>
                                    </ClickAwayListener>
                                </Popper>
                        </TableCell>
                        }
                    }        
                    else
                    {
                        return  <TableCell style={{whiteSpace: "normal", wordWrap: "break-word"}}>
                                    {data[header.accessor]}
                                </TableCell>
                    }
                })}
                
            </TableRow>
        })
        return (
            <div>
                <Paper elevation={0}>
                <div className="dashboard_right_content">
                        <img className="dashboard_right_content_top_icon" src="images/Rectangle.svg" />
                        <div className="dashboard_right_content_top">
                        <h5><Link to = "/operator-management" className="email_link"><ArrowBackIosIcon></ArrowBackIosIcon></Link>Truck Listing</h5>
                            <div className="dashboard_right_content_top_options"> 
                                <div className="dashboard_right_content_top_search">
                                    {/* <Paper component="form">
                                        <IconButton aria-label="menu">
                                            <img src="images/search_icon.svg" />
                                        </IconButton>  
                                        <InputBase onChange={(event) => this.inputChangedHadler(event)} placeholder="Search..." />
                                        <Button onClick={this.onClickSearch} variant="contained" color="primary" type = "submit">Search</Button>
                                    </Paper> */}
                                </div>  
                                <Button className="email_link"
                                    ref={this.state.anchorRef}
                                    aria-controls={this.state.open ? 'menu-list-grow' : undefined}
                                    aria-haspopup="true"
                                    onClick={this.handleClick('bottom')}
                                    style={{height:'35px',visibility:'hidden'}}
                            >
                                Bulk Import
                                {
                                    this.state.open ?   <ArrowDropUpIcon /> : <ArrowDropDownIcon/> 
                                }
                                
                            </Button>
                            <Popper open={this.state.open_bulk} anchorEl={this.state.anchorEl_bulk} placement={this.state.placement} transition>
                            {({ TransitionProps }) => (
                                    <ClickAwayListener onClickAway={this.handleClickAwayBulk}>
                                <Paper>
                                    <MenuList>
                                    <MenuItem onChange={this.onFileChange}> 
                                        <label style={{ color:'#3dab35',fontSize:'small',align:'left', fontFamily: "'Open Sans', sans-serif"}}> 
                                            <input type="file" style={{display:'none',align:'left'}} /> 
                                            Bulk Upload
                                        </label> 
                                        </MenuItem> 
                                    <a href={truckBulkUploadFile} style={{textDecoration:'none', 
                                                                        color:'#3dab35', 
                                                                        fontSize:'small', 
                                                                        fontFamily: "'Open Sans', sans-serif"}} download> 
                                        <MenuItem onClick={this.handleClose}>Download Sample</MenuItem>     
                                    </a>
                                    </MenuList>
                                </Paper>
                                </ClickAwayListener>
                            )}
                            </Popper>   
                            {!!this.props.location&&!!this.props.location.state&&this.props.location.state.searchByTruckNo=="searchTruck"?"": <Button   id="addNewRecord" variant="outlined" color="primary" onClick = {this.addNewTruck} disabled= {this.state.isDisabled}>Add New Truck</Button>      }   
                            </div>
                        </div>
                    </div>
                    <div className="operator_listing" >
                        <div style={{clear: 'both'}}><h5 align='left' style={{paddingLeft:'2%',float:'left'}}>Operator Name :</h5><h5 align='left' style={{color:'#3DAB35', paddingLeft:'1%',float:'left'}}>{localStorage.operatorName && localStorage.operatorName}</h5></div>
                             <TableContainer component={Paper} style={{paddingLeft:"15px", paddingRight:"15px", maxHeight:690}}>
                                 <Table stickyHeader aria-label="sticky table" style={{ tableLayout: 'fixed' }}>
                                     <colgroup>
                                       <col style={{width :'5%'}}/>
                                        <col style={{width:'10%'}}/>
                                        <col style={{width:'10%'}}/>
                                        <col style={{width:'8%'}}/>
                                        <col style={{width:'8%'}}/>
                                        <col style={{width:'9%'}}/>
                                        <col style={{width:'8%'}}/>
                                        <col style={{width:'10%'}}/>
                                        <col style={{width:'10%'}}/>
                                        <col style={{width:'10%'}}/>  
                                        <col style={{width:'10%'}}/>
                                        <col style={{width:'5%'}}/>  
                                   </colgroup>
                                    <TableHead >
                                        <TableRow >
                                            {truckHeaders}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                { this.state.isAddEnabled && 
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell align = "left" style = {{paddingTop:'18px'}}>
                                            <TextField type="text"
                                                    id="registrationNumber"
                                                    variant="outlined"
                                                    size = "small"
                                                    placeholder = "Truck No"
                                                    value={this.state.registrationNumber}
                                                    name="registrationNumber"
                                                    onChange={this.handleChange}
                                                    InputProps={{ style: { fontSize: 12 } }}/>
                                            { this.state.registrationNumberErr ? <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.registrationNumberErr}</div> : <div style={{fontSize:12,color:"white", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>required</div>}
                                        </TableCell>

                                        <TableCell style = {{paddingTop:'18px'}}>
                                            <FormControl variant="outlined" size="small">
                                            <Select value={this.state.vehicleTypeId} 
                                                    onChange={this.handleChange} 
                                                    name="vehicleTypeId" 
                                                    size="small" 
                                                    style={{fontSize:12,width:'100%'}}>
                                                        
                                                    {options.map((option) => (
                                                        <MenuItem key={option.id}
                                                                  style={{fontSize:12,width:'100%'}} 
                                                                  value={option.id}>
                                                                  {option.key}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                            {this.state.vehicleTypeIdErr ? <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.vehicleTypeIdErr}</div> :  <div style={{fontSize:12,color:"white", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>required</div>}
                                            </FormControl>
                                        </TableCell>

                                        <TableCell style = {{paddingTop:'18px'}}>
                                            <TextField type="number"
                                                        id="capacity"
                                                        variant="outlined"
                                                        size = "small"
                                                        placeholder = "Capacity"
                                                        value={this.state.capacity}
                                                        name="capacity"
                                                        onChange={this.handleChange}
                                                        InputProps={{ style: { fontSize: 12, } }}/>
                                            {this.state.capacityErr ? <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.capacityErr}</div> :  <div style={{fontSize:12,color:"white", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>required</div>}
                                        </TableCell>
                                        <TableCell align = "left" style = {{paddingTop:'18px'}}>
                                            <FormControl variant="outlined" size="small">
                                            <Select value={this.state.gpsInstalled} 
                                                    onChange={this.handleChange} 
                                                    name="gpsInstalled" 
                                                    size="small" 
                                                    style={{fontSize:12,width:'100%'}}>
                                                    {gpsInstalledOptions.map((option) => (
                                                        <MenuItem key={option.id}
                                                                  style={{fontSize:12,width:'100%'}} 
                                                                  value={option.key}>
                                                                  {option.name}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                            {<div style={{fontSize:12,color:"white", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>required </div> }
                                            </FormControl>
                                        </TableCell>
                                        <TableCell align = "left" style = {{paddingTop:'3px'}}>
                                        <FormControl variant="outlined" size="small">
                                            <Select value={this.state.isDisableTruck} 
                                                    onChange={this.handleChange} 
                                                    name="isDisableTruck" 
                                                    size="small" 
                                                    style={{fontSize:12,width:'100%'}}>
                                                    {isDisableTruck.map((option) => (
                                                        <MenuItem key={option.id}
                                                                  style={{fontSize:12,width:'100%'}} 
                                                                  value={option.key}>
                                                                  {option.name}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                            {/* {this.state.licensedWithULBErr ? <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%",}}>{this.state.licensedWithULBErr}</div> :<div style={{fontSize:12,color:"white", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>required </div> } */}
                                            </FormControl>
                                        </TableCell>


                                        <TableCell align = "left" style = {{paddingTop:'18px'}}>
                                            <FormControl variant="outlined" size="small">
                                            <Select value={this.state.licensedWithULB} 
                                                    onChange={this.handleChange} 
                                                    name="licensedWithULB" 
                                                    size="small" 
                                                    style={{fontSize:12,width:'100%'}}>
                                                    {licensedWithULBOptions.map((option) => (
                                                        <MenuItem key={option.id}
                                                                  style={{fontSize:12,width:'100%'}} 
                                                                  value={option.key}>
                                                                  {option.name}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                            {this.state.licensedWithULBErr ? <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%",}}>{this.state.licensedWithULBErr}</div> :<div style={{fontSize:12,color:"white", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>required </div> }
                                            </FormControl>
                                        </TableCell>
                                        <TableCell align = "center" >
                                        </TableCell>
                                        <TableCell align = "left" style = {{paddingTop:'18px'}}>
                                            <TextField type = "text"
                                                    id="referenceNumber"
                                                    variant="outlined"
                                                    size="small"
                                                    placeholder="Ref. No"
                                                    value={this.state.referenceNumber}
                                                    name="referenceNumber"
                                                    onChange={this.handleChange}
                                                    disabled={this.state.islicensedWithULB === true ? false : true}
                                                    InputProps={{ style: { fontSize: 12 } }} />
                                             {this.state.referenceNumberErr ? <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.referenceNumberErr}</div> : <div style={{fontSize:12,color:"white", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>required</div>}
                                        </TableCell>

                                        <TableCell align = "left" style = {{paddingTop:'0px'}}>
                                                {/* <DatePicker className='date-input-field' 
                                                            selected={this.state.validity} 
                                                            minDate={moment().toDate()}
                                                            onChange={this.setStartDate} 
                                                            placeholderText="&nbsp;&nbsp;&nbsp;&nbsp;mm/dd/yyyy"
                                                            disabled={this.state.islicensedWithULB === true ? true : false}
                                                            style={{height:'35px',borderRadius:'5px',width: '118px !important'}}
                                                /> */}
                                                   <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <span style={{fontSize: 'small',fontFamily: 'Open Sans, sans-serif'}}>&nbsp;&nbsp;&nbsp;&nbsp;</span> 
                                                    <DatePicker
                                                        variant="inline"
                                                        // inputVariant="outlined"
                                                        value={this.state.validity}  
                                                        onChange={this.setStartDate}
                                                        format='dd/MM/yyyy'
                                                        autoOk
                                                        minDate={this.state.startDate}
                                                        minDateMessage=''
                                                        disabled={this.state.islicensedWithULB === true ? false : true}
                                                        style={{width:'100%', borderBottom: 'none'}}
                                                        InputProps={{ style:{fontSize:'small', padding:'8px' ,height:'35px',marginTop:'2px',border: '1px solid rgb(193, 193, 193)', 
                                                        borderRadius:'4px', fontFamily: 'Open Sans, sans-serif'},disableUnderline: true}}/>
                                                    </MuiPickersUtilsProvider>    
                                             {this.state.validityErr ? <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.validityErr}</div> : <div style={{fontSize:12,color:"white", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>required</div>}
                                        </TableCell>
                                        <TableCell align = "center" >
                                        </TableCell>
                                        <TableCell align = "left">  
                                            <IconButton
                                                aria-label="more"
                                                aria-controls="long-menu"
                                                aria-haspopup="true"
                                                onClick={(e)=>this.handleClickMoreVertIconOnAdd(e)}
                                                >
                                                <MoreVertIcon />
                                                </IconButton>
                                                    <Popper
                                                    open={this.state.open_onadd} anchorEl={this.state.anchorEl_onadd}>
                                                        <ClickAwayListener onClickAway={this.handleClickAwayOnAdd}>
                                                    <Paper>
                                                        <MenuList>
                                                        <MenuItem style={{color:'#3DAB35'}} onClick={this.onClickCancelAdd}>Cancel</MenuItem>
                                                            <MenuItem style={{color:'#3DAB35'}} onClick={this.handleSave}>Save</MenuItem>
                                                        </MenuList>
                                                    </Paper>
                                                    </ClickAwayListener>
                                                </Popper>
                                        </TableCell>
                                    </TableRow>
                                }
                                  {truckData}
                                
                            </TableBody>
                            </Table>
                            </TableContainer>
                            </div>
                </Paper>
            </div>
        )
    }
}

const mapStateToProps = (state,props) => {
    const date = moment().format('MMMM Do YYYY, h:mm:ss a');
    return({
        token : state.auth.token,
        truckHeaders :!!props.location&&!!props.location.state&&props.location.state.searchByTruckNo=="searchTruck"?state.truckHeaders.truckHedaers: state.truckHeaders.truckHedaers.concat({header: "Action", accessor: "actionButton", sortable: "false", filter: "true"}),
        truckData : state.truckData.truckData,
        truckDataAddFail : state.truckData.error ? state.truckData.error.data.success : true
    })
}

const mapDispatchToProps = (dispatch) => {
    return({
        onFetchTruckHeaders : (payload) => dispatch(actionCreators.fetchTruckHeaders(payload)),
        onFetchTruckData : (payload,props) => dispatch(actionCreators.fetchTruckData(payload,props)),
        onAddTruckData : (payload,props) => dispatch(actionCreators.addTruckData(payload,props)),
        onFetchTruckBulkImport: (payload,formData,props) => dispatch(actionCreators.fetchTruckBulkImport(payload,formData,props)),
        onDeleteTruckData : (payload, token, props) => dispatch(actionCreators.deleteTruckData(payload, token, props)),
        onEditTruckData  : (payload,props) => dispatch(actionCreators.editTruckData(payload,props)),
        onSearchOperterTruckNumber:(payload, props)=>dispatch(actionCreators.searchOperterTruckNumber(payload, props))
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(TruckListing)