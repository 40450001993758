import React from 'react';
import ClusterForm from './clusterForm'
import { connect } from 'react-redux';
import * as actionsCreators from  '../../store/actions/index';
function ClusterAdd(props) {
    const handleSubmit = (formdata, SubmitProp, token)=>{
        props.onAddCUlbData(formdata,SubmitProp, props)

    }
    return (
        <div>
           <div className="create_ulb">
            <div className="dashboard_top_path">
                <p><img src="images/left-arrow.svg"  alt='cls'/> Cluster Management / <span>Create New Cluster</span></p>
            </div>
            <div className="dashboard_right_content">
                <img className="dashboard_right_content_top_icon" src="images/Rectangle.svg" />
                <div className="dashboard_right_content_top">
                    <h5>Create New Cluster</h5>
                </div>
                <div className="create_ulb_content_outer">
                    <div className="create_ulb_content">
                        
                       <ClusterForm  handleSubmit={handleSubmit}/>
                       
                    </div>
                </div>
            </div>
        </div> 
        </div>
    );
}

const mapStateToProps = (state,props) => {
    return {
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onAddCUlbData :(formdata,SubmitProp, props)=>dispatch(actionsCreators.addClusterUlbData(formdata,SubmitProp,props))
    }
}
export default  connect(mapStateToProps, mapDispatchToProps)(ClusterAdd);