import * as actionTypes from '../actionsTypes'
import axios from '../../../http-config'

export const fetchUlbHeadersStart = () => {
    return({
        type : actionTypes.FETCH_ULB_HEADERS_START
    })
}

export const fetchUlbHeadersSuccess = (response) => {
    return ({
        type     : actionTypes.FETCH_ULB_HEADERS_SUCCESS,
        headers  : response.data.headers
    })
}

export const fetchUlbHeadersFail = (error) => {
    return ({
        type     : actionTypes.FETCH_ULB_HEADERS_FAIL,
        error    : error
    })
}

export const fetchUlbHeaders = (payload) => {
    let token = payload.token
    return (dispatch) => {
        dispatch(fetchUlbHeadersStart())
        axios({
            method  : 'get',
            url     : 'ulb/headers',
            headers : {'Authorization': `Bearer ${token}`}
        })
        .then((response) => {
            dispatch(fetchUlbHeadersSuccess(response.data))
        })
        .catch((err) => {
            dispatch(fetchUlbHeadersFail(err))
        })
    }
}