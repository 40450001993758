import React, { Component } from 'react'
import { connect } from 'react-redux'
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { Grid } from '@material-ui/core';
import {Link} from 'react-router-dom'
import Button from '@material-ui/core/Button';
import * as actionsCreators from  '../../store/actions/index';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import axios from '../../http-config';

class ZoneForm extends Component{
    constructor(props) {
        super(props)
        this.state = {
            id                     : this.props.Id ? this.props.Id:'',
            zoneNameTm             : this.props.Name_tn && this.props.Name_tn != "null"? this.props.Name_tn : "",
            zoneName               : this.props.Name    ? this.props.Name    : "",
            zoneNameErr            : '',
            
            zoneNameTmErr          : ''  ,
           // ULBId                   : this.props.ULBId? this.props.ULBId : "",
           ulbOptions              : [],
           ulbName                 : this.props.ULBId           ? this.props.ULBId         : "",
        }
    }

    componentDidMount(){
        //To get UlB options 
        axios({
            method: 'get',
            url: '/load_template/master_data',
            headers: { 'Authorization': `Bearer ${this.props.token}` }
        })
            .then((response) => {
                if (response.data.data) {
                    this.setState({
                        ulbOptions : response.data.data['master-data'][3].data,
                    })
                }

            })
        let payload = {
            token:this.props.token
        }
        this.props.onFetchUlbData(payload);
    }

    handleSubmit = (e) =>{
        e.preventDefault()
        const err = this.checkValidity()
        if(!err){
            const props=this.props
            const formdata={
                id         : this.state.id,
                token      : this.props.token,
                name       : this.state.zoneName,
                name_tn    : this.state.zoneNameTm,
                ulbName    : this.state.ulbName,
              //  ulbId       : this.state.ULBId
            }
            this.props.handleSubmit(formdata,props)
        }
    }
    handleChange = (e) => {
        this.setState({[e.target.name] : e.target.value}, ()=>{
            this.checkValidity()
        })   
    }
    
    checkValidity = () => {
        let isError = false
        if(!this.state.zoneName){
            isError=true
            this.setState({zoneNameErr:'*This field is required'})
        }
        // if(!this.state.zoneNameTm){
        //     isError=true
        //     this.setState({zoneNameTmErr : "*This field is required"})
        // }
        return isError
    }

    // handleChangeSelectUlb = (data)=>{
    //     this.setState({ULBId:data.ulbId})
    // }

    render(){
        return (
            <div>
                <div className="create_ulb_content_outer">
                    <div className="create_ulb_content">
                    <Grid container spacing={1}>
                    {/* Added UlB Name Feild */}
                    <Grid item xs={6}>
                <FormControl>
                    <label className="required" htmlFor="ulbName" align="left">ULB Name</label>
                    <Select value={this.state.ulbName} onChange={this.handleChange} name="ulbName">
                                {this.state.ulbOptions.map((option) => (
                                    <MenuItem value={option.id}>{option.name}</MenuItem>
                                ))}
                            </Select> 

                        <div  style={{fontSize:12,color:"red"}}>{this.state.ulbNameErr}</div>   
                </FormControl>
            </Grid>

                        <Grid item xs={6}>
                        <FormControl>
                            <label className="required" htmlFor="zoneName">Zone Name</label>
                            <TextField id="zoneName" 
                                    variant="outlined" 
                                    placeholder="Enter Zone Name" 
                                    name="zoneName" 
                                    value={this.state.zoneName} 
                                    onChange={this.handleChange}/>
                            <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.usernameErr}</div>
                        </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                        <FormControl>
                            <label htmlFor="zoneNameTm">Zone Name(Tamil)</label>
                            <TextField id="zoneNameTm" 
                                variant="outlined" 
                                placeholder="மண்டல பெயரை உள்ளிடவும்" 
                                name="zoneNameTm" 
                                value={this.state.zoneNameTm} 
                                onChange={this.handleChange}/>
                            <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.emailErr}</div>
                        </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <div className="create_ulb_buttons">
                            <Link style={{textDecoration:"none", color:"#3dab35"}} to={localStorage.getItem('isZoneNotRequired') === 'true'? '/ward-management':'/zone-management'}><Button className="login_submit" variant="outlined" color="primary">Cancel</Button></Link>
                            <Button className="login_submit" variant="contained" color="primary" onClick ={this.handleSubmit}>Save</Button>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
        )
    }    
}

const mapStateToProps = (state) => ({
    token : state.auth.token,
    ulbData: state.ulbData.ulbData,
})


const mapDispatchToProps = dispatch => {
    return {
        onFetchUlbData: (payload) => dispatch(actionsCreators.fetchUlbData(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ZoneForm)