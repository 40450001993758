import * as actionTypes from '../actionsTypes'
import axios from '../../../http-config'

export const fetchTruckDocDataStart = () => {
    return ({
        type : actionTypes.FETCH_TRUCK_DOC_DATA_START
    })
}
export const fetchTruckDocDataSuccess = (response) => {
    return ({
        type : actionTypes.FETCH_TRUCK_DOC_DATA_SUCCESS,
        truckDocs : response.data.TruckDocuments
    })
}
export const fetchTruckDocDataFail = (err) => {
    return ({
        type : actionTypes.FETCH_TRUCK_DOC_DATA_FAIL,
        error : err
    })
}
export const fetchTruckDocData = (payload, props) => {
    const token = payload.token
    return (dispatch) => {
        dispatch(fetchTruckDocDataStart())
        const url = `/truck/documents?truckId=${props.match.params.id}`
        axios({
            method : 'get',
            url : url,
            headers : {'Authorization' : `Bearer ${token}`}
        })
        .then(response => {
            dispatch(fetchTruckDocDataSuccess(response.data))
        })
        .catch(err => {
            dispatch(fetchTruckDocDataFail())
        })
    }
}
export const addTruckDocData = (payload, token, props) => {
    const tokenData = {
        token 
    }
    const url = `/truck/documents?truckId=${props.match.params.id}`
    return (dispatch) => {
        dispatch(fetchTruckDocDataStart())
        axios.post(url, payload, { headers : { "Authorization":`Bearer ${token}`}})
        .then(response => {
            dispatch(fetchTruckDocData(tokenData, props ))
        })
        .catch(err => {
            dispatch(fetchTruckDocDataFail(err))
        })
    }
}
export const editTruckDocData = (payload, props) => {
    const token = payload.token
    const id = ''
    const payload2 = {
        registrationNumber : payload.registrationNumber,
        vehicleTypeId      : payload.vehicleTypeId,
        licensedWithULB    : payload.licensedWithULB,
        referenceNumber    : payload.referenceNumber,
        validity           : payload.validity,
        operatorId         : id
    }
    const url =''
    return (dispatch) => {
        dispatch(fetchTruckDocDataStart())
        axios.put(url, payload2, { headers : { "Authorization":`Bearer ${token}`}})
        .then(response => {
            dispatch(fetchTruckDocData())
        })
        .catch(err => {
            dispatch(fetchTruckDocDataFail(err))
        })
    }
}