import React , {useState, useEffect} from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import {Link, Redirect} from 'react-router-dom';
import swal from 'sweetalert';
import App from '../../../App';
import Divider from "@material-ui/core/Divider";
import axios from '../../../http-config';
import {STATEDASHBOARDUSER,ADMIN,ULBADMIN, SANITARYINSPECTOR} from '../../../../src/config/constant'

function Navigationbar(props) {
    const [expanded, setExpanded] = React.useState('panel1');
    const [selectedIndex, setSelectedIndex] = React.useState(Number(localStorage.getItem('index')));
    const [isRoleAccessModule, setIsRolesModule] = React.useState([])
    const [accessWeb, setAccessWeb] = React.useState(null)
    const handleListItemClick = (event, index) => {
        localStorage.setItem('index', index)
      setSelectedIndex(index);
      if(props.openState === true){
        props.functionhandle()
      }
     
    };

    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

    const logout = () => {
        swal({
            title: "Are you sure?",
            icon: "warning",
            buttons: {
                cancel : 'No',
                confirm: 'Yes'
            },
            // dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
              swal("Logged out successfully!", {
                icon: "success",
              });
              localStorage.removeItem('token');
              localStorage.removeItem('expirationDate');
              localStorage.removeItem('userId');
              localStorage.removeItem('index')
              localStorage.removeItem('truckRegNo')
              localStorage.removeItem('operatorName')
              localStorage.removeItem('allowedComponents')
              localStorage.removeItem('roles')
              localStorage.removeItem('roleName')
              localStorage.removeItem('ulbId')
              localStorage.removeItem('summmaryReportsEmail')
              localStorage.removeItem('ReportsEmail')
              localStorage.removeItem('StatedashboardValue')
              switch(window.location.hostname){
                case process.env.REACT_APP_STAGING_HOST:
                case process.env.REACT_APP_DEV_HOST_NAME:
                    window.location = '/';
                    break;
        
                case process.env.REACT_APP_PROD_HOST:
                case process.env.REACT_APP_LOCAL_HOST_NAME:
                    window.location = '/'; 
                    break; 
                    
                default: 
                    window.location = '/';      
                }
            } else {
            }
          });
    };
   

    const rolesId = localStorage.getItem('UserIdSave');
    const ulbid    = JSON.parse(localStorage.getItem('ulbid'))

    const [currentUlb, setUlb] = useState({}); 

    useEffect(() => {
        const payload = {
                        token : localStorage.getItem('token'),
        }
        
        if(ulbid !== null){
        const url = `/ulb/${ulbid}`
        axios({
                        method   : 'get',
                        url      : url,
                        headers  : {'Authorization': `Bearer ${payload.token}`}
                   })
                   .then((response) => {
                       setUlb(response.data.data.ulbs[0])
                       localStorage.setItem("isZoneNotRequired", response.data.data.ulbs[0].isZoneNotRequired)
                       localStorage.setItem('summmaryReportsEmail',response.data.data.ulbs[0].summmaryReportsEmail)
                       localStorage.setItem('ReportsEmail',response.data.data.ulbs[0].email)
                       localStorage.setItem("StatedashboardValue", response.data.data.ulbs[0].statedashboardValue == "Yes"? true:false )

                   })
                   .catch((err) => {
                       
                   })
                }

        if(rolesId !== null){
            const urlUser =`/user/${rolesId}` 
            axios({
                method   : 'get',
                url      : urlUser,
                headers  : {'Authorization': `Bearer ${payload.token}`}
           })
           .then((response) => {
             setIsRolesModule(!!response.data.data.users? response.data.data.users[0].isModuleAccess: [])
             setAccessWeb(!!response.data.data.users&&!!response.data.data.users[0].isRoleAccess? response.data.data.users[0].isRoleAccess.IsWebAppAccess:null)

           })
           .catch((err) => {
               
           })
        }
          
      },[]);
    return (
        <div className="dashboard_left">
            {accessWeb !== null&&accessWeb== true?
    
            <>
            <div className="dashboard_left_toggle">
                {/* <img src="images/left_toggle.svg" alt="left toggle" /> */}
            </div>
            <div className="dashboard_left_user">
               
                <div className="dashboard_left_user_pic">
                    {currentUlb&&currentUlb.logo ? <img src={currentUlb.logo} alt="side nav user" /> : <img src="images/Side-Nav-User.svg" alt="side nav user" />}
                </div>
                {localStorage.getItem('roleName') != ADMIN &&
                    <h5>{currentUlb== undefined ? "" : currentUlb.name}</h5>
                }  
            </div>
            <div className="dashboard_left_accordion">

            <List component="nav" aria-label="main mailbox folders">
              
                {!!isRoleAccessModule&& isRoleAccessModule.IsDashboardAccess== true? <div>
                    <ListItem   button
                                selected={selectedIndex === 0}
                                onClick={(event) => handleListItemClick(event, 0)}>
                        <Link style={{color:'black',align:'left', backgroundColor:'#12345600', border:'0px', paddingLeft: '0px'}} to='/'
                          onClick={handleChange('panel1')} className="dashboard_left_logout"><img src="images/summary.svg" /> 
                          <span className="logout-label"> Dashboard </span></Link>
                    </ListItem>
                    <Divider />
                </div>:''}
            
                { !!isRoleAccessModule && isRoleAccessModule.IsStateDashboardAccess=== true? <div>
                    <ListItem   button
                                selected={selectedIndex === 7}
                                onClick={(event) => handleListItemClick(event, 7)}>
                        <Link style={{textDecoration:'none' ,color:'black', backgroundColor:'#12345600', border:'0px', paddingLeft: '0px'}} to={localStorage.getItem('roleName') == "State/Regional Officer" || localStorage.getItem('roleName') == ADMIN ? '/' :'/state-dashboard'}  
                        onClick={handleChange('panel4')} className="dashboard_left_logout"><img src="images/operator.svg" /> <span className="logout-label"> Dashboard </span></Link>           
                    </ListItem>
                    <Divider />
                </div>:''}
                {!!isRoleAccessModule&& isRoleAccessModule.IsULBManagementAccess== true? <div>
                    <ListItem   button 
                                selected={selectedIndex === 8} onClick={(event) => handleListItemClick(event, 8)}>
                        <Link style={{color:'black', align:'left', backgroundColor:'#12345600', border:'0px', paddingLeft:'0px'}} to='/ulb-management' 
                        onClick={handleChange('panel1')} className="dashboard_left_logout"><img src="images/Select ULB.svg" /><span className="logout-label"> 
                        ULB Management </span> </Link>
                    </ListItem>
                    <Divider />
                </div>: ''}
                { !!isRoleAccessModule && isRoleAccessModule.IsIOTDashboardAccess=== true? <div>
                    <ListItem  button selected={selectedIndex === 6} onClick={(event) => handleListItemClick(event, 6)}>
                        <Link style={{textDecoration:'none' ,color:'black', backgroundColor:'#12345600', border:'0px', paddingLeft: '0px'}}
                         to={'/iotDashboard'} 
                          onClick={handleChange('panel6')}  className="dashboard_left_logout"><img src="images/operator.svg" />
                           <span className="logout-label"> IOT Dashboard </span></Link>           
                    </ListItem>
                    <Divider />
                </div>:""}

                {!!isRoleAccessModule&& isRoleAccessModule.IsPropertyManagementAccess== true?<div>
                    {localStorage.getItem('phase2') == 'true' && <div>
                        <ListItem   button
                                    selected={selectedIndex === 5}
                                    onClick={(event) => handleListItemClick(event, 5)}>
                            <Link style={{color:'black',align:'left', backgroundColor:'#12345600', border:'0px', paddingLeft: '0px'}} to={localStorage.getItem('roleName') == "ULB officer"  || localStorage.getItem('roleName') === SANITARYINSPECTOR ? '/' :'/property-management'}   onClick={handleChange('panel1')} className="dashboard_left_logout"><img src="images/Select ULB.svg" /> <span className="logout-label"> Property Management </span></Link>
                        </ListItem>
                        <Divider />
                    </div>}
                </div>:''}
                           
                {!!isRoleAccessModule&& isRoleAccessModule.IsSchedulePropertyAccess== true? <div>
                    <ListItem   button
                                selected={selectedIndex === 8}
                                onClick={(event) => handleListItemClick(event, 8)}>
                        <Link style={{textDecoration:'none' ,color:'black', backgroundColor:'#12345600', border:'0px', paddingLeft: '0px'}} to='/schedule-property/allData' onClick={handleChange('panel1')} className="dashboard_left_logout"><img src="images/records.svg"/> <span className="logout-label">  Schedule Properties </span></Link>           
                    </ListItem>
                    <Divider />
                </div>:''}
             
                {!!isRoleAccessModule&& isRoleAccessModule.IsTreatmentFacilityAccess== true?<div>
                    <ListItem   button
                                selected={selectedIndex === 17}
                                onClick={(event) => handleListItemClick(event, 17)}>
                        <Link style={{color:'black',align:'left', backgroundColor:'#12345600', border:'0px', paddingLeft: '0px'}} to='/treatment'  onClick={handleChange('panel1')} className="dashboard_left_logout"><img src="images/Select ULB.svg" /> <span className="logout-label"> Disposal Facility </span></Link>
                    </ListItem>
                    <Divider />
                </div>:''}

                {!!isRoleAccessModule&& isRoleAccessModule.IsOperatorManagementAccess== true? <div>
                    <ListItem   button
                                selected={selectedIndex === 2}
                                onClick={(event) => handleListItemClick(event, 2)}>
                        <Link style={{textDecoration:'none' ,color:'black', backgroundColor:'#12345600', border:'0px', paddingLeft: '0px'}} to='/operator-management'  onClick={handleChange('panel4')} className="dashboard_left_logout"><img src="images/operator.svg" /> 
                        <span className="logout-label"> Operator Management </span></Link>           
                    </ListItem>
                    <Divider />
                </div>:''} 
                                          
                {!!isRoleAccessModule&& isRoleAccessModule.IsLoadRecordAccess== true? <div>
                    <ListItem   button
                                selected={selectedIndex === 3}
                                onClick={(event) => handleListItemClick(event, 3)}>
                        <Link style={{textDecoration:'none' ,color:'black', backgroundColor:'#12345600', border:'0px', paddingLeft: '0px'}} to='/records/all' onClick={handleChange('panel1')} className="dashboard_left_logout"><img src="images/records.svg"/> <span className="logout-label"> Load Records </span></Link>           
                    </ListItem>
                    <Divider />
                </div>:''}

                {
                localStorage.getItem("roleName") =="ULB Admin" && <div>
                    <ListItem   button 
                                selected={selectedIndex === 52}
                                onClick={(event) => handleListItemClick(event, 52)}>
                    <Link style={{color:'black', align:'left', backgroundColor:'#12345600', border:'0px', paddingLeft:'0px'}}  to='/Sanitory-Manegement'
                    onClick={handleChange('panel1')} className="dashboard_left_logout"><img src="images/Select ULB.svg" /> ULB Officer Management</Link> 
                    </ListItem>
                    <Divider />
                </div>
                }
                  {!!isRoleAccessModule && isRoleAccessModule.IsRoleManagementAccess=== true?  <div>
                    <ListItem   button
                                selected={selectedIndex == 1}
                                onClick={(event) => handleListItemClick(event, 1)}>
                        <Link style={{color:'black', align:'left', backgroundColor:'#12345600', border:'0px', paddingLeft:'0px'}} to='/role-management' 
                        onClick={handleChange('panel1')} className="dashboard_left_logout"><img src="images/Select ULB.svg" />Role Management </Link> 
                    </ListItem>
                    <Divider />
                </div>: ''}

                {!!isRoleAccessModule && isRoleAccessModule.IsUserManagementAccess === true?  <div>
                    <ListItem   button 
                                selected={selectedIndex === 22}
                                onClick={(event) => handleListItemClick(event, 22)}>
                    <Link style={{color:'black', align:'left', backgroundColor:'#12345600', border:'0px', paddingLeft:'0px'}} to='/user-management'
                     onClick={handleChange('panel1')} className="dashboard_left_logout"><img src="images/Select ULB.svg" />User Management </Link> 
                    </ListItem>
                    <Divider />
                </div>:''}

                {!!isRoleAccessModule &&isRoleAccessModule.IsWardManagementAccess=== true?   <div>
                    <ListItem   button
                                selected={selectedIndex == 11}
                                onClick={(event) => handleListItemClick(event, 11)}>
                        <Link style={{color:'black', align:'left', backgroundColor:'#12345600', border:'0px', paddingLeft:'0px'}} to= { localStorage.getItem('isZoneNotRequired') === 'true'? '/ward-management':'/zone-management'} 
                         onClick={handleChange('panel1')} className="dashboard_left_logout"><img src="images/Select ULB.svg" />{localStorage.getItem('isZoneNotRequired') === 'true'? 'Ward Management' :' Zone Management'}  </Link> 
                    </ListItem>
                    <Divider />
                </div>:''}
                
                {!!isRoleAccessModule && isRoleAccessModule.IsClusterULBManagementAccess === true?  <div>
                    <ListItem   button 
                                selected={selectedIndex === 12}
                                onClick={(event) => handleListItemClick(event, 12)}>
                    <Link style={{color:'black', align:'left', backgroundColor:'#12345600', border:'0px', paddingLeft:'0px'}} to='/cluster-management'
                     onClick={handleChange('panel1')} className="dashboard_left_logout"><img src="images/Select ULB.svg" />Cluster Management </Link> 
                    </ListItem>
                    <Divider />
                </div>:''}
                {
                localStorage.getItem("roleName") =="ULB Admin" && <div>
                    <ListItem   button 
                                selected={selectedIndex === 32}
                                onClick={(event) => handleListItemClick(event, 32)}>
                    <Link style={{color:'black', align:'left', backgroundColor:'#12345600', border:'0px', paddingLeft:'0px'}} to='/setting'
                    onClick={handleChange('panel1')} className="dashboard_left_logout"><img src="images/Select ULB.svg" />Setting </Link> 
                    </ListItem>
                    <Divider />
                </div>
                }


                {localStorage.getItem("roleName") == ADMIN ?  <div>
                    <ListItem   button 
                                selected={selectedIndex === 456}
                                onClick={(event) => handleListItemClick(event, 456)}>
                    <Link style={{color:'black', align:'left', backgroundColor:'#12345600', border:'0px', paddingLeft:'0px'}} to='/regionalOfficer'
                     onClick={handleChange('panel1')} className="dashboard_left_logout"><img src="images/Select ULB.svg" />State/Regional Officer </Link> 
                    </ListItem>
                    <Divider />
                </div>:''}
              
               

                <ListItem   button
                            selected={selectedIndex === 4}
                            onClick={(event) => handleListItemClick(event, 4)}>
                    <div style={{textDecoration:'none' ,color:'black', backgroundColor:'#12345600', border:'0px', paddingLeft: '0px'}} onClick={logout} className="dashboard_left_logout"><img src='images/logout.svg'/> <span className="logout-label"> Logout </span></div>
                </ListItem>
                <Divider />
                
            </List>

            </div>
          </>  :''}
        </div>
    );
}

export default Navigationbar;


