import React, { Component, useEffect } from 'react';
// import * as React from 'react';
import { makeStyles } from '@material-ui/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';

import { Link } from 'react-router-dom'
import axios from '../../../http-config';
import Checkbox from '@material-ui/core/Checkbox';
import './RecordKeeper.css';
import * as actionsCreators from '../../../store/actions/index';
import InputBase from '@material-ui/core/InputBase';
import ReactPaginate from 'react-paginate';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import myfile from '../../../BulkLoadUploadDocument.xlsx'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'

import * as User from '../../../config/constant';
import { withStyles, } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography'
import Radio from '@material-ui/core/Radio';
import clsx from 'clsx';
import { compact } from 'lodash';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogPaper: {
    minHeight: '80vh',
    maxHeight: '80vh',
},
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

class SameLoads extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: "",
      truckId: "",
      limit: 10,
      page: 1,
      open_icon: false,
      anchorEl_icon: null,
      same_loads_id: "",
      value: 0,

      loadId: "",
      operatorValue: "",
      operatorName: '',
      registrationNumber: "",
      areaOptions: [],
      propertyOptions: [],
      containmentOptions: [],
      quantityError: "",
      phError: "",
      orpError: "",
      ecError: "",
      infoError: "",
      dataLoaded: false,
      operatorI: [],
      propertyI: [],
      validation: [],
      photo_receipt: false,
      photo_tfBedNumber: false,

      editRecord: false,
      truckRegistrationNumber: "",
      registrationNumberErr: "",
      operatorName: "",

      open_onedit: false,
      anchorEl_onedit: null,
      formValue: [],
      dataState: {},
      fixedID: {},
      storeValue: "",
      storeHeader: "",
      optionsOPerator: [],
      operatorSTValue: "",
      TruckSTValue:"",
      optionsTruck:[],
      quentityST:"",
      operatorEditValue:"",
      TruckEditValue:"",
      editRecordTruck:false,
      QuantityEditValue:"",
      editRecordQuantity:false,
      selectOption:"",
      propertTypeValue:"",
      propertyTypeName:"",
      editRecordPropertyTypeName:false,
      ULbListingOptions:[],
      ULBValue:"",
      editRecordUlbName:false,
      ulbNameEditValue:""
    }
  }
  componentDidMount() {
    const ulbid = localStorage.getItem('ulbid')
    // // const s = this.state.operatorValue.split("-")
    let payload = {
      token: this.props.token,
      // limit: this.state.limit,
      // page: this.state.page,
      // loadsId: !!this.props && !!this.props.selectedData ? this.props.selectedData: [],
      ulbId: ulbid,
      // operatorId: s[0]
    }
    // this.props.onSameLoadsListing(payload)
    this.props.onFetchHeaders(payload)
    axios({
      method: 'get',
      url: '/load_template',
      headers: { 'Authorization': `Bearer ${payload.token}` }
    })
      .then((response) => {
        this.setState({ validation: response.data.data.loads })
      })
    axios({
      method: 'get',
      url: '/load_template/master_data',
      headers: { 'Authorization': `Bearer ${payload.token}` }
    })
      .then((response) => {
        this.setState({ propertyOptions: response.data.data['master-data'][1].data, containmentOptions: response.data.data['master-data'][5].data,
        ULbListingOptions : response.data.data['master-data'][3].data})
      })


    this.props.onFetchPropertyTypesEdit(payload)
    this.setState({ ulbId: ulbid, dataLoaded: true })

    // /operator/?statistics=true
    // axios({
    //   method: 'get',
    //   url: '/operator/operator/getop',
    //   headers: { 'Authorization': `Bearer ${payload.token}` }
    // })
    //   .then((response) => {
    //     this.setState({ optionsOPerator: response.data.data.operators })
    //   })


  }
  handleChange = (e) => {  
    if(e.target.name === "operatorSTValue"){
      const splitOP = e.target.value.split("-")
      axios({
        method: 'get',
        url: `/truck?operatorId=${splitOP[0]}`,
        headers: { 'Authorization': `Bearer ${this.props.token}` }
      })
        .then((response) => {
          this.setState({ optionsTruck: response.data.data.trucks, editRecord:true, operatorEditValue:splitOP[1]  })
        })
    }
    if(e.target.name === "TruckSTValue"){
      const splitTruck = e.target.value.split("+")
      this.setState({editRecordTruck:true, TruckEditValue: splitTruck[1] })
    }
    if(e.target.name === "quentityST"){
      this.setState({editRecordQuantity:true, QuantityEditValue: e.target.value})
    }
    if(e.target.value === "selectedOperator"){
      axios({
        method: 'get',
        url: '/operator/operator/getop',
        headers: { 'Authorization': `Bearer ${this.props.token}` }
      })
        .then((response) => {
          this.setState({ optionsOPerator: response.data.data.operators })
        })
      this.setState({editRecordQuantity:false,quentityST:"", editRecordPropertyTypeName:false , propertTypeValue:"" , editRecordUlbName:false, ULBValue:""})
    }

    if(e.target.value === "selectedQuentitiy"){
      this.setState({editRecordQuantity:true, editRecord:false, operatorEditValue:"", editRecordTruck:false, TruckEditValue: "", TruckSTValue:"", operatorSTValue:"", editRecordPropertyTypeName:false , propertTypeValue:"", editRecordUlbName:false, ULBValue:""})
    }
    if(e.target.name === "propertTypeValue"){
      const spP = e.target.value.split("-")
      this.setState({propertyTypeName:spP[1], editRecordPropertyTypeName: true, ULBValue:""})
    }
    if(e.target.value === "selectedPropertyType" ) {
      this.setState({ propertTypeValue: "",editRecordUlbName:false, ULBValue:""})
    }

    if(e.target.name === "ULBValue"){
      const spU = e.target.value.split("-")
      this.setState({ulbNameEditValue:spU[1], editRecordUlbName: true})
    }
    if(e.target.value === "selectedULB" ) {
      this.setState({ ULBValue: "",editRecordPropertyTypeName:false,propertTypeValue:""  })
    }
    this.setState({ [e.target.name]: e.target.value})
  }

  handleClickMoreVertIcon = (event, id, truckId, operatorName) => {
    this.setState({ open_icon: !this.state.open_icon, anchorEl_icon: event.currentTarget, id: id, truckId, operatorName })
  }

  handleClickAwayEdit = () => {
    this.setState({
      open_icon: false
    });
  };

  handleClickMoreVertIconOnEdit = (event, id) => {
    this.setState({ open_onedit: !this.state.open_onedit, anchorEl_onedit: event.currentTarget })
  }
  handleClickAwayOnEdit = () => {
    this.setState({ open_onedit: false });
  };

  onClickCancelEdit = (e) => {
    this.setState({ editRecord: false, })
  }
  handleSave = (e) => {
    this.setState({ open_onedit: false, editRecord: false })

  }
  handleUpdateLoads =(e) => {

    let payload ={
      selectedLoadsId :this.props.selectedData,
      propertyIds:this.props.selectedPropertyData,
      propertyIdTypeId:this.props.selectedPropertyType,
      selectedUlB:this.state.ULBValue,
      TruckId :this.state.TruckSTValue? parseInt(this.state.TruckSTValue.split("+")[0]):"",
      OperatorId:this.state.operatorSTValue? parseInt((this.state.operatorSTValue).split("-")[0]):"",
      CarryingQuantity : this.state.QuantityEditValue !== "" ?  parseInt(this.state.QuantityEditValue) :"",
      page:this.state.page,
      propertyTypeId:this.state.propertTypeValue ? parseInt(this.state.propertTypeValue.split("-")[0]) :"",
      ulbId: this.state.ULBValue ? parseInt( this.state.ULBValue.split("-")[0]) :""
    }
    this.props.onMultipleLoads(payload, this.props.loadListing)
   this.handleCloseLoads()
  } 

  handleCloseLoads =()=>{
    this.props.handleClose(false)
    this.setState({  
      optionsOPerator: [],
      operatorSTValue: "",
      TruckSTValue:"",
      optionsTruck:[],
      quentityST:"",
      operatorEditValue:"",
      TruckEditValue:"",
      editRecordTruck:false,
      QuantityEditValue:"",
      editRecordQuantity:false,
      editRecordPropertyTypeName:false ,
      propertTypeValue:"",
      selectOption:"",
    ULBValue:"",
  editRecordUlbName:false,
   })
  }
  handlePageClick = (e) => {
    const ulbid = localStorage.getItem('ulbid')
    this.setState({
        activePage : e.selected
    })
    const selectedPage = e.selected;
    let payload = {
        token: this.props.token,
        page:  selectedPage + 1,
        limit: this.state.limit,
        loadsId: this.props.selectedData,
        propertyIds: this.props.selectedPropertyData,
        propertyIdTypeId:this.props.selectedselectedPropertyId,
        ulbId: ulbid,
    }
    this.props.onSameLoadsListing(payload)

};
  render() {
    let array = this.props.headers
    const fixedCols = array.map(col => {
      if(col.accessor == "checkbox"){
        col.header = <div className=""></div>
      }
      return <TableCell>
        <div style={
          col.accessor == "checkbox" ? { width: "0px" } :
            {
              display: 'flex',
              alignItems: 'start',
              height: '3em',
              paddingLeft: '10px',
              paddingRight: '10px',
              whiteSpace: 'break-spaces',
              width: "220px"



            }}>
          {col.header}
        </div>
      </TableCell>


    }
    );

    let loads = this.props.loads.map(load => {
      const id = load.loadId
      const truckId = load.truckRegistrationNumber
      const operatorName = load.operatorName
      return <TableRow key={load.id}>
        {array.map(col => {

          if (col.accessor == 'photo') {
            if (load['photo']) {
              return <TableCell style={{ lineHeight: '56px', alignItems: 'right' }}> <a className="email_link" href={load['photo']} target="_blank">View</a></TableCell>
            }
            else {
              return <TableCell style={{ lineHeight: '56px', alignItems: 'right' }}></TableCell>
            }

          }
          else if (col.accessor == 'area') {
            return <TableCell>{(load['area'] && load['area'] != ' ') ? load['area'] : load['area_tn']}</TableCell>
          }
          // else if (col.accessor == 'ulbName') {
          //   return <TableCell>{(load['ulbName'] && load['ulbName'] != ' ') ? load['ulbName'] : load['ulbName_tn']}</TableCell>
          // }
          else if (this.state.editRecord == true && load["loadId"] == id && col.accessor == 'operatorName') {
            return <TableCell style={{ paddingTop: '18px', whiteSpace: "normal", wordWrap: "break-word", }}>
              <TextField type="text"
                id={col.accessor}
                variant="outlined"
                size="small"
                disabled={true}
                style={{ width: "100%" }}
                value={this.state.operatorEditValue}
                name={"operatorEditValue"}
                onChange={(e) => this.handleChange(e)}
                InputProps={{ style: { fontSize: 12, } }} />
              {/* {this.state.operatorNameErr ? <div style={{ fontSize: 12, color: "red", fontFamily: "'Open Sans', sans-serif", marginTop: "1%" }}>{this.state.operatorNameErr}</div> : <div style={{ fontSize: 12, color: "white", fontFamily: "'Open Sans', sans-serif", marginTop: "1%" }}>required</div>} */}
            </TableCell>
          }
          else if (col.accessor == 'propertyName') {
            return <TableCell style={{ lineHeight: '56px' }} className={"white-space"} >{(load['propertyName'] && load['propertyName'] != ' ') ? load['propertyName'] : load['propertyName_tn']}</TableCell>
          }

          else if (this.state.editRecordTruck == true && load["loadId"] == id && (col.accessor == 'truckRegistrationNumber')) {
            return <TableCell style={{ paddingTop: '18px', whiteSpace: "normal", wordWrap: "break-word", }}>
              <TextField type="text"
                id={col.accessor}
                variant="outlined"
                disabled={true}
                size="small"
                style={{ width: "100%" }}
                value={this.state.TruckEditValue}
                name={"TruckEditValue"}
                onChange={(e) => this.handleChange(e)}
                InputProps={{ style: { fontSize: 12, } }} />
              {/* {this.state.registrationNumberErr ? <div style={{ fontSize: 12, color: "red", fontFamily: "'Open Sans', sans-serif", marginTop: "1%" }}>{this.state.registrationNumberErr}</div> : <div style={{ fontSize: 12, color: "white", fontFamily: "'Open Sans', sans-serif", marginTop: "1%" }}>required</div>} */}
            </TableCell>
          }


          else if (this.state.editRecordQuantity == true && load["loadId"] === id && col.accessor == "carryingQuantity") {
            return <TableCell style={{ paddingTop: '18px', whiteSpace: "normal", wordWrap: "break-word", }}>
              <TextField type="text"
                id={col.accessor}
                variant="outlined"
                size="small"
                style={{ width: "100%" }}
                disabled={true}
                value={this.state.QuantityEditValue}
                name={"QuantityEditValue"}
                onChange={(e) => this.handleChange(e)}
                InputProps={{ style: { fontSize: 12, } }} />
              {/* {this.state.carryingQuantityErr ? <div style={{ fontSize: 12, color: "red", fontFamily: "'Open Sans', sans-serif", marginTop: "1%" }}>{this.state.carryingQuantityErr}</div> : <div style={{ fontSize: 12, color: "white", fontFamily: "'Open Sans', sans-serif", marginTop: "1%" }}>required</div>} */}
            </TableCell>
          }

          else if (this.state.editRecordPropertyTypeName == true && load["loadId"] === id && col.accessor == "propertyTypeName") {
            return <TableCell style={{ paddingTop: '18px', whiteSpace: "normal", wordWrap: "break-word", }}>
              <TextField type="text"
                id={col.accessor}
                variant="outlined"
                size="small"
                style={{ width: "100%" }}
                value={this.state.propertyTypeName}
                name={"propertyTypeName"}
                disabled={true}
                onChange={(e) => this.handleChange(e)}
                InputProps={{ style: { fontSize: 12, } }} />
            </TableCell>
          }
          else if (this.state.editRecordUlbName == true && load["loadId"] === id && col.accessor == "ulbName") {
            return <TableCell style={{ paddingTop: '18px', whiteSpace: "normal", wordWrap: "break-word", }}>
              <TextField type="text"
                id={col.accessor}
                variant="outlined"
                size="small"
                style={{ width: "100%" }}
                value={this.state.ulbNameEditValue}
                name={"ulbNameEditValue"}
                disabled={true}
                onChange={(e) => this.handleChange(e)}
                InputProps={{ style: { fontSize: 12, } }} />
            </TableCell>
          }
          else {
            return <TableCell style={{ lineHeight: '56px', alignItems: 'right', width: "100%" }} className={"white-space"}>{load[col.accessor]}</TableCell>
          }
        })

        }
      </TableRow>

    });
    return (
  
      <div>
            <Dialog onClose={this.handleCloseLoads} aria-labelledby="customized-dialog-title" open={this.props.openSameload}  
                fullWidth={true}  maxWidth={'lg'}>
              <DialogTitle className="border-none"  id="customized-dialog-title" onClose={this.handleCloseLoads} >
                Edit Loads
              </DialogTitle>
            <DialogContent>
          <div className="dashboard_right_content">
          <img className="dashboard_right_content_top_icon" src="images/Rectangle.svg" />
          <div className="dashboard_right_content_top">
            {/* <h5><Link to="/records/all" className="email_link"><ArrowBackIosIcon></ArrowBackIosIcon></Link> For Same Loads </h5> */}
            <div className="dashboard_right_content_top_options">
              {/* {this.state.editRecord == true?    <Button  onClick={(e) => this.handleCallSave(e)}>Save</Button> : <Button onClick={() => this.handleCall(this.props.loads, true)}>Edit</Button> 
} */}
  <div>
                <FormControl variant="outlined" size="small">
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <label>Select the fields for update: </label>
                    <Select value={this.state.selectOption}
                      onChange={this.handleChange}
                      name="selectOption"
                      style={{ fontSize: 12, width: '200px' }}>
                        <MenuItem value={"selectedOperator"}>Operator</MenuItem>
                        <MenuItem value={"selectedQuentitiy"}>Quantity</MenuItem>
                        <MenuItem value={"selectedULB"}>ULB</MenuItem>
                        <MenuItem value={"selectedPropertyType"}>Property Type</MenuItem>

                    </Select>
                  </div>

                </FormControl>
              </div>
              {this.state.selectOption === "selectedOperator"?<>
              <div style={{marginLeft:"20px"}}> </div>

              <div>
                <FormControl variant="outlined" size="small">
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <label>Operator</label>
                    <Select value={this.state.operatorSTValue}
                      onChange={this.handleChange}
                      name="operatorSTValue"
                      style={{ fontSize: 12, width: '200px' }}>
                      {this.state.optionsOPerator.map((option) => (
                        <MenuItem value={`${option.operatorId}-${option.operatorName}`}>{option.operatorName}</MenuItem>

                      ))}
                    </Select>
                  </div>

                </FormControl>
              </div>
              <div style={{marginLeft:"20px"}}> </div>
              <div>
                {this.state.operatorSTValue !== ""? <FormControl variant="outlined" size="small">
            <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
            <label>Truck</label>
                <Select value={this.state.TruckSTValue} 
                        onChange={this.handleChange} 
                        name="TruckSTValue" 
                        style={{fontSize:12,width:'100%'}}>
                        {this.state.optionsTruck.map((option) => (
                           <MenuItem value={`${option.truckId}+${option.registrationNumber}`}>{option.registrationNumber}</MenuItem>

                        ))}
                </Select>
              </div>
            
               </FormControl>:""}
              
              </div>
              </>:this.state.selectOption === "selectedQuentitiy"? <>
              <div style={{marginLeft:"20px"}}> </div>
              <div>
              <FormControl variant="outlined" size="small">
            <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
            <label>	Quantity</label>
            <TextField type="text"
                id={"Quantity"}
                variant="outlined"
                size="small"
                style={{ width: "100%" }}
                value={this.state.quentityST}
                name={"quentityST"}
                onChange={this.handleChange}
                InputProps={{ style: { fontSize: 12, } }} />
              </div>
    
               </FormControl>
              
              </div>
              </> : "" }


              {this.state.selectOption === "selectedPropertyType"?<>
              <div style={{marginLeft:"20px"}}> </div>

              <div>
                <FormControl variant="outlined" size="small">
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <label>Property Type</label>
                    <Select value={this.state.propertTypeValue}
                      onChange={this.handleChange}
                      name="propertTypeValue"
                      style={{ fontSize: 12, width: '200px' }}>
                      {this.state.propertyOptions.map((option) => (
                        <MenuItem value={`${option.id}-${option.name}`}>{option.name}</MenuItem>

                      ))}
                    </Select>
                  </div>

                </FormControl>
              </div>
              <div style={{marginLeft:"20px"}}> </div>
              
              </>:""}
              {this.state.selectOption === "selectedULB"?<>
              <div style={{marginLeft:"20px"}}> </div>

              <div>
                <FormControl variant="outlined" size="small">
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <label>ULB</label>
                    <Select value={this.state.ULBValue}
                      onChange={this.handleChange}
                      name="ULBValue"
                      style={{ fontSize: 12, width: '200px' }}>
                      {this.state.ULbListingOptions.map((option) => (
                        <MenuItem value={`${option.id}-${option.name}`}>{option.name}</MenuItem>

                      ))}
                    </Select>
                  </div>

                </FormControl>
              </div>
              <div style={{marginLeft:"20px"}}> </div>
              
              </>:""}
                <div>{ this.state.operatorEditValue !== "" && this.state.TruckEditValue !== "" || this.state.QuantityEditValue !== "" || this.state.propertyTypeName !== "" || this.state.ulbNameEditValue !== ""?                   <Button variant='outline' onClick={(e)=> this.handleUpdateLoads(e)}>Update</Button>
:"" }
                </div>
              
            </div>

          </div>


        </div>

        <div >

        <div className="ulb_listing scrollward " >
          <TableContainer component={Paper} style={{ paddingLeft: "15px", paddingRight: "15px" }}>
            <Table stickyHeader aria-label="sticky table" style={{ tableLayout: 'sticky' }}>
                <TableHead>
                  <TableRow>
                    {fixedCols}

                  </TableRow>
                </TableHead>
                <TableBody>
                  {loads}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

        </div>
        <ReactPaginate
          previousLabel={"Prev"}
          nextLabel={"Next"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={this.props.totalCount / this.state.limit}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          forcePage={this.state.activePage}
          style={{ borderLeftWidth: '0px' }}
          onPageChange={this.handlePageClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"} />

                        </DialogContent>
                        </Dialog>
                        
      </div>
    )

  }
}

const mapStateToProps = state => {
  let exportEnabled = state.loads.length !== 0 ? false : true
  return {
    exportEnabled: exportEnabled,
    loads: state.loads.sameLoads,
    loading: state.loads.loadingSameLoads,
    token: state.auth.token,
    totalCount: state.loads.totalCountLoads,
    headers: !!state.headers && state.headers.headers,
    propertyTypesEdit: state.propertyTypesEdit.propertyTypesEdit,
    propertyListing: state.propertyListing.propertyListing,
    operaterList: state.loads.operaterList,
    truckList: state.loads.trucks,

  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSameLoadsListing: (payload) => dispatch(actionsCreators.sameLoadsListing(payload)),
    onFetchHeaders: (payload) => dispatch(actionsCreators.fetchHeaders(payload)),
    onFetchPropertyTypesEdit: (payload) => dispatch(actionsCreators.fetchPropertyTypesEdit(payload)),
    onOpertaterList: (payload) => dispatch(actionsCreators.OpertaterList(payload)),
    ontrucksList: (payload) => dispatch(actionsCreators.trucksList(payload)),
    onMultipleLoads : (payload, props) => dispatch(actionsCreators.multipleLoadsEdit(payload, props))

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SameLoads, axios);
