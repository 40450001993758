import * as actionTypes from '../../actions/actionsTypes'
import { updateObject } from '../../utility'

const initialState = {
    clusterData   : [],
    loading   : false,
    totalCount: 0,
    loadingSuccess: false,
    progress: 0,
    loadingbulk:false,
}

const fetchClusterDataStart = (state, action) => {
    return updateObject(state, {
        loading  : action.loading
    })
}

const fetchClusterDataSuccess = (state, action) => {
    return updateObject(state, {
        clusterData : action.payload,
        loading : action.loading,
        totalCount:action.totalCount
    })
}

const fetchClusterDataFail = (state, action) => {
    return updateObject(state, {
        loading :action.loading
    })
}
const getProgressPercentage = (val) => {
    let percent = 1;
    for(let i=0; i<val; i++){
        percent = percent + 1
    }
    return percent 
}
const fetchClusterBulkStart  = (state, action) => {
    return updateObject(state, {
        progress: getProgressPercentage(99),
        loadingbulk: action.success,
    })
}
 
 const fetchClusterBulkSuccess = (state, action) => {
    return updateObject(state, {
        loadingbulk: action.success,
        progress:100
    });
}

 const fetchClusterBulkFail = (state, action) => {
    return updateObject(state, {
        loadingbulk: action.success,
        progress:action.progress+=0
    })
}

const ClusterLIstingReducer = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.FETCH_CLUSTERLISTING_START   : return fetchClusterDataStart(state, action)
        case actionTypes.FETCH_CLUSTERLISTING_SUCCESS : return fetchClusterDataSuccess(state, action)
        case actionTypes.FETCH_CLUSTERLISTING_FAIL    : return fetchClusterDataFail(state, action)
        case actionTypes.FETCH_CLUSTERBULK_START      :return fetchClusterBulkStart( state,action)
        case actionTypes.FETCH_CLUSTERBULK_SUCCESS    :return fetchClusterBulkSuccess( state,action)
        case actionTypes.FETCH_CLUSTERBULK_FAIL       :return fetchClusterBulkFail( state,action)
        default                                 : return state
    }
}

export default ClusterLIstingReducer;