import * as actionTypes from '../../actions/actionsTypes'
import { updateObject } from '../../utility'

const initialState = {
    ulbData   : [],
    loading   : false,
    totalCount: 0
}

const fetchUlbDataStart = (state, action) => {
    return updateObject(state, {
        loading  : action.loading
    })
}

const fetchUlbDataSuccess = (state, action) => {
    return updateObject(state, {
        ulbData : action.payload,
        loading : action.loading,
        totalCount:action.totalCount
    })
}

const fetchUlbDataFail = (state, action) => {
    return updateObject(state, {
        loading :action.loading
    })
}

const reducer = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.FETCH_ULB_DATA_START   : return fetchUlbDataStart(state, action)
        case actionTypes.FETCH_ULB_DATA_SUCCESS : return fetchUlbDataSuccess(state, action)
        case actionTypes.FETCH_ULB_DATA_FAIL    : return fetchUlbDataFail(state, action)
        default                                 : return state
    }
}

export default reducer;