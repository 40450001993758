import * as actionTypes from '../actions/actionsTypes';
import { updateObject } from '../utility';

const initialState = {
   dashboardListing: [],
    loading: false,
    totalCount:0,
    zoneAndWardListing:[],
    totalCountzone:0
}

const fetchSInspectorStart = (state, action) => {
    return updateObject(state, {
        loading: true,
    });
}

const fetchSInspectorSuccess = (state, action) => {
    return updateObject(state, {
        dashboardListing: action.listing,
        totalCount: action.totalCount,
        loading: false,
    });
}

const fetchSIzoneAndWardSuccess = (state, action) => {
    return updateObject(state, {
        zoneAndWardListing: action.listingZone,
        totalCountzone: action.totalCountzone,
        loading: false,
    });
}

const fetchSInspectorFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error:action.error
    })
}

const siReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_INSPECTOR_START: return fetchSInspectorStart(state, action);
        case actionTypes.FETCH_INSPECTOR_SUCCESS: return fetchSInspectorSuccess(state, action);
        case actionTypes.FETCH_INSPECTOR_FAIL: return fetchSInspectorFail(state, action);
        case actionTypes.FETCH_ZONEANDWARD_SUCCESS: return fetchSIzoneAndWardSuccess(state, action)
        
        default: return state;
    }
}

export default siReducer;