import React from 'react';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom'
import * as actionsCreators from  '../../store/actions/index';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell'; 
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import ReactPaginate from 'react-paginate';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import swal from 'sweetalert'
import TextField from '@material-ui/core/TextField';
class WardListing extends React.Component {
    state = {
        wardId:'',
        open:false,
        anchorEl:null,
        limit: 10,
        page:1,
        currentSortId:'',
        sortObj : {
            key: '',
            value: ''
        },
        zoneId:'',
        ulbId:'',
        editRecord : false,
        wardNumber: '',
        editRecord                : false,
        open_onedit               : false,
        anchorEl_onedit           : null,
        statusWard:'',
        
        filterByType       : "",
        isAll:false,
    filterValue : '',
    controls: {
        search: {
            value: '',
        }
    },
    }

    componentDidMount(){
        let payload = {
            token: this.props.token,
            page:  this.state.page,
            limit: this.state.limit,
            sortName:this.state.sortObj.key,
            sortValue : this.state.sortObj.value,
            zoneNumber: this.state.filterValue,
            searchQuery: this.state.controls.search.value,
        }
        this.props.onFetchWardListing(payload);
        this.props.onFetchWardHeaders(payload);
    }

    handleActionButtonClick = (event,wardId, zoneId, ulbId,wardNumber,statusWard)=>{
        this.setState({open:!this.state.open,anchorEl:event.currentTarget,wardId: wardId, zoneId, ulbId, wardNumber, statusWard})
    }


    handleClickMoreVertIconOnEdit=(event,id)=>{
        this.setState({open_onedit:!this.state.open_onedit,anchorEl_onedit:event.currentTarget})
    }

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        this.setState({page:selectedPage})
        let payload = {
            token: this.props.token,
            page:  selectedPage + 1,
            limit: this.state.limit,
            sortName:this.state.sortObj.key,
            sortValue : this.state.sortObj.value,
            zoneNumber: this.state.filterValue,
        }
        this.props.onFetchWardListing(payload)
    };

    handleClickAwayEdit = () => {
        this.setState({open:false});
    };

    handleEditRecord = (id) => {
                const payload = {
                    token : this.props.token,
                    id    : id,
                    number:this.state.wardNumber,
                    zoneId:this.state.zoneId,
                    ulbId: this.state.ulbId
                   
                }
                const payload1 ={
                    token: this.props.token,
                    page:  this.state.page,
                    limit: this.state.limit,
                    sortName:this.state.sortObj.key,
                    sortValue : this.state.sortObj.value,
                }
                this.props.onEditWard(payload, payload1)
                this.setState({editRecord: false})
            
        }
    handleSort = (e,sortBy) => {
        let sortObj
        if (this.state.sortObj.key === sortBy.accessor) {
            let value = (this.state.sortObj.value === 'DESC') ? 'ASC' : 'DESC';
            sortObj = {
                key : sortBy.accessor,
                value: value
            }
        } 
        else {
            sortObj = {
                key : sortBy.accessor,
                value: 'DESC'
            }    
            }
    
        this.setState({ sortObj: sortObj ,currentSortId:sortBy.accessor});
        let payload = {
            token: this.props.token,
            sortName:sortObj.key,
            sortValue : sortObj.value,
            page:  this.state.page,
            limit: this.state.limit,
            wardNumber: this.state.filterValue,
            searchQuery: this.state.controls.search.value
        }
        this.props.onFetchWardListing(payload);
    
          }
        handleEdit =(wardId, zoneId, ulbId)=>{
            this.setState({editRecord : true,})
        }  
        handleChange=(e, wardid)=>{
            this.setState({[e.target.name] : e.target.value})
        }
        handleCancel = () => {
            this.setState({editRecord : false})
        }
        handleDelete=(id)=>{
            let payload = {
                id: id,
                token: this.props.token,
                sortName:this.state.sortObj.key,
                sortValue : this.state.sortObj.value,
                page:  this.state.page,
                limit: this.state.limit,
            }
            this.props.onDeleteWard(payload)
        }
        handleExport = () => {
            let payload = {
                token: this.props.token,
            page:  this.state.page,
            limit: this.state.limit,
            sortName:this.state.sortObj.key,
            sortValue : this.state.sortObj.value,
          
            }
            this.props.onFetchWardsListingExport(payload)
        }

        onClickGetFilterVals = (e, colObj) => { 
            this.setState({filterValue: e})
        let payload = {
            token: this.props.token,
            page:  this.state.page,
            limit: this.state.limit,
            wardNumber: e,
            sortName:this.state.sortObj.key,
            sortValue :this.state.sortObj.value,
            searchQuery: this.state.controls.search.value
        }
        this.props.onFetchWardListing(payload)
        }
        
        inputChangedHadler = (event, controlName) => {
            const updatedControls = {
                ...this.state.controls,
                [controlName]: {
                    ...this.state.controls[controlName],
                    value: event.target.value,
                }
            }
            this.setState({ controls: updatedControls });
            if( updatedControls.search.value === ''){
                let payload = {
                    token: this.props.token,
                    searchQuery:'',
                    limit:this.state.limit,
                    page:this.state.page,
                    wardNumber: this.state.filterValue,
                    sortName:this.state.sortObj.key,
                    sortValue :this.state.sortObj.value,
                }
                this.props.onSearchWard(payload, '', '') 
            } 
        }  
        
        handleWardSearch = (e) => {
            e.preventDefault()
            let searchVal = this.state.controls.search.value
            if (!!searchVal && searchVal.length >= 1) {
                let payload = {
                    token: this.props.token,
                    searchQuery:searchVal,
                    limit:10,
                    page:this.state.page,
                    wardNumber: this.state.filterValue,
                    sortName:this.state.sortObj.key,
                    sortValue :this.state.sortObj.value,
                  
                }
                this.props.onSearchWard(payload, '', '') 
            } else {
             
                swal("Enter atleast 1 characters")
            }
        }


    render() {
        let updatedHeaders = this.props.WardHeader
        let filterValue
        const wardTypeLists = this.props.Type
        const selectData = !!wardTypeLists&&wardTypeLists.map((item,i)=>{
              return  <option key={i} value={item.Id}>
                    {item.Name}
                </option>
                
        })
        const WardHeaderList = updatedHeaders.map((header)=>{

            let filterType
            if(header.filter == true){
                filterType = 
                <select 
                style={{
                    width: '16px',
                    borderColor:"white",
                    backgroundColor: 'LightGray',
                    outline:"none",
                    display: "flex",
                    alignItems: 'right',   
                  }}
                value={filterValue}
                onChange={(e) => {
                    this.onClickGetFilterVals(e.target.value, header)
                }}
                >
                    <option value="">All</option>
                    {selectData}
                </select>
            }
            {
            return (  <TableCell  className={"white-space"}>
            <div style={{
                    display: 'flex',
                    alignItems: 'start',
                    whiteSpace: 'break-spaces',
                    height:'3em'
                    }}>
                <div onClick={(e) =>  this.handleSort(e,header)}>
                {header.accessor === 'status' || header.accessor ==='tnName' ||header.accessor ==='actionButton'? '' :    <div>{
                this.state.currentSortId===header.accessor? this.state.sortObj.value ==='ASC'?(<ArrowDropDownIcon fontSize="small"/>):(<ArrowDropUpIcon fontSize="small"/>) :(<ArrowDropDownIcon fontSize="small"/>)}
                </div>}
                </div>
                {header.header}
                {filterType}
            </div>
        </TableCell>)
        }
        })
        const wards=this.props.wards.map((ward, j)=>{
            const wardId=ward["wardId"]
            const zoneId = ward["ZoneId"]
            const ulbId = ward["ulbId"]
            const wardNumber = ward["wardNumber"]
            const statusWard = ward['status']
            return <TableRow style={{whiteSpace: 'normal',
            wordWr: 'break-word'}} key={j}>{
            updatedHeaders.map((header, i)=>{
                if(header.accessor=='actionButton'){
                    if(this.state.editRecord && wardId == this.state.wardId){
                        return  <TableCell align = "left">  
                        <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={(e)=>this.handleClickMoreVertIconOnEdit(e)}
                            >
                            <MoreVertIcon />
                            </IconButton>
                                <Popper
                                open={this.state.open_onedit} anchorEl={this.state.anchorEl_onedit}>
                                    <ClickAwayListener onClickAway={this.handleClickAwayOnEdit}>
                                <Paper>
                                    <MenuList>
                                    <MenuItem style={{color:'#3DAB35'}} onClick={()=>this.handleCancel()}>Cancel</MenuItem>
                                        <MenuItem style={{color:'#3DAB35'}}  onClick={()=>this.handleEditRecord(this.state.wardId)}>Save</MenuItem>
                                    </MenuList>
                                </Paper>
                                </ClickAwayListener>
                            </Popper>
                    </TableCell>
                    }
                    else{
                        return  <TableCell  key={i}>
                        <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={(e)=>this.handleActionButtonClick(e,wardId, zoneId, ulbId,wardNumber, statusWard )}
                        >
                        <MoreVertIcon fontSize='small'/>
                        </IconButton>
                            <Popper
                            open={this.state.open} anchorEl={this.state.anchorEl}>
                            <ClickAwayListener onClickAway={this.handleClickAwayEdit}>
                            <Paper style={{boxShadow:"none", border:"1px solid #3dab35"}}>
                                <MenuList>
                                <Link className='email_link'>
                                    <MenuItem onClick={()=>this.handleEdit(this.state.wardId, this.state.zoneId, this.state.ulbId)}>Edit</MenuItem>
                                </Link>        
          {this.state.statusWard == 'Assigned'? '':
                <MenuItem style = {{color: '#3dab35'}} onClick={() => {
                    swal({ title : "Are you sure?",
                    text : "Once deleted, you will not be able to recover this data!",
                    icon : "warning",
                    buttons : {
                    cancel : 'No',
                    confirm: 'Yes'}
                })
                .then((willDelete) => {
                if (willDelete) {
                    this.handleDelete(this.state.wardId);
                } 
                });

                }}>Delete</MenuItem>  
          }
                                             
                                </MenuList>
                             
                            </Paper>
                            </ClickAwayListener>
                        </Popper>
                    </TableCell>
                    }

          
                }
                else{

                    if(this.state.editRecord && wardId == this.state.wardId && header.accessor == 'wardNumber'){
                        return <TableCell  style={{
                            whiteSpace: "normal",
                            wordWrap: "break-word",
                            paddingTop:'20px'
                          }}>
                            <TextField  type="text"
                                                id="wardNumber"
                                                variant="outlined"
                                                size = "small"
                                                placeholder = "Ward No."
                                                value={this.state.wardNumber}
                                                name="wardNumber"
                                                onChange={(e)=>this.handleChange(e,this.state.wardId)}
                                                InputProps={{ style: { fontSize: 12 } }}/>
                                    {/* {this.state.nameErr ? <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.nameErr}</div> :  <div style={{fontSize:12,color:"white", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>required</div>} */}
                        </TableCell>
                    }
                 else{
                    return  <TableCell style={{
                        whiteSpace: "normal",
                        wordWrap: "break-word"
                        }} key={i} className={"white-space"}>{ward[header.accessor] == null || ward[header.accessor] == 'null' ? ' ' : ward[header.accessor]}</TableCell>
                 }  
                }
               }) 
            }
         </TableRow>
        })
      
        return (
            <div>
                <div className="dashboard_right_content" style={{marginTop:'-4%'}}>
                    <img className="dashboard_right_content_top_icon" src="images/Rectangle.svg" />
                    <div className="dashboard_right_content_top">
                    <h5>Ward Listing</h5>
                        <div className="dashboard_right_content_top_options">
                                {/*Adding Search option for ward  */}
                        <div className="dashboard_right_content_top_search">
                                <Paper component="form">
                                    <IconButton aria-label="menu">
                                        <img src="images/search_icon.svg" />
                                    </IconButton>
                                    <InputBase onChange={(event) => this.inputChangedHadler(event, 'search')} placeholder="Ward Number" />
                                    <Button onClick={this.handleWardSearch} variant="contained" color="primary" type="submit">Search</Button>
                                </Paper>
                            </div>
                            {localStorage.getItem('isZoneNotRequired') === 'true'? '':<> <Link className="email_link" to='/zone-management'><Button>Zone Listing</Button></Link> 
                            <Link className="email_link" to='/add_zone'><Button>Add Zone</Button></Link> </> }
                        <Link className="email_link" to='/add_ward'><Button>Add New Ward</Button></Link>  
                        <div>
                            <Button style={{width:'100%', marginLeft:'10px' }} onClick={this.handleExport}>Export</Button>
                            </div>
                        </div>
                    </div>
                    {(!this.props.loadingWard) && <div id="cover-spin"></div>}
                    <div className="dashboard_listing AreaLIsting scrollward" >
                        <TableContainer component={Paper} style={{paddingLeft:"15px",paddingRight:"15px"}}>
                             <Table stickyHeader aria-label="sticky table" style={{ tableLayout: 'fixed' }}>
                                <TableHead >
                                    <TableRow >
                                        {WardHeaderList}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                 {wards}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
                {
                this.props.totalCount && this.props.totalCount != 0 && 
                    <ReactPaginate
                    previousLabel={"Prev"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount = {this.props.totalCount/this.state.limit}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    style={{borderLeftWidth:'0px'}}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}/>
                }
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        token: state.auth.token,
        wards:state.WardReducer.wards,
        totalCount:state.WardReducer.totalCount,
        // ulbData: state.ulbData.ulbData,
        loadingWard: state.WardReducer.loading,
        WardHeader: state.WardReducerHeader.headers ? state.WardReducerHeader.headers.concat({header: "Action", accessor: "actionButton", sortable: "false", filter: "true"}) :[],
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchWardListing: (onFetchAreaList) => dispatch(actionsCreators.fetchWardListing(onFetchAreaList)),
        onFetchWardHeaders:(payload)=>dispatch(actionsCreators.fetchWardHeaders(payload)),
        onEditWard : (payload, payload1) => dispatch(actionsCreators.editWard(payload, payload1)),
        onDeleteWard : (payload) =>dispatch(actionsCreators.deleteWard(payload)),
        onFetchWardsListingExport: (payload) => dispatch(actionsCreators.fetchWardsListingExport(payload)),
        // onFetchUlbData: (payload) => dispatch(actionsCreators.fetchUlbData(payload)),
        onSearchWard:(payload, url,type)=>dispatch(actionsCreators.searchWard(payload, url, type)),

    }
}

export default  connect(mapStateToProps, mapDispatchToProps)(WardListing);
