import React from 'react';
import * as serviceWorker from './serviceWorker';
import ReactDOM from 'react-dom';
import { I18nextProvider } from "react-i18next";


import './index.css';
import App from './App';
import i18n from "./i18n";

import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import authReducer from './store/reducers/auth';
import loadsReducer from './store/reducers/record-keeper/loads';
import propertTypesReducer from './store/reducers/propertyTypes'
import emailManageReducer from './store/reducers/emailManage'
import exportsReducer from './store/reducers/exports'
import headersReducer from './store/reducers/record-keeper/loadsHeader'
import dashboardReducer from './store/reducers/dashboard'
import stateDashboardReducer from './store/reducers/stateDashboard'
import treatmentHeadersReducer from './store/reducers/treatment-facility/treatmentHeaders'
import treatmentListingReducer from './store/reducers/treatment-facility/treatmentListing'
import operatorHeaderReducer from './store/reducers/operator-management/headers'
import operatorDataReducer from './store/reducers/operator-management/data'
import truckHeadersReducer from './store/reducers/truck-management/headers'
import truckDataReducer from './store/reducers/truck-management/data'
import truckDocDataReducer from './store/reducers/truck-management/docData'
import propertyTypesEditReducer from './store/reducers/propertyTypeEdit'
import propertyHeadersReducer from './store/reducers/property-management/propertyHeaders'
import propertyListingReducer from './store/reducers/property-management/propertyListing'
import ulbHeadersReducer from './store/reducers/ulb-management/ulbHeaders'
import ulbDataReducer from './store/reducers/ulb-management/ulbData'
import fetchRoleListing from './store/reducers/role-management/RoleListing'
import userHeadersReducer from './store/reducers/user-management/userHeaders'
import userDataReducer from './store/reducers/user-management/userListing'
import AreaListingReduce from './store/reducers/Area-managment/areaListing'
import  AreaHeader  from "./store/reducers/Area-managment/areaHeader";
import iotChartDataReducer from './store/reducers/iot-management/chartData'
import WardReducerHeader from './store/reducers/ward-management/wardHeader'
import WardReducerListing from './store/reducers/ward-management/wardListing'

import ZoneListingHeader from './store/reducers/zoneManagement/zoneHeader'
import ZOneReducerListing from './store/reducers/zoneManagement/zoneLIsting'
import clusterListing from './store/reducers/clusterMangment/clusterListing'
import clusterHeader from './store/reducers/clusterMangment/clusterHeader'
import siReducer      from './store/reducers/sanitoryInspector'
const composeEnhancers = (process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null )|| compose;

const rootReducer = combineReducers({
  auth                     : authReducer,
  loads                    : loadsReducer,
  propertyTypes            : propertTypesReducer,
  emailManage              : emailManageReducer,
  exports                  : exportsReducer,
  headers                  : headersReducer,
  dashboard                : dashboardReducer,
  stateDashboard           : stateDashboardReducer,
  treatmentHeaders         : treatmentHeadersReducer,
  treatmentListing         : treatmentListingReducer,
  operatorHeaders          : operatorHeaderReducer,
  operatorData             : operatorDataReducer,
  truckHeaders             : truckHeadersReducer,
  truckData                : truckDataReducer,
  statisticsData           : operatorDataReducer,
  truckDocs                : truckDocDataReducer,
  propertyTypesEdit        : propertyTypesEditReducer,
  propertyHeaders          : propertyHeadersReducer,
  propertyListing          : propertyListingReducer,
  operatorListing          : propertyListingReducer,
  operatorDay              : propertyListingReducer,
  ulbHeaders               : ulbHeadersReducer,
  ulbData                  : ulbDataReducer,
  roleData                 : fetchRoleListing,
  userHeaders              : userHeadersReducer,
  userData                 : userDataReducer,
  iotChartData             :iotChartDataReducer,
  WardReducerHeader        : WardReducerHeader,
  WardReducer              : WardReducerListing,
  ZoneListingreducer       :ZoneListingHeader,
  zoneReduce              :ZOneReducerListing,
  areaListing              : AreaListingReduce,
  AreaHeader               : AreaHeader,
  ClusterrReducer           :clusterHeader,
  ClusterLIstingReducer     :clusterListing,
  siReducer                 :siReducer

});

const store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(thunk)
));


const app = (
  <Provider store={store}>
    <HashRouter>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </HashRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

serviceWorker.unregister();
export default store

