import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as actionsCreators from '../../store/actions/index'
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { Grid } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell'; 
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import swal from 'sweetalert';

class OperatorDetails extends Component {
    constructor(props){
       super(props)
       this.state = {
        username            : this.props.username          ? this.props.username                 : "",
        password            :   "",   
        operatorId          : this.props.operatorId        ? this.props.operatorId               : "",
        id                  : this.props.id                ? this.props.id                       : "",
        operator            : this.props.name              ? this.props.name                     : "",
        ownerName           : this.props.applicantName     ? this.props.applicantName.trim()     : "",
        contactNo           : this.props.contactNumber     ? this.props.contactNumber.trim()     : "",
        email               : this.props.email             ? this.props.email                    : "",
        vehicleOwnerName    : this.props.vehicleOwnerName  ? this.props.vehicleOwnerName         : "",
        address             : this.props.addressOfOperator ? this.props.addressOfOperator.trim() : "",
        noOfTrucks          : this.props.noOfTrucks        ? this.props.noOfTrucks               : '',
        updatedDate         : this.props.updatedDate       ? this.props.updatedDate              : '',
        name_tn             : this.props.name_tn           ? this.props.name_tn                  : "",
        isDisableOperator   : this.props.isDisableOperator ? this.props.isDisableOperator         : ""
     
    }
    }

    openDialog = () => {
        this.props.openDialog(true,'Update')
        this.props.handleClose(true,true)
    }

    deleteUser = () => {
        swal({
            title: "Are you sure?",
            icon: "warning",
            buttons: {
                cancel : 'No',
                confirm: 'Yes'
            },
          })
          .then(async (willDelete) => {
            if (willDelete) {
                const payload = {
                    operatorId:this.state.operatorId,
                    token:this.props.token
                }
                await this.props.deleteUser(payload)
                this.props.handleClose(false,true)
            } else {
            }
          });
        
    }
   
    render() {
        return <>
            <Grid container justify="center" spacing={1}  style={{marginTop:'2%'}} direction = "row">
                <Grid item align="center" xs={6}>
                    <label>Operator Name  : <span className = "operatorDetailsValue">{this.state.operator == " " || !this.state.operator ? '-' : this.state.operator}</span> </label>  
                </Grid>
                <Grid item align="center" xs={6}>
                    <label >Operator Name (Tamil)  : <span className = "operatorDetailsValue">{this.state.name_tn == " " || !this.state.name_tn ? '-' : this.state.name_tn}</span></label>   
                </Grid>
            </Grid>
            <Grid container justify="center" spacing={1} style={{marginTop:'2%'}} direction = "row">
                <Grid item align="center" xs={6}>
                    <label>Applicant Name  : <span className = "operatorDetailsValue">{this.state.ownerName == " " || !this.state.ownerName ? '-' : this.state.ownerName}</span></label>   
                </Grid>
                <Grid item  align="center" xs={6}>
                    <label>Contact Number  : <span className = "operatorDetailsValue">{this.state.contactNo == " " || !this.state.contactNo ? '-' : this.state.contactNo}</span></label>   
                </Grid>
            </Grid>
            <Grid container justify="center" spacing={1} style={{marginTop:'2%'}} direction = "row">
                <Grid item align="center" xs={6}>
                    <label>Email  : <span className = "operatorDetailsValue">{this.state.email == " " || !this.state.email ? '-' : this.state.email}</span></label>   
                </Grid>
                <Grid item  align="center" xs={6}>
                    <label>Address of Applicant/Operator  : <span className = "operatorDetailsValue">{this.state.address == " " || !this.state.address ? '-' : this.state.address}</span> </label>  
                </Grid>
            </Grid>


            <Grid container justify="center" spacing={1} style={{marginTop:'2%'}} direction = "row">
                <Grid item align="center" xs={6}>
                    <label>Status : <span className = "operatorDetailsValue">{this.state.isDisableOperator === " "|| !this.state.isDisableOperator ?'-' : this.state.isDisableOperator }</span></label>   
                </Grid>
                <Grid item  align="center" xs={6}>
                    {/* <label>Address of Applicant/Operator  : <span className = "operatorDetailsValue">{this.state.address == " " || !this.state.address ? '-' : this.state.address}</span> </label>   */}
                </Grid>
            </Grid>
           
            {this.props.username && <Grid items xs={12}>
            <h4 style={{textAlign:'center'}}>Operator Credentials</h4>
                <div className="dashboard_new_listing" >
                    <TableContainer component={Paper} style={{maxHeight: "41vh",marginTop:'4%'}}>
                       <Table aria-label="simple table">
                           <TableHead>
                               <TableRow style={{background:'#FAFAFA'}}>
                                    <TableCell
                                        align ='center'
                                        style={{ minWidth: 170, fontWeight:'bolder', fontSize:"12px" }}>
                                       Operator User name
                                    </TableCell> 
                                    <TableCell
                                    align ='center'
                                    style={{ minWidth: 170, fontWeight:'bolder', fontSize:"12px" }}>
                                        Action</TableCell>
                                </TableRow>
                            </TableHead>
                           <TableBody> 
                                <TableRow>
                                    <TableCell align="center" style={{fontSize:"12px"}}>{this.props.username}</TableCell>
                                    <TableCell align="center" style={{fontSize:"12px"}}> <Button 
                                        onClick = {this.openDialog}
                                        style={{border:'1px solid #4CAF50',color: '#4CAF50',textAlign: 'center',height:'35px',textTransform:'capitalize'}}>
                                        Edit
                                    </Button>
                                    <Button onClick = {this.deleteUser}
                                        style={{marginLeft:'1px',border:'1px solid red',color: 'red',textAlign: 'center',height:'35px',textTransform:'capitalize'}}>
                                        Delete
                                    </Button>
                                    </TableCell>                                       
                                </TableRow>
                           </TableBody>
                       </Table>
                    </TableContainer> 
                </div>
            </Grid>}
        </>
    
    }
}

const mapStateToProps = (state,props) => {
    
    return{
        props:props,
        token:state.auth.token
    }
}

const  mapDispatchToProps = dispatch=>{
    return{
        deleteUser : (payload) => dispatch(actionsCreators.deleteOperatorUser(payload))
    }
   
}

export default connect(mapStateToProps,  mapDispatchToProps)(OperatorDetails)
