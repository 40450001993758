import React from 'react';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom'
import * as actionsCreators from  '../../store/actions/index';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell'; 
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import ReactPaginate from 'react-paginate';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import swal from 'sweetalert';
import { Dialog } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';  
import TextField from '@material-ui/core/TextField';
import InputBase from '@material-ui/core/InputBase';
import axios from '../../http-config';
import * as User  from '../../config/constant';


class AreaListing extends React.Component {
    state = {
        areaId:'',
        id:"",
        open:false,
        anchorEl:null,
        limit: 10,
        page:1,
        filterByType:"",
        currentSortId:"",
        sortObj : {
            key: '',
            value: ''
        },
        openDialog:false,
        ulbName                 : "",
        ulbNameErr              : "",

        areaName                :"",
        areaNameErr             :"",
        areaNameTm              :"",
        areaNameTmErr          : '' ,  
        zone                    :"",   
        wardNumber:""   ,
        ZoneOptions             : [],
        wardOptions             : [],   
        ulbOptions: [],
        isDisableProperty       : false,
        //search
        controls: {
            search: {
                value: '',
            }
        },
        // Area pop up 
        openAreaPop:false,
        editId:"",
        name: "",
        name_tn: "",
        ulbId :"",
        zoneId : "",
        wardId: "",
        IsDisableStatus: "",

        //Area edit 
        aName: "", 
        aTamilName:"", 
        aUlbId:"" ,
        aZone:"" ,
        aWard:"",
        aStatus:"",
        isZoneNotRequired:localStorage.getItem("isZoneNotRequired") 

    }

    componentDidMount(){
        axios({
            method: 'get',
            url: '/load_template/master_data',
            headers: { 'Authorization': `Bearer ${this.props.token}` }
        })
            .then((response) => {
                if (response.data.data) {
                    this.setState({
                        ulbOptions : response.data.data['master-data'][3].data,
                    })
                }

            })
        let payload = {
            token: this.props.token,
            page:  this.state.page,
            limit: this.state.limit,
            sortValue : this.state.sortObj.value,
            sortName:this.state.sortObj.key,
            searchQuery: this.state.controls.search.value

        }
        this.props.onFetchAreaList(payload);
        this.props.onfetchAreaHeaders(payload);
    }

    handleActionButtonClick = (event,areaId,aName,  aTamilName ,aUlbId ,aZone ,aWard, aStatus)=>{
        this.setState({open:!this.state.open,anchorEl:event.currentTarget,areaId, aName,  aTamilName ,aUlbId ,aZone ,aWard, aStatus})
    }
    handleClickAwayEdit = () => {
        this.setState({open:false});
        };
    handlePageClick = (e) => {
        const selectedPage = e.selected;
        this.setState({page: selectedPage + 1})
        let payload = {
            token: this.props.token,
            page:  selectedPage + 1,
            limit: this.state.limit,
            sortValue : this.state.sortObj.value,
            sortName:this.state.sortObj.key,
            searchQuery: this.state.controls.search.value

        }
        this.props.onFetchAreaList(payload)
    };

    // Function to delete an area
    handleDelete =(id)=>{
        let payload = {
            propertyId: id,
            token: this.props.token,
            page:  this.state.page,
            limit: this.state.limit,
            sortValue : this.state.sortObj.value,
            sortName:this.state.sortObj.key,
            searchQuery: this.state.controls.search.value

        }
        this.props.onDeleteArea(payload)

    } 

    handleSort = (e,sortBy) => {
        let sortObj
        if (this.state.sortObj.key === sortBy.accessor) {
            let value = (this.state.sortObj.value === 'DESC') ? 'ASC' : 'DESC';
            sortObj = {
                key : sortBy.accessor,
                value: value
            }
        } 
        else {
            sortObj = {
                key : sortBy.accessor,
                value: 'DESC'
            }    
            }
    
        this.setState({ sortObj: sortObj ,currentSortId:sortBy.accessor});
        let payload = {

            token: this.props.token,
            sortName:sortObj.key,
            sortValue : sortObj.value,
            page:  this.state.page,
            limit: this.state.limit,
            searchQuery: this.state.controls.search.value

        }
        this.props.onFetchAreaList(payload);
    
          }

          openDialog = () => {
            this.setState({
                openDialog:true
            })
        }
    
        handleDialogClose = (value) => {
            this.setState({
                openDialog:value
            }) 
        }
          OpenSingleAreaPop = () =>{
            this.setState({
                openAreaPop             :true,
                editId                  :"",
                areaName                :"",
                areaNameErr             :"",
                areaNameTm              :"",
                areaNameTmErr           : '' ,
                ulbName                 : "",
                wardNumber                  : "",
                zone                    : "",
                zoneErr                 : "",
                ulbNameErr              : "",
            })
           
    
        }
        
        //To close Single area Upload Pop up
        handleAreaClosePop = ()=> {
            this.setState({
                openAreaPop             :false,
                editId                  :"",
                areaName                :"",
                areaNameErr             :"",
                areaNameTm              :"",
                areaNameTmErr           : '',
                wardNumber              : "",
                ulbName                 : "",
                zone                    : "",
                zoneErr                 : "",
                ulbNameErr              : "",
                // wardNumber:""
            })
    
        }

        handleopeneditArea = (aId,aName,  aTamilName ,aUlbId ,aZone ,aWard, aStatus) => {
            const UserIdSave = localStorage.getItem("UserIdSave")
            const roleName = localStorage.getItem('roleName')

            if(aUlbId!=="" ){
                
                if(this.state.isZoneNotRequired === "true"){
                    let payload={
                        token:this.props.token,
                    }
                    axios({
                        method  : 'get',
                        url     :`/ward?ulbId=${aUlbId}`,
                        headers : {'Authorization': `Bearer ${payload.token}`}
                    })
                    .then((res)=>{
                        this.setState({wardOptions: res.data.data.wards})
                    })
                    .catch((err)=>{
                        console.log(err)
                    })
                }else{
                    let payload={
                        token:this.props.token
                    }
                    axios({
                        method  : 'get',
                        url     :UserIdSave&&roleName === User.SANITARYINSPECTOR?`/user/SI_zone_ward_assign?userId=${UserIdSave}`:  `/zone/getZones`,
                        headers: {'Authorization': `Bearer ${payload.token}`}
                    })
                    .then((response) => {
                        this.setState({
                            //  wardOptions : response.data.data.wards, 
                            ZoneOptions: UserIdSave&&roleName === User.SANITARYINSPECTOR?response.data.data.zoneWardAssignData: response.data.data.zones
                        })
                })
                }
                
            }
            if(aZone !=null){
                let payload={
                    token:this.props.token,
                }
                axios({
                    method  : 'get',
                    url     :UserIdSave&&roleName === User.SANITARYINSPECTOR? `/user/SI_zone_ward_assign?ZoneId=${aZone}`: `/ward?zoneId=${aZone}`,
                    headers : {'Authorization': `Bearer ${payload.token}`}
                })
                .then((res)=>{
                    this.setState({wardOptions: UserIdSave&&roleName === User.SANITARYINSPECTOR?JSON.parse(res.data.data.zoneWardAssignData[0].siWardid) :res.data.data.wards})
                })
                .catch((err)=>{
                    console.log(err)
                })
               }
            this.setState({
                openAreaPop: true,
                editId: aId,
                areaName:aName,
                areaNameTm: aTamilName,
                ulbName :aUlbId,
                zone : aZone,
                wardNumber: aWard,
                IsDisableStatus: aStatus,
            })
        }
         // Handle change Function For Area
    handleAreaChange = (event) =>{
        if(event.target.name=="zone"){
            let payload={
                token:this.props.token,
            }
            axios({
                method  : 'get',
                url     : `/ward?zoneId=${event.target.value}`,
                headers : {'Authorization': `Bearer ${payload.token}`}
            })
            .then((res)=>{
                 this.setState({wardOptions: res.data.data.wards})
            })
            .catch((err)=>{
                console.log(err)
            })
        }

        if(event.target.name=="ulbName"){
            this.setState({[event.target.name]:event.target.value})
            this.state.ulbName=event.target.value
            let payload={
                token:this.props.token
            }
            const ulbId = localStorage.getItem('ulbid')
            axios({
                method  : 'get',
                url     : `/zone/getZones`,
                headers: {'Authorization': `Bearer ${payload.token}`}
            })
            .then((response) => {
                this.setState({
                    //  wardOptions : response.data.data.wards, 
                    ZoneOptions: response.data.data.zones
            })
        })

        axios({
            method  : 'get',
            url     : `/ward?ulbId=${event.target.value}`,
            headers: {'Authorization': `Bearer ${this.props.token}`}
        })
        .then((response) => {
            this.setState({
                 wardOptions : response.data.data.wards,                                                      
        })
    })
    }else{
      
        this.setState({[event.target.name]:event.target.value},() => {
            this.checkAreaValidity()
        })
    }       
    }

//To Check Area Error 
checkAreaValidity = () => {
    let isError = false
    if(this.state.areaName == "" || this.state.areaName == null  || this.state.areaName == "null" || this.state.areaName ==undefined){
        isError =true
        this.setState({areaNameErr : `*This field is required`})
    }
    else{
        // isError=false
        this.setState({ areaNameErr : ''})
    }
    if (this.state.ulbName == "" ||this.state.ulbName == null ||this.state.ulbName== "null"||this.state.ulbName == undefined) {
        isError = true
        this.setState({ ulbNameErr: `*This field is required` })
    }
    else {
        // isError=false
        this.setState({ ulbNameErr: '' })
    }

    return isError

}
    handleAreaSubmit = (e) =>{
        let props=this.props
  
      const err = this.checkAreaValidity()
      let payload = {
        token: this.props.token,
        limit: this.state.limit,
        page:  this.state.page,
        sortName:this.state.sortObj.key,
        sortValue : this.state.sortObj.value,
        searchQuery: this.state.controls.search.value

    }
      if(!err){
        if(this.state.editId != ""){
            const formdata={
                token      : this.props.token,
                name       : this.state.areaName,
                name_tn    : this.state.areaNameTm,
                id         : this.state.areaId,
                ulbId                 : this.state.ulbName,
                zoneId                  : this.state.zone,
                wardId                  : this.state.wardNumber,
                IsDisableStatus      :this.state.IsDisableStatus,
            }
        this.props.onEditArea(formdata,props, payload)
        this.handleAreaClosePop() 
    }else {
        const formdata = {
            token:this.props.token,
            name:this.state.areaName,
            name_tn:this.state.areaNameTm,
            // ULBId: this.state.ulbName,
            IsDisableStatus         : this.state.IsDisableStatus,
            ulbId                   : this.state.ulbName,
            zoneId                  : this.state.zone,
            wardId                  : this.state.wardNumber,
        }
        this.props.OnAddAreaSingle(formdata,props, payload)
        this.handleAreaClosePop() 
    }  
    }
}
handleAreaStatus= (e) => {
    this.setState ({
        isDisableProperty: e.target.value
    })
  }

handleAreaSearch = (e) => {
    e.preventDefault()
    let searchVal = this.state.controls.search.value
    if (!!searchVal && searchVal.length >= 2) {
        let payload = {
            searchQuery:searchVal,
            token: this.props.token,
            page:  this.state.page,
            limit: this.state.limit,
            sortValue : this.state.sortObj.value,
            sortName:this.state.sortObj.key,
          
        }
        this.props.onSearchArea(payload) 
    } else {
     
        swal("Enter atleast 2 characters")
    }
}
inputChangedHadler = (event, controlName) => {
    const updatedControls = {
        ...this.state.controls,
        [controlName]: {
            ...this.state.controls[controlName],
            value: event.target.value,
        }
    }
    this.setState({ controls: updatedControls });
    if( updatedControls.search.value === ''){
        let payload = {
            searchQuery:'',
            token: this.props.token,
            page:  this.state.page,
            limit: this.state.limit,
            sortValue : this.state.sortObj.value,
            sortName:this.state.sortObj.key,
        }
        this.props.onSearchArea(payload) 
    } 
}

handleExport = () => {
const payload = {
    token: this.props.token,
    page:  this.state.page,
    limit: this.state.limit,
    sortValue : this.state.sortObj.value,
    sortName:this.state.sortObj.key,
    searchQuery: this.state.controls.search.value
}
this.props.OnFetchAreaListingExport(payload)
}

    render() {
        let updatedHeaders = this.props.AreaHeader
        const AreaHeaderList = updatedHeaders.map((header)=>{
            let filterType
        {
            return (  <TableCell key={header.header}  className={"white-space"}>
            <div style={{
                    display: 'flex',
                    alignItems: 'start',
                    whiteSpace: 'break-spaces',
                    height:'3em'
                    }}>
                        <div onClick={(e) =>  this.handleSort(e,header)}>
                {header.accessor ==='actionButton' || header.accessor === 'Name_tn' ? '' :    <div>{
                this.state.currentSortId===header.accessor? this.state.sortObj.value ==='ASC'?(<ArrowDropDownIcon fontSize="small"/>):(<ArrowDropUpIcon fontSize="small"/>) :(<ArrowDropDownIcon fontSize="small"/>)}
                </div>}
                </div>
                      {header.header}
                      {filterType}
            </div>
          
        </TableCell>)
        }
        })
        const Areas=this.props.area.map((area, j)=>{
            const areaId=area["Id"]
            const  aName= area.Name
            const aTamilName = area.Name_tn
            const aUlbId =  area.ULBId
            const aZone = area.ZoneId
            const aWard = area.WardId
            const aStatus = area.IsDisableStatus
            return <TableRow style={{whiteSpace: 'normal',
            wordWr: 'break-word'}} key={j}>{
            updatedHeaders.map((header, i)=>{
                if(header.accessor=='actionButton'){
                 return  <TableCell  key={i}>
                    <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={(e)=>this.handleActionButtonClick(e,areaId, aName,  aTamilName ,aUlbId ,aZone ,aWard, aStatus)}
                    >
                    <MoreVertIcon fontSize='small'/>
                    </IconButton>
                        <Popper
                        open={this.state.open} anchorEl={this.state.anchorEl}>
                        <ClickAwayListener onClickAway={this.handleClickAwayEdit}>
                        <Paper style={{boxShadow:"none", border:"1px solid #3dab35"}}>
                            <MenuList>
                            <MenuItem className='email_link' style={{ color: '#3dab35' }} onClick={() => this.handleopeneditArea(
                                                        this.state.areaId,
                                                        this.state.aName,
                                                        this.state.aTamilName,
                                                        this.state.aUlbId,
                                                        this.state.aZone,
                                                        this.state.aWard,
                                                        this.state.aStatus,                                                     
                                                        )}>     Edit
                                                        
                                                        </MenuItem>
                            </MenuList>
                            <MenuList>
                            {/* <Link to={`./property_loads/${this.state.areaId}`} className='email_link'> <MenuItem>Show Loads</MenuItem></Link> */}
                            </MenuList>
                            {/* Added delete option  */}
                            <MenuList>
                               <MenuItem  className='email_link' style={{color:'green'}} onClick = { () => {
                                     swal({ title   : "Are you sure?",
                                        text    : "Once deleted, you will not be able to recover this data!",
                                        icon    : "warning",
                                        buttons : {
                                        cancel  : 'No',
                                        confirm : 'Yes'}
                                         })
                                           .then((willDelete) => {
                                             if (willDelete) {
                                           this.handleDelete(this.state.areaId) 
                                           } 
                                     });
                                 }}>Delete</MenuItem>
                            </MenuList>
                        </Paper>
                        </ClickAwayListener>
                    </Popper>
                </TableCell>
                }
                else{
                    return  <TableCell style={{
                    whiteSpace: "normal",
                    wordWrap: "break-word"
                    }} key={i} className={"white-space"}>{area[header.accessor] == null || area[header.accessor] == 'null' ? ' ' : area[header.accessor]}</TableCell>
                }
               }) 
            }
         </TableRow>
        })
        const UserIdSave = localStorage.getItem("UserIdSave")
        const roleName = localStorage.getItem("roleName")
        return (
            <div>
             
                     <div className="dashboard_right_content" style={{marginTop:'-4%'}}>
                        <img className="dashboard_right_content_top_icon" src="images/Rectangle.svg" />
                        <div className="dashboard_right_content_top">
                        <h5><Link to ={UserIdSave&&roleName==User.SANITARYINSPECTOR?"/": "/property-management"} className="email_link"><ArrowBackIosIcon></ArrowBackIosIcon></Link>Area Listing</h5>                            <div className="dashboard_right_content_top_options">
                        <div className="dashboard_right_content_top_search">
                                <Paper component="form">
                                    <IconButton aria-label="menu">
                                        <img src="images/search_icon.svg" />
                                    </IconButton>
                                    <InputBase onChange={(event) => this.inputChangedHadler(event, 'search')} placeholder="Area Name" />
                                    <Button onClick={this.handleAreaSearch} variant="contained" color="primary" type="submit">Search</Button>
                                </Paper>
                                
                            </div>
                            <div>
                            <Button style={{width:'100%', marginLeft:'10px' }} onClick={this.handleExport}>Export</Button>
                            </div>    
                            </div>
                            
                        </div>
                    </div>
                       
                    <div className="dashboard_listing AreaLIsting scrollward" >
                        <TableContainer component={Paper} style={{paddingLeft:"15px",paddingRight:"15px"}}>
                             <Table stickyHeader aria-label="sticky table" style={{ tableLayout: 'fixed' }}>
                                <TableHead >
                                    <TableRow >
                                        {AreaHeaderList}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                 {Areas}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                
                {
                this.props.totalCount && this.props.totalCount != 0 && 
                    <ReactPaginate
                    previousLabel={"Prev"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount = {this.props.totalCount/this.state.limit}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    style={{borderLeftWidth:'0px'}}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}/>
                }
                    <div className="setFreq">
                    <Dialog
                        maxWidth={'md'}
                        fullWidth={true}
                        // open={true}
                        open={this.state.openAreaPop}
                        aria-labelledby="responsive-dialog-title">
                        <DialogTitle className="text-center border-none " style={{ color: '#3dab35' }}><span style={{ fontSize: '18px' }}>{this.state.editId != "" ? "Edit Area": "Add New Area" } </span></DialogTitle>
                        <DialogContent >
                            <div className="create_ulb_content_outer">
                                <div className="create_ulb_content create_ulb_contentPop">
                                    <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <FormControl>
                                            <label className="required" htmlFor="areaName">Area Name</label>
                                            <TextField id="areaName" 
                                                    variant="outlined" 
                                                    placeholder="Enter Area Name" 
                                                    name="areaName" 
                                                    value={this.state.areaName} 
                                                    onChange={this.handleAreaChange}/>
                                            <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.areaNameErr}</div>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl>
                                            <label htmlFor="areaNameTm">Area Name(Tamil)</label>
                                            <TextField id="areaNameTm" 
                                                variant="outlined" 
                                                placeholder="மண்டல பெயரை உள்ளிடவும்" 
                                                name="areaNameTm" 
                                                value={this.state.areaNameTm} 
                                                onChange={this.handleAreaChange}/>
                                            <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.emailErr}</div>
                                        </FormControl>
                                    </Grid>
                               
                                        <Grid item xs={6}>
                                            <FormControl>
                                                <label className="required" htmlFor="ulbName" align="left">ULB / Cluster Name</label>
                                                <Select value={this.state.ulbName} onChange={this.handleAreaChange} name="ulbName">
                                                    {
                                                        this.state.ulbOptions && this.state.ulbOptions.map((option) =>
                                                            <MenuItem value={option.id}>{option.name}</MenuItem>
                                                        )}
                                                </Select>

                                                <div style={{ fontSize: 12, color: "red" }}>{this.state.ulbNameErr}</div>
                                            </FormControl>
                                        </Grid>
                                        {this.state.isZoneNotRequired == "false"? 
                                          <Grid item xs={6}>
                                
                                          <FormControl>
                                  <label htmlFor="zone" align="left">Zone Name</label>
                                  <Select value={this.state.zone} onChange={this.handleAreaChange} name="zone">
                                  {this.state.ZoneOptions.map((option) =>{ 
                                                  
                                                  return(
                                                  <MenuItem value={option.Id}>{option.Name}</MenuItem>
                                              )})}
                                          </Select> 
                              </FormControl>
                              
                                  </Grid>:""
                                    
                                    }
                                      
                                        <Grid item xs={6}>     
                                        <FormControl>
                                            <label htmlFor="ward" align="left">Ward</label>
                                            <Select value={this.state.wardNumber} onChange={this.handleAreaChange} name="wardNumber">
                                                        {!!this.state.wardOptions.length !=0&&this.state.wardOptions.map((option) =>{ 
                                                            if(option.wardId == null){
                                                                swal({
                                                                    title:'ward not avaliable!!',
                                                                    icon:'warning'
                                                                })
                                                            }
                                                            return(
                                                            <MenuItem value={option.wardId}>{option.wardNumber}</MenuItem>
                                                        )})}
                                                    </Select> 
                                        </FormControl>
                                        
                                            </Grid>
                                    <Grid item xs={6}>
                                         <FormControl>
                                                <label htmlFor="isDisableProperty" align="left">Status</label>
                                                <Select value={this.state.isDisableProperty} onChange={this.handleAreaStatus} name="isDisableProperty">
                                                <MenuItem value={false}>{'Active'}</MenuItem>
                                                <MenuItem value={true}>{'In Active' }</MenuItem>
                                        </Select> 
                                         </FormControl>
                                    </Grid>
                                    </Grid>

                                </div>
                            </div>


                        </DialogContent>
                        <DialogActions>
                            {this.state.openAreaPop === true ?
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <div className="create_ulb_buttons" style={{ marginBottom: '10px' }}>
                                            <Button className="login_submit" variant="outlined" color="primary" onClick={this.handleAreaClosePop}>Cancel</Button>
                                            <Button className="login_submit" variant="contained" color="primary" onClick={() => this.handleAreaSubmit()}>{this.state.editId != "" ? `Save Changes` : `Save`}</Button>
                                        </div>
                                    </Grid>
                                </Grid> : ""}
                        </DialogActions>
                    </Dialog>
                    </div>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        token: state.auth.token,
        area:state.areaListing.area,
        totalCount:state.areaListing.totalCount,
        AreaHeader: state.AreaHeader.AreaHeaders ? state.AreaHeader.AreaHeaders.concat({header: "Action", accessor: "actionButton", sortable: "false", filter: "true"}) :[],
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchAreaList: (onFetchAreaList) => dispatch(actionsCreators.AreaList(onFetchAreaList)),
        onfetchAreaHeaders:(payload)=>dispatch(actionsCreators.fetchAreaHeaders(payload)),
        onDeleteArea : (payload)=>dispatch(actionsCreators.deleteArea(payload)),
        onEditArea : (formdata,props, payload) => dispatch(actionsCreators.editArea(formdata,props, payload)),
        onSearchArea : (payload) => dispatch(actionsCreators.searchAreaName(payload)),
        OnFetchAreaListingExport : (payload) => dispatch(actionsCreators.fetchAreaListingExport(payload))
    }
}

export default  connect(mapStateToProps, mapDispatchToProps)(AreaListing);
