import * as actionTypes from '../actions/actionsTypes';
import { updateObject } from '../utility';

const initialState = {
   stateDashboard: [],
   stateDashboardChartData:[],
    loading: false,
    totalCountLoadsMax:0,
    dashboardListing:[],
    totalCount:0
}

const fetchStateDashboardStart = (state, action) => {
    return updateObject(state, {
        loading: true,
    });
}

const fetchStateDashboardSuccess = (state, action) => {
    return updateObject(state, {
        stateDashboard: action.data,
        totalCount: action.totalCount,
        loading: false,
    });
}

const fetchStateDashboardFail = (state, action) => {
    return updateObject(state, {
        loading: false,
    })
}

const fetchStateDashboardChartDataStart = (state, action) => {
    return updateObject(state, {
        loading: true,
    });
}

const fetchStateDashboardChartDataSuccess = (state, action) => {
    return updateObject(state, {
        stateDashboardChartData: action.data,
        loading: false,
        totalCountLoadsMax: action.totalLoadsCount
    });
}

const fetchStateDashboardChartDataFail = (state, action) => {
    return updateObject(state, {
        loading: false,
    })
}


const fetchSRegionalStart = (state, action) => {
    return updateObject(state, {
        loading: true,
    });
}

const fetchSRegionalSuccess = (state, action) => {
    return updateObject(state, {
        dashboardListing: action.listing,
        totalCount: action.totalCount,
        loading: false,
    });
}

const fetchSRegionalFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error:action.error
    })
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_STATE_DASHBOARD_START: return fetchStateDashboardStart(state, action);
        case actionTypes.FETCH_STATE_DASHBOARD_SUCCESS: return fetchStateDashboardSuccess(state, action);
        case actionTypes.FETCH_STATE_DASHBOARD_FAIL: return fetchStateDashboardFail(state, action);
        case actionTypes.FETCH_STATE_DASHBOARD_CHART_DATA_START: return fetchStateDashboardChartDataStart(state, action);
        case actionTypes.FETCH_STATE_DASHBOARD_CHART_DATA_SUCCESS: return fetchStateDashboardChartDataSuccess(state, action);
        case actionTypes.FETCH_STATE_DASHBOARD_CHART_DATA_FAIL: return fetchStateDashboardChartDataFail(state, action);
        case actionTypes.FETCH_REGIONAL_FAIL : return fetchSRegionalFail (state, action)
        case actionTypes.FETCH_REGIONAL_START : return fetchSRegionalStart (state, action)
        case actionTypes.FETCH_REGIONAL_SUCCESS : return fetchSRegionalSuccess (state, action)

        default: return state;
    }
}

export default reducer;