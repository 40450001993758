import React, { Component}from 'react';
import { Route, Switch } from 'react-router-dom';
import { uniqBy } from 'lodash';
import { rolesConfig } from '../config/roles';
import { roleNameConfig } from '../config/roles'
import * as Routes from './index';
import swal from 'sweetalert';


// Components
import Dashboard                from '../containers/Dashboard/Dashboard';
import EmailManagement          from '../containers/RecordKeeper/EmailManagement/EmailManagement'
import OperatorAdd              from '../containers/OperatorManagement/OperatorAdd'
import OperatorEdit             from '../containers/OperatorManagement/OperatorEdit';
import OperatorListing          from '../containers/OperatorManagement/OperatorListing'
import PropertyAdd              from '../containers/PropertyManagement/PropertyAdd'
import PropertyListing          from '../containers/PropertyManagement/PropertyListing'
import RecordKeeper             from '../containers/RecordKeeper/loads/RecordKeeper';
import RecordKeeperEdit         from '../containers/RecordKeeper/loads/RecordKeeperEdit'
import TreatmentFacility        from '../containers/TreatmentFacility/TreatmentFacility'
import TreatmentFacilityNew     from '../containers/TreatmentFacility/TreatmentFacilityNew'
import TreatmentFacilityEdit    from '../containers/TreatmentFacility/TreatmentFacilityEdit';
import TruckDocs                from '../containers/TruckManagement/TruckDocs'
import TruckListing             from '../containers/TruckManagement/TruckListing'

// const PrivateRoutes = ({ match }) => (
//     <Switch>
//         <Route path='/'                              exact component={Dashboard}/>
//         <Route path='/dashboards'                    exact component={Dashboard}/>
//         <Route path='/email_manage'                  exact component={EmailManagement} />
//         <Route path='/operator-management/add/new'   exact component={OperatorAdd}/>
//         <Route path='/operator-management/:id'       exact component={OperatorEdit}/>
//         <Route path='/operator-management'           exact component={OperatorListing}/>
//         <Route path='/property-management/add/new/'  exact component={PropertyAdd}/>
//         <Route path='/property-management'           exact component={PropertyListing}/>
//         <Route path='/records'                       exact component={RecordKeeper} />
//         <Route path='/record_edit/:id'               exact component={RecordKeeperEdit}/>
//         <Route path='/treatment'                     exact component={TreatmentFacility} />
//         <Route path='/new_TF'                        exact component={TreatmentFacilityNew}/>
//         <Route path='/edit_TF/:id'                   exact component={TreatmentFacilityEdit}/>
//         <Route path='/truck-docs/:operatorId/:id'    exact component={TruckDocs}/>
//         <Route path='/truck-management/:id'          exact component={TruckListing}/>

// 	</Switch>
// );

class PrivateRoutes extends Component {
    state = { allowedRoutes: [] };

    componentDidMount() {
        let role = localStorage.getItem('roles');
        let roleName = localStorage.getItem('roleName');
		if (roleName) { 
            let allowedRoutes;
            if(roleNameConfig[roleName]) {
                allowedRoutes = roleNameConfig[roleName].routes
                allowedRoutes = uniqBy(allowedRoutes, 'url');
                this.setState({ allowedRoutes });
            }
            else {
                // swal("Not Authorised to Use the application")
                allowedRoutes = rolesConfig[0].routes
			    allowedRoutes = uniqBy(allowedRoutes, 'url');
                this.setState({ allowedRoutes });
            }
            let allowedComponents = allowedRoutes.map(({ component }) => component)
            localStorage.setItem('allowedComponents', allowedComponents)

		} else {
			this.props.history.push('/');
        }
    }
    
    render() {
		return (
            <Switch>
                {this.state.allowedRoutes.map((route, index) => (
                    <Route key={index} path={`${this.props.match.path}${route.url}`} exact component={Routes[route.component]}/>
                ))}
            </Switch>
		);
	}
}

export default PrivateRoutes;