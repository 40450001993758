import * as actionTypes from '../actionsTypes'
import axios from '../../../http-config'

export const fetchTruckHeadersStart = () => {
    return({
        type : actionTypes.FETCH_TRUCK_HEADERS_START
    })
}
export const fetchTruckHeadersSuccess = (response) => {
    return({
        type : actionTypes.FETCH_TRUCK_HEADERS_SUCCESS,
        truckHeaders : response
    })
}
export const fetchTruckHeadersFail = (err) => {
    return({
        type : actionTypes.FETCH_TRUCK_HEADERS_FAIL,
        error : err
    })
}
export const fetchTruckHeaders = (payload) => {
    const token = payload.token
    return (dispatch) => {
        dispatch(fetchTruckHeadersStart())
        axios({
            method : 'get',
            url : '/truck/headers',
            headers: {'Authorization': `Bearer ${token}`}
        })
        .then((response) => {
            const truckHeaders = response.data.data.headers
            dispatch(fetchTruckHeadersSuccess(truckHeaders))
        })
        .catch((err) => {
            dispatch(fetchTruckHeadersFail(err))
        })
    }
}

