import React, { Component } from 'react'
import axios from '../../http-config'
import { connect } from 'react-redux';
import * as actionsCreators from '../../store/actions/index';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import Grid from '@material-ui/core/Grid';
import Card from "../Dashboard/Card/Card"
import CardHeader from "../Dashboard/Card/CardHeader";
import CardIcon from "../Dashboard/Card/CardIcon";
import swal from 'sweetalert'
import CardBody from '../Dashboard/Card/CardBody'
import { TextField } from "@material-ui/core";
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import Slider from '@material-ui/core/Slider'
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Chart from './Chart'
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

const PrettoSlider = withStyles({
  root: {
    color: '#52af77',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
    backgroundColor: "gray !important",
    width: " 102% !important",

  },
})(Slider);
class CardMain extends Component {


  state = {
    startDate: moment(new Date()).subtract(7, 'd').format('YYYY-MM-DD'),
    endDate: new Date(),
    data: [],
    category: 1,
    interval: 'Current Day',
    sliderValue: [0, 100],
    // loadsSliderValue:[0,0],
    // maxCount : this.props.totalCountLoads? this.props.totalCountLoads:0
    EndLoads: 0,
    stateLoads: 0,
    controls: {
      search: {
        value: '',
      }
    },
    dataValue:""
  }
  componentDidMount = async () => {
    let payload = {
      token: this.props.token,
      startDate: moment(this.state.startDate).format('YYYY-MM-DD'),
      endDate: moment(this.state.endDate).format('YYYY-MM-DD'),
      category: this.state.category,
      sliderValue: this.state.sliderValue,
      endutilizationRateIn: this.state.sliderValue[1],
      startutilizationRateIn: this.state.sliderValue[0],
      excalDownload: true,
      ulbSerach: this.state.controls.search.value
    }
    localStorage.setItem('sdFilterDate', moment(new Date()).format('YYYY-MM-DD'));
    this.props.onFetchChartData(payload)
    await this.props.onFetchDashboardData(payload)
  }

  fetchDataBasedonFilter = async () => {

    let payload = {
      token: this.props.token,
      interval: this.state.interval,
      startDate: moment(this.state.startDate).format('YYYY-MM-DD'),
      endDate: moment(this.state.endDate).format('YYYY-MM-DD'),
      category: this.state.category,
      sliderValue: this.state.sliderValue,
      endutilizationRateIn: this.state.category == 1 ? this.state.sliderValue[1] : this.state.EndLoads,
      startutilizationRateIn: this.state.category == 1 ? this.state.sliderValue[0] : this.state.stateLoads,
      ulbSerach: this.state.controls.search.value
    }
    this.props.onFetchChartData(payload)
    await this.props.onFetchDashboardData(payload)
  }

  handleSliderChange = (event, value) => {
    this.setState({ sliderValue: value }, () => {
      this.fetchDataBasedonFilter()
    })
  }
  handleSliderState = (event, value) => {
    if (value[0] == value[1]) {
      return
    } else {
      this.setState({ sliderValue: value })
    }

  }

  // handleLoadSliderChange =(event,value) => {
  //   this.setState({loadsSliderValue:value},() => {
  //     this.fetchDataBasedonFilter()
  //   })
  // }

  // handleLoadSliderState = (event,value) => {
  //   if(value[0] == value[1]){
  //     return
  //   }else{
  //     this.setState({loadsSliderValue:value})
  //   }

  // }

  handleStartDate = (date) => {
    this.setState({ startDate: date }, () => {
      this.fetchDataBasedonFilter()
    })
  }

  handleEndDate = (date) => {
    this.setState({ endDate: date }, () => {
      this.fetchDataBasedonFilter()
    })
  }

  handleTfType = (event) => {
    this.setState({ category: event.target.value }, () => {
      this.fetchDataBasedonFilter()
    })
  }

  async setFilterByDateField(value) {
    let newDate = new Date()
    if (value != null) {
      switch (value.title) {
        case '7 Days':
          localStorage.setItem('sdFilterDate', moment().subtract(7, 'd').format('YYYY-MM-DD'));
          break;
        case '30 Days':
          localStorage.setItem('sdFilterDate', moment().subtract(30, 'd').format('YYYY-MM-DD'));
          break;
        case '1 Year':
          localStorage.setItem('sdFilterDate', moment().subtract(365, 'd').format('YYYY-MM-DD'));
          break;
        case 'Till Date':
          localStorage.setItem('sdFilterDate', moment().subtract(1825, 'd').format('YYYY-MM-DD'));
          break;
        default:
          localStorage.setItem('sdFilterDate', moment(new Date()).format('YYYY-MM-DD'));
      }
      this.setState({
        endDate: moment(new Date()).format('YYYY-MM-DD'),
        startDate: moment(localStorage.getItem('sdFilterDate')),
        interval: value.title
      }, () => {
        this.fetchDataBasedonFilter()
      })
    }

  }
  handleTopenData = async () => {
    let payload = {
      token: this.props.token,
      interval: this.state.interval,
      startDate: moment(this.state.startDate).format('YYYY-MM-DD'),
      endDate: moment(this.state.endDate).format('YYYY-MM-DD'),
      category: this.state.category,
      sliderValue: this.state.sliderValue,
      endutilizationRateIn: this.state.category == 1 ? this.state.sliderValue[1] : this.state.EndLoads,
      startutilizationRateIn: this.state.category == 1 ? this.state.sliderValue[0] : this.state.stateLoads,
      ulbSerach: this.state.controls.search.value,
    }
    this.props.onFetachTopTenData(payload)
    // await this.props.onFetchDashboardData(payload)

    this.setState({dataValue:"topValue"})
  }

  handleBottomTenData = () => {
    let payload = {
      token: this.props.token,
      interval: this.state.interval,
      startDate: moment(this.state.startDate).format('YYYY-MM-DD'),
      endDate: moment(this.state.endDate).format('YYYY-MM-DD'),
      category: this.state.category,
      sliderValue: this.state.sliderValue,
      endutilizationRateIn: this.state.category == 1 ? this.state.sliderValue[1] : this.state.EndLoads,
      startutilizationRateIn: this.state.category == 1 ? this.state.sliderValue[0] : this.state.stateLoads,
      ulbSerach: this.state.controls.search.value,
      bottomValue: "bottomvalue" 
    }

    this.props.onFetachBottomTenData(payload)
    this.setState({dataValue:"bottomValue"})

  }


  handleULBSearch = async (e) => {
    e.preventDefault()
    let searchVal = this.state.controls.search.value
    if (!!searchVal && searchVal.length >= 2) {
      let payload = {
        ulbSerach: searchVal,
        token: this.props.token,
        interval: this.state.interval,
        startDate: moment(this.state.startDate).format('YYYY-MM-DD'),
        endDate: moment(this.state.endDate).format('YYYY-MM-DD'),
        category: this.state.category,
        sliderValue: this.state.sliderValue,
        endutilizationRateIn: this.state.category == 1 ? this.state.sliderValue[1] : this.state.EndLoads,
        startutilizationRateIn: this.state.category == 1 ? this.state.sliderValue[0] : this.state.stateLoads,

      }
      this.props.onFetchChartData(payload)
      await this.props.onFetchDashboardData(payload)
    } else {

      swal("Enter atleast 2 characters")
    }
  }
  inputChangedHadler = async (event, controlName) => {
    const updatedControls = {
      ...this.state.controls,
      [controlName]: {
        ...this.state.controls[controlName],
        value: event.target.value,
      }
    }
    this.setState({ controls: updatedControls });
    if (updatedControls.search.value === '') {
      let payload = {
        ulbSerach: '',
        token: this.props.token,
        interval: this.state.interval,
        startDate: moment(this.state.startDate).format('YYYY-MM-DD'),
        endDate: moment(this.state.endDate).format('YYYY-MM-DD'),
        category: this.state.category,
        sliderValue: this.state.sliderValue,
        endutilizationRateIn: this.state.category == 1 ? this.state.sliderValue[1] : this.state.EndLoads,
        startutilizationRateIn: this.state.category == 1 ? this.state.sliderValue[0] : this.state.stateLoads,
      }
      this.props.onFetchChartData(payload)
      await this.props.onFetchDashboardData(payload)
    }
  }
  render() {
    let payload = {
      token: this.props.token,
      startDate: moment(this.state.startDate).format('YYYY-MM-DD'),
      endDate: moment(this.state.endDate).format('YYYY-MM-DD'),
      category: this.state.category,
      sliderValue: this.state.sliderValue,
      endutilizationRateIn: this.state.sliderValue[1],
      startutilizationRateIn: this.state.sliderValue[0],
      excalDownload: true,
      interval: this.state.interval,
      ulbSerach: this.state.controls.search.value

    }

    let data = this.props.data.dashboard_data
    const maxCount = this.props.data.dashboard_data && this.props.data.dashboard_data[0] && this.props.data.dashboard_data[0].table_data.maxNoOfLoadsReceived
    const loaderMarks = [
      {
        value: 0,
        label: '0',
      },
      {
        value: maxCount,
        label: maxCount,
      }
    ];
    const options = [
      {
        id: "1",
        name: "FSTP",
      }, {
        id: "2",
        name: "STP",
      }
    ];
    const marks = [
      {
        value: 0,
        label: '0',
      },
      {
        value: 25,
        label: '25',
      },
      {
        value: 50,
        label: '50',
      },
      {
        value: 75,
        label: '75',
      },
      {
        value: 100,
        label: '100',
      }
    ];

    const NORECEIVEDLOAD = this.props.data.dashboard_data && this.props.data.dashboard_data[0] && this.props.data.dashboard_data[0].table_data.NORECEIVEDLOAD
    const SEPTAGERECEIVED = this.props.data.dashboard_data && this.props.data.dashboard_data[0] && this.props.data.dashboard_data[0].table_data.SEPTAGERECEIVED
    const TFCOUNT = this.props.data.dashboard_data && this.props.data.dashboard_data[0] && this.props.data.dashboard_data[0].table_data.TFCOUNT
    const ULBCOUNT = this.props.data.dashboard_data && this.props.data.dashboard_data[0] && this.props.data.dashboard_data[0].table_data.ULBCOUNT

    return (
      <div className="mobile-size-sd">
        <Grid container spacing={2} >
          <Grid item lg={this.state.category == 1 ? 3 : 4} sm={12}>
            {/* <Card>
             <CardHeader icon>
              <hr style={{border: "1px solid #3DAB35", marginLeft:"5%", width:"30px"}}></hr>
              <h2 style={{color:"#3DAB35",fontSize:"20px",paddingTop: "10px"}} align="left">Filter By</h2>
             </CardHeader>
               <CardBody style={{marginTop:"-6px", marginLeft:'-5px'}}>
                  <Grid container spacing={0} direction="row">
                    <Grid item xs={6}>
                     
                      <h2  style = {{fontSize:"12px", marginBottom: "4%" , marginLeft:"1%", marginRight:"5%", textAlign:'left' }}>Days</h2>
                       <Autocomplete
                          style = {{width: '100%', fontSize:"10px"}}
                          value={this.state.interval== undefined? '7 Days' :this.state.interval}
                          onChange={(event, value) => this.setFilterByDateField(value)}
                          id="combo-box-demo"
                          options={[{title:"Current Day"},{title:"7 Days"},{title:"30 Days"},{title:"1 Year"},{title: 'Till Date'}]}
                          getOptionLabel={option => typeof option === 'string' ? option : option.title}
                          renderInput={(params) => <TextField {...params} style = {{width: '100%', fontSize:"12px"}} placeholder="Select" size="small" variant="outlined" />}
                        />
                    </Grid>
                    <Grid item xs={6}>
                      <h2 style = {{fontSize:"12px", marginBottom: "4%" , marginLeft:"5%", textAlign:'left' }}>Date</h2>
                      <TextField  id="sdFilterDate" disabled style = {{width: '100%', marginLeft:"5%", fontSize:"12px"}} size="small"  variant="outlined" value={localStorage.getItem('sdFilterDate')}  type="text" />
                    </Grid>
                  </Grid>
               </CardBody>
           </Card> */}
            <Card>
              <CardHeader stats icon>
                <hr style={{ border: "1px solid #3DAB35", marginLeft: "1%", width: "30px" }}></hr>
                <h2 style={{ color: "#3DAB35", fontSize: "17px", paddingTop: "10px" }} align="left">Filter By Date Range</h2>
              </CardHeader>
              <CardBody>
                <Grid container spacing={0} direction="row">
                  <Grid item xs={6}>
                    <h2 style={{ fontSize: "12px", marginBottom: "4%", marginLeft: "5%", textAlign: 'left' }}>From Date</h2>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        variant="inline"
                        value={this.state.startDate}
                        onChange={this.handleStartDate}
                        disableFuture
                        format='dd/MM/yyyy'
                        autoOk
                        style={{ width: '95%', borderBottom: 'none' }}
                        InputProps={{
                          style: {
                            fontSize: 'small', height: '35px', marginTop: '1px', border: '1px solid gray',
                            borderRadius: '4px', fontFamily: 'Open Sans, sans-serif'
                          }, disableUnderline: true
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={6}>
                    <h2 style={{ fontSize: "12px", marginBottom: "4%", marginLeft: "5%", textAlign: 'left' }}>To Date</h2>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        variant="inline"
                        value={this.state.endDate}
                        onChange={this.handleEndDate}
                        disableFuture
                        format='dd/MM/yyyy'
                        autoOk
                        style={{ width: '95%', borderBottom: 'none' }}
                        InputProps={{
                          style: {
                            fontSize: 'small', padding: '4px', height: '35px', marginTop: '1px', border: '1px solid gray',
                            borderRadius: '4px', fontFamily: 'Open Sans, sans-serif'
                          }, disableUnderline: true
                        }}
                      />
                    </MuiPickersUtilsProvider>                    </Grid>
                </Grid>
              </CardBody>
            </Card>
          </Grid>
          <Grid item lg={this.state.category == 1 ? 3 : 5} xs={12}>
            <Card>
              <hr style={{ border: "1px solid #3DAB35", marginLeft: "5%", width: "30px" }}></hr>
              <h2 style={{ color: "#3DAB35", fontSize: "17px", paddingTop: "10px", marginLeft: "20px" }} align="left">Filter By Category</h2>
              <CardBody>
                <FormControl>
                  <Select value={this.state.category} onChange={this.handleTfType} name="category" style={{ border: "1px solid #a8a8a8", borderBottom: "none", marginTop: "18px" }}>
                    {options.map((option, index) => (
                      <MenuItem key={index} value={option.id}>{option.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </CardBody>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card>
              <hr style={{ border: "1px solid #3DAB35", marginLeft: "5%", width: "30px" }}></hr>
              <h2 style={{ color: "#3DAB35", fontSize: "17px", paddingTop: "10px", marginLeft: "20px" }} align="left">ULB Search</h2>
              <CardBody>
                <Paper component="form" style={{ float: "right", width: "100%", display: "flex" }}>
                  <IconButton aria-label="menu">
                    <img src="images/search_icon.svg" />
                  </IconButton>
                  <InputBase onChange={(event) => this.inputChangedHadler(event, 'search')} placeholder="ULB Name" style={{ width: "50%" }} />
                  <Button onClick={this.handleULBSearch} variant="contained" style={{ color: 'white', backgroundColor: '#3DAB35', width: '50%' }} type="submit">Search</Button>
                </Paper>
              </CardBody>
            </Card>

          </Grid>
          {this.state.category == 1 && <Grid item lg={3} xs={12}>
            <Card>
              <hr style={{ border: "1px solid #3DAB35", marginLeft: "5%", width: "30px" }}></hr>
              <h2 style={{ color: "#3DAB35", fontSize: "17px", paddingTop: "10px", marginLeft: "25px" }} align="left">Filter By Utilization</h2>
              <CardHeader color="warning" icon>
                <CardBody>
                  <PrettoSlider
                    name="sliderValue"
                    value={this.state.sliderValue}
                    onChange={this.handleSliderState}
                    onChangeCommitted={this.handleSliderChange}
                    valueLabelDisplay="auto"
                    marks={marks}
                    aria-label="pretto slider"
                    step={1}
                    className="sliderpre" />
                </CardBody>
              </CardHeader>
            </Card>
          </Grid>}
          {/* {this.state.category == 2 && <Grid item lg={4} xs={12}>
          <Card>
          <hr style={{border: "1px solid #3DAB35", marginLeft:"5%", width:"30px"}}></hr>
            <h2 style={{color:"#3DAB35",fontSize:"17px",paddingTop: "10px",marginLeft: "20px"}} align="left">Filter By Loads</h2> */}
          {/* <CardHeader color="warning" stats icon> */}
          {/* <CardBody> */}
          {/* <PrettoSlider
                    name= "sliderValue"
                    defaultValue={this.state.loadsSliderValue[1]}
                    // defaultValue={this.state.loadsSliderValue && this.state.loadsSliderValue[1] > maxCount ? [0,maxCount] : this.state.loadsSliderValue}

                    value={this.state.loadsSliderValue}
                    onChange={this.handleLoadSliderState}
                    onChangeCommitted={this.handleLoadSliderChange}
                    valueLabelDisplay="auto"
                    min = {0}
                    max={maxCount}
                    marks={loaderMarks}
                    aria-label="pretto slider"
                    step={1}
                    className="sliderpre"/> */}
          {/* <FormControl>
                    <Select value={this.state.EndLoads} onChange={this.loadsFilter} name="loads" style={{border:"1px solid #a8a8a8", borderBottom:"none", marginTop :"18px"}}>
                          
                               <MenuItem value={100}>Loads from 0 - 100</MenuItem>
                               <MenuItem value={500}>Loads from 101 - 500</MenuItem>
                               <MenuItem value={1000}>Loads from 501 - 1000</MenuItem>
                            
                      </Select>
                </FormControl> */}
          {/* </CardBody>           */}
          {/* </CardHeader>  */}
          {/* </Card>
        </Grid>} */}

          {/* <Grid container spacing={2} >
      <Grid item xs={4}></Grid>
      <Grid item xs={4}></Grid>
      <Grid item xs={4}>
        <Button style={{color:'white', backgroundColor:'#3DAB35'}}>{"Top Ten Data"}</Button>
        <Button style={{color:'white', backgroundColor:'#3DAB35'}}>{"Top >Ten Data"}</Button>
      </Grid>
      </Grid> */}

        </Grid>
        <Grid container spacing={3} style={{marginTop:"8px"}}>
          <Grid item xs={3}>
            <Card>
              <CardHeader color="warning" icon> </CardHeader>
              <CardBody>
                <h2 style={{ color: "black", fontSize: "15px", textAlign: 'center' }} >Number of ULB's</h2>
                <h2  style ={{color: "#3dab35",fontSize: "25px",minHeight: "5px",fontWeight: "1000",textAlign:'center', marginBottom: "10px", }} >
                                            {!!ULBCOUNT?ULBCOUNT:0}</h2>
              </CardBody>

            </Card>
          </Grid>
          <Grid item xs={3}>
          <Card>
              <CardHeader color="warning" icon> </CardHeader>
              <CardBody>
                <h2 style={{ color: "black", fontSize: "15px", textAlign: 'center' }} >Disposal Facilities</h2>
                <h2  style ={{color: "#3dab35",fontSize: "25px",minHeight: "5px",fontWeight: "1000",textAlign:'center', marginBottom: "10px", }} >
                                            {!!TFCOUNT?TFCOUNT:0}</h2>
              </CardBody>

            </Card>
          </Grid>
          <Grid item xs={3}>
          <Card>
              <CardHeader color="warning" icon> </CardHeader>
              <CardBody>
                <h2 style={{ color: "black", fontSize: "15px", textAlign: 'center' }} >Quantity of Septage (in Liters)</h2>
                <h2  style ={{color: "#3dab35",fontSize: "25px",minHeight: "5px",fontWeight: "1000",textAlign:'center', marginBottom: "10px", }} >
                                            {!!SEPTAGERECEIVED?SEPTAGERECEIVED:0}</h2>
              </CardBody>

            </Card>
          </Grid>
          <Grid item xs={3}>
          <Card>
              <CardHeader color="warning" icon> </CardHeader>
              <CardBody>
                <h2 style={{ color: "black", fontSize: "15px", textAlign: 'center' }} >Number of loads received</h2>
                <h2  style ={{color: "#3dab35",fontSize: "25px",minHeight: "5px",fontWeight: "1000",textAlign:'center', marginBottom: "10px", }} >
                                            {!!NORECEIVEDLOAD?NORECEIVEDLOAD:0}</h2>
              </CardBody>

            </Card>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: '-45px' }}>
          <Grid item xs={6}></Grid>
          <Grid item xs={4}>
          </Grid>
        </Grid>
        <Grid container spacing={2} >
          <Grid item xs={10}></Grid>
          <Grid item xs={1}>
            <Button style={{ color: 'white', backgroundColor: '#3DAB35', }} onClick={this.handleTopenData}>{"Top 10"}</Button>
          </Grid>
          <Grid item xs={1}>
            <Button style={{ color: 'white', backgroundColor: '#3DAB35', float: "left" }} onClick={this.handleBottomTenData}>{"Bottom10"}</Button>
          </Grid>
          <Grid item xs={12}>
            <Chart payloadExcal={payload} updownData ={this.state.dataValue}/>
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    data: state.stateDashboard.stateDashboard,
    loading: state.loads.loading,
    token: state.auth.token,
    chartData: state.stateDashboard.stateDashboardChartData
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchDashboardData: (payload) => dispatch(actionsCreators.fetchStateDashboard(payload)),
    onFetchChartData: (payload) => dispatch(actionsCreators.fetchChartData(payload)),
    onFetachTopTenData: (payload) => dispatch(actionsCreators.fetachTopTenData(payload)),
    onFetachBottomTenData: (payload) => dispatch(actionsCreators.fetachBottomTenData(payload))

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(CardMain, axios));
