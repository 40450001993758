import React, { Component } from 'react'
import { connect } from 'react-redux';
import {Link} from 'react-router-dom'
import swal from 'sweetalert'
import * as actionsCreators from  '../../store/actions/index';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { DatePicker , MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment'
import ReactPaginate from 'react-paginate';
import TextField from '@material-ui/core/TextField';

class TreatmentViewReason extends Component {
    constructor(props){
        super(props)
        var date = new Date()
       var y = date.getFullYear()
       var m = date.getMonth();
    var firstDay = new Date(y, m, 1);
    var lastDay = new Date(y, m + 1, 0);


this.state = { 
    id         : !!this.props&&this.props.match.params.id,
    page:1,
    limit:10,

    fromDate: moment(firstDay).format('YYYY-MM-DD'),
    toDate:moment(lastDay).format('YYYY-MM-DD'),
    yearValue:new Date(),
    monthValue:new Date(),


}
    }
   
    componentDidMount() {
        let payload = {
            token  : this.props.token,
            page:this.state.page,
            limit:this.state.limit,
            tfId:this.state.id,
            fromDate: this.state.fromDate,
            toDate:this.state.toDate,
            monthName:'',
            year:''
        }
        this.props.onFetchTreatmentListingReasonView(payload) 
    }



    handlePageClick = (e) => {
        const selectedPage = e.selected;
        this.setState({page: e.selected+1})
        let payload = {
            token: this.props.token,
            page:  selectedPage + 1,
            limit: this.state.limit,
            tfId:this.state.id,
            fromDate: this.state.fromDate,
            toDate:this.state.toDate,
            monthName:moment(this.state.monthValue).format('MMMM'),
            year:moment(this.state.yearValue).format('YYYY')
           
        }
        this.props.onFetchTreatmentListingReasonView(payload)
    };


    yearChange=(event)=>{
        this.setState({yearValue:event })
        const yer = moment(event).format('YYYY')
        let payload = {
            token  : this.props.token,
            page:this.state.page,
            limit:this.state.limit,
            tfId:this.state.id,
            fromDate: '',
            toDate:'',
            monthName:moment(this.state.monthValue).format('MMMM'),
            year:yer
        }
        this.props.onFetchTreatmentListingReasonView(payload) 
    }

    monthChange = (event)=>{
        const month = moment(event).format('MMMM')
        this.setState({monthValue:event})
        let payload = {
            token  : this.props.token,
            page:this.state.page,
            limit:this.state.limit,
            tfId:this.state.id,
            fromDate: '',
            toDate:'',
            monthName:month,
            year:moment(this.state.yearValue).format('YYYY')
        }
        this.props.onFetchTreatmentListingReasonView(payload) 
    }
    setToDate =(event)=>{
        const date = moment(event).format('YYYY-MM-DD')
        this.setState({toDate:date})
        let payload = {
            token  : this.props.token,
            page:this.state.page,
            limit:this.state.limit,
            tfId:this.state.id,
            fromDate: this.state.fromDate,
            toDate:date,
            year:moment(this.state.yearValue).format('YYYY'),
          monthName:moment(this.state.monthValue).format('MMMM'),
        }
        this.props.onFetchTreatmentListingReasonView(payload) 
    }
    setFromDate = (e)=>{
        const date1 = moment(e).format('YYYY-MM-DD')
        this.setState({fromDate:date1})
        let payload = {
            token  : this.props.token,
            page:this.state.page,
            limit:this.state.limit,
            tfId:this.state.id,
            fromDate:date1,
            toDate:this.state.toDate,
            year:moment(this.state.yearValue).format('YYYY'),
            monthName:moment(this.state.monthValue).format('MMMM'),
        }
        this.props.onFetchTreatmentListingReasonView(payload) 
    }
    render() {
        let headers = [{
            accessor: "DateTime",
            filter: "false",
            header: "Date",
            sortable: "false",
        },
        {
            accessor: "Reason",
            filter: "false",
            header: "Reason",
            sortable: "false",
        },]
        const Headerlist =!!headers&&headers.map(header => {  
          {
                return <TableCell key={header.header} className={"white-space"}>
                                <div style={{
                        display: 'flex',
                        alignItems: 'start',
                        whiteSpace: 'break-spaces',
                        height: '3em'
                    }}>
                {/* {header.header === "Action"? "":
                    <div onClick={(e) =>  this.handleSort(e,header)}>
                        <div>{
                            this.state.currentSortId===header.accessor? this.state.sortObj.value ==='asc'?(<ArrowDropDownIcon fontSize="small"/>):(<ArrowDropUpIcon fontSize="small"/>) :(<ArrowDropDownIcon fontSize="small"/>)
                        }
                        </div>  
                    </div>} */}
                        {header.header}
                    </div>
                        </TableCell>
            }
        }
        );
   
        const facilities = this.props.reasonApi.map((facility, j)=>{
            return <TableRow style={{whiteSpace: 'normal',
            wordWrap: 'break-word'}} key={j}>
                {!!headers&&headers.map((header, i)=>{
                    if(header.accessor=='DateTime'){
                        return <TableCell style={{
                         whiteSpace: "normal",
                         wordWrap: "break-word"
                       }} key={i} >{ moment(facility[header.accessor]).format('DD-MMM-YYYY')}</TableCell>
                    }
                    if(header.accessor=='Reason') {
                     return  <TableCell style={{
                         whiteSpace: "normal",
                         wordWrap: "break-word"
                       }} key={i} className={"white-space"}>{facility[header.accessor]}</TableCell>
                    }
                })}
                </TableRow>
            
        })
        return (
            <div>
                
                 <div className="dashboard_right_content" style={{marginTop:'-4%'}}>
                        <img className="dashboard_right_content_top_icon" src="images/Rectangle.svg" />
                        <div className="dashboard_right_content_top">
                        <h5>Disposal Facilities Reasons</h5>
                            <div className="dashboard_right_content_top_options">
                           

            <div style={{marginTop:'20px',marginRight:'-20px'}}>   
                         {/* <lable>Days </lable> */}
                           <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <span style={{fontSize: 'small',fontFamily: 'Open Sans, sans-serif'}}>&nbsp;&nbsp;&nbsp;&nbsp;</span> 
                            <DatePicker
                                variant="inline"
                                value={this.state.fromDate}  
                                onChange={this.setFromDate}
                                disableFuture
                                format='dd MMM YYY'
                                autoOk
                                style={{width:'40%', borderBottom: 'none'}}
                                InputProps={{ style:{fontSize:'small', padding:'4px' ,height:'34px',marginTop:'2px',color: '#3DAB35',border: '1px solid #3DAB35', 
                                borderRadius: '4px', fontFamily: 'Open Sans, sans-serif'},disableUnderline: true}}/>
                                <span style={{fontSize: 'small',fontFamily: 'Open Sans, sans-serif'}}>&nbsp;&nbsp;to&nbsp;&nbsp;</span> 
                            <DatePicker
                                variant="inline"
                                value={this.state.toDate} 
                                onChange={this.setToDate} 
                                // disableFuture
                                // minDate={this.state.fromDate}
                                format='dd MMM YYY'
                                autoOk
                                style={{width:'40%'}}
                                InputProps={{ style:{fontSize:'small', padding:'4px' ,height:'34px',marginTop:'2px',color: '#3DAB35',border: '1px solid #3DAB35', 
                                borderRadius: '4px', fontFamily: 'Open Sans, sans-serif'}, disableUnderline: true}}/>
                        </MuiPickersUtilsProvider> 
                        </div>
                        <div style={{display:'flex', flexDirection:'column', marginLeft:'8px'}}>
                        <lable>Year </lable>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                                views={['year']}
                                //   label="Year"
                                value={this.state.yearValue}
                                minDate={new Date('1999-01-01')}
                                //   maxDate={new Date()}
                                onChange={this.yearChange}
                                style={{width:'100%'}}
                                InputProps={{ style:{fontSize:'small', padding:'4px' ,height:'34px',marginTop:'2px',color: '#3DAB35',border: '1px solid #3DAB35', 
                                borderRadius: '4px', fontFamily: 'Open Sans, sans-serif'}, disableUnderline: true}}
                                renderInput={(params) => <TextField {...params} helperText={null} />}
                                />
                                </MuiPickersUtilsProvider> 
                        </div>


                    <div style={{display:'flex', flexDirection:'column', marginLeft:'8px'}}>
                    <lable>Month </lable>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                            views={['month']}
                            //   label="Year and Month"
                            minDate={new Date('1999-01')}
                            maxDate={new Date()}
                            value={this.state.monthValue}
                            onChange={this.monthChange}
                            style={{width:'100%'}}
                            InputProps={{ style:{fontSize:'small', padding:'4px' ,height:'34px',marginTop:'2px',color: '#3DAB35',border: '1px solid #3DAB35', 
                            borderRadius: '4px', fontFamily: 'Open Sans, sans-serif'}, disableUnderline: true}}
                            renderInput={(params) => <TextField {...params} helperText={null} />}
                            />
                            </MuiPickersUtilsProvider> 
                    </div>
                    
                            </div>
                        </div>
                    </div>
                    <div> 
                    {(!this.props.loadingTf && facilities.length === 0 ) && <div id="cover-spin"></div>}
                    <div className="operator_listing" >
                        <TableContainer component={Paper} style={{paddingLeft:"15px",paddingRight:"15px"}}>
                             <Table stickyHeader aria-label="sticky table" style={{ tableLayout: 'fixed' }}>
     
                                <TableHead >
                                    <TableRow >
                                        {Headerlist}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                        {/* {facilities} */}
                                        {facilities.length === 0 ? <TableRow style={{textAlign:'center', fontWeight:'bold', fontSize: '13px', }}>
                                            No Records Found !!</TableRow>:facilities }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    {
                this.props.totalCount && this.props.totalCount != 0 && 
                    <ReactPaginate
                    previousLabel={"Prev"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount = {10}
                    pageCount = {this.props.totalCount/this.state.limit}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    style={{borderLeftWidth:'0px'}}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}/>
                }
                    </div>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        token: state.auth.token,
        // headers:state.treatmentHeaders.headers,
        reasonApi:state.treatmentListing.treatmentListing,
        totalCount:state.treatmentListing.totalCount,
        loadingTf: state.treatmentListing.loading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        // onFetchTreatmentHeaders: (payload) => dispatch(actionsCreators.fetchTreatmentHeaders(payload)),
        onFetchTreatmentListingReasonView: (payload) => dispatch(actionsCreators.fetchTreatmentListingReasonView(payload)),
        // onDeleteTreatmentFacility : (payload,payload1) => dispatch(actionsCreators.deleteTreatmentFacility(payload, payload1)),
        // onSearchTFName :(payload)=>dispatch(actionsCreators.searchTFName(payload))
    }
}

export default  connect(mapStateToProps, mapDispatchToProps)(TreatmentViewReason);
