import * as actionTypes from '../actionsTypes'
import axios from '../../../http-config'

export const fetchClusterStart = () => {
    return({
        type      : actionTypes.FETCH_CLUSTER_START,
        loading: false
    })
}
export const fetchClusterSuccess = (response) => {
    return({
        type      : actionTypes.FETCH_CLUSTER_SUCCESS,
        loading: true,
        ClusterHeaders  : response.data.headers
    })
}
export const fetchClusterFail = (error) => {
    return({
        type      : actionTypes.FETCH_CLUSTER_FAIL,
        loading: false,
        error        : error
    })
}

export const fetchClusterHeaders = (payload) => {
    return (dispatch) => {
        dispatch(fetchClusterStart())
        axios({
            method    : 'get',
            url       : 'ulb/cluster-ulb/headers',
            headers   : {'Authorization': `Bearer ${payload.token}`}
        })
        .then((response) => {
            dispatch(fetchClusterSuccess(response.data))
        })
        .catch((err) => {
            dispatch(fetchClusterFail(err))
        })
    }
}