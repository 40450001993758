import React, { Component } from 'react'
import { connect } from 'react-redux'

import FormControl from '@material-ui/core/FormControl';
import {Link} from 'react-router-dom'
import * as actionsCreators from '../../store/actions/index'
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import axios from '../../http-config';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import Alert from '@material-ui/lab/Alert';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import swal from 'sweetalert';
import { Grid } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

class OperatorForm extends Component {
    constructor(props){
       super(props)
       this.state = {
        username            : this.props.username          ? this.props.username                 : "",
        password            :   "",   
        operatorId          : this.props.operatorId        ? this.props.operatorId               : "",
        id                  : this.props.id                ? this.props.id                       : "",
        operator            : this.props.name              ? this.props.name                     : "",
        ownerName           : this.props.applicantName     ? this.props.applicantName.trim()     : "",
        contactNo           : this.props.contactNumber     ? this.props.contactNumber.trim()     : "",
        email               : this.props.email             ? this.props.email                    : "",
        vehicleOwnerName    : this.props.vehicleOwnerName  ? this.props.vehicleOwnerName         : "",
        address             : this.props.addressOfOperator ? this.props.addressOfOperator.trim() : "",
        noOfTrucks          : this.props.noOfTrucks        ? this.props.noOfTrucks               : '',
        updatedDate         : this.props.updatedDate       ? this.props.updatedDate              : '',
        name_tn             : this.props.name_tn           ? this.props.name_tn                  : "",
        operatorErr         : '',
        ownerNameErr        : '',
        contactNoErr        : '',
        emailErr            : '',
        vehicleOwnerNameErr : '',
        addressErr          : '',
        usernameErr         : '',
        passwordErr         : '',
        isPasswordShown: false,
        StatusValue         : !!this.props.isDisableOperator? !!this.props.isDisableOperator&&this.props.isDisableOperator  === 'Active' ?false :true:'',
        isStatus      : null,
    }
    }
   
    handleChange = (event) =>{
        this.setState({[event.target.name]:event.target.value}
            , ()=> {
            this.checkValidity()
        }
        )
    }
    handleSubmit = (e) =>{
        e.preventDefault()
        const err = this.checkValidity()
        if(!err)
        {
            let props=this.props
            const formdata= {
                token            : this.props.token,
                id               : this.state.id,
                operator         : this.state.operator,
                name_tn          : this.state.name_tn,
                ownerName        : this.state.ownerName,
                contactNo        : this.state.contactNo,
                email            : this.state.email,
                vehicleOwnerName : this.state.vehicleOwnerName,
                address          : this.state.address,
                noOfTrucks       : this.state.noOfTrucks,
                updatedDate      : this.state.updatedDate,
                username         : this.state.username,
                password         : this.state.password,
                isDisableOperator : this.state.isStatus

            }
            this.props.handleSubmit(formdata,props)
        }
    }
    checkValidity = () => {
        let isError = false
        if(this.state.username && !this.state.password){
            isError = true
            this.setState({
                passwordErr:'*This field is required'
            })
        }else{
            isError = false
            this.setState({
                passwordErr:''
            })  
        }
        if(this.state.password && !this.state.username){
            isError = true
            this.setState({
                usernameErr:'*This field is required'
            })
        }else{
            isError = false
            this.setState({
                usernameErr:''
            })  
        }

        if(this.state.username && this.state.password){
            if(!this.state.password.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/)){
                isError = true
                this.setState({
                    passwordErr:'Password must contain minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character'
                })   
            }else{
                isError = false
                this.setState({
                    passwordErr:''
                })   
            }
        }
        if(!this.state.operator){
            isError=true
            this.setState({operatorErr : '*This field is required'})
        }
        // else if(!!this.state.operator&&this.state.operator.length > 0 && this.state.operator.length <3 ){
        //     isError=true
        //     this.setState({operatorErr : '*Name should be Minimum 3 characters and Maximum 100 characters'})
        // }
        else if(!!this.state.operator && this.state.operator.length>=3){
            isError = false
            this.setState({operatorErr:''})
        }
        else{
            if(!!this.state.operator&&this.state.operator.length > 0 && this.state.operator.length <3 ){
                isError=true
                this.setState({operatorErr : '*Name should be Minimum 3 characters and Maximum 100 characters'})
            }
        }
        if(this.state.ownerName.length > 0 && this.state.ownerName.length <3){
            isError=true
            this.setState({ownerNameErr : '*Name should be Minimum 3 characters and Maximum 100 characters'})
        }
        else{
            this.setState({ownerNameErr:''})
        }
        // if(!this.state.contactNo){
        //     isError=true
        //     this.setState({contactNoErr:'*This field is required'})
        // }
         if(this.state.contactNo){
            if(!this.state.contactNo.match(/^[0-9]{1,10}$/)){
                isError=true
                this.setState({contactNoErr:'*Enter Valid Contact Number'})
            }
            else{
                if(this.state.contactNo.length === 10){
                    isError=false
                    this.setState({contactNoErr:''})
                }
              
            }
     
        }
        // else if(this.state.contactNo && this.state.contactNo.length>=10 && !Number.isNaN(parseInt(this.state.contactNo))) {
        //     this.setState({contactNoErr: ''})
        // }
        if(this.state.email){
            var mailformat= /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
            if(!this.state.email.match(mailformat)){
                isError=true
                this.setState({emailErr:'*You have entered invalid email address'})
            }
            else{
                this.setState({emailErr:''})
            }
        }
        else if(!this.state.email){
            // isError=false
            this.setState({emailErr : ''})
        }
        // if(this.state.password && !this.props.operatorId){
        //     const passwordFormat = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/
        //     if(!this.state.password.match(passwordFormat)){
        //         isError=true
        //         this.setState({passwordErr:'*Password should be between 8 to 20 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character'})
        //     }else{
        //         isError = false
        //         this.setState({passwordErr : ''})
        //     }
        // }
        // if(!this.state.vehicleOwnerName){
        //     isError=true
        //     this.setState({vehicleOwnerNameErr:'*This field is required'})
        // }
        // else if(this.state.vehicleOwnerName.length>0 && this.state.vehicleOwnerName.length<3 ){
        //     isError=true
        //     this.setState({vehicleOwnerNameErr:"*Name should be Minimum 3 characters and Maximum 100 characters"})
        // }
        else {
            this.setState({vehicleOwnerNameErr:''})
        }
        return isError
    }
    toggleVisibility=()=>{
        const{isPasswordShown}=this.state
        this.setState({isPasswordShown:!isPasswordShown})
      }
      handlChangeStatus = (e)=>{
        this.setState({isStatus: e.target.value , StatusValue :  e.target.value})
      }
    render() {
        const operatorId = this.props.operatorId ? this.props.operatorId : ''
        return <div>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                        <FormControl>
                        <label className="required" htmlFor="operator">Operator Name</label>
                            <TextField id="operator" 
                                        variant="outlined" 
                                        placeholder="Enter Business/ Trade Name" 
                                        name="operator" 
                                        value={this.state.operator} 
                                        onChange={this.handleChange}/>
                            <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.operatorErr}</div>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                    
                        <FormControl>
                        <label htmlFor="name_tn">Operator Name (Tamil)</label>
                            <TextField id="name_tn" 
                                        variant="outlined" 
                                        placeholder="வணிக / வர்த்தக பெயரை உள்ளிடவும்" 
                                        name="name_tn" 
                                        value={this.state.name_tn} 
                                        onChange={this.handleChange}/>
                            {/* <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.operatorErr}</div> */}
                    </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                    <FormControl>
                        <label htmlFor="ownerName">Applicant Name</label>
                            <TextField id="ownerName" 
                                        variant="outlined" 
                                        placeholder="Enter Operator Name" 
                                        name="ownerName" 
                                        value={this.state.ownerName} 
                                        onChange={this.handleChange}/>
                            <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.ownerNameErr}</div>
                    </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                    <FormControl>
                        <label htmlFor ="contactNo">Contact Number</label>
                            <TextField id = "contactNo" 
                                        variant="outlined" 
                                        placeholder="Enter Valid Phone Number" 
                                        name="contactNo" 
                                        value={this.state.contactNo} 
                                        onChange={this.handleChange}/>
                            <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.contactNoErr}</div>
                    </FormControl>
                    </Grid> 
                    <Grid item xs={6}>
                    <FormControl>
                        <label htmlFor = "email">Email</label>
                            <TextField id = "email" 
                                        variant="outlined" 
                                        placeholder="Enter Valid Email Address" 
                                        name="email" 
                                        value={this.state.email} 
                                        onChange={this.handleChange}/>
                            <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.emailErr}</div>
                    </FormControl>
                    </Grid> 
                    <Grid item xs={6}>
                    <FormControl>
                        <label htmlFor = "address">Address of Applicant/Operator</label>
                            <TextField id = "address" 
                                        variant="outlined" 
                                        placeholder="Enter Address of Applicant/Operator" 
                                        name="address" 
                                        value={this.state.address} 
                                        onChange={this.handleChange}/>
                            <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.addressErr}</div>
                    </FormControl>
                    </Grid> 
                   {!this.state.operatorId && <Grid item xs={6}>
                    <FormControl>
                        <label htmlFor = "username">Operator User Name</label>
                            <TextField id = "username" 
                                        variant="outlined" 
                                        placeholder="Enter Username" 
                                        name="username" 
                                        value={this.state.username} 
                                        onChange={this.handleChange}/>
                            <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.usernameErr}</div>
                    </FormControl>
                    </Grid>}
                    { !this.state.operatorId && <Grid item xs={6}>
                     <form autocomplete="off">
                      <FormControl>
                      <label className={this.state.username ? "required" : ''} htmlFor = "password">Operator Password</label>
                      
                      <OutlinedInput id = "password" 
                                      variant="outlined" 
                                      placeholder="Enter Password" 
                                      name="password" 
                                      value={this.state.password}
                                      onChange={this.handleChange}
                                      type={this.state.isPasswordShown ? "text" : "password"}
                                      endAdornment={this.state.isPasswordShown ?
                                        (<InputAdornment position="end"><VisibilityOffOutlinedIcon 
                                        onClick={()=>{this.toggleVisibility()}}
                                        /></InputAdornment>) :
                                        (<InputAdornment position="end">
                                            <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={()=>{this.toggleVisibility()}}
                                            >
                                            <img src="images/eye.svg" />
                                        </IconButton> </InputAdornment> )    
                                        }
                                        errortext={this.state.passwordError}    
                                      />
                                     
                          <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.passwordErr}</div>
                  </FormControl>
                  </form>
                    </Grid> }

                    
                    <Grid item xs={6}>
                    <FormControl>
                        <label htmlFor="statusType" >Status</label>
                    <TextField id="select"  name="statusType" value={this.state.StatusValue} onChange = {this.handlChangeStatus} select>
                        <MenuItem value={false}>{'Active'}</MenuItem>
                        <MenuItem value={true}>{'In Active' }</MenuItem>
                    </TextField>
                    </FormControl>
                    </Grid>
                  
                    </Grid>
                  
                 
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                        <div className="create_ulb_buttons">
                        <Link style={{textDecoration:"none", color:"#3dab35"}} to="/operator-management"><Button className="login_submit" variant="outlined" color="primary">Cancel</Button></Link>
                        <Button className="login_submit" variant="contained" color="primary" onClick={this.handleSubmit}>Save</Button>
                    </div>
                        </Grid>
                    </Grid> 
                </div>
    
    }
}

const mapStateToProps = (state,props) => {
    
    return{
        props:props,
        token:state.auth.token
    }
}

const  mapDispatchToProps = dispatch=>{
    return{
        onAddOperatorData :(payload,props)=>dispatch(actionsCreators.addOperatorData(payload,props))
    }
   
}

export default connect(mapStateToProps,  mapDispatchToProps)(OperatorForm)
