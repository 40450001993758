import React, { Component } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Layout from './hoc/Layout/Layout';
import RecordKeeper from './containers/RecordKeeper/loads/RecordKeeper';

import Dashboard from './containers/Dashboard/Dashboard';
import * as actionsCreators from './store/actions/index';

import PublicRoutes from './routes/PublicRoutes';
import PrivateRoutes from './routes/PrivateRoutes';


class App extends Component {
  state = {
    value: "en",
    pages: [
        {
          "path": "/dashboard",
          "name": "Dashboard",
          "icon": "pe-7s-graph",
          "component": Dashboard,
          "layout": "",
          "moduleId": 1,
          "modulePageId": 1,
          "actions": [
            {
              "actionId": 1,
              "actionUrl": "edit",
              "action": "Edit",
              "roleIsActive": true
            },
            {
              "actionId": 2,
              "actionUrl": "delete",
              "action": "Delete",
              "roleIsActive": true
            }
          ]
        },
        {
          "path": "/",
          "name": "Loads",
          "icon": "pe-7s-graph",
          "component": RecordKeeper,
          "layout": "",
          "moduleId": 2,
          "modulePageId": 2,
          "actions": [
            {
              "actionId": 1,
              "actionUrl": "edit",
              "action": "Edit",
              "roleIsActive": true
            },
            {
              "actionId": 2,
              "actionUrl": "delete",
              "action": "Delete",
              "roleIsActive": true
            }
          ]
        },
    ]
  };

  handleChange = event => {
    let newlang = event.target.value;
    this.setState(prevState => ({ value: newlang }));
    this.props.i18n.changeLanguage(newlang);
  };

  componentDidMount () {
    this.props.onTrySignup();
  }

  render() {
    const { t, i18n } = this.props;

    let routes ;

    if (this.props.isAuthenticated) {
      routes = (
        <Layout>
          <Switch >
            <Route path="" component={PrivateRoutes}/>
          </Switch>
        </Layout>
      )
    }
    else {
       routes = (
        <Switch>
					<Route path="" component={PublicRoutes}/>
        </Switch>
      )
    }

    return (
      <div className="App">
        {/* <div className="mb-3">
              <Form.Check
                inline
                value="en"
                label="english"
                type="radio"
                id="eng"
                name="lang"
                onChange={this.handleChange}
              />
              <Form.Check
                inline
                value="hin"
                label="hindi"
                type="radio"
                id="hin"
                name="lang"
                onChange={this.handleChange}
              />
        </div>
        <h1>{t("Login")} </h1>  */}
        {routes}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token != null
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onTrySignup: () => dispatch(actionsCreators.authCheckState())
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(App)));
