import React, { Component } from 'react'
import { connect } from 'react-redux'
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import ImageUploader from 'react-images-upload';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { DatePicker } from "@material-ui/pickers";
import moment from 'moment'
import * as actionCreators from '../../store/actions/index'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const docTypes = [
    {id:1, name:'RC Book'},
    {id:2, name:'Fitness'},
    {id:3, name:'Insurance'},
    {id:4, name:'MV Tax'},
    {id:5, name:'Goods Vehicle Permit'},
    {id:6, name:'Others'}
]
const validityOptions=[
    {
        "key":true,
        "name":"Yes"
    },
    {
        "key":false,
        "name":"No"
    }
]
const truckDocHeaders = [
    {header:'Sr No', accessor:'id'},
    {header:'Document Type', accessor:'documentTypeId'},
    {header:'Document', accessor:'documentPath'},
    {header:'Has Validity?', accessor:'hasValidity?'},
    {header:'Validity', accessor:'validity'},
]

export class TruckDocs extends Component {
    constructor(props){
        super(props)
        if(this.props.location.state){
            if(localStorage.getItem('truckRegNo') == this.props.location.state.truckRegNo){
            }
            else if(localStorage.getItem('truckRegNo') != this.props.location.state.truckRegNo){
                localStorage.setItem('truckRegNo', this.props.location.state.truckRegNo)
            }
        }
       
        else if(!localStorage.getItem('truckRegNo')){
            localStorage.setItem('truckRegNo', this.props.location.state.truckRegNo)
        }
    }
    state = {
        docTypeId : '',
        img:'',
        imgName:'',
        validityDate : moment(),
        dialogState : false,
        addNew : false,
        open : false,
        isDisabled:false,
        docTypeIderr:'',
        imgErr:'',
        hasValidity:false
    }
    componentDidMount = () => {
        const payload = {
            token : this.props.token,
        }
        this.props.onFetchTruckDocsData(payload, this.props)
    }
    handleChange = (e) => {
        this.setState({[e.target.name]:e.target.value}, ()=> {this.checkValidity()})
    }
    handleImage = (e) => {
        this.setState({img:e[0]}, ()=> this.checkValidity())
    }
    handleButton = (e) => {
        this.setState({dialogState:true})
    }
    handleDate = ( date ) => {
        this.setState({validityDate:date})
    }
    handleNew = () => {
        this.setState({addNew:true,isDisabled:true})
    }
    handleSave = () => {
        const err = this.checkValidity()
        if(!err){
            const token = this.props.token
            const validity = this.state.hasValidity? moment(this.state.validityDate).format('DD-MMM-YYYY 23:59:59') : null
            const formdata = new FormData()
            formdata.append(
                "DocumentPath",
                this.state.img,
                this.state.img["name"]
            )
            formdata.append(
                "DocTypeId",
                this.state.docTypeId,
            )
            formdata.append(
                "TruckId",
                Number(this.props.match.params.id),
            )
            formdata.append(
                "Validity",
                validity
            )
            this.props.onAddTruckDocsData(formdata, token, this.props)
            this.setState({docTypeId : '', img:'',  imgName:'',  validityDate : moment(), addNew : false,isDisabled:false})
        }
    }
    handleDialog = () => {
        this.setState({open : true})
    }
    handleClose = () => {
        this.setState({open : false})
    }
    checkValidity(){
        let isError=false;
        if(!this.state.docTypeId ){
            isError=true
            this.setState({docTypeIderr:'*required'})
        }
        else if(this.state.docTypeId){
            this.setState({docTypeIderr:''})
        }
        if(!this.state.img){
            isError=true
            this.setState({imgErr:'*required'})
        }
        else if(this.state.img){
            this.setState({imgErr:''})
        }
        return isError  
    }
    render() {
        const tableHeaders = truckDocHeaders.map(header => {
            return <TableCell>{header.header}</TableCell>
        })
        const tableData = this.props.truckDocs.map(data => {
            return <TableRow>
            {truckDocHeaders.map(header => {
                if(header.accessor == 'documentPath'){
                    return <TableCell>
                                <a className="email_link" href={data[header.accessor]} target="_blank">View Document</a>
                                {/* <img src={data[header.accessor]} onClick = {this.handleDialog} style={{height:'1%'}}/> */}
                                {/* <Dialog autoDetectWindowHeight={true}
                                                    modal={false}
                                                    open={this.state.open}
                                                    aria-labelledby="simple-dialog-title"
                                                    InputProps = {{style : {position:'none !important'}}}><div style ={{width:'300px'}}></div> */}
                                    {/* <DialogTitle id="simple-dialog-title" align="center" style={{fontSize:'12px'}}> */}
                                        {/* <IconButton style={{
                                            // position:'relative',
                                                                            left: 130,
                                                                            right: 1,
                                                                            top: -8,
                                                                            bottom: 0
                                                                        }}  >
                                            <CloseOutlinedIcon fontSize='small' align='right'onClick={this.handleClose}/>
                                        </IconButton> */}
                                    {/* </DialogTitle> */}
                                    {/* <img src={data[header.accessor]} style={{height: '400px'}}/> */}
                                {/* </Dialog> */}
                            </TableCell>
                }
                else if(header.accessor == 'documentTypeId'){
                    const docType = docTypes.find(docType => docType.id == data[header.accessor])
                    return <TableCell>{docType.name}</TableCell>
                }
                else if(header.accessor == 'validity'){
                    if(!data["validity"]){
                        return <TableCell>N/A</TableCell>
                    }
                    else{
                        return <TableCell>{moment(data[header.accessor]).format('DD-MMM-YYYY')}</TableCell>
                    }
                }
                else if(header.accessor == 'hasValidity?'){
                    if(!data["validity"]){
                        return <TableCell>No</TableCell>
                    }
                    else{
                        return <TableCell>Yes</TableCell>
                    }
                }
                else{
                    return <TableCell>{data[header.accessor]}</TableCell>
                }
            })}</TableRow>
        })
        return (
            <div>
                <Paper elevation={0}>
                    <div className="dashboard_right_content">
                        <img className="dashboard_right_content_top_icon" src="images/Rectangle.svg" />
                        <div className="dashboard_right_content_top">
                            <h5><Link to={`/truck-management/${this.props.match.params.operatorId}`} className="email_link"><ArrowBackIosIcon></ArrowBackIosIcon></Link>Truck Documents</h5>
                            <div className="dashboard_right_content_top_options">
                                <Button  id="addNewRecord" variant="outlined" color="primary" onClick = {this.handleNew} disabled= {this.state.isDisabled}>Add New Document</Button>
                                <Button  className="email_link" onClick={this.handleSave}>Save</Button> 
                            </div>
                        </div>
                    </div>
                    <div className="truckdoc_listing" >
                    <div style={{clear: 'both'}}><h5 align='left' style={{paddingLeft:'2%',float:'left'}}>Truck Registration Number :</h5><h5 align='left' style={{color:'#3DAB35', paddingLeft:'1%',float:'left'}}>{localStorage.truckRegNo &&  localStorage.truckRegNo}</h5></div>
                            <TableContainer component={Paper} style={{overflow:"hidden" ,paddingLeft:"15px", paddingRight:"15px", maxHeight:690}}>
                            <Table stickyHeader aria-label="sticky table" style={{ tableLayout: 'fixed' }}>
                            <colgroup>
+                                <col style={{width:'10%'}}/>
+                                <col style={{width:'23%'}}/>
+                                <col style={{width:'23%'}}/>
+                                <col style={{width:'21%'}}/>
+                                <col style={{width:'23%'}}/>
+                            </colgroup>
                            <TableHead >
                                <TableRow >
                                        {tableHeaders}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {this.state.addNew && 
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell align = "left">
                                        <FormControl variant="outlined" size="small">
                                        <Select value={this.state.docTypeId} 
                                                onChange={this.handleChange} 
                                                name="docTypeId" 
                                                size="small" 
                                                style={{fontSize:12,fontFamily:'Open Sans sans-serif !important',minWidth:'100px',marginTop:'14px'}}
                                                >
                                                {docTypes.map((option) => (
                                                            <MenuItem style={{fontSize:12,fontFamily:'Open Sans sans-serif !important',width:'100%'}} 
                                                                    value={option.id}>
                                                                    {option.name}
                                                            </MenuItem>
                                                        ))}
                                        </Select>
                                        { this.state.docTypeIderr ? 
                                                   <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"5%",lineHeight:'11px'}}>{this.state.docTypeIderr}</div> 
                                                   : 
                                                   <div style={{fontSize:12,color:"white", fontFamily:"'Open Sans', sans-serif", marginTop:"5%",lineHeight:'11px'}}>required</div>
                                            }
                                        </FormControl>
                                    </TableCell>

                                    <TableCell>
                                        {/* <img src={this.state.img}/> */}
                                        <FormControl variant="outlined" size="small">
                                            {
                                                !this.state.docTypeId? <Button className="imgButtonDisable" disabled = {true} style={{width:'100%', height:'35.25px',fontSize:'12px',padding:'0px',marginTop:'0px',marginBottom:'3%'}}>Upload</Button> : 
                                                <div>
                                                    <ImageUploader withIcon={false}
                                                        singleImage={true} 
                                                        fileContainerStyle = {{color:'#3dab35'}} 
                                                        // withPreview ={true}
                                                        buttonText='Upload'
                                                        buttonStyles={{backgroundColor:'white', color:'#3dab35',fontSize:'12px', borderRadius:'5px',height:'35px',	width:'100%',padding:'0px',marginTop:'14px',marginBottom:'0%'}}
                                                        withLabel={false}
                                                        onChange={this.handleImage}
                                                        imgExtension={['.jpg', '.gif', '.png', '.gif', '.pdf', '.xlsx', '.jpeg']}
                                                        fileSizeError=" file size is too big"
                                                        fileTypeError=" is not supported file extension"
                                                        maxFileSize={5242880}
                                                        buttonClassName="imgButtonEnable"
                                                        />
                                            { this.state.imgErr ? 
                                                   <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"5%",lineHeight:'11px'}}>{this.state.imgErr}</div> 
                                                   : 
                                                   <div style={{fontSize:12,fontFamily:"'Open Sans', sans-serif", marginTop:"3%",lineHeight:'11px'}}>{this.state.img && this.state.img['name']}</div>
                                            }
                                                </div>
                                            }
                                            {/* <Dialog autoDetectWindowHeight={true}
                                                    modal={false}
                                                    open={this.state.dialogState}
                                                    aria-labelledby="simple-dialog-title"><div style ={{width:'300px'}}> */}


                                                {/* <DialogTitle id="simple-dialog-title" align="center" style={{fontSize:'12px'}}> */}
                                                    {/* <IconButton style={{position:'relative',
                                                                        // left: 130,
                                                                        // right: 0,
                                                                        // top: -9,
                                                                        // bottom: 0
                                                                    }}  >
                                                        <CloseOutlinedIcon fontSize='small'position='relative' align='right'onClick={this.handleClose}/>
                                                    </IconButton> */}
                                                {/* </DialogTitle> */}
                                                {/* <ImageUploader withIcon={false}
                                                        singleImage={true} 
                                                        fileContainerStyle = {{color:'#3dab35'}} 
                                                        buttonText='Upload'
                                                        buttonStyles={{backgroundColor:'#3dab35', position:'relative', bottom:10}}
                                                        withPreview ={true}
                                                        withLabel={false}
                                                        onChange={this.handleImage}
                                                        imgExtension={['.jpg', '.gif', '.png', '.gif']}
                                                        fileSizeError=" file size is too big"
                                                        fileTypeError=" is not supported file extension"
                                                        maxFileSize={5242880}/> */}
                                            {/* </div> */}
                                            {/* </Dialog> */}

                                            
                                        </FormControl>
                                    </TableCell>
                                                        
                                    <TableCell>
                                        <FormControl variant="outlined" size="small">
                                            <Select value = {this.state.hasValidity}
                                                    onChange = {this.handleChange}
                                                    name="hasValidity"
                                                    size="small" 
                                                    style={{fontSize:12,minWidth:'100px',marginTop:'14px'}}>
                                                {validityOptions.map(option => {
                                                    return <MenuItem key = {option.key} value = {option.key}>{option.name}</MenuItem>
                                                })}
                                            </Select>
                                            <div style={{fontSize:12,color:"white", fontFamily:"'Open Sans', sans-serif", marginTop:"5%",lineHeight:'11px'}}>required</div>
                                        </FormControl>
                                          
                                    </TableCell>

                                    <TableCell>
                                        <FormControl variant="outlined" size="small">
                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <DatePicker disablePast={true}
                                                            format='DD/MM/YYYY'
                                                            variant="inline"
                                                            autoOk={true}
                                                            disabled = {this.state.hasValidity? false : true}
                                                            value={this.state.validityDate}
                                                            onChange={this.handleDate}
                                                            style={{marginTop:'14px'}}/>
                                            </MuiPickersUtilsProvider>
                                            <div style={{fontSize:12,color:"white", fontFamily:"'Open Sans', sans-serif", marginTop:"5%",lineHeight:'11px'}}>required</div>
                                        </FormControl>
                                    </TableCell>
                            </TableRow>}
                            {tableData}
                        </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                </Paper>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return ({
        token : state.auth.token,
        truckDocs : state.truckDocs.truckDocs
    })
    
}

const mapDispatchToProps = (dispatch) => {
    return({
        onFetchTruckDocsData : (payload, props) => dispatch(actionCreators.fetchTruckDocData(payload, props)),
        onAddTruckDocsData : (payload, token, props) => dispatch(actionCreators.addTruckDocData(payload, token, props))
    }) 
}

export default connect(mapStateToProps, mapDispatchToProps)(TruckDocs)
