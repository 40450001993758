import React, { Component } from 'react';
import { connect } from 'react-redux';
import Aux from '../Aux/Aux';
import './Layout.css';
import '../../App.css';
import Navigationbar from '../../components/Navigation/Navigationbar/Navigationbar';
import axios from '../../http-config'
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { withStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';


const drawerWidth = '50%';
const useStyles = ((theme) => ({
	root: {
		display: 'flex',
	},
	appBar: {
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		backgroundColor: '#FFFFFF'
	},
	appBarShift: {
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	menuButton: {
		color: '#3dab35 !important',
		fontWeight: 'bold'
	},
	hide: {
		display: 'none',
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		//   width: drawerWidth,
	},
	drawerHeader: {
		alignItems: 'center',
		padding: theme.spacing(0, 1),
		...theme.mixins.toolbar,
		justifyContent: 'flex-end',
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
		paddingLeft: '10px',
		paddingRight: '10px',
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),

	},
	contentShift: {
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginLeft: 0,
	},
}));

class Layout extends Component {
	constructor(props) {
		super(props)
		this.state = {
			role: "",
			open: false,
		}
	}

	componentDidMount() {
		const roleId = localStorage.getItem('roles')
		const payload = {
			token: this.props.token,
		}
		const url = `/role/${roleId}`
		axios({
			method: 'get',
			url: url,
			headers: { 'Authorization': `Bearer ${payload.token}` }
		})
			.then((response) => {
				this.setState({ role: response.data.data[0].name == "State Dashboard" ? "State/Regional Officer" : response.data.data[0].name })				
			})
			.catch((err) => {

			})
	}
	handleDrawerOpen = () => {
		this.setState({ open: true })
	};
	handleDrawerClose = () => {
		this.setState({ open: false })
	}

	render() {
		const username = localStorage.getItem('username')
		const { classes } = this.props
		return (
			<Aux>
			{window.innerWidth <=480 ? 
			<div className="dipalyApp">
				<CssBaseline />
				<AppBar
					position="fixed"
					className={clsx(classes.appBar, {
						[classes.appBarShift]: this.state.open,
					})}>
					<ClickAwayListener onClickAway={this.handleDrawerClose}>
					<Toolbar>
						<IconButton
							aria-label="open drawer"
							onClick={this.handleDrawerOpen}
							edge="start"
							className={clsx(classes.menuButton, this.state.open && classes.hide)}>
							<MenuIcon style={{ width: "25px", height: '25px' }} />
						</IconButton>
						<div className="dashboard_header">
							<img src="images/dashboard_logo.svg" alt="dashboard logo" />
							<div className="dashboard_header_options">
								<p style={{ fontSize: "medium", color: "#3DAB35" }}>{username}</p>
							</div>
						</div>
					</Toolbar>
					</ClickAwayListener>
				</AppBar>
				<Drawer
					className={classes.drawer}
					variant="persistent"
					anchor="left"
					open={this.state.open}
					classes={{
						paper: classes.drawerPaper,
					}}>
					<div>
						<IconButton onClick={this.handleDrawerClose} className="iconbuttong">
							{'ltr' === 'ltr' ? <ChevronLeftIcon style={{ width: "25px", height: '25px', color: 'green' }} /> : <ChevronRightIcon style={{ width: "25px", color: 'green', height: '25px' }} />}
						</IconButton>
					</div>
				
					<Navigationbar functionhandle={this.handleDrawerClose} openState={this.state.open} />
				</Drawer>
				<main
					className={clsx(classes.content, {
						[classes.contentShift]: this.state.open,
					})}>
					<div >
						{this.props.children}
					</div>
				</main>
			</div> :
			<div className="dashboard_page">
				<div className="dashboard_header">
					<img src="images/dashboard_logo.svg" alt="dashboard logo" />
					<div className="dashboard_header_options">
						<p style={{ fontSize: "medium", color: "#3DAB35" }}>{username}</p>
						<p style={{ fontSize: "small" }}>{this.state.role}</p>
					</div>
				</div>
				<div className="dashboard_content">
					<Navigationbar />
					{/* <Navigationbar {...this.props.children["_self"].props}/> */}
					<div className="dashboard_right">
						<main className="Content">
							{this.props.children}
						</main>
					</div>
				</div>
			</div>
		}
		<div className="dashboard_footer">
			<p>Powered By Transerve Technologies Private Limited</p>
				<p>&copy;{ new Date().getFullYear()} FSSM. All&nbsp;&nbsp;rights reserved.</p>
			{/* <p>&copy; 2020 FSSM. All rights reserved. Cookie Preferences, Privacy and Terms.</p> */}
			</div>
		</Aux>
		)
	}
}

const mapStateToProps = state => {
	return {
		token: state.auth.token,
		isAuthenticated: state.auth.token != null
	}
}
export default connect(mapStateToProps)(withStyles(useStyles)(Layout));