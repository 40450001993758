import React from 'react';
import { connect } from 'react-redux';
import '../../App.css'
import * as actionsCreators from '../../store/actions/index'
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import axios from '../../http-config';
import _ from 'lodash'
import OperatorForm from './OperatorForm'

function OperatorAdd (props) {
    
    const handleSubmit = (formdata)=>{
        props.onAddOperatorData(formdata,props)
    }

        return(
            <div className="create_ulb">
            <div className="dashboard_top_path">
                <p><img src="images/left-arrow.svg" /> Operator Management / <span>Create New Operator</span></p>
            </div>
            <div className="dashboard_right_content">
                <img className="dashboard_right_content_top_icon" src="images/Rectangle.svg" />
                <div className="dashboard_right_content_top">
                    <h5>Create New Operator</h5>
                </div>
                <div className="create_ulb_content_outer">
                    <div className="create_ulb_content">
                        
                       <OperatorForm handleSubmit={handleSubmit}/>
                       
                    </div>
                </div>
            </div>
        </div>
        )
}



const mapStateToProps = (state,props) => {
return {
}
}

const mapDispatchToProps = dispatch => {
return {
    onAddOperatorData :(payload,props)=>dispatch(actionsCreators.addOperatorData(payload,props))
}
}

export default  connect(mapStateToProps, mapDispatchToProps)(OperatorAdd);
