import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import * as actionCreators from '../../store/actions/index'
import moment from 'moment'
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import swal from 'sweetalert'
import TextField from '@material-ui/core/TextField';
import ReactPaginate from 'react-paginate';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
const headers = [
    {id : 1, header : 'Sr No', accessor : 'id'},
    {id : 2, header : 'Role', accessor : 'name'},
    {id: 3,  header: "Access Mode",accessor: "accessMode"},
    {id: 4,  header: "Access Modules", accessor: "accessModules"},
    {id : 5, header : 'Updated Date', accessor : 'updatedAt'},
    {id : 6, header : 'Action', accessor : 'actionButton'},
  
]
const gpsInstalledOptions=[
    {
        "key"    : 'web',
        "name"   : "Web"
    },
    {
        "key"    : 'App',
        "name"   : "App"
    },
    {
        "key"    : 'both',
        "name"   : "Both"
    }

]
export class RoleListing extends Component {
    state = {
        anchorEl                  : null,
        open                      : false,
        id                        : '',
        addNew                    : false,
        addDisabled               : false,
        name                      : '',
        nameErr                   : '',
        editRecord                : false,
        open_onedit               : false,
        anchorEl_onedit           : null,
        isDefault                 : '',
        page                      : 1,
        // activePage                : 0,
        limit                     : 10,
        currentSortId:'',
        sortObj : {
            key: '',
            value: ''
        },
        open:false,
        accessSite:'',
        webAc:'',
        appAc:'',
        app: false,
        web:false,
        webAces :"",
        mobile:'',
        filterValue:''
    }
    componentDidMount(){
        const payload = {
            token : this.props.token,
            page:this.state.page,
            limit:this.state.limit,
            sortName:this.state.sortObj.key,
            sortValue : this.state.sortObj.value,
        }
        this.props.onFetchRole(payload)
    }

    //function to handle pagination
    handlePageClick = (e) => {
        this.setState({
            page:e.selected + 1,
            // activePage : e.selected
        },() => {
            let payload = {
                token: this.props.token,
                page:  e.selected + 1,
                limit:this.state.limit,
                sortName: this.state.sortObj.key,
                sortValue : this.state.sortObj.value,
            }
            this.props.onFetchRole(payload)
        }) 
    }; 

    handleClickMoreVertIcon = (event, id,name,isDefault, mobile, webAces) => {
        this.setState({open : !this.state.open, anchorEl : event.currentTarget, id : id,name, isDefault, mobile, webAces})
    }
    handleClickMoreVertIconOnEdit=(event,id)=>{
        this.setState({open_onedit:!this.state.open_onedit,anchorEl_onedit:event.currentTarget})
    }
    handleClickAwayEdit = () => {
        this.setState({open : false, accessSite:''});
    };
    handleDelete = (id) => {
        const payload = {
            id : id
        }
        let payload2 ={
            token : this.props.token,
            page:this.state.page,
            limit:this.state.limit,
            sortName: this.state.sortObj.key,
            sortValue :this.state.sortObj.value,
        }
        this.props.onDeleteRole(payload, payload2)
        this.setState({name:'',  accessSite:''})
     
        
    }
    handleAddNew = () => {
        
        this.checkValidity()
        this.setState({addNew : true, addDisabled : true, editRecord:false,name:''})
    }
    handleChange = (e) => {
        if(e.target.name === 'accessSite'){
            if(e.target.value == 'both'){
                this.setState({app: true, web: true})
            }
            if(e.target.value == 'App'){
                this.setState({app: true, web: false})
            }
            if(e.target.value == 'web'){
                this.setState({web: true, app:false})
            }
        }
        this.setState({[e.target.name] : e.target.value}, ()=> {
            this.checkValidity()
        })
    }
    checkValidity(){
        let isError = false
        if(!this.state.name){
            isError=true
            this.setState({nameErr:'*required'})
        }
        else if(this.state.name && this.state.name.trim().length < 5 || this.state.name.trim().length > 25){
            isError=true
            this.setState({nameErr : '*between 5 to 25'})
        }
        else if(this.state.name && this.state.name.trim().length >= 5 || this.state.name.trim().length <= 25){
            this.setState({nameErr:''})
        }
        return isError
    }
    handleSave = (e) => {
        e.preventDefault()
        let err = this.checkValidity()
        if(!err){
            const payload = {
                name  : this.state.name.trim(),
                "roleAccess":{
                    "IsMobileAppAccess":this.state.app,
                    "IsWebAppAccess":this.state.web
                }
            }
            const payload2 = {
                token : this.props.token,
                page:this.state.page,
                limit:this.state.limit,
                sortName: this.state.sortObj.key,
                sortValue :this.state.sortObj.value,
            }
            this.props.onAddRole(payload, payload2)
            this.setState({addNew: false, addDisabled: false, name : '', editRecord:false, accessSite:''})
        }
    }
    handleEdit = (id,name) => {
        this.setState({editRecord : true, addDisabled:true}, () => this.checkValidity())
    }
    handleCancel = () => {
        this.setState({editRecord : false, name:'', addDisabled:false,accessSite:''})
    }
    handleEditRecord = (id) => {
        let err = this.checkValidity()
        if(!err){
            const payload = {
                name  : this.state.name.trim(),
                id    : this.state.id,
                "roleAccess":{
                    "IsMobileAppAccess":this.state.app,
                    "IsWebAppAccess":this.state.web
                }
               
            }
            const payload2 = {
                token : this.props.token,
                page:this.state.page,
                limit:this.state.limit,
                sortName: this.state.sortObj.key,
                sortValue :this.state.sortObj.value,
            }
            this.props.onEditRole(payload,payload2)
            this.setState({editRecord: false,name : '', addDisabled:false, accessSite:''})
        }
    }
    handleAddCancel = () => {
        this.setState({addNew : false, addDisabled : false, name :"", accessSite:''})
    }


    handleSort = (e,sortBy) => {
        let sortObj
        if (this.state.sortObj.key === sortBy.accessor) {
            let value = (this.state.sortObj.value === 'desc') ? 'asc' : 'desc';
            sortObj = {
                key : sortBy.accessor,
                value: value
            }
        } 
        else {
            sortObj = {
                key : sortBy.accessor,
                value: 'desc'
         }    
         }
 
        this.setState({ sortObj: sortObj ,currentSortId:sortBy.accessor});
        let payload = {
            token: this.props.token,
            sortName:sortObj.key,
         sortValue : sortObj.value,
         page: this.state.page,
         limit: this.state.limit
        }

        this.props.onFetchRole(payload);
  
      }

      onClickGetFilterVals = (e, colObj) => {
        let payload = {
            token : this.props.token,
            page:this.state.page,
            limit:this.state.limit,
            sortName:this.state.sortObj.key,
            sortValue : this.state.sortObj.value,
            filterValue:e
        }
        this.setState({filterValue: e,})
        this.props.onFetchRole(payload)

       }  

       handleExport = () => {
        const payload = {
            token : this.props.token,
            page:this.state.page,
            limit:this.state.limit,
            sortName:this.state.sortObj.key,
            sortValue : this.state.sortObj.value,
        }
        this.props.OnFetchRoleListingExport(payload)
        }
        onClickRemoveFilter = ()=>{
            const payload = {
                token : this.props.token,
                page:1,
                limit:this.state.limit,
                sortName:'',
                sortValue : '',
            }
            this.setState({
                filterValue:'', 
                sortObj:{
                    key: '',
                    value: ''
            } })
            this.props.onFetchRole(payload)
        }
        
    render() {
        
        let  roleList = this.props.roleData
        let filterValue;
        const roleHeaders = headers.map((header) => {
            let filterType;
            if( header.accessor == 'name'){
                header.filter = true
            }
            if(header.filter == true && header.accessor == 'name'){
                filterType = 
                <select 
                style={{
                    width: '16px',
                    borderColor:"white",
                    backgroundColor: 'LightGray',
                    outline:"none",
                    display: "flex",
                    alignItems: 'right',   
                  }}
                value={filterValue}
                onChange={(e) => {
                    this.onClickGetFilterVals(e.target.value , header)
                }}
                >
                    <option value="">&nbsp;All&nbsp;</option>
                   
                   { !!this.props.roleData&&this.props.roleData.map(data => {
                          if(data.name == 'Admin'){
                            return ''
                        }
                       return <option  value={data.roleId}>{data.name}</option>
                   })}

                    {/* <option  value={"admin"}>Admin</option>
                    <option  value={"ULBAdmin"}>ULB Admin </option>
                    <option value={"StateDashboard"}>State/Regional Officer</option>
                    <option value={"scheduler"}>Scheduler</option>
                    <option value={"operator"}>Operator</option>
                    <option value={"TFAdmin"}>TF Admin</option> */}
                
                </select>
            }





            return <TableCell key ={header.id}>
                <div style={{
                        display: 'flex',
                        alignItems: 'start',
                        whiteSpace: 'break-spaces',
                        height: '3em'
                    }}>
                {header.accessor == 'accessModules'||header.accessor == 'accessMode'||header.header === "Action"? "":
                    <div onClick={(e) =>  this.handleSort(e,header)}>
                        <div>{
                            this.state.currentSortId===header.accessor? this.state.sortObj.value ==='asc'?(<ArrowDropDownIcon fontSize="small"/>):(<ArrowDropUpIcon fontSize="small"/>) :(<ArrowDropDownIcon fontSize="small"/>)
                        }
                        </div>  
                    </div>}
                        {header.header}
                        {filterType}
                    </div>
                </TableCell>
        })
        const roleData = !!this.props.roleData&&this.props.roleData.map(data => {
            const id = data['roleId']
            const name = data['name']
            const isDefault = data['isDefault']
            const webAces = data.isRoleAccess !== null? data.isRoleAccess.IsWebAppAccess: ''
            const mobile = data.isRoleAccess !== null? data.isRoleAccess.IsMobileAppAccess:''
            const modules = !!data.isRoleDefaultAccess ? data.isRoleDefaultAccess: ''
            return(
                <TableRow key={data.Id}>
                    {headers.map((header) => {
                        
                        if(header.accessor == 'accessMode'){

                            if(this.state.editRecord && data["roleId"] == this.state.id && header.accessor == 'accessMode'){

                                return <TableCell align = "left">
                                <FormControl variant="outlined" size="small">
                                    <Select   
                                    value={this.state.accessSite}
                                            onChange={this.handleChange} 
                                            name="accessSite" 
                                            size="small" 
                                            style={{fontSize:12,width:'100%'}}>
                                            {gpsInstalledOptions.map((option) => (
                                                <MenuItem key={option.id}
                                                          style={{fontSize:12,width:'100%'}} 
                                                          value={option.key}>
                                                          {option.name}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                  

                                 </FormControl>
                                </TableCell>
                            }


                            else{

                                if(data.isRoleAccess !== null){
                                    if(data.isRoleAccess.IsWebAppAccess == false && data.isRoleAccess.IsMobileAppAccess == false){
                                        return <TableCell key={header.id} style={{
                                            whiteSpace: "normal",
                                            wordWrap: "break-word"
                                        }}>
                                            
                                        </TableCell>
                                    }
                                else if(data.isRoleAccess.IsWebAppAccess == true && data.isRoleAccess.IsMobileAppAccess == true){
                                        return <TableCell key={header.id} style={{
                                            whiteSpace: "normal",
                                            wordWrap: "break-word"
                                        }}>
                                            Web | App
                                        </TableCell>
                                    }
                                else  if(data.isRoleAccess.IsMobileAppAccess == true){
                                        return <TableCell key={header.id} style={{
                                            whiteSpace: "normal",
                                            wordWrap: "break-word"
                                        }}>
                                            App
                                        </TableCell>
                                    }
                                else if(data.isRoleAccess.IsWebAppAccess == true){
                                        return <TableCell key={header.id} style={{
                                            whiteSpace: "normal",
                                            wordWrap: "break-word"
                                        }}>
                                            web
                                        </TableCell>
                                    }
                                }


                                else{
                                    return <TableCell key={header.id} style={{
                                        whiteSpace: "normal",
                                        wordWrap: "break-word"
                                    }}>
                                        
                                    </TableCell>
                                }

                            }


                    
                        }
                       else if(header.accessor == 'accessModules'){
                         return <TableCell key={header.id} style={{
                            
                            whiteSpace: "normal",
                            wordWrap: "break-word"
                            
                          }}>
                              <Link to={{pathname:`/role-management/${id}`, id:id, mobileAcess:mobile, webAccess: webAces, roleModule: name, module: modules }}  className='email_link'>  
                             view
                        </Link>
                       
                            </TableCell>
                        }
                       else if(header.accessor == 'updatedAt'){
                            return <TableCell key={header.id} style={{
                                whiteSpace: "normal",
                                wordWrap: "break-word"
                              }}>{moment(data['updatedAt']).format('DD-MMM-YYYY hh:mm a')}</TableCell>
                        }
                        else if(header.accessor == 'actionButton'){
                            if(this.state.editRecord && data["roleId"] == this.state.id){
                               
                                return  <TableCell align = "left">  
                                <IconButton
                                    aria-label="more"
                                    aria-controls="long-menu"
                                    aria-haspopup="true"
                                    onClick={(e)=>this.handleClickMoreVertIconOnEdit(e)}
                                    >
                                    <MoreVertIcon />
                                    </IconButton>
                                        <Popper
                                        open={this.state.open_onedit} anchorEl={this.state.anchorEl_onedit}>
                                            <ClickAwayListener >
                                        <Paper>
                                            <MenuList>
                                            <MenuItem style={{color:'#3DAB35'}} onClick={()=>this.handleCancel()}>Cancel</MenuItem>
                                                <MenuItem style={{color:'#3DAB35'}}  onClick={()=>this.handleEditRecord(this.state.id)}>Save</MenuItem>
                                            </MenuList>
                                        </Paper>
                                        </ClickAwayListener>
                                    </Popper>
                            </TableCell>
                            }
                            else{
                              
                            return <TableCell key={header.id}>
                                        <IconButton onClick={(e)=>this.handleClickMoreVertIcon(e,id,name,isDefault, mobile,webAces)}
                                                    aria-label="more"
                                                    aria-controls="long-menu"
                                                    aria-haspopup="true"
                                                    disabled={this.state.addDisabled}>
                                            <MoreVertIcon fontSize='small'/>  
                                        </IconButton>
                                        <Popper open={this.state.open} anchorEl={this.state.anchorEl}>
                                        <ClickAwayListener onClickAway={this.handleClickAwayEdit}>
                                            <Paper>
                                                <MenuList>
                                                    <Link className='email_link'>
                                                        <MenuItem onClick={() => this.handleEdit(this.state.id, this.state.name)}>Edit</MenuItem>
                                                    </Link>
                                                {!this.state.isDefault && 
                                                    <MenuItem style = {{color: '#3dab35'}} onClick={() => {
                                                             swal({ title : "Are you sure?",
                                                                    text : "Once deleted, you will not be able to recover this data!",
                                                                    icon : "warning",
                                                                    buttons : {
                                                                    cancel : 'No',
                                                                    confirm: 'Yes'}
                                                            })
                                                            .then((willDelete) => {
                                                                if (willDelete) {
                                                                    this.handleDelete(this.state.id);
                                                                } 
                                                            });
                                                        }}>Delete</MenuItem>}
                                                </MenuList>
                                            </Paper>
                                        </ClickAwayListener>
                                    </Popper>
                            </TableCell>}
                        }
                        else{
                            if(this.state.editRecord && data["roleId"] == this.state.id && header.accessor == 'name'){
                                return <TableCell  style={{
                                    whiteSpace: "normal",
                                    wordWrap: "break-word",
                                    paddingTop:'20px'
                                  }}>
                                    <TextField  type="text"
                                                        id="name"
                                                        variant="outlined"
                                                        size = "small"
                                                        placeholder = "Role Name"
                                                        value={this.state['name']}
                                                        name="name"
                                                        onChange={this.handleChange}
                                                        InputProps={{ style: { fontSize: 12 } }}/>
                                            {this.state.nameErr ? <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.nameErr}</div> :  <div style={{fontSize:12,color:"white", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>required</div>}
                                </TableCell>
                            }
                            else{
                                return <TableCell  style={{
                                    whiteSpace: "normal",
                                    wordWrap: "break-word"
                                  }} key={header.id}>{data[header.accessor]}</TableCell>
                            }
                        }
                    })}
                </TableRow>
            )
        })
        return (
            <div>
                <div className="dashboard_right_content" style={{marginTop:"-4%"}}> 
                    <img className="dashboard_right_content_top_icon" src="images/Rectangle.svg" />
                    <div className="dashboard_right_content_top">
                        <h5>Role Management</h5>
                        <div className="dashboard_right_content_top_options">
                        <div>
                            <Button style={{width:'100%', marginLeft:'10px' }} onClick={this.handleExport}>Export</Button>
                            </div>   
                            <div >
                                <Button onClick={this.onClickRemoveFilter}>Reset</Button> 
                                </div>
                            {/* Removed add new Role button */}
                            {/* <Button disabled={this.state.addDisabled}  onClick ={this.handleAddNew} id="addNewRecord" variant="outlined" color="primary">Add a New Role</Button> */}
                            {/* <Button className="email_link" onClick={this.handleSave}>Save</Button>  */}
                        </div>
                    </div>
                </div>
                {/* {(!this.props.loadingRole)&&  <div id="cover-spin"></div>} */}
                <div className="operator_listing " >
                    <TableContainer component={Paper} style={{paddingLeft:"15px",paddingRight:"15px"}}>
                        <Table stickyHeader aria-label="sticky table" style={{ tableLayout: 'fixed' }}>
                            <colgroup>
                                <col style={{width:'20%'}}/>
                                <col style={{width:'20%'}}/>
                                <col style={{width:'20%'}}/>
                                <col style={{width:'20%'}}/> 
                                <col style={{width:'20%'}}/>
                                <col style={{width:'20%'}}/> 
                            </colgroup>
                            <TableHead >
                                <TableRow >
                                    {roleHeaders}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    this.state.addNew && <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell align = "left" style = {{paddingTop:'20px'}}>
                                            <TextField  type="text"
                                                        id="name"
                                                        variant="outlined"
                                                        size = "small"
                                                        placeholder = "Role Name"
                                                        value={this.state.name}
                                                        name="name"
                                                        onChange={this.handleChange}
                                                        InputProps={{ style: { fontSize: 12 } }}/>
                                            {this.state.nameErr ? <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.nameErr}</div> :  <div style={{fontSize:12,color:"white", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>required</div>}
                                        </TableCell>
                                        <TableCell align = "left" style = {{paddingTop:'9px'}}>
                                        <FormControl variant="outlined" size="small">
                                            <Select value={this.state.accessSite} 
                                                    onChange={this.handleChange} 
                                                    name="accessSite" 
                                                    size="small" 
                                                    style={{fontSize:12,width:'100%'}}>
                                                    {gpsInstalledOptions.map((option) => (
                                                        <MenuItem key={option.id}
                                                                  style={{fontSize:12,width:'100%'}} 
                                                                  value={option.key}>
                                                                  {option.name}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                            {/* {<div style={{fontSize:12,color:"white", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>required </div> } */}

                                         </FormControl>
                                        </TableCell>
                                        <TableCell align = "left">  
                                            <IconButton
                                                aria-label="more"
                                                aria-controls="long-menu"
                                                aria-haspopup="true"
                                                onClick={(e)=>this.handleClickMoreVertIconOnEdit(e)}
                                                >
                                                <MoreVertIcon />
                                                </IconButton>
                                                    <Popper
                                                    open={this.state.open_onedit} anchorEl={this.state.anchorEl_onedit}>
                                                        <ClickAwayListener onClickAway={this.handleClickAwayOnEdit}>
                                                    <Paper>
                                                        <MenuList>
                                                        <MenuItem style={{color:'#3DAB35'}} onClick={()=>this.handleAddCancel()}>Cancel</MenuItem>
                                                            <MenuItem style={{color:'#3DAB35'}} onClick={this.handleSave} >Save</MenuItem>
                                                        </MenuList>
                                                    </Paper>
                                                    </ClickAwayListener>
                                                </Popper>
                                        </TableCell>
                                    </TableRow>
                                }
                                {/* {roleData} */}
                                {roleData.length === 0 ? <TableRow style={{textAlign:'center', fontWeight:'bold', fontSize: '13px', }}>
                                            No Records Found !!</TableRow>:roleData }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>  
                {
                this.props.totalCount && this.props.totalCount != 0 && 
                    <ReactPaginate
                    previousLabel={"Prev"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount = {this.props.totalCount/this.state.limit}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    style={{borderLeftWidth:'0px'}}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}/>
                }  
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return({
        token    : state.auth.token,
        roleData : state.roleData.roleData,
        totalCount: state.roleData.totalCount,
        loadingRole: state.roleData.loading
    })
}

const mapDispatchToProps = (dispatch) => {
    return({
        onFetchRole   : (payload) => dispatch(actionCreators.fetchRoleListing(payload)),
        onAddRole     : (payload, token) => dispatch(actionCreators.addRole(payload, token)),
        onDeleteRole  : (payload, payload2) => dispatch(actionCreators.deleteRole(payload, payload2)),
        onEditRole    : (payload, token) => dispatch(actionCreators.editRole(payload, token)),
        OnFetchRoleListingExport : (payload) => dispatch(actionCreators.fetchRoleListingExport(payload))
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(RoleListing)
