import * as actionTypes from '../../actions/actionsTypes';
import { updateObject } from '../../utility';
const initialState = {
    zone:[],
    loading: false,
    totalCount:0,
 
}
const fetchZoneListingStart = (state, action) => {
    return updateObject(state, {
        loading: true,

    });
}

const fetchZoneSucces = (state, action) => {
    return updateObject(state, {
        totalCount:action.totalCount,
        zone:action.zoneListing,
        loading: false,
     
    });
}

const fetchZoneListingFail = (state, action) => {
    return updateObject(state, {
        loading: false,
    })
}
const zoneReduce = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_ZONE_START: return fetchZoneListingStart(state, action);
        case actionTypes.FETCH_ZONE_SUCCESS: return fetchZoneSucces(state, action);
        case actionTypes.FETCH_ZONE_FAIL: return fetchZoneListingFail(state, action);
   
        default: return state;
    }
}

export default zoneReduce;