import React, {useState,useEffect} from 'react';
import { connect } from 'react-redux';
import '../../App.css'
import * as actionsCreators from '../../store/actions/index'
import axios from '../../http-config';
import _ from 'lodash'
import PropertyForm from './PropertyForm'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {Link} from 'react-router-dom'

function PropertyEdit (props) {
    const [singleProperty, setProperty] = useState({}); 

    useEffect(() => {
        const payload = {
                        token : props.token,
                        id    : props.match.params.id
        }
        const url = `/property/${payload.id}`
        axios({
                        method   : 'get',
                        url      : url,
                        headers  : {'Authorization': `Bearer ${payload.token}`}
                   })
                   
                   .then((response) => {
                       setProperty(response.data.data.properties[0])
                   })
                   .catch((err) => {
                       
                   })
      },[]);
    
    const handleSubmit = (formdata)=>{
        props.onEditPropertyData(formdata,props)
    }
        return(
            
            <div className="create_ulb">
            <div className="dashboard_top_path">
                <p><img src="images/left-arrow.svg" /> Property Management / <span>Edit Property</span></p>
            </div>
            <div className="dashboard_right_content">
                <img className="dashboard_right_content_top_icon" src="images/Rectangle.svg" />
                <div className="dashboard_right_content_top">
                    <h5><Link to = "/property-management" className="email_link"><ArrowBackIosIcon></ArrowBackIosIcon></Link>Edit Property</h5>
                </div>
                <div className="create_ulb_content_outer">
                    <div className="create_ulb_content">
                { 
                 !_.isEmpty(singleProperty)&&(
                     <PropertyForm {...singleProperty} handleSubmit={handleSubmit}/>
                  )
                }    
                    </div>
                </div>
            </div>
        </div>
                
        )
}



const mapStateToProps = (state,props) => {
    
return {
    token:state.auth.token
}
}

const mapDispatchToProps = dispatch => {
return {
    onEditPropertyData: (formdata,props) => dispatch(actionsCreators.editPropertyData(formdata,props))
}
}

export default  connect(mapStateToProps, mapDispatchToProps)(PropertyEdit);

