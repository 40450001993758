import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import '../../App.css'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import GoogleMapReact from 'google-map-react';
import Marker from './Marker';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { TimePicker } from '@material-ui/pickers'
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import axios from '../../http-config';
import { ThreeSixty } from '@material-ui/icons';
const options = [
    {
        id: "1",
        name: "FSTP",
    }, {
        id: "2",
        name: "STP",
    }, {
        id: "3",
        name: "Pumping/Decanting station",
    }
];

class TreatmentFacilityForm extends Component {

    constructor(props) {
        super(props);
        this.handleMapClick = this.handleMapClick.bind(this);
        this.state = {
            tfName: props.facilty ? props.facilty["Name"] : '',
            username: props.facilty ? props.facilty["UserName"] : '',
            email: props.facilty ? props.facilty["Email"] : '',
            location: {
                latitude: "",
                longitude: ""
            },
            latitude: props.facilty ? props.facilty["Location"] ? props.facilty["Location"]["x"] : '' : '',
            longitude: props.facilty ? props.facilty["Location"] ? props.facilty["Location"]["y"] : '' : '',
            capacity: props.facilty ? props.facilty["Capacity"] : '',
            locationName: props.facilty ? props.facilty["LocationName"] : '',
            ward: props.facilty ? props.facilty["WardZone"] : '',
            amount: props.facilty ? props.facilty["Amount"] ? props.facilty["Amount"] : '' : '',
            operationalHoursFrom: props.facilty ? moment().format(`MM-DD-YYYY ${this.props.facilty["OperationHoursFrom"]}`) : moment(Date.now()).format('MM-DD-YYYY 09:00'),
            operationalHoursTo: props.facilty ? moment().format(`MM-DD-YYYY ${this.props.facilty["OperationHoursTo"]}`) : moment(Date.now()).format('MM-DD-YYYY 18:00'),
            receiptNumberPrefix: "",
            UserId: props["facilty"] ? props["facilty"]["UserId"] : "",
            tfNameErr: "",
            usernameErr: "",
            emailErr: "",
            capacityErr: "",
            operationalHoursFromError: "",
            operationalHoursToError: "",
            amountErr: "",
            receiptNumberPrefixErr: "",
            longitudeErr: "",
            latitudeErr: "",
            isCheckboxOn: props.facilty ? props.facilty["Amount"] ? true : false : false,
            typeOfDisposal: props.facilty ? props.facilty["TreatmentFacilityTypeId"] : '',
            facilityEmail: "",
            typeOfDisposalError: "",
            locationError: "",
            nameError: "",
            facilityIdError: "",
            locationNameErr: "",
            wardErr: "",
            feeError: "",
            position: 'inherit',
            left: '50%',
            top: '50%',
            // zone : props.facilty ? props.facilty["zone"] : '',
            // ward : props.facilty ? props.facilty ["ward"]: '',
            // ZoneOptions  :props.facilty ? props.facilty["zone"] : '',
            // wardOptions: props.facilty ? props.facilty["wardNumber"] : '',

        }
    }

    // componentDidMount (){
    //     const zoneRquired = localStorage.getItem('isZoneNotRequired')
    //     if (zoneRquired=== false){
    //         const ulbId = localStorage.getItem('ulbid')
    //         axios({
    //             method  : 'get',
    //             url     : `/zone/getZones`,
    //             headers: {'Authorization': `Bearer ${this.props.token}`}
    //         })
    //         .then((response) => {
    //             this.setState({
    //                 ZoneOptions : response.data.data.zones
    //         })
    //     })
    //     }


    // }

    handleMap = (value) => {
        if (document.fullscreenElement) {
            this.setState({ position: 'fixed', top: 0, left: 0 })
        }
        else {
            this.setState({ position: 'inherit', top: '50%', left: '50%' })
        }
    }
    handleMapClick(x) {
        this.setState({ latitude: x.lat }, () => {
            this.checkValidity()
        })
        this.setState({ longitude: x.lng })
    }

    handleSubmit = (e) => {
        e.preventDefault()
        const err = this.checkValidity()
        if (!err) {
            const formdata = {
                UserId: this.state.UserId,
                tfName: this.state.tfName,
                amount: this.state.amount ? Number(this.state.amount) : null,
                type: parseInt(this.state.typeOfDisposal),
                capacity: this.state.capacity ? Number(this.state.capacity) : null,
                operationalHoursFrom: moment(this.state.operationalHoursFrom).format('HH:mm'),
                operationalHoursTo: moment(this.state.operationalHoursTo).format('HH:mm'),
                receiptNumberPrefix: this.state.receiptNumberPrefix ? this.state.receiptNumberPrefix : null,
                username: this.state.username,
                email: this.state.email,
                location: {
                    latitude: parseFloat(this.state.latitude),
                    longitude: parseFloat(this.state.longitude)
                },
                locationName: this.state.locationName ? this.state.locationName : null,
                wardZone: this.state.ward ? this.state.ward : null,
                token: this.props.token,
                props: this.props,
                // zone: this.state.zone ? this.state.zone : null, 
                // ward: this.state.ward ? this.state.ward : null,
            }
            this.props.handleSubmit(formdata)
        }
    }
    handleChange = (e) => {
        
        
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            this.checkValidity()
        })
    }
    toggleVisibility = () => {   
        const { isCheckboxOn } = this.state
        this.setState({ isCheckboxOn: !isCheckboxOn }, () => {
            this.checkValidity()
        })
    }
    checkValidity = () => {
        let isError = false;
        if (!this.state.tfName) {
            isError = true
            this.setState({ tfNameErr: "*This field is required" })
        }
        else if (this.state.tfName && this.state.tfName.length < 3 || this.state.tfName.length > 100) {
            isError = true
            this.setState({ tfNameErr: "Minimum 3 characters, maximum 100 characters" })
        }
        else if (this.state.tfName && this.state.tfName.length >= 3 && this.state.tfName.length <= 100) {
            // isError = false
            this.setState({ tfNameErr: "" })
        }
        if (!this.state.typeOfDisposal) {
            isError = true
            this.setState({ typeOfDisposalError: "*This field is required" })
        }
        else if (this.state.typeOfDisposal) {
            this.setState({ typeOfDisposalError: "" })
        }
        if (!this.state.username) {
            isError = true
            this.setState({ usernameErr: "*This field is required" })
        }
        else if (this.state.username && this.state.username.length < 3 || this.state.username.length > 20) {
            isError = true
            this.setState({ usernameErr: "Minimum 3 characters, maximum 20 characters" })
        }
        else if (this.state.username && this.state.username.length >= 3 && this.state.username.length <= 20) {
            // isError = false
            this.setState({ usernameErr: "" })
        }
        if (!this.state.email) {
            isError = true
            this.setState({ emailErr: "*This field is required" })
        }
        else if (this.state.email) {
            var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
            if (!this.state.email.match(mailformat)) {
                isError = true;
                this.setState({ emailErr: "*You have entered an invalid email address" })
            }
            else {
                // isError=false
                this.setState({ emailErr: "" })
            }
        }
        if (!this.state.capacity) {
            isError = true
            this.setState({ capacityErr: "Capacity in KL" })
        }
        else if (this.state.capacity) {
            if(!String(this.state.capacity).match(/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/) ){
                isError = true
                this.setState({ capacityErr: "Capacity should be postive number!" })
            }
            else{
                this.setState({ capacityErr: "" })
            }
        
        }
        if (this.state.isCheckboxOn) {
            if (!this.state.amount) {
                isError = true;
                this.setState({ amountErr: "*Enter Valid Amount" })
            }
            else if (this.state.amount) {
                if(!String(this.state.amount).match(/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/)){
                    isError = true;
                    this.setState({ amountErr: "Amount should be postive number!" })
                }
                else{
                    this.setState({ amountErr: "" })
                }
            }
            if (this.state.receiptNumberPrefix) {
                this.setState({ receiptNumberPrefixErr: "" })
            }

        }
        if (!this.state.operationalHoursFrom) {
            isError = true
            this.setState({ operationalHoursFromError: "*This field is required" })
        }
        else if (this.state.operationalHoursFrom) {
            this.setState({ operationalHoursFromError: "" })
        }
        if (!this.state.operationalHoursTo) {
            isError = true
            this.setState({ operationalHoursToError: "*This field is required" })
        }
        else if (this.state.operationalHoursTo) {
            this.setState({ operationalHoursToError: "" })
        }
        if (this.state.operationalHoursFrom && this.state.operationalHoursTo) {
            if (moment(this.state.operationalHoursTo) <= moment(this.state.operationalHoursFrom)) {
                isError = true
                this.setState({ operationalHoursToError: "to hours cannot be lesser than/eqaul to from hours" })
            }
        }
        if (!this.state.latitude) {
            isError = true
            this.setState({ latitudeErr: "*This field is required" })
        }
        else if (this.state.latitude) {
            this.setState({ latitudeErr: "" })
        }
        if (!this.state.longitude) {
            isError = true
            this.setState({ longitudeErr: "*This field is required" })
        }
        else if (this.state.longitude) {
            this.setState({ longitudeErr: "" })
        }
        if (!this.state.locationName) {
            this.setState({ locationNameErr: "" })
        }
        else if (this.state.locationName) {
            if (this.state.locationName.length < 3 || this.state.locationName.length > 100) {
                isError = true
                this.setState({ locationNameErr: "*Minimum 3 characters, maximum 100 characters" })
            }
            else if (this.state.locationName && this.state.locationName.length >= 3 || this.state.locationName.length <= 100) {
                this.setState({ locationNameErr: "" })
            }
        }
        if (!this.state.ward) {
            this.setState({ wardErr: "" })
        }
        else if (this.state.ward) {
            if (this.state.ward.length > 100) {
                isError = true
                this.setState({ wardErr: "*Maximum 100 characters" })
            }
            else if (this.state.ward.length <= 100) {
                this.setState({ wardErr: "" })
            }
        }
        return isError
    }
    static defaultProps = {
        center: {
            lat: 11.1271,
            lng: 78.6569
        },
        zoom: 7,
        maps_key: "AIzaSyBV5XySVlxwJm_lAJ2ZoecKismiBv1ZJAo"
    };
    handleTimeFrom = (time) => {
        this.setState({ operationalHoursFrom: time }, () => {
            this.checkValidity()
        })
    }
    handleTimeTo = (time) => {
        this.setState({ operationalHoursTo: time }, () => {
            this.checkValidity()
        })
    }
    render() {
        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormControl>
                            <label className="required" htmlFor="tfName" align="left">Name</label>
                            <TextField id="tfName"
                                variant="outlined"
                                value={this.state.tfName}
                                onChange={this.handleChange}
                                name="tfName"
                                placeholder="Enter Name" />
                            <div style={{ fontSize: 12, color: "red" }}>{this.state.tfNameErr}</div>
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl>
                            <label class="required" for="typeOfDisposal" align="left">Type Of Disposal Facility</label>
                            <Select value={this.state.typeOfDisposal} onChange={this.handleChange} name="typeOfDisposal">
                                {options.map((option) => (
                                    // <option value={option.id}>{option.name}</option>
                                    <MenuItem value={option.id}>{option.name}</MenuItem>
                                ))}
                            </Select>
                            <div style={{ fontSize: 12, color: "red" }}>{this.state.typeOfDisposalError}</div>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl>
                            <label className="required" htmlFor="username" align="left">Facility Username</label>
                            <OutlinedInput id="username"
                                variant="outlined"
                                value={this.state.username}
                                onChange={this.handleChange}
                                name="username"
                                placeholder="Enter User Name" />
                            <div style={{ fontSize: 12, color: "red" }}>{this.state.usernameErr}</div>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl>
                            <label className="required" htmlFor="email" align="left">Facility Email</label>
                            <OutlinedInput id="email"
                                variant="outlined"
                                value={this.state.email}
                                onChange={this.handleChange}
                                name="email"
                                placeholder="Enter Email Id" />
                            <div style={{ fontSize: 12, color: "red" }}>{this.state.emailErr}</div>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{ height: '30vh', width: '100%', paddingBottom: '15px', position: this.state.position, top: this.state.top, left: this.state.left }}>
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: this.props.maps_key }}
                                defaultCenter={this.props.center}
                                defaultZoom={this.props.zoom}
                                onClick={this.handleMapClick}
                                onChange={this.handleMap}>
                                <Marker
                                    lat={this.state.latitude}
                                    lng={this.state.longitude}
                                    name="My Marker"
                                    color="blue" />
                            </GoogleMapReact>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl>
                            <label className="required" htmlFor="latitude" align="left">Latitude</label>
                            <OutlinedInput id="latitude"
                                variant="outlined"
                                disabled
                                value={evaluateLocation(this.state.latitude)}
                                onChange={this.handleChange}
                                name="latitude"
                                placeholder="Enter Latitute"
                                endAdornment={<InputAdornment><LocationOnOutlinedIcon /></InputAdornment>} />
                            <div style={{ fontSize: 12, color: "red", }}>{this.state.latitudeErr}</div>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl>
                            <label className="required" htmlFor="longitude" align="left">Longitude</label>
                            <OutlinedInput id="longitude"
                                variant="outlined"
                                disabled
                                value={evaluateLocation(this.state.longitude)}
                                onChange={this.handleChange}
                                name="longitude"
                                placeholder="Enter Longitude"
                                endAdornment={<InputAdornment><LocationOnOutlinedIcon /></InputAdornment>} />
                            <div style={{ fontSize: 12, color: "red" }}>{this.state.longitudeErr}</div>
                        </FormControl>

                    </Grid>
                    <Grid item xs={6}>
                        <FormControl>
                            <label htmlFor="locationName" align="left">Location name </label>
                            <OutlinedInput id="locationName"
                                variant="outlined"
                                value={this.state.locationName}
                                onChange={this.handleChange}
                                name="locationName"
                                placeholder="Enter Location Name" />
                            <div style={{ fontSize: 12, color: "red" }}>{this.state.locationNameErr}</div>

                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl>
                            <label htmlFor="ward" align="left">Ward/Zone</label>
                            <OutlinedInput id="ward"
                                variant="outlined"
                                value={this.state.ward}
                                onChange={this.handleChange}
                                name="ward"
                                placeholder="Ward/Zone" />
                            <div style={{ fontSize: 12, color: "red" }}>{this.state.wardErr}</div>

                        </FormControl>

                    </Grid>
                    {/* <Grid item xs={6}>
                              
                        <FormControl>
                            <label htmlFor="zone" align="left">Zone Name</label>
                            <Select value={this.state.zone} onChange={this.handleChange} name="zone">
                            {this.state.ZoneOptions.map((option) =>{ 
                                            return(
                                            <MenuItem value={option.Id}>{option.Name}</MenuItem>
                                        )})}
                                    </Select> 
                        </FormControl>
                    </Grid> */}
                    {/* <Grid item xs={6}>                         
                        <FormControl>
                            <label htmlFor="wardNumber" align="left">Ward</label>
                            <Select value={this.state.wardNumber} onChange={this.handleChange} name="wardNumber">
                                        {!!this.state.wardOptions.length !=0&&this.state.wardOptions.map((option) =>{ 
                                            return(
                                            <MenuItem value={option.wardId}>{option.wardNumber}</MenuItem>
                                        )})}
                                    </Select>
                        </FormControl>
                        
                    </Grid> */}
                    <Grid item xs={6}>
                        <FormControl>
                            <label className="required" htmlFor="operationalHoursFrom" align="left">Operational Hours From </label>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <TimePicker autoOk
                                    value={this.state.operationalHoursFrom}
                                    onChange={this.handleTimeFrom}
                                    InputProps={{ style: { fontSize: 'small', paddingLeft: '12px', paddingBottom: '7px', paddingTop: '7px', fontFamily: "'Open Sans', sans-serif !important}" } }} />
                                <div style={{ fontSize: 12, color: "red" }}>{this.state.operationalHoursFromError}</div>
                            </MuiPickersUtilsProvider>
                        </FormControl>

                    </Grid>
                    <Grid item xs={6}>
                        <FormControl>
                            <label className="required" htmlFor="operationalHoursTo" align="left">Operational Hours To</label>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <TimePicker autoOk
                                    value={this.state.operationalHoursTo}
                                    onChange={this.handleTimeTo}
                                    InputProps={{ style: { fontSize: 'small', paddingLeft: '12px', paddingBottom: '7px', paddingTop: '7px', fontFamily: "'Open Sans', sans-serif !important}" } }} />
                                <div style={{ fontSize: 12, color: "red" }}>{this.state.operationalHoursToError}</div>
                            </MuiPickersUtilsProvider>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>

                        <FormControl>
                            <label htmlFor="capacity" align="left">Capacity(in KL per day)</label>
                            <OutlinedInput id="capacity"
                                // type="number"
                                // min="0"
                                // step="1"
                                variant="outlined"
                                value={this.state.capacity}
                                onChange={this.handleChange}
                                name="capacity"
                                placeholder="Enter in Kilo Liters" />
                            <div style={{ fontSize: 12, color: "red" }}>{this.state.capacityErr}</div>
                        </FormControl>
                    </Grid>

                    <Grid container spacing={1} item xs={6}>
                    
                        <Grid item xs={12}>
                        <FormControl>
                            <label htmlFor="amount" align="left" style={{ display: "flex", align: 'center', alignItems: "center" }}>Decantation Fee (₹)
                        {!this.state.isCheckboxOn ? <CheckBoxOutlineBlankOutlinedIcon onClick={() => { this.toggleVisibility() }} /> : <CheckBoxIcon style={{ color: "#3dab35" }} onClick={() => { this.toggleVisibility() }} />}</label>
                        </FormControl>
                            {this.state.isCheckboxOn ?
                                <div>
                                    <FormControl style={{width:'100%', float: "left"}}>
                                        <label className="required" htmlFor="amount" align="left">Amount</label>
                                        <OutlinedInput id="amount"
                                            // type="number"
                                            variant="outlined"
                                            // min="0"
                                            // step="1" 
                                            value={this.state.amount}
                                            onChange={this.handleChange}
                                            name="amount"
                                            placeholder="Fee Amount" />
                                        <div style={{ fontSize: 12, color: "red" }}>{this.state.amountErr}</div>
                                    </FormControl>
                        &emsp;&emsp;&emsp;
                        </div> : null}
                        </Grid>
                    </Grid>
                            
                    <Grid item xs={12}>
                        <div className="create_ulb_buttons">
                            <Link className="email_link" to="/treatment"> <Button className="login_submit" variant="outlined" color="primary">Cancel</Button></Link>
                            <Button className="login_submit" variant="contained" color="primary" onClick={this.handleSubmit}>Save</Button>
                        </div>
                    </Grid>

                </Grid>


            </div>
        )
    }
}

/**
 * Evaluates lat long for 4 digit precision
 * @param {*} loc - Lat Long for evaluation
 */
function evaluateLocation(loc) {
    return loc.toString().length > 0 ? parseFloat(loc).toFixed(4) : "";
}

const mapStateToProps = (state) => {
    return {
        token: state.auth.token,
    }
}

export default connect(mapStateToProps)(TreatmentFacilityForm);

