import * as actionTypes from '../actionsTypes';
import axios from '../../../http-config';

//Operator Listing HEADERS
export const fetchOperatorHeadersStart = () =>
{
    return {
        type : actionTypes.FETCH_OPERATOR_HEADERS_START
    }
}
export const fetchOperatorHeadersSuccess = (response) =>
{

    return{
        type : actionTypes.FETCH_OPERATOR_HEADERS_SUCCESS,
        operatorHeaders : response
    }
}
export const fetchOperatorHeadersFail = (err) =>
{
    return{
        type : actionTypes.FETCH_OPERATOR_HEADERS_FAIL,
        error : err
    }
}
export const fetchOperatorHeaders = (payload) =>
{
    const token = payload.token
    return (dispatch) =>
    {
        dispatch(fetchOperatorHeadersStart())
        axios({
            method : 'get',
            url : '/operator/headers',
            headers : {'Authorization' : `Bearer ${token}`}
        })
        .then((response)=>{
            const operatorHeaders = response.data.data.headers
            dispatch(fetchOperatorHeadersSuccess(operatorHeaders))
        })
        .catch((err)=>{
            dispatch(fetchOperatorHeadersFail(err))
        })
    }
}


