import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom'

import './RecordKeeper.css';
import * as actionsCreators from  '../../../store/actions/index';
import Spinner from '../../../components/Spinner/Spinner';
import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler';
import axios from '../../../http-config';
import Checkbox from '@material-ui/core/Checkbox';
import swal from 'sweetalert';
// import { makeStyles, withStyles } from '@material-ui/core/styles';
import ReactPaginate from 'react-paginate';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell'; 
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from '@material-ui/core/MenuList';
import NativeSelect from '@material-ui/core/NativeSelect';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Grid from '@material-ui/core/Grid';
import Popper from '@material-ui/core/Popper';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
// import DialogTitle from '@material-ui/core/DialogTitle';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment'
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import myfile from '../../../BulkLoadUploadDocument.xlsx'
import Config1 from './../../../BulkUploadsTemplate(Config1).xlsx';
import Config2 from './../../../BulkLoadsUploader(config2).xlsx';
import { DatePicker , MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import * as User  from '../../../config/constant';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography'
import Radio from '@material-ui/core/Radio';
import clsx from 'clsx';
import LinearProgress, { LinearProgressProps } from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import SameLoads from './SameLoads';
import FormControl from '@material-ui/core/FormControl';
import {createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress style={{color:'#3dab35'}} variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }
  const theme = createMuiTheme({
    palette: {
       secondary: {
           main: '#3dab35'
       }
    }
  })
const BootstrapInput = withStyles((theme) => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor:"#fff",
      border: '1px solid #4CAF50',
      fontSize: 15,
      padding: '7px 26px 7px 20px',
      transition: theme.transitions.create(['border-color']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        'Open Sans',
        'sans-serif',
   
      ].join(','),
      '&:focus ': {
        borderRadius: 4,
            backgroundColor:"#fff !important"
   
      }
    },
  }))(InputBase);

  const useStyles = makeStyles({
    root: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    icon: {
      borderRadius: '50%',
      width: 16,
      height: 16,
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#f5f8fa',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
      },
      'input:hover ~ &': {
        backgroundColor: '#ebf1f5',
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
      },
    },
    checkedIcon: {
      backgroundColor: '#3dab35',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
      },
      'input:hover ~ &': {
        backgroundColor: '#3dab35',
      },
    },
  });
  
  // Inspired by blueprintjs
  function StyledRadio(props) {
    const classes = useStyles();
  
    return (
      <Radio
        className={classes.root}
        disableRipple
        color="default"
        checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
        icon={<span className={classes.icon} />}
        {...props}
      />
    );
  }
const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    dialogPaper: {
      minHeight: '80vh',
      maxHeight: '80vh',
  },
  });
  
  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  
  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);
class RecordKeeper extends Component {
    state = {
        activePage:this.props?.location?.state ? this.props?.location?.state?.page-1 : 0,
        sortObj : {
            key: '',
            value: ''
        },
        limit: this.props?.location?.state ? this.props?.location?.state?.limit :10,
        page: this.props?.location?.state ? this.props?.location?.state?.page : 1,
        filter : {
            'wardNumber':'',
            'operatorName': !!this.props.location&&this.props.location.state != undefined ?this.props.location.state.operatorName:'' ,
            'truckRegistrationNumber':'',
            'propertyTypeName':'',
        },
        controls: {
            search: {
                value: '',
            }
        },
        query:this.props?.location?.state ? this.props?.location?.state?.query : '',
        currentSortId:"",
        export:true,
        filterBy:"",
        isAll:false,
        open_dialog:false,
        todate:this.props?.location?.state ? this.props?.location?.state?.toDate : (localStorage.getItem('roleName' ) == User.STATEDASHBOARDUSER || localStorage.getItem('roleName' ) == User.ADMIN)? !!this.props.location&&this.props.location.endDate :new Date(),
        fromdate:this.props?.location?.state ? this.props?.location?.state?.fromDate : (localStorage.getItem('roleName' ) == User.STATEDASHBOARDUSER || localStorage.getItem('roleName' ) == User.ADMIN)? !!this.props.location&&this.props.location.startDate : new Date().setMonth(new Date().getMonth() - 2),
        isFilter:false,
        selectedFile:null,
        open_addRecord:false,
        open:false,
        anchorEl:null,
        placement:'',
        open_icon:false,
        anchorEl_icon:null,
        id:'',
        searchQuery:'',
        openInfo:false,
        
        collecton:localStorage.getItem('isRepeatCollection')&&localStorage.getItem('isRepeatCollection')== "true"? true:localStorage.getItem('isRepeatCollection')&&localStorage.getItem('isRepeatCollection')=="false"? false:null,
        operatorIds :this.props?.location?.state ? this.props?.location?.state?.operatorId : !!this.props.location&& !!this.props.location.state? this.props.location.state.operatorIds:"",
        checkValue:false,
        selected: [],
        selectedPropertyId: [],
        propertyIdTypeId:[],
        checkAllValue:false,
        setRowsPerPage:10,
        allcheckBox:false,
        singleLoad:[],
        bulkUp: false,
        openUploadMulitpleLoadsPopUp :false,
        // openUpdateEdit:false
    }

    componentDidMount() {
        const roleName = localStorage.getItem("roleName")
       if(roleName === "ULB Admin"){
        let payload = {
            token: this.props.token,
            page: this.state.page,
            limit: this.state.limit,
            sort: '',
            filter: JSON.stringify([this.state.filter]),
            query: this.state.query,
            ulbId:this.props.match.params.id == 'all' ? '' : this.props.match.params.id,
            operatorId: this.state.operatorIds
        }
        
        this.props.onFetchHeaders(payload);
        this.onClickApplyFilter();
        this.props.onDownloadSampleFile(); 
    } else {
        let payload = {
            token: this.props.token,
            page:  this.state.page,
            limit: this.state.limit,
            sort: '',
            filter: JSON.stringify([this.state.filter]),
            query: this.state.query,
            ulbId:this.props.match.params.id == 'all' ? '' : this.props.match.params.id,
            operatorId: this.state.operatorIds
        }
        
        this.props.onFetchHeaders(payload);
        this.onClickApplyFilter();
    }
    }

    handlePageClick = (e) => {
        this.setState({
            activePage : e.selected
        })
        const selectedPage = e.selected;
        this.setState({page: selectedPage + 1})
        let payload = {
            token: this.props.token,
            page:  selectedPage + 1,
            limit: this.state.limit,
            sort: `${this.state.sortObj.key}${this.state.sortObj.value}`,
            filter: JSON.stringify([this.state.filter]),
            query: this.state.query,
            fromDate:moment(this.state.fromdate).format('YYYY-MM-DD 00:00'),
            ulbId:this.props.match.params.id == 'all' ? '' : this.props.match.params.id,
            toDate:moment(this.state.todate).format('YYYY-MM-DD 23:59'),
            operatorId: this.state.operatorIds
        }
        this.state.isFilter ? this.props.onFilterLoads(payload) : this.props.onFetchLoads(payload)
    };

    // handlePageClickForSameLoads =(e)=>{
    //     this.setState({
    //         sameLoadsPage : e.selected+1,
    //         sameLoadsActivepage: e.selected
    //     })
        // const selectedPage = e.selected;
        // let payload = {
        //     token: this.props.token,
        //     page:  selectedPage + 1,
        //     limit: this.state.limit,
        //     sort: `${this.state.sortObj.key}${this.state.sortObj.value}`,
        //     filter: JSON.stringify([this.state.filter]),
        //     query: this.state.query,
        //     fromDate:moment(this.state.fromdate).format('YYYY-MM-DD 00:00'),
        //     ulbId:this.props.match.params.id == 'all' ? '' : this.props.match.params.id,
        //     toDate:moment(this.state.todate).format('YYYY-MM-DD 23:59'),
        //     operatorId: this.state.operatorIds
        // }
        
    // }
    handleSort = (e, sortBy) => {      
        let sortObj;
        if (this.state.sortObj.key === sortBy.accessor) {
            let value = (this.state.sortObj.value === '-desc') ? '-asc' : '-desc';
            sortObj = {
                key : sortBy.accessor,
                value: value
            }
        } 
        else {
            sortObj = {
                key : sortBy.accessor,
                value: '-desc'
            }    
        }
        this.setState({ sortObj: sortObj ,currentSortId:sortBy.accessor});
        let payload = {
            token: this.props.token,
            page:  this.state.page,
            limit: this.state.limit,
            sort: `${sortObj.key}${sortObj.value}`,
            filter: JSON.stringify([this.state.filter]),
            query: this.state.query,
            fromDate:moment(this.state.fromdate).format('YYYY-MM-DD 00:00'),
            ulbId:this.props.match.params.id == 'all' ? '' : this.props.match.params.id,
            toDate:moment(this.state.todate).format('YYYY-MM-DD 23:59'),
            operatorId: this.state.operatorIds
        }
        this.state.isFilter ? this.props.onFilterLoads(payload) : this.props.onFetchLoads(payload)
    }

    onClickGetFilterVals = (e, colObj) => {
       if(e===undefined){
          let isAll=true
          this.setState({isAll})
       }  
      else{
          let isAll=false
          this.setState({isAll})
      }
        const updatedFilter = {
            ...this.state.filter
        }
        updatedFilter[colObj.accessor] = e;
        this.setState({ filter: updatedFilter ,filterBy: colObj.accessor});   
        let payload = {
            token: this.props.token,
            page:  this.state.page,
            limit: this.state.limit,
            sort: `${this.state.sortObj.key}${this.state.sortObj.value}`,
            filter: JSON.stringify([updatedFilter]),
            query: this.state.query,
            fromDate:moment(this.state.fromdate).format('YYYY-MM-DD 00:00'),
            ulbId:this.props.match.params.id == 'all' ? '' : this.props.match.params.id,
            toDate:moment(this.state.todate).format('YYYY-MM-DD 23:59'),
            operatorId: this.state.operatorIds
        }
        this.state.isFilter ? this.props.onFilterLoads(payload) : this.props.onFetchLoads(payload)
    }

    onClickSearch = (e) => {
        e.preventDefault()
        let searchVal = this.state.controls.search.value
        if (searchVal.length >= 3 || searchVal.length == 0) {
            let payload = {
                token: this.props.token,
                page:  this.state.page,
                limit: this.state.limit,
                sort: `${this.state.sortObj.key}${this.state.sortObj.value}`,
                filter: JSON.stringify([this.state.filter]),
                query: searchVal,
                fromDate:moment(this.state.fromdate).format('YYYY-MM-DD 00:00'),
                toDate:moment(this.state.todate).format('YYYY-MM-DD 23:59'),
                ulbId:this.props.match.params.id == 'all' ? '' : this.props.match.params.id,
                operatorId: this.state.operatorIds
            }
            this.setState({ query: searchVal });
            this.state.isFilter ? this.props.onFilterLoads(payload) : this.props.onFetchLoads(payload)
        } else {
            swal("Enter atleast 3 characters")
        }
    }
      
    onClickExport=()=>{
        let payload = {
            token: this.props.token,
            filter: JSON.stringify([this.state.filter]),
            query: this.state.query,
            export:this.state.export,
            fromDate:this.state.isFilter?moment(this.state.fromdate).format('YYYY-MM-DD 00:00'):'',
            toDate:moment(this.state.todate).format('YYYY-MM-DD 23:59'),
            ulbId:this.props.match.params.id == 'all' ? '' : this.props.match.params.id,
            operatorId: this.state.operatorIds
        }
        this.props.onFetchExports(payload);
       
    }

    inputChangedHadler = (event, controlName) => {
        const updatedControls = {
            ...this.state.controls,
            [controlName]: {
                ...this.state.controls[controlName],
                value: event.target.value,
            }
        }
        this.setState({ controls: updatedControls,searchQuery:updatedControls.search.value});
        
    }

    isActionAllowed = (actionUrl = "") => {
        return ["edit", "delete"].indexOf(actionUrl) >= 0 ? true : false;
    };
    handleChange=(e)=>{
         this.setState({[e.target.name]:e.target.value})
    }
    onClickApplyFilter= ()=>{
        let payload = {
            token: this.props.token,
            page:  this.state.page,
            limit: this.state.limit,
            sort: '',
            filter:  this.props?.location?.state ? this.props?.location?.state?.filter : JSON.stringify([this.state.filter]),
            query: this.state.query,
            fromDate:moment(this.state.fromdate).format('YYYY-MM-DD 00:00'),
            toDate:moment(this.state.todate).format('YYYY-MM-DD 23:59'),
            ulbId:this.props.match.params.id == 'all' ? '' : this.props.match.params.id,
            operatorId: this.state.operatorIds
        }
        this.props.onFilterLoads(payload)
        this.setState({isFilter:true})
    }
    onClickRemoveFilter = ()=>{
        let payload = {
            token: this.props.token,
            page:  1,
            limit: this.state.limit,
            sort: '',
            filter: JSON.stringify([{
                'wardNumber':'',
                'operatorName': '' ,
                'truckRegistrationNumber':'',
                'propertyTypeName':'',
            }]),
            query: '',
            fromDate:moment(new Date().setMonth(new Date().getMonth() - 2)).format('YYYY-MM-DD 00:00'),
            toDate:moment(!!this.props.location && this.props.location.endDate).format('YYYY-MM-DD 23:59'),
            ulbId:localStorage.getItem('ulbid'),
            operatorId: this.state.operatorIds
        }
        this.props.onFetchLoads(payload);
        this.setState({searchQuery:'',isFilter:false,fromdate:(localStorage.getItem('roleName' ) == User.STATEDASHBOARDUSER || localStorage.getItem('roleName' ) == User.ADMIN)? !!this.props.location&&this.props.location.startDate : new Date().setMonth(new Date().getMonth() - 2),
        todate:(localStorage.getItem('roleName' ) == User.STATEDASHBOARDUSER || localStorage.getItem('roleName' ) == User.ADMIN) ? !!this.props.location && this.props.location.endDate :new Date(),
        filter : {
            'wardNumber':'',
            'operatorName': '' ,
            'truckRegistrationNumber':'',
            'propertyTypeName':'',
        },
        controls: {
            search: {
                value: '',
            }
        },
        sortObj : {
            key: '',
            value: ''
        },
    })
    }
    setFromDate =(date)=>{
        this.setState({fromdate:date}, this.onClickApplyFilter)
    } 
    setToDate =(date)=>{
        this.setState({todate:date}, this.onClickApplyFilter)
    }
    
   onFileUpload = () => { 
        let payload = {
            token: this.props.token,
            page:  this.state.page,
            limit: this.state.limit,
            sort: '',
            filter: JSON.stringify([this.state.filter]),
            query: this.state.query,
            fromDate:moment(this.state.fromdate).format('YYYY-MM-DD 00:00'),
            ulbId:this.props.match.params.id == 'all' ? '' : this.props.match.params.id,
            toDate:moment(this.state.todate).format('YYYY-MM-DD 23:59'),
            operatorId: this.state.operatorIds
        }
    const formData = new FormData(); 
    formData.append( 
        "myFile", 
        this.state.selectedFile, 
        this.state.selectedFile.name 
    )
    this.props.onfetchBulkImport(payload,formData);
    this.setState({open:false})
    }
    onFileChange = (event) => {
        this.setState({ selectedFile: event.target.files[0], bulkUp: true},()=>{
            this.onFileUpload()
        });   
    }
    handleClose = (event) => {
    this.setState({open:false})
    };
    handleClick = (newPlacement) => (event) => {
    this.setState({ anchorEl: event.currentTarget, newPlacement:newPlacement,open:!this.state.open});
    };
    handleClickMoreVertIcon=(event,id)=>{
        this.setState({open_icon:!this.state.open_icon,anchorEl_icon:event.currentTarget,id:id})
      }
      handleClickAwayBulk = () => {
        this.setState({
          open: false 
        });
      };
      handleClickAwayEdit = () => {
        this.setState({
          open_icon:false
        });
      };
  
    handleDeleteRecord = () => {
        swal({ 
                title   : "Are you sure?",
                text    : "Once deleted, you will not be able to recover this data!",
                icon    : "warning",
                buttons : {
                    cancel  : 'No',
                    confirm : 'Yes'}
                })
                .then((willDelete) => {
                    if (willDelete) {
                        let payload = {
                            token: this.props.token,
                            page:  1,
                            limit: this.state.limit,
                            sort: '',
                            filter: JSON.stringify([this.state.filter]),
                            query: this.state.query,
                            id : this.state.id,
                            fromDate:moment(this.state.fromdate).format('YYYY-MM-DD 00:00'),
                            ulbId:this.props.match.params.id == 'all' ? '' : this.props.match.params.id,
                            toDate:moment(this.state.todate).format('YYYY-MM-DD 23:59'),
                            operatorId: this.state.operatorIds
                        }
                    this.props.onDeleteRecords(payload,this.props)
                    this.setState({
                        activePage:0
                    })
                } 
            });
    }  
    handleClickOpen = () => {
        this.setState({openInfo:true});
    };
    // handleClickEditOpen = () => {
    //     this.setState({openInfoedit:true});
    // };
    handleCloseInfo = () => {
        this.setState({openInfo:false});
      };


      handleCollectionChange =(e)=>{
        this.setState({collecton: e.target.value},()=>{
            let payload = {
                token: this.props.token,
                "isRepeatCollection": this.state.collecton
            }
            this.props.FetchCollectioRequired(payload)
        })
      }


      handleClickRow=(event, ro)=>{
                let dataArr = []
                this.setState({checkAllValue:event.target.checked, checkValue:true})
        
                if(!!event.target&&event.target.checked === true&&ro.accessor == "checkbox"){
                    !!this.props.loads&&this.props.loads.slice(this.state.activePage * this.state.setRowsPerPage, this.state.activePage * this.state.setRowsPerPage + this.state.setRowsPerPage).map(d=>{
                        dataArr.push(d.loadId)
                    })
                    this.setState({selected:dataArr})
                }else if(!!event.target&&event.target.checked === false&&ro.accessor == "checkbox"){
                    this.setState({selected:[], checkValue:false})
                }
        
            }
        
        
    handleChangeRowsPerPage = (event) => {
    this.setState({setRowsPerPage:parseInt(event.target.value, 10), activePage:0});
    
    };
    handleCheckboxClick = (event, id, loadpropertyId) => {
        event.stopPropagation();
        const statsvalue = event.target.value
  if(event.target.checked == true){
    this.setState({checkValue: true})
  }
  else{
    this.setState({checkValue: false})
  }
        const { selected, selectedPropertyId } = this.state;
        const selectedIndex = selected.indexOf(id);
        const selectedPropertyIndex = selectedPropertyId.indexOf(loadpropertyId)
        let newSelected = [];
        let newSelectedProperty =[];
        if (selectedIndex === -1 ) {
            newSelected = newSelected.concat(selected, id);
            newSelectedProperty = newSelectedProperty.concat(selectedPropertyId, loadpropertyId);

        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
            newSelectedProperty = newSelectedProperty.concat(selectedPropertyId.slice(1));

        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
            newSelectedProperty = newSelectedProperty.concat(selectedPropertyId.slice(0, -1));

        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
            newSelectedProperty = newSelectedProperty.concat(
                selectedPropertyId.slice(0, selectedPropertyIndex),
                selectedPropertyId.slice(selectedPropertyIndex + 1)
            );
        }
        this.setState({ selected: newSelected , selectedPropertyId:newSelectedProperty,propertyIdTypeId:newSelectedProperty});
      

    };
    handleCloseInfoEdit=()=>{
        this.setState({singleLoad : [], openInfoedit:false})
    }
    // handlechangeEdit= (arr)=>{
    //     const payload = {
    //         token         : this.props.token,
    //         ulbId            : localStorage.getItem('ulbid' ),
    //         limitLoads: this.state.sameLoadslimit ,
    //         LoadsPage:this.state.sameLoadsPage
    //     }
    //     this.props.onFetchHeaders(payload)
    //     const url = `/load/?loadsId=${JSON.stringify(arr)}&limit=${payload.limitLoads}&page=${payload.LoadsPage}`
    //     axios({
    //         method   : 'get',
    //         url      : url,
    //         headers  : {'Authorization': `Bearer ${payload.token}`}
    //     })
    //     .then((response) => {
    //     this.setState({singleLoad : response.data.data.loads, openInfoedit:true, sameLoadsCount: response.data.totalCount})
    //     })
    //     .catch((err) => {
        
    //     })
    // }
    openUploadMulitpleLoads=(data)=>{
        this.setState({openUploadMulitpleLoadsPopUp:data})
    }
    handleOpenSameEdit = ()=>{
        const ulbid = localStorage.getItem('ulbid')
        // const s = this.state.operatorValue.split("-")
        let payload = {
          token: this.props.token,
          limit: this.state.limit,
          page:1,
          loadsId:  this.state.selected,
          propertyIds:this.state.selectedPropertyId,
          propertyIdTypeId:this.state.propertyIdTypeId,
          ulbId: ulbid,
          // operatorId: s[0]
        }
        this.props.onSameLoadsListing(payload)
        this.setState(()=>{
            this.openTest()     
        })
    }
    openTest = () =>{
        this.setState({
            openUploadMulitpleLoadsPopUp:true
        })
    }
    handleDialogClose = (value) => {
        this.setState({
            openUploadMulitpleLoadsPopUp:value,
             selected:[], checkValue:false, selectedPropertyId :[],propertyIdTypeId:[] , checkAllValue:false
        }) 
        let payload = {
            token: this.props.token,
            page:  this.state.page,
            limit: this.state.limit,
            sort: '',
            filter: JSON.stringify([this.state.filter]),
            query: this.state.query,
            ulbId:this.props.match.params.id == 'all' ? '' : this.props.match.params.id,
            operatorId: this.state.operatorIds
        }
        
        this.props.onFetchHeaders(payload);
        this.onClickApplyFilter();
        this.props.onDownloadSampleFile(); 
    }
    render() {
        let filterValue;
        let array1=this.props.headers.headers.slice(0,3)
        let array2=this.props.headers.headers.slice(3)
        array2 = array2.filter(header => {
            if(header.header != 'Record-Keeper'){
                return header
            }
        })


        array2.push({
            accessor: "recordKeeperName",
            filter: "false",
            header: "Record-Keeper",
            sortable: "false"
        })

        array2 = array2.map(data => {
           if(data.header == 'Date' || data.header == 'Time'){
               return {
                   ...data,
                   sortable: "true"
               }
           }else{
               return data
           }
       })
        // let loads = <Spinner />;
        // let loads2 = <Spinner />;
        let fixedLoads
        let loads
        if ( !this.props.loading ) {
            if (this.props.loads.length === 0) {
            fixedLoads = <h3>No Loads</h3>
            } else {
                fixedLoads = this.props.loads.map((load) => (
                    <TableRow  key={load.id}>{
                        array1.map(col => {
                            if(col.accessor == "checkbox"){
                                return <TableCell 
                                style={{lineHeight:'56px',alignItems:'right'}}> 
                                {!!this.state.checkAllValue&&this.state.checkAllValue == true? 
                                <div> <Checkbox
                                style={(localStorage.getItem('roleName' ) == User.STATEDASHBOARDUSER ||
                                localStorage.getItem('roleName' ) == User.ADMIN|| !!this.state.operatorIds && this.state.operatorIds != "") ? {display:"none"}:{ display:'block', color: 'green', fontFamily: 'Open sans-serif', transform: 'scale(1.2)', width: 48}}
                                            // style={{ color: 'green', fontFamily: 'Open sans-serif', transform: 'scale(1.2)', width: 48 }}
                                                onClick={event =>this.handleCheckboxClick(event, load.loadId, load.propertyId)}
                                                value={this.state.checkValue}
                                                checked={this.state.checkValue}
                                             
                                            />
                                            </div>
                                :
                                 <Checkbox
                                 style={(localStorage.getItem('roleName' ) == User.STATEDASHBOARDUSER ||
                                 localStorage.getItem('roleName' ) == User.ADMIN|| !!this.state.operatorIds && this.state.operatorIds != "") ? {display:"none"}:{ display:'block', color: 'green', fontFamily: 'Open sans-serif', transform: 'scale(1.2)', width: 48}}
                                // style={{ color: 'green', fontFamily: 'Open sans-serif', transform: 'scale(1.2)', width: 48 }}
                                    onClick={event =>this.handleCheckboxClick(event, load.loadId ,load.propertyId )}
                                    value={this.state.checkValue}
                                    // checked={this.state.checkAllValue == true? this.state.checkValue: }
                                 
                                />
                              }  
                             
                                </TableCell>
                             }
                            else{
                                return <TableCell style={{lineHeight:'56px',alignItems:'right'}} className={"white-space"} >{ load[col.accessor]}</TableCell>
                            }

                                
                            })
                        }
                    </TableRow>
                ));
                
            }
            loads = this.props.loads.map(load => 
            {
             const id=load.loadId
             return<TableRow key={load.id}>
                 {array2.map(col => {
                        if(col.accessor == 'photo'){
                            if(load['photo']){
                            // return <TableCell><img src={load['photo']} style={{height:'1%'}}/></TableCell>
                            return <TableCell style={{lineHeight:'56px',alignItems:'right'}}> <a className="email_link" href={load['photo']} target="_blank">View</a></TableCell>
                        }
                        else{
                            return <TableCell style={{lineHeight:'56px',alignItems:'right'}}></TableCell>
                        }
                        // else if(col.accessor == 'dateTime'){
                        //     const updatedAtDate = moment(load[col.accessor]).format('DD-MMM-YYYY hh:mm a');
                        //     return <TableCell>{updatedAtDate}</TableCell>
                        // }
                    }
                    else if(col.accessor == 'area'){
                       return <TableCell>{ (load['area'] && load['area'] != ' ') ? load['area'] : load['area_tn']}</TableCell>
                      }
                    else if(col.accessor == 'ulbName'){
                       return <TableCell style={{lineHeight:'56px'}} className={"white-space"} >{ (load['ulbName'] && load['ulbName'] != ' ') ? load['ulbName'] : load['ulbName_tn']}</TableCell>
                                       }
                    else if(col.accessor == 'operatorName'){
                        return <TableCell>{ (load['operatorName'] && load['operatorName'] != ' ') ? load['operatorName'] : load['operatorName_tn']}</TableCell>
                        }
                    else if(col.accessor == 'propertyName'){
                        return <TableCell style={{lineHeight:'56px'}} className={"white-space"} >{ (load['propertyName'] && load['propertyName'] != ' ') ? load['propertyName'] : load['propertyName_tn']}</TableCell>
                    }
                    // else if(col.accessor=='propertyIsSlum'){
                    //     if(load['propertyIsSlum'] == true)
                    //     {
                    //         return <TableCell>Yes</TableCell>
                    //     }
                    //     else if(load['propertyIsSlum'] == false)
                    //     {
                    //         return <TableCell>No</TableCell>
                    //     }else{
                    //         return <TableCell>load['propertyIsSlum']</TableCell>
                    //     }
                    // }
                    else{
                            return <TableCell style={{lineHeight:'56px',alignItems:'right'}} className={"white-space"}>{ load[col.accessor]}</TableCell>}
                        })
                    }
                   
                    
                    {(localStorage.getItem('roleName' ) == User.STATEDASHBOARDUSER || localStorage.getItem('roleName' ) == User.ADMIN) ? '' :   <TableCell>  
                        <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            id='not-loads'
                            onClick={(e)=>this.handleClickMoreVertIcon(e,id)}
                            >
                            <MoreVertIcon />
                            </IconButton>
                             <Popper
                             open={this.state.open_icon} anchorEl={this.state.anchorEl_icon} transition>
                                    <ClickAwayListener onClickAway={this.handleClickAwayEdit}>
                                <Paper  style={{boxShadow:"none", border:"1px solid #3dab35"}}>
                                    <MenuList>
                                         <Link to={{ pathname:`/record_edit/${this.state.id}`, state:{ pageR:this.state.page, limitR:this.state.limit, operatorIdR: this.state.operatorIds, queryR:this.state.query,fromDateR:moment(this.state.fromdate).format('YYYY-MM-DD 00:00'),ulbIdR:this.props.match.params.id == 'all' ? '' : this.props.match.params.id,toDateR:moment(this.state.todate).format('YYYY-MM-DD 23:59'), sortR:`${this.state.sortObj.key}${this.state.sortObj.value}`, filterR:JSON.stringify([this.state.filter])}}} className='email_link'> <MenuItem>Edit</MenuItem></Link>
                                         <MenuItem style = {{color : '#3dab35'}} onClick = {this.handleDeleteRecord}>Delete</MenuItem>
                                    </MenuList>
                                </Paper>
                                </ClickAwayListener>
                            </Popper>
                        </TableCell>}
                  
                </TableRow> 
             
            });
         
        }
        const fixedCols =  array1.map(col => {  
            if(col.accessor == "checkbox"){
                col.header = <div className="check-class">
             <Checkbox  
             style={(localStorage.getItem('roleName' ) == User.STATEDASHBOARDUSER ||
             localStorage.getItem('roleName' ) == User.ADMIN|| !!this.state.operatorIds && this.state.operatorIds != "") ? {display:"none"}:{ display:'block', color: 'green', fontFamily: 'Open sans-serif', transform: 'scale(1.2)', width: 48, marginLeft:'12px'}}
                
                onClick={(event) => this.handleClickRow(event, col)}
                    checked={this.state.checkAllValue}
              
                />

                </div> 
        }
        return <TableCell  className={"white-space"}>
            <div style={{
                            display: 'flex',
                            alignItems: 'start',
                            whiteSpace: 'break-spaces',
                            height:'3em',
                        
                        }}>
                    <div onClick={(e) =>  this.handleSort(e,col)}>
                        {col.accessor == "checkbox" ? "" :
                        <div>{
                           this.state.currentSortId===col.accessor? this.state.sortObj.value ==='-asc'?(<ArrowDropDownIcon fontSize="small"/>):(<ArrowDropUpIcon fontSize="small"/>) :(<ArrowDropDownIcon fontSize="small"/>)
                        }
                        </div>  
        } 
                    </div>
                    {col.header}
                    </div>
                </TableCell>
        
          
        }
        );
       
        const cols =  array2.map(col => {

            let filter; 
            if (col.filter === 'true') {
            filter = 
            <select 
            style={{
                width: '16px',
                borderColor:"white",
                backgroundColor: 'LightGray',
                outline:"none",
                display: "flex",
                alignItems: 'right',   
              }}
            value={filterValue}
            onChange={(e) => {
                this.onClickGetFilterVals(e.target.value || undefined, col)
            }}
            >
                <option value="">All</option>
                {col.filterKeys.map((option, i) => (
                <option key={i} value={option.key}>
                    {option.key}
                </option>
                ))}
            </select>
            }   
          
            let filter2; 
            if (col.filter === 'true') {
            filter2= 
            <select 
            style={{
                width: '16px',
                borderColor:"white",
                backgroundColor: 'LightGray',
                outline:"none",
                display: "flex",
                alignItems: 'right',   
              }}
            value={filterValue}
            onChange={(e) => {
                this.onClickGetFilterVals(e.target.value || undefined, col)
            }}
            >
              <option value="">All</option>
                {col.filterKeys.map((option, i) => (
                <option key={i} value={option.key}>
                    {option.key}
                </option>  
                ))}
            </select>
            } 
          
            return <TableCell  className={"white-space"}>
                        <div style={ col.accessor === "currentLoadNumber" ? {
                                display: 'flex',
                                alignItems: 'start',
                                height:'3em',
                                // paddingLeft:'10px',
                                // paddingRight:'20px',
                                whiteSpace:'break-spaces',
                                width:'80px'
                            }:{
                                display: 'flex',
                                alignItems: 'start',
                                height:'3em',
                                // paddingLeft:'10px',
                                paddingRight:'25px',
                                whiteSpace:'break-spaces'
                            }}>
                        {col.sortable == 'false' &&
                         <div onClick={(e) =>  this.handleSort(e,col)}>
                            {col.accessor == "serviceStatus" ? "" : 
                         <div>{
                             this.state.currentSortId===col.accessor? this.state.sortObj.value ==='-asc'?(<ArrowDropDownIcon fontSize="small"/>):(<ArrowDropUpIcon fontSize="small"/>) :(<ArrowDropDownIcon fontSize="small"/>)
                         }
                         </div>  
                         }
                     </div> 
                        }
                        {col.header}    
                        {this.state.filterBy===col.accessor && this.state.isAll===false? filter2 : filter} 
                        </div> 
                    </TableCell>
            }
            )

            const UserIdSave = localStorage.getItem("UserIdSave")
            let payload = {
                token: this.props.token,
                page:  this.state.page,
                limit: this.state.limit,
                sort: '',
                filter: JSON.stringify([this.state.filter]),
                query: this.state.query,
                fromDate:moment(this.state.fromdate).format('YYYY-MM-DD 00:00'),
                toDate:moment(this.state.todate).format('YYYY-MM-DD 23:59'),
                ulbId:this.props.match.params.id == 'all' ? '' : this.props.match.params.id,
                operatorId: this.state.operatorIds
            }
    return (
            <div>
                <div className="dashboard_right_content" style={{marginTop:'-4%'}}>
                    <img className="dashboard_right_content_top_icon" src="images/Rectangle.svg" />
                    <div className="dashboard_right_content_top">
                   {localStorage.getItem('roleName' ) == User.STATEDASHBOARDUSER || (localStorage.getItem('roleName' ) == User.SANITARYINSPECTOR&&UserIdSave)?  <h5><Link to = "/" className="email_link"><ArrowBackIosIcon></ArrowBackIosIcon></Link>Loads Records</h5>: localStorage.getItem('roleName') == User.ADMIN ? 
                   <h5><Link to = "/" className="email_link"><ArrowBackIosIcon></ArrowBackIosIcon></Link>Loads Records</h5> 
                   : !!this.state.operatorIds && this.state.operatorIds != ""?  <h5><Link to = "/operator-management" className="email_link"><ArrowBackIosIcon></ArrowBackIosIcon></Link>Loads Records</h5> :<h5>Loads Records</h5>} 
                    </div>
                        <div className="dashboard_right_content_top_options">
                            <div className="dashboard_right_content_top_search">
                                <Paper component="form">
                                    <IconButton aria-label="menu">
                                        <img src="images/search_icon.svg" />
                                    </IconButton>
                                    <InputBase value = {this.state.searchQuery} onChange={(event) => this.inputChangedHadler(event, 'search')} placeholder="Property Name" />
                                    <Button onClick={this.onClickSearch} variant="contained" color="primary" type="submit">Search</Button>
                                </Paper>
                            </div>
                           
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>

                                <span style={{fontSize: 'small',fontFamily: 'Open Sans, sans-serif'}}>&nbsp;&nbsp;&nbsp;&nbsp;</span> 
                                <DatePicker
                                        variant="inline"
                                        // inputVariant="outlined"
                                        value={this.state.fromdate}  
                                        onChange={this.setFromDate}
                                        disableFuture
                                        format='dd MMM YYY'
                                        autoOk
                                        style={{width:'10%', borderBottom: 'none'}}
                                        InputProps={{ style:{fontSize:'small', padding:'4px' ,height:'35px',marginTop:'2px',color: '#3DAB35',border: '1px solid #3DAB35', 
                                        borderRadius: '4px', fontFamily: 'Open Sans, sans-serif'},disableUnderline: true}}/>
                                 <span style={{fontSize: 'small',fontFamily: 'Open Sans, sans-serif'}}>&nbsp;&nbsp;to&nbsp;&nbsp;</span> 
                                        <DatePicker
                                        variant="inline"
                                        // inputVariant="outlined"
                                        value={this.state.todate} 
                                        onChange={this.setToDate} 
                                        disableFuture
                                        minDate={this.state.fromdate}
                                        format='dd MMM YYY'
                                        autoOk
                                        style={{width:'10%'}}
                                        InputProps={{ style:{fontSize:'small', padding:'4px' ,height:'35px',marginTop:'2px',color: '#3DAB35',border: '1px solid #3DAB35', 
                                        borderRadius: '4px', fontFamily: 'Open Sans, sans-serif'}, disableUnderline: true}}/>
                               </MuiPickersUtilsProvider>     
                                {/* {this.state.isFilter ? */}
                              {/* {!!this.state.operatorIds && this.state.operatorIds==0 ?  <Button onClick={this.onClickRemoveFilter}>Reset</Button> :this.state.id == ""? : ""}   */}
                             {!!this.state.operatorIds && this.state.operatorIds != ""? "": <Button onClick={this.onClickRemoveFilter}>Reset</Button>} 
                            {/* :<Button onClick={this.onClickApplyFilter}>Filter</Button>} */}
                            <Button  onClick={this.onClickExport} disabled= {this.props.exportEnabled}>Export</Button>
                            {/* <InfoOutlinedIcon onClick={this.handleClickOpen} style={{width:'50px',height:'24px',color: "gray", cursor: "pointer"}}></InfoOutlinedIcon> */}
                            <Button
                            className="email_link"
                                ref={this.state.anchorRef}
                                aria-controls={this.state.open ? 'menu-list-grow' : undefined}
                                aria-haspopup="true"
                                onClick={this.handleClick('bottom')}
                                style={(localStorage.getItem('roleName' ) == User.STATEDASHBOARDUSER || localStorage.getItem('roleName' ) == User.ADMIN || !!this.state.operatorIds && this.state.operatorIds != "") ? {display:"none"}:{height:'35px', display:'block'}}

                            >
                                Bulk Import
                                {
                                    this.state.open ?   <ArrowDropUpIcon /> : <ArrowDropDownIcon/> 
                                }
                                
                            </Button>
                            
                            <Popper open={this.state.open} anchorEl={this.state.anchorEl} placement={this.state.placement} transition>
                            {({ TransitionProps }) => (
                                    <ClickAwayListener onClickAway={this.handleClickAwayBulk}>
                                <Paper>
                                    <MenuList>
                                    <MenuItem onChange={this.onFileChange}>
                                        <label style={{ color:'#3dab35',fontSize:'small',align:'left', fontFamily: "'Open Sans', sans-serif"}}> 
                                            <input type="file" style={{display:'none',align:'left'}} /> 
                                            Bulk Upload
                                        </label> 
                                        </MenuItem>     
                                    <a href={!!this.props.downloadSample&&this.props.downloadSample.map((item)=> item.SampleFileValidation === 'Config1'|| item.SampleFileValidation === null? Config1 : Config2)} style={{textDecoration:'none', 
                                                            color:'#3dab35', 
                                                            fontSize:'small', 
                                                            fontFamily: "'Open Sans', sans-serif"}} download> 
                                        <MenuItem onClick={this.handleClose}>Download Sample</MenuItem>
                                    </a>
                                    </MenuList>
                                </Paper>
                                </ClickAwayListener>
                            )}
                            </Popper>
                               
                            <InfoOutlinedIcon onClick={this.handleClickOpen} style={(localStorage.getItem('roleName' ) == User.STATEDASHBOARDUSER ||
                                localStorage.getItem('roleName' ) == User.ADMIN) ? {display:"none"}:{ display:'block',width:'50px',height:'24px',color: "gray", cursor: "pointer"}
                                }></InfoOutlinedIcon>



                                    <React.Fragment>
                                    <Link className="email_link" to='/email_manage'  style={(localStorage.getItem('roleName' ) == User.STATEDASHBOARDUSER ||
                                        localStorage.getItem('roleName' ) == User.ADMIN|| !!this.state.operatorIds && this.state.operatorIds != "") ? {display:"none"}:{ display:'block'}} ><Button className="emailMargin">Email</Button></Link>

                                    </React.Fragment>
                         {this.state.selected.length>0 ?  <div>
                            <Button onClick={()=>this.handleOpenSameEdit()}>Bulk Edit</Button>
                                     {/* <Link  style={(localStorage.getItem('roleName' ) == User.STATEDASHBOARDUSER ||
                                        localStorage.getItem('roleName' ) == User.ADMIN|| !!this.state.operatorIds && this.state.operatorIds != "") ? {display:"none"}:{ display:'block'}} to={{ pathname:`/SameLoads`, state:{selectedLoads:this.state.selected}}}><Button>Loads Edit </Button></Link>    */}
                                    </div>:""}
                                   

                          {/* <Button onClick={()=>this.openUploadMulitpleLoads(true)} >Update multiple Loads</Button> */}
                     
                        </div>
                    {this.props.loadingbulk == true || this.state.bulkUp == false  ? "":          
                     <div>
                    <MuiThemeProvider theme={theme}>
                         <LinearProgressWithLabel color="secondary" value={this.props.progressPercentage} /> 
                    </MuiThemeProvider>
                </div> 
               }
                    </div>
                    <div className="mobile-load-record">
                        <Grid container spacing={2}>
                                <Grid item xs={12}>
                                <img className="dashboard_right_content_top_icon" src="images/Rectangle.svg" />
                                    <div className="dashboard_right_content_top">
                                {localStorage.getItem('roleName' ) == User.STATEDASHBOARDUSER?  <h5><Link to = "/" className="email_link"><ArrowBackIosIcon></ArrowBackIosIcon></Link>Loads Records</h5>: 
                                localStorage.getItem('roleName' ) == User.ADMIN?  <h5><Link to = "/state-dashboard" className="email_link"><ArrowBackIosIcon></ArrowBackIosIcon></Link>Loads Records</h5>: <h5>Loads Records</h5>} 
                                    </div>
                                </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                                <Grid xs={8}>
                                <div className="dashboard_right_content_top_search">
                                <Paper component="form">
                                    <IconButton aria-label="menu">
                                        <img src="images/search_icon.svg" />
                                    </IconButton>
                                    <InputBase value = {this.state.searchQuery} onChange={(event) => this.inputChangedHadler(event, 'search')} placeholder="Property Name" />
                                    <Button onClick={this.onClickSearch} variant="contained" color="primary" type="submit">Search</Button>
                                </Paper>
                            </div>
                                </Grid>
                                <Grid xs={4}>
                                <div className="dashboard_right_content_top_options">
                                <Button onClick={this.onClickRemoveFilter}>Reset</Button> 
                                </div>
                                </Grid>
                        </Grid>

                        <Grid container spacing={2}> 
                                <Grid item xs={8}>
                                    <div style={{marginTop:'20px'}}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>

                                    {/* <span style={{fontSize: 'small',fontFamily: 'Open Sans, sans-serif'}}>&nbsp;&nbsp;&nbsp;&nbsp;</span>  */}
                                    <DatePicker
                                            variant="inline"
                                            // inputVariant="outlined"
                                            value={this.state.fromdate}  
                                            onChange={this.setFromDate}
                                            disableFuture
                                            format='dd/MM/yyyy'
                                            autoOk
                                            style={{width:'43%', borderBottom: 'none', marginTop:'-2px'}}
                                            InputProps={{ style:{fontSize:'small', padding:'4px' ,height:'40px',color: '#3DAB35',border: '1px solid #3DAB35', 
                                            borderRadius: '4px', fontFamily: 'Open Sans, sans-serif'},disableUnderline: true}}/>
                                    <span style={{fontSize: 'small',fontFamily: 'Open Sans, sans-serif'}}>&nbsp;&nbsp;to&nbsp;&nbsp;</span> 
                                            <DatePicker
                                            variant="inline"
                                            // inputVariant="outlined"
                                            value={this.state.todate} 
                                            onChange={this.setToDate} 
                                            disableFuture
                                            minDate={this.state.fromdate}
                                            format='dd/MM/yyyy'
                                            autoOk
                                            style={{width:'43%', marginTop:'-2px'}}
                                            InputProps={{ style:{fontSize:'small', padding:'4px' ,height:'40px',color: '#3DAB35',border: '1px solid #3DAB35', 
                                            borderRadius: '4px', fontFamily: 'Open Sans, sans-serif'}, disableUnderline: true}}/>
                                    </MuiPickersUtilsProvider>   
                                    </div>
                               

                                </Grid>
                                <Grid xs={4}>
                                <div style={{marginTop:'25px'}}>
                                <div className="dashboard_right_content_top_options">
                                <Button  onClick={this.onClickExport} disabled= {this.props.exportEnabled}>Export</Button>
                                </div>
                               </div>
                                </Grid>

                        </Grid>
                    </div>
                    <div className="scroltable-mobile "> 
                    <Grid container direction="row">
                         <Grid items xs={6} lg={3}>
                             <div className="dashboard_listing " >
                                <TableContainer component={Paper} style={{overflow:"hidden",paddingLeft:"15px"}}>
                                    <Table aria-label="simple table" style={{ tableLayout: 'fixed' }}>
                                                <colgroup>
                    +                                <col style={{width:'4rem'}}/>
                    +                                <col style={{width:'4rem'}}/>
                    +                                <col style={{width:'8rem'}}/>
                   {/* +                                 <col style={{width:'10%'}}/> */}
                    +                            </colgroup>
                                        <TableHead>
                                            <TableRow>
                                                {fixedCols}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                                {fixedLoads}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                         </Grid>
                         <Grid items xs={6} lg={9}>
                             <div className="dashboard_listing" >
                                 <TableContainer component={Paper} style={{paddingLeft:"0px" ,paddingRight:"15px"}}>
                                 <Table aria-label="simple table" >

                                        <TableHead>
                                            <TableRow >
                                            {cols} 
                                            {(localStorage.getItem('roleName' ) == User.STATEDASHBOARDUSER || localStorage.getItem('roleName' ) == User.ADMIN) ? '' :<TableCell style={{paddingTop:'0px'}}>Action</TableCell> }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody> 
                                            {loads}
                                        </TableBody>
                                    </Table>
                                 </TableContainer> 
                             </div>
                         </Grid>
                     </Grid>
                    </div>
                <ReactPaginate
                    previousLabel={"Prev"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.props.totalCount/this.state.limit}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    forcePage = {this.state.activePage}
                    style={{borderLeftWidth:'0px'}}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}/>



            <Dialog onClose={this.handleCloseInfo} aria-labelledby="customized-dialog-title" open={this.state.openInfo}  
                fullWidth={true}  maxWidth={'sm'}>
                        <DialogTitle className="border-none"  id="customized-dialog-title" onClose={this.handleCloseInfo} >
                        Mandatory Fields
                        </DialogTitle>
                        <DialogContent>
                    <div className="paramDiv" style={{display:"flex", justifyContent:"space-between", padding:" 2% 25%"}}>
                            <div >
                              <h4 style={{color: "#3DAB35", fontFamily:"Arial, Helvetica, sans-serif"}}>Fields</h4>
                              <p className="valueOf-para">1.Facility</p>
                              <p className="valueOf-para">2.Date</p>
                              <p className="valueOf-para">3.Truck</p>
                              <p className="valueOf-para">4.ULB</p>
                              <p className="valueOf-para">5.Property Type</p>
                             
                            </div>
                    </div>
                        </DialogContent>
                      </Dialog>


                      <SameLoads selectedData={this.state.selected} selectedPropertyData= {this.state.selectedPropertyId} selectedPropertyType={this.state.propertyIdTypeId} openSameload={this.state.openUploadMulitpleLoadsPopUp} handleClose = {this.handleDialogClose} loadListing={payload}/>
                </div>






    );
    }
}

const mapStateToProps = state => {
    let exportEnabled = state.loads.length !==0 ? false : true
    return {
        exportEnabled : exportEnabled,
        loads: state.loads.loads,
        loading: state.loads.loading,
        token: state.auth.token,
        totalCount: state.loads.totalCount,
        headers:state.headers,
        downloadSample : state.loads.configlist,
        loadingCluster: state.loads.loading,
        progressPercentage : !!state.loads&&state.loads.progress,
        loadingbulk: state.loads.loadingbulk

    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchLoads: (payload) => dispatch(actionsCreators.fetchLoads(payload)),
        onFetchExports: (payload) => dispatch(actionsCreators.fetchExports(payload)),
        onFetchHeaders: (payload) => dispatch(actionsCreators.fetchHeaders(payload)),
        onFilterLoads: (payload) => dispatch(actionsCreators.filterLoads(payload)),
        onfetchBulkImport: (payload,formData) => dispatch(actionsCreators.fetchBulkImport(payload,formData)),
        onDeleteRecords :(payload,props) => dispatch(actionsCreators.deleteRecords(payload,props)),
        // OnfetchLoadsPage : (payload)=> dispatch(actionsCreators.fetchLoadsPage(payload))
        onDownloadSampleFile :()=> dispatch(actionsCreators.DownloadSampleFile()),
        FetchCollectioRequired : (payload, props)=>dispatch(actionsCreators.isCollectioRequired(payload, props)),
        onSameLoadsListing: (payload) => dispatch(actionsCreators.sameLoadsListing(payload)),

    }
}

export default  connect(mapStateToProps, mapDispatchToProps)(RecordKeeper, axios);
