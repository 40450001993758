import * as actionTypes from '../actionsTypes';
import axios from '../../../http-config';

export const fetchAreaHeadersSuccess = (response) => {
    return { 
        type: actionTypes.FETCH_PROPERTYHEADERS_SUCCESS,
        AreaHeaders:response.data.headers   
    }
}

export const fetchAreaHeadersFail = (error) => {
    return {
        type: actionTypes.FETCH_PROPERTYHEADERS_FAIL,
        error:error
    }
}

export const fetchAreaHeadersStart = () => {
    return {
        type: actionTypes.FETCH_PROPERTYHEADERS_START
    }
}

export const fetchAreaHeaders = (payload) => {
    let token = payload.token;
    return dispatch => {
        dispatch(fetchAreaHeadersStart());
        axios({
            method: 'get',
            url: '/area/headers',
            headers: {'Authorization': `Bearer ${token}`}
        })
        .then((response) => {
            const fetchedAreaHeadersResponse =response.data    
            dispatch(fetchAreaHeadersSuccess(fetchedAreaHeadersResponse));
        }).catch(error => {
            dispatch(fetchAreaHeadersFail(error));
        })

    }
}
