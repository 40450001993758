import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import * as actionsCreators from '../../store/actions/index'
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import swal from 'sweetalert'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import moment from 'moment'
import InputBase from '@material-ui/core/InputBase';
import ReactPaginate from 'react-paginate';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { Grid, } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import ImageUploader from 'react-images-upload';
import CancelIcon from '@material-ui/icons/Cancel';
const options = [
    {
        id    : "1",
        name  : "Corporation",
    }, {
        id    : "2",
        name  : "Town Panchayat",
    }, {
        id    : "3",
        name  : "Village Panchayat",
    }, {
        id    : "4",
        name  : "Municipality"
    }
  ];

export class UlbListing extends Component {
    state = {
        anchorEl: null,
        open: false,
        id: '',
        page: 1,
        limit: 10,
        selected: [],
        currentSortId:'asc',
        sortObj : {
            key: '',
            value: ''
        },
        controls: {
            search: {
                value: '',
            }
        },
        filterValue:'',
        filterTypesValue:'',
        StatusType:'',

        editId:"",
        Uulbname:'', 
        UuserId:'',
        Uname_tn:'',
        Uemail:'',
        Uusername:'',
        UtypeId:'',
        UoverallLoadNumberPrefix:'',
        UreceiptNumberPrefix:'', 
        Ulogo:'',
        UisZoneNotRequired:'',
        UisDisableULB:'',
        UstatedashboardValue:'', 
        UisRepeatCollection:'',
        Ucode:'',
        logo:'',
        openAddUlbPopup:false,


        ulbName                           : "",
        userId                                : "",
        ulbName_tm                             : "",
        ulbCode                                 : "",
        email                                  : "",
        adminName                        : "", 
        type                               : "",
        overallLoadNo         : "",
        receiptNo                  : "",
        img                    : {
            name               :  "",
        },
        ulbNameErr             : "",
        ulbCodeErr             : "",
        emailErr               : "",
        adminNameErr           : "",
        typeErr                : "",
        overallLoadNoErr       : "",
        receiptNoErr           : "",    
        imgErr                 : "" ,
        isLogo                  : false,

        isZoneRequire          : true,
        isStatus                : null,
        StatusValue            :false,
        statedashboardValue     : false,
        isRepeatCollection: false

    }
    componentDidMount() {
        const payload = {
            token: this.props.token,
            page: this.state.page,
            limit: this.state.limit,
            sortName:this.state.sortObj.key,
            sortValue :this.state.sortObj.value,
            searchQuery: this.state.controls.search.value,
            filterValue : this.state.filterValue,
            status: this.state.StatusType,
            filterTypesValue: this.state.filterTypesValue
        }
        this.props.onFetchUlbHeaders(payload)
        this.props.onFetchUlbData(payload)
    }

    //function to handle pagination
    handlePageClick = (e) => {
        const selectedPage = e.selected;
        this.setState({ page: selectedPage + 1 })
        let payload = {
            token: this.props.token,
            page: selectedPage + 1,
            limit: this.state.limit,
            sortName:this.state.sortObj.key,
            sortValue :this.state.sortObj.value,
            searchQuery: this.state.controls.search.value,
            filterValue : this.state.filterValue,
            status: this.state.StatusType,
            filterTypesValue: this.state.filterTypesValue
            
        }
        this.props.onFetchUlbData(payload)
    };

    handleClickMoreVertIcon = (event, id, Uulbname, UuserId, Uname_tn,Ucode,Uemail ,Uusername, UtypeId, UoverallLoadNumberPrefix, UreceiptNumberPrefix, Ulogo, UisZoneNotRequired, UisDisableULB, UstatedashboardValue, UisRepeatCollection) => {
        this.setState({ open: !this.state.open, anchorEl: event.currentTarget, id: id, Uulbname, UuserId, Uname_tn,Ucode,Uemail ,Uusername, UtypeId, UoverallLoadNumberPrefix, UreceiptNumberPrefix, Ulogo, UisZoneNotRequired, UisDisableULB, UstatedashboardValue, UisRepeatCollection })
    }
    handleClickAwayEdit = () => {
        this.setState({ open: false });
    };
    handleDelete = (id) => {
        const payload = {
            id: id,
            sortName:this.state.sortObj.key,
            sortValue :this.state.sortObj.value,
        }
        this.props.onDeleteUlbData(payload, this.props.token)
    }
    // isSelected = id => {
    //     return this.state.selected.indexOf(id) !== -1;
    // }
    // handleCheckboxClick = (event, id) => {
    //     event.stopPropagation();
    //     const statsvalue = JSON.parse(event.target.value)
    //     const { selected } = this.state;
    //     const selectedIndex = selected.indexOf(id);
    //     let newSelected = [];
    //     if (selectedIndex === -1) {
    //         newSelected = newSelected.concat(selected, id);
    //     } else if (selectedIndex === 0) {
    //         newSelected = newSelected.concat(selected.slice(1));
    //     } else if (selectedIndex === selected.length - 1) {
    //         newSelected = newSelected.concat(selected.slice(0, -1));
    //     } else if (selectedIndex > 0) {
    //         newSelected = newSelected.concat(
    //             selected.slice(0, selectedIndex),
    //             selected.slice(selectedIndex + 1)
    //         );
    //     }
    //     this.setState({ selected: newSelected });
    //     let payload = {
    //         id: id,
    //         statedashboardValue: statsvalue,
    //         token: this.props.token,
    //         page: this.state.page,
    //         limit: this.state.limit
    //     }
    //     this.props.onSelectULbs(payload)
    //     this.props.onFetchUlbData(payload)
    // };


    handleSort = (e,sortBy) => {
        let sortObj
        if (this.state.sortObj.key === sortBy.accessor) {
            let value = (this.state.sortObj.value === 'desc') ? 'asc' : 'desc';
            sortObj = {
                key : sortBy.accessor,
                value: value
            }
        } 
        else {
            sortObj = {
                key : sortBy.accessor,
                value: 'desc'
         }    
         }
 
        this.setState({ sortObj: sortObj ,currentSortId:sortBy.accessor});
        let payload = {
            token: this.props.token,
            sortName:sortObj.key,
         sortValue : sortObj.value,
         page: this.state.page,
         limit: this.state.limit,
         filterValue : this.state.filterValue,
         status: this.state.StatusType,
         filterTypesValue: this.state.filterTypesValue
        }

        this.props.onFetchUlbData(payload);
  
      }



      handlePropertySearch = (e) => {
        e.preventDefault()
        let searchVal = this.state.controls.search.value
        if (!!searchVal && searchVal.length >= 2) {
            let payload = {
                token: this.props.token,
                searchQuery:searchVal,
                limit:10,
                page:this.state.page,
                // propertyTypeId: this.state.filterValue,
                sortName:this.state.sortObj.key,
                sortValue :this.state.sortObj.value,
                filterValue : this.state.filterValue,
                status: this.state.StatusType,
                filterTypesValue: this.state.filterTypesValue
              
            }
            this.props.OnSearchULBName(payload) 
        } else {
         
            swal("Enter atleast 2 characters")
        }
    }
    inputChangedHadler = (event, controlName) => {
        const updatedControls = {
            ...this.state.controls,
            [controlName]: {
                ...this.state.controls[controlName],
                value: event.target.value,
            }
        }
        this.setState({ controls: updatedControls });
        if( updatedControls.search.value === ''){
            let payload = {
                token: this.props.token,
                searchQuery:'',
                limit:this.state.limit,
                page:this.state.page,
                // propertyTypeId: this.state.filterValue,
                sortName:this.state.sortObj.key,
                sortValue :this.state.sortObj.value,
                filterValue : this.state.filterValue,
                status: this.state.StatusType,
                filterTypesValue: this.state.filterTypesValue
            }
            this.props.OnSearchULBName(payload) 
        }
   
    }

    onClickGetFilterVals = (e, colObj) => {
        let payload = {
            token: this.props.token,
            page: this.state.page,
            limit: this.state.limit,
            sortName:this.state.sortObj.key,
            sortValue :this.state.sortObj.value,
            searchQuery: this.state.controls.search.value,
            filterValue:e
        }
        this.setState({filterValue: e,})
       
        this.props.onFetchUlbData(payload)
       }  
       onClickGetFilterValsType=(e, obj)=>{
        let payload = {
            token: this.props.token,
            page: this.state.page,
            limit: this.state.limit,
            sortName:this.state.sortObj.key,
            sortValue :this.state.sortObj.value,
            searchQuery: this.state.controls.search.value,
            filterTypesValue:e
        }
        this.setState({ filterTypesValue:e})

        this.props.onFetchUlbData(payload)
       }
       onClickGetFilterValsStatus=(e )=>{
        this.setState({StatusType: e})
        let payload = {
            token: this.props.token,
            page: this.state.page,
            limit: this.state.limit,
            sortName:this.state.sortObj.key,
            sortValue :this.state.sortObj.value,
            searchQuery: this.state.controls.search.value,
            status: e
        }
        this.props.onFetchUlbData(payload)
        // this.props.onFetchPropertyHistoryList(payload)
    }
    handleExport = () => {
        let payload = {
            token: this.props.token,
            page: this.state.page,
            limit: this.state.limit,
            sortName:this.state.sortObj.key,
            sortValue :this.state.sortObj.value,
            searchQuery: this.state.controls.search.value,
            filterValue : this.state.filterValue,
            status: this.state.StatusType,
            filterTypesValue: this.state.filterTypesValue
      
        }
        this.props.onFetchULBListingExport(payload)
    }
    openUlbAddPopup =()=>{
        this.setState({openAddUlbPopup:true,
            ulbName                           : "",
            userId                             : "",
            ulbName_tm                             : "",
            ulbCode                                 : "",
            email                                  : "",
            adminName                        : "", 
            type                               : "",
            overallLoadNo         : "",
            receiptNo                  : "",
            img                    : {
                name               :  "",
            },
            isLogo                  : false,

            isZoneRequire          : true,
            isStatus                : null,
            StatusValue            :false,
            statedashboardValue     : false,
            isRepeatCollection: false,
            editId:''
    })
            }
    openEditUlbPopup =(id, Uulbname, UuserId, Uname_tn, Ucode,Uemail, Uusername, UtypeId, UoverallLoadNumberPrefix, UreceiptNumberPrefix, Ulogo, UisZoneNotRequired, UisDisableULB, UstatedashboardValue, UisRepeatCollection, )=>{
        this.setState({
            openAddUlbPopup:true,
            editId:id,
            ulbName                : Uulbname ,
            userId                 :  UuserId,
            ulbName_tm             :Uname_tn,
            ulbCode                :  Ucode,
            email                  :  Uemail,
            adminName              :  Uusername, 
            type                   :  UtypeId,
            overallLoadNo         : UoverallLoadNumberPrefix,

            img                    : {
                name               :  Ulogo,
            },
            StatusValue             : UisDisableULB  === 'Active' ?false :true,
            statedashboardValue     : UstatedashboardValue ? UstatedashboardValue &&UstatedashboardValue === 'No' ? false : true: false,
            isRepeatCollection:UisRepeatCollection, 
            receiptNo                  : UreceiptNumberPrefix,
            isLogo                  : true,
        })
        
    }
    handleClose=()=>{
        this.setState({openAddUlbPopup:false,
            receiptNo:"",
            editId:'',
        ulbNameErr             : "",
        ulbCodeErr             : "",
        emailErr               : "",
        adminNameErr           : "",
        typeErr                : "",
        overallLoadNoErr       : "",
        receiptNoErr           : "",    
        imgErr                 : "" ,
        statedashboardValueErr     : '',
        StatusValueErr           : '',
        ulbName                           : "",
        userId                                : "",
        ulbName_tm                             : "",
        ulbCode                                 : "",
        email                                  : "",
        adminName                        : "", 
        type                               : "",
        overallLoadNo         : "",
        receiptNo                  : "",
        img                    : {
            name               :  "",
        },
        isLogo                  : false,

        isZoneRequire          : true,
        isStatus                : null,
        StatusValue            :false,
        statedashboardValue     : false,
        isRepeatCollection: false,
    
    
    })  
        
    }

    handleChange = (event) =>{
        if(event.target.name == 'overallLoadNo' || event.target.name == 'receiptNo'){
                const overallLoadNo = event.target.value.toUpperCase()
                const receiptNo = event.target.value.toUpperCase()
                this.setState({[event.target.name]:overallLoadNo ,[event.target.name]: receiptNo}, ()=> {
                    this.checkValidity()
                })
        }
        else{
            this.setState({[event.target.name]:event.target.value}
                , ()=> {
                this.checkValidity()
            }
            )
        }
    }
    handleRemove=()=>{
     this.setState({img :{name :''},isLogo:false})
    }
    handleSubmit =()=>{
        console.log(this.state.StatusValue, "StatusValue");
        const {t, i18n} = this.props
        const err = this.checkValidity()
        let payload = {
            token: this.props.token,
            page: this.state.page,
            limit: this.state.limit,
            sortName:this.state.sortObj.key,
            sortValue :this.state.sortObj.value,
            searchQuery: this.state.controls.search.value,
            filterValue : this.state.filterValue,
            status: this.state.StatusType,
            filterTypesValue: this.state.filterTypesValue
        }
        if(!err)
        {
            if(this.state.editId !=""){
                const formdata = new FormData()
                this.state.id && formdata.append("id",this.state.id)
                this.state.logo ? formdata.append("filename",this.state.img['name']) : (this.state.img['name'] ? formdata.append("filename",this.state.img,this.state.img['name']) : formdata.append("filename",'') )
                formdata.append("userId" ,this.state.userId)
                formdata.append("name", this.state.ulbName)
                formdata.append("name_tn", this.state.ulbName_tm)
                formdata.append("code", this.state.ulbCode)
                formdata.append("email",this.state.email)
                formdata.append("username", this.state.adminName)
                formdata.append("typeId", this.state.type)
                formdata.append("overallLoadNumberPrefix",this.state.overallLoadNo)
                formdata.append("receiptNumberPrefix",this.state.receiptNo)
                // formdata.append("isZoneNotRequired", this.state.isZoneRequire)
                formdata.append("isDisableULB", this.state.StatusValue)
                // formdata.append("isRepeatCollection", this.state.isRepeatCollection)
                formdata.append("statedashboardValue", this.state.statedashboardValue)
                // let SubmitProp=this.props
                this.props.onEditUlbData(formdata, this.props, payload)
                
                this.setState({openAddUlbPopup:false})
            }
            else{
                const formdata = new FormData()
                this.state.id && formdata.append("id",this.state.id)
                this.state.logo ? formdata.append("filename",this.state.img['name']) : (this.state.img['name'] ? formdata.append("filename",this.state.img,this.state.img['name']) : formdata.append("filename",'') )
                formdata.append("userId" ,this.state.userId)
                formdata.append("name", this.state.ulbName)
                formdata.append("name_tn", this.state.ulbName_tm)
                formdata.append("code", this.state.ulbCode)
                formdata.append("email",this.state.email)
                formdata.append("username", this.state.adminName)
                formdata.append("typeId", this.state.type)
                formdata.append("overallLoadNumberPrefix",this.state.overallLoadNo)
                formdata.append("receiptNumberPrefix",this.state.receiptNo)
                // formdata.append("isZoneNotRequired", this.state.isZoneRequire)
                formdata.append("isDisableULB", this.state.StatusValue)
                // formdata.append("isRepeatCollection", this.state.isRepeatCollection)
                formdata.append("statedashboardValue", this.state.statedashboardValue)
                // let SubmitProp=this.props
                this.props.onAddUlbData(formdata, this.props, payload)
                
        this.setState({openAddUlbPopup:false})
            }       
        }
    }
    checkValidity = () => {
        let isError = false
        if(!this.state.ulbName){
            isError=true
            this.setState({ulbNameErr : '*This field is required'})
        }
        else if((this.state.ulbName.length > 0 && this.state.ulbName.length <3 ) || (this.state.ulbName.length > 100)){
            isError=true
            this.setState({ulbNameErr : '*Name should be Minimum 3 characters and Maximum 100 characters'})
        }
        else if(this.state.ulbName && this.state.ulbName.length>=3){
            this.setState({ulbNameErr:''})
        }
        if(!this.state.adminName){
            isError=true
            this.setState({adminNameErr:'*This field is required'})
        }
        else if((this.state.adminName.length > 0 && this.state.adminName.length <3) || (this.state.adminName.length > 25)){
            isError=true
            this.setState({adminNameErr : '*Name should be Minimum 3 characters and Maximum 25 characters'})
        }
        else{
            this.setState({adminNameErr:''})
        }
        if(!this.state.ulbCode){
            isError=true
            this.setState({ulbCodeErr:'*This field is required'})
        }
        else if((this.state.ulbCode.length > 0 && this.state.ulbCode.length <3) || (this.state.ulbCode.length > 6)){
            isError=true
            this.setState({ulbCodeErr : '*Code should be Minimum 3 characters and Maximum 6 characters'})
        }
        else{
            this.setState({ulbCodeErr:''})
        }
        if(this.state.email){
            var mailformat= /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
            if(!this.state.email.match(mailformat)){
                isError=true
                this.setState({emailErr:'*You have entered invalid email address'})
            }
            else{
                this.setState({emailErr:''})
            }
        }
        else if(!this.state.email){
            isError=true
            this.setState({emailErr:'*This field is required'})
        }
        if(!this.state.type){
            isError=true
            this.setState({typeErr : "*This field is required"})
        }
        else{
            this.setState({typeErr:''})
        }
        if(!this.state.overallLoadNo){
            isError=true
            this.setState({overallLoadNoErr : "*This field is required"})
        }
        else{
            this.setState({overallLoadNoErr: ''})
        }
        if(!this.state.receiptNo){
            isError=true
            this.setState({receiptNoErr : "*This field is required"})
        }
        else{
            this.setState({receiptNoErr : ""})
        }
        if(!this.state.img){
            isError=true
            this.setState({imgErr:'*This field is required'})
        }
        else{
            this.setState({imgErr:''})
        }
        return isError
    }
    handleImage = (e) => {
        this.setState({img:e[0],isLogo:true}, ()=> this.checkValidity())
    }
    isZonetRequired = (e)=>{
        this.setState({isZoneRequire:e.target.value })
    }

    handlChangeStatus =(e)=>{
        this.setState({isStatus: e.target.value , StatusValue :  e.target.value})
    }


    handleStatedashboardValue =(e)=>{
        this.setState({statedashboardValue: e.target.value})
    }
    isRepeatCollectionHandle =(e)=>{
        this.setState({isRepeatCollection: e.target.value})
    }
    onClickRemoveFilter = ()=>{
        const payload = {
            token: this.props.token,
            page:1,
            limit: this.state.limit,
            sortName:'',
            sortValue :'',
            filterValue :'',
            status: '',
            filterTypesValue: '',
        }
     
        this.setState({
            searchQuery:'', 
            controls:{
                search: {
                    value: '',
                         }
             },
            filterValue:'', 
            sortObj:{
                key: '',
                value: ''
        } })
        this.props.onFetchUlbData(payload)
    }
    render() {
        let filterValue;
        let filterTypesValue;
        let filterValueStatus;
        let headerUlb = this.props.ulbHeaders
        const ulbHeaders = !!headerUlb && headerUlb.map(header => {
            if(header.accessor == 'statedashboardValue'){
                  header.filter = true
            }
            if(header.accessor == 'type'){
                header.filter = true
          }
          if(header.accessor == "isDisableULB"){
            header.filter = true
        }  
            let filterType;
            let filtertypeData;
            let StatusType;
            if(header.filter == true && header.accessor == 'statedashboardValue'){
                filterType = 
                <select 
                style={{
                    width: '16px',
                    borderColor:"white",
                    backgroundColor: 'LightGray',
                    outline:"none",
                    display: "flex",
                    alignItems: 'right',   
                  }}
                value={filterValue}
                onChange={(e) => {
                    this.onClickGetFilterVals(e.target.value , header)
                }}
                >
                    <option value="">&nbsp;All&nbsp;</option>
                   
                    <option  value={false}>&nbsp;No&nbsp; </option>
                    <option  value={true}>&nbsp;Yes&nbsp; </option>
                
                </select>
            }

            if(header.filter === true&&header.accessor== "type"){
                filtertypeData = 
                <select 
                style={{
                    width: '16px',
                    borderColor:"white",
                    backgroundColor: 'LightGray',
                    outline:"none",
                    display: "flex",
                    alignItems: 'right',   
                  }}
                value={filterTypesValue}
                onChange={(e) => {
                    this.onClickGetFilterValsType(e.target.value , header)
                }}
                >
                    <option value="">&nbsp;All&nbsp;</option>
                   
                  
                    <option  value={1}>Corporation</option>
                    <option  value={4}>Municipality </option>
                    <option value={2}>Town Panchayat</option>
                    <option value={3}>Village Panchayat</option>
                    
                
                </select>
            }

            if(header.filter == true &&header.accessor == "isDisableULB" ){
                StatusType = 
            <select 
            style={{
                width: '16px',
                borderColor:"white",
                backgroundColor: 'LightGray',
                outline:"none",
                display: "flex",
                alignItems: 'right',   
              }}
            value={this.state.StatusType}
            onChange={(e) => {
                this.onClickGetFilterValsStatus(e.target.value, header)
            }}
            >           <option value="">All</option>
                       <option value={false}>Active</option>
                       <option value={true}>In Active</option>
                    
                </select>
         
            }

            return (
                <TableCell key={header.header} className={"white-space"}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'start',
                        whiteSpace: 'break-spaces',
                        height: '3em'
                    }}>
{ header.header === "Action"? "":
                    <div onClick={(e) =>  this.handleSort(e,header)}>
                        <div>{
                            this.state.currentSortId===header.accessor? this.state.sortObj.value ==='asc'?(<ArrowDropDownIcon fontSize="small"/>):(<ArrowDropUpIcon fontSize="small"/>) :(<ArrowDropDownIcon fontSize="small"/>)
                        }
                        </div>  
                    </div>}
                        {header.header}
                        {filterType}
                        {filtertypeData} 
                        {StatusType}
                    </div>
                </TableCell>
            )
        })
        const ulbData = this.props.ulbData.map((data, index) => {
            const id = data['ulbId']
            const Uulbname = data['name']
            const UuserId = data['userId']
            const Uname_tn = data['name_tn']
            const Ucode = data['code']
            const Uemail = data['email']
            const Uusername = data['username']
            const UtypeId = data['typeId']
            const UoverallLoadNumberPrefix = data['overallLoadNumberPrefix']
            const UreceiptNumberPrefix = data['receiptNumberPrefix']
            const Ulogo = data['logo']
            const UisZoneNotRequired = data['isZoneNotRequired']
            const UisDisableULB =data['isDisableULB']
            const UstatedashboardValue = data['statedashboardValue']
            const UisRepeatCollection =data['isRepeatCollection']
            return (
                <TableRow key={index}
                >
                    {this.props.ulbHeaders.map(header => {
                            if (header.accessor == 'actionButton') {
                                return <TableCell key={header.accessor}>
                                    <IconButton onClick={(e) => this.handleClickMoreVertIcon(e, id, Uulbname, UuserId, Uname_tn,Ucode,Uemail ,Uusername, UtypeId, UoverallLoadNumberPrefix, UreceiptNumberPrefix, Ulogo, UisZoneNotRequired, UisDisableULB, UstatedashboardValue, UisRepeatCollection)}
                                        aria-label="more"
                                        aria-controls="long-menu"
                                        aria-haspopup="true">
                                        <MoreVertIcon fontSize='small' />
                                    </IconButton>
                                    <Popper open={this.state.open} anchorEl={this.state.anchorEl}>
                                        <ClickAwayListener onClickAway={this.handleClickAwayEdit}>
                                            <Paper style={{ boxShadow: "none", border: "1px solid rgb(61, 171, 53)" }}>
                                                <MenuList>
                                                    <Link className='email_link'>
                                                    <MenuItem  className='email_link' onClick={()=>this.openEditUlbPopup(this.state.id, this.state.Uulbname, this.state.UuserId, this.state.Uname_tn,this.state.Ucode,this.state.Uemail, this.state.Uusername, this.state.UtypeId, this.state.UoverallLoadNumberPrefix, this.state.UreceiptNumberPrefix, this.state.Ulogo, this.state.UisZoneNotRequired, this.state.UisDisableULB, this.state.UstatedashboardValue, this.state.UisRepeatCollection)}>{("Edit")}</MenuItem>
                                                        {/* <MenuItem style={{ color: '#3dab35' }} onClick={() => {
                                                        swal({
                                                            title: "Confirmation Alert!",
                                                            text: "Once the current configuration is updated, you wont be able to retrive the data!",
                                                            icon: "warning",
                                                            className:'confirmationAlert',
                                                            buttons: {
                                                                cancel: 'No',
                                                                confirm: 'Yes'
                                                            }
                                                        })
                                                            .then((willDelete) => {
                                                                if (willDelete) {
                                                                 return this.props.history.push( {pathname: `/ulb-management/edit/rcConfiguration/${this.state.id}`, ulbId:this.state.id })
                                                                   
                                                                }
                                                            });
                                                    }}>ULB Configuration</MenuItem> */}


                                                    </Link>
                                                    <MenuItem style={{ color: '#3dab35' }} onClick={() => {
                                                        swal({
                                                            title: "Are you sure?",
                                                            text: "Once deleted, you will not be able to recover this data!",
                                                            icon: "warning",
                                                            buttons: {
                                                                cancel: 'No',
                                                                confirm: 'Yes'
                                                            }
                                                        })
                                                            .then((willDelete) => {
                                                                if (willDelete) {
                                                                    this.handleDelete(this.state.id);
                                                                }
                                                            });
                                                    }}>Delete</MenuItem>
                                                </MenuList>
                                            </Paper>
                                        </ClickAwayListener>
                                    </Popper>
                                </TableCell>
                            }
                            else if (header.accessor == 'updatedAt') {
                                return <TableCell style={{
                                    whiteSpace: "normal",
                                    wordWrap: "break-word"
                                }} key={header.accessor} >{moment(data[header.accessor]).format('DD-MMM-YYYY hh:mm a')}</TableCell>
                            }
                            else if (header.accessor == 'logo') {
                                if (data[header.accessor]) {
                                    return <TableCell style={{ textAlign:"center" }}> <a className="email_link" href={data[header.accessor]} target="_blank">View</a></TableCell>
                                }
                                else {
                                    return <TableCell></TableCell>
                                }
                            }
                            else if (header.accessor === "statedashboardValue") {
                                return <TableCell className="selectCheckboxStatus"  key={header.accessor} 
                                style={{ whiteSpace: "normal", wordWrap: "break-word", textAlign:"center" }} >
                                    {data[header.accessor]}
                                </TableCell>
                            }

                            else if(header.accessor === "code") {
                                return <TableCell style={{ whiteSpace: "normal", wordWrap: "break-word", textAlign:"center"}} key={header.accessor} className={"white-space"}>
                                    {data[header.accessor]}
                                      </TableCell>
                            }
                            else if(header.accessor === "isDisableULB") {
                                return <TableCell style={{ whiteSpace: "normal", wordWrap: "break-word", textAlign:"center"}} key={header.accessor} className={"white-space"}>
                                    {data[header.accessor]}
                                      </TableCell>
                            }
                            else {
                                return <TableCell style={{
                                    whiteSpace: "normal",
                                    wordWrap: "break-word"
                                }} key={header.accessor} 
                                     className={"white-space"}>
                                    {data[header.accessor]}
                                      </TableCell>
                            }
                        })
                    }
                </TableRow>
            )
        })
        return (
            <div>
                <div className="dashboard_right_content" style={{marginTop:"-4%"}}>
                    <img className="dashboard_right_content_top_icon" src="images/Rectangle.svg" />
                    <div className="dashboard_right_content_top">
                        <h5>ULB Management</h5>
                        <div className="dashboard_right_content_top_options">
                        <div className="dashboard_right_content_top_search">
                                <Paper component="form">
                                    <IconButton aria-label="menu">
                                        <img src="images/search_icon.svg" />
                                    </IconButton>
                                    <InputBase onChange={(event) => this.inputChangedHadler(event, 'search')} placeholder="ULB Name" />
                                    <Button onClick={this.handlePropertySearch} variant="contained" color="primary" type="submit">Search</Button>
                                </Paper>
                            </div>
                            
                            <Button onClick={this.openUlbAddPopup}>Add a New ULB</Button>
                            <div>
                            <Button style={{width:'100%', marginLeft:'10px' }} onClick={this.handleExport}>Export</Button>
                            </div>
                            <div >
                                <Button onClick={this.onClickRemoveFilter}>Reset</Button> 
                            </div>
                        </div>
                    </div>
                </div>
                {/* {(!this.props.loadingUlb)&& <div id="cover-spin"></div>} */}
                <div className="operator_listing AreaLIsting scrollward " >
                    <TableContainer component={Paper} style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                        <Table stickyHeader aria-label="sticky table" style={{ tableLayout: 'fixed' }}>
                            <colgroup>
                                <col style={{ width: '5%' }} />
                                <col style={{ width: '10%' }} />
                                <col style={{ width: '10%' }} />
                                <col style={{ width: '8%' }} />
                                <col style={{ width: '15%' }} />
                                <col style={{ width: '10%' }} />
                                <col style={{ width: '8%' }} />
                                <col style={{ width: '8%' }} />
                                <col style={{ width: '8%' }} />
                            </colgroup>
                            <TableHead >
                                <TableRow >
                                    {ulbHeaders}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {/* {ulbData} */}
                                {ulbData.length === 0 ? <TableRow style={{textAlign:'center', fontWeight:'bold', fontSize: '13px', }}>
                                            No Records Found !!</TableRow>:ulbData }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                {
                    this.props.totalCount && this.props.totalCount != 0 &&
                    <ReactPaginate
                        previousLabel={"Prev"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                     
                        pageCount={this.props.totalCount / this.state.limit}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        style={{ borderLeftWidth: '0px' }}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"} />
                }


<div className="setFreq">
      <Dialog
        maxWidth={'md'}
        fullWidth={true} 
        // open={true}
        open={this.state.openAddUlbPopup}
        aria-labelledby="responsive-dialog-title">
        <DialogTitle className="text-center border-none " style={{color:'#3dab35',padding: "4px 24px"}}><span style={{fontSize:'18px'}}>{this.state.editId != ""? `${("Edit ULB")}`: `${("Add ULB")}`}</span></DialogTitle>
        <DialogContent style={{padding:'0px'}}> 
        <div className="create_ulb_content_outer">
                <div className="create_ulb_content create_ulb_contentPop">

        <Grid container spacing={1}>
                                <Grid item xs={6}>
                                <FormControl>
                                    <label className="required" htmlFor="ulbName">ULB Name</label>
                                        <TextField id="ulbName" 
                                                variant="outlined" 
                                                placeholder="Enter Name" 
                                                name="ulbName" 
                                                value={this.state.ulbName} 
                                                onChange={this.handleChange}/>
                                        <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.ulbNameErr}</div>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                <FormControl>
                                    <label htmlFor="ulbName_tm">ULB Name (Tamil)</label>
                                        <TextField id="ulbName_tm" 
                                                    variant="outlined" 
                                                    placeholder="பெயரை உள்ளிடுக" 
                                                    name="ulbName_tm" 
                                                    value={this.state.ulbName_tm} 
                                                    onChange={this.handleChange}/>
                                </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                <FormControl>
                                    <label className="required" htmlFor="ulbCode">ULB Code</label>
                                    <TextField id="ulbCode"
                                            variant="outlined"
                                            placeholder="Enter Code"
                                            name="ulbCode"
                                            value={this.state.ulbCode}
                                            onChange={this.handleChange}/>
                                    <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.ulbCodeErr}</div> 
                                </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                <FormControl>
                                    <label className="required" htmlFor="email">Admin Email</label>
                                    <TextField id="email"
                                            variant="outlined"
                                            placeholder="Enter Admin Email"
                                            name="email"
                                            value={this.state.email}
                                            onChange={this.handleChange}/>
                                    <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.emailErr}</div> 
                                </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                <FormControl>
                                    <label className="required" htmlFor="adminName">Admin User Name</label>
                                    <TextField id="adminName"
                                            variant="outlined"
                                            placeholder="Enter Admin User Id"
                                            name="adminName"
                                            value={this.state.adminName}
                                            onChange={this.handleChange}/>
                                    <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.adminNameErr}</div> 
                                </FormControl> 
                                </Grid>
                                <Grid item xs={6}>
                                <FormControl>
                                    <label className="required" htmlFor="type">Overall Load Number Prefix</label>
                                    <TextField id="overallLoadNo"
                                            variant="outlined"
                                            placeholder="Enter Overall Load Number Prefix"
                                            name="overallLoadNo"
                                            value={this.state.overallLoadNo}
                                            onChange={this.handleChange}
                                            disabled={this.props.disabled ? this.props.disabled : false}
                                            inputProps={{ maxLength: 3 }}/>
                                    <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.overallLoadNoErr}</div> 
                                </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                         <FormControl>
                                        <label htmlFor="type">Receipt Number Prefix</label>
                                        <TextField id="receiptNo"
                                                variant="outlined"
                                                placeholder="Enter Receipt Number Prefix"
                                                name="receiptNo"
                                                value={this.state.receiptNo}
                                                onChange={this.handleChange}
                                                //disabled={this.props.disabled ? this.props.disabled : false}
                                                inputProps={{ maxLength: 3 }}/>
                                        <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.receiptNoErr}</div> 
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                <FormControl>
                                    <label className="required" htmlFor="type">Type</label>
                                    <Select value={this.state.type} onChange={this.handleChange} name="type">
                                        {options.map((option)=>(
                                            <MenuItem value={option.id}>{option.name}</MenuItem>
                                        ))}
                                    </Select>
                                    <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.typeErr}</div> 
                                </FormControl>
                                        </Grid>
                                <Grid item xs={6}>
                                <FormControl variant="outlined" size="small">
                                     <label>Logo</label>      <span style={{display:'flex'}}>
                                                    <ImageUploader style={{align:'left'}}withIcon={false}
                                                        singleImage={true} 
                                                        fileContainerStyle = {{color:'#3dab35'}} 
                                                        buttonText='Upload'
                                                        buttonStyles={{backgroundColor:'white', color:'#3dab35',fontSize:'12px', borderRadius:'5px',align:'left',height:'35px',	width:'100%',padding:'0px',marginTop:'1px',marginBottom:'0%', height:"45px"}}
                                                        withLabel={false}
                                                        value={this.state.img}
                                                        onChange={this.handleImage}
                                                        imgExtension={['.jpg', '.png','.jpeg']}
                                                        fileSizeError=" file size is too big"
                                                        fileTypeError=" is not supported file extension"
                                                        maxFileSize={5242880}
                                                        buttonClassName="imgButtonEnable"
                                                        />
                                                        {this.state.isLogo &&
                                                         <CancelIcon style={{fontSize:'large',color:'#f51c00'}}  onClick={this.handleRemove} />
                                                         }
                                                        </span>
                                            { this.state.imgErr ? 
                                                   <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"5%",lineHeight:'11px'}}>{this.state.imgErr}</div> 
                                                   : 
                                                   <div>{"Upload Logo"}</div>
                                            }
 
                                        </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                <FormControl>
                                    <label htmlFor="StatusValue" >Status</label>
                                <TextField id="select"  name="StatusValue" value={this.state.StatusValue} onChange = {this.handlChangeStatus} select>
                                    <MenuItem value={false}>{'Active'}</MenuItem>
                                    <MenuItem value={true}>{'In Active' }</MenuItem>
                                </TextField>
                                </FormControl>
                                </Grid>
                            
                                
                            </Grid>
                      
                        <Grid container spacing={3}>
                                <Grid item xs={12}>
                                <div className="create_ulb_buttons">
                                <Button className="login_submit" variant="outlined" color="primary" onClick={this.handleClose}>Cancel</Button>
                                    <Button className="login_submit" variant="contained" color="primary" onClick={this.handleSubmit}>Save</Button>
                                </div>
                                </Grid>
                        </Grid>
                      
</div></div>
        </DialogContent>
        </Dialog>
        </div>



            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.auth.token,
        ulbHeaders: state.ulbHeaders.ulbHeaders.concat({ header: "Action", accessor: "actionButton", sortable: "false", filter: "false" }),
        ulbData: state.ulbData.ulbData,
        totalCount: state.ulbData.totalCount,
        loadingUlb: state.ulbData.loading
    }
}

const mapDispatchToProps = (dispatch) => {
    return ({
        onFetchUlbHeaders: (payload) => dispatch(actionsCreators.fetchUlbHeaders(payload)),
        onFetchUlbData: (payload) => dispatch(actionsCreators.fetchUlbData(payload)),
        onDeleteUlbData: (payload, token) => dispatch(actionsCreators.deleteUlbData(payload, token)),
        onSelectULbs: (payload) => dispatch(actionsCreators.SelectULbs(payload)),
        OnSearchULBName: (payload) => dispatch(actionsCreators.searchULBName(payload)),
        onFetchULBListingExport:(payload)=>dispatch(actionsCreators.fetchULBListingExport(payload)),
        onEditUlbData: (formdata, props, payload) => dispatch(actionsCreators.editUlbData(formdata,props, payload)),
        onAddUlbData :(formdata, props, payload)=>dispatch(actionsCreators.addUlbData(formdata,props, payload))

    })
}

export default connect(mapStateToProps, mapDispatchToProps)(UlbListing)
