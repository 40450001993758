import * as actionTypes from '../actionsTypes';
import axios from '../../../http-config';

export const fetchPropertyHeadersSuccess = (response) => {
    return {
        
        type: actionTypes.FETCH_PROPERTYHEADERS_SUCCESS,
         headers:response.data.headers   
    }
}

export const fetchPropertyHeadersFail = (error) => {
    return {
        type: actionTypes.FETCH_PROPERTYHEADERS_FAIL,
        error:error
    }
}

export const fetchPropertyHeadersStart = () => {
    return {
        type: actionTypes.FETCH_PROPERTYHEADERS_START
    }
}

export const fetchPropertyHeaders = (payload) => {
    let token = payload.token;
    return dispatch => {
        dispatch(fetchPropertyHeadersStart());
        axios({
            method: 'get',
            url: '/property/headers',
            headers: {'Authorization': `Bearer ${token}`}
        })
        .then((response) => {
            const fetchedPropertyHeadersResponse =response.data        
            dispatch(fetchPropertyHeadersSuccess(fetchedPropertyHeadersResponse));
        }).catch(error => {
            dispatch(fetchPropertyHeadersFail(error));
        })

    }
}
