import * as actionTypes from '../actions/actionsTypes';
import { updateObject } from '../utility';

const initialState = {
    propertyTypes: [],
    count:0,
    totalCount: 0,
    loading: false
}

const fetchPropertyTypesStart = (state, action) => {
    return updateObject(state, {
        loading: true,
    });
}

const fetchPropertyTypesSuccess = (state, action) => {
    return updateObject(state, {
        propertyTypes: action.propertyTypes,
        count:action.count,
        totalCount: action.totalCount,
        loading: false,
    });
}

const fetchPropertyTypesFail = (state, action) => {
    return updateObject(state, {
        loading: false,
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_PROPERTYTYPES_START: return fetchPropertyTypesStart(state, action);
        case actionTypes.FETCH_PROPERTYTYPES_SUCCESS: return fetchPropertyTypesSuccess(state, action);
        case actionTypes.FETCH_PROPERTYTYPES_FAIL: return fetchPropertyTypesFail(state, action);
        default: return state;
    }
}

export default reducer;