import axios from 'axios';

const token = localStorage.getItem('token');
let headers = {}

if (token != null) {
    
}

const getBaseUrl = (url) => {
    // return 'http://192.168.0.40:9001/v1/'
    switch(url){
        case process.env.REACT_APP_LOCAL_HOST_NAME:
            return process.env.REACT_APP_PROD_BASE_URL
           // return process.env.REACT_APP_LOCAL_BASE_URL
        case process.env.REACT_APP_DEV_HOST_NAME:
            return process.env.REACT_APP_DEV_BASE_URL
        case process.env.REACT_APP_PROD_HOST:
            return process.env.REACT_APP_PROD_BASE_URL
    }
}

const instance = axios.create({
 baseURL: getBaseUrl(window.location.hostname),
    headers: headers
});

export default instance;