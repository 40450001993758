import * as actionTypes from '../actionsTypes'
import axios from '../../../http-config'
import swal from 'sweetalert'

export const fetchTruckDataStart = () => {
    return({
        type : actionTypes.FETCH_TRUCK_DATA_START
    })
}
export const fetchTruckDataSuccess = (response) => {
    return({
        type : actionTypes.FETCH_TRUCK_DATA_SUCCESS,
        truckData : response.data.trucks
    })
}
export const fetchTruckDataFail = (err) => {
    return({
        type : actionTypes.FETCH_TRUCK_DATA_FAIL, 
        error : err.response
    })
}
export const fetchTruckData = (payload,props) => {
    let id=props.match.params.id
    const token = payload.token
    return (dispatch) => {
        dispatch(fetchTruckDataStart())
        axios({
            method : 'get',
            url : `/truck?operatorId=${id}&sortColumnName=${payload.sortName}&sortColumnType=${payload.sortValue}`,
            headers : {'Authorization' : `Bearer ${token}`}
        })
        .then((response) => {
            const truckData = response.data
            dispatch(fetchTruckDataSuccess(truckData))
        })
        .catch((err) => {
            dispatch(fetchTruckDataFail(err))
        })
    }
}
export const addTruckData = (payload,props) => {
    const token = payload.token
    let id= props.match.params.id
    const payload2 = {
        registrationNumber : payload.registrationNumber,
        vehicleTypeId      : payload.vehicleTypeId,
        gpsInstalled       : payload.gpsInstalled,
        licensedWithULB    : payload.licensedWithULB,
        referenceNumber    : payload.referenceNumber,
        validity           : payload.validity,
        capacity           : payload.capacity,
        operatorId         : id,
        isDisableTruck     : payload.isDisableTruck
    }

    let url='/truck'
    return (dispatch) => {
        dispatch(fetchTruckDataStart())
        axios.post(url, payload2, { headers : { "Authorization":`Bearer ${token}`}})
        .then((response) => {
            const fetchedResponse = response.data.success;
            fetchedResponse && swal({
                title: "Truck added successfully!",
                icon: "success",
                buttons: {
                    confirm: 'Ok'
                }
              })
            dispatch(fetchTruckData(payload,props))
        }, 
        (err) => {
            swal(err.response.data.error[0]["message"])
            dispatch(fetchTruckDataFail(err))
        })
        .catch((err)=> {
            dispatch(fetchTruckDataFail(err))
        })
    }
}

export const fetchTruckBulkImport = (payload,formData,props) => {
    let token = payload.token
    return dispatch => {
        dispatch(fetchTruckDataStart());
        const url = '/truck/bulk'

        axios.post(url,formData,{headers:{
            "Authorization":`Bearer ${token}`
        }})
        .then(function (response) {
            if(response){
                const fetchedResponse = response.data.success;
                fetchedResponse && swal({
                    title: "Data imported successfully!",
                    icon: "success",
                    buttons: {
                        confirm: 'Ok'
                    }
                  })
                  .then((bulk) => {
                    if (bulk) {
                    dispatch(fetchTruckData(payload,props))
                    } 
                  });
            }
              
        }, (err) => {
            swal({icon : "error" , title : err.response.data.error[0]["message"]})    
        })
        .catch(error => {
            dispatch(fetchTruckDataFail(error));
        })

    }
}

export const deleteTruckData = (payload, token, props) => {
    const dataOj = {id:payload.id}
    return (dispatch) => {
        dispatch(fetchTruckDataStart())
        const url = '/truck'
        axios.delete(url, { data : dataOj,  headers : { "Authorization":`Bearer ${token}`}})
        .then((response) => {
            dispatch(fetchTruckData(payload, props))
            swal({
                title:"Your data has been deleted!",
                icon: "success",
            })
        }, 
        (err) => {
            swal({icon : "error" , title : err.response.data.error[0]["message"]})    
        })
    }
}

export const editTruckData = (payload, props) => {
    // const id = props.match.params.id
    let token = payload.token;
    return dispatch => {
        dispatch(fetchTruckDataStart());
        let url = '/truck/';
        axios.put(url, payload,{headers:{
            "Authorization":`Bearer ${token}`
        }})
        .then(response => {
            const fetchedResponse = response.data.success;
                fetchedResponse && swal({
                    title: "You have Successfully edited the data!!!",
                    icon: "success",
                    buttons: {
                        confirm: 'Ok'
                    }
                  })
                  dispatch(fetchTruckData(payload,props));
        }, (err) => {
            swal({icon : "error" , title : err.response.data.error[0]["message"]})          
        })
        .catch(err => {
            dispatch(fetchTruckDataFail(err))
        })
    }
}

export const searchOperterTruckNumber=(payload, props)=>{
    return dispatch =>{
        dispatch(fetchTruckDataStart());
        const url =`operator?registrationNumber=${payload.searchQuery}&limit=${payload.limit}&page=${payload.page}`
        axios.get(url,{headers:{
            "Authorization":`Bearer ${payload.token}`
        }} ).then(response=>{
              if( response && response.data && response.data.data.trucks &&
                response.data.data.trucks ){
                    const truckData = response.data
                    dispatch(fetchTruckDataSuccess(truckData))
                    props.history.push({pathname:`/truck-management/${response.data.data.trucks[0]["operatorId"]}`, state: {operatorName:response.data.data.trucks[0].name ,searchByTruckNo:"searchTruck", TruckNo:payload.searchQuery, id:response.data.data.trucks[0]['id'] },})
                   
                }
                else{
                    response && response.data && response.data.data.trucks &&
                    response.data.data.trucks && 
                    response.data.data.trucks.length == 0 && 
                    swal("No Records Found !", {
                        icon: "warning",
                      });
                }
           
        }).catch(err=>{
            dispatch(fetchTruckDataFail(err))
        })
    }
}