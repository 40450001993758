import * as actionTypes from '../../actions/actionsTypes'
import { updateObject } from '../../utility'

export const initialState = {
    truckData : [], 
    loading : false,
    error : null
}
export const fetchTruckDataStart = ( state, action ) => {
    return updateObject(state, {
        loading:true
    })
}
export const fetchTruckDataSuccess = ( state, action ) => {
    return updateObject(state, {
        truckData : action.truckData,
        loading : false
    })
}
export const fetchTruckDataFail = ( state, action ) => {
    return updateObject( state, {
        error : action.error,
        loading : false
    })
}
const truckDataReducer = (state = initialState, action) => {
    switch(action.type)
    {
        case actionTypes.FETCH_TRUCK_DATA_START : return fetchTruckDataStart(state, action)
        case actionTypes.FETCH_TRUCK_DATA_SUCCESS : return fetchTruckDataSuccess(state, action)
        case actionTypes.FETCH_TRUCK_DATA_FAIL : return fetchTruckDataFail(state, action)
        default : return state
    }
}
export default truckDataReducer