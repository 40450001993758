import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom'
import '../../../App.css'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import _ from 'lodash'
import * as actionsCreators from  '../../../store/actions/index';
import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler';
import axios from '../../../http-config';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Slider from '@material-ui/core/Slider'
import NumberFormat from 'react-number-format';
import { v4 as uuidv4 } from 'uuid';
import { Grid } from '@material-ui/core';
const optionsPropertyIsSlum =[
    {
        "key":true,
        "name":"Yes"
    },
    {
        "key":false,
        "name":"No"
    }
]
const marks = [
    {
      value: 1,
      label: "1"
    },
  
    {
      value: 14,
      label: "14"
    }
  ];
const desludgePeriod = [
    {
        "id":2,
        "name":'Years'
    },
    {
        "id":3,
        "name":'Months'
    },
    {
        "id":4,
        "name":'Weeks'
    },
    {
        "id":5,
        "name":'Days'
    },
    

]

class RecordKeeperForm extends Component {
    constructor(props){
    super(props)
        this.state={
            loadId:this.props.loads?this.props.loads[0].loadId:"",
            operatorValue : this.props.loads?`${this.props.loads[0].operatorId}-${this.props.loads[0].operatorName}`:"",
            operatorName : '',
            registrationNumber:this.props.loads?this.props.loads[0].truckRegistrationNumber:"", 
            areaOptions:[],
            propertyOptions:[],
            containmentOptions: [],
            quantityError:"",
            phError:"",
            orpError:"",
            ecError:"",
            infoError:"",
            dataLoaded:false,
            operatorI: [],
            propertyI : [],
            validation: [],
            photo_receipt:false,
            photo_tfBedNumber:false
        }
    }  
    componentDidMount=()=>{
        const s = this.state.operatorValue.split("-")
        let payload={
            token:this.props.token,
            operatorId: s[0]
        }
      
        this.props.ontrucksList(payload)
       this.props.onOpertaterList(payload)
        const ulbid=localStorage.getItem('ulbid')

        axios({
            method  : 'get',
            url     : '/load_template',
            headers: {'Authorization': `Bearer ${payload.token}`}
        })
        .then((response) => {
            this.setState({validation:response.data.data.loads})
        })
        axios({
            method  : 'get',
            url     : '/load_template/master_data',
            headers: {'Authorization': `Bearer ${payload.token}`}
        })
        .then((response) => {
            this.setState({propertyOptions:response.data.data['master-data'][1].data ,containmentOptions:response.data.data['master-data'][5].data})
        })

        this.props.loads.map((load,i)=>{
            this.props.headers.headers.map(header=>{
            if(header.accessor == 'load_sf_ph' ){
                const toNumber = Number(load[header.accessor])
                this.state[header.accessor]=toNumber
            }
            else if(header.accessor=='propertyTypeName'){
                const id=load['propertyTypeId']
                this.state['propertyTypeName'+(i+1)]=id 
                this.state['propertyId'+(i+1)]=load['propertyId']
                load['propertyCode'] ? this.state['propertyCode'+(i+1)]=load['propertyCode'] : this.state['propertyCode'+(i+1)]=uuidv4()
            }
            else if(header.accessor =='ulbName' || header.accessor == 'propertyName' || header.accessor=='propertyOwnerName'
                || header.accessor =='propertyAddress'){
                this.state[header.accessor +(i+1)] =load[header.accessor]
            }
            else if(header.accessor == 'propertyIsSlum'){
                if(load['propertyIsSlum']=='Yes'){
                    this.state[header.accessor +(i+1)]= true
                }
                else if(load['propertyIsSlum']=='No'){
                    this.state[header.accessor +(i+1)]= false
                }
            }
            else if(header.accessor=='desludgingPeriodValue'){
                const id=load['desludgingPeriodRangeId']
                const value=load['desludgingPeriodValueOriginal']
                this.state['desludgingPeriodRange'+(i+1)]=id 
                this.state['desludgingPeriodValue'+(i+1)]=value
            }
            else if(header.accessor=='containmentTypeName'){
                const id=load['containmentTypeId']
                this.state[header.accessor +(i+1)] =id
            }
            else if(header.accessor=='propertyId'){
                this.state[header.accessor +(i+1)] =load[header.accessor]
            }
            else if(header.accessor=='area' || header.accessor=='wardNumber'){
                const areaId=load['areaId']
                const wardId=load['wardId']
                this.state['areaId'+(i+1)]=areaId
                this.state['wardId'+(i+1)]=wardId
                this.state['area'+(i+1)]=load['area']
                this.state['wardNumber'+(i+1)]=load['wardNumber']
            }
            else{
                this.state[header.accessor]=load[header.accessor]
            }
        })
    })
        this.props.onFetchPropertyTypesEdit(payload)
        this.setState({ulbId:ulbid,dataLoaded:true})
    }

    handleSubmit = (e)=>{
        e.preventDefault()
        let props=this.props.props
        const err=this.checkValidity()
        if(!err)
        {
        const formdata={
                "data":{
                    "loads":{
                        "data":[{
                            "LOAD.ID":this.state.loadId,
                            "isWeb":true,
                            "TRUCK_NUMBER":this.state.registrationNumber,    
                            "OPERATOR_NAME":this.state['operatorName'],
                            "CARRYING_QUANTITY":this.state['carryingQuantity'],
                            // "AREA":this.state['area'],
                            // "PROPERTY_TYPE_NAME":this.state['propertyTypeName'],
                            // "PROPERTY_NAME":this.state['propertyName'],
                            // "ADDRESS":this.state['propertyAddress'],
                            // "RESPONDANT_NAME":this.state['propertyOwnerName'],
                            // "SLUM":this.state['propertyIsSlum'],
                            // "DESLUDGINGPERIOD":this.state['desludgingPeriodValue'],
                            "LOAD_SF_PH":(this.state['load_sf_ph']) >= 1 ? this.state['load_sf_ph'] : null,
                            "LOAD_SF_ORP":(this.state['load_sf_orp'])? parseInt(this.state['load_sf_orp']) : null,
                            "LOAD_SF_TOTAL_SOLIDS":(this.state['load_sf_total_solids']) ? parseFloat(this.state['load_sf_total_solids']) : null,
                            "LOAD_SF_ELECTRICAL_CONDUCTIVITY":(this.state['load_sf_electrical_Conductivity'])? parseFloat(this.state['load_sf_electrical_Conductivity']) : null,
                            "LOAD_SF_OTHER":(this.state['load_sf_other']) ? this.state['load_sf_other']: null,      
                        }],
                        "count":1
                    },
                    "properties":{
                        "data":
                            this.props.loads.map((load,i)=>{
                                return {
                                    "PROPERTY_ID":this.state['propertyId'+(i+1)],
                                    "ULB_Name":this.state['ulbName'+(i+1)],
                                    "WARD_NUMBER":this.state['wardId'+(i+1)],
                                    "ULB_ID":this.state['ulbId'],
                                    "AREA_ID":this.state['areaId'+(i+1)],
                                    "CONTAINMENT_TYPE_ID":this.state['containmentTypeName'+(i+1)],
                                    "PROPERTY_TYPE_ID": this.state['propertyTypeName'+(i+1)],
                                    "PROPERTY_NAME":this.state['propertyName'+(i+1)],
                                    "PROPERTY_OWNER_NAME" : this.state['propertyOwnerName'+(i+1)],
                                    "PROPERTY_ADDRESS":this.state['propertyAddress'+(i+1)],
                                    "IS_SLUM":this.state['propertyIsSlum'+(i+1)],
                                    "DESLUDGING_PERIOD_RANGE_ID": this.state['desludgingPeriodRange'+(i+1)],
                                    "DESLUDGING_PERIOD_RANGE_VALUE": this.state['desludgingPeriodValue'+(i+1)],
                                    "PROPERTY_CODE":this.state['propertyCode'+(i+1)],
                                    "IS_UNKNOWN":true,
                                }
                            })
                            ,
                        "count":this.props.loads.length
                    }
            }   
        }
        this.props.handleSubmit(formdata)
    }   
    }

    handleScreeningFieldsChange = (values, name) => {
        this.setState({[name] : values.formattedValue
        },()=>{
            this.checkValidity()
        })
    }
    handleChange=(e)=>{
        let ct = e.target.value.split("-")
        if(e.target.name == 'operatorValue'){
            this.setState({operatorName: ct[1]})
            let payload = {
                token : this.props.token,
                operatorId: ct[0]
            }
            this.props.ontrucksList(payload)

        }
        this.setState({
            [e.target.name]:e.target.value
        },()=>{
            this.checkValidity()
        })
     

    }
    
    checkValidity=()=> {
        let isError=false;
        this.state.validation.map((val)=>{
           if(val.code=='LOAD_SF_ORP' && this.state['load_sf_orp']){
               if(Number(this.state['load_sf_orp']) < val.validations['min_value'] || Number(this.state['load_sf_orp']) > val.validations['max_value']){
                isError = true
                this.setState({orpError: `${val.validations['min_value']} to ${val.validations['max_value']}`})
               }
               else if(Number(this.state['load_sf_orp']) >= val.validations['min_value'] && Number(this.state['load_sf_orp']) <= val.validations['max_value']){
                    this.setState({orpError:""})
                }
                else{
                    this.setState({orpError:""})
                }  
           }
           if(val.code== 'LOAD_SF_ELECTRICAL_CONDUCTIVITY' && this.state['load_sf_electrical_Conductivity']){
               if(Number(this.state['load_sf_electrical_Conductivity']) < val.validations['min_value'] || Number(this.state['load_sf_electrical_Conductivity']) > val.validations['max_value']){
                isError = true
                this.setState({ecError:"0 to 4000"})
                this.setState({ecError:`${val.validations['min_value']} to ${val.validations['max_value']}`})
                }  
                else if(Number(this.state['load_sf_electrical_Conductivity']) >= 0 && Number(this.state['load_sf_electrical_Conductivity']) <= 4000){           
                    this.setState({ecError:""})
                }
                else{
                    this.setState({ecError:""})
                }  
            }
        })
        return isError    
    }
    handlepHChange = (e, newValue) => {
        this.setState({['load_sf_ph']:newValue})
    }

    render(){ 
        return(
        <div className="create_ulb">
            <div className="dashboard_right_content">
                <div className="create_ulb_content_outer">
                    <div className="create_ulb_content">
                    <h2 align='left'>Operator Information</h2>
                    <Grid container spacing={1}>
                    {/* <FormControl></FormControl> */}
                        {!_.isEmpty(this.props.headers.headers)&& (
                            
                           this.props.headers.headers.map((header,i)=>{
                            if(header.accessor =='operatorName'){
                                return  ( <Grid item xs={6}>
                                  <FormControl>
                                      <label className= "required" htmlFor="operatorName">Operator Name</label>
                                     
                                          <TextField id="select"  name="operatorValue"  value={this.state.operatorValue}  select   onChange={(event) => {this.handleChange(event, header.accessor)}} >
                                          {!!this.props.operaterList&&this.props.operaterList.map((item, i)=>{
                                          return (
                                              <MenuItem key={i} value={`${item.operatorId}-${item.name}` }>{item.name}</MenuItem>
                                            
                                          )
                                      })}
                                             
                                          </TextField>
                                  </FormControl>
                              </Grid>)
                              }
                            if(header.accessor =='id' || header.accessor =='overallLoadNumber' || header.accessor =='currentLoadNumber' 
                            || header.accessor =='treatmentFacilityName' || header.accessor =='recordKeeperName' || header.accessor =='dateTime' 
                            ||header.accessor =='carryingQuantity'){
                            !this.state.operatorI.includes(header.accessor) && this.state.operatorI.push(header.accessor)
                            return ( 
                                <Grid item xs={6}>
                                <div>
                                {header.accessor=='id'}
                                {header.accessor=='overallLoadNumber'}
                                <FormControl>
                                <label>{header.header}</label>
                                <TextField id={header.accessor} 
                                            variant="outlined" 
                                            value={this.state[header.accessor]} 
                                            name={header.accessor} 
                                            onChange={this.handleChange} 
                                            disabled={header.accessor==='carryingQuantity'|| header.accessor =='truckRegistrationNumber'|| header.accessor =='operatorName'? false:true}
                                />
                                </FormControl> 
                               </div>
                               </Grid>
                                )   
                            } 
                            if(header.accessor =='truckRegistrationNumber'){
                                return  ( <Grid item xs={6}>
                                {this.state.operatorValue != ""?   <FormControl>
                                        <label className= "required" htmlFor="truckRegistrationNumber">Truck</label>
                                        
                                            <TextField id="select" name='registrationNumber' value={this.state["registrationNumber"]} select  onChange = {this.handleChange}>
                                                {!!this.props.truckList&&this.props.truckList.map((item)=>{
                                                 return    <MenuItem value={item.registrationNumber}>{item.registrationNumber}</MenuItem>
                                                })}
                                                
                                       
                                            </TextField>
                                    </FormControl>
                            :''}
                                </Grid>)
                            }

                           })
                        )}
                    </Grid>
                 
                 <Grid container spacing={1}>
                 {this.state.operatorI.length %2!=0 && <FormControl></FormControl>}
                 <h2 align='left' style={{marginLeft:"10px", marginBottom:"-19px"}}>Property Information</h2>
                        <FormControl></FormControl>
                        {!_.isEmpty(this.props.headers.headers)&& (
                            this.props.loads.map((load,i)=>{  
                               var extra=<FormControl></FormControl>
                                var array= this.props.headers.headers.map((header)=>{
                                    
                                if(header.accessor =='ulbName'){
                                       this.state['propertyI'+(i+1)]=new Array(header.accessor)
                                    return(
                                        <Grid item xs={6}>

                                        <div>
                                            <h2 align='left'  style={{marginLeft:"6px"}}>Property&nbsp;{i+1}</h2>
                                            <br/>
                                            <FormControl>
                                            <label>{header.header}</label>
                                            <TextField id={header.accessor} 
                                                        variant="outlined" 
                                                        value={this.state[header.accessor+(i+1)]} 
                                                        name={header.accessor+(i+1)} 
                                                        onChange={(event) => {this.handleChange(event, header.accessor)}} 
                                                        disabled
                                            />
                                            </FormControl>  
                                        </div>
                                        </Grid>
                                        ) 
                                }
                                else if(header.accessor =='wardNumber'){
                                    this.state['propertyI'+(i+1)] && this.state['propertyI'+(i+1)].push(header.accessor)
                                    return(
                                        <Grid item xs={6}>
                                        <div>
                                            <h2 align='left'>&nbsp;</h2>
                                            <br/>
                                            <FormControl>
                                            <label>{header.header}</label>
                                            <TextField id={header.accessor} 
                                                        variant="outlined" 
                                                        value={this.state[header.accessor+(i+1)]} 
                                                        name={header.accessor+(i+1)} 
                                                        onChange={(event) => {this.handleChange(event, header.accessor)}} 
                                                        disabled
                                            />
                                            </FormControl>  
                                        </div>
                                        </Grid>
                                        ) 
                                }    
                                else if(header.accessor =='area'){
                                    this.state['propertyI'+(i+1)] && this.state['propertyI'+(i+1)].push(header.accessor)
                                    return (
                                        <Grid item xs={6}>
                                    <FormControl>
                                            <label>{header.header}</label>
                                            <TextField id={header.accessor} 
                                                        variant="outlined" 
                                                        value={this.state[header.accessor+(i+1)]} 
                                                        name={header.accessor+(i+1)} 
                                                        onChange={(event) => {this.handleChange(event, header.accessor)}} 
                                                        disabled
                                            />
                                            </FormControl>  
                                            </Grid>)
                                }    
                                else if( header.accessor == 'propertyName'|| header.accessor=='propertyOwnerName'|| header.accessor =='propertyAddress'){
                                    this.state['propertyI'+(i+1)] && this.state['propertyI'+(i+1)].push(header.accessor)
                                    return (  <Grid item xs={6}>
                                     <FormControl>
                                            <label>{header.header}</label>
                                            <TextField id={header.accessor} 
                                                        variant="outlined" 
                                                        value={this.state[header.accessor+(i+1)]} 
                                                        name={header.accessor+(i+1)} 
                                                        onChange={(event) => {this.handleChange(event, header.accessor)}} 
                                            />
                                            </FormControl>  
                                            </Grid>)
                                }
                                else if(header.accessor =='propertyTypeName' && this.state.dataLoaded){
                                    this.state['propertyI'+(i+1)] && this.state['propertyI'+(i+1)].push(header.accessor)
                                    return ( <Grid item xs={6}>
                                    <FormControl>
                                            <label>{header.header}</label>
                                            <Select value={this.state['propertyTypeName'+(i+1)]} 
                                                    name={'propertyTypeName'+(i+1)}
                                                    onChange={this.handleChange}
                                                   
                                                      >
                                            {this.state.propertyOptions.map((option) => (
                                                <MenuItem value={option.id}>{option.name}</MenuItem>
                                            ))}
                                            </Select>
                                            </FormControl>
                                            </Grid>)
                                }
                                else if(header.accessor == 'propertyIsSlum' && this.state.dataLoaded){
                                    this.state['propertyI'+(i+1)] && this.state['propertyI'+(i+1)].push(header.accessor)
                                    return ( <Grid item xs={6}>
                                    <FormControl>
                                            <label>Slum</label>
                                            <Select value={this.state['propertyIsSlum'+(i+1)]} 
                                                            onChange={this.handleChange} 
                                                            name={'propertyIsSlum'+(i+1)}
                                                            size="small" 
                                                            style={{fontSize:12, 
                                                           }}
                                                            >
                                                            {optionsPropertyIsSlum.map((option) => (
                                                                <MenuItem 
                                                                            style={{fontSize:12,width:'100%'}} 
                                                                            value={option.key}>
                                                                            {option.name}
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                        </FormControl> 
                                        </Grid>)
                                }
                             
                        
                                else if(header.accessor == 'desludgingPeriodValue' && this.state.dataLoaded){
                                    this.state['propertyI'+(i+1)] && this.state['propertyI'+(i+1)].push('desludgingPeriodValue','desludgingPeriodRange')
                                    return (
                                        <Grid item xs={12}>
                                        <div style={{display:"flex"}}>
                                        <FormControl>
                                            <label>Desludging Period Range</label>
                                            <Select value={this.state['desludgingPeriodRange'+(i+1)]} onChange={this.handleChange} 
                                            name={'desludgingPeriodRange'+(i+1)}>
                                                    {desludgePeriod.map((option) => (
                                                        <MenuItem value={option.id}>{option.name}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl> &emsp; &emsp; &emsp;
                                            
                                            <FormControl>
                                                <label>Desludging Period Value</label>
                                                <TextField id='desludgingPeriodValue'
                                                            variant="outlined"
                                                            value={this.state['desludgingPeriodValue'+(i+1)]}
                                                            name={'desludgingPeriodValue'+(i+1)}
                                                            onChange={(event)=> {this.handleChange(event)}}/>
                                            </FormControl>                            
                                    </div>
                                    </Grid>
                                    )
                                }
                                else if(header.accessor == 'containmentTypeName' && this.state.dataLoaded){
                                    this.state['propertyI'+(i+1)] && this.state['propertyI'+(i+1)].push(header.accessor)
                                    return (
                                        <Grid item xs={6}>
                                        <div>
                                        <FormControl>
                                        <label>{header.header}</label>
                                        <Select value={this.state[header.accessor+(i+1)]} 
                                                        name={header.accessor+(i+1)} 
                                                        onChange={this.handleChange} 
                                                        size="small" 
                                                        style={{fontSize:12,minWidth:'60px'}}>
                                                            {
                                                                !_.isEmpty(this.state.containmentOptions) && 
                                                                this.state.containmentOptions.map((option) => (
                                                                    <MenuItem key={option.id}
                                                                                style={{fontSize:12,width:'100%'}} 
                                                                                value={option.id}>
                                                                                {option.name}
                                                                    </MenuItem>
                                                                ))}
                                                </Select>
                                        </FormControl>
                                        </div>
                                        </Grid>
                                    )
                                }
                               
                                }) 
                           
                             if(this.state['propertyI'+(i+1)] && this.state['propertyI'+(i+1)].length %2!=0 ) {
                                    return ([array , extra])
                             }
                             else {
                                 return array
                             }
                            })
                        )}
              
   </Grid>
                 <Grid container spacing={1}>
                 <h2 align='left' style={{marginLeft:"6px"}}>Load Information</h2>
                    <FormControl></FormControl>
                   {!_.isEmpty(this.props.headers.headers)&& (
                          
                   this.props.headers.headers.map((header)=>{
                    if(header.accessor =='load_sf_other'){
                      return(<Grid item xs={6}>
                           <FormControl>
                                <label>{header.header}</label>
                                <TextField id={header.accessor} 
                                            variant="outlined" 
                                            value={this.state[header.accessor]} 
                                            name={header.accessor} 
                                            onChange={this.handleChange} 
                                />
                            </FormControl>
                            </Grid>)
                      }
                    else if(header.accessor=== 'photo'){
                        if(this.state[header.accessor]){
                            return (<Grid item xs={6}>
                            <FormControl>
                                    <label>{header.header}</label>
                                    <div style={{fontSize: "small", padding: "10px 14px", height: "46px", boxSizing: "border-box" ,border:'1px solid #C0C0C0', borderRadius:'5px'}}>
                                        <a className="email_link" href={this.state[header.accessor]} target="_blank">View Image</a>
                                    </div>
                                    </FormControl>
                                    </Grid>)
                    }
                    }
                    else if(header.accessor == 'load_sf_ph'){
                        return( <Grid item xs={6}>
                        <FormControl>
                                <label for="ph">pH</label>
                                <Slider min = {0}
                                        max = {14}
                                        marks = {marks}
                                        step = {0.1}
                                        value = {Number(this.state['load_sf_ph'])}
                                        name = {header.accessor}
                                        onChange = {this.handlepHChange}
                                        valueLabelDisplay="on" />
                                </FormControl> </Grid>)
                    }
                    else if(header.accessor == 'load_sf_total_solids'){
                        return (<Grid item xs={6}>
                        <FormControl>
                                <label>{header.header}</label>
                                <NumberFormat InputProps={{ style: { height: '46px' } }}
                                                customInput={TextField} 
                                                value = {this.state['load_sf_total_solids']}
                                                format="#.##"
                                                onValueChange={(values)=>{this.handleScreeningFieldsChange(values, header.accessor)}}/>
                                </FormControl> </Grid>)
                    }
                    else if(header.accessor == 'load_sf_orp' ){
                        return (<Grid item xs={6}>
                        <FormControl>
                                <label>{header.header}</label>
                                <TextField type='number'
                                            id={header.accessor} 
                                            variant="outlined" 
                                            value={this.state['load_sf_orp']} 
                                            name={header.accessor} 
                                            onChange={(event) => {this.handleChange(event, header.accessor)}} 
                                />
                                <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.orpError}</div>
                                </FormControl> </Grid>)
                    }
                    else if(header.accessor == 'load_sf_electrical_Conductivity'){
                        return (<Grid item xs={6}>
                        <FormControl>
                                <label>{header.header}</label>
                                <NumberFormat InputProps={{ style: { height: '46px' } }}
                                                    customInput={TextField} 
                                                    value = {this.state['load_sf_electrical_Conductivity']}
                                                    decimalScale={2} 
                                                    onValueChange={(values)=>{this.handleScreeningFieldsChange(values, header.accessor)}}/>
                                <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.ecError}</div>
                                </FormControl> </Grid>)
                    }
                    else if(header.accessor == 'receiptNumber'){
                        return (<Grid item xs={6}><FormControl>
                                <label>{header.header}</label>
                                <TextField id={header.accessor} 
                                            variant="outlined" 
                                            value={this.state['receiptNumber']} 
                                            name={header.accessor} 
                                            onChange={(event) => {this.handleChange(event, header.accessor)}} 
                                            disabled
                                        />
                                </FormControl> </Grid>)
                    }
                    else if(header.accessor == 'tfBedNumber'){
                        return(<Grid item xs={6}> <FormControl>
                                <label>{header.header}</label>
                                <TextField id={header.accessor}
                                            variant="outlined"
                                            value={this.state['tfBedNumber']}
                                            name={header.accessor}
                                            onChange={(event)=>{this.handleChange(event,header.accessor)}}
                                            disabled
                                />
                                </FormControl> </Grid>)
                    }
                })
                )}
                 </Grid>
                     
               <Grid container spacing={3}>
                    <Grid item xs={12}>
                    <div className="create_ulb_buttons">
                            <Link className="email_link" to={{
                                   pathname: '/records/all',
                                   state: {
                                     page : this.props?.pageDetails?.location?.state?.pageR,
                                     limit: this.props?.pageDetails?.location?.state?.limitR,
                                     sort :  this.props?.pageDetails?.location?.state?.sortR,
                                   filter :  this.props?.pageDetails?.location?.state?.filterR,
                                   query : this.props?.pageDetails?.location?.state?.queryR,
                                   ulbId :  this.props?.pageDetails?.location?.state?.ulbIdR,
                                   fromDate: this.props?.pageDetails?.location?.state?.fromDateR,
                                   toDate: this.props?.pageDetails?.location?.state?.toDateR,
                                   operatorId:this.props?.pageDetails?.location?.state?.operatorIdR,
                                   }
                            }}> <Button className="login_submit" variant="outlined" color="primary">Cancel</Button></Link>
                            <Link style={{textDecoration:"none", color:"white", fontSize:"small"}} to="/records/all"><Button className="login_submit" variant="contained" color="primary" onClick={this.handleSubmit}>Save</Button></Link>
                        </div>
                    </Grid>
               </Grid>
                      
                    </div>
                </div>
            </div>
        </div>
        )
    }
    }
    
    const mapStateToProps = state => {
        return {
            headers:state.headers,
            token:state.auth.token,
            propertyTypesEdit:state.propertyTypesEdit.propertyTypesEdit,
            propertyListing:state.propertyListing.propertyListing,
            operaterList: state.loads.operaterList,
            truckList : state.loads.trucks,
        }
    }
    
    const mapDispatchToProps = dispatch => {
        return {
            onFetchHeaders: (payload) => dispatch(actionsCreators.fetchHeaders(payload)),
            onFetchPropertyTypesEdit : (payload)=> dispatch(actionsCreators.fetchPropertyTypesEdit(payload)),
            onOpertaterList : (payload) => dispatch(actionsCreators.OpertaterList(payload)),
            ontrucksList : (payload) => dispatch(actionsCreators.trucksList(payload))
        }
    }
    
    export default  connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(RecordKeeperForm, axios));
    