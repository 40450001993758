import * as actionTypes from './actionsTypes';
import axios from '../../http-config';

export const fetchPropertyTypesEditSuccess = (response) => {
    return {
        type: actionTypes.FETCH_PROPERTYTYPESEDIT_SUCCESS,
        propertyTypesEdit:response.data['master-data'][1].data
    }
}

export const fetchPropertyTypesEditFail = (error) => {
    return {
        type: actionTypes.FETCH_PROPERTYTYPESEDIT_FAIL,
        error:error
    }
}

export const fetchPropertyTypesEditStart = () => {
    return {
        type: actionTypes.FETCH_PROPERTYTYPESEDIT_START
    }
}

export const fetchPropertyTypesEdit = (payload) => {
    let token = payload.token;
    return dispatch => {
        dispatch(fetchPropertyTypesEditStart());
        const url = '/load_template/master_data'

        axios({
            method: 'get',
            url: url,
            headers: {'Authorization': `Bearer ${token}`}
        })
        .then(function (response) {
            const fetchedResponse = response.data
            dispatch(fetchPropertyTypesEditSuccess(fetchedResponse));
        }).catch(error => {
            dispatch(fetchPropertyTypesEditFail(error));
        })
    }
}