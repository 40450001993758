import AsyncComponent from '../hoc/asyncComponent/asyncComponent';
import {Route, Switch } from 'react-router-dom';
import React from 'react';

// Components
import ResetPassword from '../containers/Auth/ResetPassword/SubmitOtp/SubmitOtp';

const asyncLogin = AsyncComponent(() => {
    return import('../containers/Auth/Login/Login')
});
const asyncNotFound =AsyncComponent(()=>{
    return import('../containers/Auth/NOTFoundPage/notfoundpage')
})
const PublicRoutes = ({ match }) => (
    <Switch>
        <Route path='/'                 exact component={asyncLogin}/>
        <Route path='/login'            exact component={asyncLogin} />
        <Route path='/notFound'            exact component={asyncNotFound} />
        <Route path='/reset_password'   exact component={ResetPassword} />
	</Switch>
);

export default PublicRoutes;