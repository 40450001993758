import * as actionTypes from '../../actions/actionsTypes'
import { updateObject } from '../../utility'

export const initialState = {
    truckDocs : [],
    loading : false
}
export const fetchTruckDocDataStart = ( state, action ) => {
    return updateObject(state, {
        loading:true
    })
}
export const fetchTruckDocDataSuccess = ( state, action ) => {
    return updateObject(state, {
        truckDocs : action.truckDocs,
        loading : false
    })
}
export const fetchTruckDocDataFail = ( state, action ) => {
    return updateObject( state, {
        loading : false
    })
}
const truckDocDataReducer = (state = initialState, action) => {
    switch(action.type)
    {
        case actionTypes.FETCH_TRUCK_DOC_DATA_START: return fetchTruckDocDataStart(state, action)
        case actionTypes.FETCH_TRUCK_DOC_DATA_SUCCESS : return fetchTruckDocDataSuccess(state, action)
        case actionTypes.FETCH_TRUCK_DOC_DATA_FAIL : return fetchTruckDocDataFail(state, action)
        default : return state
    }
}
export default truckDocDataReducer