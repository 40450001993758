import React, { Component } from 'react'
import { connect } from 'react-redux'
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { Grid, MenuItem } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from '../../http-config'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import * as actionsCreators from '../../store/actions/index';
// import Autocomplete from '@material-ui/lab/Autocomplete';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ReactPaginate from 'react-paginate';
import clsx from 'clsx';
import swal from 'sweetalert';
const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#3dab35',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#3dab35',
        },
    },
});

// Inspired by blueprintjs
function StyledRadio(props) {
    const classes = useStyles();

    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
        />
    );
}
class WardForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            zone: this.props.ZoneId ? this.props.ZoneId : "",
            wardNumber: "",
            wardNumberErr: "",
            zoneErr: "",
            id: this.props.wardId,
            // isZoneNRequired: this.props.isZoneNotRequired ? this.props.isZoneNotRequired : false,
            SelectWardValue: '',
            page: 1,
            limit: 20,
            activePage: 1,
            startNumber: '',
            endNumber: ''

        }
    }

    componentDidMount() {
        this.props.onSchedulePropertyZone()
        let payload = {
            token: this.props.token,
            page:  this.state.page,
            limit: this.state.limit,
            sortName:"",
            sortValue : "",
        }
        this.props.onFetchUlbData(payload);
        this.props.onFetchWardListing(payload);
    }

    handleSubmit = (e) => {
        e.preventDefault()
        let formdata;
        const props = this.props

        if (this.state.SelectWardValue === "single") {
            formdata = {
                token: this.props.token,
                wardList: [this.state.wardNumber],
                zoneId: this.state.zone,
            }
        }
        if (this.state.SelectWardValue === "multi") {
            const num = parseInt(this.state.endNumber)
            const startNUm = parseInt(this.state.startNumber)
            var arrWard = []
            for (let index = startNUm; index <= num; index++) {
                arrWard.push(index)
            }

            formdata = {
                token: this.props.token,
                wardList: arrWard,
                zoneId: this.state.zone,
            }
        }
        if (!!this.state.zone && this.state.zone !== '' || !!this.state.wardNumber && this.state.wardNumber != '') {
            this.props.handleSubmit(formdata, props)
        }
        else if (!!this.state.zone && this.state.zone !== '' || !!this.state.startNumber && this.state.startNumber != '' && !!this.state.endNumber && this.state.endNumber != "") {
            this.props.handleSubmit(formdata, props)

        }
        else {
            swal({
                icon: "info",
                title: 'Kindly Fill the each Fields!'
            })
        }



    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
        const payload = {
            token: this.props.token,
            id: e.target.value,
            page: this.state.page,
            limit: this.state.limit,
        }
        this.props.onSelectZoneWard(payload)
    }

    handleChangeSelect = (e) => {
        this.setState({ SelectWardValue: e.target.value })
    }

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        let payload = {
            token: this.props.token,
            id: this.state.zone,
            page: selectedPage + 1,
            limit: this.state.limit,
        }
        this.props.onSelectZoneWard(payload)
    };

    handleChangeWard = (e) => {
        this.setState({ wardNumber: e.target.value })
    }
    handleChangeStart = (e) => {
        this.setState({ startNumber: e.target.value })
    }
    handleChangeEnd = (e) => {
        this.setState({ endNumber: e.target.value })
    }
    render() {
        const updatedHeaders = [
            {
                accessor: "wardNumber",
                filter: "false",
                header: "Ward No.",
                sortable: "false",
            },
        ]

        const WardHeaderList = updatedHeaders.map((item) => {
            return (<TableCell className={"white-space"}>
                <div style={{
                    textAlign: 'center',
                    whiteSpace: 'break-spaces',

                }}>
                    {item.header}
                </div>
            </TableCell>)
        })

        const wards = !!this.props.wardsSelectZone && this.props.wardsSelectZone.slice(0, 10).map((ward, j) => {
            return <TableRow style={{
                whiteSpace: 'normal',
                wordWr: 'break-word'
            }} key={j}>{
                    updatedHeaders.map((header, i) => {
                        return <TableCell style={{
                            whiteSpace: "normal",
                            textAlign: 'center',
                            padding: '5%',
                            wordWrap: "break-word"
                        }} key={i} className={"white-space"}>{ward[header.accessor] == null || ward[header.accessor] == 'null' ? ' ' : ward[header.accessor]}</TableCell>

                    })
                }
            </TableRow>
        })
        const properties = !!this.props.wardsSelectZone && this.props.wardsSelectZone.slice(10, this.props.totalCount).map((ward, j) => {
            return <TableRow style={{
                whiteSpace: 'normal',
                wordWr: 'break-word'
            }} key={j}>{
                    updatedHeaders.map((header, i) => {
                        return <TableCell style={{
                            whiteSpace: "normal",
                            textAlign: 'center',
                            padding: '5%',
                            wordWrap: "break-word"
                        }} key={i} className={"white-space"}>{ward[header.accessor] == null || ward[header.accessor] == 'null' ? ' ' : ward[header.accessor]}</TableCell>

                    })
                }
            </TableRow>
        })

        return (
            <div>
                {/* <Paper elevation={0}> */}
                <div className="create_ulb_content_outer">
                    <div className="create_ulb_content">
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <FormControl component="fieldset" className="login_options">
                                    <label className="required" htmlFor="zone">Ward creation mode</label>
                                    <RadioGroup defaultValue={this.state.SelectWardValue} aria-label="wardSelect" name="wardSelect" onChange={(e) => this.handleChangeSelect(e)} >
                                        <FormControlLabel value="single" control={<StyledRadio />} label="Single Ward" />
                                        <FormControlLabel value="multi" control={<StyledRadio />} label="Multiple Wards" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                    {!!this.props.zones &&this.props.zones.length == 0? "":     <Grid item xs={6}>
                                <FormControl>
                                    <label className="required" htmlFor="zone">Select Zone</label>
                                    <Select
                                        onChange={this.handleChange}
                                        name="zone"
                                        // disabled={this.state.isZoneNRequired == false ? true : false}
                                        value={this.state.zone}
                                        style={{ width: '99%' }}>
                                          { this.props.zones.map((zone) => {
                                           return zone.isZoneNotRequired == true? '':<MenuItem value={zone.Id}>{zone.Name}</MenuItem>
                                        })}
                                       
                                    </Select>
                                    <div style={{ fontSize: 12, color: "red", fontFamily: "'Open Sans', sans-serif", marginTop: "1%" }}>{this.state.zoneErr}</div>
                                </FormControl>
                            </Grid>}

                        
                            {
                                this.state.SelectWardValue === 'single' ? <Grid item xs={6}>
                                    <FormControl>
                                        <label className="required" htmlFor="wardNumber">Ward Number</label>
                                        <TextField id="wardNumber"
                                            variant="outlined"
                                            placeholder="Enter Ward Number"
                                            name="wardNumber"
                                            value={this.state.wardNumber}
                                            onChange={this.handleChangeWard} />
                                        <div style={{ fontSize: 12, color: "red", fontFamily: "'Open Sans', sans-serif", marginTop: "1%" }}>{this.state.wardNumberErr}</div>
                                    </FormControl>
                                </Grid> : ''
                            }

                            {
                                this.state.SelectWardValue === 'multi' ? <Grid container spacing={1}><Grid item xs={6}>
                                    <FormControl>
                                        <label className="required" htmlFor="startNumber">Start Number</label>
                                        <TextField id="startNumber"
                                            variant="outlined"
                                            placeholder="Enter Start Number"
                                            name="startNumber"
                                            value={this.state.startNumber}
                                            onChange={this.handleChangeStart} />
                                        {/* <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.wardNumberErr}</div> */}
                                    </FormControl>
                                </Grid>
                                    <Grid item xs={6}>
                                        <FormControl>
                                            <label className="required" htmlFor="endNumber">End Number</label>
                                            <TextField id="endNumber"
                                                variant="outlined"
                                                placeholder="Enter End Number"
                                                name="endNumber"
                                                value={this.state.endNumber}
                                                onChange={this.handleChangeEnd} />
                                            {/* <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.wardNumberErr}</div> */}
                                        </FormControl>
                                    </Grid></Grid> : ''
                            }


                            <Grid item xs={12}>
                                
                                   <h3 style={{ textAlign: 'left' }}>Existing Wards*</h3> 
                            </Grid>
                            <Grid item xs={3}>
                            <div className="dashboard_listing AreaLIsting" >
                                        <TableContainer component={Paper}>
                                            <Table stickyHeader aria-label="sticky table" style={{ tableLayout: 'fixed' }}>
                                                <TableHead >
                                                    <TableRow >
                                                        {WardHeaderList}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {wards}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div> 
                                
                            </Grid>

                            <Grid item xs={3}>
                                <div className="dashboard_listing AreaLIsting" >
                                        <TableContainer component={Paper} style={{ marginLeft: '-9px' }}>
                                            <Table stickyHeader aria-label="sticky table" style={{ tableLayout: 'fixed' }}>
                                                <TableHead >
                                                    <TableRow >
                                                        {WardHeaderList}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {properties}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div> 
                            </Grid>
                            <Grid item xs={6}>

                            </Grid>

                            <Grid item xs={12}>
                                {
                                    this.props.totalCount && this.props.totalCount != 0  &&
                                    <ReactPaginate
                                        previousLabel={"Prev"}
                                        nextLabel={"Next"}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        // pageCount={10}
                                        pageCount={this.props.totalCount / this.state.limit}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        style={{ borderLeftWidth: '0px' }}
                                        onPageChange={this.handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"} />
                                }
                            </Grid>


                           
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <div className="create_ulb_buttons">
                                    <Link style={{ textDecoration: "none", color: "#3dab35" }} to={localStorage.getItem('isZoneNotRequired') === 'true'? '/ward-management':'/zone-management'}>
                                        <Button className="login_submit" variant="outlined" color="primary">Cancel</Button></Link>
                                    <Button className="login_submit" variant="contained" color="primary" onClick={this.handleSubmit}>Save</Button>
                                </div>
                            </Grid>
                        </Grid>

                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return ({
        token: state.auth.token,
        zones: state.propertyListing.zones,
        ulbData: state.ulbData.ulbData,
        wardsSelectZone: state.WardReducer.wards,
        totalCount: state.WardReducer.totalCount,
        // wards:state.WardReducer.wards,
    })
}


const mapDispatchToProps = dispatch => {
    return {
        onFetchWardListing: (onFetchAreaList) => dispatch(actionsCreators.fetchWardListing(onFetchAreaList)),
        onSchedulePropertyZone: () => dispatch(actionsCreators.schedulePropertyZone()),
        onFetchUlbData: (payload) => dispatch(actionsCreators.fetchUlbData(payload)),
        onSelectZoneWard: (payload) => dispatch(actionsCreators.SelectZoneWard(payload))
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(WardForm)