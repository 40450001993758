import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import * as actionsCreators from '../../store/actions/index'
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { Grid, MenuItem } from '@material-ui/core';
// import Button from '@material-ui/core/Button';

import RcJson from './RCjson.json'
class RcConfiguration extends Component {
    constructor(props){
        super(props);
        this.state = {
            loadNumber: !!this.props.props.LOAD_NUMBER? this.props.props.LOAD_NUMBER.validations.required: '',
            date: !!this.props.props.LOAD_DATE? this.props.props.LOAD_DATE.validations.required: '',
            time:  !!this.props.props.LOAD_TIME? this.props.props.LOAD_TIME.validations.required: '',
            operater:  !!this.props.props.OPERATOR_LIST? this.props.props.OPERATOR_LIST.validations.required: '',
            truck:  !!this.props.props.TRUCK_LIST? this.props.props.TRUCK_LIST.validations.required: '',
            carryqty:  !!this.props.props.CARRYING_QUANTITY? this.props.props.CARRYING_QUANTITY.validations.required: '',
            repertLoad:  !!this.props.props.REPEAT_LOAD? this.props.props.REPEAT_LOAD.validations.required: '',
            ulb:  !!this.props.props.PROPERTY_ULB? this.props.props.PROPERTY_ULB.validations.required: '',
            address: !!this.props.props.PROPERTY_ADDRESS? this.props.props.PROPERTY_ADDRESS.validations.required: '',
            ward: !!this.props.props.PROPERTY_WARD? this.props.props.PROPERTY_WARD.validations.required: '',
            area: !!this.props.props.PROPERTY_AREA? this.props.props.PROPERTY_AREA.validations.required: '',
            property: !!this.props.props.PROPERTY_LIST? this.props.props.PROPERTY_LIST.validations.required: '',
            building:  !!this.props.props.PROPERTY_NAME? this.props.props.PROPERTY_NAME.validations.required: '',
            desludgeperiod: !!this.props.props.PROPERTY_DESLUDGING_PERIOD_RANGE? this.props.props.PROPERTY_DESLUDGING_PERIOD_RANGE.validations.required: '',
            desludgeperiodValue: !!this.props.props.PROPERTY_DESLUDGING_PERIOD_VALUE? this.props.props.PROPERTY_DESLUDGING_PERIOD_VALUE.validations.required: '',
            propertycontaiment:  !!this.props.props.PROPERTY_CONTAINMENT_TYPE? this.props.props.PROPERTY_CONTAINMENT_TYPE.validations.required: '',
            areaName:   !!this.props.props.PROPERTY_AREA_NAME? this.props.props.PROPERTY_AREA_NAME.validations.required: '',
            type:  !!this.props.props.PROPERTY_TYPE_LIST? this.props.props.PROPERTY_TYPE_LIST.validations.required: '',
            ResidentName:!!this.props.props&& !!this.props.props.PROPERTY_OWNER_NAME? this.props.props.PROPERTY_OWNER_NAME.validations.required:'',
            loadOrp: !!this.props.props.LOAD_SF_ORP? this.props.props.LOAD_SF_ORP.validations.required: '',
            loadts: !!this.props.props.LOAD_SF_TOTAL_SOLIDS? this.props.props.LOAD_SF_TOTAL_SOLIDS.validations.required: '',
            loadPh: !!this.props.props.LOAD_SF_PH? this.props.props.LOAD_SF_PH.validations.required: '',
            LOADELECTRICALCONDUCTIVITY: !!this.props.props.LOAD_SF_ELECTRICAL_CONDUCTIVITY? this.props.props.LOAD_SF_ELECTRICAL_CONDUCTIVITY.validations.required: '',
            loadOther:  !!this.props.props.LOAD_SF_OTHER? this.props.props.LOAD_SF_OTHER.validations.required: '',
            loadrecipt:!!this.props.props.LOAD_RECEIPT? this.props.props.LOAD_RECEIPT.validations.required: '',
            loadbednum: !!this.props.props.LOAD_TF_BED_NUMBER? this.props.props.LOAD_TF_BED_NUMBER.validations.required: '',
            operaters: !!this.props.props.OPERATOR_LIST? this.props.props.OPERATOR_LIST.validations.required: '',
            operaterName: !!this.props.props.OPERATOR_NAME? this.props.props.OPERATOR_NAME.validations.required: '',
            truckNumber: !!this.props.props.TRUCK_NUMBER? this.props.props.TRUCK_NUMBER.validations.required: '',
            propertySlum: !!this.props.props.PROPERTY_SLUM? this.props.props.PROPERTY_SLUM.validations.required: '',
            driverName:!!this.props.props.DRIVER_NAME? this.props.props.DRIVER_NAME.validations.required: '',
            loadPhoto: !!this.props.props.LOAD_PHOTO? this.props.props.LOAD_PHOTO.validations.required: '',
        }
    }
    handleChnagedField = (e)=>{
        this.setState({
            [e.target.name] : e.target.value
        })
    }
    handleSubmit = (e)=>{
        e.preventDefault();
        let payload = {
            "ulbId" :!!this.props&&this.props.id,
            "body" : {
                "data": {
                    "loads": [
                        {
                            "group": "operator",
                            "code": "LOAD_NUMBER",
                            "data_type": "NUMBER",
                            "label_en": "Load Number",
                            "label_tn": "நடை எண்",
                            "icon": "text.png",
                            "text_prefix": "",
                            "validations": {
                                "required": this.state.loadNumber
                            }
                        },
                        {
                            "group": "operator",
                            "code": "LOAD_DATE",
                            "data_type": "DATE",
                            "label_en": "Date",
                            "label_tn": "தேதி",
                            "icon": "date.png",
                            "validations": {
                                "required": this.state.date,
                                "default_value": "CURRENT_DATE",
                                "min_value": "PREVIOUS_DATE"
                            }
                        },
                        {
                            "group": "operator",
                            "code": "LOAD_TIME",
                            "data_type": "TIME",
                            "label_en": "Time",
                            "label_tn": "நேரம்",
                            "icon": "time.png",
                            "validations": {
                                "required": this.state.time,
                                "default_value": "CURRENT_TIME",
                                "min_value": "PREVIOUS_DATE"
                            }
                        },
                        {
                            "group": "operator",
                            "code": "TRUCK_LIST",
                            "data_type": "SINGLE_CHOICE_AUTO_SUGGEST",
                            "label_en": "Trucks",
                            "label_tn": "வண்டிகள்",
                            "icon": "truck.png",
                            "auto_fill": [
                                "OPERATOR_LIST"
                            ],
                            "validations": {
                                "required": this.state.truck
                            }
                        },
                        {
                            "group": "operator",
                            "code": "TRUCK_NUMBER",
                            "data_type": "TEXT",
                            "label_en": "Truck Number",
                            "label_tn": "வண்டிகள்",
                            "icon": "truck.png",
                            "conditions": [
                                {
                                    "operand_1": "TRUCK_LIST",
                                    "operand_2": "OPTION",
                                    "operand_2_value": "1",
                                    "operator": "eq",
                                    "action": {
                                        "visibility": true
                                    }
                                }
                            ],
                            "validations": {
                                "required": this.state.truckNumber,
                                "text_case": "caps_numeric"
                            }
                        },
                        {
                            "group": "operator",
                            "code": "OPERATOR_LIST",
                            "data_type": "SINGLE_CHOICE",
                            "label_en": "Operators List",
                            "label_tn": "வாகன இயக்குநர்கள்",
                            "icon": "singlechoice.png",
                            "conditions": [
                                {
                                    "operand_1": "TRUCK_LIST",
                                    "operand_2": "OPTION",
                                    "operand_2_value": "1",
                                    "operator": "eq",
                                    "action": {
                                        "showall": true
                                    }
                                }
                            ],
                            "validations": {
                                "required": this.state.operaters
                            }
                        },
                        {
                            "group": "operator",
                            "code": "OPERATOR_NAME",
                            "data_type": "TEXT",
                            "label_en": "Operator Name",
                            "label_tn": "வாகன இயக்குநர்",
                            "icon": "operator.png",
                            "conditions": [
                                {
                                    "operand_1": "OPERATOR_LIST",
                                    "operand_2": "OPTION",
                                    "operand_2_value": "1",
                                    "operator": "eq",
                                    "action": {
                                        "visibility": true
                                    }
                                }
                            ],
                            "validations": {
                                "required": this.state.operaterName,
                                "min_length": 5,
                                "max_length": 100
                            }
                        },
                        {
                            "group": "operator",
                            "code": "DRIVER_NAME",
                            "data_type": "TEXT",
                            "label_en": "Driver Name",
                            "label_tn": "இயக்கி பெயர்",
                            "icon": "text.png",
                            "validations": {
                                "required": this.state.driverName,
                                "min_length": 5,
                                "max_length": 100
                            }
                        },
                        {
                            "group": "operator",
                            "code": "CARRYING_QUANTITY",
                            "data_type": "PROGRESS_BAR",
                            "data_type_subtype": "SLIDER",
                            "label_en": "Carrying Quantity",
                            "label_tn": "ஏற்றிச்செல்லும் அளவு",
                            "icon": "text.png",
                            "validations": {
                                "required": this.state.carryqty,
                                "max_value": 10000,
                                "min_value": 500,
                                "steps": 500
                            }
                        },
                        {
                            "group": "operator",
                            "code": "REPEAT_LOAD",
                            "data_type": "BOOLEAN",
                            "label_en": "Repeat Load",
                            "label_tn": "மறு நடை",
                            "icon": "text.png",
                            "validations": {
                                "required": this.state.repertLoad
                            }
                        },
                        {
                            "group": "property",
                            "code": "PROPERTY_ULB",
                            "data_type": "SINGLE_CHOICE",
                            "label_en": "ULB (City/Town)",
                            "label_tn": "நகர்ப்புற உள்ளாட்சி அமைப்பு (ULB) - டவுன் ஃ நகரத்தின் பெயர்",
                            "icon": "property.png",
                            "validations": {
                                "required": this.state.ulb
                            }
                        },
                        {
                            "group": "property",
                            "code": "PROPERTY_WARD",
                            "data_type": "TEXT",
                            "label_en": "Ward",
                            "label_tn": "வார்டு எண்",
                            "icon": "property.png",
                            "conditions": [
                                {
                                    "operand_1": "PROPERTY_ULB",
                                    "operand_2": null,
                                    "operand_2_value": null,
                                    "operator": "is",
                                    "action": {
                                        "showall": true
                                    }
                                }
                            ],
                            "validations": {
                                "required": this.state.ward
                            }
                        },
                        {
                            "group": "property",
                            "code": "PROPERTY_AREA",
                            "data_type": "SINGLE_CHOICE_AUTO_SUGGEST",
                            "label_en": "Area",
                            "label_tn": "பகுதியின் பெயர்",
                            "icon": "property.png",
                            "conditions": [
                                {
                                    "operand_1": "PROPERTY_ULB",
                                    "operand_2": null,
                                    "operand_2_value": null,
                                    "operator": "is",
                                    "action": {
                                        "showall": true
                                    }
                                }
                            ],
                            "validations": {
                                "required": this.state.area
                            }
                        },
                        {
                            "group": "property",
                            "code": "PROPERTY_AREA_NAME",
                            "data_type": "TEXT",
                            "label_en": "Area Name",
                            "label_tn": "பகுதியின் பெயர்",
                            "icon": "truck.png",
                            "conditions": [
                                {
                                    "operand_1": "PROPERTY_AREA",
                                    "operand_2": "OPTION",
                                    "operand_2_value": "1",
                                    "operator": "eq",
                                    "action": {
                                        "visibility": true
                                    }
                                }
                            ],
                            "validations": {
                                "required": this.state.areaName
                            }
                        },
                        {
                            "group": "property",
                            "code": "PROPERTY_TYPE_LIST",
                            "data_type": "SINGLE_CHOICE",
                            "label_en": "Property Types",
                            "label_tn": "கட்டிடத்தின் வகை",
                            "icon": "property.png",
                            "validations": {
                                "required": this.state.type
                            }
                        },
                        {
                            "group": "property",
                            "code": "PROPERTY_LIST",
                            "data_type": "SINGLE_CHOICE_AUTO_SUGGEST",
                            "label_en": "Property",
                            "label_tn": "எடுத்து இடம்  ஃ கட்டிடம்",
                            "icon": "property.png",
                            "auto_fill": [
                                "PROPERTY_NAME",
                                "PROPERTY_OWNER_NAME",
                                "PROPERTY_ADDRESS"
                            ],
                            "validations": {
                                "required": this.state.property
                            }
                        },
                        {
                            "group": "property",
                            "code": "PROPERTY_NAME",
                            "data_type": "TEXT",
                            "label_en": "Building/Apartment Name",
                            "label_tn": "கட்டிடம் ஃ அடுக்குமாடியின் பெயர்",
                            "icon": "building.png",
                            "validations": {
                                "required": this.state.building,
                                "min_length": 5,
                                "max_length": 100
                            }
                        },
                        {
                            "group": "property",
                            "code": "PROPERTY_OWNER_NAME",
                            "data_type": "TEXT",
                            "label_en": "Owner / Resident Name",
                            "label_tn": "குடியிருப்பாளரின் பெயர் / வீட்டு உரிமையாளர் பெயர்",
                            "icon": "building.png",
                            "conditions": [
                                {
                                    "operand_1": "PROPERTY_TYPE_LIST",
                                    "operand_2": "OPTION",
                                    "operand_2_value": "1",
                                    "operator": "neq",
                                    "action": {
                                        "visibility": true
                                    }
                                }
                            ],
                            "validations": {
                                "required": this.state.ResidentName,
                                "min_length": 5,
                                "max_length": 100
                            }
                        },
                        {
                            "group": "property",
                            "code": "PROPERTY_ADDRESS",
                            "data_type": "TEXT",
                            "label_en": "Address / Door No / Street Name",
                            "label_tn": "முகவரி ஃ கதவு எண் ஃ தெரு பெயர்",
                            "icon": "building.png",
                            "validations": {
                                "required": this.state.address,
                                "min_length": 5,
                                "max_length": 300
                            }
                        },
                        {
                            "group": "property",
                            "code": "PROPERTY_DESLUDGING_PERIOD_RANGE",
                            "data_type": "SINGLE_CHOICE",
                            "label_en": "Desludging Period Range",
                            "label_tn": "கழிவுநீர் அகற்றும் கால இடைவெளி",
                            "icon": "property.png",
                            "validations": {
                                "required": this.state.desludgeperiod
                            }
                        },
                        {
                            "group": "property",
                            "code": "PROPERTY_DESLUDGING_PERIOD_VALUE",
                            "data_type": "TEXT",
                            "label_en": "Desludging Period Value",
                            "label_tn": "கழிவுநீர் அகற்றும் கால மதிப்பு",
                            "icon": "building.png",
                            "conditions": [
                                {
                                    "operand_1": "PROPERTY_DESLUDGING_PERIOD_RANGE",
                                    "operand_2": null,
                                    "operand_2_value": null,
                                    "operator": "is",
                                    "action": {
                                        "showall": true
                                    }
                                }
                            ],
                            "validations": {
                                "required": this.state.desludgeperiodValue,
                                "min_length": 5,
                                "max_length": 20
                            }
                        },
                        {
                            "group": "load",
                            "code": "LOAD_SF_PH",
                            "data_type": "PROGRESS_BAR",
                            "data_type_subtype": "SLIDER",
                            "label_en": "pH",
                            "label_tn": "pH",
                            "icon": "text.png",
                            "validations": {
                                "required": this.state.loadPh,
                                "max_value": 14,
                                "min_value": 0,
                                "steps": 0.1
                            }
                        },
                        {
                            "group": "load",
                            "code": "LOAD_SF_ELECTRICAL_CONDUCTIVITY",
                            "data_type": "NUMBER",
                            "label_en": "EC(mS)",
                            "label_tn": "மின் கடத்தும்திறன்",
                            "icon": "text.png",
                            "validations": {
                                "required": this.state.LOADELECTRICALCONDUCTIVITY,
                                "input_filters": "two_decimal_places",
                                "max_value": 4000,
                                "min_value": 0
                            }
                        },
                        {
                           
                                "group": "load",
                                "code": "LOAD_SF_OTHER",
                                "data_type": "TEXT",
                                "label_en": "Any Other Information",
                                "label_tn": "மற்றவை",
                                "icon": "text.png",
                                "validations": {
                                    "required": this.state.loadOther,
                                    "min_length": 1,
                                    "max_length": 300
                                }
                            
                        },
                        {
                            "group": "load",
                            "code": "LOAD_RECEIPT",
                            "data_type": "TEXT",
                            "label_en": "Receipt number",
                            "label_tn": "ரசீது எண்",
                            "text_prefix": "",
                            "icon": "text.png",
                            "validations": {
                                "required": this.state.loadrecipt
                            }
                        },
                        {
                            "group": "load",
                            "code": "LOAD_TF_BED_NUMBER",
                            "data_type": "TEXT",
                            "label_en": "FSTP Drying Bed number",
                            "label_tn": "கசடு உலர்த்தும் அறை எண்",
                            "text_prefix": "",
                            "icon": "text.png",
                            "validations": {
                                "required": this.state.loadbednum
                            }
                        },
                        {
                            "group": "load",
                            "code": "LOAD_PHOTO",
                            "data_type": "IMAGE",
                            "label_en": "Photo",
                            "label_tn": "புகைப்படம்",
                            "icon": "photo.png",
                            "validations": {
                                "required": this.state.loadPhoto
                            }
                        },
                        {
                            "group": "property",
                            "code": "PROPERTY_SLUM",
                            "data_type": "BOOLEAN",
                            "label_en": "Slum/Non-slum",
                            "label_tn": "குடிசைப்பகுதி குடிசை அல்லாத பகுதி",
                            "icon": "text.png",
                            "validations": {
                                "required": this.state.propertySlum
                            }
                        },
                        {
                            "group": "load",
                            "code": "LOAD_SF_TOTAL_SOLIDS",
                            "data_type": "NUMBER",
                            "label_en": "TS(%)",
                            "label_tn": "மொத்த திடப்பொருட்கள்",
                            "icon": "text.png",
                            "validations": {
                                "required": this.state.loadts,
                                "input_filters": "two_decimal_places",
                                "min_length": 1,
                                "max_length": 3
                            }
                        },
                        {
                            "group": "load",
                            "code": "LOAD_SF_ORP",
                            "data_type": "NUMBER",
                            "label_en": "ORP(mv)",
                            "label_tn": "ORP(mv)",
                            "icon": "text.png",
                            "validations": {
                                "required": this.state.loadOrp,
                                "max_value": 999,
                                "min_value": -999
                             }
                        },
                    ]
                },
             
            },
        }
        const data = {
            token: this.props.token,
            limit: 10,
            page:1,
            searchQuery:'',
            sortName:'',
            sortValue:''
        }
        this.props.handleSubmit(payload, data)
    }
    render() {

        return (
            <div>
             

                                <Grid container spacing={1}>
                                    {RcJson.map((item, i) => {
                                        return <>
                                            <Grid item xs={4} key={i}>
                                                <FormControl>
                                                    <label className={!!item.data.LOAD_NUMBER.validations && item.data.LOAD_NUMBER.validations.required == true ? "required" : ''} htmlFor="loadNumber">{!!item.data.LOAD_NUMBER && item.data.LOAD_NUMBER.label_en}</label>
                                                    {!!item.data.LOAD_NUMBER && item.data.LOAD_NUMBER.label_en &&

                                                        <TextField id="select" name="loadNumber" value={this.state.loadNumber} select  onChange = {this.handleChnagedField}>
                                                            <MenuItem value={true}>Yes</MenuItem>
                                                            <MenuItem value={false}>No</MenuItem>
                                                        </TextField>
                                                    }

                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl>
                                                    <label className={!!item.data.LOAD_DATE.validations && item.data.LOAD_DATE.validations.required == true ? "required" : ''} htmlFor="date">{!!item.data.LOAD_DATE && item.data.LOAD_DATE.label_en}</label>
                                                    {!!item.data.LOAD_DATE && item.data.LOAD_DATE.label_en &&
                                                        <TextField id="select" name="date" value={this.state.date} select  onChange = {this.handleChnagedField}>
                                                            <MenuItem value={true}>Yes</MenuItem>
                                                            <MenuItem value={false}>No</MenuItem>
                                                        </TextField>}
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl>
                                                    <label className={!!item.data.LOAD_TIME.validations && item.data.LOAD_TIME.validations.required == true ? "required" : ''} htmlFor="time">{!!item.data.LOAD_TIME && item.data.LOAD_TIME.label_en}</label>
                                                    {!!item.data.LOAD_TIME && item.data.LOAD_TIME.label_en &&
                                                        <TextField id="select" name="time" value={this.state.time} select  onChange = {this.handleChnagedField}>
                                                            <MenuItem value={true}>Yes</MenuItem>
                                                            <MenuItem value={false}>No</MenuItem>
                                                        </TextField>}

                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl>

                                                    <label className={!!item.data.OPERATOR_LIST.validations && item.data.OPERATOR_LIST.validations.required == true ? "required" : ''} htmlFor="operaters">{!!item.data.OPERATOR_LIST && item.data.OPERATOR_LIST.label_en}</label>
                                                    {!!item.data.OPERATOR_LIST && item.data.OPERATOR_LIST.label_en &&
                                                        <TextField id="select" name="operaters" value={this.state.operaters} select  onChange = {this.handleChnagedField}>
                                                            <MenuItem value={true}>Yes</MenuItem>
                                                            <MenuItem value={false}>No</MenuItem>
                                                        </TextField>}
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl>

                                                    <label className={!!item.data.OPERATOR_NAME.validations && item.data.OPERATOR_NAME.validations.required == true ? "required" : ''} htmlFor="operaterName">{!!item.data.OPERATOR_NAME && item.data.OPERATOR_NAME.label_en}</label>
                                                    {!!item.data.OPERATOR_NAME && item.data.OPERATOR_NAME.label_en &&
                                                        <TextField id="select" name="operaterName" value={this.state.operaterName} select  onChange = {this.handleChnagedField}>
                                                            <MenuItem value={true}>Yes</MenuItem>
                                                            <MenuItem value={false}>No</MenuItem>
                                                        </TextField>}
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={4}>
                                                <FormControl>

                                                    <label className={!!item.data.DRIVER_NAME.validations && item.data.DRIVER_NAME.validations.required == true ? "required" : ''} htmlFor="driverName">{!!item.data.DRIVER_NAME && item.data.DRIVER_NAME.label_en}</label>
                                                    {!!item.data.DRIVER_NAME && item.data.DRIVER_NAME.label_en &&
                                                        <TextField id="select" name="driverName" value={this.state.driverName} select onChange = {this.handleChnagedField}>
                                                            <MenuItem value={true}>Yes</MenuItem>
                                                            <MenuItem value={false}>No</MenuItem>
                                                        </TextField>}
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl>
                                                    <label className={!!item.data.TRUCK_LIST.validations && item.data.TRUCK_LIST.validations.required == true ? "required" : ''} htmlFor="truck">{!!item.data.TRUCK_LIST && item.data.TRUCK_LIST.label_en}</label>
                                                    {!!item.data.TRUCK_LIST && item.data.TRUCK_LIST.label_en &&
                                                        <TextField id="select" name="truck" value={this.state.truck} select  onChange = {this.handleChnagedField}>
                                                            <MenuItem value={true}>Yes</MenuItem>
                                                            <MenuItem value={false}>No</MenuItem>
                                                        </TextField>}
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl>
                                                    <label className={!!item.data.TRUCK_NUMBER.validations && item.data.TRUCK_NUMBER.validations.required == true ? "required" : ''} htmlFor="truckNumber">{!!item.data.TRUCK_NUMBER && item.data.TRUCK_NUMBER.label_en}</label>
                                                    {!!item.data.TRUCK_NUMBER && item.data.TRUCK_NUMBER.label_en &&
                                                        <TextField id="select" name="truckNumber" value={this.state.truckNumber} select  onChange = {this.handleChnagedField}>
                                                            <MenuItem value={true}>Yes</MenuItem>
                                                            <MenuItem value={false}>No</MenuItem>
                                                        </TextField>}
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl>
                                                    <label className={!!item.data.PROPERTY_SLUM.validations && item.data.PROPERTY_SLUM.validations.required == true ? "required" : ''} htmlFor="propertySlum">{!!item.data.PROPERTY_SLUM && item.data.PROPERTY_SLUM.label_en}</label>
                                                    {!!item.data.PROPERTY_SLUM && item.data.PROPERTY_SLUM.label_en &&
                                                        <TextField id="select" name="propertySlum" value={this.state.propertySlum} select onChange = {this.handleChnagedField}>
                                                            <MenuItem value={true}>Yes</MenuItem>
                                                            <MenuItem value={false}>No</MenuItem>
                                                        </TextField>}
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl>
                                                    <label className={!!item.data.PROPERTY_CONTAINMENT_TYPE.validations && item.data.PROPERTY_CONTAINMENT_TYPE.validations.required == true ? "required" : ''} htmlFor="propertycontaiment">{!!item.data.PROPERTY_CONTAINMENT_TYPE && item.data.PROPERTY_CONTAINMENT_TYPE.label_en}</label>
                                                    {!!item.data.PROPERTY_CONTAINMENT_TYPE && item.data.PROPERTY_CONTAINMENT_TYPE.label_en &&
                                                        <TextField id="select" name="propertycontaiment" value={this.state.propertycontaiment} select onChange = {this.handleChnagedField}>
                                                            <MenuItem value={true}>Yes</MenuItem>
                                                            <MenuItem value={false}>No</MenuItem>
                                                        </TextField>}
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl>

                                                    <label className={!!item.data.CARRYING_QUANTITY.validations && item.data.CARRYING_QUANTITY.validations.required == true ? "required" : ''} htmlFor="carryqty">{!!item.data.CARRYING_QUANTITY && item.data.CARRYING_QUANTITY.label_en}</label>
                                                    {!!item.data.CARRYING_QUANTITY && item.data.CARRYING_QUANTITY.label_en &&
                                                        <TextField id="select" name="carryqty" value={this.state.carryqty} select  onChange = {this.handleChnagedField}>
                                                            <MenuItem value={true}>Yes</MenuItem>
                                                            <MenuItem value={false}>No</MenuItem>
                                                        </TextField>}

                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl>
                                                    <label className={!!item.data.REPEAT_LOAD.validations && item.data.REPEAT_LOAD.validations.required == true ? "required" : ''} htmlFor="repertLoad">{!!item.data.REPEAT_LOAD && item.data.REPEAT_LOAD.label_en}</label>
                                                    {!!item.data.REPEAT_LOAD && item.data.REPEAT_LOAD.label_en &&
                                                        <TextField id="select" name="repertLoad" value={this.state.repertLoad} select  onChange = {this.handleChnagedField}>
                                                            <MenuItem value={true}>Yes</MenuItem>
                                                            <MenuItem value={false}>No</MenuItem>
                                                        </TextField>}


                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl>

                                                    <label className={!!item.data.PROPERTY_ULB.validations && item.data.PROPERTY_ULB.validations.required == true ? "required" : ''} htmlFor="ulb">{!!item.data.PROPERTY_ULB && item.data.PROPERTY_ULB.label_en}</label>
                                                    {!!item.data.PROPERTY_ULB && item.data.PROPERTY_ULB.label_en &&
                                                        <TextField id="select" name="ulb" value={this.state.ulb} select  onChange = {this.handleChnagedField}>
                                                            <MenuItem value={true}>Yes</MenuItem>
                                                            <MenuItem value={false}>No</MenuItem>
                                                        </TextField>}
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl>


                                                    <label className={!!item.data.PROPERTY_WARD.validations && item.data.PROPERTY_WARD.validations.required == true ? "required" : ''} htmlFor="ward">{!!item.data.PROPERTY_WARD && item.data.PROPERTY_WARD.label_en}</label>
                                                    {!!item.data.PROPERTY_WARD && item.data.PROPERTY_WARD.label_en &&
                                                        <TextField id="select" name="ward" value={this.state.ward} select onChange = {this.handleChnagedField}>
                                                            <MenuItem value={true}>Yes</MenuItem>
                                                            <MenuItem value={false}>No</MenuItem>
                                                        </TextField>}
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl>

                                                    <label className={!!item.data.PROPERTY_AREA.validations && item.data.PROPERTY_AREA.validations.required == true ? "required" : ''} htmlFor="area">{!!item.data.PROPERTY_AREA && item.data.PROPERTY_AREA.label_en}</label>
                                                    {!!item.data.PROPERTY_AREA && item.data.PROPERTY_AREA.label_en &&
                                                        <TextField id="select" name="area" value={this.state.area} select onChange = {this.handleChnagedField}>
                                                            <MenuItem value={true}>Yes</MenuItem>
                                                            <MenuItem value={false}>No</MenuItem>
                                                        </TextField>}

                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl>
                                                    <label className={!!item.data.PROPERTY_AREA_NAME.validations && item.data.PROPERTY_AREA_NAME.validations.required == true ? "required" : ''} htmlFor="areaName">{!!item.data.PROPERTY_AREA_NAME && item.data.PROPERTY_AREA_NAME.label_en}</label>
                                                    {!!item.data.PROPERTY_AREA_NAME && item.data.PROPERTY_AREA_NAME.label_en &&
                                                        <TextField id="select" name="areaName" value={this.state.areaName} select onChange = {this.handleChnagedField}>
                                                            <MenuItem value={true}>Yes</MenuItem>
                                                            <MenuItem value={false}>No</MenuItem>
                                                        </TextField>}

                                                </FormControl>
                                            </Grid>


                                            <Grid item xs={4}>
                                                <FormControl>

                                                    <label className={!!item.data.PROPERTY_TYPE_LIST.validations && item.data.PROPERTY_TYPE_LIST.validations.required == true ? "required" : ''} htmlFor="type">{!!item.data.PROPERTY_TYPE_LIST && item.data.PROPERTY_TYPE_LIST.label_en}</label>
                                                    {!!item.data.PROPERTY_TYPE_LIST && item.data.PROPERTY_TYPE_LIST.label_en &&
                                                        <TextField id="select" name="type" value={this.state.type} select  onChange = {this.handleChnagedField}>
                                                            <MenuItem value={true}>Yes</MenuItem>
                                                            <MenuItem value={false}>No</MenuItem>
                                                        </TextField>}
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={4}>
                                                <FormControl>

                                                    <label className={!!item.data.PROPERTY_LIST.validations && item.data.PROPERTY_LIST.validations.required == true ? "required" : ''} htmlFor="property">{!!item.data.PROPERTY_LIST && item.data.PROPERTY_LIST.label_en}</label>
                                                    {!!item.data.PROPERTY_LIST && item.data.PROPERTY_LIST.label_en &&
                                                        <TextField id="select" name="property" value={this.state.property} select onChange = {this.handleChnagedField}>
                                                            <MenuItem value={true}>Yes</MenuItem>
                                                            <MenuItem value={false}>No</MenuItem>
                                                        </TextField>}

                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl>
                                                    <label className={!!item.data.PROPERTY_NAME.validations && item.data.PROPERTY_NAME.validations.required == true ? "required" : ''} htmlFor="building">{!!item.data.PROPERTY_NAME && item.data.PROPERTY_NAME.label_en}</label>
                                                    {!!item.data.PROPERTY_NAME && item.data.PROPERTY_NAME.label_en &&
                                                        <TextField id="select" name="building" value={this.state.building} select onChange = {this.handleChnagedField}>
                                                            <MenuItem value={true}>Yes</MenuItem>
                                                            <MenuItem value={false}>No</MenuItem>
                                                        </TextField>}

                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={4}>
                                                <FormControl>
                                                    <label className={!!item.data.PROPERTY_OWNER_NAME.validations && item.data.PROPERTY_OWNER_NAME.validations.required == true ? "required" : ''} htmlFor="ResidentName">{!!item.data.PROPERTY_OWNER_NAME && item.data.PROPERTY_OWNER_NAME.label_en}</label>
                                                    {!!item.data.PROPERTY_OWNER_NAME && item.data.PROPERTY_OWNER_NAME.label_en &&
                                                        <TextField id="select" name="ResidentName" value={this.state.ResidentName} select onChange = {this.handleChnagedField}>
                                                            <MenuItem value={true}>Yes</MenuItem>
                                                            <MenuItem value={false}>No</MenuItem>
                                                        </TextField>}

                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={4}>
                                                <FormControl>
                                                    <label className={!!item.data.PROPERTY_ADDRESS.validations && item.data.PROPERTY_ADDRESS.validations.required == true ? "required" : ''} htmlFor="address">{!!item.data.PROPERTY_ADDRESS && item.data.PROPERTY_ADDRESS.label_en}</label>
                                                    {!!item.data.PROPERTY_ADDRESS && item.data.PROPERTY_ADDRESS.label_en &&
                                                        <TextField id="select" name="address" value={this.state.address} select onChange = {this.handleChnagedField}>
                                                            <MenuItem value={true}>Yes</MenuItem>
                                                            <MenuItem value={false}>No</MenuItem>
                                                        </TextField>}

                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={4}>
                                                <FormControl>



                                                    <label className={!!item.data.PROPERTY_DESLUDGING_PERIOD_RANGE.validations && item.data.PROPERTY_DESLUDGING_PERIOD_RANGE.validations.required == true ? "required" : ''} htmlFor="desludgeperiod">{!!item.data.PROPERTY_DESLUDGING_PERIOD_RANGE && item.data.PROPERTY_DESLUDGING_PERIOD_RANGE.label_en}</label>
                                                    {!!item.data.PROPERTY_DESLUDGING_PERIOD_RANGE && item.data.PROPERTY_DESLUDGING_PERIOD_RANGE.label_en &&
                                                        <TextField id="select" name="desludgeperiod" value={this.state.desludgeperiod} select onChange = {this.handleChnagedField}>
                                                            <MenuItem value={true}>Yes</MenuItem>
                                                            <MenuItem value={false}>No</MenuItem>
                                                        </TextField>}

                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl>

                                                    <label className={!!item.data.PROPERTY_DESLUDGING_PERIOD_VALUE.validations && item.data.PROPERTY_DESLUDGING_PERIOD_VALUE.validations.required == true ? "required" : ''} htmlFor="desludgeperiodValue">{!!item.data.PROPERTY_DESLUDGING_PERIOD_VALUE && item.data.PROPERTY_DESLUDGING_PERIOD_VALUE.label_en}</label>
                                                    {!!item.data.PROPERTY_DESLUDGING_PERIOD_VALUE && item.data.PROPERTY_DESLUDGING_PERIOD_VALUE.label_en &&
                                                        <TextField id="select" name="desludgeperiodValue" value={this.state.desludgeperiodValue} select onChange = {this.handleChnagedField}>
                                                            <MenuItem value={true}>Yes</MenuItem>
                                                            <MenuItem value={false}>No</MenuItem>
                                                        </TextField>}

                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={4}>
                                                <FormControl>

                                                    <label className={!!item.data.LOAD_SF_ORP.validations && item.data.LOAD_SF_ORP.validations.required == true ? "required" : ''} htmlFor="loadOrp">{!!item.data.LOAD_SF_ORP && item.data.LOAD_SF_ORP.label_en}</label>
                                                    {!!item.data.LOAD_SF_ORP && item.data.LOAD_SF_ORP.label_en &&
                                                        <TextField id="select" name="loadOrp"  value={this.state.loadOrp} select onChange = {this.handleChnagedField}>
                                                            <MenuItem value={true}>Yes</MenuItem>
                                                            <MenuItem value={false}>No</MenuItem>
                                                        </TextField>}

                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={4}>
                                                <FormControl>

                                                    <label className={!!item.data.LOAD_SF_TOTAL_SOLIDS.validations && item.data.LOAD_SF_TOTAL_SOLIDS.validations.required == true ? "required" : ''} htmlFor="loadts">{!!item.data.LOAD_SF_TOTAL_SOLIDS && item.data.LOAD_SF_TOTAL_SOLIDS.label_en}</label>
                                                    {!!item.data.LOAD_SF_TOTAL_SOLIDS && item.data.LOAD_SF_TOTAL_SOLIDS.label_en &&
                                                        <TextField id="select" name="loadts"  value={this.state.loadts} select onChange = {this.handleChnagedField}>
                                                            <MenuItem value={true}>Yes</MenuItem>
                                                            <MenuItem value={false}>No</MenuItem>
                                                        </TextField>}

                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={4}>
                                                <FormControl>

                                                    <label className={!!item.data.LOAD_SF_PH.validations && item.data.LOAD_SF_PH.validations.required == true ? "required" : ''} htmlFor="loadPh">{!!item.data.LOAD_SF_PH && item.data.LOAD_SF_PH.label_en}</label>
                                                    {!!item.data.LOAD_SF_PH && item.data.LOAD_SF_PH.label_en &&
                                                        <TextField id="select" name="loadPh" value={this.state.loadPh}  select onChange = {this.handleChnagedField}>
                                                            <MenuItem value={true}>Yes</MenuItem>
                                                            <MenuItem value={false}>No</MenuItem>
                                                        </TextField>}

                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={4}>
                                                <FormControl>

                                                    <label className={!!item.data.LOAD_SF_ELECTRICAL_CONDUCTIVITY.validations && item.data.LOAD_SF_ELECTRICAL_CONDUCTIVITY.validations.required == true ? "required" : ''} htmlFor="LOADELECTRICALCONDUCTIVITY">{!!item.data.LOAD_SF_ELECTRICAL_CONDUCTIVITY && item.data.LOAD_SF_ELECTRICAL_CONDUCTIVITY.label_en}</label>
                                                    {!!item.data.LOAD_SF_ELECTRICAL_CONDUCTIVITY && item.data.LOAD_SF_ELECTRICAL_CONDUCTIVITY.label_en &&
                                                        <TextField id="select" name="LOADELECTRICALCONDUCTIVITY" value={this.state.LOADELECTRICALCONDUCTIVITY}  select onChange = {this.handleChnagedField}>
                                                            <MenuItem value={true}>Yes</MenuItem>
                                                            <MenuItem value={false}>No</MenuItem>
                                                        </TextField>}

                                                </FormControl>
                                            </Grid>


                                            <Grid item xs={4}>
                                                <FormControl>

                                                    <label className={!!item.data.LOAD_SF_OTHER.validations && item.data.LOAD_SF_OTHER.validations.required == true ? "required" : ''} htmlFor="loadOther">{!!item.data.LOAD_SF_OTHER && item.data.LOAD_SF_OTHER.label_en}</label>
                                                    {!!item.data.LOAD_SF_OTHER && item.data.LOAD_SF_OTHER.label_en &&
                                                        <TextField id="select" name="loadOther" value={this.state.loadOther} select onChange = {this.handleChnagedField}>
                                                            <MenuItem value={true}>Yes</MenuItem>
                                                            <MenuItem value={false}>No</MenuItem>
                                                        </TextField>}

                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={4}>
                                                <FormControl>

                                                    <label className={!!item.data.LOAD_RECEIPT.validations && item.data.LOAD_RECEIPT.validations.required == true ? "required" : ''} htmlFor="loadOther">{!!item.data.LOAD_RECEIPT && item.data.LOAD_RECEIPT.label_en}</label>
                                                    {!!item.data.LOAD_RECEIPT && item.data.LOAD_RECEIPT.label_en &&
                                                        <TextField id="select" name="loadrecipt" value={this.state.loadrecipt} select onChange = {this.handleChnagedField}>
                                                            <MenuItem value={true}>Yes</MenuItem>
                                                            <MenuItem value={false}>No</MenuItem>
                                                        </TextField>}

                                                </FormControl>
                                            </Grid>


                                            <Grid item xs={4}>
                                                <FormControl>

                                                    <label className={!!item.data.LOAD_PHOTO.validations && item.data.LOAD_PHOTO.validations.required == true ? "required" : ''} htmlFor="loadOther">{!!item.data.LOAD_PHOTO && item.data.LOAD_PHOTO.label_en}</label>
                                                    {!!item.data.LOAD_PHOTO && item.data.LOAD_PHOTO.label_en &&
                                                        <TextField id="select" name="loadPhoto" value={this.state.loadPhoto} select onChange = {this.handleChnagedField}>
                                                            <MenuItem value={true}>Yes</MenuItem>
                                                            <MenuItem value={false}>No</MenuItem>
                                                        </TextField>}

                                                </FormControl>
                                            </Grid>


                                            <Grid item xs={4}>
                                                <FormControl>

                                                    <label className={!!item.data.LOAD_TF_BED_NUMBER.validations && item.data.LOAD_TF_BED_NUMBER.validations.required == true ? "required" : ''} htmlFor="loadOther">{!!item.data.LOAD_TF_BED_NUMBER && item.data.LOAD_TF_BED_NUMBER.label_en}</label>
                                                    {!!item.data.LOAD_TF_BED_NUMBER && item.data.LOAD_TF_BED_NUMBER.label_en &&
                                                        <TextField id="select" name="loadbednum" value={this.state.loadbednum} select onChange = {this.handleChnagedField}>
                                                            <MenuItem value={true}>Yes</MenuItem>
                                                            <MenuItem value={false}>No</MenuItem>
                                                        </TextField>}

                                                </FormControl>
                                            </Grid>
                                        </>

                                    })}

                                </Grid>

                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <div className="create_ulb_buttons">
                                            <Link style={{ textDecoration: "none", color: "#3dab35" }} to="/setting"><Button className="login_submit" variant="outlined" color="primary">Cancel</Button></Link>
                                            <Button className="login_submit" variant="contained" color="primary" onClick={this.handleSubmit}>Save</Button>
                                        </div>
                                    </Grid>
                                </Grid>

            </div>
        )
    }
}

const mapStateToProps = (state,props) => {
    
    return{
        props:props,
        token:state.auth.token
    }
}
const mapDispatchToProps = (dispatch) => {
    return ({
        // onFetchRCData: (payload, data) => dispatch(actionsCreators.configuration(payload, data)),
    
    })
   
}

export default connect(mapStateToProps,  mapDispatchToProps)(RcConfiguration)
