import React,{Component} from 'react'
import Chart from './Chart'
import CardFinal from './CardFinal'
import CardMain from './CardMain'
import { connect } from 'react-redux';


import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler.js';
import axios from '../../http-config';
import * as actionsCreators from  '../../store/actions/index';


class Dashboard extends Component{


  state = {
    filter : {
      'interval':'',
    },
    data:[],
    id:'',
    isDisabled:false,
    interval:'Current Day'
  }
  componentDidMount=()=>{
    let payload = {
      token: this.props.token,
      filter: JSON.stringify([this.state.filter]),
      interval:this.state.interval
  }
    //  this.props.onFetchDashboardData(payload)
  }

     render(){
     return(
       <div style={{marginRight:'1%'}}>
        <CardMain dashboardProps={{no_of_loads:this.props.data.dashboard_data}}/>
         {/* <Chart/> */}
       </div>
     )
  }
}

const mapStateToProps = state => {
  return {
    data: state.dashboard.dashboard,
    loading: state.loads.loading,
    token: state.auth.token
    
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchDashboardData: (payload) => dispatch(actionsCreators.fetchDashboard(payload))
  }
}

export default  connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(Dashboard, axios));

