import * as actionTypes from '../../actions/actionsTypes'
import { updateObject } from '../../utility'

export const initialState = {
    iotChartData : [],
    metaData : [],
    filteredData:[],
    iotTableData :[],
    loading:false,
    iotChartDataSucess:true
}

export const fetchChartDataStart = (state, action) => {
    return updateObject(state, {iotChartDataSucess : false})
}

export const fetchChartMetaDataStart = (state, action) =>{
    return updateObject(state, {iotChartDataSucess : false})
}


export const fetchChartMetaDataSuccess = (state, action) =>
{
    return updateObject(state, {
        metaData : action.metaData,
        // iotChartDataSucess: true
    })
}

export const fetchTableDataStart = (state, action) =>{
    return updateObject(state, {iotChartDataSucess : false})
}


export const fetchTableDataSuccess = (state, action) =>
{
    return updateObject(state, {
        iotTableData : action.iotTableData,
        iotChartDataSucess: true
    })
}


export const fetchChartDataSuccess = (state, action) =>
{
    return updateObject(state, {
    iotChartData : action.iotChartData,
    iotChartDataSucess: true
    })
}

const fetchChartDataFail = (state, action) => {
    return updateObject(state, {
        iotChartDataSucess: true
    })
}

export const fetchFilteredDataStart = (state, action) =>
{
    return updateObject(state, {iotChartDataSucess : false})
}

export const fetchFilteredDataSuccess = (state, action) =>
{
    return updateObject(state, {
    filteredData : action.filteredData,
    iotChartDataSucess: true
    })
}
const iotChartDataReducer = (state=initialState, action) =>
{
    switch(action.type)
    {
        case actionTypes.FETCH_IOT_CHART_DATA_START : return fetchChartDataStart(state, action)
        case actionTypes.FETCH_IOT_CHART_DATA_SUCCESS : return fetchChartDataSuccess(state, action)
        case actionTypes.FETCH_CHART_META_DATA_START : return fetchChartMetaDataStart(state, action)
        case actionTypes.FETCH_CHART_META_DATA_SUCCESS : return fetchChartMetaDataSuccess(state, action)
        case actionTypes.FETCH_IOT_CHART_DATA_FAIL : return fetchChartDataFail(state,action)
        case actionTypes.FETCH_FILTEREDDATA_START : return fetchFilteredDataStart(state, action)
        case actionTypes.FETCH_FILTEREDDATA_SUCCESS : return fetchFilteredDataSuccess(state,action)
        case actionTypes.FETCH_IOT_TABLE_DATA_START : return fetchTableDataStart(state, action)
        case actionTypes.FETCH_IOT_TABLE_DATA_SUCCESS : return fetchTableDataSuccess(state,action)
        default : return state
    }
}
export default iotChartDataReducer