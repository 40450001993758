import React, { Component, useEffect } from 'react'
import { connect } from 'react-redux'
import InputBase from '@material-ui/core/InputBase';
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import swal from 'sweetalert'
import axios from '../../http-config'
import moment from 'moment'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReactPaginate from 'react-paginate';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import * as actionsCreators from '../../store/actions/index'
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { Grid, } from '@material-ui/core';
import Select from '@material-ui/core/Select';
export class ZonesAndWardsListing extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: this.props.match ? this.props.match.params.id : '',
            page: 1,
            limit: 10,
            currentSortId: '',
            sortObj: {
                key: '',
                value: ''
            },
            anchorEl: null,
            open: false,
            zoneWardData: [],
            openZoneWardEdit: false,
            zonesOptions: [],
            wardsOptions: [],
            zone: "",
            wardSelectionList:[],
            sId:"",
            EditsId:""
            // controls: {
            //     search: {
            //         value: '',
            //     }
            // },
        }
    }

    componentDidMount() {
        const payload = {
            token: this.props.token,
            page: this.state.page,
            limit: this.state.limit,
            id: this.state.id,
            sortName: this.state.sortObj.key,
            sortValue: this.state.sortObj.value,
            // searchQuery: this.state.controls.search.value
        }
        this.props.onFetchSiZonesWardLIsting(payload)
        if (localStorage.getItem("isZoneNotRequired") === "false") {
            axios({
                method: 'get',
                url: `/zone/getZones`,
                headers: { 'Authorization': `Bearer ${this.props.token}` }
            })
                .then((response) => {
                    this.setState({ zonesOptions: response.data.data.zones })
                })
        }
    }

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        this.setState({ page: selectedPage + 1 })
        let payload = {
            token: this.props.token,
            page: selectedPage + 1,
            limit: this.state.limit,
            id: this.state.id,
            sortName: this.state.sortObj.key,
            sortValue: this.state.sortObj.value,
            // searchQuery: this.state.controls.search.value
        }
        this.props.onFetchSiZonesWardLIsting(payload)
    };
    handleSort = (e, sortBy) => {
        let sortObj
        if (this.state.sortObj.key === sortBy.accessor) {
            let value = (this.state.sortObj.value === 'desc') ? 'asc' : 'desc';
            sortObj = {
                key: sortBy.accessor,
                value: value
            }
        }
        else {
            sortObj = {
                key: sortBy.accessor,
                value: 'desc'
            }
        }

        this.setState({ sortObj: sortObj, currentSortId: sortBy.accessor });
        let payload = {
            token: this.props.token,
            sortName: sortObj.key,
            sortValue: sortObj.value,
            page: this.state.page,
            limit: this.state.limit,
            id: this.state.id,
            // searchQuery: this.state.controls.search.value
        }

        this.props.onFetchSiZonesWardLIsting(payload);

    }

    handleClickMoreVertIcon = (event, zoneWardData, sId) => {
        this.setState({ open: !this.state.open, anchorEl: event.currentTarget, zoneWardData, sId })
    }
    handleClickAwayEdit = () => {
        this.setState({ open: false });
    };
    //   handlePropertySearch = (e) => {
    //     e.preventDefault()
    //     let searchVal = this.state.controls.search.value
    //     if (!!searchVal && searchVal.length >= 2) {
    //         let payload = {
    //             token: this.props.token,
    //             searchQuery:searchVal,
    //             limit:10,
    //             page:this.state.page,
    //             // propertyTypeId: this.state.filterValue,
    //             sortName:this.state.sortObj.key,
    //             sortValue :this.state.sortObj.value,

    //         }
    //         this.props.OnSearchClusterName(payload) 
    //     } else {

    //         swal("Enter atleast 2 characters")
    //     }
    // }
    // inputChangedHadler = (event, controlName) => {
    //     const updatedControls = {
    //         ...this.state.controls,
    //         [controlName]: {
    //             ...this.state.controls[controlName],
    //             value: event.target.value,
    //         }
    //     }
    //     this.setState({ controls: updatedControls });
    //     if( updatedControls.search.value === ''){
    //         let payload = {
    //             token: this.props.token,
    //             searchQuery:'',
    //             limit:this.state.limit,
    //             page:this.state.page,
    //             // propertyTypeId: this.state.filterValue,
    //             sortName:this.state.sortObj.key,
    //             sortValue :this.state.sortObj.value,
    //         }
    //         this.props.OnSearchClusterName(payload) 
    //     }

    // }

    // handleExport = () => {
    //     let payload = {
    //         token: this.props.token,
    //         page: this.state.page,
    //         limit: this.state.limit,
    //         sortName:this.state.sortObj.key,
    //         sortValue :this.state.sortObj.value,
    //         searchQuery: this.state.controls.search.value

    //     }
    //     this.props.onFetchClusterULBListingExport(payload)
    // }
    handleEditZoneWard = (data) => {
            axios({
                method: 'get',
                url: localStorage.getItem("isZoneNotRequired") === "true" ? `/ward` : `/ward?zoneId=${data.ZoneId}`,
                headers: { 'Authorization': `Bearer ${this.props.token}` }
            })
            .then((response) => {
                this.setState({ wardsOptions: response.data.data.wards })
            }).catch((err) => {
                console.log(err, "errr");
            })
        this.setState({EditsId:data.sId, zone: data.ZoneId, wardSelectionList: JSON.parse(data.siWardid),  openZoneWardEdit: true })

        setTimeout(() => {
            const filteredWardsOptions = this.state.wardsOptions.filter((option) =>
            !this.state.wardSelectionList.some((opt) => option.wardNumber === opt.wardNumber)
          );
          this.setState({ wardsOptions: filteredWardsOptions })
        },300)
    }
    handleDelete = (id) => {
        const payload = {
            token: this.props.token,
            page: this.state.page,
            limit: this.state.limit,
            sortName: this.state.sortObj.key,
            sortValue: this.state.sortObj.value,
            id: this.state.id,
            sId : id

        }
        this.props.onDeleteUser(payload)
    }

    handleChange = (event) => {
        if (event.target.name == "zone") {
            if(this.state.wardSelectionList.length >0){
                swal({
                    icon:"warning",
                    title:"First save selected zone & ward!"
                })
            }
            else {
                this.setState({
                    [event.target.name]: event.target.value,
                })
            }
            axios({
                method: 'get',
                url: localStorage.getItem("isZoneNotRequired") === "true" ? `/ward` : `/ward?zoneId=${event.target.value}`,
                headers: { 'Authorization': `Bearer ${this.props.token}` }
            })
                .then((response) => {
                    this.setState({ wardsOptions: response.data.data.wards })
                }).catch((err) => {
                    console.log(err, "errr");
                })
        }
    }
    handleSelectedWard = (value) => {
        let selectedWardList = this.state.wardSelectionList
        let arr = []
        value.forEach(element => {
            arr.push(element)
        });
        this.setState({ wardSelectionList: [...arr] })
       if(selectedWardList.length <= arr.length) return;
        let option = this.state.wardsOptions

        const removedItems = selectedWardList.filter((item) => !arr.includes(item));
        removedItems.forEach((removedItem) => {
            if (!option.some((existingItem) => existingItem.wardNumber === removedItem.wardNumber)) {
              option.push(removedItem);
            }
          }); 
        this.setState({ wardsOptions: option });
    }
    handleCloseEdit = () => {
        this.setState({wardSelectionList: [], wardsOptions:[],  openZoneWardEdit: false, zone:"" })
    }
    handleSubmitEdit =() => {
        const data = {
            sId :this.state.EditsId,
            ZoneId : this.state.zone,
            siWardid: this.state.wardSelectionList
        }
        const payload = {
            token: this.props.token,
            page: this.state.page,
            limit: this.state.limit,
            sortName: this.state.sortObj.key,
            sortValue: this.state.sortObj.value,
            id: this.state.id
        }
        this.setState({wardSelectionList: [], wardsOptions:[],  openZoneWardEdit: false, zone:"", EditsId:"" })

        this.props.onEditSiZoneWardData(data, payload)
    }
    render() {
        let header = [
            {
                accessor: "sId",
                filter: "false",
                header: "Sr.no",
                sortable: "false",
            },
            {
                accessor: "Name",
                filter: "false",
                header: "Zone Name",
                sortable: "false",
            },
            {
                accessor: "siWardid",
                filter: "false",
                header: "Ward",
                sortable: "false",
            },
            {
                accessor: "actionButton",
                filter: "false",
                header: "Action",
                sortable: "false",
            }

        ]
        const Headers = !!header && header.map(header => {
            return (
                <TableCell key={header.header} className={"white-space"}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'start',
                        whiteSpace: 'break-spaces',
                        height: '3em'
                    }}>
                        <div onClick={(e) => this.handleSort(e, header)}>
                            {header.header === 'Ward' || header.header == "Action" ? '' : <div>{
                                this.state.currentSortId === header.accessor ? this.state.sortObj.value === 'asc' ? (<ArrowDropDownIcon fontSize="small" />) : (<ArrowDropUpIcon fontSize="small" />) : (<ArrowDropDownIcon fontSize="small" />)
                            }
                            </div>}
                        </div>
                        {header.header}
                    </div>
                </TableCell>
            )
        })
        const listingInspector = !!this.props.zoneAndWardListing && this.props.zoneAndWardListing.map((data, index) => {
        const sId = data.sId
            return (
                <TableRow key={index}>
                    {!!header && header.map(header => {
                        if (header.accessor == "siWardid") {
                            const wardList = JSON.parse((data["siWardid"]))
                            return <TableCell key={index}>
                                {wardList.map((item) => {
                                    return <TableRow style={{
                                        whiteSpace: "normal",
                                        wordWrap: "break-word",
                                        alignItems: 'center',
                                    }}>
                                        {item["wardNumber"]}
                                    </TableRow>

                                })}
                            </TableCell>
                        } else if (header.accessor == 'actionButton') {
                            return <TableCell>
                                <IconButton onClick={(e) => this.handleClickMoreVertIcon(e, data, sId)}
                                    aria-label="more"
                                    aria-controls="long-menu"
                                    aria-haspopup="true">
                                    <MoreVertIcon fontSize='small' />
                                </IconButton>
                                <Popper open={this.state.open} anchorEl={this.state.anchorEl}>
                                    <ClickAwayListener onClickAway={this.handleClickAwayEdit}>
                                        <Paper style={{ boxShadow: "none", border: "1px solid rgb(61, 171, 53)" }}>
                                            <MenuList>
                                                <MenuItem onClick={() => this.handleEditZoneWard(this.state.zoneWardData)}>{("Edit")}</MenuItem>

                                                <MenuItem style={{ color: '#3dab35' }} onClick={() => {
                                                    swal({
                                                        title: "Are you sure?",
                                                        text: "Once deleted, you will not be able to recover this data!",
                                                        icon: "warning",
                                                        buttons: {
                                                            cancel: 'No',
                                                            confirm: 'Yes'
                                                        }
                                                    })
                                                        .then((willDelete) => {
                                                            if (willDelete) {
                                                                this.handleDelete(this.state.sId);
                                                            }
                                                        });
                                                }}>Delete</MenuItem>
                                            </MenuList>
                                        </Paper>
                                    </ClickAwayListener>
                                </Popper>
                            </TableCell>
                        }

                        else {
                            return <TableCell style={{
                                whiteSpace: "normal",
                                wordWrap: "break-word"
                            }} key={header.accessor}>
                                {data[header.accessor]}
                            </TableCell>
                        }
                    })
                    }
                </TableRow>
            )
        })
        return (
            <div>
                <div className="dashboard_right_content">
                    <img className="dashboard_right_content_top_icon" src="images/Rectangle.svg" />
                    <div className="dashboard_right_content_top">
                        <h5><Link to="/Sanitory-Manegement" className="email_link"><ArrowBackIosIcon></ArrowBackIosIcon></Link> Assinged Zone/Ward Listing </h5>

                    </div>
                </div>
                <div className="ulb_listing " >
                    <TableContainer component={Paper} style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                        <Table stickyHeader aria-label="sticky table" style={{ tableLayout: 'fixed' }}>

                            <TableHead >
                                <TableRow >
                                    {Headers}
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {this.props.zoneAndWardListing.length === 0 ? <TableRow style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', }}>
                                    No Records Found !!</TableRow> : listingInspector}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                {
                    this.props.totalCountzone && this.props.totalCountzone != 0 &&
                    <ReactPaginate
                        previousLabel={"Prev"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.props.totalCountzone / this.state.limit}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        style={{ borderLeftWidth: '0px' }}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"} />
                }


                <div className="setFreq">
                    <Dialog
                        maxWidth={'md'}
                        fullWidth={true}
                        open={this.state.openZoneWardEdit}
                        aria-labelledby="responsive-dialog-title">
                        <DialogTitle className="text-center border-none " style={{ color: '#3dab35' }}><span style={{ fontSize: '18px' }}>Edit Zone/ Ward </span></DialogTitle>
                        <DialogContent >
                        <div className="create_ulb_content_outer">
                                <div className="create_ulb_content create_ulb_contentPop">
                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                            <label >Zone Name</label>
                                            <FormControl disabled>
                                                <Select value={this.state.zone} onChange={this.handleChange} name="zone">
                                                    {this.state.zonesOptions.map((option) => {
                                                        return (
                                                            <MenuItem value={option.Id}>{option.Name}</MenuItem>
                                                        )
                                                    })}
                                                </Select>

                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <label className="required" htmlFor="ulb">Wards</label>
                                            <Autocomplete
                                                multiple
                                                id="tags-outlined"
                                                options={this.state.wardsOptions}
                                                getOptionLabel={(option) => option.wardNumber}
                                                defaultValue={this.state.wardSelectionList}
                                                filterSelectedOptions
                                                onChange={(event, value) => this.handleSelectedWard(value)}
                                                renderInput={(params) => {
                                                    return (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            placeholder="Select ward"
                                                        />
                                                    )
                                                }}
                                            />
                                         <div style={{ fontSize: 12, color: "red",marginTop:'5px' }}>* Assigned wards can not be re-assigned.</div> 
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <div className="create_ulb_buttons" style={{marginBottom:"30px", marginTop:"20px"}}>
                                                <Button className="login_submit" variant="outlined" color="primary"  onClick={this.handleCloseEdit}>{("Cancel")}</Button>

                                                <Button className="login_submit" variant="contained" color="primary" onClick={this.handleSubmitEdit}>{"Save change"}</Button>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.auth.token,
        listingInspector: state.siReducer.dashboardListing,
        totalCount: state.siReducer.totalCount,
        zoneAndWardListing: state.siReducer.zoneAndWardListing,
        totalCountzone: state.siReducer.totalCountzone
    }
}
const mapDispatchToProps = (dispatch) => {
    return ({
        onFetchSiZonesWardLIsting: (payload) => dispatch(actionsCreators.fetchSiZonesWardLIsting(payload)),
        onDeleteSIUser: (payload) => dispatch(actionsCreators.deleteSIUser(payload)),
        onDeleteUser: (payload) => dispatch(actionsCreators.deleteUlbOfficerZoneward(payload)),
        onEditSiZoneWardData :(data, payload) => dispatch(actionsCreators.editSiZoneWardData(data, payload))

    })
}

export default connect(mapStateToProps, mapDispatchToProps)(ZonesAndWardsListing);










