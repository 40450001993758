import React, { Component } from 'react'
import { connect } from 'react-redux';
import {Link, Switch} from 'react-router-dom'

import * as actionsCreators from  '../../store/actions/index';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import axios from '../../http-config';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment'
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import swal from 'sweetalert';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid, Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import ReactPaginate from 'react-paginate';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Fade from '@material-ui/core/Fade';
import * as User  from '../../config/constant';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Box from '@material-ui/core/Box';
import clsx from 'clsx';
const BootstrapInput = withStyles((theme) => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor:"#fff",
      border: '1px solid #4CAF50',
      fontSize: 15,
      padding: '7px 26px 7px 20px',
      transition: theme.transitions.create(['border-color']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        'Open Sans',
        'sans-serif',
   
      ].join(','),
      '&:focus ': {
        borderRadius: 4,
            backgroundColor:"#fff !important"
   
      }
    },
  }))(InputBase);

  const useStyles = makeStyles({
    root: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    icon: {
      borderRadius: '50%',
      width: 16,
      height: 16,
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#f5f8fa',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
      },
      'input:hover ~ &': {
        backgroundColor: '#ebf1f5',
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
      },
    },
    checkedIcon: {
      backgroundColor: '#3dab35',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
      },
      'input:hover ~ &': {
        backgroundColor: '#3dab35',
      },
    },
  });
  
  // Inspired by blueprintjs
  function StyledRadio(props) {
    const classes = useStyles();
  
    return (
      <Radio
        className={classes.root}
        disableRipple
        color="default"
        checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
        icon={<span className={classes.icon} />}
        {...props}
      />
    );
  }


  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }
class Index extends React.Component {
    constructor(props){
        super(props)
        this.state ={
        WeekDay:this.props.configurableDay,
        dayChanged: false,
        statedashboardValue:localStorage.getItem("StatedashboardValue") == "true"? true: localStorage.getItem("StatedashboardValue") == "false"?false : false,
        ulbData:[],
        requiredZone:localStorage.getItem('isZoneNotRequired'),
        openEmailPopup:false,
        emailSelect: 'yes',
        email: localStorage.getItem('summmaryReportsEmail') !== '' ?localStorage.getItem('summmaryReportsEmail') != 'null'? localStorage.getItem('summmaryReportsEmail'):'' : '',
        reportEmail: localStorage.getItem('ReportsEmail') !== ""? localStorage.getItem('ReportsEmail'):'',
        value:0, 
        collecton:localStorage.getItem('isRepeatCollection')&&localStorage.getItem('isRepeatCollection')== "true"? true:localStorage.getItem('isRepeatCollection')&&localStorage.getItem('isRepeatCollection')=="false"? false:null,
        LName:"",
        FName:"",
        ConfigurableDayUserSec:"",
        ConfigurableDayUser:'',
        FNameSec:"",
        LNameSec:"",
        emailId:"",
        secId:"",
        emailIdSec:"",
        FirstId:"",
        ulbId:localStorage.getItem("ulbid"),
        designation:"",
        designationSec:""
        // operatorIds :!!this.props.location&& !!this.props.location.state? this.props.location.state.operatorIds:""
        }
    }
    componentDidMount(){
      // const ulbid = localStorage.getItem("ulbid")
      if(this.state.ulbId !== null){
        const url = `/ulb/${this.state.ulbId}`
        axios({
                        method   : 'get',
                        url      : url,
                        headers  : {'Authorization': `Bearer ${this.props.token}`}
                   })
                   .then((response) => {
                    this.setState({ulbData:[response.data.data.ulbs[0]]})
                    localStorage.setItem('ReportsEmail',response.data.data.ulbs[0].email)
                   })
                   .catch((err) => {
                       
                   })
                }

                axios({
                  method   : 'get',
                  url      : "user/user_matrixs",
                  headers  : {'Authorization': `Bearer ${this.props.token}`}
             })
             .then((response) => {
              this.setState({
                FirstId:response.data.data? response.data.data.userMatrixData[0].Id:"",
                FName:response.data.data? response.data.data.userMatrixData[0].FirstName:"", 
                LName:response.data.data? response.data.data.userMatrixData[0].LastName:'',
                emailId:response.data.data? response.data.data.userMatrixData[0].Email:"",
                ConfigurableDayUser:response.data.data? response.data.data.userMatrixData[0].ConfigurableDay:"",
                secId:response.data.data? response.data.data.userMatrixData[1].Id:"",
                FNameSec:response.data.data? response.data.data.userMatrixData[1].FirstName:"",
                LNameSec:response.data.data? response.data.data.userMatrixData[1].LastName:"",  
                ConfigurableDayUserSec:response.data.data? response.data.data.userMatrixData[0].ConfigurableDay:"",
                emailIdSec:response.data.data? response.data.data.userMatrixData[1].Email:"",
                designation:response.data.data? response.data.data.userMatrixData[0].Designation:"",
                designationSec:response.data.data? response.data.data.userMatrixData[1].Designation:""

              })
             })
             .catch((err) => {
                 
             })


    }
    handleDayChange= (e) => {
        this.setState ({
            WeekDay: e.target.value,
            dayChanged:true
        },()=>
        this.onClickExport());
      };


      handleDayChangeUser=(e)=>{
        this.setState ({
          ConfigurableDayUser: e.target.value,
          dayChangedUser:true
      })
      }
      handleDayChangeUserSec=(e)=>{
        this.setState ({
          ConfigurableDayUserSec: e.target.value,
          dayChangedUser:true
      })
      }
    onClickExport=(e)=>{
        
        let payload = {           
            token: this.props.token,
            "configurableDay":this.state.WeekDay          
        }
        this.props.onAddDayData(payload);
    };

    handleZoneChange=(e)=>{
      this.setState({requiredZone: e.target.value},()=>{
          let payload = {
              token: this.props.token,
              "isZoneNotRequired": this.state.requiredZone
          }
          this.props.oNisZoneRequired(payload)
      })

  }
   handleChange = (event, newValue) => {
    this.setState({[event.target.name]:event.target.value});

  };

  handleChangeTab =(event, newValue)=>{
    this.setState({value:newValue})
  }
// Configure report
  openEmailCong =()=>{
    this.setState({openEmailPopup: true})
    const ulbid    = JSON.parse(localStorage.getItem('ulbid'))

    if(ulbid !== null){
        const url = `/ulb/${ulbid}`
        axios({
                        method   : 'get',
                        url      : url,
                        headers  : {'Authorization': `Bearer ${localStorage.getItem('token')}`}
                   })
                   .then((response) => {
                      this.setState({email:response.data.data.ulbs[0].summmaryReportsEmail})
                      localStorage.setItem('summmaryReportsEmail',response.data.data.ulbs[0].summmaryReportsEmail )
                      localStorage.setItem('ReportsEmail',response.data.data.ulbs[0].email)
                   })
                   .catch((err) => {
                       
                   })
                }
}
handleEmaiClose =()=>{
    this.setState({openEmailPopup: false})
}
handleChangeEmailSelect = (e)=>{
    this.setState({emailSelect:e.target.value })
    
   
}
handleChangeEmail = (e)=>{
this.setState({email: e.target.value})
}
UpadatedEmail = (e)=>{
    e.preventDefault()
    let payload = {
        token : this.props.token,
        "summmaryReportsEmail": this.state.emailSelect == 'yes' ?this.state.email: null
    }
    this.props.onEmailUpdate(payload)
    this.setState({openEmailPopup: false})
}
//end

handleCollectionChange =(e)=>{
  this.setState({collecton: e.target.value},()=>{
      let payload = {
          token: this.props.token,
          "isRepeatCollection": this.state.collecton
      }
      this.props.FetchCollectioRequired(payload)
  })
}

handleStatedashboardValue =(e)=>{
  this.setState({statedashboardValue: e.target.value}, ()=>{
    let payload ={
      token: this.props.token,
      statedashboardValue: this.state.statedashboardValue

    }
    this.props.Onstate_dashboardAssigned(payload,this.props)
  })
}
handleSubmit =()=>{
// e.preventDefault();

if((this.state.FirstId !="" )|| (this.state.secId !="")){
  const payloadEdit={
    token:this.props.token,
    FirstName:this.state.FName,
    LastName:this.state.LName,
    ConfigurableDay:this.state.ConfigurableDayUser,
    Email:this.state.emailId,
    Id:this.state.FirstId,
    Designation:this.state.designation


  }
  
  const payloadSecEdit={
    token:this.props.token,
    FirstName:this.state.FNameSec,
    LastName:this.state.LNameSec,
    ConfigurableDay:this.state.ConfigurableDayUserSec,
    Email:this.state.emailIdSec,
    Id:this.state.secId,
    Designation:this.state.designationSec

  }

  this.props.onUpdateMatrix(payloadEdit);
  this.props.onUpdateMatrix(payloadSecEdit);
}
else{
  const payload={
    token:this.props.token,
    FirstName:this.state.FName,
    LastName:this.state.LName,
    ConfigurableDay:this.state.ConfigurableDayUser,
    Email:this.state.emailId,
    ULBId:this.state.ulbId,
    Designation:this.state.designation
  }
  
  const payloadSec={
    token:this.props.token,
    FirstName:this.state.FNameSec,
    LastName:this.state.LNameSec,
    ConfigurableDay:this.state.ConfigurableDayUserSec,
    Email:this.state.emailIdSec,
    ULBId:this.state.ulbId,
    Designation:this.state.designationSec

  
  }
  this.props.onCreateMatrix(payload);
  this.props.onCreateMatrix(payloadSec);
}




}

render(){
    return (
        <div>
        <div className="dashboard_right_content" style={{padding:"30px 30px", marginTop:"-4%"}}>
            <img className="dashboard_right_content_top_icon" src="images/Rectangle.svg" />
            <div className="dashboard_right_content_top">
                <h5>Setting</h5>
            </div>

<div className="create_ulb_content_outer">
<div className="root1">
      <AppBar position="static" color="default" className='paper'>
        <Tabs
        className='tabs-setting'
          value={this.state.value}
          onChange={this.handleChangeTab}
          indicatorColor="primary"
          TabIndicatorProps={{
            style: {
              backgroundColor: "green"
             }
            }}
          // textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Schedule Properties" {...a11yProps(0)} />
          <Tab label="Loads Records" {...a11yProps(1)} />
          <Tab label="State Dashboard" {...a11yProps(2)} />
          {/* <Tab label="User Matrix" {...a11yProps(3)} /> */}
          <Tab label="ULB Configuration" {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <TabPanel value={this.state.value} index={0}>
      <div className="create_ulb_content_outer">
                    <div className="create_ulb_content">
                    <Grid container spacing={1}>
                      <Grid xs={12}>
                      <h1 style={{float:"left", marginBottom:'10px',marginLeft:'-80px', fontWeight:'800'}}>*Schedule Properties</h1>
                      </Grid>
                     
                            <Grid item xs={6}>
                            <div >
                                
                                <FormControl style={{color: '#4CAF50',width:"100%", marginRight: "9px"}}>
                                    <label style={{fontSize:'18px'}}>Set Configurable Day</label>
                                {this.state.ulbData&&this.state.ulbData.map((item)=>{
                                    return <NativeSelect
                                    value={this.state.Day}
                                    onChange={this.handleDayChange}
                                    input={<BootstrapInput  />}
                                    style={{color: '#4CAF50', marginTop:'5px'}}>
                                       {this.state.dayChanged==false?<option value=''>Selected Day: {item["configurableDay"]}</option>:<option disabled>{"Select Configurable Day"}</option>}
                                        <option value="Monday" >Monday</option>
                                        <option value="Tuesday">Tuesday</option >
                                        <option value="Wednesday">Wednesday</option >
                                        <option value="Thursday">Thursday</option >
                                        <option value="Friday">Friday</option >
                                        <option value="Saturday">Saturday</option>
                                        <option  value="Sunday">Sunday</option >
                                    </NativeSelect>
                                 })}
                                </FormControl>
                            </div>

                            </Grid>
                            <Grid item xs={6}>
                            <div >
                                <FormControl style={{color: '#4CAF50',width:"80%", marginRight: "9px"}}>
                                    <label  style={{fontSize:'18px'}}>Zone Required?</label>
        
                                     <NativeSelect
                                    value={this.state.requiredZone}
                                    onChange={this.handleZoneChange}
                                    input={<BootstrapInput  />}
                                    style={{color: '#4CAF50', marginTop:'5px'}}>
                                       {/* <option value={''} >Select</option> */}
                                        <option value={false} >Yes</option>
                                        <option value={true}>No</option >
                                       
                                    </NativeSelect>
    
                                </FormControl>
                            </div>
                            </Grid>
                            <Grid xs={6}> 
                            <div>
                            <Button className="but-config" style={{
                                background:'#3dab35',
                              float:'left',
                                width:'100%'
                            }}  color='white' type="submit"onClick={()=>this.openEmailCong()}>Configure Report</Button>
                        </div>
                    

                            </Grid>
                    
                            </Grid>
                            
                        </div>
                       </div>
      </TabPanel>
      <TabPanel value={this.state.value} index={1}>
    
            <div className="create_ulb_content_outer">

            <div className="create_ulb_content">
<Grid container spacing={1}>

  <Grid xs={12}>
  <h1 style={{float:"left",marginLeft:'-80px',  marginBottom:'10px',fontWeight:'800'}}>*Loads Records</h1>
  </Grid>

  <Grid xs={6}>
  <div style={(localStorage.getItem('roleName' ) == User.STATEDASHBOARDUSER ||
                    localStorage.getItem('roleName' ) == User.ADMIN) ? {display:"none"}:{ display:'block'}}  >
            <FormControl style={{color: '#4CAF50',width:"100%", marginLeft: "9px", }}>
                <label style={{fontSize:'18px'}}>Repeat Collection Required?</label>

                 <NativeSelect
                value={this.state.collecton}
                onChange={this.handleCollectionChange}
                input={<BootstrapInput  />}
               
                style={{color: '#4CAF50', marginTop:'5px'}}
                >
                
                    <option value={true} >Yes</option>
                    <option value={false}>No</option >
                   
                </NativeSelect>

            </FormControl>
        </div>
  </Grid>
  <Grid xs={6}>

  </Grid>
</Grid>
</div>

  </div>

      </TabPanel>
      <TabPanel value={this.state.value} index={2}>
      <div className="create_ulb_content_outer">

<div className="create_ulb_content">
<Grid container spacing={1}>

<Grid xs={12}>
<h1 style={{float:"left",marginLeft:'-80px',  marginBottom:'10px',fontWeight:'800'}}>*State Dashboard</h1>
</Grid>

<Grid item xs={6}>
    <FormControl style={{color: '#4CAF50',width:"100%", marginLeft: "9px", }}>
        <label style={{fontSize:'18px'}} htmlFor="ULBstatedashboard">Display ULB in State Dashboard?</label>

        <NativeSelect
                value={this.state.statedashboardValue}
                onChange={this.handleStatedashboardValue}
                input={<BootstrapInput  />}
               
                style={{color: '#4CAF50', marginTop:'5px'}}
                >
                
                    <option value={true} >Yes</option>
                    <option value={false}>No</option >
                   
                </NativeSelect>
        {/* <Select value={this.state.statedashboardValue} onChange={this.handleStatedashboardValue} name="StateValue">
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
      
        </Select> */}
        {/* <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.typeErr}</div>  */}
    </FormControl>
    </Grid>
</Grid>
</div>
</div>
      </TabPanel>


      {/* <TabPanel value={this.state.value} index={3}>
      <div className="create_ulb_content_outer">

      <div className="create_ulb_content">
      <Grid container spacing={1}>

      <Grid xs={12}>
      <h1 style={{float:"left",marginLeft:'-80px',  marginBottom:'10px',fontWeight:'800'}}>*User 1</h1>
      </Grid>
        <Grid xs={6}>
        <FormControl>
        <label className="required" htmlFor="FName" align="left">First Name</label>
        <TextField id="FName"
            variant="outlined"
            value={this.state.FName}
            onChange={this.handleChange}
            name="FName"
            placeholder= "Enter First Name" />
    </FormControl>
        </Grid>
        <Grid xs={6}>
        <FormControl>
        <label className="" htmlFor="LName" align="left">Last Name</label>
        <TextField id="LName"
            variant="outlined"
            value={this.state.LName}
            onChange={this.handleChange}
            name="LName"
            placeholder= "Enter Last Name" />
    </FormControl>
        </Grid>


        <Grid xs={6}>
        <FormControl>
        <label className="required" htmlFor="emailId" align="left">Email</label>
        <TextField id="emailId"
            variant="outlined"
            value={this.state.emailId}
            onChange={this.handleChange}
            name="emailId"
            placeholder= "Enter Email" />
    </FormControl>
        </Grid>
        <Grid xs={6}>
        <div >
                                
        <FormControl style={{color: '#4CAF50',width:"100%", marginRight: "9px"}}>
            <label style={{fontSize:'18px'}}>Configurable Day</label>
       <NativeSelect
            value={this.state.ConfigurableDayUser}
            onChange={this.handleDayChangeUser}
            input={<BootstrapInput  />}
            style={{color: '#4CAF50', marginTop:'5px'}}>
                <option value="select" >Selcet...</option>
                <option value="Monday" >Monday</option>
                <option value="Tuesday">Tuesday</option >
                <option value="Wednesday">Wednesday</option >
                <option value="Thursday">Thursday</option >
                <option value="Friday">Friday</option >
                <option value="Saturday">Saturday</option>
                <option  value="Sunday">Sunday</option >
            </NativeSelect>
     
        </FormControl>
    </div>
        </Grid>

        <Grid xs={6}>
        <FormControl>
        <label className="required" htmlFor="designation" align="left">Designation</label>
        <TextField id="designation"
            variant="outlined"
            value={this.state.designation}
            onChange={this.handleChange}
            name="designation"
            placeholder= "Enter designation" />
    </FormControl>
        </Grid>
      </Grid>




      <Grid container spacing={1}>

<Grid xs={12}>
<h1 style={{float:"left",marginLeft:'-80px',  marginBottom:'10px',fontWeight:'800'}}>*User 2</h1>
</Grid>
  <Grid xs={6}>
  <FormControl>
  <label className="required" htmlFor="FNameSec" align="left">First Name</label>
  <TextField id="FNameSec"
      variant="outlined"
      value={this.state.FNameSec}
      onChange={this.handleChange}
      name="FNameSec"
      placeholder= "Enter First Name" />
</FormControl>
  </Grid>
  <Grid xs={6}>
  <FormControl>
  <label className="" htmlFor="LNameSec" align="left">Last Name</label>
  <TextField id="LNameSec"
      variant="outlined"
      value={this.state.LNameSec}
      onChange={this.handleChange}
      name="LNameSec"
      placeholder= "Enter Last Name" />
</FormControl>
  </Grid>


  <Grid xs={6}>
  <FormControl>
  <label className="required" htmlFor="emailIdSec" align="left">Email</label>
  <TextField id="emailIdSec"
      variant="outlined"
      value={this.state.emailIdSec}
      onChange={this.handleChange}
      name="emailIdSec"
      placeholder= "Enter Email" />
</FormControl>
  </Grid>
  <Grid xs={6}>
  <FormControl style={{color: '#4CAF50',width:"100%", marginRight: "9px"}}>
            <label style={{fontSize:'18px'}}>Configurable Day</label>
       <NativeSelect
            value={this.state.ConfigurableDayUserSec}
            onChange={this.handleDayChangeUserSec}
            input={<BootstrapInput  />}
            style={{color: '#4CAF50', marginTop:'5px'}}>
                <option value="select" >Selcet...</option>
                <option value="Monday" >Monday</option>
                <option value="Tuesday">Tuesday</option >
                <option value="Wednesday">Wednesday</option >
                <option value="Thursday">Thursday</option >
                <option value="Friday">Friday</option >
                <option value="Saturday">Saturday</option>
                <option  value="Sunday">Sunday</option >
            </NativeSelect>
     
        </FormControl>
  </Grid>

  <Grid xs={6}>
        <FormControl>
        <label className="required" htmlFor="designationSec" align="left">Designation</label>
        <TextField id="designationSec"
            variant="outlined"
            value={this.state.designationSec}
            onChange={this.handleChange}
            name="designationSec"
            placeholder= "Enter Designation" />
    </FormControl>
        </Grid>
</Grid>

<Grid container spacing={2}>
<Grid item xs={8}>
</Grid>
<Grid item xs={4}>
  <div className="create_ulb_buttons" style={{ marginBottom: '10px' }}>

  <Button className="login_submit" variant="contained" color="primary" onClick={() => this.handleSubmit()}>{this.state.FirstId != ""||this.state.secId !="" ? "Update": "Submit"}</Button>
</div>
</Grid>
</Grid>

      </div></div>

      </TabPanel> */}

      <TabPanel value={this.state.value} index={3}>
      <Grid container spacing={1}>

        <Grid sm={4}> 
        <Button variant='outline' className="but-config" style={{
                                background:'#3dab35',
                              float:'left',
                                width:'100%'
                            }}  color='white' type="submit" onClick={() => {
                  swal({
                      title: "Confirmation Alert!",
                      text: "Once the current configuration is updated, you wont be able to retrive the data!",
                      icon: "warning",
                      className:'confirmationAlert',
                      buttons: {
                          cancel: 'No',
                          confirm: 'Yes'
                      }
                  })
                      .then((willDelete) => {
                          if (willDelete) {
                            return this.props.history.push( {pathname: `/setting/rcConfiguration/${localStorage.getItem("ulbid")}`, ulbId:localStorage.getItem("ulbid") })
                              
                          }
                      });
              }}>RC  configuration</Button>
              
              </Grid>
        <Grid sm={8}></Grid>
      </Grid>
   
      </TabPanel>
      {/* <TabPanel value={this.state.value} index={3}>
        Item Four
      </TabPanel>

      <TabPanel value={this.state.value} index={5}>
        Item Six
      </TabPanel>
      <TabPanel value={this.state.value} index={6}>
        Item Seven
      </TabPanel> */}
    </div>
</div>


       
                   </div>




        <div className="setFreq">
      <Dialog
        maxWidth={'xs'}
        fullWidth={true} 
        open={this.state.openEmailPopup}
        aria-labelledby="responsive-dialog-title">
        <DialogTitle className="text-center border-none " style={{color:'#3dab35'}}><span style={{fontSize:'18px'}}></span>Summary Report Email Configuration</DialogTitle>
      
        <DialogContent > 
            <div className="create_ulb_content_outer">
                <div className="create_ulb_content_sched">
                    <Grid container spacing={3}>
                    {/* <span>{this.state.reportEmail}</span> */}
                    <Grid item xs={12}>
               <h4 style={{color:'#3dab35'}}>ULB Admin email: </h4> <span>{this.state.reportEmail}</span>

                    </Grid>
                        <Grid item xs={12}>
                        <FormControl component="fieldset" className="login_options">
                        <RadioGroup defaultValue={this.state.emailSelect} aria-label="emailSelect" name="emailSelect" onChange ={(e)=>this.handleChangeEmailSelect(e)}>
                         {/* <FormControlLabel value="daily" control={<StyledRadio />} label="Daily" /> */}
                                <FormControlLabel value='yes' control={<StyledRadio />} label="Yes" />
                                <FormControlLabel value='no' control={<StyledRadio />} label="No" />
                                </RadioGroup>
                        </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl>
                                <label className="required" style={{fontWeight:'bold'}} htmlFor="email" >Kindly enter the email address to receive weekly Summary Reports</label>
                                <TextField id="email"  
                                    name="email"
                                    variant = "outlined"
                                    style={{border:'none',marginTop:'2px'}} 
                                    defaultValue={this.state.emailSelect == 'yes'? this.state.email:this.state.email = "" } 
                                    disabled={this.state.emailSelect == 'yes'?false: true}
                                    onChange={this.handleChangeEmail}
                                    />
                              
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
            </div>      
        </DialogContent>
        <DialogActions className="leftp">
            <Button autoFocus onClick={this.handleEmaiClose} style={{
                background:'none',
                color:'#3dab35',
                border:" 1px solid rgba(61, 171, 53, 0.5)",
                }} 
                type="submit">Cancel
            </Button>
            <Button  style={{
                background:'#3dab35',
                color:'white',
                border:" 1px solid rgba(61, 171, 53, 0.5)",
                }}  autoFocus
                type="submit"
                // disabled={this.state.emailSelect == 'yes'?false: true}
                onClick={this.UpadatedEmail}
                >Save
            </Button>
        </DialogActions>
    </Dialog>
    </div>



 



    
        </div>
    )
}
}



const mapStateToProps = state => {
    return {
        // totalCount:state.propertyListing.totalCount,
        token: state.auth.token,
        // headers:state.propertyHeaders.headers,
        // propertyListing:!!state.propertyListing.propertyListing&&state.propertyListing.propertyListing,
        // zones: state.propertyListing.zones,
        // wards : state.propertyListing.wards,
        // propertyTypeList: !!state.propertyListing.propertyTypeList? state.propertyListing.propertyTypeList :!!state.propertyListing.ListPropertyTypes ?state.propertyListing.ListPropertyTypes: [] ,
        // operatorDay:!!state.operatorDay.operatorDay&&state.operatorDay.operatorDay,
        // loadingData: state.propertyListing.loadingSuccess,
        // propertyLoadListing: state.propertyListing.propertyListLoad,
        // totalCountLoads: state.propertyListing.totalCountLoads,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchPropertyHeaders: (payload) => dispatch(actionsCreators.fetchPropertyHeaders(payload)),
        onFetchPropertyListing: (payload,url,type) => dispatch(actionsCreators.fetchPropertyListing(payload,url,type)),
        onFetchPropertyBulkImport: (payload,formData,props) => dispatch(actionsCreators.fetchPropertyBulkImport(payload,formData,props)),
        onFetchAreaBulkImport: (payload,formData,props) => dispatch(actionsCreators.fetchAreaBulkImport(payload,formData,props)),
        onSchedulePropertyZone :()=> dispatch(actionsCreators.schedulePropertyZone()),
        onSchedulePropertyWard : (payload)=>dispatch(actionsCreators.schedulePropertyWard(payload)),
        onFilterZoneAndWard   : (payload)=> dispatch(actionsCreators.filterZoneAndWard(payload)),
        onSearchProperty:(payload,url,type)=>dispatch(actionsCreators.searchProperty(payload,url,type)),
        onAddFreqSchedule:(payload)=>dispatch(actionsCreators.addFreqSchedule(payload)),
        onPropertyType:()=>dispatch(actionsCreators.propertyType()),
        onAddDayData :(formdata,SubmitProp, props)=>dispatch(actionsCreators.addDayData(formdata,SubmitProp,props)),
        onFetchOperatorDay: (payload,url,type) => dispatch(actionsCreators.fetchOperatorDay(payload,url,type)),
        onDeleteScheduleProperty : (payload)=>dispatch(actionsCreators.deleteSchedule(payload)),
        oNisZoneRequired : (payload, props)=>dispatch(actionsCreators.isZoneRequired(payload, props)),
        onLoadDataSummmary:(payload)=>dispatch(actionsCreators.loadDataSummmary(payload)),
        onEmailUpdate : (payload, props)=>dispatch(actionsCreators.emailUpdate(payload, props)),
        FetchCollectioRequired : (payload, props)=>dispatch(actionsCreators.isCollectioRequired(payload, props)),
        Onstate_dashboardAssigned : (payload, props)=> dispatch(actionsCreators.state_dashboardAssigned(payload, props)),
        onCreateMatrix:(payload)=>dispatch(actionsCreators.CreateMatrix(payload)),
        onUpdateMatrix:(payload)=>dispatch(actionsCreators.UpdateMatrix(payload)),


    }
}

export default  connect(mapStateToProps, mapDispatchToProps)(Index);
