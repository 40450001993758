import React, {useState,useEffect} from 'react';
import { connect } from 'react-redux';
import '../../App.css'
import * as actionsCreators from '../../store/actions/index'
import axios from '../../http-config';
import _ from 'lodash'
import OperatorForm from './OperatorForm'
import {Link,Router} from 'react-router-dom'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
function OperatorEdit (props) {
    const [singleOperator, setOperator] = useState({}); 

    useEffect(() => {
        const payload = {
                        token : props.token,
                        id    : props.match.params.id
        }
        const url = `/operator/${payload.id}`
        axios({
                        method   : 'get',
                        url      : url,
                        headers  : {'Authorization': `Bearer ${payload.token}`}
                   })
                   .then((response) => {
                       setOperator(response.data.data.operators[0])
                   })
                   .catch((err) => {
                       
                   })
      },[]);
    
    const handleSubmit = (formdata)=>{
        props.onEditOperatorData(formdata,props)
    }

        return(
            <div className="create_ulb">
            <div className="dashboard_top_path">
                <p><img src="images/left-arrow.svg" /> Operator Management / <span>Edit Operator</span></p>
            </div>
            <div className="dashboard_right_content">
                <img className="dashboard_right_content_top_icon" src="images/Rectangle.svg" />
                <div className="dashboard_right_content_top">
                <h5><Link to = {`/operator-data/${props.match.params.id}`} className="email_link"><ArrowBackIosIcon></ArrowBackIosIcon></Link>Edit Operator</h5>
                   
                </div>
                <div className="create_ulb_content_outer">
                    <div className="create_ulb_content">
                { 
                 !_.isEmpty(singleOperator)&&(
                     <OperatorForm {...singleOperator} handleSubmit={handleSubmit}/>
                 )
                }        
                    </div>
                </div>
            </div>
        </div>
                
        )
}



const mapStateToProps = (state,props) => {
    
return {
    token:state.auth.token
}
}

const mapDispatchToProps = dispatch => {
return {
    onEditOperatorData: (formdata,props) => dispatch(actionsCreators.editOperatorData(formdata,props))
}
}

export default  connect(mapStateToProps, mapDispatchToProps)(OperatorEdit);

