import React, { Component } from 'react'
import { connect } from 'react-redux'
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { Grid } from '@material-ui/core';
import {Link} from 'react-router-dom'
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import axios from '../../http-config';

class AreaForm extends Component{
    constructor(props) {
        super(props)
        this.state = {
            areaNameTm             : this.props.Name_tn && this.props.Name_tn != "null" ? this.props.Name_tn : "",
            areaName               : this.props.Name    ? this.props.Name    : "",
            wardNumber             : this.props.WardId         ? this.props.WardId       : "",
            zone                   : this.props.ZoneId         ? this.props.ZoneId        : "",
            ulbName                : this.props.ULBId           ? this.props.ULBId         : "",
            areaNameErr            : '',
            areaNameTmErr          : '' ,
            ulbNameErr             : "",
            id                     : this.props.Id,

            isDisableProperty      : this.props.isDisableProperty=== "Active"         ? false      : true,
            ulbOptions             : [],
            wardOptions            : [],
            ZoneOptions            :[]
            
        }
    }

    componentDidMount(){
        //To get Ulb Options from Meta-data via GET method  While Editing Single Area Form
        let payload={
            token:this.props.token
        }
        axios({
            method  : 'get',
            url     : '/load_template/master_data',
            headers : {'Authorization': `Bearer ${payload.token}`}
        })
        .then((response) => {
            this.setState({ 
                            ulbOptions : response.data.data['master-data'][3].data,
                            // wardOptions : response.data.data['master-data'][3].data.find(data=>data.id==this.state.ulbName).children ?
                            // response.data.data['master-data'][3].data.find(data=>data.id==this.state.ulbName).children[0].data : [{id: -1, name: "Ward doesn't exists"}],                   
                        })
        })
        const ulbId = localStorage.getItem('ulbid')
        axios({
            method  : 'get',
            url     : `/zone/getZones`,
            headers: {'Authorization': `Bearer ${payload.token}`}
        })
        .then((response) => {
            this.setState({           
                ZoneOptions: response.data.data.zones
        })
    })
    
    if(this.state.zone != null){
        axios({
            method  : 'get',
            url     : `/ward?zoneId=${this.state.zone}`,
            headers : {'Authorization': `Bearer ${payload.token}`}
        })
        .then((res)=>{
            this.setState({wardOptions: res.data.data.wards})
        })
        .catch((err)=>{
        })
    }
    }
    
    handleSubmit = (e) =>{
        e.preventDefault()
        const err = this.checkValidity()
        if(!err){
            const props=this.props
            const formdata={
                token      : this.props.token,
                name       : this.state.areaName,
                name_tn    : this.state.areaNameTm,
                id         : this.state.id,
                ulbId                 : this.state.ulbName,
                zoneId                  : this.state.zone,
                wardId                  : this.state.wardNumber,
                IsDisableStatus      :this.state.isDisableProperty
            }
            this.props.handleSubmit(formdata,props)
        }
    }
    handleChange = (e) => {
        if(e.target.name=="ulbName"){
            this.setState({[e.target.name]:e.target.value})
            this.state.UlbName=e.target.value
            let payload={
                token:this.props.token
            }
        const ulbId = localStorage.getItem('ulbid')
        axios({
            method  : 'get',
            url     : `/zone/getZones`,
            headers: {'Authorization': `Bearer ${payload.token}`}
        })
        .then((response) => {
            this.setState({
                //  wardOptions : response.data.data.wards, 
                ZoneOptions: response.data.data.zones
        })
    })
        if(e.target.name=="zone"){
            let payload={
                token:this.props.token,
            }
            axios({
                method  : 'get',
                url     : `/ward?zoneId=${e.target.value}`,
                headers : {'Authorization': `Bearer ${payload.token}`}
            })
            .then((res)=>{
                this.setState({wardOptions: res.data.data.wards})
            })
            .catch((err)=>{
                console.log(err)
            })
        }
    }
    else{
        this.setState({[e.target.name] : e.target.value}, ()=>{

            this.checkValidity()
        })   
    }
      
    }
    
    checkValidity = () => {
        let isError = false
        if(!this.state.areaName){
            isError=true
            this.setState({areaNameErr:'*This field is required'})
        }
        // if(!this.state.zoneNameTm){
        //     isError=true
        //     this.setState({zoneNameTmErr : "*This field is required"})
        // }
        if(!this.state.ulbName){
            isError=true
            this.setState({ulbNameErr : "*This field is required"})
        }
        else{
            this.setState({ulbNameErr:''})
        }
        return isError
    }
    // to handle status : Active & Inactive
    handleStatus= (e) => {
        this.setState ({
            isDisableProperty: e.target.value
        })
      }

    render(){
        return (
            <div>
                <div className="create_ulb_content_outer">
                    <div className="create_ulb_content">
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                        <FormControl>
                            <label className="required" htmlFor="areaName">Area Name</label>
                            <TextField id="areaName" 
                                    variant="outlined" 
                                    placeholder="Enter Area Name" 
                                    name="areaName" 
                                    value={this.state.areaName} 
                                    onChange={this.handleChange}/>
                            <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.usernameErr}</div>
                        </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                        <FormControl>
                            <label htmlFor="areaNameTm">Area Name(Tamil)</label>
                            <TextField id="areaNameTm" 
                                variant="outlined" 
                                placeholder="மண்டல பெயரை உள்ளிடவும்" 
                                name="areaNameTm" 
                                value={this.state.areaNameTm} 
                                onChange={this.handleChange}/>
                            <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.emailErr}</div>
                        </FormControl>
                        </Grid>
                    {/* Added Extra Fields => ULB / Cluster Name, Ward , zone & Status */}
                        <Grid item xs={6}>
            <FormControl>
                    <label className="required" htmlFor="ulbName" align="left">ULB / Cluster Name</label>
                    <Select value={this.state.ulbName} onChange={this.handleChange} name="ulbName">
                                {this.state.ulbOptions.map((option) => (
                                    <MenuItem value={option.id}>{option.name}</MenuItem>
                                ))}
                            </Select> 

                        <div  style={{fontSize:12,color:"red"}}>{this.state.ulbNameErr}</div>   
                </FormControl>
            </Grid>
            {/* Added two new Fields */}
            <Grid item xs={6}>
                                
                                <FormControl>
                        <label htmlFor="zone" align="left">Zone Name</label>
                        <Select value={this.state.zone} onChange={this.handleChange} name="zone">
                        {this.state.ZoneOptions.map((option) =>{ 
                                        
                                        return(
                                        <MenuItem value={option.Id}>{option.Name}</MenuItem>
                                    )})}
                                        
                                     
                                </Select> 
                        {/* <div style={{fontSize:12,color:"red"}}>{this.state.zoneErr}</div>  */}
                    </FormControl>
                    
                        </Grid>
                        {
                    this.state.zone== ""?"":
                    <Grid item xs={6}>
                    
                    <FormControl>
                        <label htmlFor="wardNumber" align="left">Ward Number</label>
                        <Select value={this.state.wardNumber} onChange={this.handleChange} name="wardNumber">
                            {this.state.wardOptions.map((option) =>{ 
                                
                                return(
                                <MenuItem value={option.wardId}>{option.wardNumber}</MenuItem>
                            )})}
                        </Select> 
                    </FormControl>
                    
                        </Grid>
                }
            <Grid item xs={6}>
                            <FormControl>
                                <label htmlFor="isDisableProperty" align="left">Status</label>
                                <Select value={this.state.isDisableProperty} onChange={this.handleStatus} name="isDisableProperty">
                                                <MenuItem value={false} >{"Active"}</MenuItem>
                                                <MenuItem value={true}>{"InActive"}</MenuItem>
                                        </Select> 
                            </FormControl>
                </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <div className="create_ulb_buttons">
                            <Link style={{textDecoration:"none", color:"#3dab35"}} to="/area-management"><Button className="login_submit" variant="outlined" color="primary">Cancel</Button></Link>
                            <Button className="login_submit" variant="contained" color="primary" onClick ={this.handleSubmit}>Save</Button>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
        )
    }    
}

const mapStateToProps = (state) => ({
    token : state.auth.token
})
const mapDispatchToProps = (dispatch) => {
    return({
        // onEditArea : (payload,props) => dispatch(actionCreators.editArea(payload,props)),
    })    
}

export default connect(mapStateToProps, mapDispatchToProps)(AreaForm)