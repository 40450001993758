import * as actionTypes from '../../actions/actionsTypes';
import { updateObject } from '../../utility';

const initialState = {
    headers: [],
    totalCount: 0,
    loading: false
}

const fetchHeadersStart = (state, action) => {
    return updateObject(state, {
        loading: true,
    });
}

const fetchHeadersSuccess = (state, action) => {
    return updateObject(state, {
        headers: action.headers,
        totalCount: action.totalCount,
        loading: false,
    });
}

const fetchHeadersFail = (state, action) => {
    return updateObject(state, {
        loading: false,
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_HEADERS_START: return fetchHeadersStart(state, action);
        case actionTypes.FETCH_HEADERS_SUCCESS: return fetchHeadersSuccess(state, action);
        case actionTypes.FETCH_HEADERS_FAIL: return fetchHeadersFail(state, action);
        default: return state;
    }
}

export default reducer;