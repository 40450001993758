import * as actionTypes from '../actions/actionsTypes';
import { updateObject } from '../utility';

const initialState = {
   dashboard: [],
    loading: false
}

const fetchDashboardStart = (state, action) => {
    return updateObject(state, {
        loading: true,
    });
}

const fetchDashboardSuccess = (state, action) => {
    return updateObject(state, {
        dashboard: action.data,
        totalCount: action.totalCount,
        loading: false,
    });
}

const fetchDashboardFail = (state, action) => {
    return updateObject(state, {
        loading: false,
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_DASHBOARD_START: return fetchDashboardStart(state, action);
        case actionTypes.FETCH_DASHBOARD_SUCCESS: return fetchDashboardSuccess(state, action);
        case actionTypes.FETCH_DASHBOARD_FAIL: return fetchDashboardFail(state, action);
        
        default: return state;
    }
}

export default reducer;