import * as actionTypes from './actionsTypes';
import axios from '../../http-config';
import swal from 'sweetalert';
export const authStart = () => {
    return {
        type: actionTypes.AUTH_START,
    }
}

export const authSuccess = (token, userId) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        idToken: token,
        userId: userId
    }
}

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    }
}

export const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('userId');
    localStorage.removeItem('truckRegNo')
    localStorage.removeItem('operatorName')
    localStorage.removeItem('index')

    switch(window.location.hostname){

        case process.env.REACT_APP_STAGING_HOST:
        case process.env.REACT_APP_DEV_HOST_NAME:
            window.location = '/';
            break;

        case process.env.REACT_APP_PROD_HOST:
        case process.env.REACT_APP_LOCAL_HOST_NAME:
             window.location = '/'; 
             break; 
             
        default: 
             window.location = '/';      
    } 
    return {
        type: actionTypes.AUTH_LOGOUT
    }
}

export const checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime * 1000);
    }
}

export const auth = (email, password) => {
    return dispatch => {
        dispatch(authStart());
        const payload = {
            email: email,
            password: password
        }
        let url = '/auth/login';
        axios.post(url, payload).then((response) => { console.log(payload)
            const expirationDate = new Date(new Date().getTime() + 3600 * 1000);
            localStorage.setItem('token', response.data.data.token);
            localStorage.setItem('expirationDate', expirationDate);
            localStorage.setItem('userId', response.data.data.username);
            localStorage.setItem('index', 0)
            localStorage.setItem('phase2', true)
            localStorage.setItem('roles',response.data.data.role)
            localStorage.setItem('ulbid',response.data.data.ulbid)
            localStorage.setItem('username',response.data.data.username)
            localStorage.setItem('roleName',response.data.data.roleName)
            localStorage.setItem('UserIdSave',response.data.data.id)
            // localStorage.setItem('summmaryReportsEmail',response.data.data.summmaryReportsEmail)
            localStorage.setItem('isRepeatCollection',response.data.data.isRepeatCollection)
            dispatch(authSuccess(response.data.data.token, response.data.data.username ));
            if(!!response.data.data&&response.data.data.isRoleAccess.IsWebAppAccess==true){
                switch(window.location.hostname){

                    case process.env.REACT_APP_STAGING_HOST:
                    case process.env.REACT_APP_DEV_HOST_NAME:
                        window.location = '/';
                        break;
            
                    case process.env.REACT_APP_PROD_HOST:
                    case process.env.REACT_APP_LOCAL_HOST_NAME:
                         window.location = '/'; 
                         break; 
                         
                    default: 
                         window.location = '/';      
                }
            }

            if(!!response.data.data.roleName&& response.data.data.roleName === 'State/Regional Officer'){
                localStorage.setItem('index', 7)
                localStorage.getItem('index')
              
          }
          if(!!response.data.data.roleName&& response.data.data.roleName === 'ULB officer'){
            localStorage.setItem('index', 5)
            localStorage.getItem('index')
          
      }
          if(response.data.data.username === 'admin'){
              localStorage.setItem('index', 7)
              localStorage.getItem('index')
            
        }
            dispatch(checkAuthTimeout(3600));
        }).catch(err => {
            dispatch(authFail(err.response?.data?.error));
        })
    }
}

export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        const userId = localStorage.getItem('userId');

        if (!token) {
            // dispatch(logout());
        } else {
            const expirationDate = new Date(localStorage.getItem('expirationDate'));
            if (expirationDate <= new Date()) {
                dispatch(logout());  
            } else {
                dispatch(authSuccess(token, userId));
                dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000 ));
            }
        }
    };
}

export const resetPassword = (email, otp, password, props) => {
    return dispatch => {
        dispatch(resetPasswordStart());
        const payload = {
            email: email,
            password: password,
            otp: otp
        }

        let url = '/auth/update_password';
        axios.patch(url, payload).then((response) => {
            dispatch(resetPasswordSuccess());
            swal('Reset password succesful')
            localStorage.removeItem('email');
            props.history.push('/login');
            
        }).catch(err => {
            dispatch(resetPasswordFail(err.response.data.error));
        })
    }
}

export const resetPasswordStart = () => {
    return {
        type: actionTypes.RESET_PASSWORD_START,
    }
}

export const resetPasswordSuccess = () => {
    return {
        type: actionTypes.RESET_PASSWORD_SUCCESS,
    }
}

export const resetPasswordFail = (error) => {
    return {
        type: actionTypes.RESET_PASSWORD_FAIL,
        error: error
    }
}