import * as actionTypes from '../../actions/actionsTypes';
import { updateObject } from '../../utility';

const initialState = {
    headers: [],
    loading: false
}

const fetchPropertyHeadersStart = (state, action) => {
    return updateObject(state, {
        loading: true,
    });
}

const fetchPropertyHeadersSuccess = (state, action) => {
    return updateObject(state, {
        headers: action.headers,
        loading: false,
    });
}

const fetchPropertyHeadersFail = (state, action) => {
    return updateObject(state, {
        loading: false,
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_PROPERTYHEADERS_START: return fetchPropertyHeadersStart(state, action);
        case actionTypes.FETCH_PROPERTYHEADERS_SUCCESS: return fetchPropertyHeadersSuccess(state, action);
        case actionTypes.FETCH_PROPERTYHEADERS_FAIL: return fetchPropertyHeadersFail(state, action);
        default: return state;
    }
}

export default reducer;