import * as actionTypes from '../actionsTypes';
import axios from '../../../http-config';
import swal from 'sweetalert'

// export const fetchWardListingSuccess = (response) => {
//     return {
//         type: actionTypes.FETCH_WARD_LISTING_START,
//         propertyListing:response.data.properties,
//         totalCount : response.totalCount  
//     }
// }

export const fetchWardListingdSucces =(response)=>{
    return {
        type : actionTypes.FETCH_WARD_LISTING_SUCCESS,
        WardProperty : response.data.wards,
        totalCount : response.totalCount  ,
        wardload:true
    }
}

export const fetchWardListingFail = (error) => {
    return {
        type: actionTypes.FETCH_WARD_LISTING_FAIL,
        error:error,
        wardload:false
    }
}


export const fetchWardListingStart = () => {
    return {
        type: actionTypes.FETCH_WARD_LISTING_START,
        wardload:false
    }
}


export const fetchWardListing = (payload) => {
    let api = `/ward?limit=${payload.limit}&page=${payload.page}&sortColumnName=${payload.sortName}&sortColumnType=${payload.sortValue}`
    let token = payload.token;
    return dispatch => {
        dispatch(fetchWardListingStart());
        axios({
            method : 'get',
            url : api,
            headers :  {'Authorization': `Bearer ${token}`}
        })
        .then((response) => {
            const fetchedWardListingResponse = response.data
            dispatch(fetchWardListingdSucces(fetchedWardListingResponse));
        })
        .catch((err) => {
            dispatch(fetchWardListingFail(err))
        })
    }
}

export const addWard = (payload,props) => {
    let token= payload.token
    let body = {
        wardList:payload.wardList,
        zoneId:payload.zoneId,
        // ulbId: payload.ULBId
    }
    return (dispatch) => {
        const url = '/ward'
        dispatch(fetchWardListingStart())
        axios.post(url, body, { headers : { "Authorization":`Bearer ${token}`}})
        .then((response) => {
            const fetchedResponse = response.data.success;
            fetchedResponse && swal({
                title: "Ward added successfully!",
                icon: "success",
                buttons: {
                    confirm: 'Ok'
                }
            })
            dispatch(fetchWardListing({limit:10,page:1,token:payload.token}))
            props.history.push('/ward-management')
        }, 
        (err) => {
            swal({icon : "error" , title : err.response.data.error[0]["message"]}) 
        })
        .catch((err) => {
            dispatch(fetchWardListingFail(err))
        })
    }
}

export const editWard = (payload, payload1) => {
    let token= payload.token
    const id = payload.id
    return (dispatch) => {
        const body = {
            number:payload.number,
            zoneId:payload.zoneId,
            ulbId: payload.ulbId
        }
        dispatch(fetchWardListingStart())
        const url = `/ward/${id}`
        axios.put(url, body, {headers:{"Authorization":`Bearer ${token}`}})
        .then((response) => {
            const fetchedResponse = response.data.success;
            fetchedResponse && swal({
                title: "You have successfully updated the data!!!",
                icon: "success",
                buttons: {
                    confirm: 'Ok'
                }
            })
            dispatch(fetchWardListing(payload1))
            // props.history.push('/ward-management')
        },
        (err) => {
            swal({icon : "error" , title : err.response.data.error[0]["message"]}) 
        })
        .catch((err) => {
            // dispatch(fetchUserListingFail(err))
        })
    }
}


export const SelectZoneWard = (payload)=>{
    let api = `/ward?zoneId=${payload.id}&limit=${payload.limit}&page=${payload.page}`
    let token = payload.token;
    return dispatch => {
        dispatch(fetchWardListingStart());
        axios({
            method : 'get',
            url : api,
            headers :  {'Authorization': `Bearer ${token}`}
        })
        .then((response) => {
            const fetchedWardListingResponse = response.data
            dispatch(fetchWardListingdSucces(fetchedWardListingResponse));
        })
        .catch((err) => {
            dispatch(fetchWardListingFail(err))
        })
    }
}
export const deleteWard = (payload)=>{
    let token= payload.token
    return (dispatch) => {
        dispatch(fetchWardListingStart())
        const url = `ward/${payload.id}`
        axios.delete(url, {headers:{"Authorization":`Bearer ${token}`}})
        .then((response) => {
            const fetchedResponse = response.data.success;
            fetchedResponse && swal({
                title: "You have successfully Delete the data!!!",
                icon: "success",
                buttons: {
                    confirm: 'Ok'
                }
            })
            dispatch(fetchWardListing(payload))
        },
        (err) => {
            swal({icon : "error" , title : err.response.data.error[0]["message"]}) 
        })
        .catch((err) => {
            dispatch(fetchWardListingFail(err))
        })
    }
}
export const makeAssignWard = (payload, props)=>{
  const  body = {
        "wardIdList": payload.wardIdList,
        "zoneId":payload.zoneId
    }
    return dispatch =>{
        dispatch(fetchWardListingStart())
        const url = `ward/makeAssignWard`
        axios.put(url, body, {headers:{"Authorization":`Bearer ${payload.token}`}})
        .then((response) => {
            const fetchedResponse = response.data.success;
            fetchedResponse && swal({
                title: "You have successfully Assigned All wards!!!",
                icon: "success",
                buttons: {
                    confirm: 'Ok'
                }
            })
            // dispatch(unAssignedListing(payload))
            // props.history.push('/viewWard:id')
            window.location.reload(true)
        
        },
        (err) => {
            swal({icon : "error" , title : err.response.data.error[0]["message"]}) 
        })
        .catch((err) => {
            dispatch(fetchWardListingFail(err))
        })
    }
}

export const makeUnAssignedWard = (payload, props)=>{
    const  body = {
          "wardIdList": payload.wardIdList,
      }
      return dispatch =>{
          dispatch(fetchWardListingStart())
          const url = `ward/makeUnAssignedWard`
          axios.put(url, body, {headers:{"Authorization":`Bearer ${payload.token}`}})
          .then((response) => {
              const fetchedResponse = response.data.success;
              fetchedResponse && swal({
                  title: "You have successfully UnAssigned All wards!!!",
                  icon: "success",
                  buttons: {
                      confirm: 'Ok'
                  }
              })
              window.location.reload(true)
         
          
          },
          (err) => {
              swal({icon : "error" , title : err.response.data.error[0]["message"]}) 
          })
          .catch((err) => {
              dispatch(fetchWardListingFail(err))
          })
      }
  }

  export const unAssignedListing = (payload)=>{
    let api = `/ward?unAssigned=true&limit=${payload.limit}&page=${payload.page}`
    let token = payload.token;
    return dispatch => {
        dispatch(fetchWardListingStart());
        axios({
            method : 'get',
            url : api,
            headers :  {'Authorization': `Bearer ${token}`}
        })
        .then((response) => {
            const fetchedWardListingResponse = response.data
            dispatch(fetchWardListingdSucces(fetchedWardListingResponse));
        })
        .catch((err) => {
            dispatch(fetchWardListingFail(err))
        })
    }
}

//Search Ward 
export const searchWard=(payload,Url,type)=>{
    return dispatch =>{
        dispatch(fetchWardListingStart());
        const url =`ward?limit=${payload.limit}&page=${payload.page}&sortColumnName=${payload.sortName}&sortColumnType=${payload.sortValue}&WardNumber=${payload.searchQuery}`
        axios.get(url,{headers:{
            "Authorization":`Bearer ${payload.token}`
        }} ).then(response=>{
            response && response.data && response.data.data &&
            response.data.data.wards.wardNumber && 
            response.data.data.wards.wardNumber.length == 0 && 
            swal("No Records Found !", {
                icon: "warning",
              });
            dispatch(fetchWardListingdSucces(response.data));
        }).catch(err=>{
            dispatch(fetchWardListingFail(err));
        })
    }
}
