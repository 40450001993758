import React, { Component } from 'react'
import { connect } from 'react-redux';
import {Link} from 'react-router-dom'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import * as actionsCreators from  '../../store/actions/index';
import { DatePicker , MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment'
import ReactPaginate from 'react-paginate';
import Grid from '@material-ui/core/Grid';
import Card from "../Dashboard/Card/Card.js"
import CardHeader from "../Dashboard/Card/CardHeader.js";
import CardBody from '../Dashboard/Card/CardBody.js'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import InputBase from '@material-ui/core/InputBase';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { set, toDate } from 'date-fns';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import swal from 'sweetalert';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import clsx from 'clsx';
import axios from '../../http-config';
const BootstrapInput = withStyles((theme) => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor:"#fff",
      border: '1px solid #4CAF50',
      fontSize: 15,
      padding: '7px 26px 7px 20px',
      transition: theme.transitions.create(['border-color']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        'Open Sans',
        'sans-serif',
   
      ].join(','),
      '&:focus ': {
        borderRadius: 4,
            backgroundColor:"#fff !important"
   
      }
    },
  }))(InputBase);
  const useStyles = makeStyles({
    root: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    icon: {
      borderRadius: '50%',
      width: 16,
      height: 16,
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#f5f8fa',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
      },
      'input:hover ~ &': {
        backgroundColor: '#ebf1f5',
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
      },
    },
    checkedIcon: {
      backgroundColor: '#3dab35',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
      },
      'input:hover ~ &': {
        backgroundColor: '#3dab35',
      },
    },
  });
  
  function StyledRadio(props) {
    const classes = useStyles();
  
    return (
      <Radio
        className={classes.root}
        disableRipple
        color="default"
        checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
        icon={<span className={classes.icon} />}
        {...props}
      />
    );
  }

class PropertySummary extends Component {
    constructor(props){
        super(props)
        var curr = new Date; // get current date
        var first = (curr.getDate() - curr.getDay()); // First day is the day of the month - the day of the week
        var firstdayweek =moment( new Date(curr.setDate(first)).toISOString()).format('MM-DD-YYYY');
        var lastdayweek = moment(new Date(curr.setDate(curr.getDate()+6)).toISOString()).format('MM-DD-YYYY');


        this.state={ 
            fromDate:!!this.props.location&&this.props.location.fromF? this.props.location.fromF: !!this.props.location&&this.props.location.fromHis?this.props.location.fromHis:  firstdayweek ,
            toDate: !!this.props.location&&this.props.location.todateF? this.props.location.todateF: !!this.props.location&&this.props.location.toDateHis? this.props.location.toDateHis: lastdayweek,
            limit: 10,
            activePage:0,
            weekDate:'',
            currentSortId:'',
            sortObj : {
                key: '',
                value: ''
            },
            filterValueStatus:'',
            schedulePropertyCount: 0,
            CollectedPropertiesCount: 0,
            CollectedLoadsCount: 0,
            DisposedLoadsCount:0,
            ExpiredPropertiesCount:0,
            RefusedPropertiesCount:0,
            cancleProperties:0,
            viewHistoryId: !!this.props.match.params&&this.props.match.params.id? this.props.match.params.id:'',
            openEmailPopup:false,
            emailSelect: 'yes',
            email: localStorage.getItem('summmaryReportsEmail') !== '' ?localStorage.getItem('summmaryReportsEmail') != 'null'? localStorage.getItem('summmaryReportsEmail'):'' : '',
            reportEmail: localStorage.getItem('ReportsEmail') !== ""? localStorage.getItem('ReportsEmail'):'',
            isZoneNotRequired:localStorage.getItem("isZoneNotRequired") 

        }
    }
   
    
    componentDidMount() {

        let payload = {
            token: this.props.token,
            page:  1,
            limit: this.state.limit,
            fromDate:moment(this.state.fromDate).format('YYYY-MM-DD'),
            toDate:moment(this.state.toDate).format('YYYY-MM-DD'),
            status: this.state.filterValueStatus,
            viewHistory: this.state.viewHistoryId,
            sortName:this.state.sortObj.key,
            sortValue :this.state.sortObj.value,

        }
        this.props.onFetchPropertySummary(payload); 
        this.props.onFetchStatus(payload)
        this.props.onDayFetch()
    }

    handlePageClick = (e) => {
        this.setState({
            activePage : e.selected
        })
        const selectedPage = e.selected;
        let payload = { token: this.props.token,
            limit: this.state.limit,
            fromDate:moment(this.state.fromDate).format('YYYY-MM-DD'),
            toDate:moment(this.state.toDate).format('YYYY-MM-DD'),
            token: this.props.token,
            page:  selectedPage + 1,
            status: this.state.filterValueStatus,
            viewHistory: this.state.viewHistoryId,
            sortName:this.state.sortObj.key,
            sortValue :this.state.sortObj.value,
        }
        this.props.onFetchPropertySummary(payload)
    };

    setFromDate =(date)=>{
        const toDate7days = moment(date).add(7, 'days')
        this.setToDate(new Date(toDate7days))
        this.setState({fromDate:date,activePage:0}, () => {
            let payload = {
                token: this.props.token,
                page:1,
                limit:10,
                fromDate:moment(this.state.fromDate).format('YYYY-MM-DD'),
                toDate:moment(this.state.toDate).format('YYYY-MM-DD'),
                status: this.state.filterValueStatus,
                viewHistory: this.state.viewHistoryId,
                sortName:this.state.sortObj.key,
                sortValue :this.state.sortObj.value,
            }
            this.props.onFetchPropertySummary(payload); 
            this.props.onFetchStatus(payload)
        })
    } 

    setToDate =(date)=>{
        this.setState({toDate:date,activePage:0}, () => {
            let payload = {
                page:1,
                limit:10,
                token: this.props.token,
                fromDate:moment(this.state.fromDate).format('YYYY-MM-DD'),
                toDate:moment(this.state.toDate).format('YYYY-MM-DD'),
                status: this.state.filterValueStatus,
                viewHistory: this.state.viewHistoryId,
                sortName:this.state.sortObj.key,
                sortValue :this.state.sortObj.value,
            }
            this.props.onFetchPropertySummary(payload); 
            this.props.onFetchStatus(payload)
        })
    }

    handleExport = () => {
        let payload = {
            token: this.props.token,
            fromDate:moment(this.state.fromDate).format('YYYY-MM-DD'),
            toDate:moment(this.state.toDate).format('YYYY-MM-DD'),
            status: this.state.filterValueStatus,
            viewHistory: this.state.viewHistoryId,
            sortName:this.state.sortObj.key,
            sortValue :this.state.sortObj.value,
        }
        this.props.exportSummary(payload)
    }


    handleWeek = (e) => {
    this.setState({weekDate:e.target.value})
        this.setFromDate(new Date( e.target.value))
        const toweekDate = moment(new Date(e.target.value)).add(6, 'days').format('MM-DD-YYYY')
        this.setToDate(new Date(toweekDate))
        this.setState({fromDate : new Date( e.target.value)})
        this.setState({toDate: new Date(toweekDate)})
    //    if(toweekDate > moment(new Date()).format('MM-DD-YYYY')){
    //     this.setToDate(moment(new Date()).add(7, 'days'))
    //     this.setState({toDate: moment(new Date()).add(7, 'days')})
    //    }
    }
 handleSort = (e,sortBy) => {
        let sortObj
        if (this.state.sortObj.key === sortBy.accessor) {
            let value = (this.state.sortObj.value === 'desc') ? 'asc' : 'desc';
            sortObj = {
                key : sortBy.accessor,
                value: value
            }
        } 
        else {
            sortObj = {
                key : sortBy.accessor,
                value: 'desc'
            }    
        }
        this.setState({ sortObj: sortObj ,currentSortId:sortBy.accessor});
        let payload = {
            token: this.props.token,
            page:  1,
            limit: this.state.limit,
            fromDate:moment(this.state.fromDate).format('YYYY-MM-DD'),
            toDate:moment(this.state.toDate).format('YYYY-MM-DD'),
            status: this.state.filterValueStatus,
            viewHistory: this.state.viewHistoryId,
            sortName: sortObj.key,
            sortValue : sortObj.value,

        }
        this.props.onFetchPropertySummary(payload); 
      }
      onClickGetFilterValsStatus=(e)=>{
        this.setState({filterValueStatus: e})
        let payload = {
            token: this.props.token,
            page:  1,
            limit: this.state.limit,
            fromDate:moment(this.state.fromDate).format('YYYY-MM-DD'),
            toDate:moment(this.state.toDate).format('YYYY-MM-DD'),
            status: e,
            viewHistory: this.state.viewHistoryId,
            sortName:this.state.sortObj.key,
            sortValue :this.state.sortObj.value,
        }
        this.props.onFetchPropertySummary(payload); 
    }
    openEmailCong =()=>{
        this.setState({openEmailPopup: true})
        const ulbid    = JSON.parse(localStorage.getItem('ulbid'))

        if(ulbid !== null){
            const url = `/ulb/${ulbid}`
            axios({
                            method   : 'get',
                            url      : url,
                            headers  : {'Authorization': `Bearer ${localStorage.getItem('token')}`}
                       })
                       .then((response) => {
                          this.setState({email:response.data.data.ulbs[0].summmaryReportsEmail})
                          localStorage.setItem('summmaryReportsEmail',response.data.data.ulbs[0].summmaryReportsEmail )
    
                       })
                       .catch((err) => {
                           
                       })
                    }
    }
    handleEmaiClose =()=>{
        this.setState({openEmailPopup: false})
    }
    handleChangeEmailSelect = (e)=>{
        this.setState({emailSelect:e.target.value })
        
       
    }
    handleChangeEmail = (e)=>{
    this.setState({email: e.target.value})
    }
    UpadatedEmail = (e)=>{
        e.preventDefault()
        let payload = {
            token : this.props.token,
            "summmaryReportsEmail": this.state.emailSelect == 'yes' ?this.state.email: null
        }
        this.props.onEmailUpdate(payload)
        this.setState({openEmailPopup: false})
    }
    handleExportScheduleHistory = ()=>{
        let payload = {
            token: this.props.token,
            fromDate:moment(this.state.fromDate).format('YYYY-MM-DD'),
            toDate:moment(this.state.toDate).format('YYYY-MM-DD'),
            status: this.state.filterValueStatus,
            viewHistory: this.state.viewHistoryId,
            sortName:this.state.sortObj.key,
            sortValue :this.state.sortObj.value,
        }
        this.props.exportSummary(payload)
    }
    onClickRemoveFilter = ()=>{
        let payload = {
            token: this.props.token,
            page:  1,
            limit: this.state.limit,
            fromDate:!!this.props.location&&this.props.location.fromF? this.props.location.fromF: !!this.props.location&&this.props.location.fromHis?this.props.location.fromHis:  moment( new Date( (new Date).setDate(((new Date).getDate() - (new Date).getDay())+1)).toISOString()).format('MM-DD-YYYY') ,
            toDate: !!this.props.location&&this.props.location.todateF? this.props.location.todateF: !!this.props.location&&this.props.location.toDateHis? this.props.location.toDateHis: moment(new Date((new Date).setDate((new Date).getDate()+6)).toISOString()).format('MM-DD-YYYY'),
            status: '',
            viewHistory:this.state.viewHistoryId,
            sortName:'',
            sortValue :'',

        }
        this.props.onFetchPropertySummary(payload); 
        this.props.onFetchStatus(payload)

        this.setState({
            filterValueStatus:'', 
            sortObj : {
                key: '',
                value: ''
            },
            fromDate:!!this.props.location&&this.props.location.fromF? this.props.location.fromF: !!this.props.location&&this.props.location.fromHis?this.props.location.fromHis:  moment( new Date( (new Date).setDate(((new Date).getDate() - (new Date).getDay())+1)).toISOString()).format('MM-DD-YYYY') ,
            toDate: !!this.props.location&&this.props.location.todateF? this.props.location.todateF: !!this.props.location&&this.props.location.toDateHis? this.props.location.toDateHis: moment(new Date((new Date).setDate((new Date).getDate()+6)).toISOString()).format('MM-DD-YYYY'), })
    }
    render() {
        let  propertiesSummary = this.props.propertySummary
        // if(this.state.sortObj.value === 'ASC'){
        // switch(this.state.sortObj.key){
        //     case "sr.no":
        //         let sortNo =this.props.propertySummary.sort(function(a, b) {
        //             return a["sr.no"] - b["sr.no"];
        //         });
        //        propertiesSummary = sortNo
        //     break
        //     case "ward":
        //         let sortward =this.props.propertySummary.sort(function(a, b) {
        //             return a.ward - b.ward;
        //         });
        //       propertiesSummary = sortward
        //      break
        //      case "zone":
        //         let sortZone =this.props.propertySummary.sort(function(a, b) {
        //             return (a.zone > b.zone) ? 1 : (a.zone === b.zone) ? ((a.zone > b.zone) ? 1 : -1) : -1 
        //         });
        //        propertiesSummary = sortZone
        //       break
        //     case "propertyName":
        //         let sortpropertyName =this.props.propertySummary.sort(function(a, b) {
                   
        //             return  (a.propertyName > b.propertyName) ? 1 : (a.propertyName === b.propertyName) ? ((a.propertyName > b.propertyName) ? 1 : -1) : -1 
        //         });
        //         propertiesSummary = sortpropertyName
        //         break
        //     case "propertyType":
        //         let sortType =this.props.propertySummary.sort(function(a, b) {
        //             return  (a.type > b.type) ? 1 : (a.type === b.type) ? ((a.type > b.type) ? 1 : -1) : -1 
        //         });
        //         propertiesSummary = sortType
        //         break    
        //     case "scheduledDate":
        //         let sortScheduledDate =this.props.propertySummary.sort(function(a, b) {
        //             return new Date(!!a&& !!a.scheduledDate? a.scheduledDate.split('/').reverse():a.scheduledDate) - new Date(!!b&& !!b.scheduledDate? b.scheduledDate.split('/').reverse():b.scheduledDate)
        //         });
        //         propertiesSummary = sortScheduledDate
        //         break

        //     case "collectionDate":
        //         let sortcollectionDate =this.props.propertySummary.sort(function(a, b) {
        //             return new Date(!!a&& !!a.collectionDate? a.collectionDate.split('/').reverse():a.collectionDate) - new Date(!!b&& !!b.collectionDate? b.collectionDate.split('/').reverse():b.collectionDate)
        //         });
        //         propertiesSummary = sortcollectionDate
        //         break
        //     case "disposedDate":
        //         let sortDisposedDate =this.props.propertySummary.sort(function(a, b) {
        //             return new Date(!!a&& !!a.disposedDate? a.disposedDate.split('/').reverse():a.disposedDate) - new Date(!!b&& !!b.disposedDate? b.disposedDate.split('/').reverse():b.disposedDate)
        //         });
        //         propertiesSummary = sortDisposedDate
        //         break
        //     case  "operatorName":
        //         let sortOperatorName =this.props.propertySummary.sort(function(a, b) {
        //             return  (a.operatorName > b.operatorName) ? 1 : (a.operatorName === b.operatorName) ? ((a.operatorName > b.operatorName) ? 1 : -1) : -1 
        //         });
        //         propertiesSummary = sortOperatorName
        //         break
        //     case "truckNo":
        //         let sorttruckNo =this.props.propertySummary.sort(function(a, b) {
        //             return  (a.truckNo > b.truckNo) ? 1 : (a.truckNo === b.truckNo) ? ((a.truckNo > b.truckNo) ? 1 : -1) : -1 
        //         });
        //         propertiesSummary = sorttruckNo
        //         break
        //     case "tfName":
        //         let sorttfName =this.props.propertySummary.sort(function(a, b) {
        //             return  (a.tfName > b.tfName) ? 1 : (a.tfName === b.tfName) ? ((a.tfName > b.tfName) ? 1 : -1) : -1 
        //         });
        //         propertiesSummary = sorttfName
        //         break
        //     case "GPSLocation":
        //         let sortcoordinates =this.props.propertySummary.sort(function(a, b) {
        //             return (a.coordinates > b.coordinates) ? 1 : (a.coordinates === b.coordinates) ? ((a.coordinates > b.coordinates) ? 1 : -1) : -1 
        //         });
        //         propertiesSummary = sortcoordinates
        //         break
        //     case "status":
        //         let sortstatus =this.props.propertySummary.sort(function(a, b) {
        //             return (a.status > b.status) ? 1 : (a.status === b.status) ? ((a.status > b.status) ? 1 : -1) : -1 
        //         });
        //         propertiesSummary = sortstatus
        //         break
        //     case "serviceStatus":
        //         let sortserviceStatus =this.props.propertySummary.sort(function(a, b) {
        //             return (a.serviceStatus > b.serviceStatus) ? 1 : (a.serviceStatus === b.serviceStatus) ? ((a.serviceStatus > b.serviceStatus) ? 1 : -1) : -1 
        //         });
        //         propertiesSummary = sortserviceStatus
        //         break
        //     default:
        //         break;
        // }

        // }
     
        // if(this.state.sortObj.value === 'DESC'){
        //     switch (this.state.sortObj.key) {
        //         case "sr.no":
        //             let sortNo =this.props.propertySummary.sort(function(a, b) {
        //                 return b["sr.no"] - a["sr.no"];
        //             });
        //             propertiesSummary = sortNo
        //             break;
        //         case "ward":
        //             let sortward =this.props.propertySummary.sort(function(a, b) {
        //                 return b.ward - a.ward;
        //             });
        //             propertiesSummary = sortward
        //             break
        //         case "zone":
        //             let sortZone =this.props.propertySummary.sort(function(a, b) {
        //                 return  (b.zone > a.zone) ? 1 : (b.zone === a.zone) ? ((b.zone > a.zone) ? 1 : -1) : -1 
        //             });
        //             propertiesSummary = sortZone
        //             break
        //         case  "propertyName":
        //             let sortpropertyName =this.props.propertySummary.sort(function(a, b) {
        //                 return  (b.propertyName > a.propertyName) ? 1 : (b.propertyName === a.propertyName) ? ((b.propertyName > a.propertyName) ? 1 : -1) : -1 
        //             });
        //             propertiesSummary = sortpropertyName
        //             break
            
        //         case "propertyType":
        //             let sortType =this.props.propertySummary.sort(function(a, b) {
        //                 return  (b.type > a.type) ? 1 : (b.type === a.type) ? ((b.type > a.type) ? 1 : -1) : -1 
        //             });
        //             propertiesSummary = sortType
        //             break
        //         case "scheduledDate":
        //             let sotward =this.props.propertySummary.sort(function(a, b) {
        //                 return  new Date(!!b&& !!b.scheduledDate? b.scheduledDate.split('/').reverse():b.scheduledDate) - new Date(!!a&& !!a.scheduledDate? a.scheduledDate.split('/').reverse():a.scheduledDate)
        //             });
        //             propertiesSummary = sotward
        //             break
        //         case "collectionDate":
        //             let sortcollectionDate =this.props.propertySummary.sort(function(a, b) {
        //                 return  new Date(!!b&& !!b.collectionDate? b.collectionDate.split('/').reverse():b.collectionDate) - new Date(!!a&& !!a.collectionDate? a.collectionDate.split('/').reverse():a.collectionDate)
        //             });
        //             propertiesSummary = sortcollectionDate
        //             break
        //         case  "disposedDate":
        //             let sortDisposedDate =this.props.propertySummary.sort(function(a, b) {
        //                 return new Date(!!b&& !!b.disposedDate? b.disposedDate.split('/').reverse():b.disposedDate) - new Date(!!a&& !!a.disposedDate? a.disposedDate.split('/').reverse():a.disposedDate)
        //             });
        //             propertiesSummary = sortDisposedDate
        //             break
        //         case "operatorName":
        //             let sortOperatorName =this.props.propertySummary.sort(function(a, b) {
        //                 return  (b.operatorName > a.operatorName) ? 1 : (b.operatorName === a.operatorName) ? ((b.operatorName > a.operatorName) ? 1 : -1) : -1 
        //             });
        //             propertiesSummary = sortOperatorName
        //             break
        //         case  "truckNo":
        //             let sorttruckNo =this.props.propertySummary.sort(function(a, b) {
        //                 return  (b.truckNo > a.truckNo) ? 1 : (b.truckNo === a.truckNo) ? ((b.truckNo > a.truckNo) ? 1 : -1) : -1 
        //             });
        //             propertiesSummary = sorttruckNo
        //             break
        //         case "tfName":
        //             let sorttfName =this.props.propertySummary.sort(function(a, b) {
        //                 return  (b.tfName > a.tfName) ? 1 : (b.tfName === a.tfName) ? ((b.tfName > a.tfName) ? 1 : -1) : -1 
        //             });
        //             propertiesSummary = sorttfName
        //             break  
        //         case "GPSLocation":
        //             let sortcoordinates =this.props.propertySummary.sort(function(a, b) {
        //                 return (b.coordinates > a.coordinates) ? 1 : (b.coordinates === a.coordinates) ? ((b.coordinates > a.coordinates) ? 1 : -1) : -1 
        //             });
        //             propertiesSummary = sortcoordinates
        //             break
        //         case "status":
        //             let sortstatus =this.props.propertySummary.sort(function(a, b) {
        //                 return (b.status > a.status) ? 1 : (b.status === a.status) ? ((b.status > a.status) ? 1 : -1) : -1 
        //             });
        //             propertiesSummary = sortstatus
        //             break
        //         case  "serviceStatus":
        //             let sortserviceStatus =this.props.propertySummary.sort(function(a, b) {
        //                 return (b.serviceStatus > a.serviceStatus) ? 1 : (b.serviceStatus === a.serviceStatus) ? ((b.serviceStatus > a.serviceStatus) ? 1 : -1) : -1 
        //             });
        //             propertiesSummary = sortserviceStatus
        //             break
        //         default:
        //             break;
        //     }
        // }


    const summaryHeaders = [
        {
            accessor: "id",
            filter: "false",
            header: "Sr.no",
            sortable: "false",
        },
        {
            accessor: "ReqId",
            filter: "false",
            header: "Request Id",
            sortable: "false",
        },
        {
            accessor: "ulbName",
            filter: "false",
            header: "ULB/Cluster",
            sortable: "false",
        },
        {
            accessor: "ward",
            filter: "false",
            header: "Ward",
            sortable: "false",
        },
        {
            accessor: "zone",
            filter: "Zone",
            header: "Zone",
            sortable: "false",
        },
        {
            accessor: "propertyName",
            filter: "false",
            header: "Property Name",
            sortable: "false",
        },
        {
            accessor: "propertyType",
            filter: "false",
            header: "Property Type",
            sortable: "false",
        },
        {
            accessor: "scheduledDate",
            filter: "false",
            header: "Scheduled Date",
            sortable: "false",
        },
        // {
        //     accessor: "scheduledTime",
        //     filter: "false",
        //     header: "Scheduled Time",
        //     sortable: "false",
        // }, 
        {
            accessor: "collectionDate",
            filter: "false",
            header: "Collection Date",
            sortable: "false",
        },
        {
            accessor: "collectionTime",
            filter: "false",
            header: "Collection Time",
            sortable: "false",
        }, 
        {
            accessor:"disposedDate",
            filter: "false",
            header: "Disposed Date",
            sortable: "false",
        },
        {
            accessor:"disposedTime",
            filter: "false",
            header: "Disposed Time",
            sortable: "false",
        },
        { header: "Refused Date", 
         accessor: "refusedDate",  
         filter: "false", 
         sortable: "false"
        },
        { header: "Refused Time", 
         accessor: "refusedTime", 
         filter: "false", 
         sortable: "false"
        },
        { 
        header: "Cancelled Date", 
        accessor: "cancelledDate", 
         filter: "false", 
         sortable: "false"
        },
        { header: "Cancelled Time", 
        accessor: "cancelledTime",
         filter: "false",
          sortable: "false", 
        },
        { header: "Expired Date", 
        accessor: "expiredDate", 
         filter: "false",
          sortable: "false"
        },
        { header: "Expired Time", 
        accessor: "expiredTime", 
        filter: "false",
         sortable: "false"
        },
        {
            accessor: "operatorName",
            filter: "false",
            header: "Operator Name",
            sortable: "false",
        },
        {
            accessor: "truckNo",
            filter: "false",
            header: "Truck No",
            sortable: "false",
        },
        {
            accessor: "tfName",
            filter: "false",
            header: "TF Name",
            sortable: "false",
        },
        {
            accessor: "GPSLocation",
            filter: "false",
            header: "GPS Location",
            sortable: "false",
        },
        {
            accessor:"status",
            filter:"false",
            header:"Status",
            sortable:"false"
        },
        {
            accessor:"CreatedBy",
            filter:"false",
            header:"CreatedBy",
            sortable:"false"
        },
        {
            accessor:"serviceStatus",
            filter:"false",
            header:"Service Status",
            sortable:"false"
        },
        {
            accessor: "unknownProperty",
            filter: "false",
            header: "Is unknown Property?",
            sortable: "false",
        },
       
    ]
     
    let updatedHeaders = summaryHeaders
    let array1 = updatedHeaders.slice(0,4)
    let array2 = updatedHeaders.slice(4)
    let  filterValueStatus;
    const fixedColumnData = !!this.props.propertySummary && this.props.propertySummary.map((property, j)=>{
        return <TableRow style={{whiteSpace: 'normal',
        wordWrap: 'break-word'}} key={j}>{
            !!array1 && array1.map((header, i)=>{
                return  <TableCell
                style={{lineHeight:'56px',alignItems:'right'}} className={"white-space"} key={i} >{property[header.accessor] == null || property[header.accessor] == 'null' ? ' ' : property[header.accessor]}</TableCell>      
            }) 
        }
        </TableRow>
    })
      
    const fixedColumnHeaders =  !!array1 && array1.map(header => {{
        return <TableCell key={header.header} className={"white-space"}>
            <div style={{
                    display: 'flex',
                    alignItems: 'start',
                    whiteSpace: 'break-spaces',
                    height:'3em', 
                    }}>
                 {header.header}
            </div>
        </TableCell>
    }});

    const scrollableColumnData = !!this.props.propertySummary && this.props.propertySummary.map((property, j)=>{
        return <TableRow style={{whiteSpace: 'normal',
        wordWrap: 'break-word'}} key={j}>{
            !!array2 && array2.map((header, i)=>{
                return  <TableCell style={{lineHeight:'56px',alignItems:'right'}} key={i} className={"white-space"}>{property[header.accessor] == null || property[header.accessor] == 'null' ? ' ' : property[header.accessor]}</TableCell>      
            }) 
        }
        </TableRow>
    })
      
    const scrollableColumnHeaders =  !!array2 && array2.map(header => {{
        return <TableCell key={header.header} className={"white-space"}>
            <div style={{
                 display: 'flex',
                 alignItems: 'start',
                 whiteSpace: 'break-spaces',
                 height:'3em'
                 }}>
                 {header.header}
            </div>
        </TableCell>
    }});

    const headers =  !!updatedHeaders&&updatedHeaders.map(header => {    
        //  if(this.state.isZoneNotRequired == "true" && header.accessor =="zone" ){
        //         header.accessor = ""
        //         header.filter= "false"
        //         header.header= ""
        //         header.sortable= "false"
        //     }   
        if(header.accessor == "status"){
            header.header = 'Status'
            header.filter = true
        }  
        let StatusType
        if(header.filter == true &&header.accessor == "status" ){
            StatusType = 
        <select 
        style={{
            width: '16px',
            borderColor:"white",
            backgroundColor: 'LightGray',
            outline:"none",
            display: "flex",
            alignItems: 'right',   
          }}
        value={filterValueStatus}
        onChange={(e) => {
            this.onClickGetFilterValsStatus(e.target.value, header)
        }}
        >           <option value="">All</option>
                   <option value="scheduled">Scheduled</option>
                   <option value="collected">Collected</option>
                   <option value="expired">Expired</option>
                   <option value="refused">Refused</option>
                   <option value="cancelled">Cancelled</option>
                   <option value="disposed">Disposed</option>
            </select>
     
        }
        return <TableCell key={header.header} className={"white-space"}>
            <div style={{
                            display: 'flex',
                            alignItems: 'start',
                             whiteSpace: 'normal',
                            height:'3em',
                            wordBreak:"break-word"
                        }}>
                        <div onClick={(e) =>  this.handleSort(e,header)}>
                        {header.accessor === 'collectedTime' || header.accessor ==='disposedTime' || header.accessor === 'refusedTime' ||  header.accessor ==='cancelledTime' ||  header.accessor ==="expiredTime" ? '' :<div>{
                        this.state.currentSortId===header.accessor? this.state.sortObj.value ==='asc'?(<ArrowDropDownIcon fontSize="small"/>):(<ArrowDropUpIcon fontSize="small"/>) :(<ArrowDropDownIcon fontSize="small"/>)}
                        </div>}
                        </div>
                    {header.header}
                    {StatusType}
                    </div>
                </TableCell>
    }
    );

    const properties =!!propertiesSummary&&propertiesSummary.slice(this.state.activePage *this.state.limit, this.state.activePage * this.state.limit + this.state.limit).map((property, j) => {
     return   (<TableRow  key={property.id}>{
            !!updatedHeaders&&updatedHeaders.map((header, i) => {
               if (header.accessor == 'scheduledDate'||header.accessor == 'collectedDate' || header.accessor ==="disposedDate"){
                        return <TableCell  style={{
                            whiteSpace: "normal",
                            wordWrap: "break-word"
                          }} key={i}>{ !!property&&property[header.accessor] ? property[header.accessor] : '-'}</TableCell>
                    }
                else{
                    return <TableCell style={{
                        // lineHeight: '40px',
                        // alignItems:'right',
                        // paddingLeft:'10px',
                        // paddingRight:'10px',
                        whiteSpace: "normal",
                        wordWrap: "break-word"
                        }} className={"white-space"} >{property[header.accessor] == null || property[header.accessor] == 'null' || property[header.accessor] == undefined ? '-' : property[header.accessor]}</TableCell>
                }
                   
                })
            }
        </TableRow>)
    });
    // var presentdate
    // var lastWeekDate
    // var lastWeekDate7
    // var last2Week
    // var last2Week7
    // var last3Week 
    // var last3Week7
    // var last4Week
    // var last4Week7
    // var onemonth
    // var nullDate  
    // var currnet
    // let curr = new Date 
    // let week = []
    // var dayweek =[]
    // var first = curr.getDate() - curr.getDay()+1; // First day is the day of the month - the day of the week
    // var last = first + 6; // last day is the first day + 6
    
    // var firstday = moment(new Date(curr.setDate(first)).toUTCString()).format('MM-DD-YYYY');
    
    var curr = new Date; // get current date
    var first = (curr.getDate() - curr.getDay())+1; // First day is the day of the month - the day of the week
    var last = first + 6; // last day is the first day + 6

    var firstdayweek =moment( new Date(curr.setDate(first)).toISOString()).format('MM-DD-YYYY');
    var lastdayweek = moment(new Date(curr.setDate(last)).toISOString()).format('MM-DD-YYYY');

    var secondlastdayweek =moment( new Date(curr.setDate(new Date(firstdayweek).getDate()-1)).toISOString()).format('MM-DD-YYYY');
    var secondfirstdayweek =moment( new Date(curr.setDate(new Date(secondlastdayweek).getDate()-6)).toISOString()).format('MM-DD-YYYY');

    var thirdlastdayweek = moment(new Date(curr.setDate(new Date(secondfirstdayweek).getDate()-1)).toISOString()).format('MM-DD-YYYY');
    var thirdfirstdayweek =moment( new Date(curr.setDate(new Date(thirdlastdayweek).getDate()-6)).toISOString()).format('MM-DD-YYYY');

    var fourthlastdayweek = moment(new Date(curr.setDate(new Date(thirdfirstdayweek).getDate()-1)).toISOString()).format('MM-DD-YYYY');
    var fourthfirstdayweek =moment( new Date(curr.setDate(new Date(fourthlastdayweek).getDate()-6)).toISOString()).format('MM-DD-YYYY');




    // for (let i = 1; i <= 7; i++) {
    //   let first = curr.getDate() - curr.getDay() + i 
    //   let day = new Date(curr.setDate(first)).toISOString().slice(0, 10)
    //   week.push(day)
      
    // }
 
    // const s = this.props.DayFetch.map((item)=>{
    //     nullDate =item.ConfigurableDay
    //     for ( let i = 0; i < week.length; i++){
    //         dayweek.push(moment(new Date(week[i])).format('dddd'))
    //         if(moment(new Date(week[i])).format('dddd') === item.ConfigurableDay){
    //             const date = new Date(week[i])
    //             presentdate = moment(date).format('MM-DD-YYYY')
    //             lastWeekDate = moment(new Date(presentdate)).subtract(6, 'days').format('MM-DD-YYYY')
    //             currnet = moment(new Date(lastWeekDate)).add(6, 'days').format('MM-DD-YYYY')
    //             lastWeekDate7 = moment(new Date(presentdate)).subtract(7, 'days').format('MM-DD-YYYY')
    //             last2Week = moment(new Date(lastWeekDate7)).subtract(6, 'days').format('MM-DD-YYYY')
    //             last2Week7 = moment(new Date(lastWeekDate7)).subtract(7, 'days').format('MM-DD-YYYY')
    //             last3Week = moment(new Date(last2Week7)).subtract(6, 'days').format('MM-DD-YYYY')
    //             last3Week7 = moment(new Date(last2Week7)).subtract(7, 'days').format('MM-DD-YYYY')
    //             last4Week = moment(new Date(last3Week7)).subtract(6, 'days').format('MM-DD-YYYY')
    //             last4Week7 = moment(new Date(last3Week7)).subtract(7, 'days').format('MM-DD-YYYY')
    //         }
    //     }
    // })
    var schedulePropertyCountt;
    var CollectedPropertiesCountt;
    var CollectedLoadsCountt;
    var DisposedLoadsCountt;
    var ExpiredPropertiesCountt;
    var currentPropertyCountSchedule;
    var CollectedPropertiesCounttCurrent;
var RefusedPropertiesCountt;
var canclePropertiesCount;
var statusCancle;
var statusRefused;
var statusExpire;
 var sD = !!this.props.status&&this.props.status.map((item)=>{
    if(item.Status === "scheduled"&& item.type === "property"){
        schedulePropertyCountt= item.PropertyCount
        currentPropertyCountSchedule = item.currentPropertyCount
    }
    if(item.Status === "collected"&& item.type === "property"){
    CollectedPropertiesCountt= item.viaScheduleCollectCount
    CollectedPropertiesCounttCurrent = item.addNewReqCollectCount
    }
    if(item.Status === "collected"&& item.type === "load"){
        CollectedLoadsCountt= item.loadCount
    }
    if(item.Status === "disposed"&& item.type === "property"){
        DisposedLoadsCountt= item.PropertyCount
    }
    if(item.Status === "expired"&& item.type === "property"){
        ExpiredPropertiesCountt=item.currentPropertyCount
        statusExpire= item.Status
    }
    if(item.Status === "refused"&& item.type === "property"){
    RefusedPropertiesCountt= item.currentPropertyCount
    statusRefused= item.Status
    }
    if(item.Status === "cancelled"&& item.type === "property"){
        canclePropertiesCount= item.currentPropertyCount
        statusCancle= item.Status
        }
})

    return (
        <div>
            <div className="dashboard_right_content" style ={{ marginTop: '-4%'}}> 
                <img className="dashboard_right_content_top_icon" src="images/Rectangle.svg" />
                <div className="dashboard_right_content_top">
                {this.state.viewHistoryId == 'allData'?   <h5>Summary</h5>:  <h5><Link to = "/property-summary/all" className="email_link"><ArrowBackIosIcon></ArrowBackIosIcon></Link>Scheduled History</h5>}
                    {this.state.viewHistoryId == 'allData'? 
                    <div className="dashboard_right_content_top_options helpTxt">  

                    <div>
                      <FormControl style={{color: '#4CAF50',width:"200px", marginLeft: '13px'}}>
                         
                         <NativeSelect
                         value={this.state.weekDate}
                         onChange={this.handleWeek}
                         input={<BootstrapInput  />}
                         style={{color: '#4CAF50', fontFamily:'Open Sans, sans-serif'}}
                         >
                         <option value={firstdayweek}>Current Week</option>
                         {/* <option value={secondfirstdayweek}>{`${moment(secondfirstdayweek).format('Do MMM')} To ${moment(secondlastdayweek).format('Do MMM')}`}</option>
                         <option value={thirdfirstdayweek}> {`${moment(thirdfirstdayweek).format('Do MMM')} To ${moment(thirdlastdayweek).format('Do MMM')}`}</option>
                         <option value={fourthfirstdayweek}>{`${moment(fourthfirstdayweek).format('Do MMM')} To ${moment(fourthlastdayweek).format('Do MMM')}`}</option> */}

                         </NativeSelect>
                     </FormControl>
                    
                        </div>

                    
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <span style={{fontSize: 'small',fontFamily: 'Open Sans, sans-serif'}}>&nbsp;&nbsp;&nbsp;&nbsp;</span> 
                            <DatePicker
                                variant="inline"
                                value={this.state.fromDate}  
                                onChange={this.setFromDate}
                                // disableFuture
                                format='dd MMM YYY'
                                autoOk
                                style={{width:'100%', borderBottom: 'none'}}
                                InputProps={{ style:{fontSize:'small', padding:'4px' ,height:'34px',marginTop:'2px',color: '#3DAB35',border: '1px solid #3DAB35', 
                                borderRadius: '4px', fontFamily: 'Open Sans, sans-serif'},disableUnderline: true}}/>
                                <span style={{fontSize: 'small',fontFamily: 'Open Sans, sans-serif'}}>&nbsp;&nbsp;to&nbsp;&nbsp;</span> 
                            <DatePicker
                                variant="inline"
                                value={this.state.toDate} 
                                onChange={this.setToDate} 
                                // disableFuture
                                minDate={this.state.fromDate}
                                format='dd MMM YYY'
                                autoOk
                                style={{width:'100%'}}
                                InputProps={{ style:{fontSize:'small', padding:'4px' ,height:'34px',marginTop:'2px',color: '#3DAB35',border: '1px solid #3DAB35', 
                                borderRadius: '4px', fontFamily: 'Open Sans, sans-serif'}, disableUnderline: true}}/>
                        </MuiPickersUtilsProvider> 
                        {/* <div>
                            <Button style={{
                                background:'none',
                                color:'#3dab35',
                                marginLeft: '18px',
                                width:'150px'
                            }} color="primary" type="submit"onClick={()=>this.openEmailCong()}>Configure Report</Button>
                        </div> */}
                    
                        <Button style={{width:'100%'}} onClick={this.handleExport}>Export</Button>
            
                                 <div>
                            <Link to={{pathname:`/property-summary/all`, fromDate: this.state.fromDate, toDate: this.state.toDate}} className='email_link'> <Button style={{
                                background:'none',
                                color:'#3dab35',
                                marginLeft: '18px',
                                width:'150px'
                            }} color="primary" type="submit" >Schedule Property</Button></Link>
                        </div>

                        <div>
                            <Link to={{pathname:`/property-History/allHisorty`,  fromDateHis: this.state.fromDate, todateHis: this.state.toDate}} className='email_link'> <Button style={{
                                background:'none',
                                color:'#3dab35',
                                marginLeft: '18px',
                                width:'150px'
                            }} color="primary" type="submit" >History</Button></Link>
                        </div>
                        <Button onClick={this.onClickRemoveFilter}>Reset</Button>  
                    </div>
                    : <div className="dashboard_right_content_top_options">
                    <Button style={{width:'100%'}} onClick={this.handleExportScheduleHistory}>Export</Button>

            </div>}
                </div>
                {this.state.viewHistoryId == 'allData'?   <div style={{color:'red',fontSize:'12px', textAlign:'right' , fontFamily:'Open Sans, sans-serif'}}>*The schedules set for weekend, will be adjusted to weekdays by Auto schedule.</div>:""}
                {/* <div style={{display:'flex', justifyContent: 'flex-end' }}>
                <span style={{color:'black !important'}}>***</span>***</span>
                </div> */}
                
            </div>
            <div> 
                {this.state.viewHistoryId == 'allData'? 
                <Grid style={{marginTop:'50px'}} container spacing={1} >
                            
                                <Grid item xs={2}>
                                <Card >
                                    <hr style={{border: "1px solid #3DAB35", marginLeft:"5%", width:"30px"}}></hr>  
                                    <CardHeader color="warning" icon> 
                                        <CardBody>
                                        <h2  style = {{color:"black",fontSize:"15px", textAlign:'center'}}>Schedules</h2>
                                        <div style={{display:'flex', justifyContent:'center', marginTop:'4%'}}>
                                            <div style={{display:'inline'}}>
                                            <h2  style ={{color: "#3dab35",fontSize: "25px",minHeight: "5px",fontWeight: "1000",marginBottom: "10px", textAlign:'center',}}
                                                                                >{!!schedulePropertyCountt?schedulePropertyCountt: this.state.schedulePropertyCount}</h2>  
                                                <h5 style = {{color:"black",textAlign:'center'}}>Total</h5>
                                            </div>
                                        
                                            <div style={{ borderLeft: 'thick solid #3DAB35' , marginLeft:'20px', marginRight:'20px'}}>| </div>
                                            <div style={{display:'inline'}}>
                                            <h2  style ={{color: "#3dab35",fontSize: "25px",minHeight: "5px",fontWeight: "1000",marginBottom: "10px",  textAlign:'center'}}
                                                    >{!!currentPropertyCountSchedule?currentPropertyCountSchedule: 0}</h2>

                                            <h5  style = {{color:"black", textAlign:'center'}}>Pending</h5>
                                                </div>
                                          
                                        </div>
                                    
                                         
                                        </CardBody>          
                                    </CardHeader> 
                                </Card>
                                </Grid> 
                    
                            <Grid item xs={2}>
                            <Card>
                                <hr style={{border: "1px solid #3DAB35", marginLeft:"5%", width:"30px"}}></hr>  
                                <CardHeader color="warning"  icon>
                                    <CardBody>
                                    <h2  style = {{color:"black",fontSize:"15px", textAlign:'center'}} >Collected Schedules</h2>
                                    <div style={{display:'flex', justifyContent:'center', marginTop:'4%'}}>
                                    <div style={{display:'inline'}}>
                                    <h2  style ={{color: "#3dab35",fontSize: "25px",minHeight: "5px",fontWeight: "1000",marginBottom: "10px", textAlign:'center'}}
                                                                                > {!!CollectedPropertiesCountt?CollectedPropertiesCountt: this.state.CollectedPropertiesCount}</h2>  
                                                <h5  style = {{color:"black", textAlign:'center'}}>Scheduled</h5>
                                    </div>
                                    <div style={{ borderLeft: 'thick solid #3DAB35' , marginLeft:'20px', marginRight:'20px'}}>| </div>
                                    <div style={{display:'inline'}}>
                                    <h2  style ={{color: "#3dab35",fontSize: "25px",minHeight: "5px",fontWeight: "1000",textAlign:'center', marginBottom: "10px", }} >
                                            {!!CollectedPropertiesCounttCurrent?CollectedPropertiesCounttCurrent:0}</h2>
                                            <h5  style = {{color:"black", textAlign:'center'}}>Ad-&nbsp;hoc basis</h5>
                                       
                                    </div>

                                    </div>
                                     
                                    </CardBody>          
                                </CardHeader> 
                            </Card>
                            </Grid>
                    
               
                            {/* <Grid item xs={2}>
                            <Card>
                                <hr style={{border: "1px solid #3DAB35", marginLeft:"5%", width:"30px"}}></hr>  
                                <CardHeader color="warning" icon>
                                    <CardBody>
                                    <h2  style = {{color:"black",fontSize:"15px", marginBottom: "10px", textAlign:'center'}}>Collected Loads</h2>
                                        <h2 className="card-text-style"  style ={{color: "#3dab35",fontSize: "32px",minHeight: "5px",fontWeight: "1000", textAlign:'center'}} >
                                            {!!CollectedLoadsCountt?CollectedLoadsCountt:this.state.CollectedLoadsCount}</h2>
                                      
                                    </CardBody>          
                                </CardHeader> 
                            </Card>
                            </Grid> */}
        
                        <Grid item xs={2}>
                        <Card>
                            <hr style={{border: "1px solid #3DAB35", marginLeft:"5%", width:"30px"}}></hr>  
                            <CardHeader color="warning"  icon>
                                <CardBody>
                                <h2  style = {{color:"black",fontSize:"15px", textAlign:'center'}} >Disposed Schedules</h2>
                                    <h2 className="card-text-style"  style ={{color: "#3dab35",fontSize: "32px",minHeight: "5px",fontWeight: "1000",textAlign:'center'}} >
                                    {!!DisposedLoadsCountt?DisposedLoadsCountt:this.state.DisposedLoadsCount}</h2>
                                 
                                </CardBody>          
                            </CardHeader> 
                        </Card>
                        </Grid> 
                        <Grid item xs={2}  >
                        <Card>
                            <hr style={{border: "1px solid #3DAB35", marginLeft:"5%", width:"30px"}}></hr>  
                            <CardHeader color="warning" icon>
                                <CardBody>
                                <h2  style = {{color:"black",fontSize:"15px", textAlign:'center'}}>Expired Schedules</h2>
                                <Link to={!!statusExpire?{pathname:'/property-summary/'+statusExpire, fromDate:this.state.fromDate, toDate:this.state.toDate}:'/schedule-property/allData'}  style={{textDecoration:'none' ,color:'#3DAB35'}}>   <h2 className="card-text-style"   style ={{color: "#3dab35",fontSize: "32px",minHeight: "5px",fontWeight: "1000",textAlign:'center' }}>
                                        {!!ExpiredPropertiesCountt?ExpiredPropertiesCountt:this.state.ExpiredPropertiesCount}</h2>
                                   </Link>
                                </CardBody>          
                            </CardHeader> 
                        </Card>
                        </Grid>
            
                            <Grid item xs={2} >
                            <Card>
                                <hr style={{border: "1px solid #3DAB35", marginLeft:"5%", width:"30px"}}></hr>  
                                <CardHeader color="warning" icon>
                                    <CardBody>
                                    <h2  style = {{color:"black",fontSize:"15px", textAlign:'center'}} >Refused Schedules</h2>
                                    <Link to={!!statusRefused?{pathname:'/property-summary/'+statusRefused, fromDate:this.state.fromDate, toDate:this.state.toDate}:'/schedule-property/allData'}    style={{textDecoration:'none' ,color:'#3DAB35'}}>   <h2 className="card-text-style"   style ={{color: "#3dab35",fontSize: "32px",minHeight: "5px",fontWeight: "1000", textAlign:'center'}} >
                                        {!!RefusedPropertiesCountt?RefusedPropertiesCountt:this.state.RefusedPropertiesCount}</h2>
                                        </Link>
                                    </CardBody>          
                                </CardHeader> 
                            </Card>
                            </Grid>   
                            <Grid item xs={2}>
                            <Card>
                                <hr style={{border: "1px solid #3DAB35", marginLeft:"5%", width:"30px"}}></hr>  
                                <CardHeader color="warning" icon>
                                    <CardBody>
                                     <h2  style = {{color:"black",fontSize:"15px", textAlign:'center'}} >Cancel schedules</h2>
                                     <Link to={!!statusCancle?{pathname:'/property-summary/'+statusCancle, fromDate:this.state.fromDate, toDate:this.state.toDate}:'/schedule-property/allData'}    style={{textDecoration:'none' ,color:'#3DAB35'}}>  <h2 className="card-text-style"   style ={{color: "#3dab35",fontSize: "32px",minHeight: "5px",fontWeight: "1000", textAlign:'center'}} >
                                        {!!canclePropertiesCount?canclePropertiesCount:this.state.cancleProperties}</h2>
                                        </Link>
                                    </CardBody>          
                                </CardHeader> 
                            </Card>
                            </Grid>   
            
                             
                </Grid>:""}
               
            <div className="dashboard_listing scrollSummaryPage" style={{marginTop:"-2%"}} >
            {/* <Grid container direction="row"> */}
                {/* <Grid items xs={12} lg={12}> */}
                <TableContainer component={Paper} style={{paddingLeft:"0.5%" ,paddingRight:"1%"}}>
                     <Table  stickyHeader aria-label="sticky table" className="table-sc-style" style={{ tableLayout: 'fixed', width:'300%', overflowX:'auto' }}>
                     <colgroup>
+                                <col style={{width:'5%'}}/>
+                                <col style={{width:'8%'}}/>
+                                <col style={{width:'10%'}}/>
+                                <col style={{width:'7%'}}/>
+                                <col style={{width:'10%'}}/>
+                                <col style={{width:'12%',}}/>
+                                <col style={{width:'10%'}}/>
+                                <col style={{width:'10%'}}/>
+                                <col style={{width:'10%'}}/>
+                                <col style={{width:'10%'}}/>
                                 <col style={{width:'10%'}}/>    
                                 <col style={{width:'10%'}}/>
+                                <col style={{width:'10%'}}/>
+                                <col style={{width:'10%'}}/>
+                                <col style={{width:'10%'}}/>
+                                <col style={{width:'10%'}}/>
+                                <col style={{width:'10%',}}/>
+                                <col style={{width:'10%'}}/>
+                                <col style={{width:'10%'}}/>
+                                <col style={{width:'10%'}}/>
+                                <col style={{width:'10%'}}/>
                                 <col style={{width:'10%'}}/>    
                                 <col style={{width:'10%'}}/>
                                 <col style={{width:'10%'}}/>  
                                 <col style={{width:'10%'}}/>   
+                                <col style={{width:'10%',paddingLeft:'0%',paddingRight:'0%'}}/>  
+                            </colgroup>
                        <TableHead >
                            <TableRow >
                                {headers}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {/* {this.props.propertySummary.length== 0 && this.props.loadingSummary == true? <TableRow style={{alignItems:'center', fontWeight:'bold', color:'gray' }}>
                                            No Records Found !!</TableRow>: properties} */}
                                            {properties}
                            
                        </TableBody>
                    </Table>
                </TableContainer>
                {/* </Grid> */}
                {/* </Grid> */}
            </div>
        </div>
        {
            this.props.totalCount > 0 &&
                <ReactPaginate
                previousLabel={"Prev"}
                nextLabel={"Next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount = {this.props.totalCount/this.state.limit}
                marginPagesDisplayed={2}
                forcePage = {this.state.activePage}
                pageRangeDisplayed={5}
                style={{borderLeftWidth:'0px'}}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}/>
        }









<div className="setFreq">
      <Dialog
        maxWidth={'xs'}
        fullWidth={true} 
        open={this.state.openEmailPopup}
        aria-labelledby="responsive-dialog-title">
        <DialogTitle className="text-center border-none " style={{color:'#3dab35'}}><span style={{fontSize:'18px'}}></span>Summary Report Email Configuration</DialogTitle>
      
        <DialogContent > 
            <div className="create_ulb_content_outer">
                <div className="create_ulb_content_sched">
                    <Grid container spacing={3}>
                    {/* <span>{this.state.reportEmail}</span> */}
                    <Grid item xs={12}>
               <h4 style={{color:'#3dab35'}}>ULB Admin email: </h4> <span>{this.state.reportEmail}</span>

                    </Grid>
                        <Grid item xs={12}>
                        <FormControl component="fieldset" className="login_options">
                        <RadioGroup defaultValue={this.state.emailSelect} aria-label="emailSelect" name="emailSelect" onChange ={(e)=>this.handleChangeEmailSelect(e)}>
                         {/* <FormControlLabel value="daily" control={<StyledRadio />} label="Daily" /> */}
                                <FormControlLabel value='yes' control={<StyledRadio />} label="Yes" />
                                <FormControlLabel value='no' control={<StyledRadio />} label="No" />
                                </RadioGroup>
                        </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl>
                                <label className="required" style={{fontWeight:'bold'}} htmlFor="email" >Kindly enter the email address to receive weekly Summary Reports</label>
                                <TextField id="email"  
                                    name="email"
                                    variant = "outlined"
                                    style={{border:'none',marginTop:'2px'}} 
                                    defaultValue={this.state.emailSelect == 'yes'? this.state.email:this.state.email = "" } 
                                    disabled={this.state.emailSelect == 'yes'?false: true}
                                    onChange={this.handleChangeEmail}
                                    />
                              
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
            </div>      
        </DialogContent>
        <DialogActions className="leftp">
            <Button autoFocus onClick={this.handleEmaiClose} style={{
                background:'none',
                color:'#3dab35',
                border:" 1px solid rgba(61, 171, 53, 0.5)",
                }} 
                type="submit">Cancel
            </Button>
            <Button  style={{
                background:'#3dab35',
                color:'white',
                border:" 1px solid rgba(61, 171, 53, 0.5)",
                }}  autoFocus
                type="submit"
                // disabled={this.state.emailSelect == 'yes'?false: true}
                onClick={this.UpadatedEmail}
                >Save
            </Button>
        </DialogActions>
    </Dialog>
    </div>
    </div>
)}}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        totalCount : state.propertyListing.loadStatusCount,
        propertySummary : state.propertyListing.propertySummary,
        status:state.propertyListing.status,
        DayFetch : state.loads.configlist,
        loadingSummary : state.propertyListing.summaryLoading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchPropertySummary : (payload) => dispatch(actionsCreators.fetchPropertySummary(payload)),
        onFetchStatus : (payload) => dispatch(actionsCreators.fetchStatus(payload)),
        exportSummary :(payload) => dispatch(actionsCreators.exportSummary(payload)),
        onDayFetch : ()=> dispatch(actionsCreators.DownloadSampleFile()),
        onEmailUpdate : (payload, props)=>dispatch(actionsCreators.emailUpdate(payload, props)),
        
    }
}

export default  connect(mapStateToProps, mapDispatchToProps)(PropertySummary);