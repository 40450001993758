import React, {useState,useEffect} from 'react';
import { connect } from 'react-redux'
import * as actionCreators from '../../store/actions/index'
import axios from '../../http-config';
import _ from 'lodash'
import WardForm from './WardForm';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {Link} from 'react-router-dom'
function WardEdit (props){
 
    const [singleWard, setWard] = useState({}); 

    useEffect(() => {
        const payload = {
            token : props.token,
            id    : props.match.params.id
        }
        const url = `/ward/${payload.id}`
        axios({
                method   : 'get',
                url      : url,
                headers  : {'Authorization': `Bearer ${payload.token}`}
            })
            .then((response) => {
             setWard(response.data.data.wards[0])
            })
            .catch((err) => {
                
            })
      },[]);
    
    const handleSubmit = (formdata)=>{
        props.onEditWard(formdata,props)
    }
    return (
        <div>
            <div className="create_ulb">
                <div className="dashboard_top_path">
                    <p><img src="images/left-arrow.svg" /> Ward Management / <span>Edit Ward</span></p>
                </div>
                <div className="dashboard_right_content">
                    <img className="dashboard_right_content_top_icon" src="images/Rectangle.svg" />
                    <div className="dashboard_right_content_top">
                    <h5><Link to = "/ward-management" className="email_link"><ArrowBackIosIcon></ArrowBackIosIcon></Link>Edit Ward</h5>
                    </div>
                    <div className="create_ulb_content_outer">
                        <div className="create_ulb_content">
                            {
                                !_.isEmpty(singleWard) && (
                                    <WardForm {...singleWard} handleSubmit={handleSubmit}/>
                                )
                            }
                           
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    )
    
}

const mapStateToProps = (state) => ({
   token:state.auth.token 
})

const mapDispatchToProps = (dispatch) => {
    return({
        onEditWard : (payload,props) => dispatch(actionCreators.editWard(payload,props))
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(WardEdit)

