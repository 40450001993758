import * as actionTypes from '../actionsTypes';
import axios from '../../../http-config';

export const fetchTreatmentHeadersSuccess = (response) => {
    return {
        type: actionTypes.FETCH_TREATMENTHEADERS_SUCCESS,
         headers:response.data.headers   
    }
}

export const fetchTreatmentHeadersFail = (error) => {
    return {
        type: actionTypes.FETCH_TREATMENTHEADERS_FAIL,
        error:error
    }
}

export const fetchTreatmentHeadersStart = () => {
    return {
        type: actionTypes.FETCH_TREATMENTHEADERS_START
    }
}

export const fetchTreatmentHeaders = (payload) => {
    let token = payload.token;
    return dispatch => {
        dispatch(fetchTreatmentHeadersStart());
        axios({
            method: 'get',
            url: '/treatment_facility/headers',
            headers: {'Authorization': `Bearer ${token}`}
        })
        .then((response) => {
            const fetchedTreatmentHeadersResponse =response.data
            
            dispatch(fetchTreatmentHeadersSuccess(fetchedTreatmentHeadersResponse));
        }).catch(error => {
            dispatch(fetchTreatmentHeadersFail(error));
        })

    }
}
