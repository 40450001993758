import React, {useState,useEffect} from 'react';
import { connect } from 'react-redux'
import AreaForm from './AreaForm'
import * as actionCreators from '../../store/actions/index'
import axios from '../../http-config';
import _ from 'lodash'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {Link} from 'react-router-dom'

function AreaEdit (props){
 
    const [singleArea, setArea] = useState({}); 

    useEffect(() => {
        const payload = {
                        token : props.token,
                        id    : props.match.params.id
        }
        const url = `/area/${payload.id}`
        axios({
                        method   : 'get',
                        url      : url,
                        headers  : {'Authorization': `Bearer ${payload.token}`}
                   })
                   .then((response) => {
                    setArea(response.data.data.areas[0])
                   })
                   .catch((err) => {
                       
                   })
      },[]);
    
    const handleSubmit = (formdata)=>{
        props.onEditArea(formdata,props)
    }
    return (
        <div>
            <div className="create_ulb">
                <div className="dashboard_top_path">
                    <p><img src="images/left-arrow.svg" /> Area Management / <span>Edit Area</span></p>
                </div>
                <div className="dashboard_right_content">
                    <img className="dashboard_right_content_top_icon" src="images/Rectangle.svg" />
                    <div className="dashboard_right_content_top">
                    <h5><Link to = "/area-management" className="email_link"><ArrowBackIosIcon></ArrowBackIosIcon></Link>Edit Area</h5>
                    </div>
                    <div className="create_ulb_content_outer">
                        <div className="create_ulb_content">
                            {
                                !_.isEmpty(singleArea) && (
                                    <AreaForm {...singleArea} handleSubmit={handleSubmit}/>
                                )
                            }
                           
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    )
    
}

const mapStateToProps = (state) => ({
    token:state.auth.token
})

const mapDispatchToProps = (dispatch) => {
    return({
        onEditArea : (payload,props) => dispatch(actionCreators.editArea(payload,props)),
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(AreaEdit)

