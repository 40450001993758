import React, { Component, useEffect } from 'react'
import { connect } from 'react-redux'
import InputBase from '@material-ui/core/InputBase';
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import swal from 'sweetalert'
import axios from '../../http-config'
import Chip from '@material-ui/core/Chip';
import moment from 'moment'
import ReactPaginate from 'react-paginate';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import * as actionsCreators from '../../store/actions/index'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { Grid, } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import Autocomplete from '@material-ui/lab/Autocomplete';

export class RegionalListing extends Component {
    state = {
        anchorEl: null,
        open: false,
        id: '',
        page: 1,
        limit: 10,
        selected: [],
        currentSortId: '',
        sortObj: {
            key: '',
            value: ''
        },
        controls: {
            search: {
                value: '',
            }
        },
        isDefaultValue: '',
        tfaIDvalue: '',

        userName: "",
        email: "",
        role: "",
        roleId: "",
        ULBlist: [],
        openEditR_User: false,
        nameSelected: [],
        editId: "",
        email_r: "",
        username_r: "",
        checkValue: false,
        edit_ulb_list: [],

        username: "",
        email: "",
        role: "",
        ulb: "",
        roleOptions: [],
        ulbOptions: [],
        usernameErr: "",
        emailErr: "",
        roleErr: "",
        ulbErr: "",
        roleName: undefined,
        iotdashboard: false,
        stateDashboard: false,
        checkValue: false,
        openSelectulb: false,
        ULBselectionList: []
    }
    componentDidMount() {
        const payload = {
            token: this.props.token,
            page: this.state.page,
            limit: this.state.limit,
            sortName: this.state.sortObj.key,
            sortValue: this.state.sortObj.value,
            searchQuery: this.state.controls.search.value
        }
        this.props.OnFetchRegionalListing(payload)
        // this.props.onFetchUserHeaders(payload)
        axios({
            method: 'get',
            url: `/role/?admin=${true}`,
            headers: { 'Authorization': `Bearer ${this.props.token}` }
        })
            .then((response) => {
                this.setState({
                    roleOptions: response.data.data.filter((item) => {
                        if (item.name == "State Dashboard") {
                            item.name = "State/Regional Officer"
                        }
                        return item.roleId == 6
                    })
                })
            })
        axios({
            method: 'get',
            url: `ulb`,
            headers: { 'Authorization': `Bearer ${this.props.token}` }
        })
            .then((response) => {
               let filteredULB = response.data.data.ulbs.filter(item => item.isDisableULB === 'Active')
               
                this.setState({ ulbOptions: filteredULB })
                // setUlbOptions(response.data.data.ulbs)
            })

    }
    handleClickMoreVertIcon = (event, id, userName, email, ULBlist) => {
        this.setState({ open: !this.state.open, anchorEl: event.currentTarget, id, userName, email, ULBlist })
    }

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        this.setState({ page: selectedPage + 1 })
        let payload = {
            token: this.props.token,
            page: selectedPage + 1,
            limit: this.state.limit,
            sortName: this.state.sortObj.key,
            sortValue: this.state.sortObj.value,
            // searchQuery: this.state.controls.search.value
        }
        this.props.OnFetchRegionalListing(payload)
    };

    handleClickAwayEdit = () => {
        this.setState({ open: false });
    };
    handleDelete = (id) => {
        const payload = {
            id: id,
            token: this.props.token,
            page: this.state.page,
            limit: this.state.limit,
            sortName: this.state.sortObj.key,
            sortValue: this.state.sortObj.value,
            searchQuery: this.state.controls.search.value

        }
        const data = {
            id: id,
        }
        this.props.onDeleteUser(payload, data)
    }

    handleSort = (e, sortBy) => {
        let sortObj
        if (this.state.sortObj.key === sortBy.accessor) {
            let value = (this.state.sortObj.value === 'desc') ? 'asc' : 'desc';
            sortObj = {
                key: sortBy.accessor,
                value: value
            }
        }
        else {
            sortObj = {
                key: sortBy.accessor,
                value: 'desc'
            }
        }

        this.setState({ sortObj: sortObj, currentSortId: sortBy.accessor });
        let payload = {
            token: this.props.token,
            sortName: sortObj.key,
            sortValue: sortObj.value,
            page: this.state.page,
            limit: this.state.limit,
            searchQuery: this.state.controls.search.value
        }

        this.props.OnFetchRegionalListing(payload);

    }
    handleExport = () => {
        const payload = {
            token: this.props.token,
            page: this.state.page,
            limit: this.state.limit,
            sortName: this.state.sortObj.key,
            sortValue: this.state.sortObj.value,
            searchQuery: this.state.controls.search.value
        }
        this.props.OnFetchStateListingExport(payload)
    }
    handleEditRegional = (R_id, R_userName, R_email, R_ULBlist) => {
        axios({
            method: 'get',
            url: '/ulb/',
            headers: { 'Authorization': `Bearer ${this.props.token}` }
        })
            .then((response) => {
                this.setState({
                    openEditR_User: true,
                    email_r: R_email,
                    username_r: R_userName,
                    editId: R_id,
                    ulbOptions: response.data.data.ulbs,
                }, () => {
                    let ar = []
                    response.data.data.ulbs.forEach(element => {

                        R_ULBlist.forEach(elementUlb => {
                            if (element.ulbId == elementUlb) {
                                ar.push(element)
                            }
                        });
                    });
                    this.setState({ ULBselectionList: [...ar] })
                }
                )
            })
    }

    handleCloseEdituser = () => {
        this.setState({
            openEditR_User: false,
            email_r: "",
            username_r: "",
            checkValue: false,
            selected: [],
            nameSelected: [],
            editId: ""
        })

    }

    handleSubmitEdituser = (e) => {
        e.preventDefault();
        if (this.state.editId !== "") {
            const payload = {
                token: this.props.token,
                id: this.state.editId,
                userName: this.state.username_r,
                limit: 10,
                page: 1,
                email: this.state.email_r,
                ULBList: JSON.stringify(this.state.ULBselectionList)
            }
            this.props.OnUpdate_state_regional(payload)
            this.handleCloseEdituser()
        } else {
            const formdata = {
                token: this.props.token,
                userName: this.state.username_r,
                limit: this.state.limit,
                page: this.state.page,
                email: this.state.email_r,
                ULBList: JSON.stringify(this.state.ULBselectionList),
                roleId: this.state.roleName.roleId,
                isModuleAccess: {
                    "IsDashboardAccess": false,
                    "IsPropertyManagementAccess": false,
                    "IsSchedulePropertyAccess": false,
                    "IsTreatmentFacilityAccess": false,
                    "IsOperatorManagementAccess": false,
                    "IsLoadRecordAccess": false,
                    "IsWardManagementAccess": false,
                    "IsIOTDashboardAccess": this.state.iotdashboard,
                    "IsStateDashboardAccess": this.state.stateDashboard,
                    "IsULBManagementAccess": false,
                    "IsRoleManagementAccess": false,
                    "IsUserManagementAccess": false,
                    "IsClusterULBManagementAccess": false
                }
            }
            this.props.onAddUser(formdata, this.props)
            this.setState({
                openEditR_User: false,
                email_r: "",
                username_r: "",
                editId: "",
                edit_ulb_list: [],
                stateDashboard: false,
                iotdashboard: false,
                ULBselectionList: []
            })
        }

    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    handleSelect = (id, ulbName_data) => {
        const selectedRowsArr = this.state.selected
        const selectedRowArrName = this.state.nameSelected
        if (!selectedRowsArr.includes(id) || !selectedRowArrName.includes(ulbName_data)) {
            selectedRowsArr.push(id)
            selectedRowArrName.push(ulbName_data)
        } else if (selectedRowsArr.includes(id)) {
            selectedRowsArr.splice(selectedRowsArr.indexOf(id), 1)
            selectedRowArrName.splice(selectedRowArrName.indexOf(ulbName_data), 1)

        } else {
            return null
        }
        this.setState({ selected: [...selectedRowsArr], nameSelected: [...selectedRowArrName] })
    }

    handleOpenuser = () => {
        this.setState({
            openEditR_User: true,
            email_r: "",
            username_r: "",
            editId: "",
            edit_ulb_list: [],
            stateDashboard: false,
            iotdashboard: false,
            ULBselectionList: []
        })
    }
    handleChecked = (e) => {
        this.setState({ [e.target.name]: e.target.checked })
    }
    handleSelectedULB = (value) => {
        let arr = []
        value.forEach(element => {
            arr.push(element.ulbId)
        });
        this.setState({ ULBselectionList: [...arr] })
    }

    handlePropertySearch = (e) => {
        e.preventDefault()
        let searchVal = this.state.controls.search.value
        if (!!searchVal && searchVal.length >= 2) {
            let payload = {
                searchQuery:searchVal,
                token: this.props.token,
                page: this.state.page,
                limit: this.state.limit,
                sortName: this.state.sortObj.key,
                sortValue: this.state.sortObj.value,              
            }
            this.props.onSearchRegionalName(payload) 
        } else {
         
            swal("Enter atleast 2 characters")
        }
    }
    inputChangedHadler = (event, controlName) => {
        const updatedControls = {
            ...this.state.controls,
            [controlName]: {
                ...this.state.controls[controlName],
                value: event.target.value,
            }
        }
        this.setState({ controls: updatedControls });
        if( updatedControls.search.value === ''){
            let payload = {
                searchQuery:'',
                token: this.props.token,
                page: this.state.page,
                limit: this.state.limit,
                sortName: this.state.sortObj.key,
                sortValue: this.state.sortObj.value,
            }
            this.props.onSearchRegionalName(payload) 
        }
    }
    render() {
        const userHeaders = [
            {
                "header": "Sr No",
                "accessor": "Id",
                "sortable": "false",
                "filter": "false"
            },
            {
                "header": "UserName",
                "accessor": "UserName",
                "sortable": "false",
                "filter": "false"
            },
            {
                "header": "Email",
                "accessor": "Email",
                "sortable": "false",
                "filter": "false"
            },

            {
                "header": "Updated Date",
                "accessor": "UpdatedAt",
                "sortable": "false",
                "filter": "false"
            },
            {
                "header": "Action",
                "accessor": "actionButton",
                "sortable": "false",
                "filter": "false"
            }
        ]
        const Headers = !!userHeaders && userHeaders.map(header => {
            return (
                <TableCell key={header.header} className={"white-space"}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'start',
                        whiteSpace: 'break-spaces',
                        height: '3em'
                    }}>
                        {header.header === "Action" || header.header === "Sr No" ? "" :
                            <div onClick={(e) => this.handleSort(e, header)}>
                                <div>{
                                    this.state.currentSortId === header.accessor ? this.state.sortObj.value === 'asc' ? (<ArrowDropDownIcon fontSize="small" />) : (<ArrowDropUpIcon fontSize="small" />) : (<ArrowDropDownIcon fontSize="small" />)
                                }
                                </div>
                            </div>}
                        {header.header}
                    </div>
                </TableCell>
            )
        })

        const listingInspector = !!this.props.stateUserLIsting && this.props.stateUserLIsting.map((data, index) => {
            const ID = data['Id']
            const userName = data['UserName']
            const email = data['Email']
            // const role= data['role']
            // const roleId= data['roleId']
            // const ULBId = data['ULBId']
            // const unAssignable = data['unAssignable']
            // const isModuleAccess = data['isModuleAccess']
            // const UserDesignation= data["Designation"]
            const ULBlist = data["ULBList"]

            return (
                <TableRow key={index}
                >
                    {!!userHeaders && userHeaders.map(header => {
                        if (header.accessor == "UpdatedAt") {
                            return <TableCell style={{
                                whiteSpace: "normal",
                                wordWrap: "break-word"
                            }} key={index} >{moment(data[header.accessor]).format('DD-MMM-YYYY hh:mm a')}</TableCell>
                        }

                        if (header.accessor == 'actionButton') {
                            return <TableCell>
                                <IconButton onClick={(e) => this.handleClickMoreVertIcon(e, ID, userName, email, ULBlist)}
                                    aria-label="more"
                                    aria-controls="long-menu"
                                    aria-haspopup="true">
                                    <MoreVertIcon fontSize='small' />
                                </IconButton>
                                <Popper open={this.state.open} anchorEl={this.state.anchorEl}>
                                    <ClickAwayListener onClickAway={this.handleClickAwayEdit}>
                                        <Paper style={{ boxShadow: "none", border: "1px solid rgb(61, 171, 53)" }}>
                                            <MenuList>
                                                <MenuItem onClick={() => this.handleEditRegional(this.state.id, this.state.userName, this.state.email, this.state.ULBlist)}>{("Edit")}</MenuItem>

                                                <MenuItem style={{ color: '#3dab35' }} onClick={() => {
                                                    swal({
                                                        title: "Are you sure?",
                                                        text: "Once deleted, you will not be able to recover this data!",
                                                        icon: "warning",
                                                        buttons: {
                                                            cancel: 'No',
                                                            confirm: 'Yes'
                                                        }
                                                    })
                                                        .then((willDelete) => {
                                                            if (willDelete) {
                                                                this.handleDelete(this.state.id);
                                                            }
                                                        });
                                                }}>Delete</MenuItem>
                                            </MenuList>
                                        </Paper>
                                    </ClickAwayListener>
                                </Popper>
                            </TableCell>
                        }

                         if (header.accessor == "Id") {
                            return <TableCell 
                            key={header.accessor} 
                            style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
                              {index+1}
                         </TableCell>
                        }
                        else {
                            return <TableCell style={{
                                whiteSpace: "normal",
                                wordWrap: "break-word"
                            }} key={header.accessor}
                                className={"white-space"}>
                                {data[header.accessor]}
                            </TableCell>
                        }
                    })
                    }
                </TableRow>
            )
        })
        return (
            <div>
                <div className="dashboard_right_content" style={{ marginTop: "-4%" }}>
                    <img className="dashboard_right_content_top_icon" src="images/Rectangle.svg" />
                    <div className="dashboard_right_content_top">
                        <h5>State/Regional Officer</h5>
                        <div className="dashboard_right_content_top_options">
                        <div className="dashboard_right_content_top_search">
                                <Paper component="form">
                                    <IconButton aria-label="menu">
                                        <img src="images/search_icon.svg" />
                                    </IconButton>
                                    <InputBase onChange={(event) => this.inputChangedHadler(event, 'search')} placeholder="Name" />
                                    <Button onClick={this.handlePropertySearch} variant="contained" color="primary" type="submit">Search</Button>
                                </Paper>
                            </div>
                            <Button style={{ width: '100%' }} onClick={this.handleExport}>Export</Button>
                            <div>
                                <Button style={{ width: '100%' }} onClick={this.handleOpenuser}>Add&nbsp;new&nbsp;User</Button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* {(this.props.loadingCluster == false)? <div id="cover-spin"></div>:''} */}
                <div className="ulb_listing AreaLIsting scrollward " >
                    <TableContainer component={Paper} style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                        <Table stickyHeader aria-label="sticky table" style={{ tableLayout: 'fixed' }}>

                            <TableHead >
                                <TableRow >
                                    {Headers}
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {this.props.stateUserLIsting.length === 0 ? <TableRow style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', }}>
                                    No Records Found !!</TableRow> : listingInspector}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                {
                    this.props.totalCount && this.props.totalCount != 0 &&
                    <ReactPaginate
                        previousLabel={"Prev"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        // pageCount={10}
                        pageCount={this.props.totalCount / this.state.limit}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        style={{ borderLeftWidth: '0px' }}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"} />
                }



                <div className="setFreq">
                    <Dialog
                        maxWidth={'md'}
                        fullWidth={true}
                        // open={true}
                        open={this.state.openEditR_User}
                        aria-labelledby="responsive-dialog-title">
                        <DialogTitle className="text-center border-none " style={{ color: '#3dab35' }}>{this.state.editId !== "" ? "Edit User" : "Add User"}</DialogTitle>
                        <DialogContent >
                            <div className="create_ulb_content_outer">
                                <div className="create_ulb_content create_ulb_contentPop">
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <FormControl>
                                                <label className="required" htmlFor="username">User Name</label>
                                                <TextField id="username"
                                                    variant="outlined"
                                                    placeholder="Enter User Name"
                                                    name="username_r"
                                                    value={this.state.username_r}
                                                    onChange={this.handleChange} />
                                                {/* <div style={{ fontSize: 12, color: "red", fontFamily: "'Open Sans', sans-serif", marginTop: "1%" }}>{usernameErr}</div> */}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl>
                                                <label className="required" htmlFor="email">Email</label>
                                                <TextField id="email"
                                                    variant="outlined"
                                                    placeholder="Enter Email"
                                                    name="email_r"
                                                    value={this.state.email_r}
                                                    onChange={this.handleChange} />
                                                {/* <div style={{ fontSize: 12, color: "red", fontFamily: "'Open Sans', sans-serif", marginTop: "1%" }}>{emailErr}</div> */}
                                            </FormControl>
                                        </Grid>

                                        {this.state.editId == "" ? <><Grid item xs={6} >
                                            <FormControl>
                                                <label className="required" htmlFor="role">Role</label>
                                                {<Select value={this.state.roleName}
                                                    onChange={this.handleChange}
                                                    name="roleName">
                                                    {this.state.roleOptions.map((role) => (
                                                        <MenuItem value={role}>{role.name}</MenuItem>
                                                    ))}
                                                </Select>
                                                }

                                                <div style={{ fontSize: 12, color: "red", fontFamily: "'Open Sans', sans-serif", marginTop: "1%" }}>{this.state.roleErr}</div>
                                            </FormControl>
                                        </Grid>
                                            {this.state.roleName != undefined ?
                                                <Grid item xs={6}>
                                                    <div className="">
                                                        <FormControl>
                                                            <label className="required" htmlFor="modules">Modules Selection</label>
                                                            <div className="">
                                                                {this.state.roleName && this.state.roleName.name == 'State/Regional Officer' ? <>
                                                                    <FormControlLabel
                                                                        control={<Checkbox style={{ color: 'green', fontFamily: 'Open sans-serif', transform: 'scale(1.5)', width: 48 }} checked={this.state.stateDashboard} onChange={this.handleChecked} name="stateDashboard" />}
                                                                        label="State/Regional Officer"
                                                                    />
                                                                    <FormControlLabel
                                                                        control={<Checkbox style={{ color: 'green', fontFamily: 'Open sans-serif', transform: 'scale(1.5)', width: 48 }} checked={this.state.iotdashboard} onChange={this.handleChecked} name="iotdashboard" />}
                                                                        label="IOT Dashboard"
                                                                    />

                                                                </>
                                                                    : <span style={{ color: "red" }}>!!! Assigned "Role" doesn't have access to web application module(s). !!!</span>}
                                                            </div>
                                                        </FormControl>
                                                    </div>
                                                </Grid> : ""}
                                        </> : ""}
                                        <Grid item xs={6}>
                                            <label className="required" htmlFor="ulb">ULBs Selection</label>
                                            <Autocomplete
                                                multiple
                                                id="tags-outlined"
                                                options={this.state.ulbOptions}
                                                getOptionLabel={(option) => option.name}
                                                defaultValue={this.state.ULBselectionList}
                                                filterSelectedOptions
                                                onChange={(event, value) => this.handleSelectedULB(value)}
                                                renderInput={(params) => {
                                                    return (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            placeholder="Select ULB"
                                                        />
                                                    )
                                                }}
                                            />
                                        </Grid>

                                        {/* {this.state.selected.length > 0 ? <Grid item xs={6}>
                                            <label htmlFor="ulb">Selected ULBs Total:  {this.state.nameSelected.length}</label>

                                            <Paper component="ul" className="chip">
                                                {this.state.nameSelected.map((data, i) => {
                                                    return (
                                                        <li key={i}>
                                                            <Chip
                                                                label={data}
                                                                // onDelete={()=>this.handleDeleteCheckUlb(data)}
                                                                style={{ margin: "10px" }}
                                                            />
                                                        </li>
                                                    );
                                                })}

                                            </Paper>
                                        </Grid> : ""} */}


                                    </Grid>

                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <div className="create_ulb_buttons">
                                                <Button className="login_submit" variant="outlined" color="primary" onClick={this.handleCloseEdituser}>{("Cancel")}</Button>

                                                <Button className="login_submit" variant="contained" color="primary" onClick={this.handleSubmitEdituser}>{this.state.editId !== "" ? "Save change" : "Save"}</Button>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog>
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.auth.token,
        stateUserLIsting: state.stateDashboard.dashboardListing,
        totalCount: state.stateDashboard.totalCount

    }
}

const mapDispatchToProps = (dispatch) => {
    return ({
        OnFetchRegionalListing: (payload) => dispatch(actionsCreators.fetchRegionalListing(payload)),
        onDeleteSIUser: (payload) => dispatch(actionsCreators.deleteSIUser(payload)),
        OnFetchStateListingExport: (payload) => dispatch(actionsCreators.fetchStateListingExport(payload)),
        onDeleteUser: (payload, data) => dispatch(actionsCreators.deleteUserRegional(payload, data)),
        OnUpdate_state_regional: (payload) => dispatch(actionsCreators.update_state_regional(payload)),
        onAddUser: (payload, props) => dispatch(actionsCreators.addUser(payload, props)),
        onSearchRegionalName :(payload)=>dispatch(actionsCreators.SearchRegionalName(payload)),

    })

}

export default connect(mapStateToProps, mapDispatchToProps)(RegionalListing);