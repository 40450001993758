import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { head } from 'lodash';


export class RoleModulesView extends Component {
    render() {
        const roleModule = !!this.props.location && this.props.location.roleModule
        const mobileAcess = !!this.props.location && this.props.location.mobileAcess
        const webAccess = !!this.props.location && this.props.location.webAccess
        const modulerole = !!this.props.location && this.props.location.module
        const header = [
            // {
            //     header: "Role",
            //     accessor: "roleName",
            //     sortable: "false",
            //     filter: "false"
            // },
            {
                header: "Modules",
                accessor: "module",
                sortable: "false",
                filter: "false"
            },
            {
                header: "Access Mode",
                accessor: "accessmode",
                sortable: "false",
                filter: "false"
            },

        ]

        const roleHeaders = !!header && header.map((header) => {
            return <TableCell key={header.id}>
                <div style={{
                    display: 'flex',
                    alignItems: 'start',
                    whiteSpace: 'break-spaces',
                    height: '3em'
                }}>

                    {header.header}
                </div>
            </TableCell>
        })

        const m1 = !!modulerole && modulerole.IsULBManagementAccess != undefined && modulerole.IsULBManagementAccess == true ? <TableCell key={1} style={{
            whiteSpace: "normal",
            wordWrap: "break-word"
        }}>
            ULB Management
        </TableCell> : ''

        const m2 = !!modulerole && modulerole.IsRoleManagementAccess != undefined && modulerole.IsRoleManagementAccess == true ? <TableCell key={1} style={{
            whiteSpace: "normal",
            wordWrap: "break-word"
        }}>
            Role Management
        </TableCell> : ''
        const m3 = !!modulerole && modulerole.IsStateDashboardAccess != undefined && modulerole.IsStateDashboardAccess == true ? <TableCell key={1} style={{
            whiteSpace: "normal",
            wordWrap: "break-word"
        }}>
            State/Regional Officer
        </TableCell> : ''

        const m4 = !!modulerole && modulerole.IsUserManagementAccess != undefined && modulerole.IsUserManagementAccess == true ? <TableCell key={1} style={{
            whiteSpace: "normal",
            wordWrap: "break-word"
        }}>
            User Management
        </TableCell> : ''
        const m5 = !!modulerole && modulerole.IsClusterULBManagementAccess != undefined && modulerole.IsClusterULBManagementAccess == true ? <TableCell key={1} style={{
            whiteSpace: "normal",
            wordWrap: "break-word"
        }}>
            Cluster Management
        </TableCell> : ''



        const m6 = !!modulerole && modulerole.IsDashboardAccess != undefined && modulerole.IsDashboardAccess == true ? <TableCell key={1} style={{
            whiteSpace: "normal",
            wordWrap: "break-word"
        }}>
            Dashboard
        </TableCell> : ''

        const m7 = !!modulerole && modulerole.IsIOTDashboardAccess != undefined && modulerole.IsIOTDashboardAccess == true ? <TableCell key={1} style={{
            whiteSpace: "normal",
            wordWrap: "break-word"
        }}>
            IOT Dashboard
        </TableCell> : ''

        const m8 = !!modulerole && modulerole.IsPropertyManagementAccess != undefined && modulerole.IsPropertyManagementAccess == true ? <TableCell key={1} style={{
            whiteSpace: "normal",
            wordWrap: "break-word"
        }}>
            Property Management
        </TableCell> : ''

        const m9 = !!modulerole && modulerole.IsSchedulePropertyAccess != undefined && modulerole.IsSchedulePropertyAccess == true ? <TableCell key={1} style={{
            whiteSpace: "normal",
            wordWrap: "break-word"
        }}>
            Schedule Properties
        </TableCell> : ''

        const m10 = !!modulerole && modulerole.IsTreatmentFacilityAccess != undefined && modulerole.IsTreatmentFacilityAccess == true ? <TableCell key={1} style={{
            whiteSpace: "normal",
            wordWrap: "break-word"
        }}>
            Treatment Facilities
        </TableCell> : ''

        const m11 = !!modulerole && modulerole.IsOperatorManagementAccess != undefined && modulerole.IsOperatorManagementAccess == true ? <TableCell key={1} style={{
            whiteSpace: "normal",
            wordWrap: "break-word"
        }}>
            Operator Management
        </TableCell> : ''

        const m12 = !!modulerole && modulerole.IsWardManagementAccess != undefined && modulerole.IsWardManagementAccess == true ? <TableCell key={1} style={{
            whiteSpace: "normal",
            wordWrap: "break-word"
        }}>
            Ward Management
        </TableCell> : ''

        const m13 = !!modulerole && modulerole.IsLoadRecordAccess != undefined && modulerole.IsLoadRecordAccess == true ? <TableCell key={1} style={{
            whiteSpace: "normal",
            wordWrap: "break-word"
        }}>
            Loads Record
        </TableCell> : ''



        const headerlist = !!header && header.map((item) => {

            if (item.accessor === 'accessmode' && mobileAcess === true && webAccess == true) {
                return <TableCell key={item.id} style={{
                    whiteSpace: "normal",
                    wordWrap: "break-word"
                }}>
                    Web | App
                </TableCell>
            }
            else if (item.accessor === 'accessmode' && mobileAcess === false && webAccess == false) {
                return <TableCell key={item.id} style={{
                    whiteSpace: "normal",
                    wordWrap: "break-word"
                }}>

                </TableCell>
            }
            else if (item.accessor === 'accessmode' && webAccess == true) {
                return <TableCell key={item.id} style={{
                    whiteSpace: "normal",
                    wordWrap: "break-word"
                }}>
                    Web
                </TableCell>
            }

            else if (item.accessor === 'accessmode' && mobileAcess === true) {
                return <TableCell key={item.id} style={{
                    whiteSpace: "normal",
                    wordWrap: "break-word"
                }}>
                    App
                </TableCell>
            }
        })
        return <div>
            <div className="dashboard_right_content">
                <img className="dashboard_right_content_top_icon" src="images/Rectangle.svg" />
                <div className="dashboard_right_content_top">
                    <h5> <Link to="/role-management" className="email_link"><ArrowBackIosIcon></ArrowBackIosIcon></Link>Access Module Details</h5>

                    <h5 className="email_link">Role :- {roleModule}</h5>
                </div>
               
            </div>
            <div className="operator_listing" >
                <TableContainer component={Paper} style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                    <Table stickyHeader aria-label="sticky table" style={{ tableLayout: 'fixed' }}>
                        {/* <colgroup>
                    <col style={{width:'20%'}}/>
                    <col style={{width:'20%'}}/>
             
                </colgroup> */}
                        <TableHead >
                            <TableRow >
                                {roleHeaders}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {roleModule == 'Admin' ? <> <TableRow>
                                {/* <TableCell>{roleModule}</TableCell> */}
                                {m1}
                                {headerlist}
                            </TableRow>
                                <TableRow>
                                    {/* <TableCell>{ }</TableCell> */}
                                    {m2}
                                    {headerlist}
                                </TableRow>

                                <TableRow>
                                    {/* <TableCell>{ }</TableCell> */}
                                    {m3}
                                    {headerlist}
                                </TableRow>
                                <TableRow>
                                    {/* <TableCell>{ }</TableCell> */}
                                    {m4}
                                    {headerlist}
                                </TableRow>
                                <TableRow>
                                    {/* <TableCell>{ }</TableCell> */}
                                    {m5}
                                    {headerlist}
                                </TableRow></> :
                                roleModule == 'ULB Admin' ? <>
                                    <TableRow>
                                        {/* <TableCell>{roleModule}</TableCell> */}
                                        {m6}
                                        {headerlist}
                                    </TableRow>

                                    {/* <TableRow>
                                        <TableCell>{ }</TableCell>
                                        {m7}
                                        {headerlist}
                                    </TableRow> */}


                                    <TableRow>
                                        {/* <TableCell>{ }</TableCell> */}
                                        {m8}
                                        {headerlist}
                                    </TableRow>
                                    <TableRow>
                                        {/* <TableCell>{roleModule}</TableCell> */}
                                        {m13}
                                        {headerlist}
                                    </TableRow>
                                    <TableRow>
                                        {/* <TableCell>{ }</TableCell> */}
                                        {m9}
                                        {headerlist}
                                    </TableRow>

                                    <TableRow>
                                        {/* <TableCell>{ }</TableCell> */}
                                        {m10}
                                        {headerlist}
                                    </TableRow>

                                    <TableRow>
                                        {/* <TableCell>{ }</TableCell> */}
                                        {m11}
                                        {headerlist}
                                    </TableRow>

                                    <TableRow>
                                        {/* <TableCell>{ }</TableCell> */}
                                        {m12}
                                        {/* {headerlist} */}
                                        {headerlist}
                                    </TableRow>

                                    <div style={{color:'red', fontSize:'15px', textAlign:'left'}}>*IOT Dashboard, is allowed only for PNP ULB.</div>
                                </> : roleModule == 'State/Regional Officer' ? <>
                                    <TableRow>
                                        {/* <TableCell>{roleModule}</TableCell> */}
                                        {m3}
                                        {headerlist}
                                    </TableRow>
                                    <TableRow>
                                        {/* <TableCell>{ }</TableCell> */}
                                        {m7}
                                        {headerlist}
                                    </TableRow>
                                </> : roleModule == 'Scheduler' ?
                                    <TableRow>
                                        {/* <TableCell>{'Scheduler'}</TableCell> */}
                                        {m9 == ""? <TableCell></TableCell>: m9}
                                        {headerlist}
                                    </TableRow> :
                                    roleModule == 'ULB officer' ?
                                    <>
                                     <TableRow>
                                        {/* <TableCell>{'Scheduler'}</TableCell> */}
                                        {m8 == ""? <TableCell></TableCell>: m8}
                                        {headerlist}
                                    </TableRow>
                                     <TableRow>
                                        {/* <TableCell>{'Scheduler'}</TableCell> */}
                                        {m9 == ""? <TableCell></TableCell>: m9}
                                        {headerlist}
                                    </TableRow>
                                    </>
                                   :
                                    <TableRow>
                                        {/* <TableCell>{roleModule}</TableCell> */}
                                        <TableCell></TableCell>
                                        {headerlist}
                                    </TableRow>}



                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    }

}
export default RoleModulesView