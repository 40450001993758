import * as actionTypes from './actionsTypes';
import axios from '../../http-config';
import moment from 'moment/moment';
export const fetchDashboardSuccess = (response) => {
    return {
        type: actionTypes.FETCH_DASHBOARD_SUCCESS,
        data: response.data,
        id:response
      
    }
}

export const fetchDashboardFail = (error) => {
    return {
        type: actionTypes.FETCH_DASHBOARD_FAIL,
        error:error
    }
}

export const fetchDashboardStart = () => {
    return {
        type: actionTypes.FETCH_DASHBOARD_START
    }
}

export const fetchDashboard = (payload) => {
    let token = payload.token;
    let interval = payload.interval
    var startDate = moment(payload.fromDate);
    var endDate = moment(payload.todate);
    var result =  endDate.diff(startDate, 'days');
    return dispatch => {
        dispatch(fetchDashboardStart());
        let url 
        if(result >30 && result <=365){
             url = `dashboard/?fromDate=${payload.fromDate}&toDate=${payload.todate}&Month=true`;
        }else if(result >365){
             url = `dashboard/?fromDate=${payload.fromDate}&toDate=${payload.todate}&Year=true`;

        }else{
             url = `dashboard/?fromDate=${payload.fromDate}&toDate=${payload.todate}`;
        }


        axios({
            method: 'get',
            url: url,
            headers: {'Authorization': `Bearer ${token}`}
        })
        .then((response) => {
            const fetchedEmailManageResponse = response.data;
            dispatch(fetchDashboardSuccess(fetchedEmailManageResponse));
        }).catch(error => {
            dispatch(fetchDashboardFail(error));
        })

    }
}


