import React,{Component} from 'react'
import axios from '../../http-config'
import { connect } from 'react-redux';
import * as actionsCreators from  '../../store/actions/index';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import {Doughnut,Line, Bar} from 'react-chartjs-2';
import BasicTable from './Table'
import BasicTableArea from './tableArea'
import Grid from '@material-ui/core/Grid';
import { Paper } from '@material-ui/core';

class Chart extends Component{


  state = {
    filter : {
      'interval':'',
    },
    data:[],
    id:'',
    isDisabled:false,
    interval:'Current Day'
  }
  componentDidMount=()=>{
      let payload = {
        token: this.props.token,
        filter: JSON.stringify([this.state.filter]),
        interval:this.state.interval

    }
      //  this.props.onFetchDashboardData(payload)
    }

  render(){
    let dashboard_data =!!this.props.data.dashboard_data&& this.props.data.dashboard_data
    let colors = []
    let values = []
    let pie_chart_labels = []
    let line_chart_labels = []
    let line_chart_data   = []
    let rows = []
    let TableDataBody = []
    let columnsDataHeaders = []
    let tfName =[]
    let tfCounts =[]
    let TotalTippingFee = []
    let LoadsWithDecantationFee=[]
    let LoadsWithoutDecantationFee =[]
    if(!!dashboard_data&&dashboard_data.length >0){
    !!dashboard_data&&dashboard_data.length >0&&dashboard_data[0].property_type&&dashboard_data[0].property_type.forEach(function(property_type) {
       colors.push(property_type.Color)
       values.push(property_type.Count)
       pie_chart_labels.push(property_type.Name)

    });
    !!dashboard_data&&dashboard_data[0].date_wise_loads.forEach(function(date_wise_load) {
      if ('Year' in date_wise_load){
        line_chart_labels.push(date_wise_load.Year)
        line_chart_data.push(date_wise_load.Count)

      } else  if ('Month' in date_wise_load){

        line_chart_labels.push(date_wise_load.Month)
        line_chart_data.push(date_wise_load.Count)

      }else{
        line_chart_labels.push(date_wise_load.DateTime)
        line_chart_data.push(date_wise_load.Count)
      }

  
    });
    rows = !!dashboard_data&&!!dashboard_data[0]&&dashboard_data[0].tf_data.body
    columnsDataHeaders= dashboard_data[0].tf_data.headers

   !!dashboard_data&&dashboard_data[0].tf_data.body.forEach(function(loads) {
    tfName.push(loads.tfName)
    LoadsWithDecantationFee.push(loads.LoadsWithDecantationFee)
    LoadsWithoutDecantationFee.push(loads.LoadsWithoutDecantationFee)
      
    })
    TableDataBody = dashboard_data[0].table_data.body
    }
    
    //const final=[]
    //const color=['#8dd3c7','#ffffb3','#bebada','#fb8072','#80b1d3','#fdb462','#b3de69','#fccde5','#d9d9d9']
    //const  data=[300, 50, 100,76,65,143,98,67,86]
    const color  = colors
    const data   = values
    const labels = pie_chart_labels
    // const results =[...this.props.propertyTypes]
    //     for (const result of results){
    //       for(const item in result){
    //       final.push(result[item])
    //       }
    //     }

    let minTick         = Math.min(...line_chart_data) - (Math.max(...line_chart_data)*0.1) < 0 ? 0 : Math.round(Math.min(...line_chart_data) - (Math.max(...line_chart_data)*0.1))
    let maxTick         = Math.ceil(Math.max(...line_chart_data) + (Math.max(...line_chart_data)*0.1))
    const tickDifference  = Math.ceil(maxTick/10)
    maxTick = Math.max(...line_chart_data) + tickDifference
    minTick = Math.min(...line_chart_data) - tickDifference < 0 ? 0 : Math.round(Math.min(...line_chart_data) - tickDifference)


    let ulbCount = 0
    let clstCount = 0
   const dataCls= !!dashboard_data&&dashboard_data[0]&&dashboard_data[0].ulb_cluster_data?dashboard_data[0].ulb_cluster_data:[]
   dataCls.forEach((counts)=>{
      if(counts.ULBType === "Host"){
        ulbCount = counts.Count
      }
      if(counts.ULBType == "Cluster"){
        const n= parseInt(counts.Count)
        clstCount = clstCount + n
      }

    })

   const columnsDataUlb = ["ULBName", "Count",  "ULBType"]
    const maindata = {
      labels:labels,
      datasets: [{
          data:data,
          backgroundColor:color,
      }],
      options:{
      legend:{
        position:"right",
        align:"middle"
      }
      }
    };

    var lineChartOptions = {
      showScale: true,
      //pointDot: true,
      //showLines: false,
      title: {
        display: true,
        text: 'Date Wise Loads'
    },
      legend: {
        display: true,
        labels: {
          boxWidth: 50,
          fontSize: 12,
          fontColor: 'rgb(115 115 115)',
          padding: 5,
        }
      },
      scales: {
        yAxes: [{
          ticks: {
            //beginAtZero:true,
            autoSkip: true,
            min: minTick,
            maxTicksLimit: tickDifference,
            max: maxTick
          },
          scaleLabel: {
            boxWidth: 50,
            fontSize: 12,
            fontColor: '#3DAB35',
            padding: 10,
            display: true,
            labelString: 'No of Loads',
            fontStyle: "bold",
          }
        }],
        xAxes: [{
          scaleLabel: {
            boxWidth: 50,
            fontSize: 12,
            fontColor: '#3DAB35',
            padding: 10,
            display: true,
            labelString: 'Year / Month / Day',
            fontStyle: "bold",

          }
        }]
      }

    }

    const lineData =  {
      labels: line_chart_labels,
      datasets: [
        {
          color:"#3DAB35",
          label:"Collected Loads",
          fill: false,
          lineTension: 0.1,
          borderColor:  "#3DAB35",
          pointRadius: 5,
          pointBorderColor: "#3DAB35",
          pointBackgroundColor: "#3DAB35",
          data: line_chart_data,
        }  
      ]
    };
    const barData =  {
      labels: tfName,
      datasets: [
        {
          label: 'With Decantation Fee',
          data: LoadsWithDecantationFee,
          backgroundColor: '#52AC36',
          borderWidth: 3,
          width:100
        },
        {
          label: 'Without Decantation Fee',
          data: LoadsWithoutDecantationFee,
          backgroundColor: 'gray',
          borderWidth: 3,
          width:100
        }
       
      ],
     
    }
   const  ULBBarData={
    labels: ["Host", "Cluster"],
    datasets: [
      {
        label: 'Loads',
        data: [ulbCount,clstCount],
        backgroundColor: '#52AC36',
        borderWidth: 3,
        width:100
      },
      // {
      //   label: 'Cluster',
      //   data: [14,13,12,11],
      //   backgroundColor: 'green',
      //   borderWidth: 3,
      //   width:100
      // }
    ],
   
  }
    const BarOptions = {
      // onClick: function(evt, element) {
      //   console.log(evt,element,"gggggggggg");
      // },
      legend: {
        position: "top",
        align: "center",
        labels: {
          fontColor: 'black',
          fontFamily:'sans-serif',
          defaultFontSize:120,
      }
     },
     ticks: {
      autoSkip: false
  },
      scales: {
        yAxes: [
          { 
            ticks: {
              beginAtZero: true
          },
           scaleLabel: {
            boxWidth: 50,
            fontSize: 15,
            fontColor: '#3DAB35',
            padding: 10,
            display: true,
            labelString: 'No of Loads',
            fontStyle: "bold",

          },
          gridLines: {
            drawOnChartArea: false,
            drawTicks: true
         }
         
        }
        ],

        xAxes: [{
          barPercentage:0.6,
          scaleLabel: {
            boxWidth: 50,
            fontSize: 15,
            fontColor: '#3DAB35',
            padding: 10,
            display: true,
            labelString: this.props.loadsTypes== 4? 'ULB / Cluster Loads': 'TF Names',
            fontStyle: "bold",

          },
          gridLines: {
            // display: true,
            // drawBorder: true,
            drawOnChartArea: false,
         },
         
        }]
      },
    }
    return(
    <Grid>
      <Grid container spacing={1} direction="row"> 
      {this.props&&this.props.loadsTypes==1? <Grid item xs={12}>
          <Paper style={{height:"500"}}><hr style={{border: "1px solid #3DAB35", marginLeft:"5%", width:"30px"}}></hr><Line data={lineData} options = {lineChartOptions} width={900} height={380} align="center"/></Paper>  
        </Grid>: this.props&&this.props.loadsTypes==2?<Grid item xs={12}> <Paper style={{height:"500"}}><hr style={{border: "1px solid #3DAB35", marginLeft:"5%",width:"30px"}}></hr><Doughnut data={maindata}  
            options={{
              legend:{
                display:true,
                position:'right',
                labels: {
                  boxWidth: 50,
                  fontSize: 10,
                  fontColor: 'rgb(115 115 115)',
                  padding: 4,
                }
              },
              title: {
                display: true,
                text: 'Property Type Wise Loads'
            },
            layout: {
              padding: {
                left: 10,
                right: 10,
                top: 10,
                bottom: 10,
              }
            }
            }} 
            width={900} height={380} /></Paper></Grid> : this.props&&this.props.loadsTypes==3?<>
            <Grid item xs={12}>
            <Paper style={{height:"500"}}><hr style={{border: "1px solid #3DAB35", marginLeft:"5%", width:"30px"}}></hr><Bar data={barData} options = {BarOptions} width={900} height={380} align="center"></Bar></Paper>  
            </Grid>
            <Grid item xs={12}>
            <Paper><hr style={{border: "1px solid #3DAB35", marginLeft:"5%", width:"30px"}}></hr><BasicTable rows={rows} columnsDataHeaders={columnsDataHeaders}/></Paper>
            </Grid>
            </> :this.props&&this.props.loadsTypes==4?
            <>
            <Grid item xs={12}>
            <Paper style={{height:"500"}}><hr style={{border: "1px solid #3DAB35", marginLeft:"5%", width:"30px"}}></hr><Bar data={ULBBarData} options = {BarOptions} width={900} height={380} align="center"></Bar></Paper>
            </Grid>
            <Grid item xs={12}>
            <Paper><hr style={{border: "1px solid #3DAB35", marginLeft:"5%", width:"30px"}}></hr><BasicTableArea rows={dataCls} columnsDataUlb={columnsDataUlb}/></Paper>
            </Grid>
            </>
          :""}
        
       
      </Grid>
    </Grid>
    )
  }
}

const mapStateToProps = state => {
  return {
    data: state.dashboard.dashboard,
    loading: state.loads.loading,
    token: state.auth.token

  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchDashboardData: (payload) => dispatch(actionsCreators.fetchDashboard(payload))
  }
}

export default  connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(Chart, axios));
