import React, { Component } from 'react';
import { connect } from 'react-redux'
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { Grid, MenuItem } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import {Link} from 'react-router-dom'
import Button from '@material-ui/core/Button';
import ImageUploader from 'react-images-upload';
import CancelIcon from '@material-ui/icons/Cancel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
const options = [
    {
        id    : "1",
        name  : "Corporation",
    }, {
        id    : "2",
        name  : "Town Panchayat",
    }, {
        id    : "3",
        name  : "Village Panchayat",
    }, {
        id    : "4",
        name  : "Municipality"
    }
  ];
class UlbForm extends Component {
    constructor(props){
        super(props)
        this.state={
            ulbName                : this.props.name                     ? this.props.name                     : "",
            userId                 : this.props.userId                   ? this.props.userId                   : "",
            ulbName_tm             : this.props.name_tn                  ? this.props.name_tn                  : "",
            ulbCode                : this.props.code                     ? this.props.code                     : "",
            email                  : this.props.email                    ? this.props.email                    : "",
            adminName              : this.props.username                 ? this.props.username                 : "", 
            type                   : this.props.typeId                   ? this.props.typeId                   : "",
            overallLoadNo          : this.props.overallLoadNumberPrefix  ? this.props.overallLoadNumberPrefix  : "",
            receiptNo              : this.props.receiptNumberPrefix      ? this.props.receiptNumberPrefix      : "",
            img                    : {
                name               : this.props.logo                     ? this.props.logo                     : "",
            },
            ulbNameErr             : "",
            ulbCodeErr             : "",
            emailErr               : "",
            adminNameErr           : "",
            typeErr                : "",
            overallLoadNoErr       : "",
            receiptNoErr           : "",    
            imgErr                 : "" ,
            isLogo                 : this.props.logo                    ? true                                 : false,

            isZoneRequire           :this.props.isZoneNotRequired == false?this.props.isZoneNotRequired:  true,
            isStatus                : null,
            StatusValue             : !!this.props.isDisableULB? !!this.props.isDisableULB&&this.props.isDisableULB  === 'Active' ?false :true:'',
            statedashboardValue     : !!this.props.statedashboardValue ?  !!this.props.statedashboardValue &&this.props.statedashboardValue === 'No' ? false : true: false,
            isRepeatCollection:!!this.props.isRepeatCollection? this.props.isRepeatCollection: false
        }
    }

    handleChange = (event) =>{
        if(event.target.name == 'overallLoadNo' || event.target.name == 'receiptNo'){
                const overallLoadNo = event.target.value.toUpperCase()
                const receiptNo = event.target.value.toUpperCase()
                this.setState({[event.target.name]:overallLoadNo ,[event.target.name]: receiptNo}, ()=> {
                    this.checkValidity()
                })
        }
        else{
            this.setState({[event.target.name]:event.target.value}
                , ()=> {
                this.checkValidity()
            }
            )
        }
    }
    handleRemove=()=>{
     this.setState({img :{name :''},isLogo:false})
    }
    handleSubmit = (e) =>{
        e.preventDefault()
        const err = this.checkValidity()
        if(!err)
        {
            const formdata = new FormData()
            let token=this.props.token
            this.props.id && formdata.append("id",this.props.id)
            this.props.logo ? formdata.append("filename",this.state.img['name']) : (this.state.img['name'] ? formdata.append("filename",this.state.img,this.state.img['name']) : formdata.append("filename",'') )
            formdata.append("userId" ,this.state.userId)
            formdata.append("name", this.state.ulbName)
            formdata.append("name_tn", this.state.ulbName_tm)
            formdata.append("code", this.state.ulbCode)
            formdata.append("email",this.state.email)
            formdata.append("username", this.state.adminName)
            formdata.append("typeId", this.state.type)
            formdata.append("overallLoadNumberPrefix",this.state.overallLoadNo)
            formdata.append("receiptNumberPrefix",this.state.receiptNo)
            // formdata.append("isZoneNotRequired", this.state.isZoneRequire)
            formdata.append("isDisableULB", this.state.StatusValue)
            // formdata.append("isRepeatCollection", this.state.isRepeatCollection)
            formdata.append("statedashboardValue", this.state.statedashboardValue)
            let SubmitProp=this.props
            this.props.handleSubmit(formdata,SubmitProp,token)
        }
    }
    checkValidity = () => {
        let isError = false
        if(!this.state.ulbName){
            isError=true
            this.setState({ulbNameErr : '*This field is required'})
        }
        else if((this.state.ulbName.length > 0 && this.state.ulbName.length <3 ) || (this.state.ulbName.length > 100)){
            isError=true
            this.setState({ulbNameErr : '*Name should be Minimum 3 characters and Maximum 100 characters'})
        }
        else if(this.state.ulbName && this.state.ulbName.length>=3){
            this.setState({ulbNameErr:''})
        }
        if(!this.state.adminName){
            isError=true
            this.setState({adminNameErr:'*This field is required'})
        }
        else if((this.state.adminName.length > 0 && this.state.adminName.length <3) || (this.state.adminName.length > 25)){
            isError=true
            this.setState({adminNameErr : '*Name should be Minimum 3 characters and Maximum 25 characters'})
        }
        else{
            this.setState({adminNameErr:''})
        }
        if(!this.state.ulbCode){
            isError=true
            this.setState({ulbCodeErr:'*This field is required'})
        }
        else if((this.state.ulbCode.length > 0 && this.state.ulbCode.length <3) || (this.state.ulbCode.length > 6)){
            isError=true
            this.setState({ulbCodeErr : '*Code should be Minimum 3 characters and Maximum 6 characters'})
        }
        else{
            this.setState({ulbCodeErr:''})
        }
        if(this.state.email){
            var mailformat= /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
            if(!this.state.email.match(mailformat)){
                isError=true
                this.setState({emailErr:'*You have entered invalid email address'})
            }
            else{
                this.setState({emailErr:''})
            }
        }
        else if(!this.state.email){
            this.setState({emailErr:'*This field is required'})
        }
        if(!this.state.type){
            isError=true
            this.setState({typeErr : "*This field is required"})
        }
        else{
            this.setState({typeErr:''})
        }
        if(!this.state.overallLoadNo){
            isError=true
            this.setState({overallLoadNoErr : "*This field is required"})
        }
        else{
            this.setState({overallLoadNoErr: ''})
        }
        if(!this.state.receiptNo){
            isError=false
            this.setState({receiptNoErr : ""})
        }
        else{
            this.setState({receiptNoErr : ""})
        }
        if(!this.state.img){
            isError=true
            this.setState({imgErr:'*This field is required'})
        }
        else{
            this.setState({imgErr:''})
        }
        return isError
    }
    handleImage = (e) => {
        this.setState({img:e[0],isLogo:true}, ()=> this.checkValidity())
    }
    isZonetRequired = (e)=>{
        this.setState({isZoneRequire:e.target.value })
    }

    handlChangeStatus =(e)=>{
        this.setState({isStatus: e.target.value , StatusValue :  e.target.value})
    }


    handleStatedashboardValue =(e)=>{
        this.setState({statedashboardValue: e.target.value})
    }
    isRepeatCollectionHandle =(e)=>{
        this.setState({isRepeatCollection: e.target.value})
    }
    render() {
        return (
            <div>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                <FormControl>
                                    <label className="required" htmlFor="ulbName">ULB Name</label>
                                        <TextField id="ulbName" 
                                                variant="outlined" 
                                                placeholder="Enter Name" 
                                                name="ulbName" 
                                                value={this.state.ulbName} 
                                                onChange={this.handleChange}/>
                                        <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.ulbNameErr}</div>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                <FormControl>
                                    <label htmlFor="ulbName_tm">ULB Name (Tamil)</label>
                                        <TextField id="ulbName_tm" 
                                                    variant="outlined" 
                                                    placeholder="பெயரை உள்ளிடுக" 
                                                    name="ulbName_tm" 
                                                    value={this.state.ulbName_tm} 
                                                    onChange={this.handleChange}/>
                                </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                <FormControl>
                                    <label className="required" htmlFor="ulbCode">ULB Code</label>
                                    <TextField id="ulbCode"
                                            variant="outlined"
                                            placeholder="Enter Code"
                                            name="ulbCode"
                                            value={this.state.ulbCode}
                                            onChange={this.handleChange}/>
                                    <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.ulbCodeErr}</div> 
                                </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                <FormControl>
                                    <label className="required" htmlFor="email">Admin Email</label>
                                    <TextField id="email"
                                            variant="outlined"
                                            placeholder="Enter Admin Email"
                                            name="email"
                                            value={this.state.email}
                                            onChange={this.handleChange}/>
                                    <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.emailErr}</div> 
                                </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                <FormControl>
                                    <label className="required" htmlFor="adminName">Admin User Name</label>
                                    <TextField id="adminName"
                                            variant="outlined"
                                            placeholder="Enter Admin User Id"
                                            name="adminName"
                                            value={this.state.adminName}
                                            onChange={this.handleChange}/>
                                    <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.adminNameErr}</div> 
                                </FormControl> 
                                </Grid>
                                <Grid item xs={6}>
                                <FormControl>
                                    <label className="required" htmlFor="type">Overall Load Number Prefix</label>
                                    <TextField id="overallLoadNo"
                                            variant="outlined"
                                            placeholder="Enter Overall Load Number Prefix"
                                            name="overallLoadNo"
                                            value={this.state.overallLoadNo}
                                            onChange={this.handleChange}
                                            disabled={this.props.disabled ? this.props.disabled : false}
                                            inputProps={{ maxLength: 3 }}/>
                                    <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.overallLoadNoErr}</div> 
                                </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                         <FormControl>
                                        <label htmlFor="type">Receipt Number Prefix</label>
                                        <TextField id="receiptNo"
                                                variant="outlined"
                                                placeholder="Enter Receipt Number Prefix"
                                                name="receiptNo"
                                                value={this.state.receiptNo}
                                                onChange={this.handleChange}
                                                //disabled={this.props.disabled ? this.props.disabled : false}
                                                inputProps={{ maxLength: 3 }}/>
                                        <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.receiptNoErr}</div> 
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                <FormControl>
                                    <label className="required" htmlFor="type">Type</label>
                                    <Select value={this.state.type} onChange={this.handleChange} name="type">
                                        {options.map((option)=>(
                                            <MenuItem value={option.id}>{option.name}</MenuItem>
                                        ))}
                                    </Select>
                                    <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.typeErr}</div> 
                                </FormControl>
                                        </Grid>
                                <Grid item xs={6}>
                                <FormControl variant="outlined" size="small">
                                     <label>Logo</label>      <span style={{display:'flex'}}>
                                                    <ImageUploader style={{align:'left'}}withIcon={false}
                                                        singleImage={true} 
                                                        fileContainerStyle = {{color:'#3dab35'}} 
                                                        buttonText='Upload'
                                                        buttonStyles={{backgroundColor:'white', color:'#3dab35',fontSize:'12px', borderRadius:'5px',align:'left',height:'35px',	width:'100%',padding:'0px',marginTop:'1px',marginBottom:'0%', height:"45px"}}
                                                        withLabel={false}
                                                        value={this.state.img}
                                                        onChange={this.handleImage}
                                                        imgExtension={['.jpg', '.png','.jpeg']}
                                                        fileSizeError=" file size is too big"
                                                        fileTypeError=" is not supported file extension"
                                                        maxFileSize={5242880}
                                                        buttonClassName="imgButtonEnable"
                                                        />
                                                        {this.state.isLogo &&
                                                         <CancelIcon style={{fontSize:'large',color:'#f51c00'}}  onClick={this.handleRemove} />
                                                         }
                                                        </span>
                                            { this.state.imgErr ? 
                                                   <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"5%",lineHeight:'11px'}}>{this.state.imgErr}</div> 
                                                   : 
                                                   <div>{"Upload Logo"}</div>
                                            }
 
                                        </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                <FormControl>
                                    <label htmlFor="statusType" >Status</label>
                                <TextField id="select"  name="statusType" value={this.state.StatusValue} onChange = {this.handlChangeStatus} select>
                                    <MenuItem value={false}>{'Active'}</MenuItem>
                                    <MenuItem value={true}>{'In Active' }</MenuItem>
                                </TextField>
                                </FormControl>
                                </Grid>
                                 {/* <Grid item xs={6}>
                                <FormControl>
                                    <label className="required" htmlFor="reuireZone">Is zone required for ward</label>
                                    <TextField id="select1"  name="reuireZone" value={this.state.isZoneRequire} onChange = {this.isZonetRequired} select>
                                    <MenuItem value={false}>Yes</MenuItem>
                                    <MenuItem value={true}>No</MenuItem>
                                </TextField>
                                </FormControl>
                                        </Grid> */}


                                {/* <Grid item xs={6}>
                                <FormControl>
                                    <label htmlFor="ULBstatedashboard">Display ULB in State/Regional Officer</label>
                                    <Select value={this.state.statedashboardValue} onChange={this.handleStatedashboardValue} name="StateValue">
                                            <MenuItem value={true}>Yes</MenuItem>
                                            <MenuItem value={false}>No</MenuItem>
                                  
                                    </Select> */}
                                    {/* <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.typeErr}</div>  */}
                                {/* </FormControl>
                                </Grid> */}
                              {/* <Grid item xs={6}>
                                <FormControl>
                                    <label className="required" htmlFor="isRepeatCollection">Is Repeat Collection Required?</label>
                                    <TextField id="isRepeatCollection"  name="isRepeatCollection" value={this.state.isRepeatCollection} onChange = {this.isRepeatCollectionHandle} select>
                                    <MenuItem value={true}>Yes</MenuItem>
                                    <MenuItem value={false}>No</MenuItem>
                                </TextField>
                                </FormControl>
                                        </Grid> */}
                                
                            </Grid>
                      
                        <Grid container spacing={3}>
                                <Grid item xs={12}>
                                <div className="create_ulb_buttons">
                                    <Link style={{textDecoration:"none", color:"#3dab35"}} to="/"><Button className="login_submit" variant="outlined" color="primary">Cancel</Button></Link>
                                    <Button className="login_submit" variant="contained" color="primary" onClick={this.handleSubmit}>Save</Button>
                                </div>
                                </Grid>
                        </Grid>
                      
                        </div>
        );
    }
}

const mapStateToProps = (state,props) => {
    
    return{
        props:props,
        token:state.auth.token
    }
}

const  mapDispatchToProps = dispatch=>{
    return{
    }
   
}

export default connect(mapStateToProps,  mapDispatchToProps)(UlbForm)