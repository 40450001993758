import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import ReactExport from "react-export-excel";
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  containerTF: {
    //  maxHeight:'29.6vh',
     marginTop:'20px'
   },
});

export default function StickyHeadTable(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  let rowsDataTF    = []
  let columnsDataForAreaWise = []
  let rowsDataForAreaWise    = []
  
  function createData(tfName,LoadsCount, DecantationFee, TotalDecantationFee,LoadsWithDecantationFee, LoadsWithoutDecantationFee) {
    return {tfName,LoadsCount, DecantationFee, TotalDecantationFee,LoadsWithDecantationFee, LoadsWithoutDecantationFee };
  }
  props.rows.forEach(function(obj){
    let row = createData(obj.tfName,obj.LoadsCount,obj.DecantationFee ,obj.TotalDecantationFee,obj.LoadsWithDecantationFee,obj.LoadsWithoutDecantationFee)
    rowsDataTF.push(row)
  })
  props.columnsDataHeaders.forEach(function(obj){
    let column =  { id: obj, label: obj, minWidth: 170 , align: 'center' }
    columnsDataForAreaWise.push(column)
  })
  const rowsTF    = rowsDataTF
  const columnsForAreaWise = columnsDataForAreaWise
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const camelCase = (str) =>  {
    return str.substring(0, 1).toUpperCase() + str.substring(1);
};

const filterColumns = (data) => {
    const columns = Object.keys(data[0]);
   const desiredOrder = [
      "tfName",
      "LoadsCount",
      "LoadsWithDecantationFee", 
      "DecantationFee",
      "TotalDecantationFee",
      "LoadsWithoutDecantationFee",
    ];
  
    const sortedColumns = columns.sort((a, b) => {
      const indexOfA = desiredOrder.indexOf(a);
      const indexOfB = desiredOrder.indexOf(b);
      return indexOfA - indexOfB;
    });
    return sortedColumns 
};

  return (
    <div className="dashboard_new_listing">
  
  
  <Paper className={classes.root}>
      <TableContainer className={classes.containerTF}>
<div style={{float:"right", marginRight: "15px"}}>
<ExcelFile filename="TFWiseLoads" element={<button style={{padding: "6px", color: "#3DAB35", border: "1px solid #3DAB35", cursor: "grabbing", borderRadius:"4px" }}>Export xlsx</button>}>
                <ExcelSheet data={rowsTF} name="TFWiseLoads">
                    {
                        filterColumns(rowsTF).map((col)=> {
                            return <ExcelColumn label={camelCase(col)} value={col}/>
                        })
                    }
                </ExcelSheet>
            </ExcelFile>
</div>

  

        <Table stickyHeader aria-label="sticky table">
        <TableHead>
            <TableRow>
            <TableCell align="center"style={{ minWidth: columnsForAreaWise.minWidth, fontWeight:'bolder', fontSize:"12px" }}>TF Name</TableCell>
            <TableCell align="center"style={{ minWidth: columnsForAreaWise.minWidth, fontWeight:'bolder', fontSize:"12px" }}>No. of Loads</TableCell>
            <TableCell align="center"style={{ minWidth: columnsForAreaWise.minWidth, fontWeight:'bolder', fontSize:"12px" }}>Loads With Decantation Fee (A)</TableCell>
            <TableCell align="center"style={{ minWidth: columnsForAreaWise.minWidth, fontWeight:'bolder', fontSize:"12px" }}>Decantation Fee (B)</TableCell>
            <TableCell align="center"style={{ minWidth: columnsForAreaWise.minWidth, fontWeight:'bolder', fontSize:"12px" }}>Total (A <b><CloseIcon style={{marginBottom:"-3px"}}/></b> B) </TableCell>
            <TableCell align="center"style={{ minWidth: columnsForAreaWise.minWidth, fontWeight:'bolder', fontSize:"12px" }}>Loads Without Decantation Fee </TableCell>


            </TableRow>
          </TableHead>
          <TableBody>
            {
              rowsTF.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row,rowIndex) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={rowIndex}>
                  {columnsForAreaWise.map((column,index) => {
                    let value = row[column.id];
                    return (
                      <TableCell key={index} align={column.align} style={{fontSize:"12px"}}>
                        {column.format && typeof value === 'number' ? column.format(value) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
     
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5,10, { label: '2', value: 2 }]}
        colSpan={3}
        count={rowsTF.length}
        component="div"
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: { 'aria-label': 'rows per page' },
          native: true,
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>

            
    </div>
  );
}
