import * as actionTypes from './actionsTypes';
import axios from '../../http-config';
import swal from 'sweetalert'
import { CodeSharp } from '@material-ui/icons';

export const fetchEmailManageSuccess = (response) => {
    return {
        type: actionTypes.FETCH_EMAILMANAGE_SUCCESS,
        users: response.data.users,
        id:response
      
    }
}

export const fetchEmailManageFail = (error) => {
    return {
        type: actionTypes.FETCH_EMAILMANAGE_FAIL,
        error:error
    }
}

export const fetchEmailManageStart = () => {
    return {
        type: actionTypes.FETCH_EMAILMANAGE_START
    }
}

export const fetchEmailManage = (payload) => {
    let token = payload.token;
    let search = payload.search ? payload.search : ''
    return dispatch => {
        dispatch(fetchEmailManageStart());

        axios({
            method: 'get',
            url: `/record_keeping_email?search=${search}`,
            headers: {'Authorization': `Bearer ${token}`}
        })
        .then((response) => {
            const fetchedEmailManageResponse = response.data;
            dispatch(fetchEmailManageSuccess(fetchedEmailManageResponse));
        }).catch(error => {
            dispatch(fetchEmailManageFail(error));
        })

    }
}

export const fetchEmailByUserId = (payload) => {
    let token = payload.token;
    return dispatch => {
        dispatch(fetchEmailManageStart());

        axios({
            method: 'get',
            url: `/record_keeping_email/${payload.id}`,
            headers: {'Authorization': `Bearer ${token}`}
        })
        .then((response) => {
            const fetchedEmailManageResponse = response.data;
            dispatch(fetchEmailManageSuccess(fetchedEmailManageResponse));
        }).catch(error => {
            dispatch(fetchEmailManageFail(error));
        })

    }
}


export const addEmailManage = (payload) => {
    let token = payload.token;
    let name= payload.name
    let email=payload.email
    
    return dispatch => {
        dispatch(fetchEmailManageStart());

        const payload2 = {
            email: email,
            name: name
        }
        let url = '/record_keeping_email';
        axios.post(url, payload2,{headers:{
            "Authorization":`Bearer ${token}`
        }})
        .then((response) => {
         dispatch(fetchEmailManage(payload));
        
        }).catch(err => {
           
        })
        
    }
}

export const editEmail = (payload,props) => {
    let token = payload.token;    
    return (dispatch) => {
        let url = `/record_keeping_email/${payload.id}`;
        dispatch(fetchEmailManageStart())
        axios.put(url, {email:payload.email,name:payload.name, tfIds:payload.tfIds}, { headers : { "Authorization":`Bearer ${token}`}})
        .then((response) => {
            dispatch(fetchEmailManage(payload))
            props.history.push('/email_manage')
            const fetchedResponse = response.data.success;
            fetchedResponse && swal({
                title: "Email Edited successfully!",
                icon: "success",
                buttons: {
                    confirm: 'Ok'
                }
              })  
        }, (err) => {
            swal({icon : "error" , title : err.response.data.error[0]["message"]})    
        })
        .catch((err)=> {
            dispatch(fetchEmailManageFail(err))
        })
    }
}

export const addEmail = (payload,props) => {
    let token = payload.token;    
    return (dispatch) => {
        let url = '/record_keeping_email';
        dispatch(fetchEmailManageStart())
        axios.post(url, {email:payload.email,name:payload.name, tfIds:payload.tfIds}, { headers : { "Authorization":`Bearer ${token}`}})
        .then((response) => {
            dispatch(fetchEmailManage(payload))
            props.history.push('/email_manage')
            const fetchedResponse = response.data.success;
            fetchedResponse && swal({
                title: "Email Added successfully!",
                icon: "success",
                buttons: {
                    confirm: 'Ok'
                }
              })  
        }, (err) => {
            swal({icon : "error" , title : err.response.data.error[0]["message"]})    
        })
        .catch((err)=> {
            dispatch(fetchEmailManageFail(err))
        })
    }
}

export const removeEmailManage=(payload)=>{
   let id=payload.id
    let token= payload.token
   
    return dispatch=>{
        dispatch(fetchEmailManageStart())
        const payload2 = {
            id: id
          
        }
        let url = '/record_keeping_email';
        axios.delete(url, { data: payload2, headers:{
            'Content-Type':'application/json',"Authorization":`Bearer ${token}`
        }})
        .then((response) => {
            
        dispatch(fetchEmailManage(payload));
        
        }).catch(err => {
           
        })

       
}
}
