import React, { Component } from 'react'
import { connect } from 'react-redux';
import {Link} from 'react-router-dom'
import LinearProgress, { LinearProgressProps } from '@material-ui/core/LinearProgress';
import * as actionsCreators from  '../../store/actions/index';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import axios from '../../http-config';
import swal from 'sweetalert';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import PropertyBulkUploadFile from '../../PropertyBulkUpload.xlsx'
import AreaBulkUploadFile from '../../AreaBulkUpload.xlsx'
import ReactPaginate from 'react-paginate';
import { Dialog, Typography } from '@material-ui/core';
// import ScheduleDialog from './ScheduleDialog'
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { Grid } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { ThreeSixty, TimerSharp } from '@material-ui/icons';
import {createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import * as User  from '../../config/constant';



function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress style={{color:'#3dab35'}} variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }
  const theme = createMuiTheme({
    palette: {
       secondary: {
           main: '#3dab35'
       }
    }
  })


class PropertyListing extends Component {
    state={ 
        anchorEl:null,
        open:false,
        id:'',
        limit: 10,
        openDialog:false,
        propertyId:'',
        anchorEl_bulk_property             : null,
        placement_property                : '',
        open_bulk_property                : false,
        anchorEl_bulk_area             : null,
        placement_area                : '',
        open_bulk_area                : false,
        propertyType : 'all',
        filterByType:"",
        isAll:false,
        filterValue : '',
        sortObj : {
            key: '',
            value: ''
        },
        page: 1,
        controls: {
            search: {
                value: '',
            }
        },
        deleteProperty:'',

        // Single property upload
        openPop                 : false,
        id                      : "",
        buildingName            : "",
        address                 : "",
        ownerName               : "",
        propType                : "",
        area                    : "",
        wardNo                  : "",
        zone                    : null,
        ulbName                 : "",
        containmentType         : "",
        buildingNameErr         : "",
        ownerNameErr            : "",
        propTypeErr             : "",
        areaErr                 : "",
        wardNoErr               : "",
        zoneErr                 : "",
        ulbNameErr              : "",
        containmentTypeErr      : "",
        propertyOptions         : [],
        containmentOptions      : [],
        ulbOptions              : [],
        areaOptions             : [],
        wardOptions             : [],
        onUlb                   : false,
        onUlbName               : '',
        isDisableProperty       : false,
        reason                  : '',
        reasonErr               : '',
        ZoneOptions             : [],

        editId                  : "",

        //edit data 
       pName:"", 
       pOwner:"", 
       pAddress:"", 
       pTypeId:"", 
       pArea:'', 
       pWard:"", 
       pZone:'',
        pUlb:"", 
        pContainmentTypeId:'', 
        pReason:"", 
        pStatus:'',  

        //Single Area Upload 
        areaId                  :"",
        areaName                :"",
        areaNameErr             :"",
        areaNameTm              :"",
        areaNameTmErr          : '' ,     
        wardNumber:""   ,
        progress:0,
        bulkUp: false,
        isZoneNotRequired:localStorage.getItem("isZoneNotRequired")
    }

    componentDidMount() {
        const UserIdSave = localStorage.getItem("UserIdSave")
        const roleName = localStorage.getItem("roleName")
        axios({
            method: 'get',
            url: '/load_template/master_data',
            headers: { 'Authorization': `Bearer ${this.props.token}` }
        })
            .then((response) => {
                if (response.data.data) {
                    this.setState({
                        propertyOptions: response.data.data['master-data'][1].data,
                        containmentOptions: response.data.data['master-data'][5].data,
                        ulbOptions : response.data.data['master-data'][3].data,
                    })
                }

            })        
            // for signle ulb 
            // axios({
            //     method: 'get',
            //     url: '/ulb/get-ulb',
            //     headers: { 'Authorization': `Bearer ${this.props.token}` }
            // }).then((res)=>{
            //     this.setState({ ulbOptions: res.data.data.ulbs })
            // }).catch(err=>{
            //     console.log(err,"error")
            // })




            // const ulbId = localStorage.getItem('ulbid')
            // //To get Area Options
        //     axios({
        //         method  : 'get',
        //         url     : `/area?ULBId=${ulbId}`,
        //         headers: {'Authorization': `Bearer ${this.props.token}`}
        //     })
        //     .then((response) => {
        //         this.setState({
        //              areaOptions : response.data.data.areas
        //     })
        // })
        let payload = {
            token: this.props.token,
            page:  this.state.page,
            limit: this.state.limit,
            propertyTypeId: this.state.filterValue,
            sortName:this.state.sortObj.key,
            sortValue :this.state.sortObj.value,
            searchQuery: this.state.controls.search.value
        }
        this.props.onFetchPropertyHeaders(payload);
        this.props.onFetchPropertyListing(payload)
        this.props.onPropertyType()
    }

    //Handle change for single property upload option
    handleChange = (event) =>{
        const UserIdSave = localStorage.getItem("UserIdSave")
        const roleName = localStorage.getItem("roleName")
    if(event.target.name=="zone"){
                let payload={
                    token:this.props.token,
                }
                axios({
                    method  : 'get',
                    url     :UserIdSave&&roleName === User.SANITARYINSPECTOR? `/user/SI_zone_ward_assign?ZoneId=${event.target.value}`: `/ward?zoneId=${event.target.value}`,
                    headers : {'Authorization': `Bearer ${payload.token}`}
                })
                .then((res)=>{
                    this.setState({wardOptions: UserIdSave&&roleName === User.SANITARYINSPECTOR?JSON.parse(res.data.data.zoneWardAssignData[0].siWardid) :res.data.data.wards})
                })
                .catch((err)=>{
                    console.log(err)
                }) 
            }
    
    if(event.target.name=="ulbName"){
        this.setState({[event.target.name]:event.target.value})
        this.state.ulbName=event.target.value
        let payload={
            token:this.props.token
        }
        const ulbId = localStorage.getItem('ulbid')
        if(this.state.isZoneNotRequired =="true"){
            let payload={
                token:this.props.token,
            }
            axios({
                method  : 'get',
                url     : `/ward?ulbId=${event.target.value}`,
                headers : {'Authorization': `Bearer ${payload.token}`}
            })
            .then((res)=>{
                this.setState({wardOptions: res.data.data.wards})
            })
            .catch((err)=>{
                console.log(err)
            })
        }
    
        else{
            axios({
                method  : 'get',
                url     :UserIdSave&&roleName === User.SANITARYINSPECTOR?`/user/SI_zone_ward_assign?userId=${UserIdSave}&ULBId=${this.state.ulbName}`: this.state.isZoneNotRequired =="false"? `/zone/getZones?ulbId=${event.target.value}`:`/zone/getZones`,
                headers: {'Authorization': `Bearer ${payload.token}`}
            })
            .then((response) => {
                this.setState({           
                    //  wardOptions : response.data.data.wards, 
                    ZoneOptions: UserIdSave&&roleName === User.SANITARYINSPECTOR?response.data.data.zoneWardAssignData: response.data.data.zones
            })
        })
        }
}

if(event.target.name =="wardNo"){
    this.setState({[event.target.name]:event.target.value})
    axios({
        method  : 'get',
        url     : `/area?wardId=${event.target.value}`,
        headers: {'Authorization': `Bearer ${this.props.token}`}
    })
    .then((response) => {
        this.setState({
             areaOptions : response.data.data.areas
    })
})
}
else{
  
    this.setState({[event.target.name]:event.target.value},() => {
        this.checkAreaValidity()
    })
}              
    }
    // Handle change Function For Area
    handleAreaChange = (event) =>{
        const UserIdSave = localStorage.getItem("UserIdSave")
        const roleName = localStorage.getItem("roleName")

        if(event.target.name=="zone"){
            let payload={
                token:this.props.token,
            }
            axios({
                method  : 'get',
                url     :UserIdSave&&roleName === User.SANITARYINSPECTOR? `/user/SI_zone_ward_assign?ZoneId=${event.target.value}`: event.target.value ==null?`/ward`: `/ward?zoneId=${event.target.value}`,
                headers : {'Authorization': `Bearer ${payload.token}`}
            })
            .then((res)=>{
                this.setState({wardOptions: UserIdSave&&roleName === User.SANITARYINSPECTOR?JSON.parse(res.data.data.zoneWardAssignData[0].siWardid) :res.data.data.wards})
            })
            .catch((err)=>{
                console.log(err)
            })
        }

        if(event.target.name=="ulbName"){
            this.setState({[event.target.name]:event.target.value})
            this.state.ulbName=event.target.value
            // const UserIdSave = localStorage.getItem("UserIdSave")
            let payload={
                token:this.props.token
            }
            const ulbId = localStorage.getItem('ulbid')
            axios({
                method  : 'get',
                url     :UserIdSave&&roleName === User.SANITARYINSPECTOR?`/user/SI_zone_ward_assign?userId=${UserIdSave}`:  `/zone/getZones`,
                headers: {'Authorization': `Bearer ${payload.token}`}
            })
            .then((response) => {
                this.setState({
                    //  wardOptions : response.data.data.wards, 
                    ZoneOptions: UserIdSave&&roleName === User.SANITARYINSPECTOR?response.data.data.zoneWardAssignData: response.data.data.zones
                })
        })

        axios({
            method  : 'get',
            url     : `/ward?ulbId=${event.target.value}`,
            headers: {'Authorization': `Bearer ${this.props.token}`}
        })
        .then((response) => {
            this.setState({
                 wardOptions : response.data.data.wards,                                                      
        })
    })
    }else{
      
        this.setState({[event.target.name]:event.target.value},() => {
            this.checkAreaValidity()
        })
    }       
    }
    handlePageClick = (e) => {
        const selectedPage = e.selected;
        this.setState({page: selectedPage + 1})
        let payload = {
            token: this.props.token,
            page:  selectedPage + 1,
            limit: this.state.limit,
            propertyTypeId: this.state.filterValue,
            sortName:this.state.sortObj.key,
            sortValue :this.state.sortObj.value,
            searchQuery: this.state.controls.search.value
        }
        this.props.onFetchPropertyListing(payload)
    };

    handleClickMoreVertIcon=(event,propertyId, deleteProperty,  pName, pAddress, pOwner, pTypeId,pContainmentTypeId,pUlb, pZone, pWard,  pArea,  pStatus ,  pReason, )=>{
        this.setState({open:!this.state.open,anchorEl:event.currentTarget,propertyId, deleteProperty , pName, pAddress, pOwner, pTypeId,pContainmentTypeId,pUlb, pZone, pWard,  pArea,  pStatus ,  pReason,   })
      }
    handleClickAwayEdit = () => {
    this.setState({open:false});
    };
    
    /*Property Bulk upload*/
    handleClickProperty = (newPlacement) => (event) => {
        this.setState({ anchorEl_bulk_property:event.currentTarget, placement_property:newPlacement, open_bulk_property:!this.state.open_bulk_property});
    };
    handleClickAwayBulkProperty = () => {
        this.setState({open_bulk_property: false});
    };
    onFileUploadProperty = () => { 
        let props= this.props
        const payload = {   
            token : this.props.token
        }
        const formData = new FormData(); 
        formData.append( 
            "PropertyBulkUploadFile", 
            this.state.selectedFile, 
            this.state.selectedFile.name 
        )
        this.props.onFetchPropertyBulkImport(payload,formData,props);
        this.setState({open_bulk_property:false})
    }

    onFileChangeProperty = (event) => {
        this.setState({ selectedFile: event.target.files[0],bulkUp: true},()=>{
            this.onFileUploadProperty()
        });   
    }
componentWillReceiveProps(nextProps){
    this.setState({progress:nextProps.progressPercentage})
}
    handleCloseProperty = (event) => {
        this.setState({open_bulk_property:false,})
    };
    
    /*Area Bulk Upload*/
    handleClickArea = (newPlacement) => (event) => {
        this.setState({ anchorEl_bulk_area:event.currentTarget, placement_area:newPlacement, open_bulk_area:!this.state.open_bulk_area});
    };
    handleClickAwayBulkArea = () => {
        this.setState({open_bulk_area: false});
    };
    onFileUploadArea = () => { 
        let props= this.props
        const payload = {
            token : this.props.token
        }
        const formData = new FormData(); 
        formData.append( 
            "AreaBulkUploadFile", 
            this.state.selectedFile, 
            this.state.selectedFile.name 
        )
        this.props.onFetchAreaBulkImport(payload,formData,props);
        this.setState({open_bulk_area:false})
    }

    onFileChangeArea = (event) => {

        this.setState({ selectedFile: event.target.files[0],bulkUp: true}, ()=>{
            this.onFileUploadArea()
        }); 
        
    }

    handleCloseArea = (event) => {
        this.setState({open_bulk_area:false})
    };
    
    openDialog = () => {
        this.setState({
            openDialog:true
        })
    }

    handleDialogClose = (value) => {
        this.setState({
            openDialog:value
        }) 
    }
    onClickGetFilterVals = (e, colObj) => { 
        this.setState({filterValue: e})
    let payload = {
        token: this.props.token,
        page:  this.state.page,
        limit: this.state.limit,
        propertyTypeId: e,
        sortName:this.state.sortObj.key,
        sortValue :this.state.sortObj.value,
        searchQuery: this.state.controls.search.value
    }
    this.props.onFetchPropertyListing(payload)
    }         
    handleSort = (e,sortBy) => {
        let sortObj
        if (this.state.sortObj.key === sortBy.accessor) {
            let value = (this.state.sortObj.value === 'DESC') ? 'ASC' : 'DESC';
            sortObj = {
                key : sortBy.accessor,
                value: value
            }
        } 
        else {
            sortObj = {
                key : sortBy.accessor,
                value: 'DESC'
         }    
         }
 
        this.setState({ sortObj: sortObj ,currentSortId:sortBy.accessor});
        let payload = {
            token: this.props.token,
            sortName:sortObj.key,
            sortValue : sortObj.value,
            page:  this.state.page,
            limit: this.state.limit,
            propertyTypeId: this.state.filterValue,
            searchQuery: this.state.controls.search.value
        }
   
        this.props.onFetchPropertyListing(payload) 
  
      }
      handlePropertySearch = (e) => {
        e.preventDefault()
        let searchVal = this.state.controls.search.value
        if (!!searchVal && searchVal.length >= 2) {
            let payload = {
                token: this.props.token,
                searchQuery:searchVal,
                limit:10,
                page:this.state.page,
                propertyTypeId: this.state.filterValue,
                sortName:this.state.sortObj.key,
                sortValue :this.state.sortObj.value,
              
            }
            this.props.onSearchProperty(payload, '', '') 
        } else {
         
            swal("Enter atleast 2 characters")
        }
    }
    inputChangedHadler = (event, controlName) => {
        const updatedControls = {
            ...this.state.controls,
            [controlName]: {
                ...this.state.controls[controlName],
                value: event.target.value,
            }
        }
        this.setState({ controls: updatedControls });
        if( updatedControls.search.value === ''){
            let payload = {
                token: this.props.token,
                searchQuery:'',
                limit:this.state.limit,
                page:this.state.page,
                propertyTypeId: this.state.filterValue,
                sortName:this.state.sortObj.key,
                sortValue :this.state.sortObj.value,
            }
            this.props.onSearchProperty(payload, '', '') 
        } 
    }

    handleExport = () => {
        let payload = {
            token: this.props.token,
      
        }
        this.props.exportProperty(payload)
    }
    handleDelete =(id)=>{
        let payload = {
            propertyId: id,
            token: this.props.token,
            page:  this.state.page,
            limit: this.state.limit,
            propertyTypeId: this.state.filterValue,
            sortName:this.state.sortObj.key,
            sortValue :this.state.sortObj.value,
            searchQuery: this.state.controls.search.value
        }
        this.props.onDeleteProperty(payload)

    } 

    // Popup for single upload property

    openSignlePropertyPop = () => {
        this.setState({
            openPop                 : true,
            editId                  : "",
            buildingName            : "",
            address                 : "",
            ownerName               : "",
            propType                : "",
            area                    : "",
            wardNo                  : "",
            zone                    : null,
            ulbName                 : "",
            containmentType         : "",
            buildingNameErr         : "",
            ownerNameErr            : "",
            propTypeErr             : "",
            areaErr                 : "",
            wardNoErr               : "",
            zoneErr                 : "",
            ulbNameErr              : "",
            containmentTypeErr      : "",


        })
    }

    //For closing the single Upload property popup
    handleClosePop = () => {
        this.setState({
            openPop                 : false,
            editId                  : "",
            buildingName            : "",
            address                 : "",
            ownerName               : "",
            propType                : "",
            area                    : "",
            wardNo                  : "",
            zone                    : null,
            ulbName                 : "",
            containmentType         : "",
            buildingNameErr         : "",
            ownerNameErr            : "",
            propTypeErr             : "",
            areaErr                 : "",
            wardNoErr               : "",
            zoneErr                 : "",
            ulbNameErr              : "",
            containmentTypeErr      : "",

        })
    }

    //Pop up for Single Area Ulpoad 
    OpenSingleAreaPop = () =>{
        this.setState({
            openAreaPop             :true,
            editId                  :"",
            areaName                :"",
            areaNameErr             :"",
            areaNameTm              :"",
            areaNameTmErr           : '' ,
            ulbName                 : "",
            wardNo                  : "",
            zone                    : null,
            zoneErr                 : "",
            ulbNameErr              : "",
        })
       

    }
    
    //To close Single area Upload Pop up
    handleAreaClosePop = ()=> {
        this.setState({
            openAreaPop             :false,
            editId                  :"",
            areaName                :"",
            areaNameErr             :"",
            areaNameTm              :"",
            areaNameTmErr           : '',
             wardNo                 : "",
            ulbName                 : "",
            zone                    : null,
            zoneErr                 : "",
            ulbNameErr              : "",
            wardNumber:""
        })

    }


//conditions to check if there is any error 
checkValidity = () => {
    let isError = false
    if (this.state.propType =="" ||this.state.propType == null ||this.state.propType == "null" || this.state.propType == undefined) {
        isError = true
        this.setState({ propTypeErr: `*This field is required` })
    }
    else {
        // isError=false
        this.setState({ propTypeErr: '' })
    }
  
    if (this.state.buildingName == ""||this.state.buildingName == null || this.state.buildingName == "null" || this.state.buildingName == undefined ) {
        isError = true
        this.setState({ buildingNameErr: `*This field is required` })
    }
    else {
        // isError=false
        this.setState({ buildingNameErr: '' })
    }
    if (this.state.ulbName == "" ||this.state.ulbName == null ||this.state.ulbName== "null"||this.state.ulbName == undefined) {
        isError = true
        this.setState({ ulbNameErr: `*This field is required` })
    }
    else {
        // isError=false
        this.setState({ ulbNameErr: '' })
    }
    return isError
}

//To Check Area Error 
checkAreaValidity = () => {
    let isError = false
    if(this.state.areaName == "" || this.state.areaName == null  || this.state.areaName == "null" || this.state.areaName ==undefined){
        isError =true
        this.setState({areaNameErr : `*This field is required`})
    }
    else{
        // isError=false
        this.setState({ areaNameErr : ''})
    }
    if (this.state.ulbName == "" ||this.state.ulbName == null ||this.state.ulbName== "null"||this.state.ulbName == undefined) {
        isError = true
        this.setState({ ulbNameErr: `*This field is required` })
    }
    else {
        // isError=false
        this.setState({ ulbNameErr: '' })
    }

    return isError

}

// Function to handle Status for single Property Upload 
handleStatus= (e) => {
    this.setState ({
        isDisableProperty: e.target.value
    })
  }
  //Function to handle status for Single Area upload 
  handleAreaStatus= (e) => {
    this.setState ({
        isDisableProperty: e.target.value
    })
  }

  //handleSubmit Function for uploading single property
  handleSubmit = (e) =>{
    let props=this.props
  const err = this.checkValidity()
  let payload = {
    token: this.props.token,
    page:  this.state.page,
    limit: this.state.limit,
    propertyTypeId: this.state.filterValue,
    sortName:this.state.sortObj.key,
    sortValue :this.state.sortObj.value,
    searchQuery: this.state.controls.search.value
}
  if(!err){

    if(this.state.editId != ""){
    const formdata= {
        token                 : this.props.token,
        id                    : this.state.propertyId,
        name                  : this.state.buildingName,
        address               :this.state.address,
        ownerName             : this.state.ownerName,
        propertyTypeId        : this.state.propType,
        containmentTypeId     : this.state.containmentType,
        ulb                   : this.state.ulbName,
        zone                  : this.state.zone,
        ward                  : this.state.wardNo,
        area                  : this.state.area,
        lastDesludgeDate      : this.state.lastDesludgeDate,
        noLoadsPerProperty    : this.state.noLoadsPerProperty,
        disableProperty       : this.state.isDisableProperty,
        propertyRemarks       : this.state.isDisableProperty === true ? this.state.reason : null

    }

    this.props.onEditPropertyData(formdata, props , payload)
    this.handleClosePop()
    }
    else{
    const formdata = new FormData()
    formdata.append("token" ,this.props.token)
    formdata.append("Name" ,this.state.buildingName)
    formdata.append("Address" ,this.state.address)
    formdata.append("OwnerName" , this.state.ownerName)
    formdata.append("PropertyTypeId", this.state.propType)
    formdata.append("ContainmentTypeId" , this.state.containmentType)
    formdata.append("ZoneId" , this.state.zone)
    formdata.append("WardId" , this.state.wardNo)
    formdata.append("ULBId", this.state.ulbName)
    formdata.append("AreaId", this.state.area)
    formdata.append("Status", this.state.isDisableProperty)


    this.props.OnAddPropertySingle(formdata , props, payload)
    this.handleClosePop()    
    }
    }
    }

    handleopeneditProperty = (pId,pName, pAddress, pOwner, pTypeId,pContainmentTypeId,pUlb, pZone, pWard,  pArea,  pStatus ,  pReason,    ) => {
    const UserIdSave = localStorage.getItem("UserIdSave")
    const roleName = localStorage.getItem('roleName')
    if(pUlb !=""){
      let payload={
        token:this.props.token
    }
    axios({
        method  : 'get',
        url     :UserIdSave&&roleName === User.SANITARYINSPECTOR?`/user/SI_zone_ward_assign?userId=${UserIdSave}`:  `/zone/getZones`,
        headers: {'Authorization': `Bearer ${payload.token}`}
    })
    .then((response) => {
        this.setState({
            //  wardOptions : response.data.data.wards, 
            ZoneOptions: UserIdSave&&roleName === User.SANITARYINSPECTOR?response.data.data.zoneWardAssignData: response.data.data.zones
        })
})
   }
   if(pZone !=""){
    let payload={
        token:this.props.token,
    }
    axios({
        method  : 'get',
        url     :UserIdSave&&roleName === User.SANITARYINSPECTOR? `/user/SI_zone_ward_assign?ZoneId=${pZone}`:pZone == null?`/ward?ulbId=${pUlb}`: `/ward?zoneId=${pZone}`,
        headers : {'Authorization': `Bearer ${payload.token}`}
    })
    .then((res)=>{
        this.setState({wardOptions: UserIdSave&&roleName === User.SANITARYINSPECTOR?JSON.parse(res.data.data.zoneWardAssignData[0].siWardid) :res.data.data.wards})
    })
    .catch((err)=>{
        console.log(err)
    })
   }

if(this.state.isZoneNotRequired == "false" &&pZone == null){
    this.setState({zoneErr:'*Zone was not required*'})
}

if(pArea !==""){
    axios({
        method  : 'get',
        url     : `/area?wardId=${pWard}`,
        headers: {'Authorization': `Bearer ${this.props.token}`}
    })
    .then((response) => {
        this.setState({
             areaOptions : response.data.data.areas
    })
})
}
    this.setState({
        openPop: true,
        editId: pId,
        buildingName: pName,
        address:pAddress,
        ownerName: pOwner,
        propType: pTypeId,
        containmentType: pContainmentTypeId,
        ulbName: pUlb,
        zone: pZone,
        wardNo: pWard,
        area: pArea,      
        isDisableProperty:pStatus == "In Active"? true : pStatus === "Active"? false:false,
        reason: pReason,
    })
}

// Function To handle Single Area Submit 
handleAreaSubmit = (e) =>{
    let props=this.props
    
  const err = this.checkAreaValidity()
  if(!err){

    const formdata = {
        token:this.props.token,
        name:this.state.areaName,
        name_tn:this.state.areaNameTm,
        // ULBId: this.state.ulbName,
        IsDisableStatus         : this.state.isDisableProperty,
        ulbId                   : this.state.ulbName,
        zoneId                  : this.state.zone,
        wardId                  : this.state.wardNumber,
        

    }
    this.props.OnAddAreaSingle(formdata)
    this.handleAreaClosePop() 
}  
}
onClickRemoveFilter = ()=>{
    let payload = {
        token: this.props.token,
        page:  1,
        limit: this.state.limit,
        sortName:"",
        sortValue :"",
        propertyTypeId: "",
        searchQuery: ""
    }
    this.setState({
        searchQuery:'', 
        controls:{
            search: {
                value: '',
                     }
         },
        filterValue:'', 
        sortObj:{
            key: '',
            value: ''
    } })
    this.props.onFetchPropertyListing(payload);
}

    render() {
        //removed current day loads and added last desludge day loads
        let updatedHeaders = this.props.headers
        let filterValue
        const propertTypeLists = this.props.Type
        const selectData = !!propertTypeLists&&propertTypeLists.map((item,i)=>{
              return  <option key={i} value={item.Id}>
                    {item.Name}
                </option>
                
        })
        const headers =  !!updatedHeaders&&updatedHeaders.map(header => {
            if(this.state.isZoneNotRequired == "true" && header.accessor =="zone" ){
                header.accessor = ""
                header.filter= "false"
                header.header= "Zone"
                header.sortable= "false"
            }
           
            if(header.accessor=='actionButton'){
                header.header = 'Action'
            } if(header.accessor == "propertyType"){
                header.header = 'Property Type'
                header.filter = true
            }
            if(header.header === 'Owner' ||  header.header === 'Containment Type' ){
                header.header = ''
            }
            let filterType
            if(header.filter == true){
                filterType = 
                <select 
                style={{
                    width: '16px',
                    borderColor:"white",
                    backgroundColor: 'LightGray',
                    outline:"none",
                    display: "flex",
                    alignItems: 'right',   
                  }}
                value={filterValue}
                onChange={(e) => {
                    this.onClickGetFilterVals(e.target.value, header)
                }}
                >
                    <option value="">All</option>
                    {selectData}
                </select>
            }
          {
                return <TableCell key={header.header} className={"white-space"}>
                             <div style={{
                                    display: 'flex',
                                    alignItems: 'start',
                                    whiteSpace: 'break-spaces',
                                    height:'3em'
                                    }}>
                            <div onClick={(e) =>  this.handleSort(e,header)}>
                        {header.header == "Property Type" || header.header == 'Action' || header.header === '' ||header.header === 'Current Day Loads' || header.header === 'Updated Date' || header.header === 'Last Desludge Date'|| header.header === ''?'':    <div>{
                             this.state.currentSortId===header.accessor? this.state.sortObj.value ==='ASC'?(<ArrowDropDownIcon fontSize="small"/>):(<ArrowDropUpIcon fontSize="small"/>) :(<ArrowDropDownIcon fontSize="small"/>)
                           }
                           </div> }   
                        </div>
                                        
                                {header.header}
                                {filterType}
                            </div>
                        </TableCell>
            }
        }
        );
        
        const properties= !!this.props.propertyListing&&this.props.propertyListing.map((property, j)=>{
        // const id=property["id"]
        const propertyId=property["propertyId"]
        const deleteProperty =property['isDeleteAbleProperty']   
        const pName = property.name
        const pAddress = property.address
        const pOwner = property.ownerName 
        const pTypeId = property.propertyTypeId
        const pContainmentTypeId = property.containmentTypeId
        const pUlb = property.ulbId
        const pZone = property.zoneId
        const pWard = property.wardId
        const pArea = property.areaId
        const pStatus = property.isDisableProperty
        const pReason = property.reason


            return <TableRow style={{whiteSpace: 'normal',
            wordWrap: 'break-word'}} key={j}>{
                !!updatedHeaders&&updatedHeaders.map((header, i)=>{
                       if(header.accessor=='actionButton'){
                        return  <TableCell  key={i}>
                                            <IconButton
                                            aria-label="more"
                                            aria-controls="long-menu"
                                            aria-haspopup="true"
                                            onClick={(e)=>this.handleClickMoreVertIcon(e,propertyId, deleteProperty, pName, pAddress, pOwner, pTypeId,pContainmentTypeId,pUlb, pZone, pWard,  pArea,  pStatus ,  pReason,  )}
                                            >
                                            <MoreVertIcon fontSize='small'/>
                                            </IconButton>
                                                <Popper
                                                open={this.state.open} anchorEl={this.state.anchorEl}>
                                                 <ClickAwayListener onClickAway={this.handleClickAwayEdit}>
                                                <Paper style={{boxShadow:"none", border:"1px solid #3dab35"}}>
                                                    <MenuList>
                                                        <MenuItem className='email_link' style={{ color: '#3dab35' }} onClick={() => this.handleopeneditProperty(
                                                        this.state.propertyId,
                                                        this.state.pName,
                                                        this.state.pAddress,
                                                        this.state.pOwner,
                                                        this.state.pTypeId,
                                                        this.state.pContainmentTypeId,
                                                        this.state.pUlb,
                                                        this.state.pZone,
                                                        this.state.pWard,
                                                        this.state.pArea,
                                                        this.state.pStatus,                                            
                                                        this.state.pReason,
                                                       
                                                        )}>     Edit
                                                        
                                                        </MenuItem>
                                                    </MenuList>
                                                        <MenuList>
                                                         <MenuItem  className='email_link' style={{color:'green'}} onClick = { () => {
                                                        swal({ title   : "Are you sure?",
                                                            text    : "Once deleted, you will not be able to recover this data!",
                                                            icon    : "warning",
                                                            buttons : {
                                                            cancel  : 'No',
                                                            confirm : 'Yes'}
                                                        })
                                                        .then((willDelete) => {
                                                            if (willDelete) {
                                                                this.handleDelete(this.state.propertyId) 
                                                            } 
                                                        });
                                                    }}>Delete</MenuItem>
                                                    </MenuList>
                                                                                                 
                                                </Paper>
                                                </ClickAwayListener>
                                            </Popper>
                                </TableCell>
                       }
                       //Created Link on Property Name Header to go to Loads section
                       else if(header.accessor=='name'){
                           return <TableCell style={{
                            whiteSpace: "normal",
                            // wordWrap: "break-word",
                       
                          }} >{property[header.accessor] == null || property[header.accessor] == 'null'  ? ' ' :<Link to={`/property_loads/${propertyId}`} style={{color:"black"}}>{property[header.accessor]}</Link>}</TableCell>
                          
                       }                       
                       else if(header.accessor=='updatedAt'){
                           return <TableCell style={{
                            whiteSpace: "normal",
                            wordWrap: "break-word"
                          }} key={i} >{ moment(property[header.accessor]).format('DD-MMM-YYYY hh:mm a')}</TableCell>
                       }

                       else if(header.accessor=='lastDesludgeDate'){
                        return <TableCell  style={{
                         whiteSpace: "normal",
                         wordWrap: "break-word"
                       }} key={i}>{ property[header.accessor] && moment(property[header.accessor]).format('DD-MMM-YYYY')}</TableCell>
                    }
                    else if(header.header == 'Scheduled AT'){
                        return <TableCell  style={{
                            whiteSpace: "normal",
                            wordWrap: "break-word"
                          }} key={i}>{ property.scheduleId ? property[header.accessor] && moment(property[header.accessor]).format('DD-MMM-YYYY') : 'NA'}</TableCell>
                    }
                     
                       else{
                           return  <TableCell style={{
                            whiteSpace: "normal",
                            wordWrap: "break-word"
                          }} key={i} className={"white-space"}>{property[header.accessor] == null || property[header.accessor] == 'null'  ? ' ' : property[header.accessor]}</TableCell>
                       }
                }) 
            }
            </TableRow>

    })

        return (
            <div>
                <div className="dashboard_right_content" style={{marginTop:'-4%'}}>
                    <img className="dashboard_right_content_top_icon" src="images/Rectangle.svg" />
                        <div className="dashboard_right_content_top">
                            <h5>Property Management Module</h5>
                            <div className="dashboard_right_content_top_options">
                            <div className="dashboard_right_content_top_search">
                                <Paper component="form">
                                    <IconButton aria-label="menu">
                                        <img src="images/search_icon.svg" />
                                    </IconButton>
                                    <InputBase onChange={(event) => this.inputChangedHadler(event, 'search')} placeholder="Property Name" />
                                    <Button onClick={this.handlePropertySearch} variant="contained" color="primary" type="submit">Search</Button>
                                </Paper>
                            </div>
                            <div>
                            <Button style={{width:'100%', marginLeft:'10px' }} onClick={this.handleExport}>Export</Button>
                            </div>
                                <div >
                                <Button onClick={this.onClickRemoveFilter}>Reset</Button> 
                                </div>
                                <Button className="email_link"
                                ref={this.state.anchorRef}
                                aria-controls={this.state.open ? 'menu-list-grow' : undefined}
                                aria-haspopup="true"
                                onClick={this.handleClickProperty('bottom')}
                                style={{height:'35px', marginLeft:'20px'}}>
                                Property
                                {
                                    this.state.open ?   <ArrowDropUpIcon /> : <ArrowDropDownIcon/> 
                                }
                                
                            </Button>
                               <Popper style={{zIndex:"10"}} open={this.state.open_bulk_property} anchorEl={this.state.anchorEl_bulk_property} placement={this.state.placement_property} transition>
                            {({ TransitionProps }) => (
                                    <ClickAwayListener onClickAway={this.handleClickAwayBulkProperty}>
                                <Paper style={{zIndex: 1, border:"1px solid #3dab35"}}>
                                    <MenuList>
                                        {/* Added Single Property Uplaod Dropdown */}
                                    <MenuItem style={{ color: '#3dab35', fontSize: 'small', align: 'left', fontFamily: "'Open Sans', sans-serif" }} onClick={() => this.openSignlePropertyPop()} >
                                            Single Property
                                    </MenuItem>     
                                    <MenuItem onChange={this.onFileChangeProperty}> 
                                        <label style={{ color:'#3dab35',fontSize:'small',align:'left', fontFamily: "'Open Sans', sans-serif"}}> 
                                            <input type="file" style={{display:'none',align:'left'}} /> 
                                            Bulk Upload Property
                                        </label>                         
                                        </MenuItem>
                                    <a href={PropertyBulkUploadFile} style={{textDecoration:'none', 
                                                                        color:'#3dab35', 
                                                                        fontSize:'small', 
                                                                        fontFamily: "'Open Sans', sans-serif"}} download> 
                                        <MenuItem onClick={this.handleCloseProperty}>Download Sample</MenuItem>     
                                    </a>
                                    </MenuList>
                                </Paper>
                                </ClickAwayListener>
                            )}
                            </Popper>  

                            <Button className="email_link"
                                ref={this.state.anchorRef}
                                aria-controls={this.state.open ? 'menu-list-grow' : undefined}
                                aria-haspopup="true"
                                onClick={this.handleClickArea('bottom')}
                                style={{height:'35px'}}>
                                Area
                                {
                                    this.state.open ?   <ArrowDropUpIcon /> : <ArrowDropDownIcon/> 
                                }
                                
                            </Button>
                            <Popper style={{zIndex:"10"}} open={this.state.open_bulk_area} anchorEl={this.state.anchorEl_bulk_area} placement={this.state.placement_area} transition>
                            {({ TransitionProps }) => (
                                    <ClickAwayListener onClickAway={this.handleClickAwayBulkArea}>
                                <Paper style={{zIndex:1, border:"1px solid #3dab35"}}>
                                    <MenuList>
                                        {/* Added Single Area pload dropdown */}
                                    <MenuItem style={{ color: '#3dab35', fontSize: 'small', align: 'left', fontFamily: "'Open Sans', sans-serif" }}onClick={() => this.OpenSingleAreaPop()} >
                                            Single Area
                                   </MenuItem>  
                                    <MenuItem onChange={this.onFileChangeArea}> 
                                        <label style={{ color:'#3dab35',fontSize:'small',align:'left', fontFamily: "'Open Sans', sans-serif"}}> 
                                            <input type="file" style={{display:'none',align:'left'}} /> 
                                            Bulk Upload Area
                                        </label> 
                                        </MenuItem>                           
                                    <a href={AreaBulkUploadFile} style={{textDecoration:'none', 
                                                                        color:'#3dab35', 
                                                                        fontSize:'small', 
                                                                        fontFamily: "'Open Sans', sans-serif"}} download> 
                                        <MenuItem onClick={this.handleCloseArea}>Download Sample</MenuItem>     
                                    </a>
                                    {/* Added area Listing dropdown  */}
                                    <MenuItem style={{ color: '#3dab35', fontSize: 'small', align: 'left', fontFamily: "'Open Sans', sans-serif" }} >
                                    <Link to = "/area-management" className="email_link">Area Listing </Link> 
                                    </MenuItem>   
                                    </MenuList>
                                </Paper>
                                </ClickAwayListener>
                            )}
                            </Popper>  
                        
                            </div>
                        </div>

                
                        
            {(this.props.loadingbulk == true || this.state.bulkUp == false ) ? "":          
                     <div>
                    <MuiThemeProvider theme={theme}>
                         <LinearProgressWithLabel color="secondary" value={this.props.progressPercentage} /> 
                    </MuiThemeProvider>
                </div> 
                }
    
                    </div>
                    <div> 
                    <div className="operator_listing scrollScheduleProperty" >
                        <TableContainer component={Paper} style={{paddingLeft:"15px",paddingRight:"15px"}}>
                             <Table stickyHeader aria-label="sticky table" style={{ tableLayout: 'fixed' }}>
                             <colgroup>
                                 <col style={{width:'5%'}}/> 
                                 <col style={{width:'10%'}}/>
                                 <col style={{width:'10%'}}/> 
                                 <col style={{width:'10%'}}/>  
                                 <col style={{width:'9%'}}/> 
                                 <col style={{width:'7%'}}/>  
                                 <col style={{width:'9%'}}/> 
                                 <col style={{width:'8%'}}/>  
                                 <col style={{width:'9%'}}/> 
                                 <col style={{width:'8%'}}/>  
                                 <col style={{width:'9%'}}/> 
                                 <col style={{width:'8%'}}/>       
+                                <col style={{width:'6%',paddingLeft:'0%',paddingRight:'0%'}}/>  
+                            </colgroup>
                                <TableHead >
                                    <TableRow >
                                        {headers}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                        {properties}

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    </div>
                    {
                        this.props.totalCount &&
                            <ReactPaginate
                            previousLabel={"Prev"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount = {this.props.totalCount/this.state.limit}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            style={{borderLeftWidth:'0px'}}
                            onPageChange={this.handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}/>
                      }
                    {/* <ScheduleDialog open={this.state.openDialog} handleClose = {this.handleDialogClose} propertyId = {this.state.propertyId}></ScheduleDialog> */}
                   {/* Created Form For Single Property Upload And Single Area Upload */}
                    <div className="setFreq">
                    <Dialog
                        maxWidth={'md'}
                        fullWidth={true}
                        // open={true}
                        open={this.state.openPop}
                        aria-labelledby="responsive-dialog-title">
                        <DialogTitle className="text-center border-none " style={{ color: '#3dab35' }}><span style={{ fontSize: '18px' }}>{this.state.editId != "" ? "Edit property": "Add New Property" } </span></DialogTitle>
                        <DialogContent >
                            <div className="create_ulb_content_outer">
                                <div className="create_ulb_content create_ulb_contentPop">
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <FormControl>
                                                <label className="required" htmlFor="ownerName" align="left">Property Name</label>
                                                <TextField id="ownerName"
                                                    variant="outlined"
                                                    value={this.state.buildingName}
                                                    onChange={this.handleChange}
                                                    name="buildingName"
                                                    placeholder= "Enter Property Name" />
                                                <div style={{ fontSize: 12, color: "red" }}>{this.state.buildingNameErr}</div>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <FormControl>
                                                <label className="not-required" htmlFor="address" align="left">Address</label>
                                                <TextField id="address"
                                                    variant="outlined"
                                                    value={this.state.address}
                                                    onChange={this.handleChange}
                                                    name="address"
                                                    placeholder= "Enter Address" />
                                                {/* <div style={{ fontSize: 12, color: "red" }}>{this.state.buildingNameErr}</div> */}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl>
                                                    <label className="not-required" htmlFor="ownerName" align="left">Owner Name</label>
                                                    <TextField id="ownerName" 
                                                                variant="outlined" 
                                                                value={this.state.ownerName} 
                                                                onChange={this.handleChange} 
                                                                name="ownerName"   
                                                                placeholder="Enter Owner Name"/>
                                                    {/* <div style={{fontSize:12,color:"red"}}>{this.state.ownerNameErr}</div>  */}
                                                </FormControl>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <FormControl>
                                                <label className="required" htmlFor="propType" align="left">Property Type</label>
                                                <Select value={this.state.propType} onChange={this.handleChange} name="propType" placeholder="Select Property Type">
                                                    {this.state.propertyOptions && this.state.propertyOptions.map((option) => (
                                                        <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                                                    ))}
                                                </Select>
                                                <div style={{ fontSize: 12, color: "red" }}>{this.state.propTypeErr}</div>
                                            </FormControl>
                                        </Grid>
                                                                          
                                        <Grid item xs={6}>
                                            <FormControl>
                                                <label className="not-required" htmlFor="containmentType" align="left">Containment type</label>
                                                <Select value={this.state.containmentType} onChange={this.handleChange} name="containmentType">
                                                    {this.state.containmentOptions && this.state.containmentOptions.map((option) => (
                                                        <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                                                    ))}
                                                </Select>
                                                {/* <div style={{ fontSize: 12, color: "red" }}>{this.state.containmentTypeErr}</div> */}
                                            </FormControl>
                                        </Grid>


                                        <Grid item xs={6}>
                                            <FormControl>
                                                <label className="required" htmlFor="ulbName" align="left">ULB / Cluster Name</label>
                                                <Select value={this.state.ulbName} onChange={this.handleChange} name="ulbName">
                                                    {
                                                        this.state.ulbOptions && this.state.ulbOptions.map((option) =>
                                                            <MenuItem  key={option.id} value={option.id}>{option.name}</MenuItem>
                                                        )}
                                                </Select>

                                                <div style={{ fontSize: 12, color: "red" }}>{this.state.ulbNameErr}</div>
                                            </FormControl>
                                        </Grid>
                                        { this.state.isZoneNotRequired == "false"  ? 
                                        <Grid item xs={6}>           
                                            <FormControl>
                                                <label htmlFor="zone" align="left">Zone Name</label>
                                                <Select value={this.state.zone} onChange={this.handleChange} name="zone">
                                                {this.state.ZoneOptions&&this.state.ZoneOptions.map((option) =>{    
                                                            return <MenuItem  key={option.Id} value={option.Id}>{option.Name}</MenuItem>
                                                        })}  
                                                    </Select> 
                                                    {/* <div style={{ fontSize: 12, color: "red" }}>{this.state.zoneErr}</div> */}

                                            </FormControl>
                                        </Grid>:""
                                         }
                                    
                                        <Grid item xs={6}>
                                            <FormControl>
                                                <label htmlFor="wardNo" align="left">Ward</label>
                                                <Select value={this.state.wardNo} onChange={this.handleChange} name="wardNo">
                                                            {!!this.state.wardOptions.length !=0&&this.state.wardOptions.map((option) =>{ 
                                                                if(option.wardId == null){
                                                                    swal({
                                                                        title:'ward not avaliable!!',
                                                                        icon:'warning'
                                                                    })
                                                                }
                                                                return(
                                                                <MenuItem  key={option.id} value={option.wardId}>{option.wardNumber}</MenuItem>
                                                            )})}
                                                        </Select> 
                                            </FormControl>
                                        </Grid>
                                                        
                                                        
                                        <Grid item xs={6}>
                                                <FormControl>
                                                    <label htmlFor="area" align="left">Area</label>
                                                    <Select value={this.state.area} onChange={this.handleChange} name="area">
                                                                {this.state.areaOptions.map((option) => (
                                                                    <MenuItem  key={option.Id} value={option.Id}>{option.Name}</MenuItem>
                                                                ))}
                                                            </Select> 
                                                </FormControl>
                                         </Grid>
                                        <Grid item xs={6}>
                            <FormControl>
                                <label htmlFor="isDisableProperty" align="left">Status</label>
                                <Select value={this.state.isDisableProperty} onChange={this.handleStatus} name="isDisableProperty">
                                     <MenuItem value={false}>{'Active'}</MenuItem>
                                    <MenuItem value={true}>{'In Active' }</MenuItem>
                                        </Select> 
                            </FormControl>
                </Grid>
                {this.state.isDisableProperty === true && this.state.editId !== "" ? 
                   <Grid item xs={6}>
                   <FormControl>
                           <label className="not-required" htmlFor="reason" align="left">Reason</label>
                           <TextField id="reason" 
                                       variant="outlined" 
                                       value={this.state.reason} 
                                       onChange={this.handleChange} 
                                       name="reason"   
                                       placeholder="Enter your reason"/>
                           {/* <div style={{fontSize:12,color:"red"}}>{this.state.ownerNameErr}</div>  */}
                       </FormControl>
               </Grid> : ""
            }
                                      


                                    </Grid>

                                </div>
                            </div>


                        </DialogContent>
                        <DialogActions>
                            {this.state.openPop === true ?
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <div className="create_ulb_buttons" style={{ marginBottom: '10px' }}>
                                            <Button className="login_submit" variant="outlined" color="primary" onClick={this.handleClosePop}>Cancel</Button>
                                            <Button className="login_submit" variant="contained" color="primary" onClick={() => this.handleSubmit()}>{this.state.editId != "" ? `Save Changes` : `Save`}</Button>
                                        </div>
                                    </Grid>
                                </Grid> : ""}
                        </DialogActions>
                    </Dialog>


                    
                </div>
                {/* <ScheduleDialog open={this.state.openDialog} handleClose = {this.handleDialogClose} propertyId = {this.state.propertyId}></ScheduleDialog> */}
                    <div className="setFreq">
                    <Dialog
                        open={this.state.openAreaPop}
                        maxWidth={'md'}
                        fullWidth={true}
                        // open={true}
                    
                        aria-labelledby="responsive-dialog-title">
                        <DialogTitle className="text-center border-none " style={{ color: '#3dab35' }}><span style={{ fontSize: '18px' }}>{this.state.editId != "" ? "Edit Area": "Add New Area" } </span></DialogTitle>
                        <DialogContent >
                            <div className="create_ulb_content_outer">
                                <div className="create_ulb_content create_ulb_contentPop">
                                    <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <FormControl>
                                            <label className="required" htmlFor="areaName">Area Name</label>
                                            <TextField id="areaName" 
                                                    variant="outlined" 
                                                    placeholder="Enter Area Name" 
                                                    name="areaName" 
                                                    value={this.state.areaName} 
                                                    onChange={this.handleAreaChange}/>
                                            <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.areaNameErr}</div>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl>
                                            <label htmlFor="areaNameTm">Area Name(Tamil)</label>
                                            <TextField id="areaNameTm" 
                                                variant="outlined" 
                                                placeholder="மண்டல பெயரை உள்ளிடவும்" 
                                                name="areaNameTm" 
                                                value={this.state.areaNameTm} 
                                                onChange={this.handleAreaChange}/>
                                            <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.emailErr}</div>
                                        </FormControl>
                                    </Grid>
                               
                                        <Grid item xs={6}>
                                            <FormControl>
                                                <label className="required" htmlFor="ulbName" align="left">ULB / Cluster Name</label>
                                                <Select value={this.state.ulbName} onChange={this.handleAreaChange} name="ulbName">
                                                    {
                                                        this.state.ulbOptions && this.state.ulbOptions.map((option) =>
                                                            <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                                                        )}
                                                </Select>

                                                <div style={{ fontSize: 12, color: "red" }}>{this.state.ulbNameErr}</div>
                                            </FormControl>
                                        </Grid>
                                        { this.state.isZoneNotRequired == "false"  ? 
                                        <Grid item xs={6}>
                                          <FormControl>
                                            <label htmlFor="zone" align="left">Zone Name</label>
                                            <Select value={this.state.zone} onChange={this.handleAreaChange} name="zone">
                                            {this.state.ZoneOptions.map((option) =>{ 
                                                return <MenuItem value={option.Id}>{option.Name}</MenuItem>
                                            })}       
                                                    </Select> 
                                        </FormControl>
                                        </Grid>:""
                                        }
                                        
                                        <Grid item xs={6}>
                                        
                                        <FormControl>
                                            <label htmlFor="ward" align="left">Ward</label>
                                            <Select value={this.state.wardNumber} onChange={this.handleAreaChange} name="wardNumber">
                                                        {!!this.state.wardOptions.length !=0&&this.state.wardOptions.map((option) =>{ 
                                                            if(option.wardId == null){
                                                                swal({
                                                                    title:'ward not avaliable!!',
                                                                    icon:'warning'
                                                                })
                                                            }
                                                            return(
                                                            <MenuItem value={option.wardId}>{option.wardNumber}</MenuItem>
                                                        )})}
                                                    </Select> 
                                        </FormControl>
                                   </Grid>
                                    <Grid item xs={6}>
                                         <FormControl>
                                                <label htmlFor="isDisableProperty" align="left">Status</label>
                                                <Select value={this.state.isDisableProperty} onChange={this.handleAreaStatus} name="isDisableProperty">
                                                <MenuItem value={false}>{'Active'}</MenuItem>
                                                <MenuItem value={true}>{'In Active' }</MenuItem>
                                        </Select> 
                                         </FormControl>
                                   </Grid>
                                </Grid>

                                </div>
                            </div>


                        </DialogContent>
                        <DialogActions>
                            {this.state.openAreaPop === true ?
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <div className="create_ulb_buttons" style={{ marginBottom: '10px' }}>
                                            <Button className="login_submit" variant="outlined" color="primary" onClick={this.handleAreaClosePop}>Cancel</Button>
                                            <Button className="login_submit" variant="contained" color="primary" onClick={() => this.handleAreaSubmit()}>{this.state.editId != "" ? `Save Changes` : `Save`}</Button>
                                        </div>
                                    </Grid>
                                </Grid> : ""}
                        </DialogActions>
                    </Dialog>
                    </div>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        token: state.auth.token,
        propertyListing:!!state.propertyListing.propertyListing&&state.propertyListing.propertyListing,
        Type : state.propertyListing.propertyTypeList,
        headers:!!state.propertyHeaders.headers&&state.propertyHeaders.headers.concat({header: "Action", accessor: "actionButton", sortable: "false", filter: "true"}),
        propertyListing:state.propertyListing.propertyListing,
        totalCount : state.propertyListing.totalCount,
        loadingData: state.propertyListing.loadingSuccess,
        progressPercentage : !!state.propertyListing&&state.propertyListing.progress,
        loadingbulk: state.propertyListing.loadingbulk,

    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchPropertyHeaders: (payload) => dispatch(actionsCreators.fetchPropertyHeaders(payload)),
        onFetchPropertyListing: (payload,url) => dispatch(actionsCreators.fetchPropertyListing(payload,url)),
        onFetchPropertyBulkImport: (payload,formData,props) => dispatch(actionsCreators.fetchPropertyBulkImport(payload,formData,props)),
        onFetchAreaBulkImport: (payload,formData,props) => dispatch(actionsCreators.fetchAreaBulkImport(payload,formData,props)),
        onPropertyType : ()=>dispatch(actionsCreators.propertyType()),
        onSearchProperty:(payload, url,type)=>dispatch(actionsCreators.searchProperty(payload, url, type)),
        onDeleteProperty : (payload)=>dispatch(actionsCreators.deleteProperty(payload)),
        exportProperty :(payload) => dispatch(actionsCreators.exportProperty(payload)),
        onEditPropertyData: (formdata, props , payload) => dispatch(actionsCreators.editPropertyData(formdata, props , payload)),
        OnAddPropertySingle: (payload)=>dispatch(actionsCreators.AddPropertySingle(payload)),
        OnAddAreaSingle: (payload)=>dispatch(actionsCreators.AddAreaSingle(payload))
    }
}

export default  connect(mapStateToProps, mapDispatchToProps)(PropertyListing);