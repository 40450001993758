import * as actionTypes from '../../actions/actionsTypes';
import { updateObject } from '../../utility';

const initialState = {
    ZoneHeader: [],
    loading: false
}

const fetchZoneHeadersStart = (state, action) => {
    return updateObject(state, {
        loading: true,
    });
}

const fetchZoneHeadersSuccess = (state, action) => {
    return updateObject(state, {
        ZoneHeader: action.ZoneHeaders,
        loading: false,
    });
}

const fetchZoneHeadersFail = (state, action) => {
    return updateObject(state, {
        loading: false,
    })
}

const ZoneListingreducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_PROPERTYHEADERS_START: return fetchZoneHeadersStart(state, action);
        case actionTypes.FETCH_PROPERTYHEADERS_SUCCESS: return fetchZoneHeadersSuccess(state, action);
        case actionTypes.FETCH_PROPERTYHEADERS_FAIL: return fetchZoneHeadersFail(state, action);
        default: return state;
    }
}

export default ZoneListingreducer;