import * as actionTypes from '../actionsTypes'
import axios from '../../../http-config'
import swal from 'sweetalert'

export const fetchUlbDataStart = () => {
    return({
        type  : actionTypes.FETCH_ULB_DATA_START,
        loading: false
    })
}

export const fetchUlbDataSuccess = (response) => {
    return ({
        type    : actionTypes.FETCH_ULB_DATA_SUCCESS,
        payload : response.data.ulbs,
        totalCount : response.totalCount,
        loading:true
    })
}

export const fetchUlbDataFail = (error) => {
    return({
        type   : actionTypes.FETCH_ULB_DATA_FAIL,
        error  : error,
        loading:false
    })
}

export const fetchUlbData = (payload) => {

    const token = payload.token
    let url ;
    if(payload.searchQuery != ""&&payload.searchQuery != undefined){
        url = `ulb?name=${payload.searchQuery}&limit=${payload.limit}&page=${payload.page}&sortColumnName=${payload.sortName}&sortColumnType=${payload.sortValue}`
    }
 
    else{
        url = payload.limit !==undefined && payload.page !== undefined || payload.filter !== undefined?  payload.filter !== undefined ? `ulb?tfULBFilter=true` :`/ulb?limit=${payload.limit}&page=${payload.page}&sortColumnName=${payload.sortName}&sortColumnType=${payload.sortValue}&statedashboardValue=${!!payload.filterValue&&payload.filterValue !== ""? payload.filterValue:''}&isDisableULB=${!!payload.status&&payload.status != ""? payload.status:''}&typeId=${!!payload.filterTypesValue&&payload.filterTypesValue != ""? payload.filterTypesValue:''}`: '/ulb/?dropdown=true'
    }
    return (dispatch) => {
        dispatch(fetchUlbDataStart())
        axios({
            method  : 'get',
            url     :url,
            headers : {'Authorization': `Bearer ${token}`}
        })
        .then((response) => {
            dispatch(fetchUlbDataSuccess(response.data))
        })
        .catch((err) => {
            dispatch(fetchUlbDataFail(err))
        })
    }
}

export const addUlbData = (payload, props, storeData)=>{
    let token=props.token
    return (dispatch)=>{
        dispatch(fetchUlbDataStart())

        let url = '/ulb/';
        axios.post(url, payload,{headers:{
            "Authorization":`Bearer ${token}`
        }})
        .then((response) => {
            dispatch(fetchUlbData(payload));
            const fetchedResponse = response.data.success;
            if(fetchedResponse === true){
                fetchedResponse && swal({
                    title: "ULB added Successfully!!!",
                    icon: "success",
                    buttons: {
                        confirm: 'Ok'
                    }
                  })
                // props.history.push('/')
                dispatch(fetchUlbData(storeData))

            }
                
        }, 
        (err) => {
            swal({icon : "error" , title : err.response.data.error[0]["message"]})    
            dispatch(fetchUlbData(storeData))
       
        }).catch(error => {
            dispatch(fetchUlbData(storeData))
            dispatch(fetchUlbDataFail(error))           
        })
        
    }
}
export const editUlbData = (payload, props, storeData) => {
    // const id = props.id
    return dispatch => {
        dispatch(fetchUlbDataStart());
        let url = '/ulb/';
        axios.put(url, payload,{headers:{
            "Authorization":`Bearer ${props.token}`
        }})
        .then(response => {
            dispatch(fetchUlbData(payload));
            const fetchedResponse = response.data.success;
            if(fetchedResponse ===true){
                fetchedResponse && swal({
                    title: "You have Successfully edited the data!!!",
                    icon: "success",
                    buttons: {
                        confirm: 'Ok'
                    }
                  })
                //   props.history.push('/')
                  dispatch(fetchUlbData(storeData))

            }
             
        }, (err) => {
            swal({icon : "error" , title : err.response.data.error[0]["message"]})   
            dispatch(fetchUlbData(storeData ))
       
        })
        .catch(err => {
            dispatch(fetchUlbDataFail(err))
        })
    }
}

export const deleteUlbData = (payload, token) => {
    return (dispatch) => {
        dispatch(fetchUlbDataStart())
        const url =  '/ulb/'
        axios.delete(url , { data : payload, headers : {   "Authorization":`Bearer ${token}`} })
        .then((response) => {
            const payload = {
                token :token
            }
            dispatch(fetchUlbData(payload))
            swal({
                title:"Your data has been deleted!",
                icon: "success",
            })
            dispatch(fetchUlbData({token:payload.token, page:1, limit:10, sortName:'', sortValue:'',statedashboardValue:"",isDisableULB:"",typeId:"" } ))

            
        }, 
        (err) => {
            dispatch(fetchUlbData({token:payload.token, page:1, limit:10, sortName:'', sortValue:'',statedashboardValue:"",isDisableULB:"",typeId:"" } ))
            swal({icon : "error" , title : err.response.data.error[0]["message"]}) 
        })
    }
}
export const SelectULbs =(payload, props)=>{
    return (dispatch)=>{
        dispatch(fetchUlbDataStart())

        let url = '/ulb/state_dashboard';
        axios.put(url, payload,{headers:{
            "Authorization":`Bearer ${payload.token}`
        }})
        .then((response) => {
            dispatch(fetchUlbData(payload));
            const fetchedResponse = response.data.success;
             if(response.data.data[0].StatedashboardValue === false){
                swal({
                    title: "ULB Removed Successfully!!!",
                    icon: "success",
                    buttons: {
                        confirm: 'Ok'
                    }
                  })
                props.history.push('/')
            }
             if(response.data.data[0].StatedashboardValue === true){
                swal({
                    title: "ULB Selected Successfully!!!",
                    icon: "success",
                    buttons: {
                        confirm: 'Ok'
                    }
                  })
                props.history.push('/')
            }
                
        }, 
        (err) => {
            if(!!err&&err.response.data.error[0]["message"]){
                swal({icon : "error" , title : err.response.data.error[0]["message"]})  
            }
                   
        }).catch(error => {
            dispatch(fetchUlbDataFail(error))           
        })
        
    }
}


export const searchULBName=(payload)=>{
    return dispatch =>{
        dispatch(fetchUlbDataStart());
        const url =`ulb?name=${payload.searchQuery}&limit=${payload.limit}&page=${payload.page}`
        axios.get(url,{headers:{
            "Authorization":`Bearer ${payload.token}`
        }} ).then(response=>{
            response && response.data && response.data.data.ulbs &&
            response.data.data.ulbs && 
            response.data.data.ulbs.length == 0 && 
            swal("No Records Found !", {
                icon: "warning",
              });
            dispatch(fetchUlbDataSuccess(response.data));
        }).catch(err=>{
            dispatch(fetchUlbDataFail(err));
        })
    }
}
export const configuration =(payload, data, props)=>{
   return (dispatch)=>{
       dispatch(fetchUlbDataStart())

       let url =`ulb/configuration`
       axios.post(url, payload,{headers:{
           "Authorization":`Bearer ${data.token}`
       }})
       .then((response) => {
           dispatch(fetchUlbData(data));
           const fetchedResponse = response.data.success;
           if(fetchedResponse === true){
               fetchedResponse && swal({
                   title: "Configuration added Successfully!!!",
                   icon: "success",
                   buttons: {
                       confirm: 'Ok'
                   }
                 })
               props.history.push('/setting')
               dispatch(fetchUlbData({token:props.token, page:1, limit:10, sortName:'', sortValue:'',statedashboardValue:"",isDisableULB:"",typeId:"" } ))

           }
               
       }, 
       (err) => {
           swal({icon : "error" , title : err.response.data.error[0]["message"]})           
       }).catch(error => {
           dispatch(fetchUlbDataFail(error))           
       })
       
   }
}


export const fetchULBListingExport =(payload)=>{
    let token = payload.token 
    const url =`ulb?name=${payload.searchQuery}&sortColumnName=${payload.sortName}&sortColumnType=${payload.sortValue}&export=true`
    return dispatch=>{
        dispatch(fetchUlbDataStart())
        axios.get(
            url,
            {headers:{ "Authorization":`Bearer ${token}`
        }}).then(response=>{
            let exportLink=response.data.data[0].file_path
            window.downloadFile = function (link) {
                window.open(link, '_blank');
            }
            window.downloadFile(exportLink)
  
        }).catch(err=>{
            dispatch(fetchUlbDataFail(err))  
        })
    }
 }