import React, {useState,useEffect} from 'react';
import { connect } from 'react-redux'
import UserForm from './UserForm'
import * as actionCreators from '../../store/actions/index'
import axios from '../../http-config';
import _ from 'lodash'

function UserEdit (props){
 
    const [singleUser, setUser] = useState({}); 
    useEffect(() => {
        const payload = {
                        token : props.token,
                        id    : props.idEdit
        }
        const url = `/user/${payload.id}`
        axios({
                        method   : 'get',
                        url      : url,
                        headers  : {'Authorization': `Bearer ${payload.token}`}
                   })
                   .then((response) => {
                       setUser(response.data.data.users[0])
                   })
                   .catch((err) => {
                       
                   })
      },[]);
    
    const handleSubmit = (formdata)=>{
        props.onEditUser(formdata,props)
    }
    return (
        <div>
            <div className="create_ulb">
                <div className="dashboard_top_path">
                    <p><img src="images/left-arrow.svg" /> User Management / <span>Edit User</span></p>
                </div>
                <div className="dashboard_right_content">
                    <img className="dashboard_right_content_top_icon" src="images/Rectangle.svg" />
                    <div className="dashboard_right_content_top">
                        <h5>Edit User</h5>
                    </div>
                    <div className="create_ulb_content_outer">
                        <div className="create_ulb_content">
                            {
                                !_.isEmpty(singleUser) && (
                                    <UserForm {...singleUser} handleSubmit={handleSubmit}/>
                                )
                            }
                           
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    )
    
}

const mapStateToProps = (state) => ({
    token:state.auth.token
})

const mapDispatchToProps = (dispatch) => {
    return({
        onEditUser : (payload,props) => dispatch(actionCreators.editUser(payload,props))
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(UserEdit)

