import React, {useState,useEffect} from 'react';
import { connect } from 'react-redux';
import '../../App.css'
import * as actionsCreators from '../../store/actions/index'
import axios from '../../http-config';
import _ from 'lodash'
import OperatorDetails from './OperatorDetails'
import {Link,Router} from 'react-router-dom'
import Button from '@material-ui/core/Button';
import OperatorDialog from './OperatorDialog'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
function OperatorData (props) {
    const [singleOperator, setOperator] = useState({}); 
    const [open,setOpen] = useState(false)
    const [button,setButton] = useState('Save')

    useEffect(() => {
        const payload = {
                        token : props.token,
                        id    : props.match.params.id
        }
        const url = `/operator/${payload.id}`
        axios({
                        method   : 'get',
                        url      : url,
                        headers  : {'Authorization': `Bearer ${payload.token}`}
                   })
                   .then((response) => {
                       setOperator(response.data.data.operators[0])
                   })
                   .catch((err) => {
                       
                   })
      },[]);

    const openDialog = (value,button) => {
        setOpen(value)
        if(button){
           setButton(button)
        }
    }

    const handleDialogClose = (value,created) => {
        setOpen(value) 
        if(created){
            const payload = {
                token : props.token,
                id    : props.match.params.id
            }
            const url = `/operator/${payload.id}`
            axios({
                    method   : 'get',
                    url      : url,
                    headers  : {'Authorization': `Bearer ${payload.token}`}
            })
            .then((response) => {
                setOperator(response.data.data.operators[0])
            })
            .catch((err) => {
                
            })
        }
        }

        return(

            <div className="create_ulb">
            <div className="dashboard_top_path">
                <p><img src="images/left-arrow.svg" /> Operator Management / <span>Operator Details</span></p>
            </div>
            <div className="dashboard_right_content">
                {/* <img className="dashboard_right_content_top_icon" src="images/Rectangle.svg" /> */}
             
                <div className="dashboard_right_content_top">
                <h5><Link to = {`/operator-management`} className="email_link"><ArrowBackIosIcon></ArrowBackIosIcon></Link>Operator Details</h5>
                    <div className="dashboard_right_content_top_options">
                        {!_.isEmpty(singleOperator) && !singleOperator.username && !singleOperator.password && <Button onClick = { () => openDialog(true,'Save')}>Add user account</Button>}
                        <Link style={{textDecoration:"none", color:"#3dab35"}} to={`/operator-management/${props.match.params.id}`}><Button className="login_submit" variant="outlined" color="primary">Edit operator</Button></Link> 
                    </div>
                </div>
                <div className="create_ulb_content_outer">
                    <div className="create_ulb_content">
                { 
                 !_.isEmpty(singleOperator)&&(
                    <OperatorDetails {...singleOperator} handleClose = {handleDialogClose} openDialog = {openDialog}/>
                 )
                }        
                    </div>
                </div>
            </div>
                {!_.isEmpty(singleOperator)&&(
                    <OperatorDialog {...singleOperator} button= {button} open={open} handleClose = {handleDialogClose}></OperatorDialog>
                )}
        </div>
                
        )
}



const mapStateToProps = (state,props) => {
    
return {
    token:state.auth.token
}
}

const mapDispatchToProps = dispatch => {
return {
}
}

export default  connect(mapStateToProps, mapDispatchToProps)(OperatorData);

