import * as actionTypes from './actionsTypes';
import axios from '../../http-config';

import swal from 'sweetalert'

export const fetchLoadsSuccess = (response) => {
    return {
        type: actionTypes.FETCH_LOADS_SUCCESS,
        loads: response.data.loads,
        totalCount: response.totalCount,
      
    }
}
export const fetchSameLoadsSuccess= (response) => {
    return {
        type: actionTypes.FETCH_SAME_LOADS_SUCCESS,
        sameLoads: response.data.loads,
        totalCountLoads: response.totalCount,
        loadingSameLoads:response.success
      
    }
}
export const fetcSampleFilesSuccess = (response) => {
    return {
        type: actionTypes.FETCH_CONFIG_SUCCESS,
        config: response.data.ulbs,
    }
}

export const fetchLoadsFail = (error) => {
    return {
        type: actionTypes.FETCH_LOADS_FAIL,
        error:error
    }
}

export const fetchLoadsStart = () => {
    return {
        type: actionTypes.FETCH_LOADS_START
    }
}
export const fetchOperatorListingSuccess = (response) => {
    return {
        type: actionTypes.FETCH_OPERATORLISTING_LOAD_SUCCESS,
        operaters: response.data.operators,
        totalCount: response.totalCount,
    }
}
export const fetchTrucksListingSuccess = (response)=>{
    return {
        type: actionTypes.FETCH_TRUCKLISTING_LOAD_SUCCESS,
        Trucks: response.data.trucks,
        totalCount: response.totalCount,
    }
}

export const fetchLoadsBulkStart = () => {
    return {
        type: actionTypes.FETCH_LOADSBULK_START,
        success:false,
        progress:0
    }
}
export const fetchLoadsBulkSuccess = () => {
    return {
        type: actionTypes.FETCH_LOADSBULK_SUCCESS,
        success:true,
        progress:100
    }
}

export const fetchLoadsBulkFail = (error) => {
    return {
        type: actionTypes.FETCH_LOADSBULK_FAIL,
        error:error,
        success:false,
        progress:0
    }
}
export const fetchLoads = (payload) => {
    let limit = payload.limit;
    let page = payload.page;
    let sort = payload.sort;
    let filter = payload.filter;
    let query = payload.query;
    let token = payload.token;
    let ulbId = payload.ulbId
     let fromDate=payload.fromDate
    let toDate=payload.toDate
    return dispatch => {
        dispatch(fetchLoadsStart());
        // load/?limit=10&page=1&sort=&query=&fromDate=01-01-2020%2000:00&toDate=10-04-2021%2023:59&operatorId=31
        const url =  ulbId == "" ? `/load/?limit=${limit}&page=${page}&sort=${sort}&filter=${filter}&query=${query}` : 
        `/load/?limit=${limit}&page=${page}&sort=${sort}&filter=${filter}&query=${query}&ulbId=${payload.ulbId}&fromDate=${fromDate}&toDate=${toDate}`;
        axios({
            method: 'get',
            url: url,
            headers: {'Authorization': `Bearer ${token}`}
        })
        .then(function (response) {
            const fetchedLoadsResponse = response.data;
            dispatch(fetchLoadsSuccess(fetchedLoadsResponse));
        }).catch(error => {
            dispatch(fetchLoadsFail(error));
        })
        }
}

export const fetchLoadsPerProperty = (payload) => {
    return dispatch => {
        dispatch(fetchLoadsStart());
        const url = `property/${payload.ulbId}/load?limit=${payload.limit}&page=${payload.page}`;
        axios({
            method: 'get',
            url: url,
            headers: {'Authorization': `Bearer ${payload.token}`}
        })
        .then(function (response) {
            const fetchedLoadsResponse = {
                "data" : {
                    "loads" : response.data.data.propertyLoads
                }
            }
            dispatch(fetchLoadsSuccess(fetchedLoadsResponse));
        }).catch(error => {
            dispatch(fetchLoadsFail(error));
        })
    }
}


export const editLoads = (formdata,props) => {
    let filterR =  props?.location?.state?.filterR
let token=localStorage.getItem('token');
    return dispatch => {
        dispatch(fetchLoadsStart());
        const url = '/load'
        
        axios.post(url,formdata,{headers:{
            "Authorization":`Bearer ${token}`
        }})
        .then(function (response) {
            const fetchedLoadsResponse = response.data.success;
            fetchedLoadsResponse && swal({
                title: "Data edited successfully!",
                icon: "success",
                buttons: {
                    confirm: 'Ok'
                }
              })
              .then((edit) => {
                if (edit) {
                    // dispatch(fetchLoads({}));
                props.history.push({
                    pathname: '/records/all',
                    state: {
                      page : props?.location?.state?.pageR,
                      limit: props?.location?.state?.limitR,
                      sort :  props?.location?.state?.sortR,
                    filter :  filterR,
                    query : props?.location?.state?.queryR,
                    ulbId :  props?.location?.state?.ulbIdR,
                    fromDate: props?.location?.state?.fromDateR,
                    toDate: props?.location?.state?.toDateR,
                    operatorId:props?.location?.state?.operatorIdR,
                    }
                  }); 
                } 
              });
            dispatch(fetchLoadsSuccess(fetchedLoadsResponse));
        }).catch(error => {
            dispatch(fetchLoadsFail(error));
        })
    }
}

export const deleteRecords = (payload,props) => {
    return (dispatch) => {
        dispatch(fetchLoadsStart())
        const url =  `load/${payload.id}`
        axios.delete(url , { data : payload, headers : {   "Authorization":`Bearer ${payload.token}`} })
        .then((response) => {
            response && swal({
                title: "Your data has been deleted!",
                icon: "success",
                buttons: {
                    confirm: 'Ok'
                }
              })
              .then((edit) => {
                if (edit) {
                props.history.push('/records/all')  
                } 
              });
            dispatch(fetchLoads(payload));
        }, 
        (err) => {
            swal({icon : "error" , title : err.response.data.error[0]["message"]}) 
        })
        props.history.push('/records/all')  
    }
}

export const filterLoads = (payload) => {
    let limit = payload.limit;
    let page = payload.page;
    let sort = payload.sort;
    let filter = payload.filter;
    let query = payload.query;
    let token = payload.token;
    let fromDate=payload.fromDate
    let toDate=payload.toDate
    let ulbId = payload.ulbId
    let operatorID = payload.operatorId
    
    return dispatch => {
        dispatch(fetchLoadsStart());
        // /load/?limit=10&page=1&sort=&filter=[{%22wardNumber%22:%22%22,%22operatorName%22:%22HelloPK%22,%22truckRegistrationNumber%22:%22%22,%22propertyTypeName%22:%22%22}]&query=
        const url = ulbId === ''&&operatorID ==''?`/load/?limit=${limit}&page=${page}&sort=${sort}&filter=${filter}&query=${query}&fromDate=${fromDate}&toDate=${toDate}`:
      ulbId !=""&&operatorID !== ""?  `/load/?limit=${limit}&page=${page}&sort=${sort}&filter=${filter}&query=${query}&fromDate=${fromDate}&toDate=${toDate}`:  ulbId !=""&&operatorID == ""?  `/load/?limit=${limit}&page=${page}&sort=${sort}&filter=${filter}&query=${query}&ulbId=${ulbId}&fromDate=${fromDate}&toDate=${toDate}`:'';
        axios({
            method: 'get',
            url: url,
            headers: {'Authorization': `Bearer ${token}`}
        })
        .then(function (response) {
            const fetchedLoadsResponse = response.data;
            
            dispatch(fetchLoadsSuccess(fetchedLoadsResponse));
        }).catch(error => {
            dispatch(fetchLoadsFail(error));
        })
    }
}

export const fetchBulkImport = (payload,formData, props) => {
    let token = payload.token
    return dispatch => {
        dispatch(fetchLoadsBulkStart());
        const url = '/load/bulk'

        axios.post(url,formData,{headers:{
            "Authorization":`Bearer ${token}`
        }})
        .then(function (response) {
            if(response){
                const fetchedLoadsResponse = response.data.success;
                setTimeout(()=>{
                    dispatch(fetchLoadsBulkSuccess())
                    fetchedLoadsResponse && swal({
                        title: "Data imported successfully!",
                        icon: "success",
                        buttons: {
                            confirm: 'Ok'
                        }
                      })
                      .then((bulk) => {
                        if (bulk) {
                        dispatch(fetchLoads(payload))
                        } 
                      });
                },2000)
            }
              
        }, (err) => {
            if(err.response.data.error.length ===1){
                swal({
                    title:err.response.data.error[0].message,
                    icon: "error",
                    buttons: {
                        confirm: 'Ok'
                    }
                  })
                  props.history.push('/records/all')
            }
            else{
                const errors = !!err.response.data.error? err.response.data.error.length : ''
                let list = '';
                var myhtml = document.createElement("div");
                myhtml.className = 'textNode-content';
                 for(let i = 0; i<errors; i++){
                     list += '*'  + err.response.data.error[i].errmessage +'  '+ '.' +'\n';
                    
                 }
                 myhtml.innerHTML = list
                 swal({icon : "error" , content: myhtml})   
                 props.history.push('/records/all')
            }
        
        })
        .catch(error => {
            dispatch(fetchLoadsFail(error));
            dispatch(fetchLoadsBulkFail(error))
        })

    }
}


export const DownloadSampleFile =()=>{
    let token=localStorage.getItem('token');
    return dispatch =>{
        dispatch(fetchLoadsStart());
        const url = '/ulb/user_ulb';
        axios.get(url, {headers: {'Authorization': `Bearer ${token}`}})
        .then(res=>{
            dispatch(fetcSampleFilesSuccess(res.data))
        })
        .catch(error=>{
            dispatch(fetchLoadsFail(error));
        })
    }
}

export const OpertaterList =(payload)=>{
    const api= '/operator/'

    let token = payload.token;
    return dispatch => {
        dispatch(fetchLoadsStart());
        axios({
            method : 'get',
            url : api,
            headers :  {'Authorization': `Bearer ${token}`}
        })
        .then((response) => {
            const fetchedoperatorListingResponse = response.data
            dispatch(fetchOperatorListingSuccess(fetchedoperatorListingResponse));
        })
        .catch((err) => {
            dispatch(fetchLoadsFail(err))
        })
    }

}

export const trucksList =(payload)=>{
    const api = `/truck?operatorId=${payload.operatorId}`
    let token = payload.token;
    return dispatch => {
        dispatch(fetchLoadsStart());
        axios({
            method : 'get',
            url : api,
            headers :  {'Authorization': `Bearer ${token}`}
        })
        .then((response) => {
            const fetchedTrucksListingResponse = response.data
            dispatch(fetchTrucksListingSuccess(fetchedTrucksListingResponse));
        })
        .catch((err) => {
            dispatch(fetchLoadsFail(err))
        })
    }
}


export const isCollectioRequired =(payload, props)=>{
        return (dispatch)=>{
            dispatch(fetchLoadsStart())
    
            let url =  `ulb/updateRepeatCollection`
            axios.put(url, payload,{headers:{
                "Authorization":`Bearer ${payload.token}`
            }})
            .then(function (response) {
                const fetchedDayData = response.data.success;
                fetchedDayData && swal({
                    title: "You have Successfully update Repeat Collection Mode!!!",
                    icon: "success",
                    buttons: {
                        confirm: 'Ok'
                    }
                  })
                  fetchedDayData && localStorage.setItem('isRepeatCollection',response.data.data[0].IsRepeatCollection)
                  props.history.push('/records/all')
                // window.location.reload();
            }, (err) => {
                swal({icon : "error" , title : err.response.data.error[0]["message"]})  
            })
            .catch(err => {
                dispatch(fetchLoadsFail(err))        
            })
            
        }
    }

    export const  sameLoadsListing =(payload)=>{
        return dispatch => {
            dispatch(fetchLoadsStart());
            const url = `/load/?loadsId=${JSON.stringify(payload.loadsId)}&limit=${payload.limit}&page=${payload.page}`
            axios({
                method: 'get',
                url: url,
                headers: {'Authorization': `Bearer ${payload.token}`}
            })
            .then(function (response) {
                const fetchedLoadsResponse = response.data;
                
                dispatch(fetchSameLoadsSuccess(fetchedLoadsResponse));
            }).catch(error => {
                dispatch(fetchLoadsFail(error));
            })
        }
    }
export const multipleLoadsEdit = (payload, loadsListingPayload )=>{
    let token=localStorage.getItem('token');
    return dispatch => {
        dispatch(fetchLoadsStart());
        const url = payload.selectedUlB !== "" ? `/load/multipleLoad/?propertyIds=${JSON.stringify(payload.propertyIds)}` : payload.propertyTypeId !== "" ?  `/load/multipleLoad/?propertyIdTypeId=${JSON.stringify(payload.propertyIdTypeId)}`: `/load/multipleLoad/?selectedLoadsId=${JSON.stringify(payload.selectedLoadsId)}`
        
        axios.put(url,payload,{headers:{
            "Authorization":`Bearer ${token}`
        }})
        .then(function (response) {
            const fetchedLoadsResponse = response.data.success;
            fetchedLoadsResponse && swal({
                title: "Data edited successfully!",
                icon: "success",
                buttons: {
                    confirm: 'Ok'
                }
              })
              .then((edit) => {
                if (edit) {
                    dispatch(filterLoads(loadsListingPayload))
                    // dispatch(sameLoadsListing({loadsId:payload.selectedLoadsId, token: token, limit:10, page:payload.page}))
                } 
              });
            dispatch(fetchLoadsSuccess(fetchedLoadsResponse));
        }).catch(error => {
            dispatch(fetchLoadsFail(error));
        })
    }
}