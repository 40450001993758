import * as actionTypes from '../actionsTypes';
import axios from '../../../http-config'
import swal from 'sweetalert'
import moment from 'moment'

export const fetchChartDataStart = () => {
    return {
        type: actionTypes.FETCH_IOT_CHART_DATA_START,
        // loading:true
    }
}

export const fetchChartDataSuccess = (response) => {
    return {
        type : actionTypes.FETCH_IOT_CHART_DATA_SUCCESS,
        iotChartData: response.data,
        success:true 
    }
}

export const fetchTableDataStart = () => {
    return {
        type: actionTypes.FETCH_IOT_TABLE_DATA_START,
    }
}

export const fetchTableDataSuccess = (response) => {
    return {
        type : actionTypes.FETCH_IOT_TABLE_DATA_SUCCESS,
        iotTableData: response.data,
    }
}

export const fetchChartMetaDataStart = () => {
    return {
        type: actionTypes.FETCH_CHART_META_DATA_START
    }
}

export const fetchChartMetaDataSuccess = (response) => {
    return {
        type : actionTypes.FETCH_CHART_META_DATA_SUCCESS,
        metaData:response.data,
        loading: false,
    }
}

export const fetchFilteredDataStart = (response) => {
    return {
        type : actionTypes.FETCH_IOT_CHART_DATA_START,
        success:true 
    }
}

export const fetchFilteredDataSuccess = (response) => {
    return {
        type : actionTypes.FETCH_FILTEREDDATA_SUCCESS,
        filteredData:response,
        success: false,
    }
}

export const fetchChartDataFail = () => {
    return {
        type: actionTypes.FETCH_IOT_CHART_DATA_FAIL,
    }
}

export const getIotChartData = (payload) => {
    return dispatch => {
        const todate = moment(payload.todate).format('YYYY-MM-DD');
        const fromdate = moment(payload.fromdate).format('YYYY-MM-DD');
        dispatch(fetchChartDataStart());
         axios.get(payload.tfValue ? `http://www.reyocto.info/reyocto/reportdata/17/${fromdate}/${todate}/` : payload.url,{timeout:40000}).then((response) => {       
        dispatch(fetchChartDataSuccess(response)); 
     })
     .catch((err) => {
        if(err.response){
            dispatch(fetchChartDataFail())
            swal({icon : "error" , title : err.response.data.Message}) 
        }else{
            dispatch(fetchChartDataFail())
            swal({icon : "error" , title :err}) 
        }
     })
    }
}

export const getTableData = (payload) => {
    return async dispatch => {
        const token = localStorage.getItem('token')
        const todate = moment(payload.todate).format('YYYY-MM-DD');
        const fromdate = moment(payload.fromdate).format('YYYY-MM-DD');
        dispatch(fetchChartDataStart());
        try{
            const response = await axios.get(payload.tfValue ? `http://www.reyocto.info/reyocto/reportdata/17/${fromdate}/${todate}/` : payload.url,
            {headers:{
                "Authorization":`Bearer ${token}`
            }})
            dispatch(fetchTableDataSuccess(response.data));
            return response 
        }
        catch (err) {
          if(err.response){
                dispatch(fetchChartDataFail())
                swal({icon : "error" , title : err.response.data.Message}) 
            }else{
                swal({icon : "error" , title :err}) 
                dispatch(fetchChartDataFail())
            }
        }
    }
}


//to get url data from backend
export const getURLData = (payload) => {
    let token = localStorage.getItem('token')
    const url = `/iot_dashboard/get_url_data/${payload.fromDate}/${payload.toDate}?url=${payload.path}`
    return async dispatch => {
        dispatch (fetchChartMetaDataStart());
        const response = await axios.get(url,{headers:{
            "Authorization":`Bearer ${token}`
        }})
        dispatch(fetchChartDataSuccess(response.data))
        return response
    }
}
//for admin and ULB admin
export const deleteChart = (payload) => {
    let body = JSON.parse(`{"id":${payload.id}}`)
    const url = '/iot_dashboard/'
    return async dispatch => {
        const response = await axios.delete(url , { data:body, headers : {   "Authorization":`Bearer ${payload.token}`} })
        if(response){
            const fetchedLoadsResponse = response.data.success;
            fetchedLoadsResponse && swal({
                title: "Graph Deleted successfully!",
                icon: "success",
                buttons: {
                    confirm: 'Ok'
                }
              })
        }
        return response
    }
}

export const addChartData = (payload,ulbid) => {
    payload.payload = JSON.parse(JSON.stringify(payload.payload))
    const url = ulbid ? `/iot_dashboard?ulbId=${ulbid}` : '/iot_dashboard/'
    return async dispatch => {
        dispatch(fetchChartMetaDataStart())
        const response = await axios.post(url,{"ulb":payload.payload[0]},{headers:{
            "Authorization":`Bearer ${payload.token}`
        }})
        const fetchedLoadsResponse = response.data.success;
        fetchedLoadsResponse && swal({
            title: "Graph Added successfully!",
            icon: "success",
            buttons: {
                confirm: 'Ok'
                }
            })
        dispatch(fetchChartDataFail())
        return response   
    }
}

export const editChart = (payload) => {
    let body = {
        Id:payload.Id,
        ULB:payload.ULB,
        UlbId:payload.ulbId
    }
    const url = '/iot_dashboard/'
    return async dispatch => {
        dispatch(fetchChartMetaDataStart())
        const response = await axios.put(url,body,{headers:{
            "Authorization":`Bearer ${payload.token}`
        }})
        const fetchedLoadsResponse = response.data.success;
        if(!payload.filter){
                fetchedLoadsResponse && swal({
                title: "Graph Edited successfully!",
                icon: "success",
                buttons: {
                    confirm: 'Ok'
                     }
                })
        }
        dispatch(fetchChartDataFail())
        return fetchedLoadsResponse
    }
}

export const getAllCharts = (payload) => {
    const url = payload.ulb ? `/iot_dashboard/${payload.ulb}` : '/iot_dashboard/'
    return dispatch => {
        dispatch (fetchChartMetaDataStart());
        axios.get(url,{headers:{
            "Authorization":`Bearer ${payload.token}`
        }})
        .then(function (response) {
            if(response){
               dispatch(fetchChartMetaDataSuccess(response.data))
            }
              
        }, (err) => {
            // swal({icon : "error" , title : err.response.data.error[0]["message"]})    
        })
        .catch(error => {
            // dispatch(fetchPropertyListingFail(error));
        })
    }
}

export const getAllChartsDefault = (payload) => {
    const url = payload.ulb ? `/iot_dashboard/${payload.ulb}` : '/iot_dashboard/'
    return async dispatch => {
        try {
            // dispatch(fetchChartDataStart())
             const response = await axios.get(url,{headers:{
            "Authorization":`Bearer ${payload.token}`
            }})
            const fetchdata = response.data.data
            dispatch(fetchChartMetaDataSuccess(response.data))
            return fetchdata
          } catch (error) {
            dispatch(fetchChartDataFail())
          }

    }
}

//for State/Regional Officer user
export const deleteChartSD = (payload,ulbId) => {
    let body = JSON.parse(`{"id":${payload.id}}`)
    const url = `/state_dashboard_user_access_iot/${payload.id}`
    return async dispatch => {
        const response = await axios.delete(url , { headers : {   "Authorization":`Bearer ${payload.token}`} })
        if(response){
            const fetchedLoadsResponse = response.data.success;
            fetchedLoadsResponse && swal({
                title: "Graph Deleted successfully!",
                icon: "success",
                buttons: {
                    confirm: 'Ok'
                }
              })
        }
        return response
    }
}

export const addChartDataSD = (payload,uldId) => {
    payload.payload = JSON.parse(JSON.stringify(payload.payload))
    const url = '/state_dashboard_user_access_iot'
    return async dispatch => {
        dispatch(fetchChartMetaDataStart())
        const response = await axios.post(url,{"ulbId": uldId,"ulb":payload.payload[0]},{headers:{
            "Authorization":`Bearer ${payload.token}`
        }})
        const fetchedLoadsResponse = response.data.success;
        fetchedLoadsResponse && swal({
            title: "Graph Added successfully!",
            icon: "success",
            buttons: {
                confirm: 'Ok'
                }
            })
        dispatch(fetchChartDataFail())
        return response   
    }
}

export const editChartSD = (payload,ulbId) => {
    let body = {
        id:payload.Id,
        ulb:payload.ULB,
        ulbId:payload.ulbId
    }
    const url = 'state_dashboard_user_access_iot'
    return async dispatch => {
        dispatch(fetchChartMetaDataStart())
        const response = await axios.put(url,body,{headers:{
            "Authorization":`Bearer ${payload.token}`
        }})
        const fetchedLoadsResponse = response.data.success;
        if(!payload.filter){
                fetchedLoadsResponse && swal({
                title: "Graph Edited successfully!",
                icon: "success",
                buttons: {
                    confirm: 'Ok'
                     }
                })
        }
        dispatch(fetchChartDataFail())
        return fetchedLoadsResponse
    }
}

export const getAllChartsSD = (payload) => {
    const url = 'state_dashboard_user_access_iot'
    return dispatch => {
        dispatch (fetchChartMetaDataStart());
        axios.get(url,{headers:{
            "Authorization":`Bearer ${payload.token}`
        }})
        .then(function (response) {
            if(response){
               dispatch(fetchChartMetaDataSuccess(response.data))
            }
              
        }, (err) => {
            // swal({icon : "error" , title : err.response.data.error[0]["message"]})    
        })
        .catch(error => {
            // dispatch(fetchPropertyListingFail(error));
        })
    }
}

export const getAllChartsDefaultSD = (payload) => {
    const url = `/state_dashboard_user_access_iot/${payload.ulb}`
    return async dispatch => {
        dispatch (fetchChartMetaDataStart());   
        try{
            const response = await axios.get(url,{headers:{
                "Authorization":`Bearer ${payload.token}`
            }})
            const fetchdata = response.data.data
            dispatch(fetchChartMetaDataSuccess(response.data))
            if(fetchdata.length == 0){
                swal({icon : "error" , title : "Data not found!"})
            }
            return fetchdata
        }
        catch(err) {

        } 
       
    }
}
