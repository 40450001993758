import * as actionTypes from '../../actions/actionsTypes'
import { updateObject } from '../../utility'

export const initialState = {
    truckHedaers : [],
    loading : false
}
export const fetchTruckHeadersStart = ( state, action ) =>
{
    return updateObject(state, {
        loading:true
    })
}
export const fetchTruckHeadersSuccess = ( state, action ) =>
{
    return updateObject(state, {
        truckHedaers : action.truckHeaders,
        loading : false
    })
}
export const fetchTruckHeadersFail = ( state, action ) =>
{
    return updateObject(state, {
        loading : false
    })
}
const truckHeadersReducer = ( state = initialState, action) =>
{
    switch(action.type)
    {
        case actionTypes.FETCH_TRUCK_HEADERS_START : return fetchTruckHeadersStart(state, action)
        case actionTypes.FETCH_TRUCK_HEADERS_SUCCESS : return fetchTruckHeadersSuccess( state, action)
        case actionTypes.FETCH_TRUCK_HEADERS_FAIL : return fetchTruckHeadersFail( state, action)
        default : return state
    }
}
export default truckHeadersReducer