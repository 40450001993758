import * as actionTypes from '../../actions/actionsTypes';
import { updateObject } from '../../utility';

const initialState = {
    AreaHeaders: [],
    loading: false
}

const fetchAreaHeadersStart = (state, action) => {
    return updateObject(state, {
        loading: true,
    });
}

const fetchAreaHeadersSuccess = (state, action) => {
    return updateObject(state, {
        AreaHeaders: action.AreaHeaders,
        loading: false,
    });
}

const fetchAreaHeadersFail = (state, action) => {
    return updateObject(state, {
        loading: false,
    })
}

const AreaHeaderReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_PROPERTYHEADERS_START: return fetchAreaHeadersStart(state, action);
        case actionTypes.FETCH_PROPERTYHEADERS_SUCCESS: return fetchAreaHeadersSuccess(state, action);
        case actionTypes.FETCH_PROPERTYHEADERS_FAIL: return fetchAreaHeadersFail(state, action);
        default: return state;
    }
}

export default AreaHeaderReducer;