import React, { Component } from 'react';
import { connect } from 'react-redux'
import * as actionsCreators from '../../store/actions/index';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { Grid, MenuItem, Typography } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import axios from '../../http-config'
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
// import FormControl from '@material-ui/core/FormControl';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import swal from 'sweetalert';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        height:"28px",
        borderRadius: 4,
        position: 'relative',
        backgroundColor: "#fff",
        border: '1px solid #4CAF50',
        fontSize: 15,
        padding: '7px 26px 7px 20px',
        transition: theme.transitions.create(['border-color']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            'Open Sans',
            'sans-serif',

        ].join(','),
        '&:focus ': {
            borderRadius: 4,
            backgroundColor: "#fff !important"

        }
    },
}))(InputBase);

const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#3dab35',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#3dab35',
        },
    },
});

// Inspired by blueprintjs
function StyledRadio(props) {
    const classes = useStyles();

    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
        />
    );
}


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}
class InspectorFrom extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: !!this.props && !!this.props.id ? this.props.id : '',
            ulb: this.props ? this.props.ULBId : false,
            ulbOptions: [],
            SIName: this.props.userName ? this.props.userName : "",
            email: this.props.email ? this.props.email : "",
            SINameErr: "",
            ulbErr: "",
            role: this.props.roleId ? this.props.roleId : "",
            password: "",
            passwordError: "",
            isPasswordShown: false,
            zoneSelection: "",
            zonesOptions: [],
            checkValue: false,
            selected: [],
            wardsOptions: [],
            wardSelected: [],
            wardCheckValue: false,
            openWard: false,
            selectedZoneId: "",
            wardValue: [],
            wardSelect: "",
            zone: "",
            SI_zone_ward_assign: [],
            ZoneName_tn: "",
            zoneId: "",
            designation: this.props.designation ? this.props.designation : "",
            ulbData: [],
            ulbId: localStorage.getItem("ulbid"),
            ConfigurableDayUser:this.props.configurableDay ? this.props.configurableDay : "",
            firstName: this.props.firstName ? this.props.firstName : "",
            lastName: this.props.lastName ? this.props.lastName : ""
        }
    }
    componentDidMount() {
        axios({
            method: 'get',
            url: '/load_template/master_data',
            headers: { 'Authorization': `Bearer ${this.props.token}` }
        })
            .then((response) => {
                this.setState({ ulbOptions: response.data.data['master-data'][3].data })
            })

        if (localStorage.getItem("isZoneNotRequired") === "true") {
            axios({
                method: 'get',
                url: `/ward`,
                headers: { 'Authorization': `Bearer ${this.props.token}` }
            })
                .then((response) => {
                    this.setState({ wardsOptions: response.data.data.wards })
                })
        }
        if (localStorage.getItem("isZoneNotRequired") === "false") {
            axios({
                method: 'get',
                url: `/zone/getZones`,
                headers: { 'Authorization': `Bearer ${this.props.token}` }
            })
                .then((response) => {
                    this.setState({ zonesOptions: response.data.data.zones })
                })
        }

        if (this.state.id != "") {
            axios({
                method: 'get',
                url: `/user/SI_zone_ward_assign?userId=${this.state.id}`,
                headers: { 'Authorization': `Bearer ${this.props.token}` }
            })
                .then((response) => {
                    this.setState({ SI_zone_ward_assign: response.data.data.zoneWardAssignData })
                })
        }


        if (this.state.ulbId !== null) {
            const url = `/ulb/${this.state.ulbId}`
            axios({
                method: 'get',
                url: url,
                headers: { 'Authorization': `Bearer ${this.props.token}` }
            })
                .then((response) => {
                    this.setState({ ulbData: [response.data.data.ulbs[0]] })
                    localStorage.setItem('ReportsEmail', response.data.data.ulbs[0].email)
                })
                .catch((err) => {

                })
        }
    }
    handleChange = (event) => {
        if (event.target.name == "zone") {
            // if(checkingAssignZoneWard == undefined){

            axios({
                method: 'get',
                url: localStorage.getItem("isZoneNotRequired") === "true" ? `/ward` : `/ward?zoneId=${event.target.value.Id}`,
                headers: { 'Authorization': `Bearer ${this.props.token}` }
            })
                .then((response) => {
                    this.setState({ wardsOptions: response.data.data.wards, ZoneName_tn: event.target.value.Name_tn, zoneId: event.target.value.Id })
                }).catch((err) => {
                    console.log(err, "errr");
                })
            // }else{
            // this.setState({wardsOptions:[]})
            // swal({icon:"warning", title:`Already assinged ${checkingAssignZoneWard.zoneName}.`})
            // }

            // const checkingAssignZoneWard =   this.state.SI_zone_ward_assign.find((el)=>JSON.parse(el.siWardid).find((data)=>data.wardId)); 
        }
        this.setState({
            [event.target.name]: event.target.value,
        })

    }
    handleSubmit = (e) => {
        e.preventDefault()
        const err = this.checkValidity()
        if (!err) {
            const formdata = {
                userName: this.state.SIName,
                firstName:this.state.firstName,
                lastName:this.state.lastName,
                email: this.state.email,
                ulbId: this.state.ulb,
                roleId: this.state.role,
                id: this.state.id,
                token: this.props.token,
                password: this.state.password,
                zoneId: this.state.zoneId,
                wardId: this.state.selected,
                ZoneName_tn: this.state.ZoneName_tn,
                Designation: this.state.designation,
                ConfigurableDay:this.state.ConfigurableDayUser
            }
            this.props.handleSubmit(formdata)
        }


    }

    toggleVisibility = () => {
        const { isPasswordShown } = this.state
        this.setState({ isPasswordShown: !isPasswordShown })
    }
    checkValidity = () => {
        let isError = false
        if (!this.state.SIName) {
            isError = true
            this.setState({ SINameErr: '*This field is required' })
        } else {
            this.setState({ SINameErr: '' })

        }

        if (!this.state.ulb) {
            isError = true
            this.setState({ ulbErr: '*This field is required' })
        } else {
            this.setState({ ulbErr: '' })

        }



        if (!this.state.password) {
            isError = true;
            this.setState({ passwordError: "*Password cannot be blank" })
        }
        else if (this.state.password.length > 0 && this.state.password.length < 8) {
            isError = true;
            this.setState({ passwordError: "*Password Should be atleast 8 characters long" })
        }
        else if (this.state.password.length > 7 && this.state.password.search(/[0-9]/) == -1) {
            isError = true;
            this.setState({ passwordError: "*Password must contain atleast 1 Number" })
        }
        else if (this.state.password.length > 7 && this.state.password.search(/[a-z]/) == -1) {
            isError = true;
            this.setState({ passwordError: "*Password must contain atleast 1 Lowercase" })
        }
        else if (this.state.password.length > 7 && this.state.password.search(/[A-Z]/) == -1) {
            isError = true;
            this.setState({ passwordError: "*Password must contain atleast 1 Uppercase" })
        }
        else if (this.state.password.length > 7 && this.state.password.search(/[!/@/#/%/^/&/*/(/)/_/-/+/=/</>/,/./|/]/) == -1) {
            isError = true;
            this.setState({ passwordError: "*Password must contain atleast 1 Special Symbol" })
        }
        else {
            this.setState({ passwordError: "" })
        }
        return isError
    }

    handleCheckboxClick = (event, id) => {
        event.stopPropagation();
        const checkingAssignZoneWard = this.state.SI_zone_ward_assign.find((el) => JSON.parse(el.siWardid).find((data) => data.wardId === id.wardId));
        if (checkingAssignZoneWard == undefined) {
            const statsvalue = event.target.value
            if (event.target.checked == true) {
                this.setState({ checkValue: true })
            }
            else {
                this.setState({ checkValue: false })
            }

            const { selected } = this.state;
            const selectedIndex = selected.indexOf(id);
            let newSelected = [];
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selected, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selected.slice(1));
            } else if (selectedIndex === selected.length - 1) {
                newSelected = newSelected.concat(selected.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selected.slice(0, selectedIndex),
                    selected.slice(selectedIndex + 1)
                );
            }
            this.setState({ selected: newSelected, });


        }
        else {
            const wardData = JSON.parse(checkingAssignZoneWard.siWardid)
            const wardNumber = wardData.find((data) => data.wardId == id.wardId)
            swal({ icon: "warning", title: `Already assinged Zone: ${checkingAssignZoneWard.zoneName}& ward:${wardNumber.wardId}.` })
            this.setState({ selected: [], checkValue: false });
        }
    };
  
    handleDayChangeUser=(e)=>{
        this.setState ({
          ConfigurableDayUser: e.target.value,
          dayChangedUser:true
      })
      }
    render() {
        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;
        const MenuProps = {
            PaperProps: {
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    width: 250,
                },
            },
        };
        return (
            <div>
                <div className="create_ulb_content_outer">
                    <div className="create_ulb_content">
                        <Grid container spacing={1}>
                        <Grid item xs={6}>
                                            <FormControl>
                                                <label className="required" htmlFor="firstName">First Name</label>
                                                <TextField id="firstName"
                                                    variant="outlined"
                                                    placeholder="Enter First Name"
                                                    name="firstName"
                                                    value={this.state.firstName}
                                                    onChange={this.handleChange} />
                                                <div style={{ fontSize: 12, color: "red", fontFamily: "'Open Sans', sans-serif", marginTop: "1%" }}>{this.state.firstNameErr}</div>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl>
                                                <label className="required" htmlFor="lastName">Last Name</label>
                                                <TextField id="lastName"
                                                    variant="outlined"
                                                    placeholder="Enter Last Name"
                                                    name="lastName"
                                                    value={this.state.lastName}
                                                    onChange={this.handleChange} />
                                                <div style={{ fontSize: 12, color: "red", fontFamily: "'Open Sans', sans-serif", marginTop: "1%" }}>{this.state.lastNameErr}</div>
                                            </FormControl>
                                        </Grid>
                            <Grid item xs={6}>
                                <FormControl>
                                    <label className="required" htmlFor="SIName">User Name</label>
                                    <TextField id="SIName"
                                        variant="outlined"
                                        placeholder="Enter Cluster ULB Name"
                                        name="SIName"
                                        value={this.state.SIName}
                                        onChange={this.handleChange} />
                                    <div style={{ fontSize: 12, color: "red", fontFamily: "'Open Sans', sans-serif", marginTop: "1%" }}>{this.state.SINameErr}</div>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl>
                                    <label htmlFor="email">Email</label>
                                    <TextField id="email"
                                        variant="outlined"
                                        placeholder="Enter Email"
                                        name="email"
                                        value={this.state.email}
                                        onChange={this.handleChange} />
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl>
                                    <label htmlFor="Designation">Designation</label>
                                    <TextField id="Designation"
                                        variant="outlined"
                                        placeholder="Enter designation"
                                        name="designation"
                                        value={this.state.designation}
                                        onChange={this.handleChange} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl>
                                    <label className="required" htmlFor="ulb">ULB</label>
                                    <Select value={this.state.ulb}
                                        onChange={this.handleChange}
                                        name="ulb"
                                        style={{ width: '99%' }}>
                                        {this.state.ulbOptions.map((ulb) => (
                                            <MenuItem value={ulb.id}>{ulb.name}</MenuItem>
                                        ))}
                                    </Select>
                                    <div style={{ fontSize: 12, color: "red", fontFamily: "'Open Sans', sans-serif", marginTop: "1%" }}>{this.state.ulbErr}</div>
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl>
                                    <label >Password</label>
                                    <OutlinedInput
                                        onChange={(event) => this.handleChange(event)}
                                        id="standard-adornment-password"
                                        type={this.state.isPasswordShown ? "text" : "password"}
                                        placeholder="Enter your password"
                                        name="password"
                                        endAdornment={this.state.isPasswordShown ?
                                            (<InputAdornment position="end"><VisibilityOffOutlinedIcon onClick={() => { this.toggleVisibility() }} /></InputAdornment>) :
                                            (<InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => { this.toggleVisibility() }}>
                                                    <img src="images/eye.svg" />
                                                </IconButton> </InputAdornment>)
                                        }
                                        errortext={this.state.passwordError}
                                    />
                                    <div style={{ fontSize: 12, color: "red" }}>{this.state.passwordError}</div>
                                </FormControl>

                            </Grid>
                            <Grid item xs={6}>
                                <div >
                                    <FormControl style={{ color: '#4CAF50', width: "100%", marginRight: "9px" }}>
                                        <label className='required' style={{ fontSize: '13px' }}>Email Configurable Day</label>
                                        <NativeSelect
                                            value={this.state.ConfigurableDayUser}
                                            onChange={this.handleDayChangeUser}
                                            input={<BootstrapInput />}
                                            style={{ color: '#4CAF50', marginTop: '0px' }}>
                                            <option value="select" >Selcet...</option>
                                            <option value="Monday" >Monday</option>
                                            <option value="Tuesday">Tuesday</option >
                                            <option value="Wednesday">Wednesday</option >
                                            <option value="Thursday">Thursday</option >
                                            <option value="Friday">Friday</option >
                                            <option value="Saturday">Saturday</option>
                                            <option value="Sunday">Sunday</option >
                                        </NativeSelect>

                                    </FormControl>
                                </div>
                            </Grid>
                            {localStorage.getItem("isZoneNotRequired") === "false" ? <>
                                <Grid item xs={6}>
                                    <label >Zone Selection</label>
                                    <FormControl>
                                        <Select value={this.state.zone} onChange={this.handleChange} name="zone">
                                            {this.state.zonesOptions.map((option) => {
                                                return (
                                                    <MenuItem value={option}>{option.Name}</MenuItem>
                                                )
                                            })}
                                        </Select>

                                    </FormControl>
                                </Grid>
                                {this.state.zone != "" && this.state.wardsOptions.length > 0 ?
                                    <Grid item xs={6}>
                                        <FormControl>
                                            <label >Ward Selection</label>
                                            <div className="scrollPrent">
                                                <List>
                                                    <div className="hidescroll">
                                                        {this.state.wardsOptions.map((item) => {
                                                            return (
                                                                <>

                                                                    <ListItem>
                                                                        <FormControlLabel
                                                                            control={<Checkbox style={{ color: 'green', fontFamily: 'Open sans-serif', transform: 'scale(1.5)', width: 48 }}
                                                                                onClick={event => this.handleCheckboxClick(event, item, true)} name={item.wardId} />}
                                                                            label={item.wardNumber}
                                                                        />

                                                                    </ListItem>


                                                                </>
                                                            )
                                                        })}



                                                    </div>

                                                </List>



                                            </div>
                                        </FormControl>

                                    </Grid>
                                    : ""}
                            </> : localStorage.getItem("isZoneNotRequired") === "true" ? <>
                                <Grid item xs={6}>
                                    <FormControl>
                                        <label >Ward Selection</label>
                                        <div className="scrollPrent">
                                            <List>
                                                <div className="hidescroll">
                                                    {this.state.wardsOptions.map((item) => {
                                                        return (
                                                            <>

                                                                <ListItem>
                                                                    <FormControlLabel
                                                                        control={<Checkbox style={{ color: 'green', fontFamily: 'Open sans-serif', transform: 'scale(1.5)', width: 48 }}
                                                                            onClick={event => this.handleCheckboxClick(event, item, true)} name={item.wardId} />}
                                                                        label={item.wardNumber}
                                                                    />

                                                                </ListItem>
                                                            </>
                                                        )
                                                    })}
                                                </div>

                                            </List>
                                        </div>
                                    </FormControl>
                                </Grid>
                            </> : ""}


                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <div className="create_ulb_buttons">
                                    <Link style={{ textDecoration: "none", color: "#3dab35" }} to="/Sanitory-Manegement"><Button className="login_submit" variant="outlined" color="primary">Cancel</Button></Link>
                                    <Button className="login_submit" variant="contained" color="primary" onClick={this.handleSubmit}>Save</Button>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>


        );
    }
}

const mapStateToProps = (state, props) => {

    return {
        props: props,
        token: state.auth.token
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onAddDayData: (formdata, SubmitProp, props) => dispatch(actionsCreators.addDayData(formdata, SubmitProp, props))
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(InspectorFrom)


