import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {Link} from 'react-router-dom'
import Button from '@material-ui/core/Button';
import axios from '../../http-config';
import MenuItem from '@material-ui/core/MenuItem';
import { Grid } from '@material-ui/core';
import * as User  from '../../config/constant';


class PropertyForm extends Component{
    constructor(props){
        super(props)
        this.state={
            id                        : this.props.id ? this.props.id  : "",
            propertyId:                this.props.propertyId? this.props.propertyId :"",
            buildingName              : this.props.name          ? this.props.name         : "",
            address                   : this.props.address      ? this.props.address    : "",
            ownerName                 : this.props.ownerName     ? this.props.ownerName    : "",
            propType                  : this.props.propertyTypeId  ? this.props.propertyTypeId : "",
            area                      : this.props.areaId        ? this.props.areaId        : "",
            wardNo                    : this.props.wardId         ? this.props.wardId       : "",
            zone                      : this.props.zoneId          ? this.props.zoneId        : "",
            ulbName                   : this.props.ulbId           ? this.props.ulbId         : "",
            containmentType           : this.props.containmentTypeId ? this.props.containmentTypeId :"",
            buildingNameErr           : "",
            ownerNameErr              : "",
            propTypeErr               : "",
            areaErr                   : "",
            wardNoErr                 : "",
            zoneErr                   : "",
            ulbNameErr                : "",
            containmentTypeErr        : "",
            propertyOptions           : [],
            containmentOptions        : [],
            ulbOptions                : [],
            areaOptions               : [],
            wardOptions               : [],
            onUlb                     : false,
            onUlbName                 : '',
            isDisableProperty         : this.props.isDisableProperty==="Active"         ? false      : true,
            reason:this.props.reason? this.props.reason:'',
            reasonErr:'',
            ZoneOptions:[],
            roleName:localStorage.getItem("roleName")

        }
    }

    componentDidMount=()=>{
        let payload={
            token:this.props.token
        }
        axios({
            method  : 'get',
            url     : '/load_template/master_data',
            headers : {'Authorization': `Bearer ${payload.token}`}
        })
        .then((response) => {
            this.setState({ propertyOptions : response.data.data['master-data'][1].data,
                            ulbOptions : response.data.data['master-data'][3].data,
                            containmentOptions : response.data.data['master-data'][5].data,
                            // wardOptions : response.data.data['master-data'][3].data.find(data=>data.id==this.state.ulbName).children ?
                            // response.data.data['master-data'][3].data.find(data=>data.id==this.state.ulbName).children[0].data : [{id: -1, name: "Ward doesn't exists"}],
                            areaOptions : response.data.data['master-data'][4].data.find(data=>data.id==this.state.ulbName).children ?
                            response.data.data['master-data'][4].data.find(data=>data.id==this.state.ulbName).children[0].data : [],
                           
                        })
        })
        const UserIdSave = localStorage.getItem("UserIdSave")
        const roleName = localStorage.getItem("roleName")
        if(UserIdSave != ""&&roleName === User.SANITARYINSPECTOR){
            axios({
                method  : 'get',
                url     : `/user/SI_zone_ward_assign?userId=${UserIdSave}`,
                headers: {'Authorization': `Bearer ${payload.token}`}
            })
            .then((response) => {
                this.setState({           
                    ZoneOptions: response.data.data.zoneWardAssignData
            })
        })
        }else{
            axios({
                method  : 'get',
                url     : `/zone/getZones`,
                headers: {'Authorization': `Bearer ${payload.token}`}
            })
            .then((response) => {
                this.setState({           
                    ZoneOptions: response.data.data.zones
            })
        })
        }
      

        // if(this.state.wardNo != null){
        //     axios({
        //         method  : 'get',
        //         url     : `ward/${this.state.wardNo}`,
        //         headers : {'Authorization': `Bearer ${payload.token}`}
        //     })
        //     .then((res)=>{
        //         this.setState({ZoneOptions: res.data.data.wards})
        //     })
        //     .catch((err)=>{
        //     })
        // }
        if(this.state.zone != null){
                axios({
                    method  : 'get',

                    url     :UserIdSave&&roleName === User.SANITARYINSPECTOR? `/user/SI_zone_ward_assign?ZoneId=${this.state.zone}`: `/ward?zoneId=${this.state.zone}`,
                    headers : {'Authorization': `Bearer ${payload.token}`}
                })
                .then((res)=>{
                    this.setState({wardOptions: UserIdSave&&roleName === User.SANITARYINSPECTOR? JSON.parse(res.data.data.zoneWardAssignData[0].siWardid) :res.data.data.wards})
                })
                .catch((err)=>{
                    console.log(err)
                })
            }
    
}
    handleChange = (event) =>{
        const UserIdSave = localStorage.getItem("UserIdSave")
        const roleName = localStorage.getItem("roleName")
        if(event.target.name=="zone"){
            let payload={
                token:this.props.token
            }
            axios({
                method  : 'get',
                url     :UserIdSave&&roleName === User.SANITARYINSPECTOR? `/user/SI_zone_ward_assign?ZoneId=${event.target.value}`: `/ward?zoneId=${event.target.value}`,
                headers : {'Authorization': `Bearer ${payload.token}`}
            })
            .then((res)=>{
                this.setState({wardOptions: UserIdSave&&roleName === User.SANITARYINSPECTOR?JSON.parse(res.data.data.zoneWardAssignData[0].siWardid) :res.data.data.wards})
            })
            .catch((err)=>{
                console.log(err)
            })
        }


        if(event.target.name=="ulbName"){
            this.setState({[event.target.name]:event.target.value})
            this.state.UlbName=event.target.value
            let payload={
                token:this.props.token
            }
            axios({
                method  : 'get',
                url     : '/load_template/master_data',
                headers: {'Authorization': `Bearer ${payload.token}`}
            })
            .then((response) => {
                this.setState({
                    //  wardOptions : response.data.data['master-data'][3].data.find(data=>data.id==this.state.ulbName).children ?
                    //  response.data.data['master-data'][3].data.find(data=>data.id==this.state.ulbName).children[0].data : [{id: -1, name: "Ward doesn't exists"}],
                     areaOptions : response.data.data['master-data'][4].data.find(data=>data.id==this.state.ulbName).children ?
                             response.data.data['master-data'][4].data.find(data=>data.id==this.state.ulbName).children[0].data : [],
                             
                            
            })
        })
        axios({
            method  : 'get',
            url     :UserIdSave&&roleName === User.SANITARYINSPECTOR?`/user/SI_zone_ward_assign?userId=${UserIdSave}`:  `/zone/getZones`,
            headers: {'Authorization': `Bearer ${payload.token}`}
        })
        .then((response) => {
            this.setState({           
                ZoneOptions: UserIdSave&&roleName === User.SANITARYINSPECTOR?response.data.data.zoneWardAssignData: response.data.data.zones
        })
    })

    }else{
        this.setState({[event.target.name]:event.target.value},() => {
            this.checkValidity()
        })
    }       
    }
    setStartDate =(date)=>{
        this.setState({lastDesludgeDate:date}
        //     ,()=> {
        //     this.checkValidity()
        // }
        )
    }
    handleSubmit = (e) =>{
        e.preventDefault()
        const err = this.checkValidity()
        if(!err)
        {
         
            let props=this.props
            const formdata= {
                token                 : this.props.token,
                id                    : this.state.propertyId,
                name                  : this.state.buildingName,
                address               :this.state.address,
                ownerName             : this.state.ownerName,
                propertyTypeId        : this.state.propType,
                containmentTypeId     : this.state.containmentType,
                ulb                   : this.state.ulbName,
                zone                  : this.state.zone,
                ward                  : this.state.wardNo,
                area                  : this.state.area,
                lastDesludgeDate      : this.state.lastDesludgeDate,
                noLoadsPerProperty    : this.state.noLoadsPerProperty,
                disableProperty       : this.state.isDisableProperty,
                propertyRemarks       : this.state.reason

            }
            this.props.handleSubmit(formdata,props)
        // }
        }
    } 

    checkValidity = () => {
        let isError = false
        if(!this.state.containmentType){
            isError=true
            this.setState({containmentTypeErr : '*This field is required'})
        }
        else{
            // isError=false
            this.setState({containmentTypeErr:''})
        }
        if(!this.state.ownerName){
            isError=true
            this.setState({ownerNameErr:'*This field is required'})
        }
        else{
            // isError=false
            this.setState({ownerNameErr:''})
        }
        if(!this.state.propType){
            isError=true
            this.setState({propTypeErr:'*This field is required'})
        }
        else{
            // isError=false
            this.setState({propTypeErr:''})
        }
  
      
        if(!this.state.buildingName){
            isError=true
            this.setState({buildingNameErr : "*This field is required"})
        }
        else{
            // isError=false
            this.setState({buildingNameErr:''})
        }
       

        if(!this.state.ulbName){
            isError=true
            this.setState({ulbNameErr : "*This field is required"})
        }
        else{
            this.setState({ulbNameErr:''})
        }

        // if(!this.state.reason){
        //     isError=true
        //     this.setState({reasonErr : "*This field is required"})
        // }
        // else{
        //     this.setState({reasonErr:''})
        // }
        return isError
    }

    handleStatus= (e) => {
        this.setState ({
            isDisableProperty: e.target.value
        })
      }

    render(){
        return(
            <div>
   <Grid container spacing={1}>
<Grid item xs={6}>
<FormControl>
    {/* renamed Building / Apartment Name to Property Name*/}
    <label className="required" htmlFor="buildingName" align="left">Property Name</label>
    <TextField id="buildingName" 
                variant="outlined" 
                value={this.state.buildingName} 
                onChange={this.handleChange} 
                name="buildingName"   
                placeholder="Enter Property Name"/>
    <div style={{fontSize:12,color:"red"}}>{this.state.buildingNameErr}</div> 
</FormControl>
</Grid>

{/* Added  address Feild  */}
<Grid item xs={6}>
    <FormControl>
        <label className="not-required" htmlFor="address" align="left">Address</label>
        <TextField id="address"
            variant="outlined"
            value={this.state.address}
            onChange={this.handleChange}
            name="address"
            placeholder= "Enter Address" />
    </FormControl>
</Grid>
<Grid item xs={6}>
<FormControl>
        <label className="not-required" htmlFor="ownerName" align="left">Owner Name</label>
        <TextField id="ownerName" 
                    variant="outlined" 
                    value={this.state.ownerName} 
                    onChange={this.handleChange} 
                    name="ownerName"   
                    placeholder="Enter Owner Name"/>
        {/* <div style={{fontSize:12,color:"red"}}>{this.state.ownerNameErr}</div>  */}
    </FormControl>
</Grid>
<Grid item xs={6}>
<FormControl>
    <label className="required" htmlFor="propType" align="left">Property Type</label>
    <Select value={this.state.propType} onChange={this.handleChange} name="propType" placeholder="Select Property Type">
        {this.state.propertyOptions.map((option) => (
            <MenuItem value={option.id}>{option.name}</MenuItem>
        ))}
    </Select> 
    <div style={{fontSize:12,color:"red"}}>{this.state.propTypeErr}</div>   
</FormControl>
</Grid>
<Grid item xs={6}>
<FormControl>
        <label className="not-required" htmlFor="containmentType" align="left">Containment type</label>
        <Select value={this.state.containmentType} onChange={this.handleChange} name="containmentType">
                    {this.state.containmentOptions.map((option) => (
                        <MenuItem value={option.id}>{option.name}</MenuItem>
                    ))}
                </Select> 
                {/* <div style={{fontSize:12,color:"red"}}>{this.state.containmentTypeErr}</div>              */}
    </FormControl>
            </Grid>
            <Grid item xs={6}>
            <FormControl>
                {/* Renamed ULB Name to ULB / Cluster Name */}
                    <label className="required" htmlFor="ulbName" align="left">ULB / Cluster Name</label>  
                    <Select value={this.state.ulbName} onChange={this.handleChange} name="ulbName">
                                {this.state.ulbOptions.map((option) => (
                                    <MenuItem value={option.id}>{option.name}</MenuItem>
                                ))}
                            </Select> 

                        <div  style={{fontSize:12,color:"red"}}>{this.state.ulbNameErr}</div>   
                </FormControl>
            </Grid>
         
<Grid item xs={6}>
            {this.state.ulbName &&
                        <FormControl>
                <label htmlFor="zone" align="left">Zone Name</label>
                <Select value={this.state.zone} onChange={this.handleChange} name="zone">
                            {this.state.ZoneOptions.map((option) =>{ 
                               return(
                                <MenuItem value={option.Id}>{option.Name}</MenuItem>
                            )
                          
                            })}
                        </Select> 
             
            </FormControl>
            }
                </Grid>
                {
                    this.state.zone== ""?"":
                    <Grid item xs={6}>
                    
                    <FormControl>
                        <label htmlFor="wardNo" align="left">Ward Number</label>
                        <Select value={this.state.wardNo} onChange={this.handleChange} name="wardNo">
                            {this.state.wardOptions.map((option) =>{ 
                                return(
                                <MenuItem value={option.wardId}>{option.wardNumber}</MenuItem>
                            )})}
                        </Select> 
                    </FormControl>
                    
                        </Grid>
                }
                
                <Grid item xs={6}>
                {this.state.ulbName &&
                            <FormControl>
                                <label htmlFor="area" align="left">Area</label>
                                <Select value={this.state.area} onChange={this.handleChange} name="area">
                                            {this.state.areaOptions.map((option) => (
                                                <MenuItem value={option.id}>{option.name}</MenuItem>
                                            ))}
                                        </Select> 
                            </FormControl>
                            }
                
                </Grid>

                <Grid item xs={6}>
                            <FormControl>
                                <label htmlFor="isDisableProperty" align="left">Status</label>
                                <Select value={this.state.isDisableProperty} onChange={this.handleStatus} name="isDisableProperty">
                                                <MenuItem value={false} >{"Active"}</MenuItem>
                                                <MenuItem value={true}>{"InActive"}</MenuItem>
                                        </Select> 
                            </FormControl>
                </Grid>

                {this.state.isDisableProperty == true? <Grid item xs={6}>
                <FormControl>
                        <label htmlFor="reason" align="left">Reason</label>
                        <TextField id="reason" 
                                    variant="outlined" 
                                    value={this.state.reason} 
                                    onChange={this.handleChange} 
                                    name="reason"   
                                    placeholder="Enter your reason"/>
                        <div style={{fontSize:12,color:"red"}}>{this.state.reasonErr}</div> 
                    </FormControl>
                </Grid>:''}


        <Grid container spacing={3}>
        <Grid item xs={12}>
    <div className="create_ulb_buttons">
                    <Link style={{textDecoration:"none", color:"#3dab35"}} to={this.state.roleName === User.SANITARYINSPECTOR? '/':  "/property-management"}><Button className="login_submit" variant="outlined" color="primary">Cancel</Button></Link>
                    <Button className="login_submit" variant="contained" color="primary" onClick={this.handleSubmit}>Save</Button>
                </div>
            </Grid>
        </Grid>
           
           
            
          
            {/* {this.state.onUlb &&
            <FormControl>
                <label className="required" htmlFor="zone" align="left">Zone</label>
                <TextField id="zone" 
                           variant="outlined" 
                           value={this.state.zone} 
                           onChange={this.handleChange} 
                           name="zone"   
                           placeholder="Enter Zone"/>
                <div style={{fontSize:12,color:"red"}}>{this.state.zoneErr}</div> 
            </FormControl>
            } */}
           
            
   </Grid>
        
          
            </div>
        )
    }
}
const mapStateToProps =(state) => {
    return {
        token: state.auth.token,
    }
}

export default  connect(mapStateToProps)(PropertyForm);