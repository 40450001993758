
import * as actionTypes from '../../actions/actionsTypes';
import { updateObject } from '../../utility';

const initialState = {
    propertyListing: [],
    operatorListing:[],
    totalCount:'',
    loading: false,
    zones:[],
    wards:[],
    propertySummary:[],
    status:[],
    loadStatusCount:0,
    propertyTypeList:[],
    ListPropertyTypes:[],
    summaryLoading: false,
    historyList :[],
    totalCountHistorty : 0,
    loadingSuccess:false,
    propertyListLoad :[],
    loadingLoad:false,
    progress:0,
    loadingbulk: false,
    // totalCountLoads:0,
    successMatrix:false,
    matrixData:[],
    totalMatrix:0
}

const fetchPropertyListingStart = (state, action) => {
    return updateObject(state, {
        loadingSuccess: action.success,
    });
}

const fetchOperatorListingStart = (state, action) => {
    return updateObject(state, {
        loading: true,
    });
}

const fetchPropertyListingSuccess = (state, action) => {
    return updateObject(state, {
        propertyListing:action.propertyListing,
        totalCount:action.totalCount,
        propertyTypeList:action.propertyTypeList,
        loadingSuccess: action.success,
    });
}

const fetchOperatorDaySuccess = (state, action) => {
    return updateObject(state, {
        operatorDay:action.operatorDay,
        loading: false,
    });
}

const fetchPropertyTypeListingSuccess = (state, action) => {
    return updateObject(state, {
        ListPropertyTypes: action.ListPropertyTypes
    });
}
const fetchOperatorListingSuccess = (state, action) => {
    return updateObject(state, {
        operatorListing:action.operatorListing,
        loading: false,
    });
}
const fetchPropertyListingFail = (state, action) => {
    return updateObject(state, {
        loadingSuccess: action.success,
    })
}
const fetchOperatorListingFail = (state, action) => {
    return updateObject(state, {
        loading: false,
    })
}

const fetchSchedulePropertyZoneSucces = (state, action)=>{
    return updateObject(state, {
        zones:action.scheduleProperty,
        loading:false
    })
}

const fetchSchedulePropertyWardSucces = (state, action)=>{
    return updateObject(state, {
        wards:action.scheduleProperty,
        loading:false
    })
}

const fetchSummaryStart = (state, action) => {
    return updateObject(state, {
        loading: true,
    });
}

const fetchSummarySuccess = (state, action) => {
    return updateObject(state, {
        propertySummary:action.propertySummary,
        loadStatusCount:action.totalCount,
        summaryLoading:action.loading

    });
}

const fetchSummaryFail = (state, action) => {
    return updateObject(state, {
        summaryLoading: action.loading,
    })
}

const fetchStatusStart = (state, action) => {
    return updateObject(state, {
        summaryLoading: action.loading,
    });
}

const fetchStatusSuccess = (state, action) => {
    return updateObject(state, {
        status:action.status,
    });
}
const fetchHistoryStart = (state, action) => {
    return updateObject(state, {
        loading: action.loading,
    });
}
const fetchPropertyListingLoadsStart = (state, action) => {
    return updateObject(state, {
        loadingSuccess: action.successLoad,
    });
}
const fetchPropertyListingLoadsSuccess =(state, action) =>{
return updateObject(state, {
    propertyListLoad: action.propertyListingLoad,
    loadingSuccess: action.successLoad,
    totalCount:action.totalCountLoads
});
}
const fetchPropertyListingLoadsFail = (state, action) => {
    return updateObject(state, {
        loadingSuccess: action.successLoad,
    })
}
const fetchHistorySuccess = (state, action) => {
    return updateObject(state, {
        historyList : action.HistoryProperty,
        loading: action.loading,
        totalCountHistorty:action.totalCountHistorty,

    });
}
const fetchHistoryFail = (state,action)=>{
    return updateObject(state, {
        loading: false,

    });
}
const fetchStatusFail = (state, action) => {
    return updateObject(state, {
        loading: false,
    })
}
const getProgressPercentage = (val) => {
    let percent = 1;
    for(let i=0; i<val; i++){
        percent = percent + 1
    }
    return percent 
}


 const fetchPropertyBulkStart  = (state, action) => {
    return updateObject(state, {
        progress : getProgressPercentage(99),
        loadingbulk: action.success,
    })
}
 
 const fetchPropertyBulkSuccess = (state, action) => {
    return updateObject(state, {
        loadingbulk: action.success,
        progress:100
    });
}

 const fetchPropertyBulkFail = (state, action) => {
    return updateObject(state, {
        loadingbulk: action.success,
        progress:action.progress+=0
    })
}


const fetchMatrixStart  = (state, action) => {
    return updateObject(state, {
        successMatrix:false
    })
}
 
 const fetchMatrixSuccess = (state, action) => {
    return updateObject(state, {
        matrixData :action.Data,
        totalMatrix:action.totalCount,
        successMatrix:true
    });
}

 const fetchMatrixFail = (state, action) => {
    return updateObject(state, {
        Error:action.error,
        successMatrix:false
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_PROPERTYLISTING_START: return fetchPropertyListingStart(state, action);
        case actionTypes.FETCH_OPERATORLISTING_START: return fetchOperatorListingStart(state, action);
        case actionTypes.FETCH_PROPERTYLISTING_SUCCESS: return fetchPropertyListingSuccess(state, action);
        case actionTypes.FETCH_OPERATORDAY_SUCCESS: return fetchOperatorDaySuccess(state, action);
        case actionTypes.FETCH_PROPERTYTYPELISTING_SUCCESS : return fetchPropertyTypeListingSuccess(state,action)
        case actionTypes.FETCH_OPERATORLISTING_SUCCESS: return fetchOperatorListingSuccess(state, action);
        case actionTypes.FETCH_PROPERTYLISTING_FAIL: return fetchPropertyListingFail(state, action);
        case actionTypes.FETCH_OPERATORLISTING_FAIL: return fetchOperatorListingFail(state, action);
        case actionTypes.FETCH_SCHEDULEPROPERTYZONE_SUCCESS : return fetchSchedulePropertyZoneSucces(state, action);
        case actionTypes.FETCH_SCHEDULEPROPERTYWARD_SUCCESS : return fetchSchedulePropertyWardSucces(state, action)
        case actionTypes.FETCH_SUMMARY_START: return fetchSummaryStart(state, action);
        case actionTypes.FETCH_SUMMARY_SUCCESS : return fetchSummarySuccess(state, action);
        case actionTypes.FETCH_SUMMARY_FAIL : return fetchSummaryFail(state, action);
        case actionTypes.FETCH_STATUS_START: return fetchStatusStart(state, action);
        case actionTypes.FETCH_STATUS_SUCCESS : return fetchStatusSuccess(state, action);
        case actionTypes.FETCH_STATUS_FAIL : return fetchStatusFail(state, action)
        case actionTypes.FETCH_HISTORY_SUCCESS : return fetchHistorySuccess(state, action);
        case actionTypes.FETCH_HISTORY_FAIL : return fetchHistoryFail(state, action);
        case actionTypes.FETCH_HISTORY_START : return fetchHistoryStart(state,action);
        case actionTypes.FETCH_PROPERTYLISTINGLOAD_SUCCESS :return fetchPropertyListingLoadsSuccess(state,action)
        case actionTypes.FETCH_PROPERTYLISTINGLOAD_START :return fetchPropertyListingLoadsStart(state,action)
        case actionTypes.FETCH_PROPERTYLISTINGLOAD_FAIL:return fetchPropertyListingLoadsFail(state,action)
        case actionTypes.FETCH_PROPERTYBULK_START :return fetchPropertyBulkStart( state,action)
        case actionTypes.FETCH_PROPERTYBULK_SUCCESS :return fetchPropertyBulkSuccess( state,action)
        case actionTypes.FETCH_PROPERTYBULK_FAIL :return fetchPropertyBulkFail( state,action)
        
        case actionTypes.FETCH_MATRIX_START :return fetchMatrixStart( state,action)
        case actionTypes.FETCH_MATRIX_SUCCESS :return fetchMatrixSuccess( state,action)
        case actionTypes.FETCH_MATRIX_FAIL :return fetchMatrixFail( state,action)
        
        default: return state;
    }
}

export default reducer;