import * as actionTypes from '../../actions/actionsTypes'
import { updateObject } from '../../utility'

const initialState = {
    userData  : [],
    loading   : false,
    error     : null,
    totalCount:0
}

export const fetchUserListingStart = (state, action) => {
    return updateObject(state, {
        loading  : action.loading
    })
}

export const fetchUserListingSuccess = (state, action) => {
    return updateObject(state, {
        userData  : action.userData,
        loading   :  action.loading,
        totalCount:action.totalCount
    })
}

export const fetchUserListingFail = (state, action) => {
    return updateObject(state, {
        error   : action.error,
        loading :  action.loading
    })
}

const userDataReducer = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.FETCH_USER_LISTING_START     : return fetchUserListingStart(state, action)
        case actionTypes.FETCH_USER_LISTING_SUCCESS   : return fetchUserListingSuccess(state, action)
        case actionTypes.FETCH_USER_LISTING_FAIL      : return fetchUserListingFail(state, action)
        default                                       : return state 
    } 
}

export default userDataReducer