import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import ReactExport from "react-export-excel";
import swal from 'sweetalert';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  container: {
    // maxHeight:'29.6vh',
    marginTop: '20px'
  }
});

export default function StickyHeadTable(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  let rowsData = []
  let columnsDataForAreaWise = []
  let rowsDataForAreaWise = []

  function createDataForTFWise(ULBName, Count, ULBType) {
    return { ULBName, Count, ULBType };
  }

  props.columnsDataUlb.forEach(function (obj) {
    let column = { label: obj, minWidth: 170, align: 'center' }
    columnsDataForAreaWise.push(column)
  })

  props.rows.forEach(function (obj) {
    let row = createDataForTFWise(obj.ULBName, obj.Count, obj.ULBType)
    rowsDataForAreaWise.push(row)
  })


  const columnsForAreaWise = columnsDataForAreaWise
  const rowsForAreaWise = rowsDataForAreaWise


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const camelCase = (str) => {
    return str.substring(0, 1).toUpperCase() + str.substring(1);
  };

  const filterColumns = (data) => {
    // Get column names
    if (data.length > 0) {
      const columns = Object.keys(data[0]);
      // Remove by key (firstname)
      const filterColsByKey = columns
      // OR use the below line instead of the above if you want to filter by index
      // columns.shift()
      return filterColsByKey // OR return columns
    }
    else {
      swal({
        icon: "warning",
        title: "Data not available"
      })
    }

  };
  return (
    <div className="dashboard_new_listing">

      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <div style={{ float: "right", marginRight: "15px" }}>
            <ExcelFile filename="ULB/Cluster'sWiseLoads" element={<button style={{ padding: "6px", color: "#3DAB35", border: "1px solid #3DAB35", cursor: "grabbing", borderRadius:"4px" }}>Export xlsx</button>}>
              <ExcelSheet data={!!rowsForAreaWise && rowsForAreaWise.length > 0 ? rowsForAreaWise : [{ "ULBName": "", "Count": "", "ULBType": "" }]} name="ULB/Cluster'sWiseLoads">
                {
                  filterColumns(!!rowsForAreaWise && rowsForAreaWise.length > 0 ? rowsForAreaWise : [{ "ULBName": "", "Count": "", "ULBType": "" }]).map((col) => {
                    return <ExcelColumn label={camelCase(col)} value={col} />
                  })
                }
              </ExcelSheet>
            </ExcelFile>
          </div>

          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columnsForAreaWise.map((column, i) => {
                  return (
                    <TableCell
                      key={i}
                      align={column.align}
                      style={{ minWidth: column.minWidth, fontWeight: 'bolder', fontSize: "12px" }}
                    >
                      {column.label}
                    </TableCell>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {
                rowsForAreaWise.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={rowIndex}>
                      {columnsForAreaWise.map((column, index) => {
                        let value = row[column.label];

                        return (
                          <TableCell key={index} align={column.align} style={{ fontSize: "12px" }}>
                            {column.format && typeof value === 'number' ? column.format(value) : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>

        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[2, 5, 10, 100]}
          component="div"
          count={rowsForAreaWise.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>


    </div>
  );
}
