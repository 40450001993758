import * as actionTypes from '../../actions/actionsTypes';
import { updateObject } from '../../utility';

const initialState = {
    headers: [],
    loading: false
}

const fetchWardHeadersStart = (state, action) => {
    return updateObject(state, {
        loading: true,
    });
}

const fetchWardHeadersSuccess = (state, action) => {
    return updateObject(state, {
        headers: action.headers,
        loading: false,
    });
}

const fetchWardHeadersFail = (state, action) => {
    return updateObject(state, {
        loading: false,
    })
}

const WardReducerHeader = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_WARD_HEADERS_START: return fetchWardHeadersStart(state, action);
        case actionTypes.FETCH_WARD_HEADERS_SUCCESS: return fetchWardHeadersSuccess(state, action);
        case actionTypes.FETCH_WARD_HEADERS_FAIL: return fetchWardHeadersFail(state, action);
        default: return state;
    }
}

export default WardReducerHeader;