import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import '../../Login/Login.css';

import Input from '../../../../components/Ui/Input/Input';

import Spinner from '../../../../components/Spinner/Spinner';

import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import * as actionCreators from '../../../../store/actions/index';


import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import Alert from '@material-ui/lab/Alert';
import swal from 'sweetalert';



class ResetPassword extends Component{
state = {
    resetFlag : false,
    value: "en",
    lang: "english",
    isPasswordShown: false,
    otpError:"",
    passwordError:"",
    controls: {
     
        otp:{
            elementType: 'input',
            elementConfig: {
                type: 'string',
                placeholder: 'Enter OTP Code'
            },
            value: '',
            validation: {
                required: true,
            },
            isValid: false,
            touched: false,
            label: 'otp'
        },
        password: {
            elementType: 'input',
            elementConfig: {
                type: 'password',
                placeholder: 'Enter new password'
            },
            value: '',
            validation: {
                required: true,
            },
            isValid: false,
            touched: false,
            label: 'password'
        }
    }
}
handleChange = (event) =>{
    this.setState({lang:event.target.value})
}
inputChangedHadler = (event, controlName) => {
    const updatedControls = {
        ...this.state.controls,
        [controlName]: {
            ...this.state.controls[controlName],
            value: event.target.value,
            touched: true
        }
    }
    this.setState({ controls: updatedControls },()=>
    {
        this.checkValidity()
    });
}


checkValidity(value, rules,form) {
     let isError=false;

    if(!this.state.controls.password.value){
        isError=true;
        this.setState({passwordError:"*Password cannot be blank"})
    }
    if(!this.state.controls.otp.value){
        isError=true;
        this.setState({otpError:"*Otp cannot be blank"})
    }
    if(this.state.controls.otp.value.length >0 && this.state.controls.otp.value.length<5)
    {
        isError=true;
        this.setState({otpError:"*Otp Should be 6 Characters long"})
    } 
    else if(this.state.controls.otp.value && this.state.controls.otp.value.length>=6)
    {
        this.setState({otpError:''})
    }  
    if(this.state.controls.password.value.length >0 && this.state.controls.password.value.length < 8 ) 
    {
        isError=true;
        this.setState({passwordError:"*Password Should be atleast 8 characters long"})
    }
    else if(this.state.controls.password.value.length>7 && this.state.controls.password.value.search(/[0-9]/)==-1)
    {
        isError=true;
        this.setState({passwordError:"*Password must contain atleast 1 Number"})
    }
    else if(this.state.controls.password.value.length>7 && this.state.controls.password.value.search(/[a-z]/)==-1)
    {
        isError=true;
        this.setState({passwordError:"*Password must contain atleast 1 Lowercase"})
    }
    else if(this.state.controls.password.value.length>7 && this.state.controls.password.value.search(/[A-Z]/)==-1)
    {
        isError=true;
        this.setState({passwordError:"*Password must contain atleast 1 Uppercase"})
    }
    else if(this.state.controls.password.value.length>7 && this.state.controls.password.value.search(/[!/@/#/%/^/&/*/(/)/_/-/+/=/</>/,/./|/]/)==-1)
    {
        isError=true;
        this.setState({passwordError:"*Password must contain atleast 1 Special Symbol"})
    }
    else if(this.state.controls.password.value)
    {
        this.setState({passwordError:''})
    }
     return isError
}


toggleVisibility=()=>{
    const{isPasswordShown}=this.state
    this.setState({isPasswordShown:!isPasswordShown})
}

resetHandler = (event) => {
    event.preventDefault();
    const err=this.checkValidity()
    if(!err){
        const otp = this.state.controls.otp.value;
        const password = this.state.controls.password.value;
        const email = localStorage.getItem('email');
        this.props.onResetSubmit(email, otp, password, this.props); 
        this.setState({resetFlag:true})
    }   
}

componentDidMount() { 
}

render() {
    let form =(
        <div className="login_page">
            <div className="login_left">
                <div className="login_left_bg">
                    <img src="images/login-bg.svg" alt="login-bg"/>
                </div>
                <p>Powered By Transerve Technologies Private Limited</p>
                <img className="login_top-left" src="images/top-left.svg" alt="login-top-left"/>
                <img className="login_top-right" src="images/top-right.svg" alt="login-top-right"/>
                <img className="login_bottom-left" src="images/bottom-left.svg" alt="login-bottom-left" />
                <img className="login_bottom-right" src="images/bottom-right.svg" alt="login-bottom-right"/>
            </div>
            <div className="login_right">
                <img className="fssm_logo" src="images/FSSM-Logo-Login.svg" alt="fssm-logo"/>
                <h5>Fecal Sludge & Septage Management</h5>
                <h4>Create Password</h4>
                <h3>We sent the OTP code to your email<br/>Please enter OTP below and set new password
                </h3>

                <form onSubmit={this.resetHandler}>
                    <div className="login_form">
                        <FormControl>
                        <label for="uname">OTP Code</label>
                        <OutlinedInput 
                        onChange={(event) => this.inputChangedHadler(event,'otp')} 
                        id="standard-basic" 
                        variant="outlined" 
                        placeholder="Enter OTP Code" 
                        name='otp' 
                        errorText={this.state.optError}/>
                        <div style={{fontSize:12,color:"red"}}>{this.state.otpError}</div>             
                        </FormControl>
                        <br/>
                        <br/>
                        <div className="form-group">
                        <FormControl>
                        <label for="uname">New Password</label> 
                        <OutlinedInput
                        onChange={(event) => this.inputChangedHadler(event,'password')}
                        id="standard-adornment-password"
                        type={this.state.isPasswordShown ? "text" : "password"}
                        placeholder="Enter new password"
                        endAdornment={this.state.isPasswordShown ?
                            (<InputAdornment position="end"><VisibilityOffOutlinedIcon onClick={()=>{this.toggleVisibility()}}/></InputAdornment>) :
                            (<InputAdornment position="end">
                                <IconButton
                                aria-label="toggle password visibility"
                                onClick={()=>{this.toggleVisibility()}}>
                                <img src="images/eye.svg" alt="toggle"/>
                            </IconButton> </InputAdornment> )    
                            }
                        errorText={this.state.passwordError}
                        />
                    <small style={{color:'#3DAB35'}}>8+&nbsp;Characters&emsp;1&nbsp;Lowercase&emsp;1&nbsp;Uppercase&emsp;1&nbsp;Number&emsp;1&nbsp;Symbol</small>
                    <div style={{fontSize:12,color:"red"}}>{this.state.passwordError}</div>
                        </FormControl>
                    </div>
                        <Button type="submit" className="login_submit" variant="contained" color="primary">Submit</Button>
                    </div>
                </form>
                {/* <FormControl component="fieldset" className="login_options">
                    <RadioGroup aria-label="language" name="language" value={this.state.lang} onChange={this.handleChange}>
                    <FormControlLabel value="english" control={<Radio />} label="English" />
                    <FormControlLabel value="tamil" control={<Radio />} label="தமிழ்" />
                    </RadioGroup>
                </FormControl> */}
                {/* <p>&copy; 2020 FSSM. All rights reserved. Cookie Preferences, Privacy and Terms.</p> */}
                <p>&copy; {new Date().getFullYear()} FSSM. All rights reserved.</p>

            </div>
        </div>
    ); 

    if (this.props.loading) {
        form = <Spinner />
    }

    let error = null;
    if(this.state.resetFlag)
    {
        if (this.props.error) {
        {
            error = (
                    <Alert severity="error">{this.props.error[0].message}</Alert>
                    )
        }    
    }}

return(
        <Container>
            <Row>
                <Col md={{ span: 6, offset: 3 }}>
                    {error}
                    {form}
                </Col>
            </Row>
        </Container>
    )
}
}

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onResetSubmit: (email, otp, password, props) => dispatch(actionCreators.resetPassword(email, otp, password, props)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ResetPassword);