import * as actionTypes from '../../actions/actionsTypes'
import { updateObject } from '../../utility'

export const initialState = {
    operatorData : [],
    statisticsData:[],
    loading : false,
    totalCount:0
}
export const fetchOperatorDataStart = (state, action) =>
{
    return updateObject(state, {loading :action.loading})
}
export const fetchOperatorDataSuccess = (state, action) =>
{
    return updateObject(state, {
        operatorData : action.operatorData,
        totalCount:action.totalCount,
        statisticsData : action.statisticsData,
        loading:action.loading
    })
}
export const fetchOperatorDataFail = (state, action) =>
{
    return updateObject(state, {
        loading : true
    })
}
const operatorDataReducer = (state=initialState, action) =>
{
    switch(action.type)
    {
        case actionTypes.FETCH_OPERATOR_DATA_START : return fetchOperatorDataStart(state, action)
        case actionTypes.FETCH_OPERATOR_DATA_SUCCESS : return fetchOperatorDataSuccess(state, action)
        case actionTypes.FETCH_OPERATOR_DATA_FAIL : return fetchOperatorDataFail(state, action)
        default : return state
    }
}
export default operatorDataReducer