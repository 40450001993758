import React from 'react';
import { connect } from 'react-redux';
import TreatmentFacilityForm from './TreatmentFacilityForm';
import * as actionsCreators from  '../../store/actions/index';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import axios from '../../http-config';

function TreatmentFacilityNew(props) {
   
   const handleSubmit=(formdata)=>{
       props.onAddTreatmentListing(formdata,props)
   }
        return(
        <div className="create_ulb">
            <div className="dashboard_top_path">
                <p><img src="images/left-arrow.svg" /> Disposal Facility Management / <span>Create Disposal Facility</span></p>
            </div>
            <div className="dashboard_right_content">
                <img className="dashboard_right_content_top_icon" src="images/Rectangle.svg" />
                <div className="dashboard_right_content_top">
                    <h5>Create Disposal Facility</h5>
                </div>
                <div className="create_ulb_content_outer">
                    <div className="create_ulb_content">
                        
                    <TreatmentFacilityForm handleSubmit={handleSubmit}/>
                       
                    </div>
                </div>
            </div>
        </div>
        )
 }
 const mapStateToProps = (state,props) => {
    return {
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onAddTreatmentListing: (formdata,props) => dispatch(actionsCreators.addTreatmentListing(formdata,props)),
    }
}
export default  connect(mapStateToProps, mapDispatchToProps)(TreatmentFacilityNew);

