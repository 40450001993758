import React, { Component, useEffect } from 'react'
import { connect } from 'react-redux'
import InputBase from '@material-ui/core/InputBase';
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import swal from 'sweetalert'
import moment from 'moment'
import ReactPaginate from 'react-paginate';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import * as actionsCreators from '../../store/actions/index'
import Clusterbulkupload from '../../Cluster_bulk_uploader.xlsx'
import {createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import LinearProgress, { LinearProgressProps } from '@material-ui/core/LinearProgress';
import { Dialog, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';  
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress style={{color:'#3dab35'}} variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }
  const theme = createMuiTheme({
    palette: {
       secondary: {
           main: '#3dab35'
       }
    }
  })

export class ClusterListing extends Component {
    state = {
        anchorEl: null,
        open: false,
        id: '',
        page: 1,
        limit: 10,
        selected: [],
        currentSortId:'',
        sortObj : {
            key: '',
            value: ''
        },
        controls: {
            search: {
                value: '',
            }
        },
        isDefaultValue :'',
        tfaIDvalue:'',
        anchorEl_bulk_property             : null,
        placement_property                : '',
        open_bulk_property                : false,
        progress    : 0,
        bulkUp: false,
  

            tfaId:  '',
            ClusterulbName: '',
            ClusterulbName_tm: '',
            typeId: '',
            ClusterulbNameErr: '',
            ulbnameErr: '',
            ulbname:  false,
            // TfNameErr: '',
            ulbOptions: [],
            tfOption: [],
            // TfName: this.props ? this.props.tfId : false,
            StatusValue: "",
            isStatus: null,
            AllSelectedUlb: [],
            AllremovedTf: [],
            tfList: [],
            selectedTable: [],
            AllSelectedTf: [],
            selectedTableTf: [],
            data:{},
            openClusterddPopup:false,

            //Edit cluster data
            editId:"",
            cClusterulbName:"",
            cClusterulbName_tm:"",
            CisDisableULB:"",
            ctfaId :"",
            ctypeId :"",
            cTFId:"",
            TfName:""
            

 
    }
    componentDidMount() {
        const payload = {
            token: this.props.token,
            page: this.state.page,
            limit: this.state.limit,
            sortName:this.state.sortObj.key,
            sortValue :this.state.sortObj.value,
            searchQuery: this.state.controls.search.value
        }
        this.props.onFetchClusterHeaders(payload)
        this.props.onFetchClusterData(payload)
    }
    handleClickMoreVertIcon = (event, id, isDefaultValue, tfaIDvalue,cClusterulbName,cClusterulbName_tm ,CisDisableULB,ctfaId,ctypeId, cTFId) => {
        this.setState({ open: !this.state.open, anchorEl: event.currentTarget,  id , isDefaultValue, tfaIDvalue,cClusterulbName,cClusterulbName_tm ,CisDisableULB, ctfaId,ctypeId, cTFId})
    }

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        this.setState({ page: selectedPage + 1 })
        let payload = {
            token: this.props.token,
            page: selectedPage + 1,
            limit: this.state.limit,
            sortName:this.state.sortObj.key,
            sortValue :this.state.sortObj.value,
            searchQuery: this.state.controls.search.value
        }
        this.props.onFetchClusterData(payload)
    };

    handleClickAwayEdit = () => {
        this.setState({ open: false });
    };
    handleDelete = (Cid, tfaIdvalue) => {
        const payload = {
            "tfaId": tfaIdvalue,
            "clusterUlbId":Cid,

        }
        this.props.onDeleteUlbData(payload)
    }
    onFileUploadProperty = () => { 
        let props= this.props
        const payload = {
            token : this.props.token
        }
        const formData = new FormData(); 
        formData.append( 
            "Clusterbulkupload", 
            this.state.selectedFile, 
            this.state.selectedFile.name 
        )
        this.props.OnbulkCluster(payload,formData,props);
        this.setState({open_bulk_property:false})
    }
    onFileChangeProperty = (event) => {
        this.setState({ selectedFile: event.target.files[0],bulkUp: true},()=>{
            this.onFileUploadProperty()
        });   
    }
    componentWillReceiveProps(nextProps){
        this.setState({progress:nextProps.progressPercentage})
    }
    handleSort = (e,sortBy) => {
        let sortObj
        if (this.state.sortObj.key === sortBy.accessor) {
            let value = (this.state.sortObj.value === 'desc') ? 'asc' : 'desc';
            sortObj = {
                key : sortBy.accessor,
                value: value
            }
        } 
        else {
            sortObj = {
                key : sortBy.accessor,
                value: 'desc'
         }    
         }
 
        this.setState({ sortObj: sortObj ,currentSortId:sortBy.accessor});
        let payload = {
            token: this.props.token,
            sortName:sortObj.key,
         sortValue : sortObj.value,
         page: this.state.page,
         limit: this.state.limit,
         searchQuery: this.state.controls.search.value
        }

        this.props.onFetchClusterData(payload);
  
      }


      handlePropertySearch = (e) => {
        e.preventDefault()
        let searchVal = this.state.controls.search.value
        if (!!searchVal && searchVal.length >= 2) {
            let payload = {
                token: this.props.token,
                searchQuery:searchVal,
                limit:10,
                page:this.state.page,
                // propertyTypeId: this.state.filterValue,
                sortName:this.state.sortObj.key,
                sortValue :this.state.sortObj.value,
              
            }
            this.props.OnSearchClusterName(payload) 
        } else {
         
            swal("Enter atleast 2 characters")
        }
    }
    inputChangedHadler = (event, controlName) => {
        const updatedControls = {
            ...this.state.controls,
            [controlName]: {
                ...this.state.controls[controlName],
                value: event.target.value,
            }
        }
        this.setState({ controls: updatedControls });
        if( updatedControls.search.value === ''){
            let payload = {
                token: this.props.token,
                searchQuery:'',
                limit:this.state.limit,
                page:this.state.page,
                // propertyTypeId: this.state.filterValue,
                sortName:this.state.sortObj.key,
                sortValue :this.state.sortObj.value,
            }
            this.props.OnSearchClusterName(payload) 
        }
   
    }
    handleClickAwayBulkProperty = () => {
        this.setState({open_bulk_property: false});
    };
    handleClickProperty = (newPlacement) => (event) => {
        this.setState({ anchorEl_bulk_property:event.currentTarget, placement_property:newPlacement, open_bulk_property:!this.state.open_bulk_property});
    };
    handleCloseProperty = (event) => {
        this.setState({open_bulk_property:false})
    };
    handleExport = () => {
        let payload = {
            token: this.props.token,
            page: this.state.page,
            limit: this.state.limit,
            sortName:this.state.sortObj.key,
            sortValue :this.state.sortObj.value,
            searchQuery: this.state.controls.search.value
      
        }
        this.props.onFetchClusterULBListingExport(payload)
    }

    openClusterAddPopup =()=>{
        this.setState({
            openClusterddPopup:true,
            editId:'',
            tfaId:  '',
            typeId: '',
            ClusterulbName: '',
            ClusterulbName_tm: '',
            ClusterulbNameErr: '',
            StatusValue:'',
    })
   }
   openClusterEditPopup =(id,cClusterulbName,cClusterulbName_tm,CisDisableULB,ctfaId,ctypeId,tfid ) =>{
    this.setState({
        openClusterddPopup        :true,
        editId                  :id,
        ClusterulbName          : cClusterulbName,
        ClusterulbName_tm       : cClusterulbName_tm ,
        StatusValue             :CisDisableULB ? !!CisDisableULB && CisDisableULB === "In Active" ? true : false : '',
        tfaId                   :ctfaId,
        typeId                  :ctypeId,
        TfName                  :tfid


    })
   }

   handleClose=()=>{
    this.setState({
        openClusterddPopup:false,
        editId:'',
        ClusterulbName: '',
        ClusterulbName_tm: '',
        ClusterulbNameErr: '',
        StatusValue:'',
 })  
}

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })

    }
    handleSubmit =()=>{
        const {t, i18n} = this.props
        const err = this.checkValidity()
        let payload = {
            token: this.props.token,
            page: this.state.page,
            limit: this.state.limit,
            sortName:this.state.sortObj.key,
            sortValue :this.state.sortObj.value,
            searchQuery: this.state.controls.search.value,
            status: this.state.StatusType,
        }
        if(!err)
        {
            if(this.state.editId !=""){
                const formdata = new FormData()
                 formdata.append("id", this.state.id)
                formdata.append("typeId", this.state.typeId)
                formdata.append("tfaId", this.state.tfaId)
                formdata.append("tfId", this.state.TfName)
                formdata.append("name", this.state.ClusterulbName)
                formdata.append("name_tn", this.state.ClusterulbName_tm)
                formdata.append("isDisableULB", this.state.StatusValue)
                // let SubmitProp=this.props
                this.props.onEditClusterUlbData(formdata, this.props, payload)
                this.handleClose()
                }
            else{
                const formdata = new FormData()
                formdata.append("id", this.state.id)
                formdata.append("typeId", this.state.typeId)
                formdata.append("tfaId", this.state.tfaId)
                formdata.append("tfId", this.state.TfName)
                formdata.append("name", this.state.ClusterulbName)
                formdata.append("name_tn", this.state.ClusterulbName_tm)
                formdata.append("isDisableULB", this.state.StatusValue)
    
                // let SubmitProp = this.props
                this.props.onAddCUlbData(formdata, this.props, payload)
                this.handleClose()
                // this.setState({openClusterPopup:false})
            }       
        }
    }

    checkValidity = () => {
        let isError = false
        if (!this.state.ClusterulbName) {
            isError = true
            this.setState({ ClusterulbNameErr: '*This field is required' })
        }
        else if ((this.state.ClusterulbName.length > 0 && this.state.ClusterulbName.length < 3) || (this.state.ClusterulbName.length > 100)) {
            isError = true
            this.setState({ ClusterulbNameErr: '*Name should be Minimum 3 characters and Maximum 100 characters' })
        }
        else if (this.state.ClusterulbName && this.state.ClusterulbName.length >= 3) {
            this.setState({ ClusterulbNameErr: '' })
        }

      
        return isError
    }

    handlChangeStatus = (e) => {
        this.setState({ isStatus: e.target.value, StatusValue: e.target.value })
    }



    render(){
        let headerCluster = !!this.props.ClusterHeaders&&this.props.ClusterHeaders
        const cHeaders = !!headerCluster&& headerCluster.map(header => {
            return (
                <TableCell key={header.header} className={"white-space"}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'start',
                        whiteSpace: 'break-spaces',
                        height: '3em'
                    }}>
{header.header === "Action"? "":
                    <div onClick={(e) =>  this.handleSort(e,header)}>
                        <div>{
                            this.state.currentSortId===header.accessor? this.state.sortObj.value ==='asc'?(<ArrowDropDownIcon fontSize="small"/>):(<ArrowDropUpIcon fontSize="small"/>) :(<ArrowDropDownIcon fontSize="small"/>)
                        }
                        </div>  
                    </div>}
                        {header.header}
                    </div>
                </TableCell>
            )
        })

const ListingCluster = !!this.props.ClusterData &&this.props.ClusterData.map((data, index)=>{
    const id = data['clusterUlbId']
    const tfaIDvalue = data['tfaId']
    const isDefaultValue = data["isDeleteAble"]
    const cClusterulbName = data["clusterUlbName"]
    const cClusterulbName_tm = data["clusterUlbName_tn"]
    const CisDisableULB = data["isDisableULB"]
    const ctfaId =data["tfaId"]  
    const ctypeId = data ["typeId"]
    const cTFId = data ["tfId"]
    

    return (
        <TableRow key={index}
        >
            {!!headerCluster&&headerCluster.map(header => {
                    if (header.accessor == 'actionButton') {
                        return <TableCell key={header.accessor}>
                            <IconButton onClick={(e) => this.handleClickMoreVertIcon(e, id, isDefaultValue, tfaIDvalue,cClusterulbName,cClusterulbName_tm ,CisDisableULB, ctfaId, ctypeId, cTFId)}
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true">
                                <MoreVertIcon fontSize='small' />
                            </IconButton>
                            <Popper open={this.state.open} anchorEl={this.state.anchorEl}>
                                <ClickAwayListener onClickAway={this.handleClickAwayEdit}>
                                    <Paper style={{ boxShadow: "none", border: "1px solid rgb(61, 171, 53)" }}>
                                        <MenuList>
                                            {/* <Link className='email_link'> */}
                                                {/* <Link to={`/cluster-management/${ this.state.tfaIDvalue}`} className='email_link'><MenuItem>Edit</MenuItem></Link> */}
                                            {/* </Link> */}
                                            <MenuItem  className='email_link' onClick={()=>this.openClusterEditPopup(
                                                this.state.id,
                                                this.state.cClusterulbName, 
                                                this.state.cClusterulbName_tm, 
                                                this.state.CisDisableULB,
                                                this.state.ctfaId,
                                                this.state.ctypeId,
                                                this.state.cTFId

                                                )}>{("Edit")}</MenuItem>
                                            {this.state.isDefaultValue == true?   <MenuItem style={{ color: '#3dab35' }} 
                                            onClick={() => {
                                                swal({
                                                    title: "Are you sure?",
                                                    text: "Once deleted, you will not be able to recover this data!",
                                                    icon: "warning",
                                                    buttons: {
                                                        cancel: 'No',
                                                        confirm: 'Yes'
                                                    }
                                                })
                                                    .then((willDelete) => {
                                                        if (willDelete) {
                                                            this.handleDelete(this.state.id, this.state.tfaIDvalue);
                                                        }
                                                    });
                                            }}
                                            >Delete</MenuItem>:""}
                                           
                                        </MenuList>
                                    </Paper>
                                </ClickAwayListener>
                            </Popper>
                        </TableCell>
                    }
                 
                    else {
                        return <TableCell style={{
                            whiteSpace: "normal",
                            wordWrap: "break-word"
                        }} key={header.accessor} 
                             className={"white-space"}>
                            {data[header.accessor]}
                              </TableCell>
                    }
                })
            }
        </TableRow>
    )
})

        
        return (
            <div>
            <div className="dashboard_right_content" style={{marginTop:'-4%'}}>
                <img className="dashboard_right_content_top_icon" src="images/Rectangle.svg" />
                <div className="dashboard_right_content_top">
                    <h5>Cluster Management</h5>
                    <div className="dashboard_right_content_top_options">
                    <div className="dashboard_right_content_top_search">
                                <Paper component="form">
                                    <IconButton aria-label="menu">
                                        <img src="images/search_icon.svg" />
                                    </IconButton>
                                    <InputBase onChange={(event) => this.inputChangedHadler(event, 'search')} placeholder="Cluster Name" />
                                    <Button onClick={this.handlePropertySearch} variant="contained" color="primary" type="submit">Search</Button>
                                </Paper>
                            </div>
                        <Button onClick={this.openClusterAddPopup}>Add a New Cluster</Button>
                        <div>
                            <Button style={{width:'100%', marginLeft:'10px' }} onClick={this.handleExport}>Export</Button>
                            </div>

                        <Button className="email_link"
                                ref={this.state.anchorRef}
                                aria-controls={this.state.open ? 'menu-list-grow' : undefined}
                                aria-haspopup="true"
                                onClick={this.handleClickProperty('bottom')}
                                style={{height:'35px'}}>
                                Bulk Import Cluster
                                {
                                    this.state.open ?   <ArrowDropUpIcon /> : <ArrowDropDownIcon/> 
                                }
                                
                            </Button>
                            <Popper style={{zIndex:"10"}} open={this.state.open_bulk_property} anchorEl={this.state.anchorEl_bulk_property} placement={this.state.placement_property} transition>
                            {({ TransitionProps }) => (
                                    <ClickAwayListener onClickAway={this.handleClickAwayBulkProperty}>
                                <Paper style={{zIndex: 1, border:"1px solid #3dab35"}}>
                                    <MenuList>
                                    <MenuItem onChange={this.onFileChangeProperty}> 
                                        <label style={{ color:'#3dab35',fontSize:'small',align:'left', fontFamily: "'Open Sans', sans-serif"}}> 
                                            <input type="file" style={{display:'none',align:'left'}} /> 
                                            Bulk Upload Cluster
                                        </label> 
                                        </MenuItem> 
                                    <a href={Clusterbulkupload} style={{textDecoration:'none', 
                                                                        color:'#3dab35', 
                                                                        fontSize:'small', 
                                                                        fontFamily: "'Open Sans', sans-serif"}} download> 
                                        <MenuItem onClick={this.handleCloseProperty}>Download Sample</MenuItem>     
                                    </a>
                                    </MenuList>
                                </Paper>
                                </ClickAwayListener>
                            )}
                            </Popper> 
                    </div>
                </div>

                {(this.props.loadingbulk == true || this.state.bulkUp == false ) ? "":          
                     <div>
                    <MuiThemeProvider theme={theme}>
                         <LinearProgressWithLabel color="secondary" value={this.props.progressPercentage} /> 
                    </MuiThemeProvider>
                </div> 
               }
            </div>
            {/* {(this.props.loadingCluster == false)? <div id="cover-spin"></div>:''} */}
            <div className="ulb_listing AreaLIsting scrollward" >
                <TableContainer component={Paper} style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                    <Table stickyHeader aria-label="sticky table" style={{ tableLayout: 'fixed' }}>
                   
                        <TableHead >
                            <TableRow >
                                {cHeaders}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                       
                            {ListingCluster.length === 0&&this.props.loadingCluster == true ? <TableRow style={{textAlign:'center', fontWeight:'bold', fontSize: '13px', }}>
                                        No Records Found !!</TableRow>:ListingCluster }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            {
                this.props.totalCount && this.props.totalCount != 0 &&
                <ReactPaginate
                    previousLabel={"Prev"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    // pageCount={10}
                    pageCount={this.props.totalCount / this.state.limit}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    style={{ borderLeftWidth: '0px' }}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"} />
            }
            <div className="setFreq">
      <Dialog
        maxWidth={'md'}
        fullWidth={true} 
        // open={true}
        open={this.state.openClusterddPopup}
        aria-labelledby="responsive-dialog-title">
        <DialogTitle className="text-center border-none " style={{color:'#3dab35',padding: "4px 24px"}}><span style={{fontSize:'18px'}}>{this.state.editId != ""? `${("Edit Cluster")}`: `${("Add Cluster")}`}</span></DialogTitle>
        <DialogContent style={{padding:'0px'}}> 
        <div className="create_ulb_content_outer">
                <div className="create_ulb_content create_ulb_contentPop">

        <Grid container spacing={1}>
                             <Grid item xs={6}>
                                <FormControl>
                                    <label className="required" htmlFor="ClusterulbName"> Cluster ULB Name</label>
                                    <TextField id="ClusterulbName"
                                        variant="outlined"
                                        placeholder="Enter Cluster ULB Name"
                                        name="ClusterulbName"
                                        value={this.state.ClusterulbName}
                                        onChange={this.handleChange} />
                                    <div style={{ fontSize: 12, color: "red", fontFamily: "'Open Sans', sans-serif", marginTop: "1%" }}>{this.state.ClusterulbNameErr}</div>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl>
                                    <label htmlFor="ulbName_tm">Cluster ULB Name (Tamil)</label>
                                    <TextField id="ulbName_tm"
                                        variant="outlined"
                                        placeholder="கிளஸ்டர் ULB பெயரை உள்ளிடவும்"
                                        name="ClusterulbName_tm"
                                        value={this.state.ClusterulbName_tm}
                                        onChange={this.handleChange} />
                                </FormControl>
                            </Grid>
                            
                                <Grid item xs={6}>
                                <FormControl>
                                    <label htmlFor="statusType" >Status</label>
                                <TextField id="select"  name="statusType" value={this.state.StatusValue} onChange = {this.handlChangeStatus} select>
                                    <MenuItem value={false}>{'Active'}</MenuItem>
                                    <MenuItem value={true}>{'In Active' }</MenuItem>
                                </TextField>
                                </FormControl>
                                </Grid>
                            
                                
                            </Grid>
                      
                        <Grid container spacing={0}>
                                <Grid item xs={12}>
                                <div className="create_ulb_buttons">
                                <Button className="login_submit" variant="outlined" color="primary" onClick={this.handleClose}>Cancel</Button>
                                    <Button className="login_submit" variant="contained" color="primary" onClick={this.handleSubmit}>Save</Button>
                                </div>
                                </Grid>
                        </Grid>
                      
</div></div>
        </DialogContent>
        </Dialog>
        </div>

        </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.auth.token,
        ClusterHeaders: state.ClusterrReducer.ClusterHeaders.concat({header: "Action", accessor: "actionButton", sortable: "false", filter: "false"}),
        ClusterData: state.ClusterLIstingReducer.clusterData,
        totalCount: state.ClusterLIstingReducer.totalCount,
        loadingCluster: state.ClusterLIstingReducer.loading,
        progressPercentage : !!state.ClusterLIstingReducer&&state.ClusterLIstingReducer.progress,
        loadingbulk: state.ClusterLIstingReducer.loadingbulk
    }
}

const mapDispatchToProps = (dispatch) => {
    return ({

        onFetchClusterHeaders: (payload) => dispatch(actionsCreators.fetchClusterHeaders(payload)),
        onFetchClusterData: (payload) => dispatch(actionsCreators.fetchClusterData(payload)),
        onDeleteUlbData: (payload) => dispatch(actionsCreators.deleteClusterUlbData(payload)),
        OnSearchClusterName: (payload) => dispatch(actionsCreators.searchClusterName(payload)),
        OnbulkCluster   : (payload,formData,props) => dispatch(actionsCreators.bulkCluster(payload,formData,props)),
        onFetchClusterULBListingExport :(payload)=>dispatch(actionsCreators.fetchClusterULBListingExport(payload)),
        onEditClusterUlbData: (formdata, props,payload) => dispatch(actionsCreators.editClusterUlbData(formdata, props,payload)),
        onAddCUlbData :(formdata, props,payload)=>dispatch(actionsCreators.addClusterUlbData(formdata,props, payload))
    })

}

export default connect(mapStateToProps, mapDispatchToProps)(ClusterListing);
