import React, { Component } from 'react'
import { connect } from 'react-redux'
import TreatmentFacilityForm from './TreatmentFacilityForm'
import * as actionsCreators from  '../../store/actions/index';
import _ from 'lodash'
export class TreatmentFacilityEdit extends Component {
   componentDidMount(){
        const payload = {
            token : this.props.token
        }
        this.props.onFetchTreatmentListing(payload) 
   }
   handleSubmit = (formData) => {
    const payload = formData
    this.props.onEditTreatmentFacility(payload, this.props)
   }
    render() {
        return (
            <div className="create_ulb">
            <div className="dashboard_top_path">
                <p><img src="images/left-arrow.svg" /> Disposal Facility Management / <span>Edit Disposal Facility</span></p>
            </div>
            <div className="dashboard_right_content">
                <img className="dashboard_right_content_top_icon" src="images/Rectangle.svg" />
                <div className="dashboard_right_content_top">
                    <h5>Edit Disposal Facility</h5>
                </div>
                <div className="create_ulb_content_outer">
                    <div className="create_ulb_content">
                        
                        {
                            !_.isEmpty(this.props.facilty) &&   <TreatmentFacilityForm facilty = {this.props.facilty} handleSubmit={this.handleSubmit}/>
                        }
                       
                    </div>
                </div>
            </div>
        </div>
        )
    }
}
const mapStateToProps = (state, props) => {
    return {
        token: state.auth.token,
        facilty : state.treatmentListing.treatmentListing.find(facilty => facilty.TFId == props.match.params.id)
    }
}
const mapDispatchToProps = (dispatch) => {
    return{
        onFetchTreatmentListing: (payload) => dispatch(actionsCreators.fetchTreatmentListing(payload)),
        onEditTreatmentFacility: (payload, props) => dispatch(actionsCreators.editTreatmentListing(payload, props)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps )(TreatmentFacilityEdit)
