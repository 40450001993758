import * as actionTypes from '../../actions/actionsTypes';
import { updateObject } from '../../utility';

const initialState = {
    loads             : [], 
    totalCount        : 0,
    loading           : false,
    configlist            : [],
    operaterList : [],
    trucks : [],
    sameLoads:[],
    totalCountLoads:0,
    loadingSameLoads:false,
    success:false,
    progress:0,
    loadingbulk:false
}

const fetchLoadsStart = (state, action) => {
    return updateObject(state, {
        loading       : true,
    });
}

const fetchLoadsSuccess = (state, action) => {
    return updateObject(state, {
        loads        : action.loads,
        totalCount   : action.totalCount                                                                                                              ,
        loading      : false,
    });
}
const fetchSameLoadsSuccess = (state, action) => {
    return updateObject(state, {
        sameLoads        : action.sameLoads,
        totalCountLoads   : action.totalCountLoads                                                                                                              ,
        loadingSameLoads      : action.loadingSameLoads,
    });
}
const fetcSampleFilesSuccess = (state, action) => {
    return updateObject(state, {
        configlist   : action.config,
        loading      : false,
    });
}
const fetchLoadsFail = (state, action) => {
    return updateObject(state, {
        loading     : false,
    })
}
const fetchOperatorListingSuccess =(state, action)=>{
    return updateObject(state, {
        operaterList        : action.operaters,
        totalCount   : action.totalCount                                                                                                              ,
        loading      : false,
    });
}
const fetchTrucksListingSuccess =(state, action)=>{
    return updateObject(state, {
        trucks        : action.Trucks,
        totalCount   : action.totalCount                                                                                                              ,
        loading      : false,
    });
}



const getProgressPercentage = (val) => {
    let percent = 10;
    for(let i=0; i<val; i++){
        percent = percent + 10
    }
    return percent 
}

const fetchLoadsBulkStart = (state, action) => {
    return updateObject(state, {
        progress: getProgressPercentage(9),
        loadingbulk: false,
    });
}

const fetchLoadsBulkSuccess = (state, action) => {
    return updateObject(state, {
        loadingbulk: true,
        progress:100
    });
}
const fetchLoadsBulkFail = (state, action) => {
    return updateObject(state, {
        loadingbulk: true,
        progress:action.progress+=0
    });
}


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_LOADS_START             : return fetchLoadsStart(state, action);
        case actionTypes.FETCH_LOADS_SUCCESS           : return fetchLoadsSuccess(state, action);
        case actionTypes.FETCH_SAME_LOADS_SUCCESS           : return fetchSameLoadsSuccess(state, action);
        case actionTypes.FETCH_LOADS_FAIL              : return fetchLoadsFail(state, action);
        case actionTypes.FETCH_CONFIG_SUCCESS           : return fetcSampleFilesSuccess(state, action)
        case actionTypes.FETCH_OPERATORLISTING_LOAD_SUCCESS : return fetchOperatorListingSuccess(state, action);
        case actionTypes.FETCH_TRUCKLISTING_LOAD_SUCCESS : return fetchTrucksListingSuccess(state, action);
        case actionTypes.FETCH_LOADSBULK_START: return fetchLoadsBulkStart(state,action);
        case actionTypes.FETCH_LOADSBULK_SUCCESS: return fetchLoadsBulkSuccess(state,action)
        case actionTypes.FETCH_LOADSBULK_FAIL: return fetchLoadsBulkFail(state,action)

        default: return state;
    }
}

export default reducer;