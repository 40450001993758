import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom'
import moment from 'moment'
import '../RecordKeeper/loads/RecordKeeper.css';
import * as actionsCreators from  '../../store/actions/index';
import axios from '../../http-config';
import * as User  from '../../config/constant';
import ReactPaginate from 'react-paginate';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell'; 
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Button from '@material-ui/core/Button';

class PropertyLoads extends Component {
    state = {
      limit:10 
    }
    componentDidMount() {
        let payload = {
            token: this.props.token,
            page:  1,
            limit:this.state.limit,
            ulbId : this.props.match.params.id
        }
        this.props.onFetchHeaders(payload);
        this.props.onFetchLoads(payload);      
    }

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        let payload = {
            ulbId : this.props.match.params.id,
            token: this.props.token,
            page:  selectedPage + 1,
            limit: this.state.limit,
        }
        this.props.onFetchLoads(payload); 
    };
    onClickExportProperty=()=>{
        let payload ={
            ulbId : this.props.match.params.id,
            exportBol:true,
            token: this.props.token,
        }
        this.props.onFetchPropertyExport(payload)
    };
    render() {
        let filterValue;
        let array1=this.props.headers.headers.slice(0,3)
        let array2=this.props.headers.headers.slice(3)
        let fixedLoads
        let scrollLoads
        let loads = this.props.loads;
        if ( !this.props.loading ) {
            if (loads.length === 0) {
            fixedLoads = <h3>No Loads</h3>
            } else {
                fixedLoads = !!loads && loads.map(load => (
                    <TableRow  key={load.id}>{
                        array1.map(col => {
                                return <TableCell style={{lineHeight:'37px',alignItems:'right'}} className={"white-space"} >{ load[col.accessor]}</TableCell>
                            })
                        }
                    </TableRow>
                ));
                
            }
            scrollLoads = !!loads && loads.map(load => 
            {
             const id=load.loadId
             return<TableRow key={load.id}>
                 {array2.map(col => {
                        if(col.accessor == 'photo'){
                            if(load['photo']){
                            return <TableCell> <a className="email_link" href={load['photo']} target="_blank">View</a></TableCell>
                        }
                        else{
                            return <TableCell></TableCell>
                        }
                    }else{
                            return <TableCell style={{lineHeight:'37px',alignItems:'right'}}  className={"white-space"}>{ load[col.accessor]}</TableCell>}
                        })
                    }
                </TableRow> 
             
            });
         
        }
        const fixedCols =  array1.map((col, i) => {       
            return <TableCell key={i}  className={"white-space"}>
                <div style={{
                                display: 'flex',
                                alignItems: 'start',
                                whiteSpace: 'break-spaces',
                                height:'3em',
                            
                            }}>
                        {col.header}
                        </div>
                    </TableCell>
        }
        );
       
        const scrollCols =  array2.map((col,i) => {
           
            return <TableCell key={i}  className={"white-space"}>
                        <div style={{
                                display: 'flex',
                                alignItems: 'start',
                                height:'3em',
                                paddingLeft:'10px',
                                paddingRight:'10px'
                            }}>
                        {col.header}    
                        </div> 
                    </TableCell>
            }
            )
            const UserIdSave = localStorage.getItem("UserIdSave")
            const roleName = localStorage.getItem('roleName')
    return (
            <div>
                <div className="dashboard_right_content">
                    <img className="dashboard_right_content_top_icon" src="images/Rectangle.svg" />
                    <div className="dashboard_right_content_top">
                    <h5><Link to = {roleName== User.SANITARYINSPECTOR? "/": "/property-management"} className="email_link"><ArrowBackIosIcon></ArrowBackIosIcon></Link>Loads Records</h5>
                    </div>
                        <div className="dashboard_right_content_top_options" style={{marginTop:"-44px"}}>
                        <Button style={{height:'30px'}} onClick={this.onClickExportProperty}>Export</Button>
                            <div className="dashboard_right_content_top_search">
                           
                            </div>
                        </div>
                    </div>
                    <div> 
                    <Grid container direction="row">
                         <Grid items xs={2}>
                             <div className="dashboard_listing" >
                                <TableContainer component={Paper} style={{overflow:"hidden",paddingLeft:"15px"}}>
                                    <Table aria-label="simple table" style={{ tableLayout: 'fixed' }}>
                                                <colgroup>
                                                        <col style={{width:'25%'}}/>
                                                        <col style={{width:'40%'}}/>
                                                        <col style={{width:'34%'}}/>
                                                </colgroup>
                                        <TableHead>
                                            <TableRow>
                                                {fixedCols}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                                {fixedLoads}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                         </Grid>
                         <Grid items xs={10}>
                             <div className="dashboard_listing" >
                                 <TableContainer component={Paper} style={{paddingLeft:"0px" ,paddingRight:"15px"}}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                            {scrollCols} 
                                            </TableRow>
                                        </TableHead>
                                        <TableBody> 
                                            {scrollLoads}
                                        </TableBody>
                                    </Table>
                                 </TableContainer> 
                             </div>
                         </Grid>
                     </Grid>
                    </div>
                {this.props.totalCount && 
                    <ReactPaginate
                        previousLabel={"Prev"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.props.totalCount/this.state.limit}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        style={{borderLeftWidth:'0px'}}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}/>
                }
                </div>
       );
    }
}

const mapStateToProps = state => {
    return {
        loads: state.loads.loads,
        loading: state.loads.loading,
        token: state.auth.token,
        totalCount: state.loads.totalCount,
        headers:state.headers

    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchLoads: (payload) => dispatch(actionsCreators.fetchLoadsPerProperty(payload)),
        onFetchHeaders: (payload) => dispatch(actionsCreators.fetchHeaders(payload)),
        onFetchPropertyExport:(payload)=>dispatch(actionsCreators.fetchPropertyExport(payload))
    }
}

export default  connect(mapStateToProps, mapDispatchToProps)(PropertyLoads, axios);
