import * as actionTypes from '../actionsTypes';
import axios from '../../../http-config'
import swal from 'sweetalert'

//Operator Management Listing DATA
export const fetchOperatorDataStart = () =>
{
    return({
        type : actionTypes.FETCH_OPERATOR_DATA_START,
        loading:false
    })
}
export const fetchOperatorDataSuccess = (response) =>
{
    return({
        type : actionTypes.FETCH_OPERATOR_DATA_SUCCESS,
        operatorData : response.data.operators,
        totalCount:response.totalCount,
        statisticsData : response.data.statistics,
        loading:true
    })
}
export const fetchOperatorDataFail = (err) =>
{
    return({
        type : actionTypes.FETCH_OPERATOR_HEADERS_FAIL,
        error : err,
        loading:true
    })
}
export const fetchOperatorData = (payload) =>

{
    const token = payload.token
    return (dispatch) =>
    {
        dispatch(fetchOperatorDataStart())
        axios({
            method : 'get',
            url : `/operator/?statistics=${true}&limit=${payload.limit}&page=${payload.page}&sortColumnName=${payload.sortName}&sortColumnType=${payload.sortValue}`,
            headers : {'Authorization' : `Bearer ${token}`}
        })
        .then((response) =>
        {
            dispatch(fetchOperatorDataSuccess(response.data))
        })
        .then(function (response) {
            let link1=response.data.data[0].file_path
            
            window.downloadFile = function (link) {
                window.open(link, '_blank');
            }
            window.downloadFile(link1)
          
        })
        .catch((err)=>
        {
            dispatch(fetchOperatorDataFail(err))
        })
    }

}
export const exportData = (payload) => {
    return dispatch => {
        const url = '/truck/?export=true'
        axios({
            method: 'get',
            url: url,
            headers: {'Authorization': `Bearer ${payload.token}`}
        })
        .then(function (response) {
            let link1=response.data.data[0].file_path
            
            window.downloadFile = function (link) {
                window.open(link, '_blank');
            }
            window.downloadFile(link1)
          
        }).catch(error => {
        })

    }
}

export const addOperatorData = (payload,props) => {
    const token = payload.token
    const payload2 = {
        name              : payload.operator,
        name_tn           : payload.name_tn,
        applicantName     : payload.ownerName,
        contactNumber     : payload.contactNo,
        email             : payload.email,
        vehicleOwnerName  : payload.vehicleOwnerName,
        addressOfOperator : payload.address,
        noOfTrucks        : payload.trucks,
        username          : payload.username,
        password          : payload.password
    }
    let url='/operator'
    return (dispatch) => {
        dispatch(fetchOperatorDataStart())
        axios.post(url, payload2, { headers : { "Authorization":`Bearer ${token}`}})
        .then((response) => {
            const fetchedResponse = response.data.success;
            fetchedResponse && swal({
                title: "Operator created successfully!",
                icon: "success",
                buttons: {
                    confirm: 'Ok'
                }
              })
            dispatch(fetchOperatorData(payload))
            props.history.push('/operator-management')  
        }, (err) => {
            swal({icon : "error" , title : err.response.data.error[0]["message"]})    
        })
        .catch((err)=> {
            dispatch(fetchOperatorDataFail(err))
        })
    }
}

export const deleteOperatorUser=(payload)=>{
     return async dispatch=>{
         try{
            let id = payload.operatorId
            let token = payload.token
            let url = `/user/delete_operator_user/${id}`;
            const response = await axios.delete(url, { headers:{
                'Content-Type':'application/json',"Authorization":`Bearer ${token}`
            }})
            response && swal({
                title: "Your data has been deleted!",
                icon: "success",
                buttons: {
                    confirm: 'Ok'
                }
              })
            return response
         }
         
        catch(err) {
            return false
         }
    }
 }


export const editOperatorData = (payload,props) => {
    const id=props.match.params.id
    let token=localStorage.getItem('token');
        return dispatch => {
            dispatch(fetchOperatorDataStart());
            const payload1 = {
                operatorId        : id,
                id                :payload.id,
                name              : payload.operator,
                name_tn           : payload.name_tn,
                applicantName     : payload.ownerName,
                contactNumber     : payload.contactNo,
                email             : payload.email,
                vehicleOwnerName  : payload.vehicleOwnerName,
                addressOfOperator : payload.address,
                noOfTrucks        : payload.noOfTrucks,
                updatedDate       : payload.updatedDate,
                username          : payload.username,
                password          : payload.password,
                isDisableOperator :payload.isDisableOperator

            }
            const url = '/operator/'   
            axios.put(url,payload1,{headers:{
                "Authorization":`Bearer ${token}`
            }})
            .then(function (response) {
                const fetchedResponse = response.data.success;
                props.history.push('/operator-management')  
                fetchedResponse && swal({
                    title: "You have Successfully edited the data!!!",
                    icon: "success",
                    buttons: {
                        confirm: 'Ok'
                    }
                  })
                  dispatch(fetchOperatorDataSuccess(fetchedResponse));
                  
            }, (err) => {
                swal({icon : "error" , title : err.response.data.error[0]["message"]})  
            }).catch(err => {
                dispatch(fetchOperatorDataFail(err));
            })
        }
    }

export const fetchOperatorBulkImport = (payload,formData) => {
    let token = payload.token
    return dispatch => {
        dispatch(fetchOperatorDataStart());
        const url = '/operator/bulk'

        axios.post(url,formData,{headers:{
            "Authorization":`Bearer ${token}`
        }})
        .then(function (response) {
            if(response){
                const fetchedResponse = response.data.success;
                fetchedResponse && swal({
                    title: "Data imported successfully!",
                    icon: "success",
                    buttons: {
                        confirm: 'Ok'
                    }
                    })
                    .then((bulk) => {
                    if (bulk) {
                    dispatch(fetchOperatorData(payload))
                    } 
                    });
            }
                
        }, (err) => {
            swal({icon : "error" , title : err.response.data.error[0]["message"]})    
        })
        .catch(error => {
            dispatch(fetchOperatorDataFail(error));
        })

    }
}

export const deleteoperator = (payload, data) => {
    return (dispatch) => {
        dispatch(fetchOperatorDataStart())
        const url =  `/operator/?statistics=${true}`
        axios.delete(url , { data : data, headers : {   "Authorization":`Bearer ${payload.token}`} })
        .then((response) => {
            swal({
                title:"Your data has been deleted!",
                icon: "success",
            })
            dispatch(fetchOperatorData(payload))
        }, 
        (err) => {
            swal({icon : "error" , title : err.response.data.error[0]["message"]}) 
        })
    }
}

export const createOperatorAccount = (payload,props) => {
    return async (dispatch) => {
        try{
            const token = payload.token
            const body = {
                "operatorId": payload.operatorId,
                "userName": payload.userName,
                "password": payload.password,
                "email":payload.email    
            }
            let url='/user/create_userId'
            const response = await axios.post(url, body, { headers : { "Authorization":`Bearer ${token}`}})
            const fetchedResponse = response.data.success;
            fetchedResponse && swal({
                title: "Operator account created successfully!",
                icon: "success",
                buttons: {
                    confirm: 'Ok'
                }
              })
            // props.history.push('/operator-data') 
            return response 
        }catch(err){
            swal({icon : "error" , title : err.response.data.error[0].message}) 
            return false
        }
    }
}

export const editOperatorUser = (payload,props) => {
    return async (dispatch) => {
        try{
            const token = payload.token
            const body = {
                "operatorId": payload.operatorId,
                "username": payload.userName,
                "password": payload.password,
                "email":payload.email    
            }
            let url='user/update_operator_user'
            const response = await axios.put(url, body, { headers : { "Authorization":`Bearer ${token}`}})
            const fetchedResponse = response.data.success;
            fetchedResponse && swal({
                title: "Operator account updated successfully!",
                icon: "success",
                buttons: {
                    confirm: 'Ok'
                }
              })
            return response 
        }catch(err){
            swal({icon : "error" , title : err.response.data.error[0].message}) 
            return false
        }
    }
}
export const updateOperatorAccount = (payload,props) => {
    const token = payload.token
    const body = {
        "operatorId": payload.operatorId,
        "userName": payload.userName,
        "password": payload.password,
        "email":payload.email    
    }
    let url='/user/create_userId'
    return (dispatch) => {
        axios.put(url, body, { headers : { "Authorization":`Bearer ${token}`}})
        .then((response) => {
            const fetchedResponse = response.data.success;
            fetchedResponse && swal({
                title: "Operator account updated successfully!",
                icon: "success",
                buttons: {
                    confirm: 'Ok'
                }
              })
            props.history.push('/operator-data')  
        }, (err) => {
            swal({icon : "error" , title : err.response.data.error[0]["message"]})    
        })
        .catch((err)=> {
        })
}
}
    

export const searchOperterName=(payload)=>{
    return dispatch =>{
        dispatch(fetchOperatorDataStart());
        const url =`operator?name=${payload.searchQuery}&limit=${payload.limit}&page=${payload.page}`
        axios.get(url,{headers:{
            "Authorization":`Bearer ${payload.token}`
        }} ).then(response=>{
            response && response.data && response.data.data.operators &&
            response.data.data.operators && 
            response.data.data.operators.length == 0 && 
            swal("No Records Found !", {
                icon: "warning",
              });
            dispatch(fetchOperatorDataSuccess(response.data));
        }).catch(err=>{
            dispatch(fetchOperatorDataFail(err));
        })
    }
}