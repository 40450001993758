import * as actionTypes from '../actionsTypes';
import axios from '../../../http-config';
import swal from 'sweetalert';

export const fetchZoneListingStart = () => {
    return {
        type: actionTypes.FETCH_ZONE_START
    }
}
export const fetchZoneSucces =(response)=>{
    return {
        type : actionTypes.FETCH_ZONE_SUCCESS,
        zoneListing : response.data.zones,
        totalCount:response.totalCount
    }
}

export const fetchZoneListingFail = (error) => {
    return {
        type: actionTypes.FETCH_ZONE_FAIL,
        error:error
    }
}

export const ZonesList = (payload)=>{
// const api = !!payload && payload.limit && payload.page ? `/zone/getZones/?limit=${payload.limit}&page=${payload.page}`: `/ulb`
let token = localStorage.getItem('token');
    return dispatch=>{
        dispatch(fetchZoneListingStart());
        const url = `/zone/getZones/?limit=${payload.limit}&page=${payload.page}&sortColumnName=${payload.sortName}&sortColumnType=${payload.sortValue}`
        axios.get(url, {headers:{
            "Authorization":`Bearer ${token}`
        }})
        .then(response=>{
            dispatch(fetchZoneSucces(response.data))
        })
        .catch(err=>{
            dispatch(fetchZoneListingFail(err))
        })
    }
}

export const addZone = (payload,props) => {
    let token= payload.token
    let body = {
        ulbId: payload.ulbName,
        name:payload.name,
        name_tn:payload.name_tn,
    }
    return (dispatch) => {
        const url = '/zone'
        dispatch(fetchZoneListingStart())
        axios.post(url, body, { headers : { "Authorization":`Bearer ${token}`}})
        .then((response) => {
            const fetchedResponse = response.data.success;
            fetchedResponse && swal({
                title: "Zone added successfully!",
                icon: "success",
                buttons: {
                    confirm: 'Ok'
                }
            })
            dispatch(ZonesList({limit:10,page:1, sortName:"", sortValue:""}))
            props.history.push('/zone-management')
        }, 
        (err) => {
            swal({icon : "error" , title : err.response.data.error[0]["message"]}) 
        })
        .catch((err) => {
            dispatch(fetchZoneListingFail(err))
        })
    }
}

export const editZone = (formdata, props, payload) => {
    let token= payload.token
    const id = formdata.id
    return (dispatch) => {
       
        dispatch(fetchZoneListingStart())
        const url = `/zone/${id}`
        axios.put(url, formdata, {headers:{"Authorization":`Bearer ${token}`}})
        .then((response) => {
            const fetchedResponse = response.data.success;
            fetchedResponse && swal({
                title: "You have successfully updated the data!!!",
                icon: "success",
                buttons: {
                    confirm: 'Ok'
                }
            })
            dispatch(ZonesList(payload))
        },
        (err) => {
            swal({icon : "error" , title : err.response.data.error[0]["message"]}) 
        })
        .catch((err) => {
        })
    }
}


//Search Zone 
export const searchZone=(payload,Url,type)=>{
    return dispatch =>{
        dispatch(fetchZoneListingStart());
        const url =`/zone/getZones/?&zoneNameEng=${payload.searchQuery}&limit=${payload.limit}&page=${payload.page}`
        axios.get(url,{headers:{
            "Authorization":`Bearer ${payload.token}`
        }} ).then(response=>{
            response && response.data && response.data.data &&
            response.data.data.zones && 
            response.data.data.zones.length === 0 && 
            swal("No Records Found !", {
                icon: "warning",
              });
            dispatch(fetchZoneSucces(response.data));
        }).catch(err=>{
            dispatch(fetchZoneListingFail(err));
        })
    }
}
