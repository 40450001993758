import * as actionTypes from '../actions/actionsTypes';
import { updateObject } from '../utility';

const initialState = {
    token: null,
    userId: null,
    error: null, 
    loading: false,
    authRedirectPath: '/',
}

const authStart = ( state, action ) => {
    return updateObject(state, { error: null, loading: true})
}

const authSuccess = ( state, action ) => {
    return updateObject(state, { 
        token: action.idToken,
        userId: action.userId,
        error: null,
        loading: false
    });
}

const authFail = ( state, action ) => {
    return updateObject(state, { error: action.error, loading: false})
}

const authLogout = (state, action) => {
    return updateObject(state, { token: null, userId: null})
}

const resetPasswordStart = ( state, action ) => {
    return updateObject(state, { error: null, loading: true})
}

const resetPasswordSuccess = ( state, action ) => {
    return updateObject(state, { 
        error: null,
        loading: false,
    });
}

const resetPasswordFail = ( state, action ) => {
    return updateObject(state, { error: action.error, loading: false})
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);

        case actionTypes.RESET_PASSWORD_SUCCESS: return resetPasswordSuccess(state, action);
        case actionTypes.RESET_PASSWORD_FAIL: return resetPasswordFail(state, action);
        case actionTypes.RESET_PASSWORD_START: return resetPasswordStart(state, action);

        default: return state;
    }
}

export default reducer;