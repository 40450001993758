import React,{Component} from 'react'
import Chart from './Chart'
import CardMain from './CardMain'
import { connect } from 'react-redux';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler.js';
import axios from '../../http-config';
import * as actionsCreators from  '../../store/actions/index';


class StateDashboard extends Component{
  state = {
    filter : {
      'interval':'',
    },
    data:[],
    id:'',
    isDisabled:false,
    interval:'Current Day'
  }
  componentDidMount=()=>{
    let payload = {
      token: this.props.token,
      filter: JSON.stringify([this.state.filter]),
      interval:this.state.interval
  }
    //  this.props.onFetchDashboardData(payload)
  }

     render(){
       const d = this.props.maxCountLoads
     return(
       <div style={{marginRight:'1%'}}>
        <CardMain dashboardProps={{no_of_loads:this.props.data.dashboard_data}} totalCountLoads = {d}/>
         {/* <Chart/> */}
       </div>
     )
  }
}

const mapStateToProps = state => {
  return {
    data: state.dashboard.dashboard,
    loading: state.loads.loading,
    token: state.auth.token,
    maxCountLoads:state.stateDashboard.totalCountLoadsMax
    
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchDashboardData: (payload) => dispatch(actionsCreators.fetchDashboard(payload))       
  }
}

export default  connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(StateDashboard, axios));

