import * as actionTypes from '../actionsTypes'
import axios from '../../../http-config'
import swal from 'sweetalert'

export const fetchUserListingStart = () => {
    return({
        type      : actionTypes.FETCH_USER_LISTING_START,
        loading: false
    })
}

export const fetchUserListingSuccess = (response) => {
    return({
        type      : actionTypes.FETCH_USER_LISTING_SUCCESS,
        userData  : response.data.users,
        totalCount : response.totalCount,
        loading: true
    })
}

export const fetchUserListingFail = (err) => {
    return({
        type      : actionTypes.FETCH_USER_LISTING_FAIL,
        error     : err,
        loading: false
    })
}

export const fetchUserListing = (payload) => {
    return (dispatch) => {
        dispatch(fetchUserListingStart())
        axios({
            method    : 'get',
            url       : `user?userName=${payload.searchQuery}&limit=${payload.limit}&page=${payload.page}&sortColumnName=${payload.sortName}&sortColumnType=${payload.sortValue}&roleId=${!!payload.roleFilter&&payload.roleFilter !=""? payload.roleFilter:''}${payload.roleName=="Admin"? "&admin=true" :""}`,
            headers   : {'Authorization': `Bearer ${payload.token}`}
        })
        .then((response) => {
            dispatch(fetchUserListingSuccess(response.data))
        })
        .catch((err) => {
            dispatch(fetchUserListingFail(err))
        })
    }
}

// export const addUser = (payload,props) => {
//     let token= payload.token
//     return (dispatch) => {
//         const url = '/user'
//         dispatch(fetchUserListingStart())
//         axios.post(url, payload, { headers : { "Authorization":`Bearer ${token}`}})
//         .then((response) => {
//             const fetchedResponse = response.data.success;
//             fetchedResponse && swal({
//                 title: "User added successfully!",
//                 icon: "success",
//                 buttons: {
//                     confirm: 'Ok'
//                 }
//             })

//             // dispatch(fetchUserListing({token: token,searchQuery:'',limit:10, page: payload.page, sortName:'', sortValue:'',  roleName:payload.roleName}))
//             // props.history.push('/user-management')
//         }, 
//         (err) => {
//             swal({icon : "error" , title : err.response.data.error[0]["message"]}) 
//             // dispatch(fetchUserListing({token: token,searchQuery:'',limit:10, page: payload.page, sortName:'', sortValue:'',  roleName:payload.roleName}))
//             // props.history.push('/user-management')
//         })
//         .catch((err) => {
//             swal({icon : "error" , title : err.response.data.error[0]["message"]}) 
//             dispatch(fetchUserListingFail(err))
//         })
//     }
// }

export const editUser = (payload,props) => {
    let token= payload.token
    const id = payload.id
    const userPayload = {
            token: payload.token,
            limit:payload.limit,
            page:payload.page,
            sortName:"",
            sortValue: "", 
             searchQuery:"",
            roleFilter:"",
            roleName:payload.roleName
    }
    return (dispatch) => {
        dispatch(fetchUserListingStart())
        const url = '/user'
        axios.put(url, payload, {headers:{"Authorization":`Bearer ${token}`}})
        .then((response) => {
            const fetchedResponse = response.data.success;
            fetchedResponse && swal({
                title: "You have successfully updated the data!!!",
                icon: "success",
                buttons: {
                    confirm: 'Ok'
                }
            })
            dispatch(fetchUserListing(userPayload))
        },
        (err) => {
            swal({icon : "error" , title : err.response.data.error[0]["message"]}) 
            dispatch(fetchUserListing(userPayload))
        })
        .catch((err) => {
            dispatch(fetchUserListingFail(err))
        })
    }
}

export const deleteUser = (payload, data, props) => {
    return (dispatch) => {
        dispatch(fetchUserListingStart())
        const url = '/user'
        axios.delete(url , { data : data, headers : {   "Authorization":`Bearer ${payload.token}`} })
        .then((response) => {
            dispatch(fetchUserListing(payload))
            swal({
                title:"Your data has been deleted!",
                icon: "success",
            })
        }, 
        (err) => {
            swal({icon : "error" , title : err.response.data.error[0]["message"]})
            dispatch(fetchUserListing({token: payload.token,searchQuery:'',limit:10, page: 1, sortName:'', sortValue:'', roleName:payload.roleName}))
            props.history.push('/user-management')
        })
        .catch((err) => {
            dispatch(fetchUserListingFail(err))
        })
    }
}

export const searchUserName=(payload)=>{
    let roleName = localStorage.getItem('roleName')
    return dispatch =>{
        dispatch(fetchUserListingStart());
        const url = roleName == "Admin" ? `user?userName=${payload.searchQuery}&limit=${payload.limit}&page=${payload.page}&admin=true` : `user?userName=${payload.searchQuery}&limit=${payload.limit}&page=${payload.page}`
        axios.get(url,{headers:{
            "Authorization":`Bearer ${payload.token}`
        }} ).then(response=>{
            response && response.data && response.data.data.users &&
            response.data.data.users && 
            response.data.data.users.length == 0 && 
            swal("No Records Found !", {
                icon: "warning",
              });
            dispatch(fetchUserListingSuccess(response.data));
        }).catch(err=>{
            dispatch(fetchUserListingFail(err));
        })
    }
}



export const fetchUserListingExport =(payload)=>{
    let roleName = localStorage.getItem("roleName")
    let token = payload.token 
    const url = roleName == "Admin" ? `user?userName=${payload.searchQuery}&export=true&admin=true`: `user?userName=${payload.searchQuery}&export=true`
    return dispatch=>{
        dispatch(fetchUserListingStart());
        axios.get(
            url,
            {headers:{ "Authorization":`Bearer ${token}`
        }}).then(response=>{
            let exportLink=response.data.data[0].file_path
            window.downloadFile = function (link) {
                window.open(link, '_blank');
            }
            window.downloadFile(exportLink)
  
        }).catch(err=>{
            dispatch(fetchUserListingFail(err));
        })
    }
 }

 