import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { makeStyles ,withStyles} from '@material-ui/core/styles';
import axios from '../../http-config'
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux'
import * as actionCreators from '../../store/actions/index'
import Switch from '@material-ui/core/Switch';
import TableCell from '@material-ui/core/TableCell';
import Dialog from '@material-ui/core/Dialog';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import { blue } from '@material-ui/core/colors';
import { DatePicker , MuiPickersUtilsProvider,KeyboardTimePicker,
  KeyboardDatePicker,} from "@material-ui/pickers";
import moment from 'moment'
import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import InputBase from '@material-ui/core/InputBase';
const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor:"#fff",
    border: '1px solid #767676',
    fontSize: 15,
    padding: '7px 26px 7px 20px',
    transition: theme.transitions.create(['border-color']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      'Open Sans',
      'sans-serif',
 
    ].join(','),
    '&:focus ': {
      borderRadius: 4,
          backgroundColor:"#fff !important"
 
    }
  },
}))(InputBase);
 class ScheduleDialog extends Component {
  constructor(props){
    super(props)
  this.state = {
    emergency:!!this.props.editEmergency&& this.props.editEmergency !== undefined&& this.props.editEmergency === true ?true:false ,
    selectedDate:this.props.dataEdit ? this.props.dataEdit:!!this.props.setFreqPop&&this.props.setFreqPop? moment(new Date()).add(this.props.setFreqPop,'d'): new Date(),
    open:true,
    selectedOperator:!!this.props.selectEditOperatorName? this.props.selectEditOperatorName:'',
    operatorErr : '',
    propertyType : 'all',
    filterBy:'-1',
  }
  }
 componentDidMount=()=>{
        let payload = {
          token: this.props.token,
        }
        let url = this.getUrl(this.state.propertyType);
        this.props.onFetchOperatorListing(payload,url,'schedule');
 }
componentWillReceiveProps(nextProps){
  this.setState({selectedDate: nextProps.dataEdit})
}
 handleClickOpen = () => {
   this.setState({
     open:true
   })
  };
  getUrl = (propertyType) => {
    let url = ''
    switch (propertyType) {
        case 'all':
            url = `/property?limit=${this.state.limit}&page=${this.state.page}`
            break;
        case 'today':
            url = `/property/listProperties?scheduled_type=today&limit=${this.state.limit}&page=${this.state.page}`
            break; 
        default:
          url = `/property?limit=${this.state.limit}&page=${this.state.page}&forScheduleProperty=true`            
    }
    return url
}

 handleClose = () => {
  this.props.handleClose(false)
  this.setState({
    emergency:!!this.props.editEmergency&& this.props.editEmergency !== undefined&& this.props.editEmergency === true ?true:false,
    selectedDate:this.props.dataEdit ? this.props.dataEdit:new Date(), operatorErr:"", selectedOperator:""
  })
  };

  handleChange = (event) => {
    this.setState({[event.target.name]:event.target.value}) 
  }

  setFromDate =(date)=>{
    this.setState({fromdate:date})
  } 

  setToDate =(date)=>{
    this.setState({todate:date})
  } 
  
  handleDateChange = (date) => {
    this.setState ({
      selectedDate:date
    })
  }
  handleOperatorNameChange= (e) => {
    this.setState ({
      selectedOperator: e.target.value}, ()=>{
        if(!this.state.selectedOperator){
          this.setState({
            operatorErr:'This field is required' 
          })
        }
        else {
          this.setState({
            operatorErr:'' 
          })
        }
      })
  }
  onClickExport=()=>{
    let payload = {
        token: this.props.token,
    }
    this.props.onFetchOperatorListing(payload);

   
}

  handleSchedule = (e) => {
    e.preventDefault()
     const dateTime = moment(this.state.selectedDate).valueOf();
     const date = this.state.selectedDate
   const payload = {
       "dateTime": dateTime,
       "emergency": this.state.emergency,
       "propertyId": this.props.propertyId,
       "propertyTypeId":this.props.propertyTypeId,
       "operatorId":this.state.selectedOperator,
       "date": date,
       status:'',
       statusSumm:this.props.summaryStatus,
       fromDateSumm:this.props.fromDateSumm,
       toDateSumm:this.props.toDateSumm,
     }
  
  if(!this.state.selectedOperator){
      this.setState({
        operatorErr:'This field is required' 
      })
  }  
  if(this.state.operatorErr == '' && this.state.selectedOperator){
    this.props.handleClose(false)
    this.props.onScheduleProperty(payload,this.props)
    this.setState({
      emergency:!!this.props.editEmergency&& this.props.editEmergency !== undefined&& this.props.editEmergency === true ?true:false,
      selectedDate: this.props.dataEdit ? this.props.dataEdit:new Date(),operatorErr: '',  selectedOperator:this.props.selectEditOperatorName?this.props.selectEditOperatorName:''
    })
  }
 }

  handleEmergencyScheduling = (event) => {
      this.setState({[event.target.name]:event.target.checked})
      if(event.target.checked === true){
              this.setState({selectedDate: new Date()})
      }
  }
  
  EditSchedule = (e)=>{
    e.preventDefault()
    const dateTime = moment(this.state.selectedDate).valueOf();
    const date = new Date(this.state.selectedDate)
    const payload = {
      "dateTime": dateTime,
      "emergency": this.state.emergency,
      "propertyId": this.props.propertyId ,
      "scheduleId":this.props.scheduleIdEdit,
      "propertyTypeId":this.props.propertyTypeId,
      "operatorId":this.state.selectedOperator,
      "date": date,
      status:'',
      statusSumm:this.props.summaryStatus,
      fromDateSumm:this.props.fromDateSumm,
       toDateSumm:this.props.toDateSumm,
    }

    if(!this.state.selectedOperator){
      this.setState({
        operatorErr:'This field is required' 
      })
  }  
  if(this.state.operatorErr == '' && this.state.selectedOperator){
    this.props.handleClose(false)
    this.props.onEditScheduleProperty(payload,this.props)
    this.setState({
      emergency:!!this.props.editEmergency&& this.props.editEmergency !== undefined&& this.props.editEmergency === true ?true:false,
      selectedDate: this.props.dataEdit ? this.props.dataEdit:new Date(),operatorErr:'', selectedOperator:this.props.selectEditOperatorName?this.props.selectEditOperatorName:''
    })
  }
}
 render(){
  return (
    <>
    <Dialog open={this.props.open} onClose={this.handleClose} aria-labelledby="form-dialog-title" maxWidth='md'>

    <DialogContent>
      <h2 style={{textAlign:"center", color:"#4CAF50", marginBottom:"5%"}}>{this.props.dataEdit ? "Edit Schedule": 'Add schedule'} </h2>
    <div style={{ overflow: "hidden", height: "100%", width: "100%" }}>
    {!!this.props.summaryStatus&&this.props.summaryStatus === "refused"||!!this.props.summaryStatus&&this.props.summaryStatus=="expired"||!!this.props.summaryStatus&&this.props.summaryStatus=="cancelled"? "":
      <Grid container justify="center" spacing ={3} direction ="row">
        <Grid align ="center" item xs={12}>
          <FormControlLabel
          control={
            <Switch
              checked={this.state.emergency}
              onChange={this.handleEmergencyScheduling}
              name="emergency"
              style ={{color:'#4CAF50'
              }}
            />
          }
          label="Emergency"
        />
        </Grid>
      </Grid>
      }
      <Grid container justify="center" spacing ={3} direction ="row">
        <Grid align ="center" item xs={12}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            format="dd-MMM-yyyy"
            margin="normal"
            minDate={moment(new Date()).add(this.props.setFreqPop,'d')}
            disabled={this.state.emergency}
            id="date-picker-inline"
            label="Select Date"
            value={this.state.selectedDate == undefined?moment(new Date()).add(this.props.setFreqPop,'d'): this.state.selectedDate }
            onChange={this.handleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
        />  
        </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
    
    
  <Grid item xs={12}>
  <FormControl style={{width:"230px"}}>
  <h5 style={{color:"#444444",fontSize:'small',fontWeight:'600',marginBottom:"8px",marginTop:"6px",marginLeft:"1px"}} className="Config">Operator Name</h5>
      <NativeSelect
      value={this.state.selectedOperator}
      input={<BootstrapInput  />}
      onChange={this.handleOperatorNameChange}
      style={{marginBottom:"3px",color:"#444444",fontSize:'small',fontWeight:'300'}}
      >
        <option value=''>Select Operator</option>
          {this.props.operatorListing.map((item)=>{
                  return   <option value={item.operatorId}> {item["name"]}</option>
          })}
      </NativeSelect>
      <div style={{fontSize:12,color:"red"}}>{this.state.operatorErr}</div> 
    </FormControl>
    </Grid>
                    
</div>
</DialogContent>
    <DialogActions>
      <Button style ={{color:"#4CAF50",textTransform: 'capitalize'}} variant="outlined" onClick={this.handleClose}>
        Cancel
      </Button>
      {this.props.dataEdit? <Button style ={{color:"#ffffff",backgroundColor:"#4CAF50",textTransform:'capitalize'}} variant="contained" onClick={this.EditSchedule}>
      Edit Schedule
      </Button> :   <Button style ={{color:"#ffffff",backgroundColor:"#4CAF50",textTransform:'capitalize'}} variant="contained" onClick={this.handleSchedule}>
      Schedule
      </Button>}
   
    </DialogActions>
  </Dialog> 
    </>
  );
 }
 
}

const mapStateToProps = (state,props) => {
  return({
    token: state.auth.token,
    operatorListing:!!state.operatorListing.operatorListing&&state.operatorListing.operatorListing,
  })
}

const mapDispatchToProps = (dispatch)=> {
  return{
    onScheduleProperty : (payload,props) => dispatch (actionCreators.scheduleProperty(payload,props)),
    onFetchPropertyHeaders: (payload) => dispatch(actionCreators.fetchPropertyHeaders(payload)),
    onFetchPropertyListing: (payload,url,type) => dispatch(actionCreators.fetchPropertyListing(payload,url,type)),
    onFetchOperatorListing: (payload,url,type) => dispatch(actionCreators.fetchOperatorListing(payload,url,type)),
    onEditScheduleProperty : (payload,props)=> dispatch(actionCreators.EditScheduleProperty(payload, props)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleDialog)
