import React, { Component, useEffect } from 'react'
import { connect } from 'react-redux'
import InputBase from '@material-ui/core/InputBase';
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import swal from 'sweetalert'
import axios from '../../http-config'
import moment from 'moment'
import ReactPaginate from 'react-paginate';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import * as actionsCreators from '../../store/actions/index'
import Dialog from '@material-ui/core/Dialog';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { Grid, } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import InputBase from '@material-ui/core/InputBase';
const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        height:"28px",
        borderRadius: 4,
        position: 'relative',
        backgroundColor: "#fff",
        border: '1px solid #4CAF50',
        fontSize: 15,
        padding: '7px 26px 7px 20px',
        transition: theme.transitions.create(['border-color']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            'Open Sans',
            'sans-serif',

        ].join(','),
        '&:focus ': {
            borderRadius: 4,
            backgroundColor: "#fff !important"

        }
    },
}))(InputBase);
export class SanitaryInspector extends Component {
    state = {
        anchorEl: null,
        open: false,
        id: '',
        page: 1,
        limit: 10,
        selected: [],
        currentSortId: '',
        sortObj: {
            key: '',
            value: ''
        },
        controls: {
            search: {
                value: '',
            }
        },
        isDefaultValue: '',
        tfaIDvalue: '',
        anchorEl_bulk_property: null,
        placement_property: '',
        open_bulk_property: false,
        username: "",
        email: "",
        role: "",
        ulb: "",
        roleOptions: [],
        ulbOptions: [],
        usernameErr: "",
        emailErr: "",
        roleErr: "",
        ulbErr: "",
        roleName: undefined,
        iotdashboard: false,
        stateDashboard: false,
        checkValue: false,
        openSelectulb: false,
        ULBselectionList: [],
        openAddUser: false,
        ulbId: localStorage.getItem("ulbid"),
        ConfigurableDayUser: '',
        ulbData: [],
        firstName:'',
        firstNameErr:"",
        lastName:"",
        lastNameErr:'',
        password: "",
        passwordError: "",
        designationErr:'',
        ConfigurableDayUserErr:'',
        isPasswordShown: false,
        zone:"",
        zonesOptions: [],
        wardsOptions:[],
        zoneId: "",
    }
    componentDidMount() {
        const payload = {
            token: this.props.token,
            page: this.state.page,
            limit: this.state.limit,
            sortName: this.state.sortObj.key,
            sortValue: this.state.sortObj.value,
            searchQuery: this.state.controls.search.value
        }
        this.props.onFetchInspectorLIsting(payload)
        axios({
            method: 'get',
            url: `/role/`,
            headers: { 'Authorization': `Bearer ${this.props.token}` }
        })
            .then((response) => {
                this.setState({ roleOptions: response.data.data.filter((item) => item.roleId == 8) })
            })


        axios({
            method: 'get',
            url: '/load_template/master_data',
            headers: { 'Authorization': `Bearer ${this.props.token}` }
        })
            .then((response) => {
                this.setState({ ulbOptions: response.data.data['master-data'][3].data })
                // setUlbOptions(response.data.data['master-data'][3].data)
            })

        if (this.state.ulbId !== null) {
            const url = `/ulb/${this.state.ulbId}`
            axios({
                method: 'get',
                url: url,
                headers: { 'Authorization': `Bearer ${this.props.token}` }
            })
                .then((response) => {
                    this.setState({ ulbData: [response.data.data.ulbs[0]] })
                    localStorage.setItem('ReportsEmail', response.data.data.ulbs[0].email)
                })
                .catch((err) => {

                })
        }
        if (localStorage.getItem("isZoneNotRequired") === "true") {
            axios({
                method: 'get',
                url: `/ward`,
                headers: { 'Authorization': `Bearer ${this.props.token}` }
            })
                .then((response) => {
                    console.log(response,"response warddddd");
                    this.setState({ wardsOptions: response.data.data.wards })
                })
        }
        if (localStorage.getItem("isZoneNotRequired") === "false") {
            axios({
                method: 'get',
                url: `/zone/getZones`,
                headers: { 'Authorization': `Bearer ${this.props.token}` }
            })
                .then((response) => {
                    this.setState({ zonesOptions: response.data.data.zones })
                })
        }
    }
    handleClickMoreVertIcon = (event, id, isDefaultValue, tfaIDvalue) => {
        this.setState({ open: !this.state.open, anchorEl: event.currentTarget, id, isDefaultValue, tfaIDvalue })
    }

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        this.setState({ page: selectedPage + 1 })
        let payload = {
            token: this.props.token,
            page: selectedPage + 1,
            limit: this.state.limit,
            sortName: this.state.sortObj.key,
            sortValue: this.state.sortObj.value,
            searchQuery: this.state.controls.search.value
        }
        this.props.onFetchInspectorLIsting(payload)
    };

    handleClickAwayEdit = () => {
        this.setState({ open: false });
    };
    handleDelete = (id) => {
        const payload = {
            token: this.props.token,
            id: id

        }
        this.props.onDeleteSIUser(payload)
    }

    handleSort = (e, sortBy) => {
        let sortObj
        if (this.state.sortObj.key === sortBy.accessor) {
            let value = (this.state.sortObj.value === 'desc') ? 'asc' : 'desc';
            sortObj = {
                key: sortBy.accessor,
                value: value
            }
        }
        else {
            sortObj = {
                key: sortBy.accessor,
                value: 'desc'
            }
        }

        this.setState({ sortObj: sortObj, currentSortId: sortBy.accessor });
        let payload = {
            token: this.props.token,
            sortName: sortObj.key,
            sortValue: sortObj.value,
            page: this.state.page,
            limit: this.state.limit,
            searchQuery: this.state.controls.search.value
        }

        this.props.onFetchInspectorLIsting(payload);

    }
    handleExport = () => {
        let payload = {
            token: this.props.token,
            page: this.state.page,
            limit: this.state.limit,
            sortName: this.state.sortObj.key,
            sortValue: this.state.sortObj.value,
            searchQuery: this.state.controls.search.value
        }
        this.props.onFetchSanitoryListingExport(payload)
    }
    handlePropertySearch = (e) => {
        e.preventDefault()
        let searchVal = this.state.controls.search.value
        if (!!searchVal && searchVal.length >= 2) {
            let payload = {
                token: this.props.token,
                page: this.state.page,
                limit: this.state.limit,
                sortName: this.state.sortObj.key,
                sortValue: this.state.sortObj.value,
                searchQuery: searchVal

            }
            this.props.onSearchSIName(payload)
        } else {

            swal("Enter atleast 2 characters")
        }
    }
    inputChangedHadler = (event, controlName) => {
        const updatedControls = {
            ...this.state.controls,
            [controlName]: {
                ...this.state.controls[controlName],
                value: event.target.value,
            }
        }
        this.setState({ controls: updatedControls });
        if (updatedControls.search.value === '') {
            let payload = {
                token: this.props.token,
                page: this.state.page,
                limit: this.state.limit,
                sortName: this.state.sortObj.key,
                sortValue: this.state.sortObj.value,
                searchQuery: ""
            }
            this.props.onSearchSIName(payload)
        }
    }
    handleNewUser = () => {
        this.setState({ openAddUser: true })
    }
    handleChange = (e) => {
        if (e.target.name == "zone") {
           axios({
                method: 'get',
                url: localStorage.getItem("isZoneNotRequired") === "true" ? `/ward` : `/ward?zoneId=${e.target.value.Id}`,
                headers: { 'Authorization': `Bearer ${this.props.token}` }
            })
                .then((response) => {
                    this.setState({ wardsOptions: response.data.data.wards, ZoneName_tn: e.target.value.Name_tn, zoneId: e.target.value.Id })
                }).catch((err) => {
                    console.log(err, "errr");
                })
           
        }
        this.setState({ [e.target.name]: e.target.value })
    }
    checkValidity = () => {
        let isError = false
        if (!this.state.username) {
            isError = true
            this.setState({ usernameErr: '*This field is required' })
        } else {
            this.setState({ usernameErr: '' })

        }
        if (!this.state.firstName) {
            isError = true
            this.setState({ firstNameErr: '*This field is required' })
        } else {
            this.setState({ firstNameErr: '' })

        }
        if (!this.state.lastName) {
            isError = true
            this.setState({ lastNameErr: '*This field is required' })
        } else {
            this.setState({ lastNameErr: '' })

        }

        if (!this.state.ulb) {
            isError = true
            this.setState({ ulbErr: '*This field is required' })
        } else {
            this.setState({ ulbErr: '' })

        }
        if(this.state.email){
            var mailformat= /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
            if(!this.state.email.match(mailformat)){
                isError=true
                this.setState({emailErr:'*You have entered invalid email address'})
            }
            else{
                this.setState({emailErr:''})
            }
        }
        else if(!this.state.email){
            // isError=false
            this.setState({emailErr : ''})
        }

       
        if (!this.state.password) {
            isError = true;
            this.setState({ passwordError: "*Password cannot be blank" })
        }
        else if (this.state.password.length > 0 && this.state.password.length < 8) {
            isError = true;
            this.setState({ passwordError: "*Password Should be atleast 8 characters long" })
        }
        else if (this.state.password.length > 7 && this.state.password.search(/[0-9]/) == -1) {
            isError = true;
            this.setState({ passwordError: "*Password must contain atleast 1 Number" })
        }
        else if (this.state.password.length > 7 && this.state.password.search(/[a-z]/) == -1) {
            isError = true;
            this.setState({ passwordError: "*Password must contain atleast 1 Lowercase" })
        }
        else if (this.state.password.length > 7 && this.state.password.search(/[A-Z]/) == -1) {
            isError = true;
            this.setState({ passwordError: "*Password must contain atleast 1 Uppercase" })
        }
        else if (this.state.password.length > 7 && this.state.password.search(/[!/@/#/%/^/&/*/(/)/_/-/+/=/</>/,/./|/]/) == -1) {
            isError = true;
            this.setState({ passwordError: "*Password must contain atleast 1 Special Symbol" })
        }
        else {
            this.setState({ passwordError: "" })
        }
        if (this.state.roleName !== undefined) {
            isError = true
            this.setState({ roleErr: '*This field is required' })
        } else {
            this.setState({ roleErr: '' })

        }
        if (!this.state.designation) {
            isError = true
            this.setState({ designationErr: '*This field is required' })
        } else {
            this.setState({ designationErr: '' })

        }

        if (!this.state.ConfigurableDayUser) {
            isError = true
            this.setState({ ConfigurableDayUserErr: '*This field is required' })
        } else {
            this.setState({ ConfigurableDayUserErr: '' })

        }
        return isError
    }
  
    handleCheckboxClick = (event, id) => {
        event.stopPropagation();
        // const checkingAssignZoneWard = this.state.SI_zone_ward_assign.find((el) => JSON.parse(el.siWardid).find((data) => data.wardId === id.wardId));
        // if (checkingAssignZoneWard == undefined) {
        //     const statsvalue = event.target.value
        //     if (event.target.checked == true) {
        //         this.setState({ checkValue: true })
        //     }
        //     else {
        //         this.setState({ checkValue: false })
        //     }

            const { selected } = this.state;
            const selectedIndex = selected.indexOf(id);
            let newSelected = [];
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selected, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selected.slice(1));
            } else if (selectedIndex === selected.length - 1) {
                newSelected = newSelected.concat(selected.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selected.slice(0, selectedIndex),
                    selected.slice(selectedIndex + 1)
                );
            }
            this.setState({ selected: newSelected, });


        // // }
        // else {
        //     const wardData = JSON.parse(checkingAssignZoneWard.siWardid)
        //     const wardNumber = wardData.find((data) => data.wardId == id.wardId)
        //     swal({ icon: "warning", title: `Already assinged Zone: ${checkingAssignZoneWard.zoneName}& ward:${wardNumber.wardId}.` })
        //     this.setState({ selected: [], checkValue: false });
        // }
    };
    handleSubmitAdduser = () => {
        const err = this.checkValidity()
        if(!err) {

        
        const payload = {
            token: this.props.token,
            firstName:this.state.firstName,
            lastName:this.state.lastName,
            userName: this.state.username,
            limit: this.state.limit,
            page: this.state.page,
            ULBList: JSON.stringify([]),
            email: this.state.email,
            roleId: this.state.role.roleId,
            ulbId: this.state.ulb,
            Designation: this.state.designation,
            ConfigurableDay:this.state.ConfigurableDayUser,
            zoneId: this.state.zoneId,
            wardId: this.state.selected,
            password: this.state.password,
            isModuleAccess: {
                "IsDashboardAccess": false,
                "IsPropertyManagementAccess": true,
                "IsSchedulePropertyAccess": true,
                "IsTreatmentFacilityAccess": false,
                "IsOperatorManagementAccess": false,
                "IsLoadRecordAccess": false,
                "IsWardManagementAccess": false,
                "IsIOTDashboardAccess": false,
                "IsStateDashboardAccess": false,
                "IsULBManagementAccess": false,
                "IsRoleManagementAccess": false,
                "IsUserManagementAccess": false,
                "IsClusterULBManagementAccess": false
            }
        }
        this.props.onAddUser(payload, this.props)
        this.setState({
            openAddUser: false,
            username: "",
            email: "",
            roleName: "",
            roleErr:"",
            ulb: "",
            ulbErr:"",
            ConfigurableDayUser:"",
            ConfigurableDayUserErr:"",
            designation:"",
            designationErr:"",
            firstName:"",
            firstNameErr:"",
            lastName:"",
            lastNameErr:'',
            password:"",
            passwordError:""
        })
    }
    }
    handleCloseEdituser = () => {
        this.setState({ openAddUser: false, 
        username: "",
        usernameErr:"",
        email: "",
        emailErr:"",
        role: "",
        
        ulb: "",
        ConfigurableDayUser:"",
        designation:"", 
        password:"",

    })

    }
    handleDayChangeUser=(e)=>{
        this.setState ({
          ConfigurableDayUser: e.target.value,
          dayChangedUser:true
      })
      }
      toggleVisibility=()=>{
        const{isPasswordShown}=this.state
        this.setState({isPasswordShown:!isPasswordShown})
      }
    render() {
        let header = [
            {
                accessor: "id",
                filter: "false",
                header: "Sr.no",
                sortable: "false",
            },
            {
                accessor: "firstName",
                filter: "false",
                header: "First Name",
                sortable: "false",
            },
            {
                accessor: "lastName",
                filter: "false",
                header: "Last Name",
                sortable: "false",
            },
            {
                accessor: "userName",
                filter: "false",
                header: "User Name",
                sortable: "false",
            },
            {
                accessor: "ULB",
                filter: "false",
                header: "ULB/Cluster",
                sortable: "false",
            },
            {
                accessor: "email",
                filter: "false",
                header: "Email",
                sortable: "false",
            },
            {
                accessor: "designation",
                filter: "false",
                header: "Designation",
                sortable: "false",
            },
            {
                accessor: "updatedAt",
                filter: "false",
                header: "Updated Date",
                sortable: "false",
            },
            {
                accessor: "actionButton",
                filter: "false",
                header: "Action",
                sortable: "false",
            }
        ]
        const Headers = !!header && header.map(header => {
            return (
                <TableCell key={header.header} className={"white-space"}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'start',
                        whiteSpace: 'break-spaces',
                        height: '3em'
                    }}>
                        {header.header === "Action" ? "" :
                            <div onClick={(e) => this.handleSort(e, header)}>
                                <div>{
                                    this.state.currentSortId === header.accessor ? this.state.sortObj.value === 'asc' ? (<ArrowDropDownIcon fontSize="small" />) : (<ArrowDropUpIcon fontSize="small" />) : (<ArrowDropDownIcon fontSize="small" />)
                                }
                                </div>
                            </div>}
                        {header.header}
                    </div>
                </TableCell>
            )
        })

        const listingInspector = !!this.props.listingInspector && this.props.listingInspector.map((data, index) => {
            const id = data['userId']
            return (
                <TableRow key={index}
                >
                    {!!header && header.map(header => {
                        if (header.accessor == 'actionButton') {
                            return <TableCell key={header.accessor}>
                                <IconButton onClick={(e) => this.handleClickMoreVertIcon(e, id)}
                                    aria-label="more"
                                    aria-controls="long-menu"
                                    aria-haspopup="true">
                                    <MoreVertIcon fontSize='small' />
                                </IconButton>
                                <Popper open={this.state.open} anchorEl={this.state.anchorEl}>
                                    <ClickAwayListener onClickAway={this.handleClickAwayEdit}>
                                        <Paper style={{ boxShadow: "none", border: "1px solid rgb(61, 171, 53)" }}>
                                            <MenuList>

                                                <Link to={`/Sanitory-Manegement/${this.state.id}`} className='email_link'><MenuItem>Edit</MenuItem></Link>
                                                <Link to={`/Sanitory-Manegement/zone/ward/${this.state.id}`} className='email_link'><MenuItem>Zones/Wards Listing</MenuItem></Link>

                                                <MenuItem style={{ color: '#3dab35' }}
                                                    onClick={() => {
                                                        swal({
                                                            title: "Are you sure?",
                                                            text: "Once deleted, you will not be able to recover this data!",
                                                            icon: "warning",
                                                            buttons: {
                                                                cancel: 'No',
                                                                confirm: 'Yes'
                                                            }
                                                        })
                                                            .then((willDelete) => {
                                                                if (willDelete) {
                                                                    this.handleDelete(this.state.id);
                                                                }
                                                            });
                                                    }}
                                                >Delete</MenuItem>

                                            </MenuList>
                                        </Paper>
                                    </ClickAwayListener>
                                </Popper>
                            </TableCell>
                        }
                        if (header.accessor == "updatedAt") {
                            return <TableCell style={{
                                whiteSpace: "normal",
                                wordWrap: "break-word"
                            }} key={index} >{moment(data[header.accessor]).format('DD-MMM-YYYY hh:mm a')}</TableCell>
                        }

                        else {
                            return <TableCell style={{
                                whiteSpace: "normal",
                                wordWrap: "break-word"
                            }} key={header.accessor}
                                className={"white-space"}>
                                {data[header.accessor]}
                            </TableCell>
                        }
                    })
                    }
                </TableRow>
            )
        })
        return (
            <div>
                <div className="dashboard_right_content" style={{ marginTop: "-4%" }}>
                    <img className="dashboard_right_content_top_icon" src="images/Rectangle.svg" />
                    <div className="dashboard_right_content_top">
                        <h5>ULB Officer Management</h5>
                        <div className="dashboard_right_content_top_options">
                            <div className="dashboard_right_content_top_search">
                                <Paper component="form">
                                    <IconButton aria-label="menu">
                                        <img src="images/search_icon.svg" />
                                    </IconButton>
                                    <InputBase onChange={(event) => this.inputChangedHadler(event, 'search')} placeholder="Name" />
                                    <Button onClick={this.handlePropertySearch} variant="contained" color="primary" type="submit">Search</Button>
                                </Paper>
                            </div>

                            <Button style={{ width: '100%' }} onClick={this.handleExport}>Export</Button>
                            <div>
                                <Button style={{ width: '100%' }} onClick={this.handleNewUser}>Add&nbsp;new&nbsp;User</Button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="operator_listing AreaLIsting scrollward " >
                    <TableContainer component={Paper} style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                        <Table stickyHeader aria-label="sticky table" style={{ tableLayout: 'fixed' }}>

                            <TableHead >
                                <TableRow >
                                    {Headers}
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {this.props.listingInspector.length === 0 ? <TableRow style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', }}>
                                    No Records Found !!</TableRow> : listingInspector}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                {
                    this.props.totalCount && this.props.totalCount != 0 &&
                    <ReactPaginate
                        previousLabel={"Prev"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        // pageCount={10}
                        pageCount={this.props.totalCount / this.state.limit}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        style={{ borderLeftWidth: '0px' }}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"} />
                }
                <div className="setFreq">
                    <Dialog
                        maxWidth={'md'}
                        fullWidth={true}
                        // open={true}
                        open={this.state.openAddUser}
                        aria-labelledby="responsive-dialog-title">
                        <DialogTitle className="text-center border-none " style={{ color: '#3dab35' }}><span style={{ fontSize: '18px' }}>{"Add User"}</span></DialogTitle>
                        <DialogContent >
                            <div className="create_ulb_content_outer">
                                <div className="create_ulb_content create_ulb_contentPop">
                                    <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                            <FormControl>
                                                <label className="required" htmlFor="firstName">First Name</label>
                                                <TextField id="firstName"
                                                    variant="outlined"
                                                    placeholder="Enter First Name"
                                                    name="firstName"
                                                    value={this.state.firstName}
                                                    onChange={this.handleChange} />
                                                <div style={{ fontSize: 12, color: "red", fontFamily: "'Open Sans', sans-serif", marginTop: "1%" }}>{this.state.firstNameErr}</div>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl>
                                                <label className="required" htmlFor="lastName">Last Name</label>
                                                <TextField id="lastName"
                                                    variant="outlined"
                                                    placeholder="Enter Last Name"
                                                    name="lastName"
                                                    value={this.state.lastName}
                                                    onChange={this.handleChange} />
                                                <div style={{ fontSize: 12, color: "red", fontFamily: "'Open Sans', sans-serif", marginTop: "1%" }}>{this.state.lastNameErr}</div>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl>
                                                <label className="required" htmlFor="username">User Name</label>
                                                <TextField id="username"
                                                    variant="outlined"
                                                    placeholder="Enter User Name"
                                                    name="username"
                                                    value={this.state.username}
                                                    onChange={this.handleChange} />
                                                <div style={{ fontSize: 12, color: "red", fontFamily: "'Open Sans', sans-serif", marginTop: "1%" }}>{this.state.usernameErr}</div>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl>
                                                <label >Password</label>
                                                <OutlinedInput
                                                    onChange={(event) => this.handleChange(event)}
                                                    id="standard-adornment-password"
                                                    type={this.state.isPasswordShown ? "text" : "password"}
                                                    placeholder="Enter your password"
                                                    name="password"
                                                    value={this.state.password}
                                                    endAdornment={this.state.isPasswordShown ?
                                                        (<InputAdornment position="end"><VisibilityOffOutlinedIcon onClick={() => { this.toggleVisibility() }} /></InputAdornment>) :
                                                        (<InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={() => { this.toggleVisibility() }}>
                                                                <img src="images/eye.svg" />
                                                            </IconButton> </InputAdornment>)
                                                    }
                                                    errortext={this.state.passwordError}
                                                />
                                                <div style={{ fontSize: 12, color: "red" }}>{this.state.passwordError}</div>
                                            </FormControl>

                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl>
                                                <label className="required" htmlFor="email">Email</label>
                                                <TextField id="email"
                                                    variant="outlined"
                                                    placeholder="Enter Email"
                                                    name="email"
                                                    value={this.state.email}
                                                    onChange={this.handleChange} />
                                                <div style={{ fontSize: 12, color: "red", fontFamily: "'Open Sans', sans-serif", marginTop: "1%" }}>{this.state.emailErr}</div>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6} >

                                            <FormControl>
                                                <label className="required" htmlFor="role">Role</label>
                                                <Select value={this.state.roleName}
                                                    onChange={this.handleChange}
                                                    name="role">
                                                    {this.state.roleOptions.map((role) => (
                                                        <MenuItem value={role}>{role.name}</MenuItem>
                                                    ))}
                                                </Select>
                                                <div style={{ fontSize: 12, color: "red", fontFamily: "'Open Sans', sans-serif", marginTop: "1%" }}>{this.state.roleErr}</div>
                                            </FormControl>
                                        </Grid>

                                        <Grid xs={6}>
                                            <FormControl>
                                                <label className="required" htmlFor="designation" align="left">Designation</label>
                                                <TextField id="designation"
                                                    variant="outlined"
                                                    value={this.state.designation}
                                                    onChange={this.handleChange}
                                                    name="designation"
                                                    placeholder="Enter designation" />
                                                    <div style={{ fontSize: 12, color: "red", fontFamily: "'Open Sans', sans-serif", marginTop: "1%" }}>{this.state.designationErr}</div>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <FormControl>
                                                <label className="required" htmlFor="ulb">ULB</label>
                                                <Select value={this.state.ulb}
                                                    onChange={this.handleChange}
                                                    name="ulb"
                                                    style={{ width: '99%' }}>
                                                    {!!this.state.ulbOptions && this.state.ulbOptions.map((ulbData) => (
                                                        <MenuItem value={ulbData.id}>{ulbData.name}</MenuItem>
                                                    ))}
                                                </Select>
                                                <div style={{ fontSize: 12, color: "red", fontFamily: "'Open Sans', sans-serif", marginTop: "1%" }}>{this.state.ulbErr}</div>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <div >
                                                <FormControl style={{ color: '#4CAF50', width: "100%", marginRight: "9px" }}>
                                                    <label className='required' style={{ fontSize: '13px' }}>Email Configurable Day</label>
                                                    <NativeSelect
                                                        value={this.state.ConfigurableDayUser}
                                                        onChange={this.handleDayChangeUser}
                                                        input={<BootstrapInput />}
                                                        style={{ color: '#4CAF50', marginTop: '0px' }}>
                                                        <option value="select" >Selcet...</option>
                                                        <option value="Monday" >Monday</option>
                                                        <option value="Tuesday">Tuesday</option >
                                                        <option value="Wednesday">Wednesday</option >
                                                        <option value="Thursday">Thursday</option >
                                                        <option value="Friday">Friday</option >
                                                        <option value="Saturday">Saturday</option>
                                                        <option value="Sunday">Sunday</option >
                                                    </NativeSelect>
                                                    <div style={{ fontSize: 12, color: "red", fontFamily: "'Open Sans', sans-serif", marginTop: "1%" }}>{this.state.ConfigurableDayUserErr}</div>
                                                </FormControl>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    {localStorage.getItem("isZoneNotRequired") === "false" ? <>
                                <Grid item xs={6}>
                                    <label >Zone Selection</label>
                                    <FormControl>
                                        <Select value={this.state.zone} onChange={this.handleChange} name="zone">
                                            {this.state.zonesOptions.map((option) => {
                                                return (
                                                    <MenuItem value={option}>{option.Name}</MenuItem>
                                                )
                                            })}
                                        </Select>

                                    </FormControl>
                                </Grid>
                                {this.state.zone != "" && this.state.wardsOptions.length > 0 ?
                                    <Grid item xs={6}>
                                        <FormControl>
                                            <label >Ward Selection</label>
                                            <div className="scrollPrent">
                                                <List>
                                                    <div className="hidescroll">
                                                        {this.state.wardsOptions.map((item) => {
                                                            return (
                                                                <>

                                                                    <ListItem>
                                                                        <FormControlLabel
                                                                            control={<Checkbox style={{ color: 'green', fontFamily: 'Open sans-serif', transform: 'scale(1.5)', width: 48 }}
                                                                                onClick={event => this.handleCheckboxClick(event, item, true)} name={item.wardId} />}
                                                                            label={item.wardNumber}
                                                                        />

                                                                    </ListItem>


                                                                </>
                                                            )
                                                        })}



                                                    </div>

                                                </List>



                                            </div>
                                        </FormControl>

                                    </Grid>
                                    : ""}
                            </> : localStorage.getItem("isZoneNotRequired") === "true" ? <>
                                <Grid item xs={6}>
                                    <FormControl>
                                        <label >Ward Selection</label>
                                        <div className="scrollPrent">
                                            <List>
                                                <div className="hidescroll">
                                                    {this.state.wardsOptions.map((item) => {
                                                        return (
                                                            <>

                                                                <ListItem>
                                                                    <FormControlLabel
                                                                        control={<Checkbox style={{ color: 'green', fontFamily: 'Open sans-serif', transform: 'scale(1.5)', width: 48 }}
                                                                            onClick={event => this.handleCheckboxClick(event, item, true)} name={item.wardId} />}
                                                                        label={item.wardNumber}
                                                                    />

                                                                </ListItem>
                                                            </>
                                                        )
                                                    })}
                                                </div>

                                            </List>
                                        </div>
                                    </FormControl>
                                </Grid>
                            </> : ""}

                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <div className="create_ulb_buttons">
                                                <Button className="login_submit" variant="outlined" color="primary" onClick={this.handleCloseEdituser}>{("Cancel")}</Button>

                                                <Button className="login_submit" variant="contained" color="primary" onClick={this.handleSubmitAdduser}>Save</Button>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.auth.token,
        listingInspector: state.siReducer.dashboardListing,
        totalCount: state.siReducer.totalCount
    }
}

const mapDispatchToProps = (dispatch) => {
    return ({
        onFetchInspectorLIsting: (payload) => dispatch(actionsCreators.fetchInspectorListing(payload)),
        onDeleteSIUser: (payload) => dispatch(actionsCreators.deleteSIUser(payload)),
        onFetchSanitoryListingExport: (payload) => dispatch(actionsCreators.fetchSanitoryListingExport(payload)),
        onSearchSIName: (payload) => dispatch(actionsCreators.searchSiName(payload)),
        onAddUser: (payload, props) => dispatch(actionsCreators.addUserOfficer(payload, props)),
    })
}
export default connect(mapStateToProps, mapDispatchToProps)(SanitaryInspector);