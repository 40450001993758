import * as actionTypes from '../actionsTypes';
import axios from '../../../http-config';
import swal from 'sweetalert';
import { CodeSharp, ReportSharp } from '@material-ui/icons';
import moment from 'moment';
import * as User  from '../../../config/constant';

export const fetchPropertyListingSuccess = (response) => {
    return {
        type: actionTypes.FETCH_PROPERTYLISTING_SUCCESS,
        propertyListing:response.data.properties,
        propertyTypeList: response.data.propertyTypeList,
        totalCount : response.totalCount ,
        success:true
    }
}

export const fetchOperatorDaySuccess = (response) => {
    return {
        type: actionTypes.FETCH_OPERATORDAY_SUCCESS,
        operatorDay:response.data.ulbs,
          
    }
}
export const fetchPropertyTypeListingSuccess = (response) => {
    return {
        type: actionTypes.FETCH_PROPERTYTYPELISTING_SUCCESS,
        ListPropertyTypes: response.data.propertyTypes

    }
}
export const fetchOperatorListingSuccess = (response) => {
    return {
        type: actionTypes.FETCH_OPERATORLISTING_SUCCESS,
        operatorListing:response.data.operators,
          
    }
}
export const fetchSchedulePropertyZoneSucces =(response)=>{
    return {
        type : actionTypes.FETCH_SCHEDULEPROPERTYZONE_SUCCESS,
        scheduleProperty : response.data.zones
    }
}
export const fetchSchedulePropertyWardSucces =(response)=>{
    return {
        type : actionTypes.FETCH_SCHEDULEPROPERTYWARD_SUCCESS,
        scheduleProperty : response.data.wards
    }
}

export const fetchPropertyListingFail = (error) => {
    return {
        type: actionTypes.FETCH_PROPERTYLISTING_FAIL,
        error:error,
        success:false,
        progress:0
    }
}

export const fetchOperatorListingFail = (error) => {
    return {
        type: actionTypes.FETCH_OPERATORLISTING_FAIL,
        error:error
    }
}


export const fetchPropertyListingStart = () => {
    return {
        type: actionTypes.FETCH_PROPERTYLISTING_START,
        success:false,
    }
}

export const fetchPropertyBulkStart = () => {
    return {
        type: actionTypes.FETCH_PROPERTYBULK_START,
        success:false,
        progress:0
    }
}
export const fetchPropertyBulkSuccess = () => {
    return {
        type: actionTypes.FETCH_PROPERTYBULK_SUCCESS,
        success:true,
        progress:100
    }
}

export const fetchPropertyBulkFail = (error) => {
    return {
        type: actionTypes.FETCH_PROPERTYBULK_FAIL,
        error:error,
        success:false,
        progress:0
    }
}
export const fetchOperatorListingStart = () => {
    return {
        type: actionTypes.FETCH_OPERATORLISTING_START
    }
}
export const fetchSummaryStart = () => {
    return {
        type: actionTypes.FETCH_SUMMARY_START,
        loading:false
    }
}

export const fetchSummarySuccess =(response)=>{
    return {
        type : actionTypes.FETCH_SUMMARY_SUCCESS,
        propertySummary : response.data,
        totalCount:response.totalCount,
        loading:true
    }
}

export const fetchSummaryFail = (error) => {
    return {
        type: actionTypes.FETCH_SUMMARY_FAIL,
        error:error,
        loading:false
    }
}

export const fetchStatusStart = () => {
    return {
        type: actionTypes.FETCH_STATUS_START
    }
}

export const fetchStatusSuccess =(response)=>{
    return {
        type : actionTypes.FETCH_STATUS_SUCCESS,
        status : response
    }
}

export const fetchStatusFail = (error) => {
    return {
        type: actionTypes.FETCH_STATUS_FAIL,
        error:error
    }
}

export const fetchHistoryStart = () => {
    return {
        type: actionTypes.FETCH_HISTORY_START,
        loading: false
    }
}

export const fetchPropertyListingLoadsSuccess= (response)=>{
    return {
        type:actionTypes.FETCH_PROPERTYLISTINGLOAD_SUCCESS,
        propertyListingLoad:response.data,
        totalCountLoads : response.totalCount ,
        successLoad:true
    }
}
export const fetchPropertyListingLoadsStart =()=>{
    return {
        type: actionTypes.FETCH_PROPERTYLISTINGLOAD_START,
        successLoad: false
    }
}
export const fetchPropertyListingLoadsFail =(err)=>{
    return {
        type: actionTypes.FETCH_PROPERTYLISTINGLOAD_FAIL,
        error:err,
        successLoad: true
    }
}
export const fetchHistorySuccess = (response) => {
    return {
        type: actionTypes.FETCH_HISTORY_SUCCESS,
        HistoryProperty:response.data,
        loading: true,
        totalCountHistorty : response.totalCount ,
          
    }
}
export const fetchHistoryFail = (error) => {
    return {
        type: actionTypes.FETCH_HISTORY_FAIL,
        error:error,
        loading:true
    }
}

export const fetchMatrixStart = () => {
    return {
        type: actionTypes.FETCH_MATRIX_START,
        success:false,
    
    }
}
export const fetchMatrixSuccess = (response) => {
    return {
        type: actionTypes.FETCH_MATRIX_SUCCESS,
        success:true,
        Data:response.data,
        totalCount : response.totalCount ,
    }
}

export const fetchMatrixFail = (error) => {
    return {
        type: actionTypes.FETCH_MATRIX_FAIL,
        error:error,
        success:false,
 
    }
}

export const fetchPropertyListing = (payload,url,type) => {  
    let api = ''
    if(!!payload.zoneId && payload.zoneId !== '-1'){
        api =!payload.wardId ? `/property/?zoneId=${payload.zoneId}&page=${payload.page}&limit=${payload.limit}`:`/property/?wardId=${payload.wardId}&page=${payload.page}&limit=${payload.limit}`
    }
    else if(!!payload.searchQuery &&payload.searchQuery.length> 0 ){
     api= `/property/?name=${payload.searchQuery}&limit=${payload.limit}&page=${payload.page}`
    }
    else{
        if(type == 'schedule'){
            api = url? url+`&sortColumnName=${payload.sortName}&sortType=${payload.sortValue}&propertyTypeId=${payload.propertyTypeId}&status=${payload.status}`: `/property?limit=${payload.limit}&page=${payload.page}&sortColumnName=${payload.sortName}&sortType=${payload.sortValue}&propertyTypeId=${payload.propertyTypeId}`
        }else if(type == 'bulk'){
            api = '/property?limit=10&page=1' 
        }
        else{
            api = `/property?limit=${payload.limit}&page=${payload.page}&sortColumnName=${payload.sortName}&sortType=${payload.sortValue}&propertyTypeId=${payload.propertyTypeId}`
        }
    }
   
    let token = payload.token;
    return dispatch => {
        dispatch(fetchPropertyListingStart());
        axios({
            method : 'get',
            url : api,
            headers :  {'Authorization': `Bearer ${token}`}
        })
        .then((response) => {
            const fetchedpropertyListingResponse = response.data
            dispatch(fetchPropertyListingSuccess(fetchedpropertyListingResponse));
        })
        .catch((err) => {
            dispatch(fetchPropertyListingFail(err))
        })
    }
}


export const fetchOperatorListing = (payload,url,type) => {
    let api = '/operator/?list=true';
    let token = payload.token;
    return dispatch => {
        dispatch(fetchOperatorListingStart());
        axios({
            method : 'get',
            url : api,
            headers :  {'Authorization': `Bearer ${token}`}
        })
        .then((response) => {
            const fetchedoperatorListingResponse = response.data
            dispatch(fetchOperatorListingSuccess(fetchedoperatorListingResponse));
        })
        .catch((err) => {
            dispatch(fetchOperatorListingFail(err))
        })
    }
}

export const addDayData = (payload,SubmitProp, props)=>{
    let token=payload.token;
    return (dispatch)=>{
        dispatch(fetchOperatorListingStart())

        let url = '/ulb/updateConfigDAy';
        axios.put(url, payload,{headers:{
            "Authorization":`Bearer ${token}`
        }})
        .then(function (response) {
            const fetchedDayData = response.data.success;
            fetchedDayData && swal({
                title: "You have Successfully Configured the date!!!",
                icon: "success",
                buttons: {
                    confirm: 'Ok'
                }
              })
        }, (err) => {
            swal({icon : "error" , title : err.response.data.error[0]["message"]})  
        })
        .catch(error => {
            //dispatch(fetchUlbDataFail(error))           
        })
        
    }
}
export const fetchOperatorDay = (payload,url,type) => {
    let api = '/ulb/user_ulb';
    let token = payload.token;
    return dispatch => {
        dispatch(fetchOperatorListingStart());
        axios({
            method : 'get',
            url : api,
            headers :  {'Authorization': `Bearer ${token}`}
        })
        .then((response) => {
            const fetchedoperatorDayResponse = response.data
            dispatch(fetchOperatorDaySuccess(fetchedoperatorDayResponse));
        })
        .catch((err) => {
            dispatch(fetchOperatorListingFail(err))
        })
    }
}

//to single ulpoad property
export const AddPropertySingle=(payload)=>{
    let token=localStorage.getItem('token');
    return dispatch => {
        dispatch(fetchPropertyListingStart());
        const payload1 = {
            "name"                : payload.buildingName,
            "Address"             : payload.Address,
            "ownerName"           : payload.ownerName,
            "propertyType"        : payload.propertyType,
            "containmentTypeId"   : payload.containmentType,
            "ulbId"               : payload.ulbName,
            "ZoneId"              : payload.zone,
            "WardId"              : payload.wardNo,
            "AreaId"              : payload.area,
            "isDisableProperty"   : payload.isDisableProperty,
            "operatorName"        : payload.operatorName,
            

        }
        const url = `property/add`   
        axios.post(url,payload,{headers:{
            "Authorization":`Bearer ${token}`
        }})
        .then(function (response) {
            const fetchedResponse = response.data.success;
            
            fetchedResponse && swal({
                title: `Data Added!!!`,
                icon: "success",
                buttons: {
                    confirm: 'Ok'
                }
              })
            dispatch(fetchPropertyListing({limit:10, page:1, token: token, sortName:'',sortValue:'',propertyTypeId:'' }))

              
        }, (err) => {
            swal({icon : "error" , title : err.response.data.error[0]["message"]})  
            dispatch(fetchPropertyListing({limit:10, page:1,token: payload.token, sortName:'',sortValue:'',propertyTypeId:'' }))
        }).catch(err => {
            dispatch(fetchPropertyListingFail(err));
        })
    }
}

// Single Area upload
export const AddAreaSingle=(payload)=>{
    let token=localStorage.getItem('token');
    return dispatch => {
        dispatch(fetchPropertyListingStart());

     
        const url = `/area/addArea`   
        axios.post(url,payload,{headers:{
            "Authorization":`Bearer ${token}`
        }})
        .then(function (response) {
            const fetchedResponse = response.data.success;
            
            fetchedResponse && swal({
                title: `Data Added!!!`,
                icon: "success",
                buttons: {
                    confirm: 'Ok'
                }
              })
            dispatch(fetchPropertyListing({limit:10, page:1, token: token, sortName:'',sortValue:'',propertyTypeId:'' }))

              
        }, (err) => {
            swal({icon : "error" , title : err.response.data.error[0]["message"]})  
            dispatch(fetchPropertyListing({limit:10, page:1,token: payload.token, sortName:'',sortValue:'',propertyTypeId:'' }))
        }).catch(err => {
            dispatch(fetchPropertyListingFail(err));
        })
    }
}

export const fetchPropertyBulkImport = (payload,formData) => {
    let token = payload.token
    return dispatch => {
        dispatch(fetchPropertyBulkStart());
        const url = '/property/bulk'

        axios.post(url,formData,{headers:{
            "Authorization":`Bearer ${token}`
        }})
        .then(function (response) {
            if(response){
                const fetchedLoadsResponse = response.data.success;
                if(fetchedLoadsResponse == true){
                    setTimeout(() =>{
                        dispatch(fetchPropertyBulkSuccess())
                        fetchedLoadsResponse && swal({
                            title: "Data imported successfully!",
                            icon: "success",
                            buttons: {
                                confirm: 'Ok'
                            }
                          }).then((bulk) => {
                            if (bulk) {
                                dispatch(fetchPropertyListing({limit:10, page:1,token: payload.token, sortName:'',sortValue:'',propertyTypeId:'' }, "", "bulk"))
                            } 
                          });
                    },2000)
                }
            }        
        }, (err) => {
            swal({icon : "error" , title : err.response.data.error[0]["message"]})    
            dispatch(fetchPropertyBulkSuccess())
            dispatch(fetchPropertyListing({limit:10, page:1,token: payload.token, sortName:'',sortValue:'',propertyTypeId:'' }, "", "bulk"))

        })
        .catch(error => {
            dispatch(fetchPropertyBulkFail(error));
        })
    }
}

export const fetchAreaBulkImport = (payload,formData) => {
    let token = payload.token

    return dispatch => {
        dispatch(fetchPropertyBulkStart());
        const url = '/area/bulk'

        axios.post(url,formData,{headers:{
            "Authorization":`Bearer ${token}`
        }})
        .then(function (response) {
            if(response){
                const fetchedLoadsResponse = response.data.success;
                if(fetchedLoadsResponse == true){
                    setTimeout(() =>{
                        dispatch(fetchPropertyBulkSuccess())
                        fetchedLoadsResponse && swal({
                            title: "Data imported successfully!",
                            icon: "success",
                            buttons: {
                                confirm: 'Ok'
                            }
                          }).then((bulk) => {
                            if (bulk) {
                            // dispatch(fetchPropertyListing(payload))
                            dispatch(fetchPropertyListing({limit:10, page:1,token: payload.token, sortName:'',sortValue:'',propertyTypeId:'' }))
                            } 
                          });
                    },2000)
                }
            }
              
        }, (err) => {
            swal({icon : "error" , title : err.response.data.error[0]["message"]})   
            dispatch(fetchPropertyBulkSuccess())
            dispatch(fetchPropertyListing({limit:10, page:1,token: payload.token, sortName:'',sortValue:'',propertyTypeId:'' }))
           
        })
        .catch(error => {
            dispatch(fetchPropertyListingFail(error));
        })

    }
}

export const editPropertyData = (formdata,props ,payload ) => {
    const id= formdata.id
    // let token=localStorage.getItem('token');    
    let token= payload.token
        return dispatch => {
            dispatch(fetchPropertyListingStart());
            const url = `property/${id}`   
            axios.put(url,formdata,{headers:{
                "Authorization":`Bearer ${token}`
            }})
            .then(function (response) {
                const fetchedResponse = response.data.success;
                fetchedResponse && swal({
                    title: "You have Successfully edited the data!!!",
                    icon: "success",
                    buttons: {
                        confirm: 'Ok'
                    }
                  })
                //   User.SANITARYINSPECTOR ?   props.history.push(`/`)  : props.history.push(`/property-management`)  
                 dispatch(fetchPropertyListing({token: token, page:payload.page, limit:10, sortName:'',sortValue:'',propertyTypeId:'' }))
            }, (err) => {
                swal({icon : "error" , title : err.response.data.error[0]["message"]})  
                dispatch(fetchPropertyListing({token: payload.token, page:payload.page, limit:10, sortName:'',sortValue:'',propertyTypeId:'' }))

            }).catch(err => {
                dispatch(fetchPropertyListingFail(err));
            })
        }
}

export const deleteProperty = (payload, props)=>{
    return dispatch =>{
        const url = `/property/${payload.propertyId}`
        axios.delete(url,{ headers: {'Authorization': `Bearer ${payload.token}`}})
        .then(function (response) {
            const fetchedResponse = response.data.success;
            dispatch(fetchPropertyListing(payload,'','schedule'));
            fetchedResponse && swal({
                title: "You have Successfully Deleted Property!!!",
                icon: "success",
                buttons: {
                    confirm: 'Ok'
                }
              })
              User.SANITARYINSPECTOR ?   props.history.push(`/`)  : props.history.push(`/property-management`)  
              
        }, (err) => {
            swal({icon : "error" , title : err.response.data.error[0]["message"]})  
        }).catch(err => {
            dispatch(fetchPropertyListingFail(err));
        })
    }
   
}

export const scheduleProperty = (payload,props) => {
    let token = localStorage.getItem('token');
        return dispatch => {
            dispatch(fetchPropertyListingStart());
            const url = `schedule`   
            axios.post(url,payload,{headers:{
                "Authorization":`Bearer ${token}`
            }})
            .then(function (response) {
                const fetchedResponse = response.data.success;
                if(!!payload.statusSumm&&payload.statusSumm === 'refused' ||!!payload.statusSumm&&payload.statusSumm === 'expired'||!!payload.statusSumm&&payload.statusSumm === 'cancelled'){
                    dispatch(loadDataSummmary({token:token,fromDateSumm: payload.fromDateSumm, toDateSumm:payload.toDateSumm, statusSumm:payload.statusSumm }))
                }
                else{
                    dispatch(fetchPropertyListing({token:localStorage.getItem('token'),page:props.pageSchedule,limit:10, sortName: props.SORT.key, sortValue:props.SORT.value, propertyTypeId: payload.propertyTypeId,status:payload.status},`/property?limit=${10}&page=${props.pageSchedule}`,'schedule'));
                }
               
                fetchedResponse && swal({
                    title: `You have Successfully scheduled the Property!!!`,
                    text: `Upcoming Schedule Date is : ${moment(payload.date).format('DD-MM-YYYY')}`,
                    icon: "success",
                    className: "swal-back-edit",
                    buttons: {
                        confirm: 'Ok'
                    }
                  })
                  
            }, (err) => {
                swal({icon : "error" , title : err.response.data.error[0]["message"]})  
            }).catch(err => {
                dispatch(fetchPropertyListingFail(err));
            })
        }
}

export const schedulePropertyZone=()=>{
    let token = localStorage.getItem('token');
    return dispatch =>{
        dispatch(fetchPropertyListingStart());
        const url =`/zone/getZones`
        axios.get(url, {headers:{
            "Authorization":`Bearer ${token}`
        }})
        .then(response=>{
            const fetchedScheduleListingResponse = response.data
            dispatch(fetchSchedulePropertyZoneSucces(fetchedScheduleListingResponse));
        }).catch(err=>{
            dispatch(fetchPropertyListingFail(err));
        })
    }
}

export const schedulePropertyWard =( payload)=>{
    let token = localStorage.getItem('token');
    return dispatch =>{
        dispatch(fetchPropertyListingStart());
        const url =`/ward/zone?zoneId=${payload.zoneId}&forScheduleProperty=true&status=${payload.status}`
        axios.get(url, {headers:{
            "Authorization":`Bearer ${token}`
        }}).then(response=>{
            const fetchedScheduleListingWArdResponse = response.data
            dispatch(fetchSchedulePropertyWardSucces(fetchedScheduleListingWArdResponse));
        }).catch(err=>{
            dispatch(fetchPropertyListingFail(err));
        })
    }
}

export const filterZoneAndWard =(payload)=>{
    const wardIdAvli = payload.wardId
    let token = localStorage.getItem('token');
    return dispatch =>{
        dispatch(fetchPropertyListingStart());
        if(!!wardIdAvli && wardIdAvli.length>0){
            const url = `/property/?wardId=${payload.wardId}&page=${payload.page}&limit=${payload.limit}&forScheduleProperty=true&status=${payload.status}`
            axios.get(url, {headers:{
                "Authorization":`Bearer ${token}`
            }}).then(response=>{
                const fetchedSchedulePropertyListingResponse = response.data
                response && response.data && response.data.data &&
                response.data.data.properties && 
                response.data.data.properties.length == 0 && 
                swal("No Records Found !", {
                    icon: "warning",
                  });
                dispatch(fetchPropertyListingSuccess(fetchedSchedulePropertyListingResponse));
            }).catch(err=>{
                dispatch(fetchPropertyListingFail(err));
            })
        }else{
            const url = `/property/?zoneId=${payload.zoneId}&page=${payload.page}&limit=${payload.limit}` 
            axios.get(url, {headers:{
                "Authorization":`Bearer ${token}`
            }}).then(response=>{
                const fetchedSchedulePropertyListingResponse = response.data
                dispatch(fetchPropertyListingSuccess(fetchedSchedulePropertyListingResponse));
            }).catch(err=>{
                dispatch(fetchPropertyListingFail(err));
            })
        }
       
      
    }
}

export const searchProperty=(payload,Url,type)=>{
    return dispatch =>{
        dispatch(fetchPropertyListingStart());
        const url =type == 'schedule'? Url+`&name=${payload.searchQuery}`: `/property/?name=${payload.searchQuery}&limit=${payload.limit}&page=${payload.page}`
        axios.get(url,{headers:{
            "Authorization":`Bearer ${payload.token}`
        }} ).then(response=>{
            response && response.data && response.data.data &&
            response.data.data.properties && 
            response.data.data.properties.length == 0 && 
            swal("No Records Found !", {
                icon: "warning",
              });
            dispatch(fetchPropertyListingSuccess(response.data));
        }).catch(err=>{
            dispatch(fetchPropertyListingFail(err));
        })
    }
}

export const fetchPropertySummary=(payload)=>{
    return dispatch =>{
        dispatch(fetchSummaryStart());
        const url =payload.viewHistory === 'allData'? `/load_status?fromDate=${payload.fromDate}&allData=true&toDate=${payload.toDate}&status=${payload.status}&sortColumnName=${payload.sortName}&sortColumnType=${payload.sortValue}`: `/load_status?propertyId=${payload.viewHistory}&allData=true&allData=true&status=${payload.status}&sortColumnName=${payload.sortName}&sortColumnType=${payload.sortValue}`
        axios.get(url,{headers:{
            "Authorization":`Bearer ${payload.token}`
        }} ).then(response=>{
            dispatch(fetchSummarySuccess(response.data));
        }).catch(err=>{
            dispatch(fetchSummaryFail(err));
        })
    }
}

export const fetchStatus = (payload)=>{
    return dispatch =>{
        dispatch(fetchStatusStart());
        const url =`/schedule/get_total?fromDate=${payload.fromDate}&toDate=${payload.toDate}&allData=true`
        axios.get(url,{headers:{
            "Authorization":`Bearer ${payload.token}`
        }} ).then(response=>{
            dispatch(fetchStatusSuccess(response.data.data));
        }).catch(err=>{
            dispatch(fetchStatusFail(err));
        })
    }
}

export const exportSummary = (payload) => {
    return dispatch => {
        const url =payload.viewHistory === 'allData'? `/load_status?fromDate=${payload.fromDate}&allData=true&toDate=${payload.toDate}&status=${payload.status}&sortColumnName=${payload.sortName}&sortColumnType=${payload.sortValue}&export=true`: `/load_status?propertyId=${payload.viewHistory}&allData=true&allData=true&status=${payload.status}&export=true`
        axios({
            method: 'get',
            url: url,
            headers: {'Authorization': `Bearer ${payload.token}`}
        })
        .then(function (response) {
            let link1=response.data.data[0].file_path
            
            window.downloadFile = function (link) {
                window.open(link, '_blank');
            }
            window.downloadFile(link1)
          
        }).catch(error => {
        })

    }
}

export const exportProperty = (payload) => {
    return dispatch => {
        const url = `property?export=true`
        axios({
            method: 'get',
            url: url,
            headers: {'Authorization': `Bearer ${payload.token}`}
        })
        .then(function (response) {
            let link1=response.data.data[0].file_path
            
            window.downloadFile = function (link) {
                window.open(link, '_blank');
            }
            window.downloadFile(link1)
          
        }).catch(error => {
        })

    }
}
export const addFreqSchedule=(payload,props)=>{
    const payload1 = {
        propUpdateFreqdays:payload.propUpdateFreqdays
    }
    return dispatch =>{
        dispatch(fetchPropertyListingStart());
        const url = `/property/edit_auto_schedule_day/${payload.propertyId}`
        axios.put(url,payload1,{ headers: {'Authorization': `Bearer ${payload.token}`}})
        .then(function (response) {
            const fetchedResponse = response.data.success;
            dispatch(fetchPropertyListing({token:payload.token,page:1,limit:10,sortName: payload.sortName, sortValue:payload.sortValue, propertyTypeId: payload.propertyTypeId},'','schedule'));
          if(payload.selecedFreq === "custom" ||payload.selecedFreq == '' ){
            fetchedResponse&& swal({
                title: "You have Successfully updated Scheduled Frequency!!!",
                icon: "success",
                text: `Upcoming Schedule Frequency ${moment(new Date()).add(payload.propUpdateFreqdays, 'days').format('DD-MM-YYYY')}.`,
                className: "swal-back-edit",
                buttons: {
                    confirm: 'Ok'
                }
              })
          }
          
             if(payload.selecedFreq === "alternate"){
                fetchedResponse && swal({
                    title: "You have Successfully updated Scheduled Frequency!!!",
                    text: `Upcoming Schedule Frequency ${moment(new Date()).add(payload.propUpdateFreqdays, 'days').format('DD-MM-YYYY')}.`,
                    icon: "success",
                    className: "swal-back-edit",
                    buttons: {
                        confirm: 'Ok'
                    }
                  })
            }
             if(payload.selecedFreq === "daily"){
                fetchedResponse && swal({
                    title: "You have Successfully updated Scheduled Frequency!!!",
                    text: `Upcoming Schedule Frequency ${moment(new Date()).add(payload.propUpdateFreqdays, 'days').format('DD-MM-YYYY')}.`,
                    icon: "success",
                    className: "swal-back-edit",
                    buttons: {
                        confirm: 'Ok'
                    }
                  })
            }
          
              props.history.push('/schedule-property/allData')  
              
        }, (err) => {
            swal({icon : "error" , title : err.response.data.error[0]["message"]})  
        }).catch(err => {
            dispatch(fetchPropertyListingFail(err));
        })
    }
}

export const EditScheduleProperty = (payload, propsData)=>{
    let token = localStorage.getItem('token');
    return dispatch => {
        dispatch(fetchPropertyListingStart());
        const url = `schedule`   
        axios.put(url,payload,{headers:{
            "Authorization":`Bearer ${token}`
        }})
        .then(function (response) {
            const fetchedResponse = response.data.success;
            if(propsData.urlPropertyType === "all"){
                dispatch(fetchPropertyListing({token:localStorage.getItem('token'),
                page:propsData.pageSchedule,limit:10, sortName: propsData.SORT.key, 
                sortValue:propsData.SORT.value, propertyTypeId:payload.propertyTypeId,status:""}
                ,`/property?limit=${10}&page=${propsData.pageSchedule}`,'schedule'));
            }else{
                dispatch(fetchPropertyListing({token:localStorage.getItem('token'),
                page:propsData.pageSchedule,limit:10, sortName: propsData.SORT.key, 
                sortValue:propsData.SORT.value, propertyTypeId:payload.propertyTypeId}
                ,propsData.uRl,'schedule'));
            }
          
            fetchedResponse && swal({
                title: "You have Successfully Edit Scheduled the Property!!!",
                text: `Upcoming Schedule Date is : ${moment(payload.date).format('DD-MM-YYYY')}`,
                icon: "success",
                className: "swal-back-edit",
                buttons: {
                    confirm: 'Ok'
                }
              })
              
        }, (err) => {
            swal({icon : "error" , title : err.response.data.error[0]["message"]})  
        }).catch(err => {
            dispatch(fetchPropertyListingFail(err));
        })
    }
}

export const propertyType=()=>{
    let token = localStorage.getItem('token');
    return dispatch =>{
        dispatch(fetchPropertyListingStart());
        const url = `/property_type`
        axios.get(url,{headers:{
            "Authorization":`Bearer ${token}`
        }}) .then((response) => {
            const fetchedpropertyListingResponse = response.data
            dispatch(fetchPropertyTypeListingSuccess(fetchedpropertyListingResponse));
        })
        .catch((err) => {
            dispatch(fetchPropertyListingFail(err))
        })
    }
}

export const deleteSchedule = (payload, props)=>{
    return dispatch =>{
        const url = `/schedule/${payload.scheduleId}`
        axios.delete(url,{ headers: {'Authorization': `Bearer ${payload.token}`}})
        .then(function (response) {
            const fetchedResponse = response.data.success;
            dispatch(fetchPropertyListing(payload,'','schedule'));
            fetchedResponse && swal({
                title: "You have Successfully Deleted Scheduled Property!!!",
                icon: "success",
                buttons: {
                    confirm: 'Ok'
                }
              })
              props.history.push('/property-summary/all')  
              
        }, (err) => {
            swal({icon : "error" , title : err.response.data.error[0]["message"]})  
        }).catch(err => {
            dispatch(fetchPropertyListingFail(err));
        })
    }
   
}

export const historyOfProperty = (payload)=>{
    let token = payload.token;
    return dispatch => {
        dispatch(fetchHistoryFail());
        axios({
            method : 'get',
            url : `load_status?allData=true&name=${payload.searchQuery}&fromDate=${payload.fromDate}&toDate=${payload.toDate}&sortColumnName=${payload.sortName}&sortColumnType=${payload.sortValue}&propertyTypeId=${payload.propertyTypeId}&status=${payload.status}&limit=10&page=${payload.page}`,
            headers :  {'Authorization': `Bearer ${token}`}
        })
        .then((response) => {
            const fetchedpropertyListingResponse = response.data
            dispatch(fetchHistorySuccess(fetchedpropertyListingResponse));
        })
        .catch((err) => {
            dispatch(fetchHistoryStart(err))
        })
    }
}
export const exportHistory =(payload)=>{
    return dispatch => {
        const url = `load_status?export=true&limit=${payload.limit}&page=${payload.page}&name=${payload.searchQuery}&fromDate=${payload.fromDate}&toDate=${payload.toDate}&sortColumnName=${payload.sortName}&sortColumnType=${payload.sortValue}&propertyTypeId=${payload.propertyTypeId}&status=${payload.status}&allData=true`
        axios({
            method: 'get',
            url: url,
            headers: {'Authorization': `Bearer ${payload.token}`}
        })
        .then(function (response) {
            let link1=response.data.data[0].file_path
            
            window.downloadFile = function (link) {
                window.open(link, '_blank');
            }
            window.downloadFile(link1)
          
        }).catch(error => {
        })

    }
}

export const searchHistoryProperty=(payload)=>{
    return dispatch =>{
        dispatch(fetchHistoryStart());
        
        const url =`load_status?name=${payload.searchQuery}&allData=true&limit=${payload.limit}&page=${payload.page}&fromDate=${payload.fromDate}&toDate=${payload.toDate}&sortColumnName=${payload.sortName}&sortColumnType=${payload.sortValue}&propertyTypeId=${payload.propertyTypeId}&status=${payload.status}`
        axios.get(url,{headers:{
            "Authorization":`Bearer ${payload.token}`
        }} ).then(response=>{
            response && response.data && response.data.data &&
            response.data.data.properties && 
            response.data.data.properties.length == 0 && 
            swal("No Records Found !", {
                icon: "warning",
              });
            dispatch(fetchHistorySuccess(response.data));
        }).catch(err=>{
            dispatch(fetchHistoryFail(err));
        })
    }
}


export const fetchStatusHistory = (payload)=>{
    return dispatch =>{
        dispatch(fetchStatusStart());
        const url =`load_status/historyCount?name=${payload.searchQuery}&allData=true&fromDate=${payload.fromDate}&toDate=${payload.toDate}&sortColumnName=${payload.sortName}&sortColumnType=${payload.sortValue}&propertyTypeId=${payload.propertyTypeId}&status=${payload.status}`
        axios.get(url,{headers:{
            "Authorization":`Bearer ${payload.token}`
        }} ).then(response=>{
            dispatch(fetchStatusSuccess(response.data.data));
        }).catch(err=>{
            dispatch(fetchStatusFail(err));
        })
    }
}


export const isZoneRequired =(payload, props)=>{

    return (dispatch)=>{
        dispatch(fetchOperatorListingStart())

        let url =  `ulb/isZoneRequired`
        axios.put(url, payload,{headers:{
            "Authorization":`Bearer ${payload.token}`
        }})
        .then(function (response) {
            const fetchedDayData = response.data.success;
            fetchedDayData && swal({
                title: "You have Successfully update Zone Required Mode!!!",
                icon: "success",
                buttons: {
                    confirm: 'Ok'
                }
              })
              localStorage.setItem("isZoneNotRequired", response.data.data[0].IsZoneNotRequired)
              window.location.reload()
        }, (err) => {
            swal({icon : "error" , title : err.response.data.error[0]["message"]})  
        })
        .catch(error => {
            //dispatch(fetchUlbDataFail(error))           
        })
        
    }
}


export const state_dashboardAssigned =(payload, props)=>{

    return (dispatch)=>{
        dispatch(fetchOperatorListingStart())

        let url =  `/ulb/state_dashboard`
        axios.put(url, payload,{headers:{
            "Authorization":`Bearer ${payload.token}`
        }})
        .then(function (response) {
            const fetchedDayData = response.data.success;
            fetchedDayData && swal({
                title: "You have Successfully Assigned ULB for State Dashboard!!!",
                icon: "success",
                buttons: {
                    confirm: 'Ok'
                }
              })
              localStorage.setItem("StatedashboardValue", response.data.data[0].StatedashboardValue)
              window.location.reload()
        }, (err) => {
            swal({icon : "error" , title : err.response.data.error[0]["message"]})  
        })
        .catch(error => {
            //dispatch(fetchUlbDataFail(error))           
        })
        
    }
}
export const loadDataSummmary=(payload)=>{
    let token = payload.token;
    return dispatch => {
        dispatch(fetchPropertyListingLoadsStart());
        axios({
            method : 'get',
            url :!!payload.summaryhistoryData&&payload.summaryhistoryData != ''? `load_status?fromDate=${payload.fromDateSumm}&allData=true&toDate=${payload.toDateSumm}&status=${payload.statusSumm}&hyperlink=true&sortColumnName=${payload.sortName}&sortColumnType=${payload.sortValue}&propertyTypeId=${payload.propertyTypeId}`:`load_status?fromDate=${payload.fromDateSumm}&allData=true&toDate=${payload.toDateSumm}&status=${payload.statusSumm}&sortColumnName=${payload.sortName}&sortColumnType=${payload.sortValue}&propertyTypeId=${payload.propertyTypeId}`,
            headers :  {'Authorization': `Bearer ${token}`}
        })
        .then((response) => {
            const fetchedpropertyListingResponse = response.data
            dispatch(fetchPropertyListingLoadsSuccess(fetchedpropertyListingResponse));
        })
        .catch((err) => {
            dispatch(fetchPropertyListingLoadsFail(err))
        })
    }

}

// ulb/updateSummmaryReportsEmail
export const emailUpdate = (payload,props) => {
    return dispatch => {
        dispatch(fetchSummaryStart());
        let url = 'ulb/updateSummmaryReportsEmail';
        axios.put(url, payload,{headers:{
            "Authorization":`Bearer ${payload.token}`
        }})
        .then(response => {
            // dispatch(fetchUlbData(payload));
            const fetchedResponse = response.data.success;
            if(fetchedResponse ===true){
                fetchedResponse && swal({
                    title: "Email address configured successfully",
                    icon: "success",
                    buttons: {
                        confirm: 'Ok'
                    }
                  })
                  localStorage.setItem('summmaryReportsEmail',response.data.data[0].summmaryReportsEmail)
                //   props.history.push('/schedule-property/allData')
                // window.location.reload(true)
            }
             
        }, (err) => {
            swal({icon : "error" , title : err.response.data.error[0]["message"]})          
        })
        .catch(err => {
            dispatch(fetchSummaryFail(err));
        })
    }
}



export const CreateMatrix = (payload)=>{
    return dispatch =>{
        dispatch(fetchMatrixStart())
        let url = 'user/createMatrix';
        axios.post(url, payload,{headers:{
            "Authorization":`Bearer ${payload.token}`
        }})
        .then(response => {
            // dispatch(fetchUlbData(payload));
            const fetchedResponse = response.data.success;
            if(fetchedResponse ===true){
                fetchedResponse && swal({
                    title: "User Created successfully",
                    icon: "success",
                    buttons: {
                        confirm: 'Ok'
                    }
                })
            
            }
            
        }, (err) => {
            swal({icon : "error" , title : err.response.data.error[0]["message"]})          
        })
        .catch(err => {
            dispatch(fetchMatrixFail(err));
        })
    }
}


export const UpdateMatrix = (payload)=>{
    return dispatch =>{
        dispatch(fetchMatrixStart())
        let url = 'user/update_matrixs';
        axios.put(url, payload,{headers:{
            "Authorization":`Bearer ${payload.token}`
        }})
        .then(response => {
            // dispatch(fetchUlbData(payload));
            const fetchedResponse = response.data.success;
            if(fetchedResponse ===true){
                fetchedResponse && swal({
                    title: "User Updated successfully",
                    icon: "success",
                    buttons: {
                        confirm: 'Ok'
                    }
                })
            
            }
            
        }, (err) => {
            swal({icon : "error" , title : err.response.data.error[0]["message"]})          
        })
        .catch(err => {
            dispatch(fetchMatrixFail(err));
        })
    }
}


export const exportStatus = (payload) => {
    return dispatch => {
        const url = `/load_status?propertyId=${payload.propertyTypeId}&allData=true&status=${payload.statusSumm}&export=true&sortColumnName=${payload.sortName}&sortColumnType=${payload.sortValue}&fromDate=${payload.fromDateSumm}&toDate=${payload.toDateSumm}`
        axios({
            method: 'get',
            url: url,
            headers: {'Authorization': `Bearer ${payload.token}`}
        })
        .then(function (response) {
            let link1=response.data.data[0].file_path
            
            window.downloadFile = function (link) {
                window.open(link, '_blank');
            }
            window.downloadFile(link1)
          
        }).catch(error => {
        })

    }
}