import React, { Component } from 'react';
import { connect } from 'react-redux'
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { Grid, MenuItem } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import ImageUploader from 'react-images-upload';
import CancelIcon from '@material-ui/icons/Cancel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import axios from '../../http-config'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
// import MenuItem from "@material-ui/core/MenuItem";
// import FormControl from "@material-ui/core/FormControl";
// import Select from "@material-ui/core/Select";
import { withStyles } from '@material-ui/core/styles';
import swal from 'sweetalert';
import { el } from 'date-fns/locale';
class ClusterForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: !!this.props && !!this.props.ULBId ? this.props.ULBId : '',
            tfaId: this.props.tfaId ? this.props.tfaId : '',
            ClusterulbName: !!this.props && !!this.props.clusterUlbName ? this.props.clusterUlbName : '',
            ClusterulbName_tm: !!this.props && !!this.props.clusterUlbName_tn ? this.props.clusterUlbName_tn : '',
            typeId: '',
            ClusterulbNameErr: '',
            ulbnameErr: '',
            ulbname: this.props ? this.props.ULBId : false,
            // TfNameErr: '',
            ulbOptions: [],
            tfOption: [],
            // TfName: this.props ? this.props.tfId : false,
            StatusValue: !!this.props.isDisableULB ? !!this.props.isDisableULB && this.props.isDisableULB === "In Active" ? true : false : '',
            isStatus: null,
            AllSelectedUlb: [],
            AllremovedTf: [],
            tfList: [],
            selectedTable: [],
            AllSelectedTf: [],
            selectedTableTf: [],
            data:{}

        }
    }
    componentDidMount() {
     
    }
    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })

    }
    handleSubmit = (e) => {
        e.preventDefault()
        const err = this.checkValidity()
        if (!err) {
            const formdata = new FormData()
            let token = this.props.token
            for (let i = 0; i < this.state.tfList.length; i++) {
                formdata.append("tfIdList[]", this.state.tfList[i])

            }
            this.props.id && formdata.append("id", this.props.id)
            formdata.append("typeId", this.state.typeId)
            formdata.append("tfaId", this.state.tfaId)
            // formdata.append("tfId", this.state.TfName)
            formdata.append("name", this.state.ClusterulbName)
            formdata.append("name_tn", this.state.ClusterulbName_tm)
            formdata.append("isDisableULB", this.state.StatusValue)

            let SubmitProp = this.props
            this.props.handleSubmit(formdata, SubmitProp)
        }


    }
    checkValidity = () => {
        let isError = false
        if (!this.state.ClusterulbName) {
            isError = true
            this.setState({ ClusterulbNameErr: '*This field is required' })
        }
        else if ((this.state.ClusterulbName.length > 0 && this.state.ClusterulbName.length < 3) || (this.state.ClusterulbName.length > 100)) {
            isError = true
            this.setState({ ClusterulbNameErr: '*Name should be Minimum 3 characters and Maximum 100 characters' })
        }
        else if (this.state.ClusterulbName && this.state.ClusterulbName.length >= 3) {
            this.setState({ ClusterulbNameErr: '' })
        }

      
        return isError
    }

    handlChangeStatus = (e) => {
        this.setState({ isStatus: e.target.value, StatusValue: e.target.value })
    }

    handleChecked = (e) => {
        if (e.target.checked == true) {
            this.state.AllSelectedUlb.push(e.target.value)
            axios({
                method: 'get',
                url: `treatment_facility/ulb/${e.target.value}`,
                headers: { 'Authorization': `Bearer ${this.props.token}` }
            })
                .then((response) => {
                    this.setState({ tfOption: response.data.data })
                })

        }
        if (e.target.checked == false) {
            this.state.AllremovedTf.push(e.target.value)
        }
        this.setState({ [e.target.name]: e.target.checked })

    }
    render() {
        return (
            <div>
                <div className="create_ulb_content_outer">
                    <div className="create_ulb_content">
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <FormControl>
                                    <label className="required" htmlFor="ClusterulbName"> Cluster ULB Name</label>
                                    <TextField id="ClusterulbName"
                                        variant="outlined"
                                        placeholder="Enter Cluster ULB Name"
                                        name="ClusterulbName"
                                        value={this.state.ClusterulbName}
                                        onChange={this.handleChange} />
                                    <div style={{ fontSize: 12, color: "red", fontFamily: "'Open Sans', sans-serif", marginTop: "1%" }}>{this.state.ClusterulbNameErr}</div>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl>
                                    <label htmlFor="ulbName_tm">Cluster ULB Name (Tamil)</label>
                                    <TextField id="ulbName_tm"
                                        variant="outlined"
                                        placeholder="கிளஸ்டர் ULB பெயரை உள்ளிடவும்"
                                        name="ClusterulbName_tm"
                                        value={this.state.ClusterulbName_tm}
                                        onChange={this.handleChange} />
                                </FormControl>
                            </Grid>

                          
                            <Grid item xs={6}>
                                <FormControl>
                                    <label htmlFor="statusType" >Status</label>
                                    <TextField id="select" name="statusType" value={this.state.StatusValue} onChange={this.handlChangeStatus} select>
                                        <MenuItem value={false}>{'Active'}</MenuItem>
                                        <MenuItem value={true}>{'In Active'}</MenuItem>
                                    </TextField>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <div className="create_ulb_buttons">
                                    <Link style={{ textDecoration: "none", color: "#3dab35" }} to="/cluster-management"><Button className="login_submit" variant="outlined" color="primary">Cancel</Button></Link>
                                    <Button className="login_submit" variant="contained" color="primary" onClick={this.handleSubmit}>Save</Button>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>


        );
    }
}

const mapStateToProps = (state, props) => {

    return {
        props: props,
        token: state.auth.token
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(ClusterForm)