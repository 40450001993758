import * as actionTypes from '../../actions/actionsTypes'
import { updateObject } from '../../utility'

const initialState = {
    ulbHeaders   : [],
    loading      : false
}

const fetchUlbHeadersStart = ( state, action ) => {
    return updateObject(state, {
        loading : true
    })
}

const fetchUlbHeadersSuccess = ( state, action ) => {
    return updateObject(state, {
        ulbHeaders : action.headers,
        loading    : false
    })
}

const fetchUlbHeadersFail = ( state, action ) => {
    return updateObject(state, {
        loading  :false
    })
}

const reducer = ( state = initialState, action ) => {
    switch(action.type){
        case actionTypes.FETCH_ULB_HEADERS_START    : return fetchUlbHeadersStart(state, action)
        case actionTypes.FETCH_ULB_HEADERS_SUCCESS  : return fetchUlbHeadersSuccess( state, action)
        case actionTypes.FETCH_ULB_HEADERS_FAIL     : return fetchUlbHeadersFail(state, action)
        default                                     : return state
    }
}

export default reducer;