import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import axios from '../../http-config'
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux'
import * as actionCreators from '../../store/actions/index'
import _ from 'lodash'
import DialogContentText from '@material-ui/core/DialogContentText';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';
import DeletedOutlined from '@material-ui/icons/DeleteOutlined'
import TextField from '@material-ui/core/TextField';

import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';
import Card from "../Dashboard/Card/Card.js"
import CardHeader from "../Dashboard/Card/CardHeader.js";
import CardIcon from "../Dashboard/Card/CardIcon.js";
import CardBody from '../Dashboard/Card/CardBody.js'
import { DatePicker , MuiPickersUtilsProvider} from "@material-ui/pickers";
import moment from 'moment'
import DateFnsUtils from '@date-io/date-fns';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { de } from 'date-fns/esm/locale';
import {Bar} from 'react-chartjs-2';
import Grid from '@material-ui/core/Grid';
import { Paper } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import classes from './editDialog.module.css'
import swal from 'sweetalert';

const emails = ['username@gmail.com', 'user02@gmail.com'];
const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

 class EditDialog extends Component {
  state = {
    chartId:'',
    chartsData:[],
    originalChartData:[],
    chartToEdit : [],
    checkedSensorPH:false,
    sensorPhTf : '',
    sensorPhType : '',
    checkedSensorCOD:false,
    sensorCODTf : '',
    sensorCODType : '',
    checkedSensorBOD:false,
    sensorBODTf : '',
    sensorBODType : '',
    checkedSensorFLRate:false,
    sensorFLRateTf : '',
    sensorFLRateType : '',
    checkedSensorNO3:false,
    sensorNO3Tf : '',
    sensorNO3Type : '',
    checkedSensorORP:false,
    sensorORPTf : '',
    sensorORPType : '',
    checkedSensorTFLRate:false,
    sensorTFLRateTf : '',
    sensorTFLRateType : '',
    checkedSensoTSS:false,
    sensorTSSTf : '',
    sensorTSSType : '',
    mixedChartData:{},
    mixedChartOptions:{},
    isDisabled:false,
    interval:'Current Day',
    todate:new Date('2020-08-31'),
    fromdate:new Date('2020-08-28'),
    open:false
  }

 componentDidMount=()=>{
  
 }

//  componentWillReceiveProps (nextProps){
//      if(nextProps.ChartId){
//         this.setInitialData(nextProps.ChartId) 
//      }
//  }

 handleClickOpen = () => {
   this.setState({
     open:true
   })
  };

 handleClose = () => {
  this.props.onClose(false)
  };

  getFormattedChartData = (chartData,range) => {
    let formatCriteria;
    switch(range){
      case 'today':
        formatCriteria = 'MMM Do, h a'
        break;
      case 'sevenDays':
        formatCriteria = 'MM-DD-YYYY'
        break;
      case 'lastMonth':
        formatCriteria = 'MM-DD-YYYY'
        break; 
      default:
        formatCriteria = 'MMM Do, h a' 
    }
     chartData = chartData.map (data => {
       return {
        ...data,
        ParamValueBOD: parseInt(data.ParamValueBOD),
        ParamValueCOD: parseInt(data.ParamValueCOD),
        ParamValueFLRate: parseInt(data.ParamValueFLRate),
        ParamValueNO3: parseInt(data.ParamValueNO3),
        ParamValueORP: parseInt(data.ParamValueORP),
        ParamValuePH: parseInt(data.ParamValuePH),
        ParamValueTFLRate: parseInt(data.ParamValueTFLRate),
        ParamValueTSS: parseInt(data.ParamValueTSS)
       }
     })

     //to group sensors falling in same date range and find its total
     const reduced = chartData.reduce(function(m, d){
        if(!m[moment(d.DateEntry).format(formatCriteria)]){  
          m[moment(d.DateEntry).format(formatCriteria)] = {...d, count: 1};
          return m;
        }
        m[moment(d.DateEntry).format(formatCriteria)].ParamValueBOD += (d.ParamValueBOD);
        m[moment(d.DateEntry).format(formatCriteria)].ParamValueCOD += (d.ParamValueCOD);
        m[moment(d.DateEntry).format(formatCriteria)].ParamValueFLRate += (d.ParamValueFLRate);
        m[moment(d.DateEntry).format(formatCriteria)].ParamValueNO3 += (d.ParamValueNO3);
        m[moment(d.DateEntry).format(formatCriteria)].ParamValueNO3 += (d.ParamValueNO3);
        m[moment(d.DateEntry).format(formatCriteria)].ParamValueORP += (d.ParamValueORP);
        m[moment(d.DateEntry).format(formatCriteria)].ParamValuePH += (d.ParamValuePH);
        m[moment(d.DateEntry).format(formatCriteria)].ParamValueTFLRate += (d.ParamValueTFLRate);
        m[moment(d.DateEntry).format(formatCriteria)].ParamValueTSS += (d.ParamValueTSS);
        m[moment(d.DateEntry).format(formatCriteria)].count += 1;
        return m;
     },{});

    //  to compute the average
     const result = Object.keys(reduced).map(function(k){
         const item  = reduced[k];
         return {
             DateEntry: moment(reduced[k].DateEntry).format(formatCriteria),
             ParamValueBOD: item.ParamValueBOD/item.count,
             ParamValueCOD: item.ParamValueCOD/item.count,
             ParamValueFLRate: item.ParamValueFLRate/item.count,
             ParamValueNO3: item.ParamValueNO3/item.count,
             ParamValueORP: item.ParamValueORP/item.count,
             ParamValuePH: item.ParamValuePH/item.count,
             ParamValueTFLRate: item.ParamValueTFLRate/item.count,
             ParamValueTSS: item.ParamValueTSS/item.count
         }
     })
     return result
    }

  getFormattedLabels = (chartData) => {
    const labels = chartData.map( label => {
      // return moment(label.DateEntry).format('MMM Do, h:mm a');
      return label.DateEntry
    })
    return labels
  }
    

  getCheckValue = (sensor) => { 
    switch(sensor){
      case 'COD':
        return this.state.checkedSensorCOD
      case 'BOD':
        return this.state.checkedSensorBOD
      case 'PH':
        return this.state.checkedSensorPH
      case 'FLRate':
        return this.state.checkedSensorFLRate 
      case 'NO3':
        return this.state.checkedSensorNO3 
      case 'ORP':
        return this.state.checkedSensorORP
      case 'TFLRate':
        return this.state.checkedSensorTFLRate 
      case 'TSS':
        return this.state.checkedSensoTSS       
      default:
        return false  
    }
  }

  getTfValue = (sensor) => {
    switch(sensor){
      case 'COD':
        return this.state.sensorCODTf
      case 'BOD':
        return this.state.sensorBODTf
      case 'PH':
        return this.state.sensorPhTf 
      case 'FLRate':
        return this.state.sensorFLRateTf
      case 'NO3':
        return this.state.sensorNO3Tf  
      case 'ORP':
        return this.state.sensorORPTf 
      case 'TFLRate':
        return this.state.sensorTFLRateTf 
      case 'TSS':
        return this.state.sensorTSSTf    
      default:
        return ''  
    }
  }

  getTypeValue = (sensor) => {
    switch(sensor){
      case 'COD':
        return this.state.sensorCODType
      case 'BOD':
        return this.state.sensorBODType
      case 'PH':
        return this.state.sensorPhType  
      case 'FLRate':
        return this.state.sensorFLRateType 
      case 'NO3':
        return this.state.sensorNO3Type 
      case 'ORP':
        return this.state.sensorNO3Type  
      case 'TFLRate':
        return this.state.sensorTFLRateType  
      case 'TSS':
        return this.state.sensorTSSType        
      default:
        return ''  
    }
  }

  setSensorValue = (sensor) => {
    switch(sensor){
      case 'COD':
        this.setState({
          checkedSensorCOD:true
        })
        break;
      case 'BOD':
        this.setState({
          checkedSensorBOD:true
        })
        break;
      case 'PH':
        this.setState({
          checkedSensorPH:true
        })
        break;
      case 'FLRate':
        this.setState({
          checkedSensorFLRate:true
        })
        break;  
      case 'NO3':
        this.setState({
          checkedSensorNO3:true
        })
        break;
      case 'ORP':
        this.setState({
          checkedSensorORP:true
        })
        break;
      case 'TFLRate':
        this.setState({
          checkedSensorTFLRate:true
        })
        break;
      case 'TSS':
        this.setState({
          checkedSensorTSS:true
        })
      break;  
      default:
        return false  
    }
  }

  setTfValue = (sensor,tf) => {
    switch(sensor){
        case 'COD':
            this.setState({
                sensorCODTf : tf
            })
            break;
        case 'BOD':
            this.setState({
                sensorBODTf : tf
            })
            break;
        case 'PH':
            this.setState({
                sensorPhTf : tf
            })
            break;
        case 'FLRate':
            this.setState({
                sensorFLRateTf : tf
            })
            break;
        case 'NO3':
            this.setState({
                    sensorNO3Tf : tf
            })
            break;
        case 'ORP':
            this.setState({
                sensorORPTf : tf
            })
            break;
        case 'TFLRate':
            this.setState({
                sensorTFLRateTf : tf
            })
            break;
        case 'TSS':
            this.setState({
                sensorTSSTf : tf
            })
            break;
      }
  }

  setTypeValue = (sensor,type) => {
    switch(sensor){
        case 'COD':
          this.setState({
            sensorCODType : type
          })
          break;
        case 'BOD':
          this.setState({
            sensorBODType : type
          })
          break;
        case 'PH':
          this.setState({
            sensorPhType : type
          })
          break;
        case 'FLRate':
          this.setState({
             sensorFLRateType : type
          })
          break;
        case 'NO3':
          this.setState({
            sensorNO3Type : type
          })
          break;
        case 'ORP':
          this.setState({
            sensorNO3Type : type
          })
          break;
        case 'TFLRate':
          this.setState({
            sensorTFLRateType : type
          })
          break;
        case 'TSS':
          this.setState({
            sensorTSSType : type
          })
          break;
      }
  }

  handleSelectedSensors = (charts,event,sensorName) => {
    if(event.target.name == `checkedSensor${sensorName}`){
      this.setState({[event.target.name]:event.target.checked},() => {
        if(this.getCheckValue(sensorName) && this.getTfValue(sensorName) && this.getTypeValue(sensorName)){
          charts.push({
            'sensor': `${sensorName}`,
            'tf':this.getTfValue(sensorName),
            'type':this.getTypeValue(sensorName)
          })
        }
      })
    }else{
      this.setState({[event.target.name]:event.target.value},() => {
        if(this.getCheckValue(sensorName) && this.getTfValue(sensorName) && this.getTypeValue(sensorName)){
          charts.push({
            'sensor': `${sensorName}`,
            'tf':this.getTfValue(sensorName),
            'type':this.getTypeValue(sensorName)
          })
        }
      })
    }
  
    this.setState({
      chartsData : charts
    })
  } 

 handleSensorPh = (event) => { 
   let charts = this.state.chartsData; 
   charts = charts.filter( charts => {
    return charts.sensor != 'PH'
   })
   this.handleSelectedSensors (charts,event,'PH')
 }  
  
 handleSensorBod = (event) => { 
  let charts = this.state.chartsData; 
  charts = charts.filter( charts => {
   return charts.sensor != 'BOD'
  })
  this.handleSelectedSensors (charts,event,'BOD')
 } 

 handleSensorCod = (event) => { 
  let charts = this.state.chartsData; 
  charts = charts.filter( charts => {
   return charts.sensor != 'COD'
  })
  this.handleSelectedSensors (charts,event,'COD')
 }

 handleSensorFLRate = (event) => {
  let charts = this.state.chartsData; 
  charts = charts.filter( charts => {
   return charts.sensor != 'FLRate'
  })
  this.handleSelectedSensors (charts,event,'FLRate')
 }

 handleSensorNO3 = (event) => {
  let charts = this.state.chartsData; 
  charts = charts.filter( charts => {
   return charts.sensor != 'NO3'
  })
  this.handleSelectedSensors (charts,event,'NO3')
 }

 handleSensorORP = (event) => {
  let charts = this.state.chartsData; 
  charts = charts.filter( charts => {
   return charts.sensor != 'ORP'
  })
  this.handleSelectedSensors (charts,event,'ORP')
 }

 handleSensorTFLRate = (event) => {
  let charts = this.state.chartsData; 
  charts = charts.filter( charts => {
   return charts.sensor != 'TFLRate'
  })
  this.handleSelectedSensors (charts,event,'TFLRate')
 }

 handleSensorTSS = (event) => {
  let charts = this.state.chartsData; 
  charts = charts.filter( charts => {
   return charts.sensor != 'TSS'
  })
  this.handleSelectedSensors (charts,event,'TSS')
 }

  createChart = () => {
      const iotChartData = this.getFormattedChartData(this.props.iotChartData,this.props.range)
      const charts = this.state.chartsData
      if(this.state.chartsData == this.state.originalChartData){
        swal("Are you sure you dont want to edit your graph?", {
          icon: "warning",
          buttons: {
            cancel : 'No',
            confirm: 'Yes'
        },
        })
        .then( (yes) => {
            if(yes){
              const editedData = this.state.chartToEdit
              const metaData = this.props.metaData.map(obj => editedData.find(o => o.id === obj.id) || obj);
            //   this.props.getChartMetaData(metaData)
              this.handleClose()
            }else{
              return
            }
        });
      }
      else if(charts.length > 2){
        swal("Please select only two sensors at a time and then proceed!", {
          icon: "warning",
        });
      }else if(charts.length == 0){
        swal("Please select atleast one sensor and then proceed!", {
          icon: "warning",
          buttons: {
            confirm: 'Ok'
        },
        })   
      }
      else{
        this.handleClose()
      }
      if(iotChartData && charts.length > 0){
        const sensor1chartType = charts[0].type;
        const sensor2chartType = charts.length > 1 ? charts[1].type : '' 
        const sensor1 = charts[0].sensor;
        const sensor2 = charts.length > 1 ? charts[1].sensor : '';
        let sensor1Unit = ''
        let sensor1Data = []
        let sensor2Data = []
        let sensor2Unit = ''
        // let labels = []
        for (const [key, value] of Object.entries(iotChartData[0])) {
          if(key.startsWith('Unit') && key.includes(sensor1)){
          sensor1Unit = value
          }
          if(key.startsWith('Unit') && key.includes(sensor2)){
            sensor2Unit = value
          }
        }

        const labels = this.getFormattedLabels(iotChartData)
        
        sensor1Data = iotChartData.map( data => {
          return data[`ParamValue${sensor1}`]
        })
        sensor2Data = iotChartData.map( data => {
          return data[`ParamValue${sensor2}`]
        })
        
      let datasets = []
      let yAxes = []
      if(charts.length > 1){
        yAxes =  [
          {
            gridLines: {
              display:false
            },
              id: sensor1,
              ticks: {
                  beginAtZero: true,
              },
              scaleLabel: {
                  display: true,
                  labelString: `${sensor1} ${sensor1Unit}`
                }
          },
          {
              gridLines: {
              display:false
              },
              id: sensor2,
              position: 'right',
              ticks: {
                  beginAtZero: true,
              },
              scaleLabel: {
                  display: true,
                  labelString: `${sensor2} ${sensor2Unit}`
                }
          },
      ]

        datasets = this.handleMultipleChartDataSet (sensor1chartType,sensor1,sensor1Data,sensor2chartType,sensor2,sensor2Data)
      }else{
        yAxes =  [
          {
            gridLines: {
              display:false
            },
              id: sensor1,
              ticks: {
                  beginAtZero: true,
              },
              scaleLabel: {
                  display: true,
                  labelString: `${sensor1} ${sensor1Unit}`
                }
          }
      ]
        
      datasets = this.handleSingleChartDataSet (sensor1chartType,sensor1,sensor1Data)
      }
      const options = {
        scales: {
            yAxes: yAxes,
            xAxes: [{
              gridLines: {
                display:false
              },
              ticks: {
                  autoSkip: true,
                  maxTicksLimit: 20
              }
          }]
        }
      }
      const mixedChartData = {
        labels: labels,
        datasets: datasets
      }
      this.setState({
        mixedChartData : mixedChartData,
        mixedChartOptions: options
      })

      let metaData = this.props.metaData
      const editedData = [{
        "id":this.state.chartId,
        "data":mixedChartData,
        "options":options
      }]
      metaData = metaData.map(oldData => editedData.find(o => o.id === oldData.id) || oldData);
    //   this.props.getChartMetaData(metaData)
      this.setState({
        chartsData:[],
      })
      }
  }

  handleSingleChartDataSet = (sensor1chartType,sensor1,sensor1Data) => {
    let datasets = []
    switch(sensor1chartType){
      case 'Line':
          datasets = [{
            type:'line',
            label:sensor1 ,
            backgroundColor: 'rgb(54, 162, 235)',
            borderColor: 'rgb(54, 162, 235)',
              // borderWidth: 2,
              fill: false,
              data: sensor1Data,
              yAxisID: sensor1,
              pointRadius: 2,
          }
        ]
        break;
      case 'Bar':
          datasets = [{
            type:'bar',
            label:sensor1 ,
            backgroundColor: 'rgb(54, 162, 235)',
            borderColor: 'rgb(54, 162, 235)',
              // borderWidth: 2,
              pointRadius: 2,
              fill: false,
              data: sensor1Data,
              yAxisID: sensor1
          }
        ]
        break; 
      case 'Area':
          datasets = [{
            type:'line',
            label:sensor1 ,
            backgroundColor: 'rgb(179, 224, 255)',
            borderColor: 'rgb(54, 162, 235)',
              // borderWidth: 2,
              pointRadius: 2,
              fill: false,
              data: sensor1Data,
              yAxisID: sensor1,
              fillOpacity: .3, 
              fill: 'start' 
          }
        ]
        break;   
    default:
        datasets = [{
          type:'line',
          label:sensor1 ,
          backgroundColor: 'rgb(54, 162, 235)',
          borderColor: 'rgb(54, 162, 235)',
            // borderWidth: 2,
            pointRadius: 2,
            fill: false,
            data: sensor1Data,
            yAxisID: sensor1
        }
      ]
    }
    return datasets
  }

  handleMultipleChartDataSet = (sensor1chartType,sensor1,sensor1Data,sensor2chartType,sensor2,sensor2Data) => {
    let datasets = []
    switch(`${sensor1chartType}${sensor2chartType}`){
      case 'LineLine':
          datasets = [{
            type:'line',
            label:sensor1 ,
            backgroundColor: 'rgb(54, 162, 235)',
            borderColor: 'rgb(54, 162, 235)',
              // borderWidth: 2,
              fill: false,
              data: sensor1Data,
              yAxisID: sensor1,
              pointRadius: 2,
          },
          {
            type:'line',
              label:sensor2,
              backgroundColor: 'rgb(255, 99, 132)',
              borderColor:'rgb(255, 99, 132)',
                // borderWidth: 2,
                fill: false,
                pointRadius: 2,
                data: sensor2Data,
                yAxisID: sensor2
          }
        ]
        break;
      case 'BarBar':
          datasets = [{
            type:'bar',
            label:sensor1 ,
            backgroundColor: 'rgb(54, 162, 235)',
            borderColor: 'rgb(54, 162, 235)',
              // borderWidth: 2,
              pointRadius: 2,
              fill: false,
              data: sensor1Data,
              yAxisID: sensor1
          },
          {
            type:'bar',
              label:sensor2,
              backgroundColor: 'rgb(255, 99, 132)',
              borderColor:'rgb(255, 99, 132)',
                // borderWidth: 2,
                pointRadius: 2,
                fill: false,
                data: sensor2Data,
                yAxisID: sensor2
          }
        ]
        break; 
      case 'AreaArea':
          datasets = [{
            type:'line',
            label:sensor1 ,
            backgroundColor: 'rgb(179, 224, 255)',
            borderColor: 'rgb(54, 162, 235)',
              // borderWidth: 2,
              pointRadius: 2,
              fill: false,
              data: sensor1Data,
              yAxisID: sensor1,
              fillOpacity: .3, 
              fill: 'start' 
          },
          {
            type:'line',
              label:sensor2,
              backgroundColor: 'rgb(245,168,192)',
              borderColor:'rgb(255, 99, 132)',
                // borderWidth: 2,
                pointRadius: 2,
                fill: false,
                data: sensor2Data,
                yAxisID: sensor2,
                fillOpacity: .3, 
                fill: 'start' 
          }
        ]
        break;   
      case 'LineBar':
          datasets = [{
            type:'line',
            label:sensor1 ,
            backgroundColor: 'rgb(54, 162, 235)',
            borderColor: 'rgb(54, 162, 235)',
              // borderWidth: 2,
              pointRadius: 2,
              fill: false,
              data: sensor1Data,
              yAxisID: sensor1
          },
          {
            type:'bar',
              label:sensor2,
              backgroundColor: 'rgb(255, 99, 132)',
              borderColor:'rgb(255, 99, 132)',
                // borderWidth: 2,
                pointRadius: 2,
                fill: false,
                data: sensor2Data,
                yAxisID: sensor2
          }
        ]
        
        break;
    case 'BarLine':
        datasets = [{
            type:'bar',
            label:sensor1,
            backgroundColor: 'rgb(255, 99, 132)',
            borderColor:'rgb(255, 99, 132)',
            // borderWidth: 2,
            pointRadius: 2,
            fill: false,
            data: sensor1Data,
            yAxisID: sensor1
          },{
          type:'line',
          label:sensor2 ,
          backgroundColor: 'rgb(54, 162, 235)',
          borderColor: 'rgb(54, 162, 235)',
          // borderWidth: 2,
          pointRadius: 2,
          fill: false,
          data: sensor2Data,
          yAxisID: sensor2
          }
        ]
        
        break; 
    case 'AreaBar':
      datasets=[{
          type:'line',
          backgroundColor: 'rgb(179, 224, 255)',
          borderColor: 'rgb(54, 162, 235)',
          data: sensor1Data,
          yAxisID: sensor1,
          label: sensor1,
          pointRadius: 2,
          fill: 'start' 
        }, {
          type:'bar',
          label:sensor2,
          backgroundColor: 'rgb(255, 99, 132)',
          borderColor:'rgb(255, 99, 132)',
          // borderWidth: 2,
          pointRadius: 2,
          fill: false,
          data: sensor2Data,
          yAxisID: sensor2
        }
      ]
      break; 
    case 'AreaLine': 
    datasets=[{
      type:'line',
      backgroundColor: 'rgb(179, 224, 255)',
      borderColor: 'rgb(54, 162, 235)',
      data: sensor1Data,
      yAxisID: sensor1,
      label: sensor1,
      pointRadius: 2,
      fill: 'start' 
    }, {
      type:'line',
      label:sensor2 ,
      backgroundColor: 'rgb(255, 99, 132)',
      borderColor:'rgb(255, 99, 132)',
      // borderWidth: 2,
      pointRadius: 2,
      fill: false,
      data: sensor2Data,
      yAxisID: sensor2
    }
  ]  
  break;
  case 'LineArea': 
      datasets=[{
        type:'line',
        backgroundColor: 'rgb(54, 162, 235)',
        borderColor: 'rgb(54, 162, 235)',
        data: sensor1Data,
        yAxisID: sensor1,
        pointRadius: 2,
        label: sensor1,
        fill: false  
      }, {
        type:'line',
        label:sensor2 ,
        backgroundColor: 'rgb(245,168,192)',
        borderColor:'rgb(255, 99, 132)',
        // borderWidth: 2,
        pointRadius: 2,
        fill: 'start',
        data: sensor2Data,
        yAxisID: sensor2
      }]
  break; 
  case 'BarArea':
      datasets=[
        {
          type:'bar',
          label:sensor1,
          backgroundColor: 'rgb(54, 162, 235)',
          borderColor: 'rgb(54, 162, 235)',
          // borderWidth: 2,
          pointRadius: 2,
          fill: false,
          data: sensor1Data,
          yAxisID: sensor1
        },{
        type:'line',
        backgroundColor: 'rgb(245,168,192)',
        borderColor:'rgb(255, 99, 132)',
        data: sensor2Data,
        yAxisID: sensor2,
        pointRadius: 2,
        label: sensor2,
        fill: 'start' 
      }
    ]
    break;   
    default:
        datasets = [{
          type:'line',
          label:sensor1 ,
          backgroundColor: 'rgb(54, 162, 235)',
          borderColor: 'rgb(54, 162, 235)',
            // borderWidth: 2,
            pointRadius: 2,
            fill: false,
            data: sensor1Data,
            yAxisID: sensor1
        },
        {
          type:'bar',
            label:sensor2,
            backgroundColor: 'rgb(255, 99, 132)',
            borderColor:'rgb(255, 99, 132)',
              // borderWidth: 2,
              pointRadius: 2,
              fill: false,
              data: sensor2Data,
              yAxisID: sensor2
        }
      ]
    }
    return datasets
  }

  handleChange = (event) => {
    this.setState({[event.target.name]:event.target.value}) 
  }

  setFromDate =(date)=>{
    this.setState({fromdate:date})
  } 

  setToDate =(date)=>{
    this.setState({todate:date})
  }  

  editChart = () => {
    this.createChart()
  }

  setInitialData = (chartId) => {
    this.setState({
      chartId : chartId,
      checkedSensorPH:false,
      sensorPhTf : '',
      sensorPhType : '',
      checkedSensorCOD:false,
      sensorCODTf : '',
      sensorCODType : '',
      checkedSensorBOD:false,
      sensorBODTf : '',
      sensorBODType : '',
      checkedSensorFLRate:false,
      sensorFLRateTf : '',
      sensorFLRateType : '',
      checkedSensorNO3:false,
      sensorNO3Tf : '',
      sensorNO3Type : '',
      checkedSensorORP:false,
      sensorORPTf : '',
      sensorORPType : '',
      checkedSensorTFLRate:false,
      sensorTFLRateTf : '',
      sensorTFLRateType : '',
      checkedSensoTSS:false,
      sensoTSSTf : '',
      sensorTSSType : '',
    })
    const chartToEdit = this.props.metaData.filter( chart => {
        if(chart.id === chartId){
            return chart
        }
    })

    const chartsData = chartToEdit[0].data.datasets.map( data => {
        return {
            sensor : data.label,
            tf : '17',
            type : (data.type == 'line'&& !data.fill) ? 'Line' : data.type == 'bar' ? 'Bar' : 'Area'
        }
    })
    this.setState({
        chartsData : chartsData,
        originalChartData : chartsData,
        chartToEdit:chartToEdit
    },() => {
        this.state.chartsData.map( chart => {
            this.setSensorValue(chart.sensor)
            this.setTypeValue(chart.sensor,chart.type)
            this.setTfValue(chart.sensor,chart.tf)
        })
    })

  
  }

 render(){  
  return (
    <>
    <Dialog open={this.props.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
      <p className={classes.DialogTitle}>ULB</p>  
        <DialogContent>
          <div className="create_ulb_content" style={{ overflow: "hidden", height: "100%", width: "100%"}}>
            <Grid container justify="center" spacing ={3} direction ="row">
                <Grid item xs={12} align='center'>
                    <p className={classes.dialogSubtitle}>Please select upto six ULb's</p>
                </Grid>
            </Grid>
            <Grid container justify="center" spacing ={3} direction ="row">
              <Grid item xs={12} align='center'>
                <FormControl>
                  <Select value="value" 
                    name="ulb"
                    style={{marginTop:'10px'}}
                    >
                    <MenuItem value={"option.id"}>{"option.name"}</MenuItem>                     
                  </Select>
                </FormControl>
                <Button style={{
                  border: '2px solid red',
                  color: 'red',
                  textAlign: 'center',
                  borderRadius: '3px',
                  textTransform: 'capitalize',
                  marginLeft:' 10px',
                  marginTop:'8px',
                  height:' 48px',
                  width: '103px'
                }}> <DeletedOutlined></DeletedOutlined> Delete
                </Button>
              </Grid>
            </Grid>
            <Grid container justify="center" spacing ={3} direction ="row">
              <Grid item xs={12} align='center'>
                <FormControl>
                  <TextField id="tfName" 
                    style={{
                      marginTop:'10px'
                    }}
                    value={this.state.tfName} 
                    onChange={this.handleChange} 
                    name="tfName"   
                    placeholder={<DeletedOutlined></DeletedOutlined>, '...search'}/>
                </FormControl>
                <Button disabled={true} style ={{color:"#ffffff",backgroundColor:"#4CAF50",marginLeft:' 10px',marginTop:'7px',
                  height:'38px',
                  width: '103px'}} variant="contained" onClick={this.editChart}>
                <AddIcon></AddIcon> Add
                </Button>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <div style={{ overflow: "hidden", height: "100%", width: "100%" ,marginTop:"10%"}}>
            <Grid container justify="center" spacing ={3} direction ="row">
              <Grid item xs={6} align="right" style={{width:"50%"}}>
                <Button style ={{color:"#4CAF50", width: 'inherit',marginBottom:"5%"}} variant="outlined" onClick={this.handleClose}>
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6} align="left" style={{width:"50%"}}>
                <Button style ={{color:"#ffffff",backgroundColor:"#4CAF50",width: 'inherit'}} variant="contained" onClick={this.editChart}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </div>  
        </DialogActions>
      </Dialog> 
    </>
  );
 }
 
}

const mapStateToProps = (state) => {
  return{
    //   iotChartData:state.iotChartData.iotChartData,
    //   metaData : state.iotChartData.metaData
  }
}

const mapDispatchToProps = (dispatch)=> {
  return{
    // getChartMetaData : (payload) => dispatch (actionCreators.getChartMetaData(payload))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditDialog)