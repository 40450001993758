import * as actionTypes from './actionsTypes';
import axios from '../../http-config';

export const fetchExportsSuccess = (response) => {
    return {
        type: actionTypes.FETCH_EXPORTS_SUCCESS,
        
    }
}

export const fetchExportsFail = (error) => {
    return {
        type: actionTypes.FETCH_EXPORTS_FAIL,
        error:error
    }
}

export const fetchExportsStart = () => {
    return {
        type: actionTypes.FETCH_EXPORTS_START
    }
}

export const fetchExports = (payload) => {
    let filter = payload.filter;
    let query = payload.query;
    let expo= payload.export;
    let token = payload.token;
    let fromDate=payload.fromDate;
    let toDate=payload.toDate;
    let ulbId = payload.ulbId

    return dispatch => {
        dispatch(fetchExportsStart());
        const url = ulbId == ''? `/load/?query=${query}&filter=${filter}&export=${expo}&fromDate=${fromDate}&toDate=${toDate}`:
        // `load/?ulbId=${ulbId}&export=${expo}&fromDate=${fromDate}&toDate=${toDate}`
        `load/?limit=10&page=1&sort=&filter=${filter}&query=&ulbId=${ulbId}&fromDate=${fromDate}&toDate=${toDate}&export=true`

       
        axios({
            method: 'get',
            url: url,
            headers: {'Authorization': `Bearer ${token}`}
        })
        .then(function (response) {
            let link1=response.data.data[0].file_path
            
            window.downloadFile = function (link) {
                window.open(link, '_blank');
            }
            window.downloadFile(link1)
          
        }).catch(error => {
            dispatch(fetchExportsFail(error));
        })

    }
}

export const fetchPropertyExport =(payload)=>{
    let id = payload.ulbId
    let exportBol = payload.exportBol
    let token = payload.token
    const url = `property/${id}/load?export=${exportBol}`
    return dispatch=>{
        dispatch(fetchExportsStart())
        axios.get(
            url,
            {headers:{ "Authorization":`Bearer ${token}`
        }}).then(response=>{
            let exportLink=response.data.data[0].file_path
            window.downloadFile = function (link) {
                window.open(link, '_blank');
            }
            window.downloadFile(exportLink)
  
        }).catch(err=>{
            dispatch(fetchExportsFail(err));
        })
    }
 }

 export const fetchZoneListingExport =(payload)=>{
    let token = payload.token
    const url = `/zone/getZones/?sortColumnName=${payload.sortName}&sortColumnType=${payload.sortValue}&export=true`
    return dispatch=>{
        dispatch(fetchExportsStart())
        axios.get(
            url,
            {headers:{ "Authorization":`Bearer ${token}`
        }}).then(function (response) {
            let link1=response.data.data[0].file_path
            
            window.downloadFile = function (link) {
                window.open(link, '_blank');
            }
            window.downloadFile(link1)
          
        }).catch(error => {
        })
    }
 }

 export const fetchDisposalListingExport =(payload)=>{
    let token = payload.token
    const url = `/treatment_facility/?sortColumnName=${payload.sortName}&sortColumnType=${payload.sortValue}&export=true`
    return dispatch=>{
        dispatch(fetchExportsStart())
        axios.get(
            url,
            {headers:{ "Authorization":`Bearer ${token}`
        }}).then(function (response) {
            let link1=response.data.data[0].file_path
            
            window.downloadFile = function (link) {
                window.open(link, '_blank');
            }
            window.downloadFile(link1)
          
        }).catch(error => {
        })
    }
 }

 export const fetchWardsListingExport =(payload)=>{
    let token = payload.token 
    const url = `/ward?sortColumnName=${payload.sortName}&sortColumnType=${payload.sortValue}&export=true`
    return dispatch=>{
        dispatch(fetchExportsStart())
        axios.get(
            url,
            {headers:{ "Authorization":`Bearer ${token}`
        }}).then(response=>{
            let exportLink=response.data.data[0].file_path
            window.downloadFile = function (link) {
                window.open(link, '_blank');
            }
            window.downloadFile(exportLink)
  
        }).catch(err=>{
            dispatch(fetchExportsFail(err));
        })
    }
 }

 