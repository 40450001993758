import React, { Component } from 'react';
import { connect } from 'react-redux';

import '../loads/RecordKeeper.css';
import * as actionsCreators from  '../../../store/actions/index';
// import TableConfig from './TableConfig';
import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler';
import axios from '../../../http-config';
import {Link} from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import swal from 'sweetalert';
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from '@material-ui/core/MenuList';
import Popper from '@material-ui/core/Popper';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
class EmailManagement extends Component {

    constructor(props){
        super()
      

    }
    state = {
        isAddEnable : false,
        useremail : '',
        username : '',
        usernameErr : '',
        useremailErr : '',
        sortObj : {
            key: '',
            value: ''
        },
        limit: 10,
        filter : {
            'wardNumber':'',
            'operatorName':'',
            'truckRegistrationNumber':'',
            'propertyTypeName':'',
        },
        controls: {
            search: {
                value: '',
            }
        },
        query: '',
        currentSortId:"",
        data:[],
        name:'',
        email:'',
        id:'',
        isDisabled:false,
        open:false,
        anchorEl:null,
        action_id:'',
        open_onadd:false,
        anchorEl_onadd:null,

       
        
    }

    componentDidMount() {
        let payload = {
            token: this.props.token,
            page:  1,
            limit: this.state.limit,
            sort: '',
            filter: JSON.stringify([this.state.filter]),
            query: this.state.query
        }
        this.props.onFetchEmailManage(payload);
    }

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        let payload = {
            token: this.props.token,
            page:  selectedPage + 1,
            limit: this.state.limit,
            sort: `${this.state.sortObj.key}${this.state.sortObj.value}`,
            filter: JSON.stringify([this.state.filter]),
            query: this.state.query
        }

        this.props.onFetchEmailManage(payload);
    };
     
    handleSort = (e, sortBy) => {
        let sortObj;
 
        if (this.state.sortObj.key === sortBy.accessor) {
            let value = (this.state.sortObj.value === '-desc') ? '-asc' : '-desc';
            sortObj = {
                key : sortBy.accessor,
                value: value
            }
        } else {
            sortObj = {
                key : sortBy.accessor,
                value: '-desc'
            }
            
        }
        this.setState({ sortObj: sortObj ,currentSortId:sortBy.accessor});
        let payload = {
            token: this.props.token,
            page:  1,
            limit: this.state.limit,
            sort: `${sortObj.key}${sortObj.value}`,
            filter: JSON.stringify([this.state.filter]),
            query: this.state.query
        }

        this.props.onFetchEmailManage(payload);
    }

    onClickGetFilterVals = (e, colObj) => {
        const updatedFilter = {
            ...this.state.filter
        }
        updatedFilter[colObj.accessor] = e;
        this.setState({ filter: updatedFilter });

        let payload = {
            token: this.props.token,
            page:  1,
            limit: this.state.limit,
            sort: `${this.state.sortObj.key}${this.state.sortObj.value}`,
            filter: JSON.stringify([updatedFilter]),
            query: this.state.query
        }
        this.props.onFetchEmailManage(payload);
    }

    onClickSearch = (e) => {
        let searchVal = this.state.controls.search.value;
            let payload = {
                token: this.props.token,
                search:searchVal ? searchVal : '',
            }
            this.setState({ query: searchVal });
            this.props.onFetchEmailManage(payload);
    }

    onClickNew =(e) =>{

            this.setState({isAddEnable: true, isDisabled:true})
            let { data } = this.state;
            data.push(data.length);
            this.setState({data});
            const payload={
               name:this.state.name
            }
    }

    onClickEdit =(id) =>{
        let { data } = this.state;
        data.push(id);
       
        this.setState({data});

}

     
    inputChangedHadler = (event, controlName) => {
        const updatedControls = {
            ...this.state.controls,
            [controlName]: {
                ...this.state.controls[controlName],
                value: event.target.value,
            }
        }
        this.setState({ controls: updatedControls });
    }

    isActionAllowed = (actionUrl = "") => {
        return ["edit", "delete"].indexOf(actionUrl) >= 0 ? true : false;
      };
     handleRemove = (id)=>{
        let payload = {
            id:id,
           token: this.props.token,
          
       }
        this.props.onRemoveEmailManage(payload)
    
    }
 
handleClickMoreVertIcon=(event,id)=>{
    this.setState({open:!this.state.open,anchorEl:event.currentTarget, action_id:id})
  }
  handleClickMoreVertIconOnAdd=(event,id)=>{
    this.setState({open_onadd:!this.state.open_onadd,anchorEl_onadd:event.currentTarget})
  }
  handleClickAwayRemove = () => {
    this.setState({open:false});
};
handleClickAwayOnAdd = () => {
    this.setState({open_onadd:false});
};

    render() {
        return (
                <div>
                    <div className="dashboard_top_path">
                <p><img src="images/left-arrow.svg" />Load Records / <span>Record Keeping Emails</span></p>
            </div>
                    <div className="dashboard_right_content"> 
                        <img className="dashboard_right_content_top_icon" src="images/Rectangle.svg" />
                        <div className="dashboard_right_content_top">
                        <h5>Record Keeping Emails</h5>
                            <div className="dashboard_right_content_top_options">
                                <div className="dashboard_right_content_top_search">
                                    <Paper component="form">
                                        <IconButton aria-label="menu">
                                            <img src="images/search_icon.svg" />
                                        </IconButton>
                                        <InputBase onChange={(event) => this.inputChangedHadler(event, 'search')} placeholder="Search..." />
                                        <Button onClick={this.onClickSearch} variant="contained" color="primary">Search</Button>   
                                    </Paper>
                                </div>
                                {/* <Button  onClick={this.onClickNew}  id="addNewRecord" variant="outlined" color="primary" disabled= {this.state.isDisabled}
                                >Add a New Record</Button> */}
                                <Link style={{textDecoration:"none", color:"#3dab35"}} to="/email-add">
                                    <Button id="addNewRecord" variant="outlined" color="primary" disabled= {this.state.isDisabled}
                                >Add a New Record</Button></Link>
                            </div>
                        </div>
                    </div>
               
                    <div className="record_keeping_email_listing">
                    <TableContainer component={Paper}>
                   
                        <Table aria-label="simple table">
                           <TableHead>
                               <TableRow>
                                   <TableCell align="center">Name</TableCell>
                                   <TableCell align="center">Email</TableCell>
                                    <TableCell align="center">Action Menu</TableCell> 
                               </TableRow>
                           </TableHead>
                         
                           <TableBody>
                                 
                               {   
                                   this.props.users.map(user=>{
                                       const id=user.id
                                   return  <TableRow key={user.id}>
                                        <TableCell  align="center">{user.name}</TableCell>
                                        <TableCell align="center">{user.email}</TableCell>
                                        <TableCell align="center">
                                        <Link to={`/email_edit/${user.id}`} className='email_link'> 
                                              <Button  className="emailEdit" variant="outlined" >Edit</Button>
                                               </Link>
                                            <Button variant="outlined" className="emailDelete"  onClick={()=>{
                                                         swal({
                                                            title: "Are you sure?",
                                                            text: "Once deleted, you will not be able to recover this data!",
                                                            icon: "warning",
                                                            buttons: {
                                                                cancel : 'No',
                                                                confirm: 'Yes'
                                                            }
                                                            })

                                                            .then((willDelete) => {
                                                                if (!!willDelete) {
                                                                    this.handleRemove(user.id)
                                                                    swal({
                                                                        title:"Your data has been deleted!",
                                                                        icon: "success",
                                                                    })
                                                                } 
                                                                });

                                            }}>Delete</Button>
                                        </TableCell>

                                    </TableRow>
                                   })
                               }    
                           </TableBody>     
                        </Table>
                    </TableContainer>

                    <ReactPaginate
                                        previousLabel={"Prev"}
                                        nextLabel={"Next"}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={this.props.totalCount/this.state.limit}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        style={{borderLeftWidth:'0px'}}
                                        onPageChange={this.handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}/>
                    </div>
                </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        users: state.emailManage.users,
        loading: state.loads.loading,
        token: state.auth.token
        
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchEmailManage: (payload) => dispatch(actionsCreators.fetchEmailManage(payload)),
        onRemoveEmailManage:(payload)=>dispatch(actionsCreators.removeEmailManage(payload))
    }
}

export default  connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(EmailManagement, axios));
