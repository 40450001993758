import * as actionTypes from '../../actions/actionsTypes'
import { updateObject } from '../../utility'

const initialState = {
    userHeaders : [],
    loading     : false,
    error       : null
}
export const fetchUserHeadersStart = (state, action) => {
    return updateObject(state, {
        loading : true
    })
}

export const fetchUserHeadersSuccess = (state, action) => {
    return updateObject(state, {
        userHeaders  : action.userHeaders,
        loading      : false
    })
}

export const fetchUserHeadersFail = (state, action) => {
    return updateObject(state, {
        loading  : false,
        error    : action.error
    })
}

const userHeadersReducer = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.FETCH_USER_HEADERS_START      : return fetchUserHeadersStart(state, action)
        case actionTypes.FETCH_USER_HEADERS_SUCCESS    : return fetchUserHeadersSuccess(state, action)
        case actionTypes.FETCH_USER_HEADERS_FAIL       : return fetchUserHeadersFail(state, action)
        default                                        : return state
    }
}

export default userHeadersReducer