import React, { Component } from 'react'
import { connect } from 'react-redux';
import {Link} from 'react-router-dom'
import swal from 'sweetalert'
import * as actionsCreators from  '../../store/actions/index';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import ReactPaginate from 'react-paginate';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
class TreatmentFacility extends Component {
    state = { 
        anchorEl   : null,
        open       : false,
        id         : '',
        page:1,
        currentSortId:'asc',
        limit:10,
        sortObj : {
            key: '',
            value: ''
        },
        controls: {
            search: {
                value: '',
            }
        },
    }
    componentDidMount() {
        let payload = {
            token  : this.props.token,
            page:this.state.page,
            limit:this.state.limit,
            sortName:this.state.sortObj.key,
            sortValue :this.state.sortObj.value,
            searchQuery: this.state.controls.search.value
        }
        this.props.onFetchTreatmentHeaders(payload);
        this.props.onFetchTreatmentListing(payload) 
    }
    handleClickMoreVertIcon=(event,id)=>{
        this.setState({open:!this.state.open,anchorEl:event.currentTarget,id:id})
      }
    handleClickAwayEdit = () => {
    this.setState({open:false});
    };
  
    handleDelete = (id) => {
        const payload = {
            id      : id,
        }
        const payload1={
            token: this.props.token,
            page:  this.state.page,
            limit: this.state.limit,
            sortName:this.state.sortObj.key,
            sortValue :this.state.sortObj.value,
        }
        this.props.onDeleteTreatmentFacility(payload,payload1)
    }

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        this.setState({page: e.selected+1})
        let payload = {
            token: this.props.token,
            page:  selectedPage + 1,
            limit: this.state.limit,
            sortName:this.state.sortObj.key,
            sortValue :this.state.sortObj.value,
            searchQuery: this.state.controls.search.value
        }
        this.props.onFetchTreatmentListing(payload)
    };

    handleSort = (e,sortBy) => {
        let sortObj
        if (this.state.sortObj.key === sortBy.accessor) {
            let value = (this.state.sortObj.value === 'desc') ? 'asc' : 'desc';
            sortObj = {
                key : sortBy.accessor,
                value: value
            }
        } 
        else {
            sortObj = {
                key : sortBy.accessor,
                value: 'desc'
         }    
         }
 
        this.setState({ sortObj: sortObj ,currentSortId:sortBy.accessor});
        let payload = {
            token: this.props.token,
            sortName:sortObj.key,
         sortValue : sortObj.value,
         page: this.state.page,
         limit: this.state.limit,
         searchQuery: this.state.controls.search.value
        }

        this.props.onFetchTreatmentListing(payload);
  
      }

      handlePropertySearch = (e) => {
        e.preventDefault()
        let searchVal = this.state.controls.search.value
        if (!!searchVal && searchVal.length >= 2) {
            let payload = {
                token: this.props.token,
                searchQuery:searchVal,
                limit:10,
                page:this.state.page,
                // propertyTypeId: this.state.filterValue,
                sortName:this.state.sortObj.key,
                sortValue :this.state.sortObj.value,
              
            }
            this.props.onSearchTFName(payload) 
        } else {
         
            swal("Enter atleast 2 characters")
        }
    }
    inputChangedHadler = (event, controlName) => {
        const updatedControls = {
            ...this.state.controls,
            [controlName]: {
                ...this.state.controls[controlName],
                value: event.target.value,
            }
        }
        this.setState({ controls: updatedControls });
        if( updatedControls.search.value === ''){
            let payload = {
                token: this.props.token,
                searchQuery:'',
                limit:this.state.limit,
                page:this.state.page,
                // propertyTypeId: this.state.filterValue,
                sortName:this.state.sortObj.key,
                sortValue :this.state.sortObj.value,
            }
            this.props.onSearchTFName(payload) 
        }
    }

    handleExport = () => {
        let payload = {
            token  : this.props.token,
            page:this.state.page,
            limit:this.state.limit,
            sortName:this.state.sortObj.key,
            sortValue :this.state.sortObj.value,
            searchQuery: this.state.controls.search.value
      
        }
        this.props.OnFetchDisposalListingExport(payload)
    }
    render() {
        const headers =  this.props.headers.map(header => {  
            if(header.accessor === 'actionButton'){
                header.header = 'Action'
            }
            if(header.accessor === "Capacity"){
                header.header = 'Capacity (in KL per day)'
            }
          {
                return <TableCell key={header.header} className={"white-space"}>
                                <div style={{
                        display: 'flex',
                        alignItems: 'start',
                        whiteSpace: 'break-spaces',
                        height: '3em'
                    }}>
                {header.header === "Action"? "":
                    <div onClick={(e) =>  this.handleSort(e,header)}>
                        <div>{
                            this.state.currentSortId===header.accessor? this.state.sortObj.value ==='asc'?(<ArrowDropDownIcon fontSize="small"/>):(<ArrowDropUpIcon fontSize="small"/>) :(<ArrowDropDownIcon fontSize="small"/>)
                        }
                        </div>  
                    </div>}
                        {header.header}
                    </div>
                        </TableCell>
            }
        }
        );
        const facilities=this.props.treatmentListing.map((facility, j)=>{
            const id=facility["TFId"]
            return <TableRow style={{whiteSpace: 'normal',
            wordWrap: 'break-word'}} key={j}>{
                this.props.headers.map((header, i)=>{
                       if(header.accessor=='actionButton'){
                        return  <TableCell  key={i}>
                                            <IconButton
                                            aria-label="more"
                                            aria-controls="long-menu"
                                            aria-haspopup="true"
                                            onClick={(e)=>this.handleClickMoreVertIcon(e,id)}
                                            >
                                            <MoreVertIcon fontSize='small'/>
                                            </IconButton>
                                                <Popper
                                                open={this.state.open} anchorEl={this.state.anchorEl}>
                                                 <ClickAwayListener onClickAway={this.handleClickAwayEdit}>
                                                <Paper  style={{boxShadow:"none", border:"1px solid #3dab35"}}>
                                                    <MenuList>
                                                        <Link to={`./edit_TF/${this.state.id}`} className='email_link'> <MenuItem>Edit</MenuItem></Link>
                                                        <Link to={`./ViewReason/${this.state.id}`} className='email_link'> <MenuItem>View Reason</MenuItem></Link>
                                                        {localStorage.getItem('phase2') == 'true' && <div>
                                                        <MenuItem style = {{color: '#3dab35'}} onClick={() => {
                                                             swal({ title : "Are you sure?",
                                                                    text : "Once deleted, you will not be able to recover this data!",
                                                                    icon : "warning",
                                                                    buttons : {
                                                                    cancel : 'No',
                                                                    confirm: 'Yes'}
                                                            })
                                                            .then((willDelete) => {
                                                                if (willDelete) {
                                                                    this.handleDelete(this.state.id);
                                                                } 
                                                            });
                                                        }}>Delete</MenuItem></div>}
                                                    </MenuList>
                                                </Paper>
                                                </ClickAwayListener>
                                            </Popper>
                                </TableCell>
                       }
                       else if(header.accessor=='Location'){
                           const location = facility[header.accessor]
                           if(location){
                            const lat = location["x"].toFixed(4)
                            const long = location["y"].toFixed(4)
                            return <TableCell style={{
                                whiteSpace: "normal",
                                wordWrap: "break-word"
                              }} key={i} >{lat},{long}</TableCell>
                           } 
                           else{
                               return <TableCell style={{
                                whiteSpace: "normal",
                                wordWrap: "break-word"
                              }}key={i}>{location}</TableCell>
                           }
                       }
                       else if(header.accessor=='UpdatedAt'){
                        //    return <TableCell key={i} >{ (new Date(facility[header.accessor])).toLocaleString()}</TableCell>
                           return <TableCell style={{
                            whiteSpace: "normal",
                            wordWrap: "break-word"
                          }} key={i} >{ moment(facility[header.accessor]).format('DD-MMM-YYYY hh:mm a')}</TableCell>
                       }
                       else{
                           return  <TableCell style={{
                            whiteSpace: "normal",
                            wordWrap: "break-word"
                          }} key={i} className={"white-space"}>{facility[header.accessor]}</TableCell>
                       }
                }) 
            }
            </TableRow>

    })
        return (
            <div>
                
                 <div className="dashboard_right_content" style={{marginTop:'-4%'}}>
                        <img className="dashboard_right_content_top_icon" src="images/Rectangle.svg" />
                        <div className="dashboard_right_content_top">
                        <h5>Disposal Facilities</h5>
                            <div className="dashboard_right_content_top_options">
                            <div className="dashboard_right_content_top_search">
                                <Paper component="form">
                                    <IconButton aria-label="menu">
                                        <img src="images/search_icon.svg" />
                                    </IconButton>
                                    <InputBase onChange={(event) => this.inputChangedHadler(event, 'search')} placeholder="Name" />
                                    <Button onClick={this.handlePropertySearch} variant="contained" color="primary" type="submit">Search</Button>
                                </Paper>
                            </div>
                                <Link className="email_link" to='/new_TF'><Button>Add New Disposal Facility </Button></Link>  
                            <div>
                            <Button style={{width:'100%', marginLeft:'10px' }} onClick={this.handleExport}>Export</Button>
                            </div>    
                            </div>
                            
                        </div>
                    </div>
                    <div> 
                    {/* {(this.props.loadingTf === false ) ? <div id="cover-spin"></div>:''} */}
                    <div className="operator_listing AreaLIsting scrollward" >
                        <TableContainer component={Paper} style={{paddingLeft:"15px",paddingRight:"15px"}}>
                             <Table stickyHeader aria-label="sticky table" style={{ tableLayout: 'fixed' }}>
                                 <colgroup>
    +                                <col style={{width:'4%'}}/>
    +                                <col style={{width:'10%'}}/>
    +                                <col style={{width:'20%'}}/>
    +                                <col style={{width:'10%'}}/>
    +                                <col style={{width:'10%'}}/>
    +                                <col style={{width:'10%'}}/>
    +                                <col style={{width:'10%'}}/>
    +                                <col style={{width:'10%'}}/>
    +                                <col style={{width:'10%'}}/>
    +                                <col style={{width:'6%'}}/>  
+                                </colgroup>
                                <TableHead >
                                    <TableRow >
                                        {headers}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                        {/* {facilities} */}
                                        {facilities.length === 0&& this.props.loadingTf == true ? <TableRow style={{textAlign:'center', fontWeight:'bold', fontSize: '13px', }}>
                                            No Records Found !!</TableRow>:facilities }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    {
                this.props.totalCount && this.props.totalCount != 0 && 
                    <ReactPaginate
                    previousLabel={"Prev"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount = {this.props.totalCount/this.state.limit}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    style={{borderLeftWidth:'0px'}}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}/>
                }
                    </div>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        token: state.auth.token,
        headers:state.treatmentHeaders.headers,
        treatmentListing:state.treatmentListing.treatmentListing,
        totalCount:state.treatmentListing.totalCount,
        loadingTf: state.treatmentListing.loading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchTreatmentHeaders: (payload) => dispatch(actionsCreators.fetchTreatmentHeaders(payload)),
        onFetchTreatmentListing: (payload) => dispatch(actionsCreators.fetchTreatmentListing(payload)),
        onDeleteTreatmentFacility : (payload,payload1) => dispatch(actionsCreators.deleteTreatmentFacility(payload, payload1)),
        onSearchTFName :(payload)=>dispatch(actionsCreators.searchTFName(payload)),
        OnFetchDisposalListingExport :(payload)=>dispatch(actionsCreators.fetchDisposalListingExport(payload))
    }
}

export default  connect(mapStateToProps, mapDispatchToProps)(TreatmentFacility);