import * as actionTypes from '../actionsTypes'
import axios from '../../../http-config'
import swal from 'sweetalert'

export const fetchClusterDataStart = () => {
    return({
        type  : actionTypes.FETCH_CLUSTERLISTING_START,
        loading: false
    })
}

export const fetchClusterDataSuccess = (response) => {
    return ({
        type    : actionTypes.FETCH_CLUSTERLISTING_SUCCESS,
        payload : response.data.ulbs,
        totalCount : response.totalCount,
        loading:true
    })
}

export const fetchClusterDataFail = (error) => {
    return({
        type   : actionTypes.FETCH_CLUSTERLISTING_FAIL,
        error  : error,
        progress:0, 
        loading:true
    })
}

export const fetchClusterBulkStart = () => {
    return {
        type: actionTypes.FETCH_CLUSTERBULK_START,
        success:false,
        progress:0
    }
}
export const fetchClusterBulkSuccess = () => {
    return {
        type: actionTypes.FETCH_CLUSTERBULK_SUCCESS,
        success:true,
        progress:100
    }
}

export const fetchClusterBulkFail = (error) => {
    return {
        type: actionTypes.FETCH_CLUSTERBULK_FAIL,
        error:error,
        success:false,
        progress:0
    }
}

export const fetchClusterData = (payload) => {
    let  url =payload.limit == undefined?  `ulb/cluster-ulb`:`ulb/cluster-ulb?ulbName=${payload.searchQuery}&limit=${payload.limit}&page=${payload.page}&sortColumnName=${payload.sortName}&sortColumnType=${payload.sortValue}`
    const token = payload.token
    return (dispatch) => {
        dispatch(fetchClusterDataStart())
        axios({
            method  : 'get',
            url     :url,
            headers : {'Authorization': `Bearer ${token}`}
        })
        .then((response) => {
            dispatch(fetchClusterDataSuccess(response.data))
        })
        .catch((err) => {
            dispatch(fetchClusterDataFail(err))
        })
    }
}

export const addClusterUlbData = (formdata,props, payload)=>{
    let token=props.token
    return (dispatch)=>{
        dispatch(fetchClusterDataStart())

        let url = 'ulb/cluster';
        axios.post(url, formdata,{headers:{
            "Authorization":`Bearer ${token}`
        }})
        .then((response) => {
            
            const fetchedResponse = response.data.success;
            if(fetchedResponse === true){
                fetchedResponse && swal({
                    title: "Cluster added Successfully!!!",
                    icon: "success",
                    buttons: {
                        confirm: 'Ok'
                    }
                  })
                props.history.push('/cluster-management')
                dispatch(fetchClusterData(payload));

            }
                
        }, 
        (err) => {
            swal({icon : "error" , title : err.response.data.error[0]["message"]})           
        }).catch(error => {
            dispatch(fetchClusterDataFail(error))           
        })
        
    }
}
export const editClusterUlbData = (formaData, props, payload) => {
    const id = props.id
    return dispatch => {
        dispatch(fetchClusterDataStart());
        let url = 'ulb/cluster';
        axios.put(url, formaData,{headers:{
            "Authorization":`Bearer ${payload.token}`
        }})
        .then(response => {
          
            const fetchedResponse = response.data.success;
            if(fetchedResponse ===true){
                fetchedResponse && swal({
                    title: "You have Successfully edited the data!!!",
                    icon: "success",
                    buttons: {
                        confirm: 'Ok'
                    }
                  })
                  props.history.push('/cluster-management')
                  dispatch(fetchClusterData(payload));
            }
             
        }, (err) => {
            swal({icon : "error" , title : err.response.data.error[0]["message"]})          
        })
        .catch(err => {
            dispatch(fetchClusterDataFail(err))
        })
    }
}
export const searchClusterName=(payload)=>{
    return dispatch =>{
        dispatch(fetchClusterDataStart());
        const url =`ulb/cluster-ulb?ulbName=${payload.searchQuery}&limit=${payload.limit}&page=${payload.page}`
        axios.get(url,{headers:{
            "Authorization":`Bearer ${payload.token}`
        }} ).then(response=>{
            response && response.data && response.data.data &&
            response.data.data && 
            response.data.data.length == 0 && 
            swal("No Records Found !", {
                icon: "warning",
              });
            dispatch(fetchClusterDataSuccess(response.data));
        }).catch(err=>{
            dispatch(fetchClusterDataFail(err));
        })
    }
}

export const deleteClusterUlbData = (payload) => {
    let token = localStorage.getItem('token');
    return (dispatch) => {
        dispatch(fetchClusterDataStart())
        const url =  '/ulb/cluster'
        axios.delete(url , { data : payload, headers : {   "Authorization":`Bearer ${token}`} })
        .then((response) => {
            dispatch(fetchClusterData({token:token , searchQuery:'',limit:10, page:1, sortName:'', sortValue:'', }))
            swal({
                title:"Your data has been deleted!",
                icon: "success",
            })
            
        }, 
        (err) => {
            swal({icon : "error" , title : err.response.data.error[0]["message"]}) 
        })
    }
}


export const bulkCluster =(payload, formData)=>{

    let token = payload.token
    return dispatch => {
        dispatch(fetchClusterBulkStart())
        const url = 'ulb/cluster-ulb/bulk'

        axios.post(url,formData,{headers:{
            "Authorization":`Bearer ${token}`
        }})
        .then(function (response) {
            if(response){
                const fetchedLoadsResponse = response.data.success;
               if( fetchedLoadsResponse==true){
                   setTimeout(()=>{
                    dispatch(fetchClusterBulkSuccess())
                    fetchedLoadsResponse && swal({
                    title: "Data imported successfully!",
                    icon: "success",
                    buttons: {
                        confirm: 'Ok'
                    }
                  })
                  .then((bulk) => {
                    if (bulk) {
                        dispatch(fetchClusterData({token:token , searchQuery:'',limit:10, page:1, sortName:'', sortValue:'', }))
                    } 
                  });
                },2000)
               
            }
        }
        }, (err) => {
            // swal({icon : "error" , title : err.response.data.error[0]["message"]})    
            if(err.response.data.error.length ===1){
                swal({
                    title:err.response.data.error[0].errmessage,
                    icon: "error",
                    buttons: {
                        confirm: 'Ok'
                    }
                  })
            }
            else{
                const errors = !!err.response.data.error? err.response.data.error.length : ''
                let list = '';
                var myhtml = document.createElement("div");
                myhtml.className = 'textNode-content';
                 for(let i = 0; i<errors; i++){
                     list += '*'  + err.response.data.error[i].errmessage +'  '+ '.' +'\n';
                    
                 }
                 myhtml.innerHTML = list
                 swal({icon : "error" , content: myhtml})   
                
            }
            dispatch(fetchClusterBulkSuccess())
            dispatch(fetchClusterData({token:token , searchQuery:'',limit:10, page:1, sortName:'', sortValue:'', }))
            
        })
        .catch(err => {
            dispatch(fetchClusterDataFail(err));
            dispatch(fetchClusterBulkFail(err))
        })

    }
}


export const fetchClusterULBListingExport =(payload)=>{
    let token = payload.token 
    const url =`ulb/cluster-ulb?ulbName=${payload.searchQuery}&sortColumnName=${payload.sortName}&sortColumnType=${payload.sortValue}&export=true`
    return dispatch=>{
        dispatch(fetchClusterDataStart())
        axios.get(
            url,
            {headers:{ "Authorization":`Bearer ${token}`
        }}).then(response=>{
            let exportLink=response.data.data[0].file_path
            window.downloadFile = function (link) {
                window.open(link, '_blank');
            }
            window.downloadFile(exportLink)
  
        }).catch(err=>{
            dispatch(fetchClusterDataFail(err));
        })
    }
 }