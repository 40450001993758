import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Bar} from 'react-chartjs-2';
import AddChartDialog from './addChartDialog'
import EditChartDialog from './editChartDialog'
import axios from '../../http-config'
import * as actionsCreators from '../../store/actions/index'
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import SettingsIcon from '@material-ui/icons/Settings';
import IconButton from '@material-ui/core/IconButton';
import * as actionCreators from '../../store/actions/index'
import swal from 'sweetalert'
import BasicTable from './Table';
import Grid from '@material-ui/core/Grid';
import CardHeader from "../Dashboard/Card/CardHeader.js";
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import DeleteIcon from '@material-ui/icons/Delete';
import { MuiPickersUtilsProvider,KeyboardTimePicker,
  KeyboardDatePicker,} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import {STATEDASHBOARDUSER,ADMIN} from '../../config/constant'
import {fetchChartDataFail,fetchChartDataStart} from '../../store/actions/iot-management/chartData'
import store from "../../../src/index";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogPaper: {
    minHeight: '80vh',
    maxHeight: '80vh',
},
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);




export class IotDashboard extends Component {
    constructor(props){
      super(props)
      this.state = {
        chartsToDisplay:[],
        open:false,
        ChartId:'',
        ulbId:'',
        selectedUlb :'1',
        success:false,
        openEditDialog:false,
        rendered:[],
        tfValue:'',
        loader:true,
        tfForTable:'',
        Url:'',
        range:'today',
        todate:new Date(),
        // fromdate:new Date(),
        fromdate:moment(new Date()).subtract(1, 'days'),
        openInfo:false,
        ArrData:[],

    }
    }
   

    async componentDidMount (dispatch) {
        const todate = moment(this.state.todate).format('YYYY-MM-DD')
        const fromdate = moment(this.state.fromdate).format('YYYY-MM-DD')
        if((localStorage.getItem('roleName') == STATEDASHBOARDUSER ||localStorage.getItem('roleName') == ADMIN)){
          this.props.fetchUlbData({token : localStorage.getItem('token'),filter:'true'})
        }
        const tfData = (localStorage.getItem('roleName') == STATEDASHBOARDUSER ||
        localStorage.getItem('roleName') == ADMIN) ? await this.props.fetchTreatmentListingIOT({token : localStorage.getItem('token'),ulb:this.state.selectedUlb})
        : await this.props.fetchTreatmentListingIOT({token : localStorage.getItem('token')}) 
        let treatmentFacility = tfData.length > 0 && tfData.filter( tf => {
          return tf.Url
        })
        if(treatmentFacility && treatmentFacility.length > 0){
          let path = this.getTfURlById(treatmentFacility[0].TFId,treatmentFacility)
          this.setState({
            tfForTable:path
          })
          const payload = { 
              url:`${this.getPathBasedOnEnv(window.location.hostname)}iot_dashboard/get_url_data/${fromdate}/${todate}?url=${path}`
          }
          await this.props.getTableData(payload)
          const chartData = localStorage.getItem('roleName') == STATEDASHBOARDUSER ? await this.props.getAllChartsDefaultSD({token : localStorage.getItem('token'),ulb:this.state.selectedUlb}) 
          :localStorage.getItem('roleName') == ADMIN ? await this.props.getAllChartsDefault({token : localStorage.getItem('token'),ulb:this.state.selectedUlb}) :
          await this.props.getAllChartsDefault({token : localStorage.getItem('token')})
          if(tfData && chartData){
            this.getCharts(chartData,treatmentFacility)
          }
        }
        
      }


    handleDialog = () => {
        this.setState({
            open:true,
            clicked:false
        })
    }

    handleEditDialog = (id,ulbId) => {
        this.setState({
            openEditDialog:true,
            ChartId : id,
            ulbId:ulbId
        })
    }

    onDelete = async(payload) => {
      localStorage.getItem('roleName') == STATEDASHBOARDUSER ? await this.props.deleteChartSD(payload,this.state.selectedUlb) : await this.props.deleteChart(payload) 
        const chartsData = localStorage.getItem('roleName') == STATEDASHBOARDUSER ? await this.props.getAllChartsDefaultSD({token : localStorage.getItem('token'),ulb:this.state.selectedUlb})
         :localStorage.getItem('roleName') == ADMIN ? await this.props.getAllChartsDefault({token : localStorage.getItem('token'),ulb:this.state.selectedUlb}) :
        await this.props.getAllChartsDefault({token : localStorage.getItem('token')})      
        this.getCharts(chartsData)
    }

    deleteChart = (id) => {
        swal("Are you sure you want to delete?", {
            icon: "warning",
            buttons: {
              cancel : 'No',
              confirm: 'Yes'
          },
          })
          .then( (yes) => {
              if(yes){
                const payload = {
                  id:id,
                  token:localStorage.getItem('token')
                } 
                this.onDelete(payload)  
              }else{
                return
              }
          });
    }

    onClose = (handleClose) => {   
      this.setState({ open: handleClose })
    }

    onEditDialogClose = (handleClose) => {
      this.setState({ openEditDialog: handleClose })
    }

    handleDateChange = (date) => {
      this.setState ({
        selectedDate:date
      })
    }

    handleClickOpen = () => {
        this.setState({openInfo:true});
    };

    handleCloseInfo = () => {
      this.setState({openInfo:false});
    };

    getFormattedChartData = (chartData,range) => {
      let formatCriteria;
      switch(range){
        case 'today':
          formatCriteria = 'MMM Do, h a'
          break;
        case 'sevenDays':
          formatCriteria = 'MM-DD-YYYY'
          break;
        case 'lastMonth':
          formatCriteria = 'MM-DD-YYYY'
          break; 
        default:
          formatCriteria = 'MMM Do, h a' 
      }
      chartData = chartData.map (data => {
        return {
         ...data,
         ParamValueBOD: Number(data.ParamValueBOD),
         ParamValueCOD: Number(data.ParamValueCOD),
         ParamValueFLRate: Number(data.ParamValueFLRate),
         ParamValueNO3: Number(data.ParamValueNO3),
         ParamValueORP: Number(data.ParamValueORP),
         ParamValuePH: Number(data.ParamValuePH),
         ParamValueTFLRate: Number(data.ParamValueTFLRate),
         ParamValueTSS: Number(data.ParamValueTSS)
        }
      })
  
      //to group sensors falling in same date range and find its total
      const reduced = chartData.reduce(function(m, d){
         if(!m[moment(d.DateEntry).format(formatCriteria)]){  
           m[moment(d.DateEntry).format(formatCriteria)] = {...d, count: 1};
           return m;
         }
         m[moment(d.DateEntry).format(formatCriteria)].ParamValueBOD += (d.ParamValueBOD);
         m[moment(d.DateEntry).format(formatCriteria)].ParamValueCOD += (d.ParamValueCOD);
         m[moment(d.DateEntry).format(formatCriteria)].ParamValueFLRate += (d.ParamValueFLRate);
         m[moment(d.DateEntry).format(formatCriteria)].ParamValueNO3 += (d.ParamValueNO3);
         m[moment(d.DateEntry).format(formatCriteria)].ParamValueNO3 += (d.ParamValueNO3);
         m[moment(d.DateEntry).format(formatCriteria)].ParamValueORP += (d.ParamValueORP);
         m[moment(d.DateEntry).format(formatCriteria)].ParamValuePH += (d.ParamValuePH);
         m[moment(d.DateEntry).format(formatCriteria)].ParamValueTFLRate += (d.ParamValueTFLRate);
         m[moment(d.DateEntry).format(formatCriteria)].ParamValueTSS += (d.ParamValueTSS);
         m[moment(d.DateEntry).format(formatCriteria)].count += 1;
         return m;
      },{});
  
     //  to compute the average
      const result = Object.keys(reduced).map(function(k){
          const item  = reduced[k];
          return {
              ...reduced[k],
              DateEntry: moment(reduced[k].DateEntry).format(formatCriteria),
              ParamValueBOD: (item.ParamValueBOD/item.count).toFixed(2),
              ParamValueCOD:( item.ParamValueCOD/item.count).toFixed(2),
              ParamValueFLRate: (item.ParamValueFLRate/item.count).toFixed(2),
              ParamValueNO3: (item.ParamValueNO3/item.count).toFixed(2),
              ParamValueORP: (item.ParamValueORP/item.count).toFixed(2),
              ParamValuePH: (item.ParamValuePH/item.count).toFixed(2),
              ParamValueTFLRate: (item.ParamValueTFLRate/item.count).toFixed(2),
              ParamValueTSS: (item.ParamValueTSS/item.count).toFixed(2)
          }
      })
      return result
    }

    getDatasets = (data,sensor) => {

      // data = this.getFormattedChartData(data,'')
      const datasets = data.map( data => {
        return data[`ParamValue${sensor}`] ? Number(data[`ParamValue${sensor}`]).toFixed(2) : 0
      })
      return datasets
    }

    getFormattedLabels = (chartData) => {
      // chartData = this.getFormattedChartData(chartData,'')
      const labels = chartData.map( label => {
        return moment(label.DateEntry).format('MMM Do, hh:mm a')
      })
      return labels
    }

  componentWillReceiveProps (nextProps){
     this.setState({
       success:nextProps.iotChartDataSucess
     })
  }

  getData = async() => {
    const todate = moment(this.state.todate).format('YYYY-MM-DD')
    const fromdate = moment(this.state.fromdate).format('YYYY-MM-DD')
    const data = await this.props.getURLData({toDate:todate,fromDate:fromdate})
    return data
  }
  
  filterChart = (chart,payload,allChartData) => {
      if((payload.data && !payload.data.Message) || !payload.data){
        payload = payload.data.sort(function(a,b){
          return new Date(a.DateEntry) - new Date(b.DateEntry)
        })
        payload.shift();
        let chartsToFormat = chart
        chartsToFormat.ULB.data.datasets.map( item => {
          item.data = this.getDatasets(payload,item.label)
          
        })
        chartsToFormat.ULB.options.scales.xAxes[0].ticks.maxTicksLimit =  window.innerWidth <=480 ? 10 : 20
        chartsToFormat.ULB.data.labels = this.getFormattedLabels(payload)
        const updateData = allChartData ? allChartData : this.state.chartsToDisplay.map(obj => [chartsToFormat].find(o => o.Id === obj.Id) || obj);
        this.setState({
          chartsToDisplay:updateData,
        })
      }else{
        if(payload.data && payload.data.Message){
          this.setState({
            chartsToDisplay: !!allChartData ? allChartData :[],
          })
          swal(payload.data.Message, {
            icon: "warning",
          });
        }else{
          swal("Data not found !", {
            icon: "warning",
          });
        }
       
      }
  }

  getPathBasedOnEnv = (hostName) => {
    switch(hostName){
      case process.env.REACT_APP_STAGING_HOST:
        return process.env.REACT_APP_STAGING_BASE_URL
      case process.env.REACT_APP_LOCAL_HOST_NAME:
        return process.env.REACT_APP_DEV_BASE_URL 
      case process.env.REACT_APP_DEV_HOST_NAME:
        return process.env.REACT_APP_DEV_BASE_URL  
      case process.env.REACT_APP_PROD_HOST:
        return process.env.REACT_APP_PROD_BASE_URL   
    }
  }

  getCharts = async(chartsData,TFData) => {
    TFData = TFData ? TFData : this.props.treatmentListing
    const tfIds = TFData.map(tf => {
      return tf.TFId
    })
    chartsData = chartsData.filter ( chart => {
      if(tfIds.includes(chart.ULB.data.tfId)){
        return chart
      }
    })

    const data =  chartsData ? chartsData :this.props.metaData
    if(data && data.length > 0){
      store.dispatch(fetchChartDataStart())
      await Promise.all(data.map( async(chart) => {
          let url = this.getTfURlById(chart.ULB.data.tfId,TFData)
          // if(url){
            const todate = moment(this.state.todate).format('YYYY-MM-DD')
            const fromdate = moment(this.state.fromdate).format('YYYY-MM-DD')
            try{
            const response = await axios.get(`${this.getPathBasedOnEnv(window.location.hostname)}iot_dashboard/get_url_data/${fromdate}/${todate}?url=${url}`,
            {headers:{
                  "Authorization":`Bearer ${localStorage.getItem('token')}`
            }})
            this.setState({ArrData:response.data.data[0]})
            this.filterChart(chart,response.data,chartsData)
            }
            catch(err){
              store.dispatch(fetchChartDataFail()) 
            }
          // }
            
      }))
      store.dispatch(fetchChartDataFail()) 

    }else{
      // swal({icon : "error" , title : "Data not found!"})
      
      this.setState({
        chartsToDisplay:[],
      })
      store.dispatch(fetchChartDataFail()) 
    }
 
}
    handleFromDate = async (date) => {
      this.setState ({
        fromdate:date,
        clicked:true,
        chartsToDisplay:this.props.metaData
      }, async () => {
        const todate = moment(this.state.todate).format('YYYY-MM-DD')
        const fromdate = moment(this.state.fromdate).format('YYYY-MM-DD')
        await this.props.getTableData({url:`${this.getPathBasedOnEnv(window.location.hostname)}iot_dashboard/get_url_data/${fromdate}/${todate}?url=${this.state.tfForTable}`})
        const data = this.props.metaData
        if(data && data.length > 0){
          data.map( (chart) => {
            if(chart.ULB!=null){
              let url = this.getTfURlById(chart.ULB.data.tfId)
              const todate = moment(this.state.todate).format('YYYY-MM-DD')
              const fromdate = moment(date).format('YYYY-MM-DD')            
              try {
                store.dispatch(fetchChartDataStart()) 
                axios.get(`${this.getPathBasedOnEnv(window.location.hostname)}iot_dashboard/get_url_data/${fromdate}/${todate}?url=${url}`,
                {headers:{
                  "Authorization":`Bearer ${localStorage.getItem('token')}`
                }}).then(response => {
                  this.filterChart(chart,response.data)
                  store.dispatch(fetchChartDataFail())  
                })
                .catch((err)=>
                {
                  store.dispatch(fetchChartDataFail()) 
                  if(err.response){
                    swal({icon : "error" , title : err.response.data.Message})
                    .then( (yes) => {
                     return
                  });
                  }
                   
                })
             } catch (error) {
                return
            }
            }
          })
          this.setState({
            loader:false
          })
        }else{
          this.setState({
            loader:false
          })
        }
      })
    }
    
    handleToDate = (date) => {
      this.setState ({
        todate:date,
        clicked:true,
        chartsToDisplay:this.props.metaData
      }, async () => {
        const todate = moment(this.state.todate).format('YYYY-MM-DD')
        const fromdate = moment(this.state.fromdate).format('YYYY-MM-DD')
        await this.props.getTableData({url:`${this.getPathBasedOnEnv(window.location.hostname)}iot_dashboard/get_url_data/${fromdate}/${todate}?url=${this.state.tfForTable}`})
        const data = this.props.metaData
        if(data && data.length > 0){
          data.map( (chart) => {
            if(chart.ULB!=null){
              let url = this.getTfURlById(chart.ULB.data.tfId)
              const todate = moment(date).format('YYYY-MM-DD')
              const fromdate = moment(this.state.fromdate).format('YYYY-MM-DD')            
              try {
                store.dispatch(fetchChartDataStart()) 
                axios.get(`${this.getPathBasedOnEnv(window.location.hostname)}iot_dashboard/get_url_data/${fromdate}/${todate}?url=${url}`,
                {headers:{
                  "Authorization":`Bearer ${localStorage.getItem('token')}`
                }}).then(response => {
                  this.filterChart(chart,response.data)  
                  store.dispatch(fetchChartDataFail()) 
                })
                .catch((err)=>
                {
                  store.dispatch(fetchChartDataFail()) 
                  if(err.response){
                    swal({icon : "error" , title : err.response.data.Message})
                    .then( (yes) => {
                     return
                  });
                  }
                   
                })
             } catch (error) {
                return
            }
            }
          })
          this.setState({
            loader:false
          })
        }else{
          this.setState({
            loader:false
          })
        }
      })
    }

    handleULBChange = (event) => {
      this.setState({
        [event.target.name]:event.target.value
      },async() => {
        (localStorage.getItem('roleName') == STATEDASHBOARDUSER || localStorage.getItem('roleName') == ADMIN) ?await this.props.fetchTreatmentListingIOT({token : localStorage.getItem('token'),ulb:this.state.selectedUlb})
        : await this.props.fetchTreatmentListingIOT({token : localStorage.getItem('token')}) 
        const chartsData = localStorage.getItem('roleName') == STATEDASHBOARDUSER ? await this.props.getAllChartsDefaultSD({token : localStorage.getItem('token'),ulb:event.target.value}) :
         await this.props.getAllChartsDefault({token : localStorage.getItem('token'),ulb:event.target.value})
         this.getCharts(chartsData)
        })
    }

    handleTableData = async(id) => {
      const path = this.getTfURlById(id)
      this.setState({
        tfForTable:path,
        loader:true,
      })
      const todate = moment(this.state.todate).format('YYYY-MM-DD')
      const fromdate = moment(this.state.fromdate).format('YYYY-MM-DD')
      const url = `${this.getPathBasedOnEnv(window.location.hostname)}iot_dashboard/get_url_data/${fromdate}/${todate}?url=${path}`
      const payload = { 
          url:url
      }
      const response = await this.props.getTableData(payload)
      this.setState({
        loader:false,
      })
      if(!response){
        this.setState({
          loader:false,
        })
      }
    }

    handleChartsToDisplay = async() => {
      const chartData = localStorage.getItem('roleName') == STATEDASHBOARDUSER ? await this.props.getAllChartsDefaultSD({token : localStorage.getItem('token'),ulb:this.state.selectedUlb}) :
      localStorage.getItem('roleName') == ADMIN ? await this.props.getAllChartsDefault({token : localStorage.getItem('token'),ulb:this.state.selectedUlb}) :
      await this.props.getAllChartsDefault({token : localStorage.getItem('token')})      
      this.getCharts(chartData)  
    }

    getTfURlById = (id,tfListingData) => {
      const treatmentListing = tfListingData ? tfListingData : this.props.treatmentListing
      const tf = treatmentListing.filter( tf => {
        if(tf.TFId == id){
          return tf.Url
        }
      })
      return !!tf && tf.length > 0 ? tf[0].Url : false
    }

    getTfNameById = (id,tfListingData) => {
      const treatmentListing = tfListingData ? tfListingData : this.props.treatmentListing
      const tf = treatmentListing.filter( tf => {
        if(tf.TFId == id){
          return tf.Url
        }
      })
      return (tf && tf.length > 0) ? tf[0].Name : false
    }

    checkTFPresence = (tfId,ids) => {
      if(ids.includes(tfId)){
        return true
      }
      return false
    }

    render() {
      let tfIds = []
      let columns = []
      let charts = this.state.chartsToDisplay.length > 0 ? this.state.chartsToDisplay : []
      const rows = this.props.iotTableData
      if(this.props.iotTableData.length){
        for (const [key, value] of Object.entries(this.props.iotTableData[0])) {
            if(value !== null){
              columns.push(key)
            }
        }
      }
      
      let treatmentFacility = this.props.treatmentListing.length > 0 && this.props.treatmentListing.filter( tf => {
        return tf.Url
      })
       tfIds = treatmentFacility && treatmentFacility.map( tf => {
        return tf.TFId
      })

        return (
            <div style={{marginTop:'-4%',marginRight:'2%'}}>
              {(!this.props.iotChartDataSucess) && <div id="cover-spin"></div>}
                {/* <div className="dashboard_top_path">
                    <p><img src="images/left-arrow.svg" /> Record Keeping Data Management / <span>IOT Dashboard</span></p>
                </div>  */}
                <div className="dashboard_right_contentWithout_bg">
                    <img className="dashboard_right_content_top_icon" src="images/Rectangle.svg" />
                    <div className="dashboard_right_content_top">
                        <h5>IOT Dashboard</h5>
                        <Button variant="outlined" style={{marginLeft:0,color:"#3DAB35",textTransform: 'none',border: '1px solid rgba(63, 171, 53, 0.5)'}} onClick = {this.handleDialog}> Add New Graph</Button>
                    </div>
                <div className="create_ulb_content_outer">
                    <div className="create_ulb_content create-Rd" style={{width:"100%"}}>
                       <Grid container spacing={3} className="marLeft" style={{marginTop:"-2px"}}>
                       <CardHeader stats icon>
                             <hr style={{border: "1px solid #3DAB35", marginLeft:"1%", width:"30px"}}></hr>
                       </CardHeader>
                     <Grid item xs={12} lg={(localStorage.getItem('roles') == 1 || localStorage.getItem('roleName') == STATEDASHBOARDUSER) ? 8 :12} style={{marginLeft:"-60px"}}>
                       <Paper style={{height:'104%'}}>
                       <Grid container spacing={3} className="py-mob" style={{paddingTop:"15px", paddingBottom:"15px", width:"100%"}}>
                       <Grid item lg={4} xs={12}>
                       <h2 className="heading-filter" style={{color:"#3DAB35",fontSize:"18px",marginLeft:"2%",}}>Filter By Date Range</h2>
                        </Grid>
                           <Grid item lg={4} xs={12}>
                             <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                 format="dd MMM YYY"
                                 margin="normal"
                                 id="date-picker-inline"
                                 disableFuture
                                 value={this.state.fromdate}
                                 onChange={this.handleFromDate}
                                 KeyboardButtonProps={{
                                   'aria-label': 'change date',
                                 }}
                               />  
                               </MuiPickersUtilsProvider>
                           </Grid>
                           <Grid item lg={4} xs={12}>
                               <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardDatePicker
                                      format="dd MMM YYY"
                                      margin="normal"
                                      minDate = {this.state.fromdate}
                                      id="date-picker-inline"
                                      value={this.state.todate}
                                      disableFuture
                                      onChange={this.handleToDate}
                                      KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                        }}
                                      />  
                                </MuiPickersUtilsProvider>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                     {(localStorage.getItem('roles') == 1 || localStorage.getItem('roleName') == STATEDASHBOARDUSER) ? 
                    <Grid lg={4} xs={12} className="grid-Padding">
                     <Paper className="style-slect-ulb" style={{width:"96%",height:'80%'}}>
                       <div className="second-ulb" style={{display:"flex", justifyContent:"space-around", paddingTop:'30px'}}>
                       <h2 style={{color:"#3DAB35",fontSize:"18px",height:'70px', width:"60%",}}>Select ULB</h2>
                       <FormControl>
                      <Select 
                      onChange = {this.handleULBChange}
                      style={{marginTop:"-12px", width:"85%"}}
                      value={this.state.selectedUlb} 
                      name="selectedUlb">
                      {this.props.ulbData.map((ulb)=>(
                          <MenuItem key={ulb.Id} value={ulb.Id}>{ulb.Name}</MenuItem>))
                      }  
                      </Select>
                      </FormControl>
                       </div>  
                       </Paper>                  
                     </Grid>:""}
                    
   
                     </Grid>
                    {charts.length > 0 && treatmentFacility.length > 0 && 
                        charts.map ( chart => {
                          return (
                            chart && chart.ULB!=null && <Grid container spacing={3} direction="row" style={{marginTop:'1%'}}> 
                            <Grid item xs={12}  className="mt-top">
                                <Paper >
                                  <hr style={{border:"1px solid #3DAB35", marginLeft:"1%", width:"30px"}}></hr>
                                <Grid container spacing={3} direction="row">
                                  <Grid item xs={4} lg={2} align="center" style={{marginTop:'1%'}}>
                                  <div className="dashboard_top_path" style={{display:"flex",justifyContent: "center"}}>
                                      {treatmentFacility && treatmentFacility.length> 0 && <p style={{fontWeight:'bold', fontSize:"15px", color: "green"}}><span style={{whiteSpace: "nowrap"}}>{this.getTfNameById(chart.ULB.data.tfId,treatmentFacility)}</span></p>}
                                      <InfoOutlinedIcon onClick={this.handleClickOpen} style={{width:'50px',height:'24px',color: "gray", cursor: "pointer"}}></InfoOutlinedIcon>
                                  </div>
                                  </Grid>
                                  <Grid item xs={8} lg={10} align="right">
                                  <SettingsIcon onClick={() => this.handleEditDialog(chart.Id,chart.UlbId)} style={{width:'20px',height:'20px',color:"green",marginTop:'2%',marginRight:"2%",cursor: "pointer", }}></SettingsIcon>
                                  <DeleteIcon onClick={() => this.deleteChart(chart.Id)} style={{width:'20px',height:'20px',color:"green",marginRight:"2%",  cursor: "pointer", }}></DeleteIcon>
                                </Grid>
                                <Grid container spacing={3} direction="row">
                                  <Grid item xs={12}>
                                  <div  className="desktopDisplay" style={{padding:"2%"}}>
                                    <Bar data ={chart.ULB.data} options = {chart.ULB.options} width={900} height={300}></Bar>
                                    </div>
                                    <div className="mobileDisplay" style={{padding:"4%"}}>
                                    <Bar  data ={chart.ULB.data} options = {chart.ULB.options} height={300}></Bar>
                                    </div>
                               
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Paper>  
                          </Grid>
                        </Grid> 
                        )
                      })
                    }
                      <Grid container spacing ={3} direction="row" className="mt-top" style={{marginTop:"2%"}}>
                      <Grid item xs={12} className='table-height'>
                          {
                            this.props.iotTableData.length > 0 && treatmentFacility.length > 0 &&
                            <Paper className="table-paper">
                            <BasicTable rows={rows} columns={columns} treatmentFacility = {treatmentFacility} handleTableData = {this.handleTableData} defaultTf = {treatmentFacility && treatmentFacility.length > 0 ? treatmentFacility[0].TFId : ''}/></Paper> 
                          }
                      </Grid>
                    </Grid> 
                  </div>
                </div>
                </div>
                <AddChartDialog responseData={rows[0]} range = {this.state.range} onClose = {this.onClose} open={this.state.open} todate={this.state.todate} handleChartToDisplay={this.handleChartsToDisplay}
                  selectedUlb = {this.state.selectedUlb} fromdate={this.state.fromdate} getTfURlById = {this.getTfURlById} tfId = {treatmentFacility && treatmentFacility.length > 0 && treatmentFacility[0].TFId}></AddChartDialog>
                <EditChartDialog responseData={rows[0]} selectedUlb = {this.state.selectedUlb} range = {this.state.range} onClose = {this.onEditDialogClose} open ={this.state.openEditDialog} ChartId = {this.state.ChartId} 
                  ulbId = {this.state.ulbId}
                  todate={this.state.todate}
                  fromdate={this.state.fromdate} handleChartToDisplay={this.handleChartsToDisplay} getTfURlById = {this.getTfURlById}></EditChartDialog>
                <Dialog onClose={this.handleCloseInfo} aria-labelledby="customized-dialog-title" open={this.state.openInfo}  
                fullWidth={true}  maxWidth={'sm'}  >
                        <DialogTitle className="border-none"  id="customized-dialog-title" onClose={this.handleCloseInfo} >
                                          Info
                        </DialogTitle>
                        <DialogContent>
                    <div className="paramDiv" style={{display:"flex", justifyContent:"space-between", padding:" 2% 25%"}}>
                            <div >
                              <h4 style={{color: "#3DAB35", fontFamily:"Arial, Helvetica, sans-serif"}}>Parameter</h4>
                              <p className="valueOf-para">1.ParamPH</p>
                              <p className="valueOf-para">2.ParamBOD</p>
                              <p className="valueOf-para">3.ParamCOD</p>
                              <p className="valueOf-para">4.ParamFLRate</p>
                              <p className="valueOf-para">5.ParamNO3</p>
                              <p className="valueOf-para">6.ParamORP</p>
                              <p className="valueOf-para">7.ParamTFLRate</p>
                              <p className="valueOf-para">8.ParamTSS</p>
                            </div>
                            <div>
                              <h4 style={{color: "#3DAB35", fontFamily:"Arial, Helvetica, sans-serif"}}>Threshold&nbsp;&nbsp;value</h4>
                              <p className="valueOf-Throhold">6.5-9</p>
                              <p className="valueOf-Throhold">30 mg/L</p>
                              <p className="valueOf-Throhold">NA</p>
                              <p className="valueOf-Throhold">NA</p>
                              <p className="valueOf-Throhold">NA</p>
                              <p className="valueOf-Throhold">NA</p>
                              <p className="valueOf-Throhold">NA</p>
                              <p className="valueOf-Throhold">100 mg/L</p>
                            </div>

                    </div>
                        </DialogContent>
                      </Dialog>
</div>

       
     
        )
    }
  }

const mapStateToProps = (state) => {
    const metaData = state.iotChartData.metaData ? state.iotChartData.metaData :[]
    let treatmentFacility = state.treatmentListing.treatmentListing &&
    state.treatmentListing.treatmentListing.length > 0 && state.treatmentListing.treatmentListing.filter( tf => {
      return tf.Url
    })
    return{
        iotChartData:state.iotChartData.iotChartData,
        iotTableData:state.iotChartData.iotTableData,
        iotChartDataSucess :state.iotChartData.iotChartDataSucess,
        metaData : metaData,
        ulbData    : state.ulbData.ulbData,
        treatmentListing:treatmentFacility
    }
}

const mapDispatchToProps = (dispatch)=> {
    return{
        getChartData: (payload) => dispatch(actionsCreators.getIotChartData(payload)),
        // getAllCharts:(payload) => dispatch(actionsCreators.getAllCharts(payload)),
        fetchUlbData : (payload) => dispatch(actionCreators.fetchUlbData(payload)),
        deleteChart:(payload) => dispatch(actionCreators.deleteChart(payload)),
        deleteChartSD:(payload,ulbId) => dispatch(actionCreators.deleteChartSD(payload,ulbId)),
        fetchTreatmentListingIOT: (payload) => dispatch(actionsCreators.fetchTreatmentListingIOT(payload)),
        editChart : (payload) => dispatch(actionCreators.editChart(payload)),
        getTableData : (payload) => dispatch(actionsCreators.getTableData(payload)),
        getAllChartsDefault :(payload) => dispatch(actionsCreators.getAllChartsDefault(payload)),
        getAllChartsDefaultSD :(payload) => dispatch(actionsCreators.getAllChartsDefaultSD(payload)),
        getURLData:(payload) => dispatch(actionCreators.getURLData(payload))

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IotDashboard)
