import * as actionTypes from '../actionsTypes'
import axios from '../../../http-config'
import swal from 'sweetalert'

export const fetchRoleListingStart = () => {
    return({
        type         : actionTypes.FETCH_ROLE_DATA_START,
        loading: false
    })  
}

export const fetchRoleListingSuccess = (response) => {
    return({
        type         : actionTypes.FETCH_ROLE_DATA_SUCCESS,
        roleListing  : response.data.filter((item)=> item.name == "State Dashboard" ? item.name = "State/Regional Officer" : item.name ),
        totalCount   : response.totalCount,
        loading: true
    })
}

export const fetchRoleListingFail = (error) => {
    return({
        type         : actionTypes.FETCH_ROLE_DATA_FAIL,
        error        : error,
        loading: false
    })
}

export const fetchRoleListing = (payload) => {
    let token = payload.token
    return (dispatch) => {
        dispatch(fetchRoleListingStart())
        axios({
            method   : 'get',
            url      : `/role?limit=${payload.limit}&page=${payload.page}&sortColumnName=${payload.sortName}&sortColumnType=${payload.sortValue}&roleId=${!!payload.filterValue&&payload.filterValue!=""? payload.filterValue:''}`,
            headers :  {'Authorization': `Bearer ${token}`}
        })
        .then((response) => {
            dispatch(fetchRoleListingSuccess(response.data))
        })
        .catch((err) => {
            dispatch(fetchRoleListingFail())
        })
    }
}

export const addRole = (payload, payload2) => {
    let url = '/role'
    return (dispatch) => {
        dispatch(fetchRoleListingStart())
        axios.post(url, payload, { headers : { "Authorization":`Bearer ${payload2.token}`}})
        .then((response) => {
            const fetchedResponse = response.data.success;
            fetchedResponse && swal({
                title: "Role added successfully!",
                icon: "success",
                buttons: {
                    confirm: 'Ok'
                }
              })
     
            dispatch(fetchRoleListing(payload2))
        }, 
        (err) => {
            swal(err.response.data.error[0]["message"])
            dispatch(fetchRoleListingFail(err))
        })
        .catch((err) => {
            dispatch(fetchRoleListingFail(err))
        })
    }
}

export const deleteRole = (payload, payload2) => {
    return (dispatch) => {
        dispatch(fetchRoleListingStart())
        const url = '/role'
        axios.delete(url , { data : payload, headers : {   "Authorization":`Bearer ${payload2.token}`} })
        .then((response) => {
    
            dispatch(fetchRoleListing(payload2))
            swal({
                title:"Your data has been deleted!",
                icon: "success",
            })
        }, 
        (err) => {
            swal({icon : "error" , title : err.response.data.error[0]["message"]}) 
        })
        .catch((err) => {
            dispatch(fetchRoleListingFail(err))
        })
    }
}

export const editRole = (payload, payload2) => {
    return (dispatch) => {
        dispatch(fetchRoleListingStart())
        let url = '/role'
        axios.put(url, payload,{headers:{
            "Authorization":`Bearer ${payload2.token}`
        }})
        .then((response) => {
            const fetchedResponse = response.data.success;
            fetchedResponse && swal({
                title: "You have Successfully edited the data!!!",
                icon: "success",
                buttons: {
                    confirm: 'Ok'
                }
            })
         
            dispatch(fetchRoleListing(payload2))
        }, 
        (err) => {
            swal({icon : "error" , title : err.response.data.error[0]["message"]})  
        })
        .catch((err) => {
            dispatch(fetchRoleListingFail(err))
        })
    }
}

export const fetchRoleListingExport = (payload) => {
    let token = payload.token
   const  url = `/role?limit=${payload.limit}&page=${payload.page}&sortColumnName=${payload.sortName}&export=true&sortColumnType=${payload.sortValue}&roleId=${!!payload.filterValue&&payload.filterValue!=""? payload.filterValue:''}`
    return dispatch=>{
        dispatch(fetchRoleListingStart())
        axios.get(
            url,
            {headers:{ "Authorization":`Bearer ${token}`
        }}).then(function (response) {
            let link1=response.data.data[0].file_path
            
            window.downloadFile = function (link) {
                window.open(link, '_blank');
            }
            window.downloadFile(link1)
          
        }).catch(error => {
        })
    }
}