// component's config object.
import {STATEDASHBOARDUSER, SANITARYINSPECTOR, ADMIN} from '../config/constant'
const components = {
	dashboard: {
		component: 'Dashboard',
		url: '',
		title: 'Dashboard',
		module: 1
	},
	emailManagement: {
		component: 'EmailManagement',
		url: 'email_manage',
		title: 'EmailManagement',
		module: 1
	},
	emailAdd: {
		component: 'EmailAdd',
		url: 'email-add',
		title: 'EmailAdd',
		module: 1
	},
	EmailManagementEdit: {
		component: 'EmailManagementEdit',
		url: 'email_edit/:id',
		title: 'EmailManagementEdit',
		module: 1
	},
	operatorAdd: {
		component: 'OperatorAdd',
		url: 'operator-management/add/new',
		title: 'OperatorAdd',
		module: 1
	},
	operatorEdit: {
		component: 'OperatorEdit',
		url: 'operator-management/:id',
		title: 'OperatorEdit',
		module: 1
	},
	operatorData: {
		component: 'operatorData',
		url: 'operator-data/:id',
		title: 'operatorData',
		module: 1
	},
	operatorListing: {
		component: 'OperatorListing',
		url: 'operator-management',
		title: 'OperatorListing',
		module: 1
	},
	propertyAdd: {
		component: 'PropertyAdd',
		url: 'property-management/add/new/',
		title: 'PropertyAdd',
		module: 1
	},
	propertyListing: {
		component: 'PropertyListing',
		url: localStorage.getItem('roleName') == "ULB officer" || localStorage.getItem('roleName') == SANITARYINSPECTOR ? '/' : 'property-management',
		title: 'PropertyListing',
		module: 1
	},
	PropertyEdit: {
		component: 'PropertyEdit',
		url: 'edit_property/:id',
		title: 'PropertyEdit',
		module: 1
	},
	propertyLoads: {
		component: 'PropertyLoads',
		url: 'property_loads/:id',
		title: 'PropertyLoads',
		module: 1
	},
	scheduleProperty:{
		component: 'PropertySummary',
		url:"schedule-property/:id",
		title: 'ScheduleProperty',
		module: 1
		
	},
	PropertySummary: {
		component: 'ScheduleProperty',
		url: 'property-summary/:id',
		title: 'ScheduleProperty',
		module: 1
	},
	PropertyHistory: {
		component: 'PropertyHistory',
		url: 'property-History/:id',
		title: 'ScheduleProperty',
		module: 1
	},
	recordKeeper: {
		component: 'RecordKeeper',
		url:'records/:id',

		title: 'RecordKeeper',
		module: 1
	},
	SameLoads: {
		component: 'SameLoads',
		url:'SameLoads',
		title: 'RecordKeeper',
		module: 1
	},
	recordKeeperEdit: {
		component: 'RecordKeeperEdit',
		url: 'record_edit/:id',
		title: 'RecordKeeperEdit',
		module: 1
	},
	treatmentFacility: {
		component: 'TreatmentFacility',
		url: 'treatment',
		title: 'TreatmentFacility',
		module: 1
	},
	treatmentFacilityNew: {
		component: 'TreatmentFacilityNew',
		url: 'new_TF',
		title: 'TreatmentFacilityNew',
		module: 1
	},
	treatmentFacilityEdit: {
		component: 'TreatmentFacilityEdit',
		url: 'edit_TF/:id',
		title: 'TreatmentFacilityEdit',
		module: 1
	},
	treatmentFacilityviewReason: {
		component: 'TreatmentViewReason',
		url: 'ViewReason/:id',
		title: 'Disposal Reason',
		module: 1
	},
	truckDocs: {
		component: 'TruckDocs',
		url: 'truck-docs/:operatorId/:id',
		title: 'TruckDocs',
		module: 1
	},
	truckListing: {
		component: 'TruckListing',
		url: 'truck-management/:id',
		title: 'TruckListing',
		module: 1
	},
	ulbListing: {
		component: 'UlbListing',
		url: 'ulb-management',
		title: 'UlbListing',
		module: 1
	},
	ulbAdd: {
		component: 'UlbAdd',
		url: 'ulb-management/add/new/',
		title: 'UlbAdd',
		module: 1
	},
	RCEdit: {
		component: 'RCEdit',
		url: 'setting/rcConfiguration/:id',
		title: 'RCEdit',
		module: 1
	},
	UlbEdit: {
		component: 'UlbEdit',
		url: 'ulb-management/:id',
		title: 'UlbEdit',
	},
	roleListing: {
		component: 'RoleListing',
		url:'role-management',
		title: 'RoleListing',
		module: 1
	},
	roleModulesView: {
		component: 'RoleModulesView',
		url: 'role-management/:id',
		title: 'RoleModule',
	},
	UserListing: {
		component: 'UserListing',
		url: 'user-management',
		title:'UserListing',
		module: 1
	},
	UserAdd: {
		component: 'UserAdd',
		url: 'user-add',
		title: 'UserAdd',
		module: 1
	},
	UserEdit: {
		component: 'UserEdit',
		url: 'edit-user/:id',
		title: 'UserEdit',
		module: 1
	},
	IotDashboard: {
		component:'IotDashboard',
		url:  'iotDashboard',
		title:'IotDashboard',
	},
	StateDashboard:{
		component: 'StateDashboard',
		url: localStorage.getItem('roleName') == "State/Regional Officer" || localStorage.getItem("roleName") == ADMIN ? '/' :'state-dashboard',
		title: 'StateDashboard',
		module: 1
	},
	WardListing:{
		component: 'WardListing',
		url: 'ward-management',
		title: 'wardListing',
		module: 1
	},
	ZoneListing:{
		component: 'ZoneListing',
		url: 'zone-management',
		title: 'ZoneListing',
		module: 1
	},
	wardAdd:{
		component: 'WardAdd',
		url: 'add_ward',
		title: 'WardAdd',
		module: 1
	},
	WardEdit:{
		component: 'WardEdit',
		url: 'edit_ward/:id',
		title: 'WardEdit',
		module: 1
	},
	ZoneAdd:{
		component: 'ZoneAdd',
		url: 'add_zone',
		title: 'ZoneAdd',
		module: 1
	},
	ZoneEdit:{
		component: 'ZoneEdit',
		url: 'edit_zone/:id',
		title: 'ZoneEdit',
		module: 1
	},
	ViewWard:{
		component: 'ViewWard',
		url: 'viewWard/:id',
		title: 'Existingward',
		module: 1
	},
	AreaListing: {
		component: 'AreaLIsting',
		url: 'Area-management',
		title: 'AreaListing',
		module: 1
	},
	// PropertySummary: {
	// 	component: 'PropertySummary',
	// 	url: 'property-summary',
	// 	title: 'PropertySummary',
	// 	module: 1
	// },	
	AreaAdd: {
		component: 'AreaAdd',
		url: 'add_area',
		title: 'AreaAdd',
		module: 1
	},
	AreaEdit:{
		component: 'AreaEdit',
		url: 'edit_area/:id',
		title: 'AreaEdit',
		module: 1
	},
	clusterListing: {
		component: 'clusterListing',
		url: 'cluster-management',
		title:'ClusterListing',
		module: 1
	},
	ClusterAdd: {
		component: 'clusterAdd',
		url: 'cluster-management/add/new/',
		title: 'clusterAdd',
		module: 1
	},
	ClusterEdit: {
		component: 'clusterEdit',
		url: 'cluster-management/:id',
		title: 'clusterEdit',
		module: 1
	},
	setting: {
		component: 'setting',
		url: 'setting',
		title:'Setting',
		module: 1
	},
	SanitaryInspector: {
		component: 'SanitaryInspector',
		url:  'Sanitory-Manegement',
		title:'SanitaryInspector',
		module: 1
	},
	EditSi: {
		component: 'EditSi',
		url: 'Sanitory-Manegement/:id',
		title: 'EditSi',
		module: 1
	},
	ZonesAndWardsListing: {
		component: 'ZonesAndWardsListing',
		url: 'Sanitory-Manegement/zone/ward/:id',
		title: 'ZonesAndWardsListing',
		module: 1
	},
	RegionalListing: {
		component: 'RegionalListing',
		url: 'regionalOfficer',
		title:'RegionalListing',
		module: 1
	},
	
	
};

// modules for grouping.
const modules = {
	0: {
		title: 'Dashboard',
		icon: 'home',
		isExpendable: true
	}
};

// component's access to roles.
const rolesConfig = {
	0:{},
	1: {
        routes: [
			components.ulbListing,
			components.UserListing,
			components.ulbAdd,
			components.UlbEdit,
			components.roleListing,
			components.UserListing,
			components.UserEdit,
			components.UserAdd,
			components.roleModulesView,
			components.clusterListing,
			components.ClusterAdd,
			components.ClusterEdit,
			components.RCEdit
			// components.IotDashboard
		]
	},
	2: {
		routes: [
			components.dashboard,
            components.emailManagement,
            
            components.emailAdd,
			components.EmailManagementEdit,
			components.operatorAdd,
			components.operatorEdit,
            components.operatorListing,
            
            components.propertyAdd,
			components.propertyListing,
			components.PropertyEdit,
			components.propertyLoads,
			components.scheduleProperty,
			
            
			components.recordKeeper,
			components.SameLoads,
            components.recordKeeperEdit,

            components.treatmentFacility,
            components.treatmentFacilityNew,
            components.treatmentFacilityEdit,
			components.treatmentFacilityviewReason,
            components.truckDocs,
			components.truckListing,

			components.StateDashboard,
			components.WardListing,
			components.ZoneListing,
			components.AreaListing,
			components.AreaAdd,
			components.SanitaryInspector,
			components.EditSi,
			components.ZonesAndWardsListing
		]
	},
	3: {
        routes: [
			// components.IotDashboard,
			components.StateDashboard
		]
	},
	4: {
		routes: [
			components.dashboard,
            components.emailManagement,
			components.emailAdd,
			components.EmailManagementEdit,

			components.operatorAdd,
			components.operatorEdit,
            components.operatorListing,
            
            components.propertyAdd,
			components.propertyListing,
			components.PropertyEdit,
			components.propertyLoads,
			components.scheduleProperty,
            
			components.recordKeeper,
			components.SameLoads,
            components.recordKeeperEdit,

            components.treatmentFacility,
            components.treatmentFacilityNew,
            components.treatmentFacilityEdit,
			components.treatmentFacilityviewReason,
            components.truckDocs,
			components.truckListing,

			components.StateDashboard,

			components.WardListing,
			components.ZoneListing,
			components.ZoneAdd,
			components.AreaListing,
			components.AreaAdd,
			components.SanitaryInspector,
			components.EditSi,
			components.ZonesAndWardsListing,
		]
	}
};

const roleNameConfig = {
	"Admin": {
        routes: [
			components.ulbListing,
			components.UserListing,
			components.ulbAdd,
			components.UlbEdit,
			components.roleListing,
			components.UserListing,
			components.UserEdit,
			components.roleModulesView,
			components.UserAdd,
			components.StateDashboard,
			components.IotDashboard,
			components.recordKeeper,
			components.SameLoads,
			components.clusterListing,
			components.ClusterAdd,
			components.ClusterEdit,
			components.RCEdit,
			components.SanitaryInspector,
			components.EditSi,
			components.ZonesAndWardsListing,
			components.RegionalListing
			
		]
	},
	"ULB Admin": {
		routes: [
			components.dashboard,
            components.emailManagement,
            
            components.emailAdd,
			components.EmailManagementEdit,
			components.operatorAdd,
			components.operatorEdit,
			components.operatorData,
            components.operatorListing,
            
            components.propertyAdd,
			components.propertyListing,
			components.PropertyEdit,
			components.propertyLoads,
			components.scheduleProperty,
			components.PropertySummary,
            components.PropertyHistory,
			components.recordKeeper,
            components.recordKeeperEdit,
			components.IotDashboard,
            components.treatmentFacility,
            components.treatmentFacilityNew,
            components.treatmentFacilityEdit,
			components.treatmentFacilityviewReason,
            components.truckDocs,
			components.truckListing,
			components.WardListing,
			components.wardAdd,
			components.WardEdit,
			components.ZoneListing,
			components.ZoneAdd,
			components.ZoneEdit,
			components.ViewWard,
			components.ulbListing,
			components.UserListing,
			components.ulbAdd,
			components.UlbEdit,
			components.roleListing,
			components.UserListing,
			components.UserEdit,
			components.roleModulesView,
			components.UserAdd,
			components.StateDashboard,
			components.IotDashboard,
			components.recordKeeper,
			components.SameLoads,
			components.clusterListing,
			components.ClusterAdd,
			components.ClusterEdit,
			components.RCEdit,
			components.setting,
			components.AreaListing,
			components.AreaAdd,
			components.AreaEdit,
			components.SanitaryInspector,
			components.EditSi,
			components.ZonesAndWardsListing
			
		]
	},
	"State/Regional Officer": {
		routes:[
			components.StateDashboard,
			components.recordKeeper,
            components.recordKeeperEdit,
			components.SameLoads,
			components.IotDashboard,
		]
	},

	"Scheduler": {
		routes:[
			components.scheduleProperty,
			components.PropertySummary,
		]
	},
	"ULB officer":{
		routes: [
			components.scheduleProperty,
			components.PropertySummary,
			components.propertyAdd,
			components.propertyListing,
			components.PropertyEdit,
			components.propertyLoads,
			components.WardListing,
			components.wardAdd,
			components.WardEdit,
			components.ZoneListing,
			components.ZoneAdd,
			components.ZoneEdit,
			components.ViewWard,
			components.AreaListing,
			components.AreaAdd,
			components.AreaEdit,
			components.recordKeeper,
			components.SameLoads,
            components.recordKeeperEdit,
			components.PropertyHistory,
		]
	}
};

export { modules, rolesConfig, roleNameConfig };
