import React, { Component, useEffect } from 'react'
import { connect } from 'react-redux'
import UserForm from './UserForm'
import * as actionCreators from '../../store/actions/index'

const UserAdd = (props) => {
    const handleSubmit = (payload) => {
        props.onAddUser(payload,props)
    }
    return (
        <div>
            <div className="create_ulb">
                <div className="dashboard_top_path">
                    <p><img src="images/left-arrow.svg" /> User Management / <span>Create New User</span></p>
                </div>
                <div className="dashboard_right_content">
                    <img className="dashboard_right_content_top_icon" src="images/Rectangle.svg" />
                    <div className="dashboard_right_content_top">
                        <h5>Create New User</h5>
                    </div>
                    <div className="create_ulb_content_outer">
                        <div className="create_ulb_content">
                            {/* <UserForm handleSubmit={handleSubmit}/> */}
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    )
    
}

const mapStateToProps = (state) => ({
    token : state.auth.token
})

const mapDispatchToProps = (dispatch) => {
    return({
        onAddUser : (payload,props) => dispatch(actionCreators.addUser(payload,props))
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAdd)