export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

export const FETCH_LOADS_START = 'FETCH_LOADS_START';
export const FETCH_LOADS_SUCCESS = 'FETCH_LOADS_SUCCESS';
export const FETCH_SAME_LOADS_SUCCESS = 'FETCH_SAME_LOADS_SUCCESS';
export const FETCH_LOADS_FAIL = 'FETCH_Lexport const OADS_FAIL';
export const FETCH_CONFIG_SUCCESS = 'FETCH_CONFIG_SUCCESS';
export const FETCH_OPERATORLISTING_LOAD_SUCCESS = 'FETCH_OPERATORLISTING_LOAD_SUCCESS';
export const FETCH_TRUCKLISTING_LOAD_SUCCESS = 'FETCH_TRUCKLISTING_LOAD_SUCCESS'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';
export const RESET_PASSWORD_START = 'RESET_PASSWORD_START';

export const FETCH_LOAD_START = 'FETCH_LOAD_START';
export const FETCH_LOAD_SUCCESS = 'FETCH_LOAD_SUCCESS';
export const FETCH_LOAD_FAIL = 'FETCH_export const LOAD_FAIL';

export const FETCH_PROPERTYTYPES_START = 'FETCH_PROPERTYTYPES_START';
export const FETCH_PROPERTYTYPES_SUCCESS = 'FETCH_PROPERTYTYPES_SUCCESS';
export const FETCH_PROPERTYTYPES_FAIL = 'FETCH_export const PROPERTYTYPES_FAIL';

export const FETCH_EMAILMANAGE_START = 'FETCH_EMAILMANAGE_START';
export const FETCH_EMAILMANAGE_SUCCESS = 'FETCH_EMAILMANAGE_SUCCESS';
export const FETCH_EMAILMANAGE_FAIL = 'FETCH_export const EMAILMANAGE_FAIL';

export const REMOVE_EMAILMANAGE = ' REMOVE_EMAILMANAGE';
export const ADD_EMAILMANAGE='ADD_EMAILMANAGE';

export const FETCH_EXPORTS_START = 'FETCH_EXPORTS_START';
export const FETCH_EXPORTS_SUCCESS = 'FETCH_EXPORTS_SUCCESS';
export const FETCH_EXPORTS_FAIL = 'FETCH_export const EXPORTS_FAIL';

export const FETCH_HEADERS_START = 'FETCH_HEADERS_START';
export const FETCH_HEADERS_SUCCESS = 'FETCH_HEADERS_SUCCESS';
export const FETCH_HEADERS_FAIL = 'FETCH_export const HEADERS_FAIL';

export const FETCH_DASHBOARD_START = 'FETCH_DASHBOARD_START';
export const FETCH_DASHBOARD_SUCCESS = 'FETCH_DASHBOARD_SUCCESS';
export const FETCH_DASHBOARD_FAIL = 'FETCH_DASHBOARD_FAIL';

export const FETCH_STATE_DASHBOARD_START = 'FETCH_STATE_DASHBOARD_START';
export const FETCH_STATE_DASHBOARD_SUCCESS = 'FETCH_STATE_DASHBOARD_SUCCESS';
export const FETCH_STATE_DASHBOARD_FAIL = 'FETCH_STATE_DASHBOARD_FAIL';

export const FETCH_STATE_DASHBOARD_CHART_DATA_START = 'FETCH_STATE_DASHBOARD_CHART_DATA_START';
export const FETCH_STATE_DASHBOARD_CHART_DATA_SUCCESS = 'FETCH_STATE_DASHBOARD_CHART_DATA_SUCCESS';
export const FETCH_STATE_DASHBOARD_CHART_DATA_FAIL = 'FETCH_STATE_DASHBOARD_CHART_DATA_FAIL';

export const FETCH_TREATMENTHEADERS_START = 'FETCH_TREATMENTHEADERS_START';
export const FETCH_TREATMENTHEADERS_SUCCESS = 'FETCH_TREATMENTHEADERS_SUCCESS';
export const FETCH_TREATMENTHEADERS_FAIL = 'FETCH_TREATMENTHEADERS_FAIL';

export const FETCH_TREATMENTLISTING_START = 'FETCH_TREATMENTLISTING_START';
export const FETCH_TREATMENTLISTING_SUCCESS = 'FETCH_TREATMENTLISTING_SUCCESS';
export const FETCH_TREATMENTLISTING_FAIL = 'FETCH_TREATMENTLISTING_FAIL';
export const FETCH_OPERATOR_HEADERS_START = 'FETCH_OPERATOR_HEADERS_START'
export const FETCH_OPERATOR_HEADERS_SUCCESS = 'FETCH_OPERATOR_HEADERS_SUCCESS'
export const FETCH_OPERATOR_HEADERS_FAIL = 'FETCH_OPERATOR_HEADERS_FAIL'

export const FETCH_OPERATOR_DATA_START = 'FETCH_OPERATOR_DATA_START'
export const FETCH_OPERATOR_DATA_SUCCESS = 'FETCH_OPERATOR_DATA_SUCCESS'
export const FETCH_OPERATOR_DATA_FAIL = 'FETCH_OPERATOR_DATA_FAIL'

export const FETCH_TRUCK_HEADERS_START = 'FETCH_TRUCK_HEADERS_START'
export const FETCH_TRUCK_HEADERS_SUCCESS = 'FETCH_TRUCK_HEADERS_SUCCESS'
export const FETCH_TRUCK_HEADERS_FAIL = 'FETCH_TRUCK_HEADERS_FAIL'

export const FETCH_TRUCK_DATA_START = 'FETCH_TRUCK_DATA_START'
export const FETCH_TRUCK_DATA_SUCCESS = 'FETCH_TRUCK_DATA_SUCCESS'
export const FETCH_TRUCK_DATA_FAIL = 'FETCH_TRUCK_DATA_FAIL' 

export const FETCH_TRUCK_DOC_HEADERS_START = 'FETCH_TRUCK_DOC_HEADERS_START'
export const FETCH_TRUCK_DOC_HEADERS_SUCCESS = 'FETCH_TRUCK_DOC_HEADERS_SUCCESS'
export const FETCH_TRUCK_DOC_HEADERS_FAIL = 'FETCH_TRUCK_DOC_HEADERS_FAIL'

export const FETCH_TRUCK_DOC_DATA_START = 'FETCH_TRUCK_DOC_DATA_START'
export const FETCH_TRUCK_DOC_DATA_SUCCESS = 'FETCH_TRUCK_DOC_DATA_SUCCESS'
export const FETCH_TRUCK_DOC_DATA_FAIL = 'FETCH_TRUCK_DOC_DATA_FAIL'

export const FETCH_BULKIMPORT_START = 'FETCH_BULKIMPORT_START'
export const FETCH_BULKIMPORT_SUCCESS = 'FETCH_BULKIMPORT_SUCCESS'
export const FETCH_BULKIMPORT_FAIL = 'FETCH_BULKIMPORT_FAIL'

export const FETCH_PROPERTYTYPESEDIT_START = 'FETCH_PROPERTYTYPESEDIT_START';
export const FETCH_PROPERTYTYPESEDIT_SUCCESS = 'FETCH_PROPERTYTYPESEDIT_SUCCESS';
export const FETCH_PROPERTYTYPESEDIT_FAIL = 'FETCH_PROPERTYTYPESEDIT_FAIL';

export const FETCH_PROPERTYLISTING_START = 'FETCH_PROPERTYLISTING_START';
export const FETCH_PROPERTYLISTING_SUCCESS = 'FETCH_PROPERTYLISTING_SUCCESS';
export const FETCH_PROPERTYTYPELISTING_SUCCESS = 'FETCH_PROPERTYTYPELISTING_SUCCESS';
export const FETCH_PROPERTYLISTING_FAIL = 'FETCH_PROPERTYLISTING_FAIL';

export const FETCH_OPERATORLISTING_START = 'FETCH_OPERATORLISTING_START';
export const FETCH_OPERATORLISTING_SUCCESS = 'FETCH_OPERATORLISTING_SUCCESS';
export const FETCH_OPERATORLISTING_FAIL = 'FETCH_OPERATORLISTING_FAIL';

export const FETCH_OPERATORDAY_SUCCESS = 'FETCH_OPERATORDAY_SUCCESS';

export const FETCH_PROPERTYHEADERS_START = 'FETCH_PROPERTYHEADERS_START';
export const FETCH_PROPERTYHEADERS_SUCCESS = 'FETCH_PROPERTYHEADERS_SUCCESS';
export const FETCH_PROPERTYHEADERS_FAIL = 'FETCH_PROPERTYHEADERS_FAIL';

export const FETCH_ULB_HEADERS_START = 'FETCH_ULB_HEADERS_START'
export const FETCH_ULB_HEADERS_SUCCESS = 'FETCH_ULB_HEADERS_SUCCESS'
export const FETCH_ULB_HEADERS_FAIL = 'FETCH_ULB_HEADERS_FAIL'

export const FETCH_ULB_DATA_START = ' FETCH_ULB_DATA_START'
export const FETCH_ULB_DATA_SUCCESS = 'FETCH_ULB_DATA_SUCCESS'
export const FETCH_ULB_DATA_FAIL = 'FETCH_ULB_DATA_FAIL'

export const FETCH_ROLE_DATA_START = 'FETCH_ROLE_DATA_START'
export const FETCH_ROLE_DATA_SUCCESS = 'FETCH_ROLE_DATA_SUCCESS'
export const FETCH_ROLE_DATA_FAIL = 'FETCH_ROLE_DATA_FAIL'

export const FETCH_USER_LISTING_START = 'FETCH_USER_LISTING_START'
export const FETCH_USER_LISTING_SUCCESS = 'FETCH_USER_LISTING_SUCCESS'
export const FETCH_USER_LISTING_FAIL = 'FETCH_USER_LISTING_FAIL'

export const FETCH_USER_HEADERS_START = 'FETCH_USER_HEADERS_START'
export const FETCH_USER_HEADERS_SUCCESS = 'FETCH_USER_HEADERS_SUCCESS'
export const FETCH_USER_HEADERS_FAIL = 'FETCH_USER_HEADERS_FAIL'

export const FETCH_IOT_CHART_DATA_START = "FETCH_IOT_CHART_DATA_START"
export const FETCH_IOT_CHART_DATA_SUCCESS = "FETCH_IOT_CHART_DATA_SUCCESS"
export const FETCH_IOT_CHART_DATA_FAIL = "FETCH_IOT_CHART_DATA_FAIL"
export const FETCH_CHART_META_DATA_START = "FETCH_CHART_META_DATA_START"
export const FETCH_CHART_META_DATA_SUCCESS = "FETCH_CHART_META_DATA_SUCCESS"
export const FETCH_FILTEREDDATA_SUCCESS = "FETCH_FILTEREDDATA_SUCCESS"
export const FETCH_FILTEREDDATA_START = "FETCH_FILTEREDDATA_START"
export const FETCH_IOT_TABLE_DATA_START = "FETCH_IOT_TABLE_DATA_START"
export const FETCH_IOT_TABLE_DATA_SUCCESS = "FETCH_IOT_TABLE_DATA_SUCCESS"

export const FETCH_SCHEDULEPROPERTYZONE_SUCCESS = "FETCH_SCHEDULEPROPERTYZONE_SUCCESS";
export const FETCH_SCHEDULEPROPERTYWARD_SUCCESS = 'FETCH_SCHEDULEPROPERTYWARD_SUCCESS';

export const FETCH_WARD_HEADERS_START = "FETCH_WARD_HEADERS_START"
export const FETCH_WARD_HEADERS_SUCCESS = "FETCH_WARD_HEADERS_SUCCESS"
export const FETCH_WARD_HEADERS_FAIL = "FETCH_WARD_HEADERS_FAIL"

export const FETCH_WARD_LISTING_START = "FETCH_WARD_LISTING_START"
export const FETCH_WARD_LISTING_SUCCESS = "FETCH_WARD_LISTING_SUCCESS"
export const FETCH_WARD_LISTING_FAIL = "FETCH_WARD_LISTING_FAIL"
export const FETCH_ZONE_SUCCESS = "FETCH_ZONE_SUCCESS";
export const FETCH_ZONE_START = "FETCH_ZONE_START";
export const FETCH_ZONE_FAIL = "FETCH_ZONE_FAIL";

export const FETCH_AREA_START = 'FETCH_AREA_START';
export const FETCH_AREA_SUCCESS = 'FETCH_AREA_SUCCESS';
export const FETCH_AREA_FAIL = 'FETCH_AREA_FAIL';

export const FETCH_SUMMARY_START = "FETCH_SUMMARY_START";
export const FETCH_SUMMARY_SUCCESS = "FETCH_SUMMARY_SUCCESS";
export const FETCH_SUMMARY_FAIL = "FETCH_SUMMARY_FAIL";

export const FETCH_STATUS_START = "FETCH_STATUS_START";
export const FETCH_STATUS_SUCCESS = "FETCH_STATUS_SUCCESS";
export const FETCH_STATUS_FAIL = "FETCH_STATUS_FAIL";

export const FETCH_CLUSTER_START = "FETCH_CLUSTER_STAR";
export const FETCH_CLUSTER_SUCCESS = "FETCH_CLUSTER_SUCCESS";
export const FETCH_CLUSTER_FAIL = "FETCH_CLUSTER_FAIL";

export const FETCH_CLUSTERLISTING_START = "FETCH_CLUSTERLISTING_START";
export const FETCH_CLUSTERLISTING_SUCCESS = "FETCH_CLUSTERLISTING_SUCCESS";
export const FETCH_CLUSTERLISTING_FAIL = "FETCH_CLUSTERLISTING_FAIL";


export const FETCH_HISTORY_START = "FETCH_HISTORY_START";
export const FETCH_HISTORY_SUCCESS = 'FETCH_HISTORY_SUCCESS';
export const FETCH_HISTORY_FAIL = "FETCH_HISTORY_FAIL";

export const FETCH_PROPERTYLISTINGLOAD_START = 'FETCH_PROPERTYLISTINGLOAD_START';
export const FETCH_PROPERTYLISTINGLOAD_SUCCESS = 'FETCH_PROPERTYLISTINGLOAD_SUCCESS';
export const FETCH_PROPERTYLISTINGLOAD_FAIL = 'FETCH_PROPERTYLISTINGLOAD_FAIL';


export const FETCH_PROPERTYBULK_START = 'FETCH_PROPERTYBULK_START';
export const FETCH_PROPERTYBULK_SUCCESS = 'FETCH_PROPERTYBULK_SUCCESS';
export const FETCH_PROPERTYBULK_FAIL = 'FETCH_PROPERTYBULK_FAIL';

export const FETCH_INSPECTOR_START = "FETCH_INSPECTOR_START";
export const FETCH_INSPECTOR_SUCCESS = "FETCH_INSPECTOR_SUCCESS";
export const FETCH_INSPECTOR_FAIL = "FETCH_INSPECTOR_FAIL";
export const FETCH_CLUSTERBULK_START = 'FETCH_CLUSTERBULK_START';
export const FETCH_CLUSTERBULK_SUCCESS = 'FETCH_CLUSTERBULK_SUCCESS';
export const FETCH_CLUSTERBULK_FAIL = 'FETCH_CLUSTERBULK_FAIL';

export const FETCH_ZONEANDWARD_SUCCESS ="FETCH_ZONEANDWARD_SUCCESS"

export const FETCH_LOADSBULK_START = "FETCH_LOADSBULK_START";
export const FETCH_LOADSBULK_SUCCESS = "FETCH_LOADSBULK_SUCCESS";
export const FETCH_LOADSBULK_FAIL = "FETCH_LOADSBULK_FAIL";

export const FETCH_MATRIX_START = "FETCH_MATRIX_START"
export const FETCH_MATRIX_SUCCESS = "FETCH_MATRIX_SUCCESS"
export const FETCH_MATRIX_FAIL = "FETCH_MATRIX_FAIL"

export const FETCH_REGIONAL_FAIL = "FETCH_REGIONAL_FAIL"
export const FETCH_REGIONAL_START = "FETCH_REGIONAL_START"
export const FETCH_REGIONAL_SUCCESS= "FETCH_REGIONAL_SUCCESS"
