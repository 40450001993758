import React from 'react';
import { connect } from 'react-redux';
import WardForm from './WardForm';
import * as actionsCreators from  '../../store/actions/index';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import axios from '../../http-config';

function WardAdd(props) {
   
   const handleSubmit = (formdata) => {
       props.onAddWard(formdata,props)
   }
        return(
        <div className="create_ulb">
            <div className="dashboard_top_path">
                <p><img src="images/left-arrow.svg" /> Ward Management / <span>Create Ward</span></p>
            </div>
            <div className="dashboard_right_content">
                <img className="dashboard_right_content_top_icon" src="images/Rectangle.svg" />
                <div className="dashboard_right_content_top">
                    <h5>Create Ward</h5>
                </div>
                <div className="create_ulb_content_outer">
                    <div className="create_ulb_content">
                        
                    <WardForm handleSubmit={handleSubmit}/>
                       
                    </div>
                </div>
            </div>
        </div>
        )
 }
 const mapStateToProps = (state,props) => {
    return {
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onAddWard: (formdata,props) => dispatch(actionsCreators.addWard(formdata,props)),
    }
}
export default  connect(mapStateToProps, mapDispatchToProps)(WardAdd);

