import * as actionTypes from '../../actions/actionsTypes'
import { updateObject } from '../../utility'

export const initialState = {
    roleData : [],
    loading  : false,
    error    : null,
    totalCount: 0
}

export const fetchRoleListingStart = (state, action) => {
    return updateObject(state, {
        loading  : action.loading
    })
}

export const fetchRoleListingSuccess = (state, action) => {
    return updateObject(state, {
        roleData  : action.roleListing,
        loading   :  action.loading,
        totalCount:action.totalCount
    })
}

export const fetchRoleListingFail = (state, action) => {
    return updateObject(state, {
        error    : action.error,
        loading  :  action.loading
    })
}

export const fetchRoleListing = (state=initialState, action) => {
    switch(action.type){
        case actionTypes.FETCH_ROLE_DATA_START    : return fetchRoleListingStart(state, action)
        case actionTypes.FETCH_ROLE_DATA_SUCCESS  : return fetchRoleListingSuccess(state, action)
        case actionTypes.FETCH_ROLE_DATA_FAIL     : return fetchRoleListingFail(state, action)
        default                                   : return state
    }
}

export default fetchRoleListing