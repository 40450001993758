import React, { Component, useEffect } from 'react'
import { connect } from 'react-redux'
import AreaForm from './AreaForm'
import * as actionCreators from '../../store/actions/index'

const AreaAdd = (props) => {
    const handleSubmit = (payload) => {
        props.onAddArea(payload,props)
    }
    return (
        <div>
            <div className="create_ulb">
                <div className="dashboard_top_path">
                    <p><img src="images/left-arrow.svg" /> Area Management / <span>Add New Area</span></p>
                </div>
                <div className="dashboard_right_content">
                    <img className="dashboard_right_content_top_icon" src="images/Rectangle.svg" />
                    <div className="dashboard_right_content_top">
                        <h5>Add New Area</h5>
                    </div>
                    <div className="create_ulb_content_outer">
                        <div className="create_ulb_content">
                            {/* <AreaForm handleSubmit={handleSubmit}/> */}
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    )
    
}

const mapStateToProps = (state) => ({
    token : state.auth.token
})

const mapDispatchToProps = (dispatch) => {
    return({
        onAddArea : (payload,props) => dispatch(actionCreators.addArea(payload,props))
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(AreaAdd)