import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom'
import '../../../App.css'
import * as actionsCreators from  '../../../store/actions/index';
import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler';
import axios from '../../../http-config';
import _ from 'lodash'
import RecordKeeperForm from './RecordKeeperForm'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

export class RecordKeeperEdit extends Component {
    state = {
        singleLoad : []
    }
    componentDidMount(){
        const payload = {
            token         : this.props.token,
            ulbId            : this.props.match.params.id
        }
        this.props.onFetchHeaders(payload)
        const url = `/load/${payload.ulbId}`
        axios({
            method   : 'get',
            url      : url,
            headers  : {'Authorization': `Bearer ${payload.token}`}
       })
       .then((response) => {
           this.setState({singleLoad : response.data.data})
       })
       .catch((err) => {
           
       })
    }
    handleSubmit = (formdata)=>{
        this.props.onEditLoads(formdata,this.props)
    }
    render(){
        return(
            <div>
                <div className="create_ulb">
                    <div className="dashboard_top_path">
                        <p><img src="images/left-arrow.svg" /> Record Keeper Management / <span>Edit Record Keeper</span></p>
                    </div>
                    <div className="dashboard_right_content">
                        <img className="dashboard_right_content_top_icon" src="images/Rectangle.svg" />
                        <div className="dashboard_right_content_top">
                            <h5><Link  to={{
                                   pathname: '/records/all',
                                   state: {
                                     page : this.props?.location?.state?.pageR,
                                     limit: this.props?.location?.state?.limitR,
                                     sort :  this.props?.location?.state?.sortR,
                                   filter :  this.props?.location?.state?.filterR,
                                   query : this.props?.location?.state?.queryR,
                                   ulbId :  this.props?.location?.state?.ulbIdR,
                                   fromDate: this.props?.location?.state?.fromDateR,
                                   toDate: this.props?.location?.state?.toDateR,
                                   operatorId:this.props?.location?.state?.operatorIdR,
                                   }
                            }} className="email_link"><ArrowBackIosIcon></ArrowBackIosIcon></Link>Edit Record Keeper</h5>
                        </div>
                    </div> 
                </div>
                
                { 
                    !_.isEmpty(this.state.singleLoad)&& !_.isEmpty(this.props.headers.headers) && (
                        <RecordKeeperForm {...this.state.singleLoad} headers = {this.props.headers} isEdit={true} handleSubmit={this.handleSubmit} pageDetails = {this.props} />
                    )
                }
            </div>
        )
    }
}

    

const mapStateToProps = (state,props) => {
    return {
        token                  : state.auth.token,
        headers                : state.headers,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onEditLoads         : (formdata,props, payload) => dispatch(actionsCreators.editLoads(formdata,props, payload)),
        onFetchHeaders      : (payload) => dispatch(actionsCreators.fetchHeaders(payload)),
    }
}

export default  connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(RecordKeeperEdit, axios));
