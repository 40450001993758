import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux'
import * as actionCreators from '../../store/actions/index'
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import swal from 'sweetalert'
 class OperatorDialog extends Component {
  constructor(props){
    super(props)
    this.state = {
      isPasswordShown:false,
      username:this.props.username,
      password:this.props.password,
      newPassword:'',
      emergency:false,
      passwordErr:'',
      usernameErr:'',
      selectedDate:new Date(),
      open:true,
      button : this.props.button
    }
  }
 

 componentDidMount=()=>{

 }

 handleClickOpen = () => {
   this.setState({
     open:true
   })
  };

 handleClose = () => {
  this.props.handleClose(false)
  };

  handleChange = (event) => {
    this.setState({[event.target.name]:event.target.value},() => {
      this.checkValidity()
    }) 
  }

  componentWillReceiveProps = (nextProps) => {
    this.setState({
      button:nextProps.button,
      username:this.props.username,
      newPassword:''
    })
  }

  checkValidity = () => {
    let isError = false
    if(!this.state.username){
        isError=true
        this.setState({usernameErr:'*This field is required'})
    }else{
      isError=false
        this.setState({usernameErr:''})
    }
    if(!this.state.newPassword){
      isError=true
      this.setState({passwordErr:'*This field is required'})
    }else if(!this.state.newPassword.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/)){
      isError=true
      this.setState({passwordErr:'Password must contain minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character'})
    }else{
      isError=false
        this.setState({passwordErr:''})
    }
    return isError
}

  handleSumit = async (e) => {
    e.preventDefault()
    let payload = {
      "operatorId": this.props.operatorId,
      "userName": this.state.username,
      "password": this.state.newPassword ? this.state.newPassword : this.state.password,
      "email":this.props.email?this.props.email :'',
      "token":this.props.token
    }
    let isFormValid = this.checkValidity()
    if(!isFormValid){
      if(this.state.button == 'Save'){
        const response = await this.props.createOperatorAccount(payload,this.props)
        if(response){
          this.props.handleClose(false,true)
        }
      }else{
        const response = await this.props.editOperatorUser(payload,this.props)
        if(response){
          this.props.handleClose(false,true)
          this.setState({
            newPassword:''
          })
        }
      }
      
    }

  }

  toggleVisibility=()=>{
    this.setState({isPasswordShown:!this.state.isPasswordShown})
  }

render(){
    return (
    <>
      <Dialog open={this.props.open} onClose={this.handleClose} aria-labelledby="form-dialog-title"
      maxWidth={'xs'}
      fullWidth={true} >
        <DialogContent>
        <h2 style={{textAlign:"center", color:"#4CAF50", marginBottom:"5%",fontSize:'18px'}}>Operator Account</h2>
        <div className="create_ulb_content_outer">
          <div className="create_ulb_content">
            <Grid container spacing ={1}>
              <Grid item xs={12}>
                  <FormControl>
                    <label className="required" htmlFor = "username">User Name</label>
                        <TextField id = "username" 
                        variant="outlined" 
                        placeholder="Enter Username" 
                        name="username" 
                        value={this.state.username} 
                        onChange={this.handleChange}/>
                        <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.usernameErr}</div>
                  </FormControl>
              </Grid>
              <Grid item xs={12}>
                <form autoComplete="off">
                  <FormControl>
                  <label className='required' htmlFor = "password">Password</label>
                  <OutlinedInput id = "password" 
                    variant="outlined" 
                    placeholder="Enter Password" 
                    name="newPassword" 
                    value={this.state.newPassword}
                    onChange={this.handleChange}
                    type={this.state.isPasswordShown ? "text" : "password"}
                    endAdornment={this.state.isPasswordShown ?
                    (<InputAdornment position="end"><VisibilityOffOutlinedIcon 
                    onClick={()=>{this.toggleVisibility()}}/></InputAdornment>) :
                    (<InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                          onClick={()=>{this.toggleVisibility()}}>
                            <img src="images/eye.svg" />
                      </IconButton> 
                    </InputAdornment> )    
                      }
                      errortext={this.state.passwordErr}/>         
                  <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.passwordErr}</div>
                  </FormControl>
                  </form>
              </Grid> 
            </Grid>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button style ={{color:"#4CAF50",textTransform: 'capitalize'}} variant="outlined" onClick={this.handleClose}>
          Cancel
        </Button>
        <Button style ={{color:"#ffffff",backgroundColor:"#4CAF50",textTransform:'capitalize'}} variant="contained" onClick={this.handleSumit}>
          {this.state.button}
        </Button>
      </DialogActions>
    </Dialog> 
    </>
  );
 }
 
}

const mapStateToProps = (state) => {
  return{
      token: state.auth.token,
  }
}

const mapDispatchToProps = (dispatch)=> {
  return{
    createOperatorAccount : (payload,props) => dispatch (actionCreators.createOperatorAccount(payload,props)),
    editOperatorUser : (payload,props) => dispatch(actionCreators.editOperatorUser(payload,props))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OperatorDialog)
