import React, { Component } from 'react'
import { connect } from 'react-redux';
import {Link, Switch} from 'react-router-dom'

import * as actionsCreators from  '../../store/actions/index';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import axios from '../../http-config';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment'
import MenuItem from '@material-ui/core/MenuItem';
import ScheduleDialog from './ScheduleDialog'
import Select from '@material-ui/core/Select';
import swal from 'sweetalert';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid, Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import ReactPaginate from 'react-paginate';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Fade from '@material-ui/core/Fade';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import clsx from 'clsx';

const ITEM_HEIGHT = 48;
const BootstrapInput = withStyles((theme) => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor:"#fff",
      border: '1px solid #4CAF50',
      fontSize: 15,
      padding: '7px 26px 7px 20px',
      transition: theme.transitions.create(['border-color']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        'Open Sans',
        'sans-serif',
   
      ].join(','),
      '&:focus ': {
        borderRadius: 4,
            backgroundColor:"#fff !important"
   
      }
    },
  }))(InputBase);

  const useStyles = makeStyles({
    root: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    icon: {
      borderRadius: '50%',
      width: 16,
      height: 16,
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#f5f8fa',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
      },
      'input:hover ~ &': {
        backgroundColor: '#ebf1f5',
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
      },
    },
    checkedIcon: {
      backgroundColor: '#3dab35',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
      },
      'input:hover ~ &': {
        backgroundColor: '#3dab35',
      },
    },
  });
  
  // Inspired by blueprintjs
  function StyledRadio(props) {
    const classes = useStyles();
  
    return (
      <Radio
        className={classes.root}
        disableRipple
        color="default"
        checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
        icon={<span className={classes.icon} />}
        {...props}
      />
    );
  }

class ScheduleProperty extends Component {
    
    state={ 
        open:false,
        openDialog:false,
        propertyId:'',
        filterBy:'-1',
        filterByType:"",
        propertyType : 'all',
        isAll:false,
        filterByWard:"",
        controls: {
            search: {
                value: '',
            }
        },
        activePage:0,
        limit:10,
        page:1,
        openFreq:false,
        FrequencyNum:'',
        anchorE:null,
        anchorEl:null,
        propUpdateFreqdaysErr:'',
        propertyIdFreq:'',
        sortOrder: '',
        currentSortId:'',
        isFilter:false,
        sortObj : {
            key: '',
            value: ''
        },
        EditDateTime:"",
        scheduleId:'',
        filterValue :'',
        editEmergency:'',
        LastDesludgedDate:'',
        WeekDay:this.props.configurableDay,
        dayChanged: false,
        id:'',
        freqStatus:'',
        selectEditOperatorName :'',
        freqDisableStatus : null,
        freqSelect:'custom',
        hideDaysField:false,
        message:'',
        selecedFreq : '',
        customFreqValue:'',
        page2ScheduleFilter : 1,

        propertyDate:'',
        propertyScheduleId:'',
        EmergencyData:'',
        ProPropUpdateFreqdays:'',
        lastDesludgeDate:'',
        collectStatus:'',
        filterValueStatus:'',
        requiredZone:localStorage.getItem('isZoneNotRequired'),
        summaryStatus:!!this.props&&this.props.match.params.id?this.props.match.params.id:'',
        summaryfromDate: moment(!!this.props&&this.props.history.location.fromDate).format('YYYY-MM-DD'),
        summarytoDate: moment(!!this.props&&this.props.history.location.toDate).format('YYYY-MM-DD'),
        summaryhistoryData : !!this.props&&this.props.location.url?this.props.location.url:'',
           
    }
    componentDidMount() {
        if(!!this.state.summaryStatus&&this.state.summaryStatus === 'refused' ||!!this.state.summaryStatus&&this.state.summaryStatus === 'expired'||!!this.state.summaryStatus&&this.state.summaryStatus === 'cancelled'){
            let payload1 ={
                token: this.props.token,
                page:  this.state.page,
                limit: this.state.limit,
                fromDateSumm:this.state.summaryfromDate,
                toDateSumm:this.state.summarytoDate,
                statusSumm:this.state.summaryStatus,
                summaryhistoryData: this.state.summaryhistoryData,
                sortName:this.state.sortObj.key,
                sortValue :this.state.sortObj.value,
                propertyTypeId: this.state.filterValue,
                searchQuery: this.state.controls.search.value,
                status: this.state.filterValueStatus
                
            }
            this.props.onPropertyType();
            this.props.onLoadDataSummmary(payload1)
            this.props.onFetchPropertyHeaders(payload1);
    
        }
        else{
            let payload = {
                token: this.props.token,
                page:  this.state.page,
                limit: this.state.limit,
                sortName:this.state.sortObj.key,
                sortValue :this.state.sortObj.value,
                propertyTypeId: this.state.filterValue,
                zoneId: this.state.filterBy,
                wardId:this.state.filterByWard,
                searchQuery: this.state.controls.search.value,
                status: this.state.filterValueStatus
            }
            let url = this.getUrl(this.state.propertyType)
            this.props.onFetchPropertyHeaders(payload);
            this.props.onFetchPropertyListing(payload,url,'schedule');
            this.props.onSchedulePropertyZone();
            this.props.onSchedulePropertyWard({zoneId:'-1',status: this.state.filterValueStatus })
            this.props.onPropertyType();
            this.props.onFetchOperatorDay(payload,url,'schedule');
        }
       
    }

    openScheduleDialog = (propertyId,dateTime, scheduleId,editEmergencyData, operatorName) => {
        this.setState({
            propertyId:propertyId,
            EditDateTime:dateTime,
            scheduleId:scheduleId,
            editEmergency: editEmergencyData,
            selectEditOperatorName: operatorName
        },()=>{
            this.openTest()
        })
    }
    openTest = () =>{
        this.setState({
            openDialog:true
        })
    }

    handleDialogClose = (value) => {
        this.setState({
            openDialog:value
        }) 
    }
     handleClickFreqOpen = (propertyId,frequency,status, DesludgedDate) => {
        if(localStorage.getItem("isRepeatCollection") === "true"){
            this.setState({freqDisableStatus: false, message:''})
        }
      else if(localStorage.getItem("isRepeatCollection") === "true" && status !== 'Collected'){
            this.setState({freqDisableStatus: true, message:'Kindly Schedule and Collect the property!'})
        }
        if(localStorage.getItem("isRepeatCollection") === "false"&&DesludgedDate !== null){
            this.setState({freqDisableStatus: false, message:''})
        }else if( localStorage.getItem("isRepeatCollection") === "false" &&DesludgedDate === null){

            this.setState({freqDisableStatus: true, message:'Kindly Schedule and desludged the property!'})
        }


      this.setState({ openFreq:true,propertyIdFreq:propertyId,FrequencyNum:frequency,customFreqValue:frequency, LastDesludgedDate:DesludgedDate,freqStatus: status}) ;
      
      };
    
       handleFreqClose = () => {
        this.setState({ openFreq:false}) ;
      };
    
    getUrl = (propertyType) => {
        let url = ''
        switch (propertyType) {
            case 'today':
                const date =  moment().add(0,'d').format('YYYY-MM-DD')
                 url =`/property/listProperties?&limit=${this.state.limit}&page=${this.state.page}&scheduled_type=scheduled&toDate=${date}&fromDate=${moment(new Date()).format('YYYY-MM-DD')}&forScheduleProperty=true`
                break; 
            case '60 Days':
                const date60 =  moment().add(60,'d').format('YYYY-MM-DD')
                url =`/property/listProperties?&limit=${this.state.limit}&page=${this.state.page}&scheduled_type=scheduled&toDate=${date60}&fromDate=${moment(new Date()).format('YYYY-MM-DD')}&forScheduleProperty=true`
                break;  
            case '90 Days':
                const date90 =  moment().add(90,'d').format('YYYY-MM-DD')
                url =`/property/listProperties?&limit=${this.state.limit}&page=${this.state.page}&scheduled_type=scheduled&toDate=${date90}&fromDate=${moment(new Date()).format('YYYY-MM-DD')}&forScheduleProperty=true`
                break;   
            case '120 Days':
                const date120 =  moment().add(120,'d').format('YYYY-MM-DD')
                url =`/property/listProperties?&limit=${this.state.limit}&page=${this.state.page}&scheduled_type=scheduled&toDate=${date120}&fromDate=${moment(new Date()).format('YYYY-MM-DD')}&forScheduleProperty=true`
                break;             
            default:
                url = `/property?limit=${this.state.limit}&page=${this.state.page}&forScheduleProperty=true`       
        }
        return url
    }

    handlePropertyType = (e) => {
        let url = ''
        this.setState({
            propertyType :e.target.value,
            activePage:0,
            page:1
        },() => {
            let payload = {
                token: this.props.token,
                page:  1,
                limit: this.state.limit,
                sortName:this.state.sortObj.key,
                sortValue :this.state.sortObj.value,
                propertyTypeId: this.state.filterValue,
                zoneId: this.state.filterBy,
                wardId:this.state.filterByWard,
                searchQuery: this.state.controls.search.value,
                status: this.state.filterValueStatus
            }
            url = this.getUrl(this.state.propertyType)
            this.props.onFetchPropertyListing(payload,url,'schedule') 
        })
        this.setState({filterBy:''})
        this.setState({filterByWard:''})
    }

    inputChangedHadler = (event, controlName) => {
        const updatedControls = {
            ...this.state.controls,
            [controlName]: {
                ...this.state.controls[controlName],
                value: event.target.value,
            }
        }
        this.setState({ controls: updatedControls });
        if( updatedControls.search.value === ''){
            let payload = {
                token: this.props.token,
                searchQuery:'',
                limit:this.state.limit,
                page:  this.state.page,
                activePage:this.state.activePage,
                zoneId: this.state.filterBy,
                wardId:this.state.filterByWard,
                status: this.state.filterValueStatus
            }
         let url = this.getUrl(this.state.propertyType)
            this.props.onSearchProperty(payload,url,'schedule') 
        }
        this.setState({filterBy:''})
        this.setState({filterByWard:''})
     
   
    }
    handleDayChange= (e) => {
        this.setState ({
            WeekDay: e.target.value,
            dayChanged:true
        },()=>
        this.onClickExport());
      };

    onClickExport=(e)=>{
        
        let payload = {           
            token: this.props.token,
            "configurableDay":this.state.WeekDay          
        }
        this.props.onAddDayData(payload);
    };

    refreshPage() {
        window.location.reload(false);
      };

    handlePropertySearch = (e) => {
        e.preventDefault()
        let searchVal = this.state.controls.search.value
        if (!!searchVal && searchVal.length >= 2) {
            let payload = {
                token: this.props.token,
                searchQuery:searchVal,
                limit:10,
                page:  this.state.page,
                activePage:this.state.activePage,
                zoneId: this.state.filterBy,
                wardId:this.state.filterByWard,
                status: this.state.filterValueStatus
            }
            let url = this.getUrl(this.state.propertyType)
            this.props.onSearchProperty(payload,url,'schedule') 
        } else {
         
            swal("Enter atleast 2 characters")
        }
    }

    handleChange = (e ) => {
        this.setState({filterBy:e.target.value})
        let payload = {
            zoneId:e.target.value,
            page:this.state.page,
            limit:this.state.limit,
            status: this.state.filterValueStatus
        }
        this.props.onSchedulePropertyWard(payload);
        this.props.onFilterZoneAndWard(payload);
      
        if(e.target.value === ""){
            this.state.filterByWard = ""
        }
    }
    handleChangeward =(e)=>{
        e.preventDefault();
        this.setState({filterByWard:e.target.value})
        let payload ={
            zoneId: this.state.filterBy,
            wardId:e.target.value,
            page:this.state.page,
            limit:this.state.limit,
            token: this.props.token,
            sortName:this.state.sortObj.key,
           sortValue :this.state.sortObj.value,
           propertyTypeId: this.state.filterValue,
           searchQuery: this.state.controls.search.value,
           status: this.state.filterValueStatus
        }
        this.props.onFilterZoneAndWard(payload);
    }

    handleChangeFreq = (event) =>{
        this.setState({FrequencyNum:event.target.value},() => {
                if(!this.state.FrequencyNum){
                    this.setState({
                        propUpdateFreqdaysErr:'This field is required' 
                    })
                }else if(!this.state.FrequencyNum.match(/^[0-9]*$/)){
                    this.setState({
                        propUpdateFreqdaysErr:'Please enter valid number' 
                    })
                }else{
                    this.setState({
                        propUpdateFreqdaysErr:'' 
                    }) 
                }
        })
    }

    handleClickMoreVertIcon=(event,propertyId, propertyDate, propertyScheduleId,EmergencyData, ProPropUpdateFreqdays, lastDesludgeDate, collectStatus )=>{
        this.setState({open:!this.state.open,anchorEl:event.currentTarget,propertyId : propertyId, propertyDate:propertyDate, propertyScheduleId:propertyScheduleId, EmergencyData, ProPropUpdateFreqdays, lastDesludgeDate, collectStatus})
      }
    handleFreqSubmit =(e)=>{
        e.preventDefault()
        let payload={
            token: this.props.token,
            propertyId:this.state.propertyIdFreq,
            propUpdateFreqdays:this.state.FrequencyNum,
            propertyTypeId: this.state.filterValue,
            sortName:this.state.sortObj.key,
            sortValue :this.state.sortObj.value,
            zoneId: this.state.filterBy,
            wardId:this.state.filterByWard,
            selecedFreq : this.state.selecedFreq,
        }
        if(!this.state.FrequencyNum){
            this.setState({
                propUpdateFreqdaysErr:'This field is required' 
            })
        }
        if(this.state.propUpdateFreqdaysErr == '' && this.state.FrequencyNum){
            this.props.onAddFreqSchedule(payload)
            this.setState({ openFreq:false,FrequencyNum:'',propUpdateFreqdaysErr:'',activePage:this.state.activePage,}) 
            let payload1 = {
                token: this.props.token,
            }
        }
        
    }

    handleClickAwayEditPopUp = () => {
        this.setState({open:false});
        };
        handleClickAwayEdit = () => {
            this.setState({open:false});
        };
    handlePageClick = (e) => {
        if(!!this.state.summaryStatus&&this.state.summaryStatus === 'refused' ||!!this.state.summaryStatus&&this.state.summaryStatus === 'expired'||!!this.state.summaryStatus&&this.state.summaryStatus === 'cancelled'){
            this.setState({
                page:e.selected + 1,
                activePage : e.selected
            },() => {
            let payload1 ={
                token: this.props.token,
                page:e.selected + 1,
                limit: this.state.limit,
                fromDateSumm:this.state.summaryfromDate,
                toDateSumm:this.state.summarytoDate,
                statusSumm:this.state.summaryStatus,
                status: this.state.filterValueStatus,
                summaryhistoryData: this.state.summaryhistoryData,
                sortName:this.state.sortObj.key,
                sortValue :this.state.sortObj.value,
                propertyTypeId: this.state.filterValue,
            }
            this.props.onLoadDataSummmary(payload1)
        })
        }
        else{
            this.setState({
                page:e.selected + 1,
                activePage : e.selected
            },() => {
                let payload = {
                    token: this.props.token,
                    sortName:this.state.sortObj.key,
                   sortValue :this.state.sortObj.value,
                   propertyTypeId: this.state.filterValue,
                   zoneId: this.state.filterBy,
                   limit:this.state.limit,
                   page:e.selected + 1,
                   wardId:this.state.filterByWard,
                   searchQuery: this.state.controls.search.value,
                   status: this.state.filterValueStatus
                }
                let url = this.getUrl(this.state.propertyType)
                this.props.onFetchPropertyListing(payload,url,'schedule') 
            }) 
        }

          
        
    
    };   
    handleSort = (e,sortBy) => {
               let sortObj
               if (this.state.sortObj.key === sortBy.accessor) {
                   let value = (this.state.sortObj.value === 'DESC') ? 'ASC' : 'DESC';
                   sortObj = {
                       key : sortBy.accessor,
                       value: value
                   }
               } 
               else {
                   sortObj = {
                       key : sortBy.accessor,
                       value: 'DESC'
                }    
                }
        
               this.setState({ sortObj: sortObj ,currentSortId:sortBy.accessor});
               if(!!this.state.summaryStatus&&this.state.summaryStatus === 'refused' ||!!this.state.summaryStatus&&this.state.summaryStatus === 'expired'||!!this.state.summaryStatus&&this.state.summaryStatus === 'cancelled'){
                let payload1 ={
                    token: this.props.token,
                    page: this.state.page,
                    limit: this.state.limit,
                    fromDateSumm:this.state.summaryfromDate,
                    toDateSumm:this.state.summarytoDate,
                    statusSumm:this.state.summaryStatus,
                    status: this.state.filterValueStatus,
                    propertyTypeId: this.state.filterValue,
                    sortName: sortObj.key,
                    sortValue : sortObj.value,
                    summaryhistoryData: this.state.summaryhistoryData
                }
                this.props.onLoadDataSummmary(payload1)
               }
               else{
               let payload = {
                   token: this.props.token,
                   sortName:sortObj.key,
                sortValue : sortObj.value,
                propertyTypeId: this.state.filterValue,
                zoneId: this.state.filterBy,
                limit:this.state.limit,
                activePage:this.state.activePage,
                page: this.state.page,
                wardId:this.state.filterByWard,
                status: this.state.filterValueStatus
               }
           let url = this.getUrl(this.state.propertyType)
       this.props.onFetchPropertyListing(payload,url,'schedule');
            }
         
             }

        onClickGetFilterVals = (e, colObj) => {

            if(!!this.state.summaryStatus&&this.state.summaryStatus === 'refused' ||!!this.state.summaryStatus&&this.state.summaryStatus === 'expired'||!!this.state.summaryStatus&&this.state.summaryStatus === 'cancelled'){
                let payload1 ={
                    token: this.props.token,
                    page: this.state.page,
                    limit: this.state.limit,
                    fromDateSumm:this.state.summaryfromDate,
                    toDateSumm:this.state.summarytoDate,
                    statusSumm:this.state.summaryStatus,
                    status: this.state.filterValueStatus,
                    propertyTypeId: e,
                    sortName:this.state.sortObj.key,
                    sortValue :this.state.sortObj.value,
                    summaryhistoryData: this.state.summaryhistoryData
                }
                this.props.onLoadDataSummmary(payload1)
            }
            else{
                let payload = {
                    token: this.props.token,
                    page:  this.state.page,
                    limit: this.state.limit,
                    sortName:this.state.sortObj.key,
                    sortValue :this.state.sortObj.value,
                    activePage:this.state.activePage,
                    propertyTypeId: e,
                    zoneId: this.state.filterBy,
                    wardId:this.state.filterByWard,
                    status: this.state.filterValueStatus
                }
                this.setState({filterValue: e})
                let url = this.getUrl(this.state.propertyType)
                this.props.onFetchPropertyListing(payload,url,'schedule');
            }
     

        }   
        handleDelete =(id)=>{
            let payload = {
                scheduleId: id,
                propertyTypeId: this.state.filterValue,
                token: this.props.token,
                page:  this.state.page,
                activePage:this.state.activePage,
                limit: this.state.limit,
                sortName:this.state.sortObj.key,
                sortValue :this.state.sortObj.value,
                zoneId: this.state.filterBy,
                wardId:this.state.filterByWard
            }
            this.props.onDeleteScheduleProperty(payload)

        }  


        handleChangefreqSelect = (e)=>{
            if(e.target.value === 'custom'&& localStorage.getItem("isRepeatCollection") === 'false' &&this.state.LastDesludgedDate === null){
                this.setState({freqDisableStatus: true, selecedFreq:e.target.value, FrequencyNum: this.state.customFreqValue})
            }else if(e.target.value === 'custom'&& localStorage.getItem("isRepeatCollection") === "false" &&this.state.LastDesludgedDate !== null){
                this.setState({freqDisableStatus: false, selecedFreq:e.target.value,FrequencyNum: this.state.customFreqValue })
            }


            if(e.target.value === 'custom'&&localStorage.getItem("isRepeatCollection") === "true"){
                this.setState({freqDisableStatus: false, message:'', selecedFreq:e.target.value, FrequencyNum: this.state.customFreqValue})
            }
            else if(e.target.value === 'custom'&&localStorage.getItem("isRepeatCollection") === "true" && this.state.freqStatus !== 'Collected'){
                this.setState({freqDisableStatus: true, selecedFreq:e.target.value, FrequencyNum: this.state.customFreqValue})
            }
            if(e.target.value === 'daily'){

                this.setState({ freqDisableStatus: true, selecedFreq:e.target.value, FrequencyNum:1})
            }
            if(e.target.value === 'alternate'){
            this.setState({freqDisableStatus: true, selecedFreq:e.target.value, FrequencyNum:2})
            }
        
        }

        handleZoneChange=(e)=>{
            this.setState({requiredZone: e.target.value},()=>{
                let payload = {
                    token: this.props.token,
                    "isZoneNotRequired": this.state.requiredZone
                }
                this.props.oNisZoneRequired(payload)
            })

        }

        onClickGetFilterValsStatus=(e )=>{
            this.setState({filterValueStatus: e})
            if(!!this.state.summaryStatus&&this.state.summaryStatus === 'refused' ||!!this.state.summaryStatus&&this.state.summaryStatus === 'expired'||!!this.state.summaryStatus&&this.state.summaryStatus === 'cancelled'){
                let payload1 ={
                    token: this.props.token,
                    page:  this.state.page,
                    limit: this.state.limit,
                    fromDateSumm:this.state.summaryfromDate,
                    toDateSumm:this.state.summarytoDate,
                    statusSumm:this.state.summaryStatus,
                    summaryhistoryData: this.state.summaryhistoryData,
                    status: e,
                    sortName:this.state.sortObj.key,
                    sortValue :this.state.sortObj.value,
                    propertyTypeId: this.state.filterValue,
                    searchQuery: this.state.controls.search.value,
                    
                }
                this.props.onLoadDataSummmary(payload1)
             
            }
            else{
            let payload = {
                token: this.props.token,
                page:  this.state.page,
                limit: this.state.limit,
                sortName:this.state.sortObj.key,
                sortValue :this.state.sortObj.value,
                propertyTypeId: this.state.filterValue,
                zoneId: this.state.filterBy,
                wardId:this.state.filterByWard,
                searchQuery: this.state.controls.search.value,
                status: e
            }
            let url = this.getUrl(this.state.propertyType)
            this.props.onFetchPropertyListing(payload,url,'schedule');
        }
        }

        handleExport = () => {
            const payload ={
                token: this.props.token,
                page:  this.state.page,
                limit: this.state.limit,
                fromDateSumm:this.state.summaryfromDate,
                toDateSumm:this.state.summarytoDate,
                statusSumm:this.state.summaryStatus,
                sortName:this.state.sortObj.key,
                sortValue :this.state.sortObj.value,
                propertyTypeId: this.state.filterValue,
                searchQuery: this.state.controls.search.value,
                status: this.state.filterValueStatus
            }
                this.props.onExportStatus(payload)
        } 
        onClickRemoveFilter = ()=>{
            let payload = {
                token: this.props.token,
                page: 1,
                limit: this.state.limit,
                sortName:'',
                sortValue :'',
                propertyTypeId: '',
                searchQuery: '',
                status: '',
                zoneId:'',
                wardId: '',
            }
            this.props.onFetchPropertyListing(payload,'schedule');
            this.props.onSchedulePropertyZone();
            this.props.onSchedulePropertyWard({zoneId:'-1',status: this.state.filterValueStatus })
            this.setState({
                searchQuery:'',
                controls: {
                    search: {
                        value: '',
                    }
                },
                filterValue:'',
                sortObj : {
                    key: '',
                    value: ''
                 }, 
                 filterBy:'-1',
                 filterByWard:"",
                 propertyType :'',
                
                })
        }
    render() {
        let filterValue;
        let filterValueStatus;
        let data = Array.from(this.props.operatorDay);
        let updatedHeaders =!!this.props.headers&& this.props.headers.filter( (header) => {
            if(header.accessor == 'propertyType'){
                header.header = 'Property Type'
                header.filter = true
            }

            return (header.accessor == "id" ||header.accessor == "name" || header.accessor == 'zone' || header.accessor == 'ward' || header.accessor === 'ulb' || header.accessor === 'propertyType')
        })
        if(!!this.state.summaryStatus&&this.state.summaryStatus === 'expired' ){
            // ||!!this.state.summaryStatus&&this.state.summaryStatus === 'expired'||!!this.state.summaryStatus&&this.state.summaryStatus === 'cancelled'
            !!updatedHeaders&&updatedHeaders.push(
                {
                    accessor: "dateTime",
                    filter: "false",
                    header: "Scheduled Date",
                    sortable: "false"
                },
                {
                accessor: "expiredDate",
                filter: "false",
                header: "Expired Date",
                sortable: "false"
            })
        }
        if(!!this.state.summaryStatus&&this.state.summaryStatus === 'cancelled'){
            !!updatedHeaders&&updatedHeaders.push(
                {
                    accessor: "dateTime",
                    filter: "false",
                    header: "Scheduled Date",
                    sortable: "false"
                },
                {
                accessor: "cancelledDate",
                filter: "false",
                header: "Cancelled Date",
                sortable: "false"
            })
        }
        if(!!this.state.summaryStatus&&this.state.summaryStatus === 'refused'){
            // 
            !!updatedHeaders&&updatedHeaders.push(
                {
                    accessor: "dateTime",
                    filter: "false",
                    header: "Scheduled Date",
                    sortable: "false"
                },
                {
                accessor: "refusedDate",
                filter: "false",
                header: "Refused Date",
                sortable: "false"
            })
        }
        if(!!this.state.summaryStatus&&this.state.summaryStatus === 'all'){
            !!updatedHeaders&&updatedHeaders.push(
                {
                    accessor: "dateTime",
                    filter: "false",
                    header: "Scheduled Date",
                    sortable: "false"
                },
                {
                accessor: "collectedDate",
                filter: "false",
                header: "Collected Date",
                sortable: "false",
    
            })
        }

        !!updatedHeaders&&updatedHeaders.push(
        {
            header:"Last Desludged Date",
            accessor:"lastDesludgeDate",
            sortable:"false",
            filter:"true"
        },
     
        {
            header:"Status",
            accessor:"status",
            sortable:"false",
            filter:"true"
        },
        {
            header: "Schedule Frequency (In Days)", 
            accessor: "propUpdateFreqdays", 
            sortable: "false", 
            filter: "true"
        },
        {
            header: "Operator Name", 
            accessor: "operatorName", 
            sortable: "false", 
            filter: "false"
        },
      
        {
            header: "Action", 
            accessor: "actionButton", 
            sortable: "false", 
            filter: "true"
        })

        const headers = !!updatedHeaders&& updatedHeaders.map(header => {
            // if(this.state.summaryStatus === 'expired'|| this.state.summaryStatus === 'cancelled'|| this.state.summaryStatus === 'refused'){
            //     header.accessor ="sr.no" 
            //     // header.header = 'Sr. No'
            // }
            if(header.accessor == "status"){
                header.header = 'Status'
                header.filter = true
            }  
            if(header.accessor == "propertyType"){
                // header.header = 'Status'
                header.filter = true
            } 

            let filterType
            let StatusType
            if(header.filter == true&&header.accessor == "propertyType"){
                filterType = 
                <select 
                style={{
                    width: '16px',
                    borderColor:"white",
                    backgroundColor: 'LightGray',
                    outline:"none",
                    display: "flex",
                    alignItems: 'right',   
                  }}
                value={filterValue}
                onChange={(e) => {
                    this.onClickGetFilterVals(e.target.value , header)
                }}
                >
                    <option value="">All</option>
                    {!!this.props.propertyTypeList&&this.props.propertyTypeList.map((option, i) => {

                   return (   <option key={i} value={option.Id}>
                            {option.Name}
                        </option>)
                         })}
                </select>
            }

            if(header.filter == true &&header.accessor == "status" ){
                StatusType = 
            <select 
            style={{
                width: '16px',
                borderColor:"white",
                backgroundColor: 'LightGray',
                outline:"none",
                display: "flex",
                alignItems: 'right',   
              }}
            value={filterValueStatus}
            onChange={(e) => {
                this.onClickGetFilterValsStatus(e.target.value, header)
            }}
            >           <option value="">All</option>
                       <option value="scheduled">Scheduled</option>
                       <option value="collected">Collected</option>
                       <option value="expired">Expired</option>
                       <option value="refused">Refused</option>
                       <option value="cancelled">Cancelled</option>
                       <option value="disposed">Disposed</option>
                </select>
         
            }

          {
                return header.header == "Action" ? (<TableCell align='center'  key={header.header} className={"white-space"}>
                             <div style={{
                                    // display: 'flex',
                                    textAlign: "center",
                                    whiteSpace: 'break-spaces',
                                    height:'3em',
                                    // marginLeft:'135px'
                                    }}>
                                {header.header}
                            </div>
                        </TableCell>): (<TableCell key={header.header} className={"white-space"}>
                            
                             <div style={{
                                    display: 'flex',
                                    alignItems: 'start',
                                    whiteSpace: 'break-spaces',
                                    height:'3em',
                                    }}>
   
                        <div onClick={(e) =>  this.handleSort(e,header)}>
                            
                            {header.header == "Operator Name" || header.header == "Property Type"  ?'':    <div>{
                             this.state.currentSortId===header.accessor? this.state.sortObj.value ==='ASC'?(<ArrowDropDownIcon fontSize="small"/>):(<ArrowDropUpIcon fontSize="small"/>) :(<ArrowDropDownIcon fontSize="small"/>)
                           }
                           </div> }
                          
                       </div>
                       
          
                                {header.header}
                                {!!this.state.summaryStatus&&this.state.summaryStatus === 'refused' ||!!this.state.summaryStatus&&this.state.summaryStatus === 'expired'||!!this.state.summaryStatus&&this.state.summaryStatus === 'cancelled'? "": StatusType} 
                                {/* {StatusType} */}
                                {filterType} 
                            </div>
                        </TableCell>)
            }
        }
        );
        const properties=(!!this.state.summaryStatus&&this.state.summaryStatus === 'refused' ||!!this.state.summaryStatus&&this.state.summaryStatus === 'expired'||!!this.state.summaryStatus&&this.state.summaryStatus === 'cancelled'?!!this.props.propertyLoadListing&&this.props.propertyLoadListing.slice(this.state.activePage *this.state.limit, this.state.activePage * this.state.limit + this.state.limit): !!this.props.propertyListing&&this.props.propertyListing).map((property, j)=>{
        const propertyId=property["propertyId"]
        const propertyDate = property["dateTime"]
        const propertyScheduleId = property["scheduleId"]
        const EmergencyData = property['emergency']
        const propstatus = property['status']
        const lastDesludgeDate= property["lastDesludgeDate"]
            return <TableRow style={{whiteSpace: 'normal',
            wordWrap: 'break-word'}} key={j}>{
                !!updatedHeaders&&updatedHeaders.map((header, i)=>{
                       if(header.accessor=='actionButton'){
                        return <TableCell key={i} >
                                        <IconButton
                                            aria-label="more"
                                            aria-controls="long-menu"
                                            aria-haspopup="true"
                                            onClick={(e)=>this.handleClickMoreVertIcon(e,propertyId, propertyDate, propertyScheduleId,EmergencyData, property.propUpdateFreqdays,lastDesludgeDate, propstatus)}
                                            >
                                            <MoreVertIcon fontSize='small'/>
                                            </IconButton>
                                                <Popper
                                                open={this.state.open} anchorEl={this.state.anchorEl}>
                                                 <ClickAwayListener onClickAway={this.handleClickAwayEdit}>
                                                <Paper style={{boxShadow:"none", border:"1px solid #3dab35"}}>
                                                    <MenuList>
                                                    {this.state.propertyDate !== null&& this.state.collectStatus== 'Scheduled'?
                                                      <MenuItem style={{ color: '#3dab35' }} onClick={() => this.openScheduleDialog(this.state.propertyId, this.state.propertyDate, this.state.propertyScheduleId,this.state.EmergencyData )}>Edit Schedule</MenuItem>
                                                 :   <MenuItem style={{ color: '#3dab35' }} onClick={() => this.openScheduleDialog(this.state.propertyId)}>Add Schedule</MenuItem>
                                                    }
                                                     
                                                
                                                     <MenuItem style={{ color: '#3dab35' }} onClick={() => this.handleClickFreqOpen(this.state.propertyId,this.state.ProPropUpdateFreqdays,this.state.collectStatus, this.state.lastDesludgeDate)}>Set Frequency</MenuItem>
                                                     {!!this.state.summaryStatus&&this.state.summaryStatus === 'refused' ||!!this.state.summaryStatus&&this.state.summaryStatus === 'expired'||!!this.state.summaryStatus&&this.state.summaryStatus === 'cancelled'? "":    <Link to={`/schedule-property/${this.state.propertyId}` } className='email_link' style={{color:'black'}}> <MenuItem style={{ color: '#3dab35' }}  >View History</MenuItem> </Link> }
                                                     {
                                                         this.state.collectStatus== 'Scheduled'? 
                                                         <MenuItem  onClick = { () => {
                                                            swal({ title   : "Are you sure?",
                                                                text    : "Once deleted, you will not be able to recover this data!",
                                                                icon    : "warning",
                                                                buttons : {
                                                                cancel  : 'No',
                                                                confirm : 'Yes'}
                                                            })
                                                            .then((willDelete) => {
                                                                if (willDelete) {
                                                                    this.handleDelete(this.state.propertyScheduleId) 
                                                                } 
                                                            });
                                                        }}>Delete</MenuItem>:''
                                                     }
                                                    
                                                    </MenuList>
                                                </Paper>
                                                </ClickAwayListener>
                                            </Popper>
                           
                        </TableCell>
                       }
                    // added Link to Name field to go to View History page
                       else if (header.accessor== 'name'){
                        return<TableCell>
                        <Link to={`/schedule-property/${propertyId}`}  ><TableCell style={{
                     whiteSpace: "normal",
                     // wordWrap: "break-word",
                     border: "none"
                   }} key={i} >{property[header.accessor] ? property[header.accessor] :   property['propertyName']  }</TableCell> </Link>
                    </TableCell>
                       }
                       else if(header.accessor=='updatedAt'){
                           return <TableCell style={{
                            whiteSpace: "normal",
                            wordWrap: "break-word"
                          }} key={i} >{ moment(property[header.accessor]).format('DD-MMM-YYYY hh:mm a')}</TableCell>
                       }
                       else if(header.accessor == 'name' ){
                         if(property['ctptName'] != null ){
                            return <TableCell>{property['ctptName'] }</TableCell>
                        }
                        else{
                            return <TableCell>{property[header.accessor] ? property[header.accessor] :   property['propertyName']  }</TableCell>
                        }
                          
                       }
                   
                       else if(header.accessor == 'operatorName'){
                        return <TableCell>{property[header.accessor] ? property[header.accessor] : property['operatorName'] }</TableCell>
                    }

                       else if(header.accessor=='lastDesludgeDate'){
                        return <TableCell  style={{
                         whiteSpace: "normal",
                         wordWrap: "break-word"
                       }} key={i}>{ property[header.accessor] && moment(property[header.accessor]).format('DD-MMM-YYYY')}</TableCell>
                    }
                    else if(header.accessor == 'dateTime' ){
                        
                        // if(property['scheduledDate'] !== null){
                        //         return <TableCell>{property['scheduledDate']}</TableCell>
                            
                    //     }
                    // else{
                            return <TableCell  style={{
                                whiteSpace: "normal",
                                wordWrap: "break-word"
                            }} key={i}>{ property[header.accessor ] ? moment(property[header.accessor]).format('DD-MMM-YYYY') :property['scheduledDate']? property['scheduledDate']: '-'}</TableCell>
                        // }
                    
                       
                    }
                    else if(header.accessor === 'ScheduleFrequencyButton'){
                            return <TableCell>
                                <Button style={{border:'1px solid #4CAF50',color: '#4CAF50',textAlign: 'center',height:'35px',textTransform:'capitalize'}} type="submit" 
                                    onClick={() => this.handleClickFreqOpen(propertyId)}>
                                        Set Frequency</Button>
                            </TableCell>
                    }

                    else if(header.accessor == 'collectedDate'){
                     
                        return <TableCell  style={{
                            whiteSpace: "normal",
                            wordWrap: "break-word"
                          }} key={i}>{ property[header.accessor ] ? moment(property[header.accessor]).format('DD-MMM-YYYY') : '-'}</TableCell>
                    }
                    else if(header.accessor === 'propUpdateFreqdays'){
                        return<TableCell tyle={{
                            whiteSpace: "normal",
                            wordWrap: "break-word"
                          }} key={i}>
                            { !!property.propUpdateFreqdays&&property.propUpdateFreqdays ?property.propUpdateFreqdays : '-'  }
                        </TableCell>
                    }
                    else if(header.accessor == "id"){
                        if(property['sr.no'] != null ){
                            return <TableCell>{property['sr.no'] }</TableCell>
                        }
                        else{
                            return <TableCell>{property[header.accessor] ? property[header.accessor] :   property['id']  }</TableCell>
                        }
                    }
                    else{
                           return  <TableCell style={{
                            whiteSpace: "normal",
                            wordWrap: "break-word"
                          }} key={i} className={"white-space"}>{property[header.accessor] == null || property[header.accessor] == 'null' ? ' ' : property[header.accessor]}</TableCell>
                    }
                 
                }) 
            }
            </TableRow>

    })
 
        return (
            <div>
                <div className="dashboard_right_content" style={{marginTop:'-4%'}}>
                    <img className="dashboard_right_content_top_icon" src="images/Rectangle.svg" />
                        <div className="dashboard_right_content_top">
                            <h5> <Link to ={!!this.state.summaryhistoryData&&this.state.summaryhistoryData != ''? {pathname:'/property-History/allHisorty', fromREC:!! this.props.location&&this.props.location.fromDate, toDateREC:!! this.props.location&&this.props.location.toDate }:{pathname:"/schedule-property/allData", fromF: !! this.props.location&&this.props.location.fromDate, todateF:!!this.props.location&& this.props.location.toDate}}  className="email_link"><ArrowBackIosIcon></ArrowBackIosIcon></Link>{!!this.props&&this.props.match.params.id == 'refused'? 'Refused Properties': !!this.props&&this.props.match.params.id == 'expired'? 'Expired Properties': !!this.props&&this.props.match.params.id == 'cancelled'? 'Cancelled Properties': ' Schedule Properties' }</h5>
                            
                            {!!this.state.summaryStatus&&this.state.summaryStatus === 'refused' ||!!this.state.summaryStatus&&this.state.summaryStatus === 'expired'||!!this.state.summaryStatus&&this.state.summaryStatus === 'cancelled'? <div className="dashboard_right_content_top_options">
                            <Button style={{width:'100%'}} onClick={this.handleExport}>Export</Button>

                    </div>:
                            <div className="dashboard_right_content_top_options">
{/*       
                            <div >
                                
                                <FormControl style={{color: '#4CAF50',width:"225px", marginRight: "9px"}}>
                                    <label>Set Configurable Day</label>
                                {data.map((item)=>{
                                    return <NativeSelect
                                    value={this.state.Day}
                                    onChange={this.handleDayChange}
                                    input={<BootstrapInput  />}
                                    style={{color: '#4CAF50', marginTop:'5px'}}>
                                       {this.state.dayChanged==false?<option value=''>Selected Day: {item["ConfigurableDay"]}</option>:<option disabled>{"Select Configurable Day"}</option>}
                                        <option value="Monday" >Monday</option>
                                        <option value="Tuesday">Tuesday</option >
                                        <option value="Wednesday">Wednesday</option >
                                        <option value="Thursday">Thursday</option >
                                        <option value="Friday">Friday</option >
                                        <option value="Saturday">Saturday</option>
                                        <option  value="Sunday">Sunday</option >
                                    </NativeSelect>
                                 })}
                                </FormControl>
                            </div> */}


                            {/* <div >
                                <FormControl style={{color: '#4CAF50',width:"225px", marginRight: "9px"}}>
                                    <label>Is Zone not Required?</label>
        
                                     <NativeSelect
                                    value={this.state.requiredZone}
                                    onChange={this.handleZoneChange}
                                    input={<BootstrapInput  />}
                                    style={{color: '#4CAF50', marginTop:'5px'}}>
                                       {/* <option value={''} >Select</option> */}
                                      {/* <option value={false} >Yes</option>
                                        <option value={true}>No</option >
                                       
                                    </NativeSelect>
    
                                </FormControl>
                            </div>   */}


                            <div className="dashboard_right_content_top_search">
                                <Paper component="form">
                                    <IconButton aria-label="menu">
                                        <img src="images/search_icon.svg" />
                                    </IconButton>
                                    <InputBase onChange={(event) => this.inputChangedHadler(event, 'search')} placeholder="Property Name" />
                                    <Button onClick={this.handlePropertySearch} variant="contained" color="primary" type="submit">Search</Button>
                                </Paper>
                            </div>

                    <div style={{marginLeft:"10px", display:"flex"}}>
                       
                        <div>
                        <FormControl style={{color: '#4CAF50',width:"130px"}}>
                            <NativeSelect
                            value={this.state.filterBy}
                            onChange={this.handleChange}
                            input={<BootstrapInput  />}
                            style={{color: '#4CAF50'}}>
                                <option value=''>All Zones</option>
                                {!!this.props.zones&&this.props.zones.map((item)=>{
                                    if(!!item.Id && item.Id !== null && item.Name != "null"){
                                        return   <option value={item.Id}> {item.Name}</option>
                                    }
                                })}
                            </NativeSelect>
                        </FormControl>
                        </div>

                    <div>
                    <FormControl style={{color: '#4CAF50', width:"130px", marginLeft: '13px'}}>
                        
                            <NativeSelect
                            value={this.state.filterByWard}
                            onChange={this.handleChangeward}
                            // disabled={!!this.state.filterBy && this.state.filterBy.length >0 || this.state.filterBy === 0 && this.state.filterBy === null ? false :true}
                            input={<BootstrapInput  />}
                            style={{color: '#4CAF50',}}
                            >
                            <option  value="" >Select Ward</option>
                            {!!this.props.wards&&this.props.wards.map((item)=>{
                                   return  <option value={item.wardId}>{item.wardNumber}</option>
                               })}
                            
                           
                            </NativeSelect>
                        </FormControl>
                    </div>
                 
                        <div>
                        <FormControl style={{color: '#4CAF50',width:"fit-content", marginLeft: '13px'}}>
                         
                            <NativeSelect
                            value={this.state.propertyType}
                            onChange={this.handlePropertyType}
                            input={<BootstrapInput  />}
                            style={{color: '#4CAF50', fontFamily:'Open Sans, sans-serif'}}
                            >
                            <option value='all'>All</option>
                            <option value='today'>Scheduled Today </option>
                            <option value='60 Days'> Scheduled within 60 Days</option>
                            <option value='90 Days'> Scheduled within 90 Days</option>
                            <option value='120 Days'> Scheduled within 120 Days</option>
                            </NativeSelect>
                        </FormControl>
                        </div>
                        <div >
                                <Button style={{marginLeft: '13px'}} onClick={this.onClickRemoveFilter}>Reset</Button> 
                                </div>
                        {/* <div>
                            <Link to={`./property-summary`} className='email_link'> <Button style={{
                                background:'none',
                                color:'#3dab35',
                                marginLeft: '18px'
                            }} color="primary" type="submit" >Report</Button></Link>
                        </div> */}
                                               
                        </div>
                        </div>}
                        </div>
                    </div>
                    <div> 
                    {(this.props.loadingData ==false) ? <div id="cover-spin"></div>:''}
                    
                    <div className="operator_listing scrollScheduleProperty" >
                        
                    {properties.length === 0 && this.props.loadingData ==true ? <TableRow style={{textAlign:'center', fontWeight:'bold', fontSize: '13px', }}>
                                            No Records Found !!</TableRow>:
                        <TableContainer component={Paper} style={{paddingLeft:"15px",paddingRight:"15px"}}>
                             <Table stickyHeader aria-label="sticky table" style={{ tableLayout: 'fixed' }}>
                             <colgroup>
                                 <col style={{width:'3vw', padding:"0"}}/>
+                                <col style={{width:'7vw', padding:"0"}}/>
+                                <col style={{width:'6vw', padding:"0"}}/>
+                                <col style={{width:'8vw', padding:"0"}}/>
+                                <col style={{width:'6vw', padding:"0"}}/>
+                                <col style={{width:'5vw', padding:"0"}}/>
+                                <col style={{width:'7vw', padding:"0"}}/>
+                                <col style={{width:'7vw', padding:"0"}}/>
+                                <col style={{width:'7vw', padding:"0"}}/>
+                                <col style={{width:'6vw', padding:"0"}}/>
                                <col style={{width:'8vw', padding:"0"}}/>
+                                <col style={{width:'6vw', padding:"0"}}/>
+                                <col style={{width:'4vw', padding:"0"}}/>
+                                </colgroup>
                                <TableHead >
                                    <TableRow >
                                        {headers}
                                    </TableRow>
                                </TableHead>
                                <TableBody>

                                        {properties}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        }
                    </div>
                    </div>
                    {
                this.props.totalCount && this.props.totalCount != 0 && 
                    <ReactPaginate
                    previousLabel={"Prev"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    forcePage = {this.state.activePage}
                    pageCount = {this.props.totalCount/this.state.limit}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    style={{borderLeftWidth:'0px'}}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}/>
                }
                    <ScheduleDialog selectEditOperatorName={this.state.selectEditOperatorName} editEmergency={this.state.editEmergency} propertyTypeId={this.state.filterValue} dataEdit={this.state.EditDateTime} scheduleIdEdit={this.state.scheduleId} pageSchedule={this.state.page}
                     activeSchedule={this.state.activePage} SORT={this.state.sortObj} open={this.state.openDialog} uRl= { this.getUrl(this.state.propertyType)} urlPropertyType={this.state.propertyType} handleClose = {this.handleDialogClose} propertyId = {this.state.propertyId} summaryStatus={this.state.summaryStatus}  fromDateSumm={this.state.summaryfromDate}
                     toDateSumm={this.state.summarytoDate} setFreqPop={this.state.ProPropUpdateFreqdays}></ScheduleDialog>
      <div className="setFreq">
      <Dialog
        maxWidth={'xs'}
        fullWidth={true} 
        open={this.state.openFreq}
        aria-labelledby="responsive-dialog-title">
        <DialogTitle className="text-center border-none " style={{color:'#3dab35'}}><span style={{fontSize:'18px'}}>Schedule Frequency</span></DialogTitle>
        <DialogContent > 
            <div className="create_ulb_content_outer">
                <div className="create_ulb_content_sched">
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                        <FormControl component="fieldset" className="login_options">
                        <RadioGroup defaultValue={this.state.freqSelect} aria-label="freqSelect" name="freqSelect" onChange ={(e)=>this.handleChangefreqSelect(e)}>
                         <FormControlLabel value="daily" control={<StyledRadio />} label="Daily" />
                                <FormControlLabel value="alternate" control={<StyledRadio />} label="Alternate" />
                                <FormControlLabel value="custom" control={<StyledRadio />} label="Custom" />
                                </RadioGroup>
                        </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl>
                                <label className="required" style={{fontWeight:'bold'}} htmlFor="FrequencyNo" >Days</label>
                                <TextField id="areaName"  
                                    name="areaName"
                                    variant = "outlined"
                                    style={{border:'none',marginTop:'2px'}} 
                                    value={this.state.FrequencyNum} 
                                    disabled={this.state.freqDisableStatus === true? this.state.freqDisableStatus: false}
                                    onChange={this.handleChangeFreq}/>
                               {this.state.freqDisableStatus === true ?<h5  style={{fontSize:15,color:"red",border:'none', fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}> {this.state.message}</h5> : 
                               <div style={{fontSize:12,color:"red",border:'none', fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{ this.state.propUpdateFreqdaysErr}</div>}
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
            </div>      
        </DialogContent>
        <DialogActions className="leftp">
            <Button autoFocus onClick={this.handleFreqClose} style={{
                background:'none',
                color:'#3dab35',
                border:" 1px solid rgba(61, 171, 53, 0.5)",
                }} 
                type="submit">Cancel
            </Button>
            <Button onClick={this.handleFreqSubmit} style={{
                background:'#3dab35',
                color:'white',
                border:" 1px solid rgba(61, 171, 53, 0.5)",
                }}  autoFocus
                type="submit"
                // disabled={this.state.LastDesludgedDate!=null&& this.state.selecedFreq == 'daily'?false:this.state.LastDesludgedDate!=null&& this.state.selecedFreq == 'alternate' ? false: this.state.freqDisableStatus}
                >Save
            </Button>
        </DialogActions>
    </Dialog>
    </div>
    </div>
    )
}
}
const mapStateToProps = state => {
    return {
        totalCount:state.propertyListing.totalCount,
        token: state.auth.token,
        headers:state.propertyHeaders.headers,
        propertyListing:!!state.propertyListing.propertyListing&&state.propertyListing.propertyListing,
        zones: state.propertyListing.zones,
        wards : state.propertyListing.wards,
        propertyTypeList: !!state.propertyListing.propertyTypeList? state.propertyListing.propertyTypeList :!!state.propertyListing.ListPropertyTypes ?state.propertyListing.ListPropertyTypes: [] ,
        operatorDay:!!state.operatorDay.operatorDay&&state.operatorDay.operatorDay,
        loadingData: state.propertyListing.loadingSuccess,
        propertyLoadListing: state.propertyListing.propertyListLoad,
        // totalCountLoads: state.propertyListing.totalCountLoads,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchPropertyHeaders: (payload) => dispatch(actionsCreators.fetchPropertyHeaders(payload)),
        onFetchPropertyListing: (payload,url,type) => dispatch(actionsCreators.fetchPropertyListing(payload,url,type)),
        onFetchPropertyBulkImport: (payload,formData,props) => dispatch(actionsCreators.fetchPropertyBulkImport(payload,formData,props)),
        onFetchAreaBulkImport: (payload,formData,props) => dispatch(actionsCreators.fetchAreaBulkImport(payload,formData,props)),
        onSchedulePropertyZone :()=> dispatch(actionsCreators.schedulePropertyZone()),
        onSchedulePropertyWard : (payload)=>dispatch(actionsCreators.schedulePropertyWard(payload)),
        onFilterZoneAndWard   : (payload)=> dispatch(actionsCreators.filterZoneAndWard(payload)),
        onSearchProperty:(payload,url,type)=>dispatch(actionsCreators.searchProperty(payload,url,type)),
        onAddFreqSchedule:(payload)=>dispatch(actionsCreators.addFreqSchedule(payload)),
        onPropertyType:()=>dispatch(actionsCreators.propertyType()),
        onAddDayData :(formdata,SubmitProp, props)=>dispatch(actionsCreators.addDayData(formdata,SubmitProp,props)),
        onFetchOperatorDay: (payload,url,type) => dispatch(actionsCreators.fetchOperatorDay(payload,url,type)),
        onDeleteScheduleProperty : (payload)=>dispatch(actionsCreators.deleteSchedule(payload)),
        oNisZoneRequired : (payload, props)=>dispatch(actionsCreators.isZoneRequired(payload, props)),
        onLoadDataSummmary:(payload)=>dispatch(actionsCreators.loadDataSummmary(payload)),
        onExportStatus : (payload) => dispatch(actionsCreators.exportStatus(payload))
    }
}

export default  connect(mapStateToProps, mapDispatchToProps)(ScheduleProperty);