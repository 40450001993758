import * as actionTypes from '../../actions/actionsTypes';
import { updateObject } from '../../utility';

const initialState = {
    headers: [],
    loading: false
}

const fetchTreatmentHeadersStart = (state, action) => {
    return updateObject(state, {
        loading: true,
    });
}

const fetchTreatmentHeadersSuccess = (state, action) => {
    return updateObject(state, {
        headers: action.headers,
        loading: false,
    });
}

const fetchTreatmentHeadersFail = (state, action) => {
    return updateObject(state, {
        loading: false,
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_TREATMENTHEADERS_START: return fetchTreatmentHeadersStart(state, action);
        case actionTypes.FETCH_TREATMENTHEADERS_SUCCESS: return fetchTreatmentHeadersSuccess(state, action);
        case actionTypes.FETCH_TREATMENTHEADERS_FAIL: return fetchTreatmentHeadersFail(state, action);
        default: return state;
    }
}

export default reducer;