import React,{Component} from 'react'
import axios from '../../http-config'
import { connect } from 'react-redux';
import * as actionsCreators from  '../../store/actions/index';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import {Doughnut,Line,Bar} from 'react-chartjs-2';
import BasicTable from './Table'
// import BasicTable from '../Dashboard/Table'
import Grid from '@material-ui/core/Grid';
import { Paper } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import EditDialog from './editDialog';
import swal from 'sweetalert';

class Chart extends Component{


  state = {
    filter : {
      'interval':'',
    },
    open:false,
    data:[],
    id:'',
    isDisabled:false,
    interval:'Current Day'
  }
  componentDidMount=()=>{
      // this.props.exports
      let payload = {
        token: this.props.token,
        filter: JSON.stringify([this.state.filter]),
        interval:this.state.interval

    }
      // this.props.onFetchDashboardData(payload)
    }
  
  handleEditDialog = () => {
    this.setState({
      open:true
    })
  } 
  
  handleClose = (close) => {
    this.setState({
      open:close
    })
  }

  getLables = (chartData) => {
    let labels = []
    if(chartData){
      chartData.map( item => {
        labels.push(item.ulbName)
      })
    }
    return labels.slice(0,10)
  }

  getData = (chartData) => {
    let data = []
    if(chartData){
      chartData.map( item => {
        data.push(item.noOfLoadsReceived)
      })   
    }
    return data.slice(0,10)
  }

  getUtilizationData = (chartData) => {
    let data = []
    if(chartData){
      chartData.map( item => {
        data.push(item.utilizationRateIn)
      })   
    }
    return data.slice(0,10)
  }

  handleSort = (e,column,sortOrder) => {
    const filter = `&sortColumnName=${column.id}&sortType=${sortOrder}`
    this.props.onFetchDashboardData(this.props.payloadExcal,filter)
  }

  render(){
    let dashboard_data = this.props.data.dashboard_data
    let chartData = this.props.data.dashboard_data
    let sortChart 
    // console.log(this.props.updownData,"this.props.payloadExcal.bottomValue");
    if(this.props.payloadExcal.category == 1) {
      sortChart = chartData&&chartData[0].table_data.body.sort(function(a, b){return  b.utilizationRateIn - a.utilizationRateIn})
    } else if(this.props.payloadExcal.category == '2'){
      sortChart = chartData&&chartData[0].table_data.body.sort(function(a, b){return  b.noOfLoadsReceived - a.noOfLoadsReceived})
    }
    if(this.props.updownData =="topValue" && this.props.payloadExcal.category == 1){
      sortChart = chartData&&chartData[0].table_data.body.sort(function(a, b){return  b.utilizationRateIn - a.utilizationRateIn})
    } else if(this.props.updownData =="topValue"&&this.props.payloadExcal.category == '2'){
      sortChart = chartData&&chartData[0].table_data.body.sort(function(a, b){return  b.noOfLoadsReceived - a.noOfLoadsReceived})
    }
    if(this.props.updownData == "bottomValue" && this.props.payloadExcal.category == 1){
      sortChart = chartData&&chartData[0].table_data.body.sort(function(a, b){return  a.utilizationRateIn - b.utilizationRateIn})
    } else if(this.props.updownData == "bottomValue" && this.props.payloadExcal.category == '2'){
      sortChart = chartData&&chartData[0].table_data.body.sort(function(a, b){return  a.noOfLoadsReceived - b.noOfLoadsReceived})
    }
    // else{
    //   sortChart = chartData&&chartData[0].table_data.body.sort(function(a, b){return  b.utilizationRateIn - a.utilizationRateIn})
    // }
    const labels = this.getLables(sortChart)
    const data = this.getData(sortChart)
    const utilizationdata = this.getUtilizationData(sortChart)
    let rows = []
    let columns = []
    rows = dashboard_data ? dashboard_data[0].table_data.body : []
    columns = dashboard_data ? dashboard_data[0].table_data.headers : []
    // const barData =  {
    //   labels: labels,
    //   datasets: [
    //     {
    //       label: 'Loads',
    //       data: data,
    //       backgroundColor: '#52AC36',
      
    //     }
    //   ],
    // }
    const LoadsBarData =  {
      labels: labels,
      datasets: [
     
        {
          label: 'Loads',
          data: data,
          borderColor: 'Blue',
          backgroundColor:"white",
         type:"line",
         yAxisID: "loadsData",
         borderWidth: 3,
         order: 1
         
        }
      ],
    }
    const LoadsOptions = {
      legend: {
        position: "top",
        align: "center",
        labels: {
          fontColor: 'black',
          fontFamily:'sans-serif',
          defaultFontSize:120,
      }
     },
     ticks: {
      autoSkip: false
  },
      scales: {
        yAxes: [
        {
          gridLines: {
            display: false
          },
          id: "loadsData",
          position: "left",
          // type: 'linear',
          display: true,
          ticks: {
            beginAtZero: true
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1]
        },
        stroke: {
          width: [0, 4]
        },
        scaleLabel: {
          boxWidth: 50,
          fontSize: 15,
          fontColor: '#3DAB35',
          padding: 10,
          display: true,
          labelString: 'Loads',
          fontStyle: "bold",
          opposite: true,

        },
        }
        ],

        xAxes: [{
          barPercentage: (labels.length == 1 ) ? 0.2 : (labels.length == 2 || labels.length == 3) ? 0.4 : 1,
          scaleLabel: {
            boxWidth: 50,
            fontSize: 15,
            fontColor: '#3DAB35',
            padding: 10,
            display: true,
            labelString: 'ULB Names',
            fontStyle: "bold",

          },
          // barThickness: 35,
          gridLines: {
            // display: true,
            // drawBorder: true,
            drawOnChartArea: false,
         }
        }]
      },
    } 

    
    const utilizationBarData =  {
      labels: labels,
      datasets: [
         {
          label: 'Utilization %',
          data: utilizationdata,
          backgroundColor: '#52AC36',
          // borderColor:"#52AC36",
         type:"bar",
         yAxisID:"UtilizationData",
         order: 2
        },
        {
          label: 'Loads',
          data: data,
          borderColor: 'Blue',
          backgroundColor:"white",
         type:"line",
         yAxisID: "loadsData",
         borderWidth: 3,
         order: 1
         
        }
      ],
    }

 
    const utilizationOptions = {
      legend: {
        position: "top",
        align: "center",
        labels: {
          fontColor: 'black',
          fontFamily:'sans-serif',
          defaultFontSize:120,
      }
     },
     ticks: {
      autoSkip: false
  },
      scales: {
        yAxes: [
         {
          id: "UtilizationData",
          position: "left",
          // type: 'linear',
          ticks: {
              beginAtZero: true
          },
           scaleLabel: {
            boxWidth: 50,
            fontSize: 15,
            fontColor: '#3DAB35',
            padding: 10,
            display: true,
            labelString: 'Utilization Rate (in %)',
            fontStyle: "bold",

          },
          gridLines: {
            drawOnChartArea: false,
            drawTicks: true
         }
        },
        {
          gridLines: {
            display: false
          },
          id: "loadsData",
          position: "right",
          // type: 'linear',
          display: true,
          ticks: {
            beginAtZero: true
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1]
        },
        stroke: {
          width: [0, 4]
        },
        scaleLabel: {
          boxWidth: 50,
          fontSize: 15,
          fontColor: '#3DAB35',
          padding: 10,
          display: true,
          labelString: 'Loads',
          fontStyle: "bold",
          opposite: true,

        },
        }
        ],

        xAxes: [{
          barPercentage: (labels.length == 1 ) ? 0.2 : (labels.length == 2 || labels.length == 3) ? 0.4 : 1,
          scaleLabel: {
            boxWidth: 50,
            fontSize: 15,
            fontColor: '#3DAB35',
            padding: 10,
            display: true,
            labelString: 'ULB Names',
            fontStyle: "bold",

          },
          // barThickness: 35,
          gridLines: {
            // display: true,
            // drawBorder: true,
            drawOnChartArea: false,
         }
        }]
      },
    } 
const dex = this.props.payloadExcal

    return(
    <Grid>
      <Grid container spacing={3} direction="row"> 
      { this.props.payloadExcal.category == '2' &&
        <Grid item xs={12}>
          <Paper height={500} style={{marginTop:'-55px' }}>
          <hr style={{border: "1px solid #3DAB35", marginLeft:"5%", width:"30px"}}></hr>
          <Grid container spacing={3} direction="row"> 
            <Grid item xs={12}>
              <div style={{padding:'2%'}} className="desktopDisplay">
              {/* <Bar data={barData} options = {options} width={800} height={300}/> */}
              <Bar data ={LoadsBarData} options = {LoadsOptions} width={900} height={300}></Bar>

              </div>
              <div style={{padding:'2%'}} className="mobileDisplay">
              {/* <Bar data={barData} options = {options} height={300}/> */}
              <Bar data ={LoadsBarData} options = {LoadsOptions} width={900} height={300}></Bar>

              </div>
            </Grid>
          </Grid>
        </Paper>  
        </Grid>
        
      }
        {this.props.payloadExcal.category == '1' &&
        <Grid item xs={12}>
          <Paper height={500} style={{marginTop:'-55px' }}>
          <hr style={{border: "1px solid #3DAB35", marginLeft:"5%", width:"30px"}}></hr>
          <Grid container spacing={3} direction="row"> 
            <Grid item xs={12}>
              <div style={{padding:'2%'}} className="desktopDisplay">
              <Bar data ={utilizationBarData} options = {utilizationOptions} width={900} height={300}></Bar>

              {/* <Bar data={utilizationBarData} options = {utilizationOptions} width={800} height={300}/> */}
              </div>
              <div style={{padding:'2%'}} className="mobileDisplay">
              <Bar data ={utilizationBarData} options = {utilizationOptions} width={900} height={300}></Bar>

              {/* <Bar data={utilizationBarData} options = {utilizationOptions} height={300}/> */}
              </div>
            </Grid>
          </Grid>
        </Paper>  
        </Grid>
        
      }
        <Grid item xs={12} className="table-height-state">
          <Paper className="scroll-table" style={{marginTop:"20px" }}><hr style={{border: "1px solid #3DAB35", marginLeft:"5%", width:"30px"}}></hr>
          <BasicTable rows={rows} columns={columns} payloadDExcal={dex} handleSort={this.handleSort} height="1500px"/></Paper> 
        </Grid>
      </Grid>
      {/* <EditDialog open={this.state.open} onClose={this.handleClose}></EditDialog> */}
    </Grid>
    )
  }
}

const mapStateToProps = state => {
  return {
    data: state.stateDashboard.stateDashboard,
    loading: state.loads.loading,
    chartData:state.stateDashboard.stateDashboardChartData,
    token: state.auth.token,
    
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchDashboardData: (payload,filter) => dispatch(actionsCreators.fetchStateDashboard(payload,filter))
    // onfetchStateDashboardExcal:(payload)=>dispatch(actionsCreators.fetchStateDashboardExcal(payload))
  }
}

export default  connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(Chart, axios));
