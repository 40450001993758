import React, { Component } from 'react';
import { connect } from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox';
import '../loads/RecordKeeper.css';
import * as actionsCreators from  '../../../store/actions/index';
import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler';
import axios from '../../../http-config';
import {Link} from 'react-router-dom'
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Card from '@material-ui/core/Card';
import { set } from 'lodash';
import { Grid } from '@material-ui/core';
const GreenCheckbox = withStyles({
    root: {
      color: "green[400] !important",
      '&$checked': {
        color: green[600],
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);
  

class EmailAdd extends Component {

    constructor(props){
        super()
    }
    state = {
        useremail : '',
        username : '',
        usernameErr : '',
        useremailErr : '',
        treatmentFacilities:[],
        treatmentFacility:[],
        name:'',
        email:'',
        id:'',
        isDisabled:false ,
        checkedG: false,
        checkedSelect:false,
        TfArr :[]
    }

    componentDidMount() {

        const treatmentFacilitiesData = ['Tf1','Tf2','Tf3','Tf4'];
        this.setState({
         treatmentFacilities: treatmentFacilitiesData
        });
        let payload = {
            token: this.props.token,
            page:  1,
            limit: this.state.limit,
            sort: '',
            filter: JSON.stringify([this.state.filter]),
            query: this.state.query
        }
        this.props.onFetchEmailManage(payload);
        this.props.onFetchTreatmentListing({ token: this.props.token})


    }

    handleChange = (e) =>
    {
        this.setState({
            [e.target.name]:e.target.value
        },()=>{
            this.checkValidity()
        })
    }

    handleTFChange = (e) => {
       
     this.setState({
                [e.target.name]:e.target.value
            })
    this.state.TfArr.push(parseInt(e.target.value))

    }

    checkValidity(value, rules,form) 
    {
        let isError=false;   
        if(!this.state.username)
        {
            isError=true;
            this.setState({usernameErr:"*This field is required"})
        }
        else if(this.state.username.length>0 && this.state.username.length<3)
        {
            isError=true;
            this.setState({usernameErr:"*Name should be atleast 3 characters long"})
        }
        else if(this.state.username && this.state.username.length >=3)
        {
            isError=false
            this.setState({usernameErr: ''})
        }
        if(!this.state.useremail)
        {
            isError=true;
            this.setState({useremailErr:"*This field is required"})
        }
        if(this.state.useremail)
        {
            var mailformat= /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
            if(!this.state.useremail.match(mailformat))
            {
                isError=true;
                this.setState({useremailErr:"*You have entered an invalid email address"})
            }
            else
            {
                isError=false
                this.setState({useremailErr:""})
            }
        }
        return isError
    }


handleChangeCheckedG =(event)=>{
    this.setState( {[event.target.name]: event.target.checked })
    const mapv = this.props.treatmentListing.map((item )=>{
        return parseInt(item.Id)
    })
  this.setState({TfArr:mapv})
  this.setState({checkedSelect:false})

}
onClickSaveAll = () => {
    const err=this.checkValidity()
    if(!err)
    {
        let isDisabled=false
        const payload={
        name:this.state.username,
        email:this.state.useremail,
        token: this.props.token,
        tfIds:this.state.TfArr
    }
    this.setState({isAddEnable:false, isDisabled:false, username : '', useremail:''})
    this.setState({checkedSelect:false})
    this.props.onAddEmailManage(payload,this.props)
}
}
handleChangeCheckedSelected =(event)=>{
    this.setState({checkedG: false})
    this.setState( {[event.target.name]: event.target.checked })
    
}

render() {
    return <div className="create_ulb">
    <div className="dashboard_top_path">
        <p><img src="images/left-arrow.svg" /> Email Management / <span>Add a New Record</span></p>
    </div>
    <div className="dashboard_right_content">
        <img className="dashboard_right_content_top_icon" src="images/Rectangle.svg" />
        <div className="dashboard_right_content_top">
            <h5>Add a New Record</h5>
        </div>
        <div className="create_ulb_content_outer">
            <div className="create_ulb_content"> 
            <Grid container spacing={3}>
                    <Grid item xs={6}>
                    <FormControl>
                        <label className="required" htmlFor="name">Name</label>
                            <TextField id="username" 
                                        variant="outlined" 
                                        placeholder="Enter Name" 
                                        name="username" 
                                        value={this.state.username} 
                                        style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif"}}
                                        onChange={this.handleChange}/>
                            <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.usernameErr}</div>
                    </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                    <FormControl>
                            <label htmlFor = "useremail">Email</label>
                                <TextField id = "useremail" 
                                            variant="outlined" 
                                            placeholder="Enter Valid Email Address" 
                                            name="useremail"
                                            InputProps={{ style: { fontSize: 12 } }} 
                                            value={this.state.useremail} 
                                            onChange={this.handleChange}/>
                                <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{this.state.useremailErr}</div>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                    <FormControl>
                                        <label htmlFor ="treatmentFacility">Select Treatment Facility</label>
                                        <div style={{display:'flex', justifyContent: "space-between"}}>
                                        <FormControlLabel className="spanLable"
                                                control={<GreenCheckbox checked={this.state.checkedG}  onChange={this.handleChangeCheckedG} name="checkedG" />}
                                                label="All"
                                            />

                                        <FormControlLabel className="spanLable"
                                                control={<GreenCheckbox checked={this.state.checkedSelect} onChange={this.handleChangeCheckedSelected} name="checkedSelect" />}
                                                label="Selected"
                                            />
                                        </div>
                                        {this.state.checkedSelect === true? 
                                        <Card style={{border:"1px solid	rgb(211,211,211)" ,width:"875px"}}>
                                        {this.props.treatmentListing.map((item )=> (
                                        
                                            <MenuItem key={item.Id} value={item.Name}>
                                                <Checkbox style = {{color:"#52AC36",fontSize: 12}} 
                                                //  checked={}          
                                                value={item.Id} 
                                                onChange={this.handleTFChange} 
                                                name="treatmentFacility"/>
                                                <ListItemText primary={item.Name} />
                                            </MenuItem>
                                            
                                            ))}
                                        </Card>
                                    :""}
                                    
                                    </FormControl>
                    </Grid>
                                </Grid>
               <Grid container spacing={3}>
                        <Grid item xs={12}>
                        <div className="create_ulb_buttons">
                    <Link style={{textDecoration:"none", color:"#3dab35"}} to="/email_manage"><Button className="login_submit" variant="outlined" color="primary">Cancel</Button></Link>
                    <Button className="login_submit" variant="contained" color="primary" onClick={this.onClickSaveAll}>Save</Button>
                </div>
                        </Grid>
               </Grid>
             
               
               
            </div>
            </div>   
            </div>
        </div>
   
}
}


const mapStateToProps = state => {
    return {
        users: state.emailManage.users,
        loading: state.loads.loading,
        token: state.auth.token,
        treatmentListing:state.treatmentListing.treatmentListing
        
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchEmailManage: (payload) => dispatch(actionsCreators.fetchEmailManage(payload)),
        onAddEmailManage : (payload,props) =>dispatch(actionsCreators.addEmail(payload,props)),
        onRemoveEmailManage:(payload)=>dispatch(actionsCreators.removeEmailManage(payload)),
        onFetchTreatmentListing: (payload) => dispatch(actionsCreators.fetchTreatmentListing(payload)),
    }
}

export default  connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(EmailAdd, axios));
