import * as actionTypes from './actionsTypes';
import axios from '../../http-config';

import swal from 'sweetalert'

export const fetchSInspectorStart = () => {
    return {
        type: actionTypes.FETCH_INSPECTOR_START,
        loading :true,
      
      
    }
}


export const fetchSInspectorSuccess = (response) => {
    return {
        type: actionTypes.FETCH_INSPECTOR_SUCCESS,
        listing: response.data.users,
        totalCount: response.totalCount,
        loading :false
    }
}

export const fetchSIzoneAndWardSuccess = (response) => {
    return {
        type: actionTypes.FETCH_ZONEANDWARD_SUCCESS,
        listingZone: response.data.zoneWardAssignData,
        totalCountzone: response.totalCount,
        loading :false
    }
}


export const fetchSInspectorFail = (error) => {
    return {
        type: actionTypes.FETCH_INSPECTOR_FAIL,
         error:error,
         loading:false
      
    }
}


export const fetchInspectorListing =(payload)=>{
    return dispatch => {
        dispatch(fetchSInspectorStart());
        const url = `user?SI=true&userName=${payload.searchQuery}&limit=${payload.limit}&page=${payload.page}&sortColumnName=${payload.sortName}&sortColumnType=${payload.sortValue}`;
        axios({
            method: 'get',
            url: url,
            headers: {'Authorization': `Bearer ${payload.token}`}
        })
        .then(function (response) {
            const fetchedLoadsResponse = response.data
            dispatch(fetchSInspectorSuccess(fetchedLoadsResponse));
        }).catch(error => {
            dispatch(fetchSInspectorFail(error));
        })
    }
}


export const deleteSIUser =(payload)=>{
    const body = {id:payload.id}
    return (dispatch) => {
        dispatch(fetchSInspectorStart())
        const url = '/user/delete_si' 
        axios.delete(url , {data:body , headers : {"Authorization":`Bearer ${payload.token}`} })
        .then((response) => {
            dispatch(fetchInspectorListing({token: payload.token,searchQuery:'',limit:10, page: 1, sortName:'', sortValue:''}))
            swal({
                title:"Your data has been deleted!",
                icon: "success",
            })
        }, 
        (err) => {
            swal({icon : "error" , title : err.response.data.error[0]["message"]})
            dispatch(fetchInspectorListing({token: payload.token,searchQuery:'',limit:10, page: 1, sortName:'', sortValue:''}))
            // props.history.push('/sanitaryInspector')
        })
        .catch((err) => {
            dispatch(fetchSInspectorFail(err))
        })
    }
}




export const editSiUserData = (payload, props) => {
    return dispatch => {
        dispatch(fetchSInspectorStart())
        let url =  `user/update_si`
        axios.put(url, payload,{headers:{
            "Authorization":`Bearer ${payload.token}`
        }})
        .then(response => {
            const fetchedResponse = response.data.success;
            if(fetchedResponse ===true){
                fetchedResponse && swal({
                    title: "You have Successfully edited the data!!!",
                    icon: "success",
                    buttons: {
                        confirm: 'Ok'
                    }
                  })
                  props.history.push("/Sanitory-Manegement")
            }
             
        }, (err) => {
            swal({icon : "error" , title : err.response.data.error[0]["message"]})          
        })
        .catch(err => {
            swal({icon : "error" , title : err["message"]})          

            dispatch(fetchSInspectorFail(err))
        })
    }
}


export const fetchSiZonesWardLIsting =(payload)=>{
    return dispatch => {
        dispatch(fetchSInspectorStart());
        const url = `/user/SI_zone_ward_assign?userId=${payload.id}&limit=${payload.limit}&page=${payload.page}&sortColumnName=${payload.sortName}&sortColumnType=${payload.sortValue}`;
        axios({
            method: 'get',
            url: url,
            headers: {'Authorization': `Bearer ${payload.token}`}
        })
        .then(function (response) {
            const fetchedLoadsResponse = response.data
            dispatch(fetchSIzoneAndWardSuccess(fetchedLoadsResponse));
        }).catch(error => {
            dispatch(fetchSInspectorFail(error));
        })
    }
}

export const fetchSanitoryListingExport =(payload)=>{
    let token = payload.token
    const url = `/user?SI=true&limit=${payload.limit}&page=${payload.page}&sortColumnName=${payload.sortName}&sortColumnType=${payload.sortValue}&export=true`
    return dispatch=>{
        dispatch(fetchSInspectorStart())
        axios.get(
            url,
            {headers:{ "Authorization":`Bearer ${token}`
        }}).then(function (response) {
            let link1=response.data.data[0].file_path
            
            window.downloadFile = function (link) {
                window.open(link, '_blank');
            }
            window.downloadFile(link1)
          
        }).catch(error => {
        })
    }
 }

 export const searchSiName = (payload) => {
    // user?SI=true&limit=10&page=1&sortColumnName=&sortColumnType=&userName=sanitory_insp01
    return dispatch => {
        dispatch(fetchSInspectorStart());
        const url = `/user?SI=true&userName=${payload.searchQuery}&limit=${payload.limit}&page=${payload.page}&sortColumnName=${payload.sortName}&sortColumnType=${payload.sortValue}`
        axios({
            method: 'get',
            url: url,
            headers: {'Authorization': `Bearer ${payload.token}`}
        })
        .then(function (response) {
            const fetchedLoadsResponse = response.data
            dispatch(fetchSInspectorSuccess(fetchedLoadsResponse));
        }).catch(error => {
            dispatch(fetchSInspectorFail(error));
        })
    }
 }

 export const addUserOfficer = (payload,props) => {
    let token= payload.token
    return (dispatch) => {
        const url = '/user'
        dispatch(fetchSInspectorStart());
        axios.post(url, payload, { headers : { "Authorization":`Bearer ${token}`}})
        .then((response) => {
            const fetchedResponse = response.data.success;
            fetchedResponse && swal({
                title: "User added successfully!",
                icon: "success",
                buttons: {
                    confirm: 'Ok'
                }
            })
            dispatch(fetchInspectorListing({token: payload.token,searchQuery:'',limit:10, page: 1, sortName:'', sortValue:''}))
        }, 
        (err) => {
            swal({icon : "error" , title : err.response.data.error[0]["message"]}) 
            dispatch(fetchInspectorListing({token: payload.token,searchQuery:'',limit:10, page: 1, sortName:'', sortValue:''}))

        })
        .catch((err) => {
            swal({icon : "error" , title : err.response.data.error[0]["message"]}) 
            dispatch(fetchSInspectorFail(err));
        })
    }
}

//delete zone ward for ulb Officer
export const deleteUlbOfficerZoneward =(payload)=>{
    const body = {sId:payload.sId}
    return (dispatch) => {
        dispatch(fetchSInspectorStart())
        const url = '/user/dlt_zone_ward_assign' 
        axios.delete(url , {data:body , headers : {"Authorization":`Bearer ${payload.token}`} })
        .then((response) => {
            dispatch(fetchSiZonesWardLIsting(payload))
            swal({
                title:"Your data has been deleted!",
                icon: "success",
            })
        }, 
        (err) => {
            swal({icon : "error" , title : err.response.data.error[0]["message"]})
            dispatch(fetchSiZonesWardLIsting(payload))
        })
        .catch((err) => {
            dispatch(fetchSInspectorFail(err))
        })
    }
}


export const editSiZoneWardData = (data, payload) => {
    return dispatch => {
        dispatch(fetchSInspectorStart())
        let url =  `user/updated_zone_ward_assign`
        axios.put(url, data,{headers:{
            "Authorization":`Bearer ${payload.token}`
        }})
        .then(response => {
            const fetchedResponse = response.data.success;
            if(fetchedResponse ===true){
                fetchedResponse && swal({
                    title: "You have Successfully edited the data!!!",
                    icon: "success",
                    buttons: {
                        confirm: 'Ok'
                    }
                  })
                  dispatch(fetchSiZonesWardLIsting(payload))
            }
             
        }, (err) => {
            swal({icon : "error" , title : err.response.data.error[0]["message"]})    
            dispatch(fetchSiZonesWardLIsting(payload))      
        })
        .catch(err => {
            swal({icon : "error" , title : err["message"]})          
            dispatch(fetchSiZonesWardLIsting(payload))
            dispatch(fetchSInspectorFail(err))
        })
    }
}