import React from 'react';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom'
import * as actionsCreators from  '../../store/actions/index';
import Table from '@material-ui/core/Table';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell'; 
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import NativeSelect from '@material-ui/core/NativeSelect';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import ReactPaginate from 'react-paginate';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import swal from 'sweetalert'
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
const BootstrapInput = withStyles((theme) => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor:"#fff",
      border: '1px solid #4CAF50',
      fontSize: 15,
      padding: '7px 26px 7px 20px',
      transition: theme.transitions.create(['border-color']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        'Open Sans',
        'sans-serif',
   
      ].join(','),
      '&:focus ': {
        borderRadius: 4,
            backgroundColor:"#fff !important"
   
      }
    },
  }))(InputBase);

class ViewWard extends React.Component{
    constructor(props){
        super(props)
        this.state ={
            page : 1,
            limit:50,
            id:!!this.props.match&&this.props.match.params.id,
            selected: [],
            checkValue:false,
            filterBy:'-1',
        }
    }
    componentDidMount(){
if(this.state.id === "all"){
    const payload1 = {
        token: this.props.token,
        // id:this.state.id,
        page: this.state.page,
        limit: this.state.limit,
    }
    this.props.onunAssignedListing(payload1)
    this.props.onSchedulePropertyZone()
}
else{
    const payload = {
        token: this.props.token,
        id:this.state.id,
        page: this.state.page,
        limit: this.state.limit,
    }
    this.props.onSelectZoneWard(payload)
}
      

    }
    handlePageClick = (e) => {
        const selectedPage = e.selected;
        if(this.state.id === "all"){
            const payload1 = {
                token: this.props.token,
                // id:this.state.id,
                page: selectedPage + 1,
                limit: this.state.limit,
            }
            this.props.onunAssignedListing(payload1)
        }
        else{
            let payload = {
                token: this.props.token,
                id: this.state.id,
                page: selectedPage + 1,
                limit: this.state.limit,
            }
            this.props.onSelectZoneWard(payload)
        }
    };


    handleCheckboxClick = (event, id) => {
        event.stopPropagation();
        const statsvalue = JSON.parse(event.target.value)
  
        this.setState({checkValue: true})
        const { selected } = this.state;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        this.setState({ selected: newSelected });
      
        // let payload = {
            
        //     wardIdList: newSelected,
        //     token: this.props.token,
        //     page: this.state.page,
        //     limit: this.state.limit
        // }
        
    };
    handleSubmit =(id)=>{
        // e.preventDefault()
if(id === 'all'){
    if(this.state.filterBy != '-1' && this.state.selected.length !== 0){
        let payload1 = {
            // id: this.state.id,
            zoneId: this.state.filterBy,
            wardIdList: this.state.selected,
            token: this.props.token,
            page: this.state.page,
            limit: this.state.limit
        }
        this.props.onMakeAssignWard(payload1)
    }
    else{
        swal({
            title:"PNP don't have Zones",
            icon:'warning',
            text:"You can't assign the ward."
        })
    }

}
     else{
         if(this.state.selected.length !== 0){
            let payload = {
                id: this.state.id,
                wardIdList: this.state.selected,
                token: this.props.token,
                page: this.state.page,
                limit: this.state.limit
            }
            this.props.onMakeUnAssignedWard(payload)
         }
         else{
            swal({
                title:'Please select the Required fields!!',
                icon:'warning'
            })
        }

    }
    }

    handleChange = (e ) => {
        this.setState({filterBy:e.target.value})
    }
    
    render(){
        const updatedHeaders = [
            {
                accessor: "select",
                filter: "false",
                header: "Select",
                sortable: "false",
            },
            {
                accessor: "wardNumber",
                filter: "false",
                header: "Ward No.",
                sortable: "false",
            },
        
        ]
        const Headers = [
            {
                accessor: "select",
                filter: "false",
                header: "Select",
                sortable: "false",
            },
            {
                accessor: "wardNumber",
                filter: "false",
                header: "Ward No.",
                sortable: "false",
            },
          
        ]

        const WardHeaderList = updatedHeaders.map((item) => {
            return (<TableCell className={"white-space"}>
                <div style={{
                    textAlign: 'center',
                    whiteSpace: 'break-spaces',

                }}>
                    {item.header}
                </div>
            </TableCell>)
        })
        const HeaderList = Headers.map((item) => {
            return (<TableCell className={"white-space"}>
                <div style={{
                    textAlign: 'center',
                    whiteSpace: 'break-spaces',

                }}>
                    {item.header}
                </div>
            </TableCell>)
        })
        
        const wards = !!this.props.wardsSelectZone && this.props.wardsSelectZone.slice(0, 10).map((ward, j) => {
           const id = ward['wardId']
            return <TableRow style={{
                whiteSpace: 'normal',
                wordWr: 'break-word'
            }} key={j}>{
                    updatedHeaders.map((header, i) => {
                        if(header.accessor == 'select'){
                            return <TableCell style={{
                                whiteSpace: "normal",
                                textAlign: 'center',
                                padding: '5%',
                                wordWrap: "break-word"
                            }} key={i}>
                                  <Checkbox
                                        onClick={event =>this.handleCheckboxClick(event, id)}
                                        value={this.state.checkValue}
                                        // checked={}
                                    />
                            </TableCell>
                        }
                        else{
                            return <TableCell style={{
                                whiteSpace: "normal",
                                textAlign: 'center',
                                padding: '5%',
                                wordWrap: "break-word"
                            }} key={i} className={"white-space"}>{ward[header.accessor] == null || ward[header.accessor] == 'null' ? ' ' : ward[header.accessor]}</TableCell>
                        }
                      

                    })
                }
            </TableRow>
        })
        const properties = !!this.props.wardsSelectZone && this.props.wardsSelectZone.slice(10, 20).map((ward, j) => {
            const id = ward['wardId']
            return <TableRow style={{
                whiteSpace: 'normal',
                wordWr: 'break-word'
            }} key={j}>{
                    Headers.map((header, i) => {
                        if(header.accessor == 'select'){
                            return <TableCell style={{
                                whiteSpace: "normal",
                                textAlign: 'center',
                                padding: '5%',
                                wordWrap: "break-word"
                            }} key={i}>
                                  <Checkbox
                                        onClick={event =>this.handleCheckboxClick(event, id)}
                                        value={this.state.checkValue}
                                        // checked={this.state.checkValue}
                                    />
                            </TableCell>
                        }
                        else{

                        return <TableCell style={{
                            whiteSpace: "normal",
                            textAlign: 'center',
                            padding: '5%',
                            wordWrap: "break-word"
                        }} key={i} className={"white-space"}>{ward[header.accessor] == null || ward[header.accessor] == 'null' ? ' ' : ward[header.accessor]}</TableCell>
                    }
                    })
                }
            </TableRow>
        })

        const properties1 = !!this.props.wardsSelectZone && this.props.wardsSelectZone.slice(20, 30).map((ward, j) => {
            const id = ward['wardId']
            return <TableRow style={{
                whiteSpace: 'normal',
                wordWr: 'break-word'
            }} key={j}>{
                    Headers.map((header, i) => {
                        if(header.accessor == 'select'){
                            return <TableCell style={{
                                whiteSpace: "normal",
                                textAlign: 'center',
                                padding: '5%',
                                wordWrap: "break-word"
                            }} key={i}>
                                  <Checkbox
                                        onClick={event =>this.handleCheckboxClick(event, id)}
                                        value={this.state.checkValue}
                                        // checked={this.state.checkValue}
                                    />
                            </TableCell>
                        }
                        else{

                        return <TableCell style={{
                            whiteSpace: "normal",
                            textAlign: 'center',
                            padding: '5%',
                            wordWrap: "break-word"
                        }} key={i} className={"white-space"}>{ward[header.accessor] == null || ward[header.accessor] == 'null' ? ' ' : ward[header.accessor]}</TableCell>
                    }
                    })
                }
            </TableRow>
        })
const properties2 = !!this.props.wardsSelectZone && this.props.wardsSelectZone.slice(30, 40).map((ward, j) => {
    const id = ward['wardId']
    return <TableRow style={{
        whiteSpace: 'normal',
        wordWr: 'break-word'
    }} key={j}>{
            Headers.map((header, i) => {
                if(header.accessor == 'select'){
                    return <TableCell style={{
                        whiteSpace: "normal",
                        textAlign: 'center',
                        padding: '5%',
                        wordWrap: "break-word"
                    }} key={i}>
                          <Checkbox
                                onClick={event =>this.handleCheckboxClick(event, id)}
                                value={this.state.checkValue}
                                // checked={this.state.checkValue}
                            />
                    </TableCell>
                }
                else{

                return <TableCell style={{
                    whiteSpace: "normal",
                    textAlign: 'center',
                    padding: '5%',
                    wordWrap: "break-word"
                }} key={i} className={"white-space"}>{ward[header.accessor] == null || ward[header.accessor] == 'null' ? ' ' : ward[header.accessor]}</TableCell>
            }

            })
        }
    </TableRow>
})
        return (
            <div>
            <div className="dashboard_right_content" style={{marginTop:'-4%'}}>
                <img className="dashboard_right_content_top_icon" src="images/Rectangle.svg" />
                <div className="dashboard_right_content_top">
                <h5><Link to = "/zone-management" className="email_link"><ArrowBackIosIcon></ArrowBackIosIcon></Link>{this.state.id == 'all'? 'Un-Assigned Wards': 'Existing Ward Listing'}</h5>
                {this.state.id == 'all'?  !!this.props.zones &&this.props.zones.length == 0? "":     <Grid item xs={3}>
                                
                <FormControl style={{color: '#4CAF50',width:"100%"}}>
                            <NativeSelect
                            value={this.state.filterBy}
                            onChange={this.handleChange}
                            input={<BootstrapInput  />}
                            style={{color: '#4CAF50'}}>
                                <option value=''>All Zones</option>
                                {!!this.props.zones&&this.props.zones.map((item)=>{
                                    if(!!item.Id && item.Id !== null && item.Name != "null"){
                                        return   <option value={item.Id}> {item.Name}</option>
                                    }
                                })}
                            </NativeSelect>
                        </FormControl>
                                                    </Grid>:''}
                </div>
                    <Grid container direction="row">
                         <Grid items xs={3} lg={2}>
                         {wards.length === 0? '':
                             <div className="dashboard_listing" >
                                <TableContainer component={Paper} style={{overflow:"hidden",paddingLeft:"15px"}}>
                                    <Table aria-label="simple table" style={{ tableLayout: 'fixed' }}>
                                        <TableHead>
                                            <TableRow>
                                            {WardHeaderList}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {wards}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>}
                         </Grid>
                         <Grid items xs={3} lg={2}>
                         {properties.length === 0? '':
                             <div className="dashboard_listing" >
                                 <TableContainer component={Paper} style={{paddingLeft:"0px" }}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                            {HeaderList}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody> 
                                      {properties}
                                        </TableBody>
                                    </Table>
                                 </TableContainer> 
                             </div>}
                         </Grid>

                         <Grid items xs={3} lg={2}>
                             {properties1.length === 0? '':
                             <div className="dashboard_listing" >
                                 <TableContainer component={Paper} style={{paddingLeft:"0px"}}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                            {HeaderList}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody> 
                                      {properties1}
                                        </TableBody>
                                    </Table>
                                 </TableContainer> 
                             </div>}
                         </Grid>
                         <Grid items xs={3} lg={2}>
                             {properties2.length === 0? '':
                             <div className="dashboard_listing" >
                                 <TableContainer component={Paper} style={{paddingLeft:"0px"}}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                            {HeaderList}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody> 
                                      {properties2}
                                        </TableBody>
                                    </Table>
                                 </TableContainer> 
                             </div>}
                         </Grid>
                     </Grid>

                     {
            this.props.totalCount && this.props.totalCount != 0 && 
                <ReactPaginate
                previousLabel={"Prev"}
                nextLabel={"Next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount = {10}
                pageCount = {this.props.totalCount/this.state.limit}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                style={{borderLeftWidth:'0px'}}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}/>
            }


                <Grid container spacing={3}>
                    {wards.length === 0? '':
                            <Grid item xs={12}>
                                <div className="create_ulb_buttons">
                                    <Link style={{ textDecoration: "none", color: "#3dab35" }} to={'/zone-management'}>
                                        <Button className="login_submit" variant="outlined" color="primary">Cancel</Button></Link>
                                    <Button className="login_submit" variant="contained" color="primary" onClick={()=>{

                                        swal({ title : "Are you sure?",
                                      
                                        icon : "warning",
                                        buttons : {
                                        cancel : 'No',
                                        confirm: 'Yes'}
                                        })
                                        .then((willDelete) => {
                                        if (willDelete) {
                                        this.handleSubmit(this.state.id);
                                        } 
                                        });
                                    }}>{this.state.id == 'all'? ' Assign Ward':' UnAssign Ward'}</Button>
                                </div>
                            </Grid>}
                        </Grid>
    
            </div>
        
        </div>
        )
    }
}


const mapStateToProps = (state) => {
    return ({
        token: state.auth.token,
        zones: state.propertyListing.zones,
        wardsSelectZone: state.WardReducer.wards,
        totalCount: state.WardReducer.totalCount,
        // wards:state.WardReducer.wards,
    })
}


const mapDispatchToProps = dispatch => {
    return {
        onSelectZoneWard: (payload) => dispatch(actionsCreators.SelectZoneWard(payload)),
        onMakeUnAssignedWard: (payload, props) => dispatch(actionsCreators.makeUnAssignedWard(payload, props)),
        onunAssignedListing :(payload) =>dispatch(actionsCreators.unAssignedListing(payload)),
        onSchedulePropertyZone: () => dispatch(actionsCreators.schedulePropertyZone()),
        onMakeAssignWard : (payload, props) => dispatch(actionsCreators.makeAssignWard(payload,props))
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(ViewWard)
