import * as actionTypes from './actionsTypes';
import axios from '../../http-config';

export const fetchHeadersSuccess = (response) => {
    return {

        type: actionTypes.FETCH_HEADERS_SUCCESS,
         headers:response.data.headers
      
      
    }
}

export const fetchHeadersFail = (error) => {
    return {
        type: actionTypes.FETCH_HEADERS_FAIL,
        error:error
    }
}

export const fetchHeadersStart = () => {
    return {
        type: actionTypes.FETCH_HEADERS_START
    }
}

export const fetchHeaders = (payload) => {
    let token = payload.token;
    let ulbId = payload.ulbId
    return dispatch => {
        dispatch(fetchHeadersStart());
        axios({
            method: 'get',
            url: ulbId == '' ? `/load/headers` : `/load/headers?ulbId=${ulbId}`,
            headers: {'Authorization': `Bearer ${token}`}
        })
        .then((response) => {
           
            const fetchedHeadersResponse = response.data;
            
            dispatch(fetchHeadersSuccess(fetchedHeadersResponse));
        }).catch(error => {
            dispatch(fetchHeadersFail(error));
        })

    }
}


