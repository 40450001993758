import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import axios from '../../http-config'
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux'
import * as actionCreators from '../../store/actions/index'

import DialogContentText from '@material-ui/core/DialogContentText';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';
import Card from "../Dashboard/Card/Card.js"
import CardHeader from "../Dashboard/Card/CardHeader.js";
import CardIcon from "../Dashboard/Card/CardIcon.js";
import CardBody from '../Dashboard/Card/CardBody.js'
import { DatePicker , MuiPickersUtilsProvider} from "@material-ui/pickers";
import moment from 'moment'
import DateFnsUtils from '@date-io/date-fns';
import { TextField } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { de } from 'date-fns/esm/locale';
import {Bar} from 'react-chartjs-2';
import Grid from '@material-ui/core/Grid';
import { Paper } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import classes from './addChartDialog.module.css'
import swal from 'sweetalert';
import {STATEDASHBOARDUSER,ADMIN} from '../../config/constant'
import Toolbar from '@material-ui/core/Toolbar';
const emails = ['username@gmail.com', 'user02@gmail.com'];
const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

 class AddChartDialog extends Component {
  state = {
    range : this.props.range ? this.props.range : '',
    chartsData:[],
    checkedSensorPH:false,
    sensorPhTf : '',
    sensorPhType : 'Bar',
    checkedSensorCOD:false,
    sensorCODTf : '',
    sensorCODType : 'Bar',
    checkedSensorBOD:false,
    sensorBODTf : '',
    sensorBODType : 'Bar',
    checkedSensorFLRate:false,
    sensorFLRateTf : '',
    sensorFLRateType : 'Bar',
    checkedSensorNO3:false,
    sensorNO3Tf : '',
    sensorNO3Type : 'Bar',
    checkedSensorORP:false,
    sensorORPTf : '',
    sensorORPType : 'Bar',
    checkedSensorTFLRate:false,
    sensorTFLRateTf : '',
    sensorTFLRateType : 'Bar',
    checkedSensorTSS:false,
    sensorTSSTf : '',
    sensorTSSType : 'Bar',
    mixedChartData:{},
    mixedChartOptions:{},
    isDisabled:false,
    interval:'Current Day',
    todate:this.props.todate,
    fromdate:this.props.fromdate,
    open:true,
    Url:'',
    tfName:'',
    tfId:'',
    selectedUlb:this.props.selectedUlb
  }

 componentDidMount=()=>{
 }


 componentWillReceiveProps=(nextProps)=>{
   this.setState({
     selectedUlb:nextProps.selectedUlb,
     tfId : nextProps.tfId
   })
}

 handleClickOpen = () => {
   this.setState({
     open:true
   })
  };

 handleClose = () => {
  this.props.onClose(false)
  this.setState({
    chartsData:[],
    checkedSensorPH:false,
    sensorPhTf : '',
    sensorPhType : 'Bar',
    checkedSensorCOD:false,
    sensorCODTf : '',
    sensorCODType : 'Bar',
    checkedSensorBOD:false,
    sensorBODTf : '',
    sensorBODType : 'Bar',
    checkedSensorFLRate:false,
    sensorFLRateTf : '',
    sensorFLRateType : 'Bar',
    checkedSensorNO3:false,
    sensorNO3Tf : '',
    sensorNO3Type : 'Bar',
    checkedSensorORP:false,
    sensorORPTf : '',
    sensorORPType : 'Bar',
    checkedSensorTFLRate:false,
    sensorTFLRateTf : '',
    sensorTFLRateType : 'Bar',
    checkedSensorTSS:false,
    sensorTSSTf : '',
    sensorTSSType : 'Bar',
    tfName: '',
    tfId:this.props.tfId
  })
  };

  getCheckValue = (sensor) => { 
    switch(sensor){
      case 'COD':
        return this.state.checkedSensorCOD
      case 'BOD':
        return this.state.checkedSensorBOD
      case 'PH':
        return this.state.checkedSensorPH
      case 'FLRate':
        return this.state.checkedSensorFLRate 
      case 'NO3':
        return this.state.checkedSensorNO3 
      case 'ORP':
        return this.state.checkedSensorORP
      case 'TFLRate':
        return this.state.checkedSensorTFLRate 
      case 'TSS':
        return this.state.checkedSensorTSS       
      default:
        return false  
    }
  }

  getTfValue = (sensor) => {
    switch(sensor){
      case 'COD':
        return this.state.sensorCODTf
      case 'BOD':
        return this.state.sensorBODTf
      case 'PH':
        return this.state.sensorPhTf 
      case 'FLRate':
        return this.state.sensorFLRateTf
      case 'NO3':
        return this.state.sensorNO3Tf  
      case 'ORP':
        return this.state.sensorORPTf 
      case 'TFLRate':
        return this.state.sensorTFLRateTf 
      case 'TSS':
        return this.state.sensorTSSTf    
      default:
        return ''  
    }
  }

  getTypeValue = (sensor) => {
    switch(sensor){
      case 'COD':
        return this.state.sensorCODType
      case 'BOD':
        return this.state.sensorBODType
      case 'PH':
        return this.state.sensorPhType  
      case 'FLRate':
        return this.state.sensorFLRateType 
      case 'NO3':
        return this.state.sensorNO3Type 
      case 'ORP':
        return this.state.sensorORPType  
      case 'TFLRate':
        return this.state.sensorTFLRateType  
      case 'TSS':
        return this.state.sensorTSSType        
      default:
        return ''  
    }
  }

  handleSelectedSensors = (charts,event,sensorName) => {
    if(event.target.name == `checkedSensor${sensorName}`){
      this.setState({[event.target.name]:event.target.checked},() => {
        if(this.getCheckValue(sensorName) && this.getTypeValue(sensorName)){
          charts.push({
            'sensor': `${sensorName}`,
            'type':this.getTypeValue(sensorName),
            'tf':this.state.tfId
          })
        }
      })
    }else{
      this.setState({[event.target.name]:event.target.value},() => {
        if(this.getCheckValue(sensorName) && this.getTypeValue(sensorName)){
          charts.push({
            'sensor': `${sensorName}`,
            'type':this.getTypeValue(sensorName),
            'tf':this.state.tfId
          })
        }
      })
    }
  
    this.setState({
      chartsData : charts
    })
  } 

 handleSensorPh = (event) => { 
   let charts = this.state.chartsData; 
   charts = charts.filter( charts => {
    return charts.sensor != 'PH'
   })
   this.handleSelectedSensors (charts,event,'PH')
 }  
  
 handleSensorBod = (event) => { 
  let charts = this.state.chartsData; 
  charts = charts.filter( charts => {
   return charts.sensor != 'BOD'
  })
  this.handleSelectedSensors (charts,event,'BOD')
 } 

 handleSensorCod = (event) => { 
  let charts = this.state.chartsData; 
  charts = charts.filter( charts => {
   return charts.sensor != 'COD'
  })
  this.handleSelectedSensors (charts,event,'COD')
 }

 handleSensorFLRate = (event) => {
  let charts = this.state.chartsData; 
  charts = charts.filter( charts => {
   return charts.sensor != 'FLRate'
  })
  this.handleSelectedSensors (charts,event,'FLRate')
 }

 handleSensorNO3 = (event) => {
  let charts = this.state.chartsData; 
  charts = charts.filter( charts => {
   return charts.sensor != 'NO3'
  })
  this.handleSelectedSensors (charts,event,'NO3')
 }

 handleSensorORP = (event) => {
  let charts = this.state.chartsData; 
  charts = charts.filter( charts => {
   return charts.sensor != 'ORP'
  })
  this.handleSelectedSensors (charts,event,'ORP')
 }

 handleSensorTFLRate = (event) => {
  let charts = this.state.chartsData; 
  charts = charts.filter( charts => {
   return charts.sensor != 'TFLRate'
  })
  this.handleSelectedSensors (charts,event,'TFLRate')
 }

 handleSensorTSS = (event) => {
  let charts = this.state.chartsData; 
  charts = charts.filter( charts => {
   return charts.sensor != 'TSS'
  })
  this.handleSelectedSensors (charts,event,'TSS')
 }

 addChartAPI = async(payload) => {
  this.setState({
    loader:true
  })
  const response = localStorage.getItem('roleName') == STATEDASHBOARDUSER ? await this.props.addChartSD(payload,this.state.selectedUlb) : 
  localStorage.getItem('roleName') == ADMIN ? await this.props.addChart(payload,this.state.selectedUlb) :
  await this.props.addChart(payload)
  this.props.handleChartToDisplay()
  this.setState({
    loader:false
  })
  if(!response){
    this.setState({
      loader:false
    })
  }
 }

  createChart = () => {
    const charts = this.state.chartsData
    const sensorUnits = {
      "UnitBOD": "mg/L",
      "UnitCOD": "mg/L",
      "UnitFLRate": "L/h",
      "UnitNO3": "mg/L",
      "UnitORP": 'mV',
      "UnitPH": '',
      "UnitTFLRate": "L",
      "UnitTSS": "mg/L"
    }
    if(this.state.tfId === ""){
        swal("Please select Treatment Facility!", {
          icon: "warning",
        });
        return
      }
      if(charts.length > 2){
        swal("Please select only two sensors at a time and then proceed!", {
          icon: "warning",
        });
        return
      }else if(charts.length == 0){
        swal("Please select atleast one sensor and then proceed!", {
          icon: "warning",
        })
        return
      }else{
        this.setState({
          loader:true
        })
        if((charts.length > 0 && this.props.metaData.length < 5)){
          const sensor1chartType = charts[0].type;
          const sensor2chartType = charts.length > 1 ? charts[1].type : '' 
          const sensor1 = charts[0].sensor;
          const sensor2 = charts.length > 1 ? charts[1].sensor : '';
          let sensor1Unit = ''
          let sensor1Data = []
          let sensor2Data = []
          let sensor2Unit = ''
          for (const [key, value] of Object.entries(sensorUnits)) {
            if(key.startsWith('Unit') && key.includes(sensor1)){
            sensor1Unit = value
            }
            if(key.startsWith('Unit') && key.includes(sensor2)){
              sensor2Unit = value
            }
          }

          const labels = []
            
          let datasets = []
          let yAxes = []
          if(charts.length > 1){
            yAxes =  [
              {
                gridLines: {
                  display:false
                },
                  id: sensor1,
                  position: 'left',
                  display:true,
                  ticks: {
                      beginAtZero: true,
                  },
                  scaleLabel: {
                      display: true,
                      labelString: `${sensor1} ${sensor1Unit}`
                    }
              },
              {
                  gridLines: {
                  display:false
                  },
                  id: sensor2,
                  position: 'right',
                  ticks: {
                      beginAtZero: true,
                  },
                  scaleLabel: {
                      display: true,
                      labelString: `${sensor2} ${sensor2Unit}`
                    }
              },
          ]
    
          datasets = this.handleMultipleChartDataSet (sensor1chartType,sensor1,[],sensor2chartType,sensor2,[sensor2Data])
          }else{
            yAxes =  [
              {
                gridLines: {
                  display: false
                },
                id: sensor1,
                display:true,
                // position: 'left',
                ticks: {
                  beginAtZero: true,
                },
                scaleLabel: {
                  display: true,
                  labelString: `${sensor1} ${sensor1Unit}`
                }
              },
              
              {
                gridLines: {
                  display: false
                },
                // id: sensor2,
                display:false,
                // position: 'right',
                // ticks: {
                //   beginAtZero: true,
                // },
                // scaleLabel: {
                //   display: true,
                //   labelString: `${sensor2} ${sensor2Unit}`
                // }
              },
          ]
            
          datasets = this.handleSingleChartDataSet (sensor1chartType,sensor1,[])
          }
          const options = {
            scales: {
                yAxes: yAxes,
                xAxes: [{
                  gridLines: {
                    display:false
                  },
                  ticks: {
                      autoSkip: true,
                      maxTicksLimit: 20
                  }
              }]
            }
          }

          const mixedChartDataForAdd = {
            labels: [],
            datasets: datasets,
            tfId:this.state.tfId
          }
         
          const AddChartData = {
              "data":mixedChartDataForAdd,
              "options":options
          }
    
          let addChartPayload = []
          addChartPayload.push(AddChartData)
          let payload = {
            "payload":addChartPayload,
            "token":localStorage.getItem('token')
          }
          this.addChartAPI(payload)
          this.setState({
            chartsData:[],
            checkedSensorPH:false,
            sensorPhTf : '',
            sensorPhType : 'Bar',
            checkedSensorCOD:false,
            sensorCODTf : '',
            sensorCODType : 'Bar',
            checkedSensorBOD:false,
            sensorBODTf : '',
            sensorBODType : 'Bar',
            checkedSensorFLRate:false,
            sensorFLRateTf : '',
            sensorFLRateType : 'Bar',
            checkedSensorNO3:false,
            sensorNO3Tf : '',
            sensorNO3Type : 'Bar',
            checkedSensorORP:false,
            sensorORPTf : '',
            sensorORPType : 'Bar',
            checkedSensorTFLRate:false,
            sensorTFLRateTf : '',
            sensorTFLRateType : 'Bar',
            checkedSensorTSS:false,
            sensorTSSTf : '',
            sensorTSSType : 'Bar',
            tfName: '',
            tfId:this.props.tfId
          })
          }else{
            swal("Cannot Add Charts more than five!", {
              icon: "warning",
            })
            this.handleClose()
          }
          this.handleClose()
      }
  }

  handleSingleChartDataSet = (sensor1chartType,sensor1,sensor1Data) => {
    let datasets = []
    switch(sensor1chartType){
      case 'Line':
          datasets = [{
            type:'line',
            label:sensor1 ,
            backgroundColor: 'rgb(54, 162, 235)',
            borderColor: 'rgb(54, 162, 235)',
              // borderWidth: 2,
              fill: false,
              data: sensor1Data,
              yAxisID: sensor1,
              pointRadius: 2,
          }
        ]
        break;
      case 'Bar':
          datasets = [{
            type:'bar',
            label:sensor1 ,
            backgroundColor: 'rgb(54, 162, 235)',
            borderColor: 'rgb(54, 162, 235)',
              // borderWidth: 2,
              pointRadius: 2,
              fill: false,
              data: sensor1Data,
              yAxisID: sensor1
          }
        ]
        break; 
      case 'Area':
          datasets = [{
            type:'line',
            label:sensor1 ,
            backgroundColor: 'rgb(179, 224, 255)',
            borderColor: 'rgb(54, 162, 235)',
              // borderWidth: 2,
              pointRadius: 2,
              fill: false,
              data: sensor1Data,
              yAxisID: sensor1,
              fillOpacity: .3, 
              fill: 'start' 
          }
        ]
        break;   
    default:
        datasets = [{
          type:'line',
          label:sensor1 ,
          backgroundColor: 'rgb(54, 162, 235)',
          borderColor: 'rgb(54, 162, 235)',
            // borderWidth: 2,
            pointRadius: 2,
            fill: false,
            data: sensor1Data,
            yAxisID: sensor1
        }
      ]
    }
    return datasets
  }

  handleMultipleChartDataSet = (sensor1chartType,sensor1,sensor1Data,sensor2chartType,sensor2,sensor2Data) => {
    let datasets = []
    switch(`${sensor1chartType}${sensor2chartType}`){
      case 'LineLine':
          datasets = [{
            type:'line',
            label:sensor1 ,
            backgroundColor: 'rgb(54, 162, 235)',
            borderColor: 'rgb(54, 162, 235)',
              // borderWidth: 2,
              fill: false,
              data: sensor1Data,
              yAxisID: sensor1,
              pointRadius: 2,
          },
          {
            type:'line',
              label:sensor2,
              backgroundColor: 'rgb(255, 99, 132)',
              borderColor:'rgb(255, 99, 132)',
                // borderWidth: 2,
                fill: false,
                pointRadius: 2,
                data: sensor2Data,
                yAxisID: sensor2
          }
        ]
        break;
      case 'BarBar':
          datasets = [{
            type:'bar',
            label:sensor1 ,
            backgroundColor: 'rgb(54, 162, 235)',
            borderColor: 'rgb(54, 162, 235)',
              // borderWidth: 2,
              pointRadius: 2,
              fill: false,
              data: sensor1Data,
              yAxisID: sensor1
          },
          {
            type:'bar',
              label:sensor2,
              backgroundColor: 'rgb(255, 99, 132)',
              borderColor:'rgb(255, 99, 132)',
                // borderWidth: 2,
                pointRadius: 2,
                fill: false,
                data: sensor2Data,
                yAxisID: sensor2
          }
        ]
        break; 
      case 'AreaArea':
          datasets = [{
            type:'line',
            label:sensor1 ,
            backgroundColor: 'rgb(179, 224, 255)',
            borderColor: 'rgb(54, 162, 235)',
              // borderWidth: 2,
              pointRadius: 2,
              fill: false,
              data: sensor1Data,
              yAxisID: sensor1,
              fillOpacity: .3, 
              fill: 'start' 
          },
          {
            type:'line',
              label:sensor2,
              backgroundColor: 'rgb(245,168,192)',
              borderColor:'rgb(255, 99, 132)',
                // borderWidth: 2,
                pointRadius: 2,
                fill: false,
                data: sensor2Data,
                yAxisID: sensor2,
                fillOpacity: .3, 
                fill: 'start' 
          }
        ]
        break;   
      case 'LineBar':
          datasets = [{
            type:'line',
            label:sensor1 ,
            backgroundColor: 'rgb(54, 162, 235)',
            borderColor: 'rgb(54, 162, 235)',
              // borderWidth: 2,
              pointRadius: 2,
              fill: false,
              data: sensor1Data,
              yAxisID: sensor1
          },
          {
            type:'bar',
              label:sensor2,
              backgroundColor: 'rgb(255, 99, 132)',
              borderColor:'rgb(255, 99, 132)',
                // borderWidth: 2,
                pointRadius: 2,
                fill: false,
                data: sensor2Data,
                yAxisID: sensor2
          }
        ]
        
        break;
    case 'BarLine':
        datasets = [{
            type:'bar',
            label:sensor1,
            backgroundColor: 'rgb(255, 99, 132)',
            borderColor:'rgb(255, 99, 132)',
            // borderWidth: 2,
            pointRadius: 2,
            fill: false,
            data: sensor1Data,
            yAxisID: sensor1
          },{
          type:'line',
          label:sensor2 ,
          backgroundColor: 'rgb(54, 162, 235)',
          borderColor: 'rgb(54, 162, 235)',
          // borderWidth: 2,
          pointRadius: 2,
          fill: false,
          data: sensor2Data,
          yAxisID: sensor2
          }
        ]
        
        break; 
    case 'AreaBar':
      datasets=[{
          type:'line',
          backgroundColor: 'rgb(179, 224, 255)',
          borderColor: 'rgb(54, 162, 235)',
          data: sensor1Data,
          yAxisID: sensor1,
          label: sensor1,
          pointRadius: 2,
          fill: 'start' 
        }, {
          type:'bar',
          label:sensor2,
          backgroundColor: 'rgb(255, 99, 132)',
          borderColor:'rgb(255, 99, 132)',
          // borderWidth: 2,
          pointRadius: 2,
          fill: false,
          data: sensor2Data,
          yAxisID: sensor2
        }
      ]
      break; 
    case 'AreaLine': 
    datasets=[{
      type:'line',
      backgroundColor: 'rgb(179, 224, 255)',
      borderColor: 'rgb(54, 162, 235)',
      data: sensor1Data,
      yAxisID: sensor1,
      label: sensor1,
      pointRadius: 2,
      fill: 'start' 
    }, {
      type:'line',
      label:sensor2 ,
      backgroundColor: 'rgb(255, 99, 132)',
      borderColor:'rgb(255, 99, 132)',
      // borderWidth: 2,
      pointRadius: 2,
      fill: false,
      data: sensor2Data,
      yAxisID: sensor2
    }
  ]  
  break;
  case 'LineArea': 
      datasets=[{
        type:'line',
        backgroundColor: 'rgb(54, 162, 235)',
        borderColor: 'rgb(54, 162, 235)',
        data: sensor1Data,
        yAxisID: sensor1,
        pointRadius: 2,
        label: sensor1,
        fill: false  
      }, {
        type:'line',
        label:sensor2 ,
        backgroundColor: 'rgb(245,168,192)',
        borderColor:'rgb(255, 99, 132)',
        // borderWidth: 2,
        pointRadius: 2,
        fill: 'start',
        data: sensor2Data,
        yAxisID: sensor2
      }]
  break; 
  case 'BarArea':
      datasets=[
        {
          type:'bar',
          label:sensor1,
          backgroundColor: 'rgb(54, 162, 235)',
          borderColor: 'rgb(54, 162, 235)',
          // borderWidth: 2,
          pointRadius: 2,
          fill: false,
          data: sensor1Data,
          yAxisID: sensor1
        },{
        type:'line',
        backgroundColor: 'rgb(245,168,192)',
        borderColor:'rgb(255, 99, 132)',
        data: sensor2Data,
        yAxisID: sensor2,
        pointRadius: 2,
        label: sensor2,
        fill: 'start' 
      }
    ]
    break;   
    default:
        datasets = [{
          type:'line',
          label:sensor1 ,
          backgroundColor: 'rgb(54, 162, 235)',
          borderColor: 'rgb(54, 162, 235)',
            // borderWidth: 2,
            pointRadius: 2,
            fill: false,
            data: sensor1Data,
            yAxisID: sensor1
        },
        {
          type:'bar',
            label:sensor2,
            backgroundColor: 'rgb(255, 99, 132)',
            borderColor:'rgb(255, 99, 132)',
              // borderWidth: 2,
              pointRadius: 2,
              fill: false,
              data: sensor2Data,
              yAxisID: sensor2
        }
      ]
    }
    return datasets
  }

  handleChange = (event) => {
    this.setState({[event.target.name]:event.target.value}) 
  }

  setFromDate =(date)=>{
    this.setState({fromdate:date})
  } 

  setToDate =(date)=>{
    this.setState({todate:date})
  }  

  handleTf = (event) => {
    this.setState({
      'Url':event.target.value,
      [event.target.name]:event.target.value
    })
  }

 render(){
  const treatmentFacility = this.props.treatmentListing.length && this.props.treatmentListing.filter( tf => {
    return tf.Url
  })
  return (
    <>
    <Dialog open={this.props.open} onClose={this.handleClose} aria-labelledby="form-dialog-title"
    fullWidth={true}
    maxWidth='md'
    >
    {this.state.loader && <div id="cover-spin"></div>}
    <p className={classes.DialogTitle}>Add New Graph</p>  
    <DialogContent>
    <div style={{ overflow: "hidden", height: "100%", width: "100%" }}>
      <Grid container justify="center" spacing={3} direction = "row">
      <Grid align ="center" item lg={4} xs={12}>
        <FormControl>
        <label className = {classes.DialogSubtitle}>Select Treatment Facility</label>
        <Select style={{border:'1px solid #4CAF50',color: '#4CAF50',textAlign: 'center'}} name='tfId' value= {this.state.tfId} placeholder="Select Tf"
           onChange = {this.handleTf}>
             {treatmentFacility && treatmentFacility.map( tf => {
               return <MenuItem key={tf.TFId} value={tf.TFId}>{tf.Name}</MenuItem>
             })}
        </Select>  
         <div style={{fontSize:12,color:"red", fontFamily:"'Open Sans', sans-serif", marginTop:"1%"}}>{ this.props.errorTreat}</div>
        </FormControl>
        </Grid>
      </Grid>
      <Grid container justify="center" spacing ={3} direction ="row">
        <Grid item xs={1}></Grid>
        <Grid align ="left" item xs={5} lg={2} style={{fontFamily: "Open sans-serif"}}>
        <label className = {classes.DialogSubtitle}>Select Sensor</label>
        <FormControlLabel style={{marginBottom:'2%'}}
        control={<Checkbox style ={{color: 'green',fontFamily: 'Open sans-serif'}} checked={this.state.checkedSensorPH} onChange={this.handleSensorPh} name="checkedSensorPH" />}
        label="Param PH"
        />
        </Grid>
        <Grid  align ="center" item xs={5} lg={2}>
        <FormControl>
        <label className = {classes.DialogSubtitle}>Chart Type</label>
          <Select disabled = {!this.state.checkedSensorPH} style={{border:'1px solid #4CAF50',color: '#4CAF50',textAlign: 'center',marginTop: '6%'}} value= {this.state.sensorPhType}  name="sensorPhType" placeholder="Select Chart Type"
           onChange = {this.handleSensorPh}>
            <MenuItem value={"Bar"}>{"Bar"}</MenuItem>
            <MenuItem value={"Line"}>{"Line"}</MenuItem>
            <MenuItem value={"Area"}>{"Area"}</MenuItem>
          </Select> 
        </FormControl>
        </Grid>
        <Grid item xs={1}>
          
        </Grid>
      </Grid>
  {!!this.props.responseData&&this.props.responseData.ParamValueBOD !== null? <Grid container justify="center" spacing ={3} direction ="row">
              <Grid item xs={1}></Grid>
              <Grid align ="left" item xs={5} lg={2} style={{fontFamily: "Open sans-serif"}}>
              <FormControlLabel
              control={<Checkbox style ={{color: 'green',fontFamily: 'Open sans-serif'}} checked={this.state.checkedSensorBOD} onChange={this.handleSensorBod} name="checkedSensorBOD" />}
              label="Param BOD"
              />
              </Grid>
              <Grid  align ="center" item xs={5} lg={2}>
              <FormControl>
                  {/* <label htmlFor="propType" align="left">Chart Type</label> */}
                <Select disabled = {!this.state.checkedSensorBOD} style={{border:'1px solid #4CAF50',color: '#4CAF50',textAlign: 'center'}} value= {this.state.sensorBODType}  name="sensorBODType" placeholder="Select Chart Type"
                 onChange = {this.handleSensorBod}>
                  <MenuItem value={"Bar"}>{"Bar"}</MenuItem>
                  <MenuItem value={"Line"}>{"Line"}</MenuItem>
                  <MenuItem value={"Area"}>{"Area"}</MenuItem>
                </Select> 
              </FormControl>
              </Grid>
              <Grid item xs={1}>
            </Grid>
          </Grid>:'' }
         
    {!!this.props.responseData&&this.props.responseData.ParamValueCOD !== null? <Grid container justify="center" spacing ={3} direction ="row">
        <Grid item xs={1}></Grid>
        <Grid align ="left" item xs={5} lg={2} style={{fontFamily: "Open sans-serif"}}>
        <FormControlLabel
        control={<Checkbox style ={{color: 'green',fontFamily: 'Open sans-serif'}} checked={this.state.checkedSensorCOD} onChange={this.handleSensorCod} name="checkedSensorCOD" />}
        label="Param COD"
        />
        </Grid>
        <Grid  align ="center" item xs={5} lg={2}>
        <FormControl>
            {/* <label htmlFor="propType" align="left">Chart Type</label> */}
          <Select disabled = {!this.state.checkedSensorCOD} style={{border:'1px solid #4CAF50',color: '#4CAF50',textAlign: 'center'}} value= {this.state.sensorCODType}  name="sensorCODType" placeholder="Select Chart Type"
           onChange = {this.handleSensorCod}>
            <MenuItem value={"Bar"}>{"Bar"}</MenuItem>
            <MenuItem value={"Line"}>{"Line"}</MenuItem>
            <MenuItem value={"Area"}>{"Area"}</MenuItem>
          </Select> 
        </FormControl>
        </Grid>
        <Grid item xs={1}>
      </Grid>
    </Grid>:''}
         
    {!!this.props.responseData&&this.props.responseData.ParamValueFLRate !== null?  <Grid container justify="center" spacing ={3} direction ="row">
        <Grid item xs={1}></Grid>
        <Grid align ="left" item xs={5} lg={2} style={{fontFamily: "Open sans-serif"}}>
        <FormControlLabel
        control={<Checkbox style ={{color: 'green',fontFamily: 'Open sans-serif'}} checked={this.state.checkedSensorFLRate} onChange={this.handleSensorFLRate} name="checkedSensorFLRate" />}
        label="Param FLRate"
        />
        </Grid>
        <Grid  align ="center" item xs={5} lg={2}>
        <FormControl>
            {/* <label htmlFor="propType" align="left">Chart Type</label> */}
          <Select disabled = {!this.state.checkedSensorFLRate} style={{border:'1px solid #4CAF50',color: '#4CAF50',textAlign: 'center'}} value= {this.state.sensorFLRateType}  name="sensorFLRateType" placeholder="Select Chart Type"
           onChange = {this.handleSensorFLRate}>
            <MenuItem value={"Bar"}>{"Bar"}</MenuItem>
            <MenuItem value={"Line"}>{"Line"}</MenuItem>
            <MenuItem value={"Area"}>{"Area"}</MenuItem>
          </Select> 
        </FormControl>
        </Grid>
        <Grid item xs={1}>
      </Grid>
    </Grid>:''}
   
     {!!this.props.responseData&&this.props.responseData.ParamValueNO3 !== null? <Grid container justify="center" spacing ={3} direction ="row">
        <Grid item xs={1}></Grid>
        <Grid align ="left" item xs={5} lg={2}style={{fontFamily: "Open sans-serif"}}>
        <FormControlLabel
        control={<Checkbox style ={{color: 'green',fontFamily: 'Open sans-serif'}} checked={this.state.checkedSensorNO3} onChange={this.handleSensorNO3} name="checkedSensorNO3" />}
        label="Param NO3"
        />
        </Grid>
        <Grid  align ="center" item xs={5} lg={2}>
        <FormControl>
            {/* <label htmlFor="propType" align="left">Chart Type</label> */}
          <Select disabled = {!this.state.checkedSensorNO3} style={{border:'1px solid #4CAF50',color: '#4CAF50',textAlign: 'center'}} value= {this.state.sensorNO3Type}  name="sensorNO3Type" placeholder="Select Chart Type"
           onChange = {this.handleSensorNO3}>
            <MenuItem value={"Bar"}>{"Bar"}</MenuItem>
            <MenuItem value={"Line"}>{"Line"}</MenuItem>
            <MenuItem value={"Area"}>{"Area"}</MenuItem>
          </Select> 
        </FormControl>
        </Grid>
        <Grid item xs={1}>
      </Grid>
    </Grid>:''}
    
 {!!this.props.responseData&&this.props.responseData.ParamValueORP !== null?     <Grid container justify="center" spacing ={3} direction ="row">
        <Grid item xs={1}></Grid>
        <Grid align ="left" item xs={5} lg={2} style={{fontFamily: "Open sans-serif"}}>
        <FormControlLabel
        control={<Checkbox style ={{color: 'green',fontFamily: 'Open sans-serif'}} checked={this.state.checkedSensorORP} onChange={this.handleSensorORP} name="checkedSensorORP" />}
        label="Param ORP"
        />
        </Grid>
        <Grid  align ="center" item xs={5} lg={2}>
        <FormControl>
            {/* <label htmlFor="propType" align="left">Chart Type</label> */}
          <Select disabled = {!this.state.checkedSensorORP} style={{border:'1px solid #4CAF50',color: '#4CAF50',textAlign: 'center'}} value= {this.state.sensorORPType}  name="sensorORPType" placeholder="Select Chart Type"
           onChange = {this.handleSensorORP}>
            <MenuItem value={"Bar"}>{"Bar"}</MenuItem>
            <MenuItem value={"Line"}>{"Line"}</MenuItem>
            <MenuItem value={"Area"}>{"Area"}</MenuItem>
          </Select> 
        </FormControl>
        </Grid>
        <Grid item xs={1}>
      </Grid>
    </Grid>:''}

  {!!this.props.responseData&&this.props.responseData.ParamValueTFLRate !== null? <Grid container justify="center" spacing ={3} direction ="row">
        <Grid item xs={1}></Grid>
        <Grid align ="left" item xs={5} lg={2} style={{fontFamily: "Open sans-serif"}}>
        <FormControlLabel
        control={<Checkbox style ={{color: 'green',fontFamily: 'Open sans-serif'}} checked={this.state.checkedSensorTFLRate} onChange={this.handleSensorTFLRate} name="checkedSensorTFLRate" />}
        label="Param TFLRate"
        />
        </Grid>
        <Grid  align ="center" item xs={5} lg={2}>
        <FormControl>
            {/* <label htmlFor="propType" align="left">Chart Type</label> */}
          <Select disabled = {!this.state.checkedSensorTFLRate} style={{border:'1px solid #4CAF50',color: '#4CAF50',textAlign: 'center'}} value= {this.state.sensorTFLRateType}  name="sensorTFLRateType" placeholder="Select Chart Type"
           onChange = {this.handleSensorTFLRate}>
            <MenuItem value={"Bar"}>{"Bar"}</MenuItem>
            <MenuItem value={"Line"}>{"Line"}</MenuItem>
            <MenuItem value={"Area"}>{"Area"}</MenuItem>
          </Select> 
        </FormControl>
        </Grid>
        <Grid item xs={1}>
      </Grid>
    </Grid>:'' }
   
  {!!this.props.responseData&&this.props.responseData.ParamValueTSS !== null?   <Grid container justify="center" spacing ={3} direction ="row">
        <Grid item xs={1}></Grid>
        <Grid align ="left" item xs={5} lg={2} style={{fontFamily: "Open sans-serif"}}>
        <FormControlLabel
        control={<Checkbox style ={{color: 'green',fontFamily: 'Open sans-serif'}} checked={this.state.checkedSensorTSS} onChange={this.handleSensorTSS} name="checkedSensorTSS" />}
        label="Param TSS"
        />
        </Grid>
        <Grid  align ="center" item xs={5} lg={2}>
        <FormControl>
            {/* <label htmlFor="propType" align="left">Chart Type</label> */}
          <Select disabled = {!this.state.checkedSensorTSS} style={{border:'1px solid #4CAF50',color: '#4CAF50',textAlign: 'center'}} value= {this.state.sensorTSSType}  name="sensorTSSType" placeholder="Select Chart Type"
           onChange = {this.handleSensorTSS}>
            <MenuItem value={"Bar"}>{"Bar"}</MenuItem>
            <MenuItem value={"Line"}>{"Line"}</MenuItem>
            <MenuItem value={"Area"}>{"Area"}</MenuItem>
          </Select> 
        </FormControl>
        </Grid>
        <Grid item xs={1}>
      </Grid>
    </Grid>:''}
  
</div>
</DialogContent>
    <DialogActions>
    <div style={{ overflow: "hidden", height: "100%", width: "100%" ,marginTop:"4%"}}>
    <Grid container justify="center" spacing ={3} direction ="row">
      <Grid item xs={6} align="right" style={{width:"50%"}}>
      <Button style ={{color:"#4CAF50", width: 'inherit',marginBottom:"5%"}} variant="outlined" onClick={this.handleClose}>
        Cancel
      </Button>
      </Grid>
      <Grid item xs={6} align="left" style={{width:"50%"}}>
      <Button style ={{color:"#ffffff",backgroundColor:"#4CAF50",width: 'inherit'}} variant="contained" onClick={this.createChart}>
        Save
      </Button>
      </Grid>
      </Grid>
    </div>  
    </DialogActions>
  </Dialog> 
    </>
  );
 }
 
}

const mapStateToProps = (state) => {
  return{
      iotChartData:state.iotChartData.iotChartData,
      metaData : state.iotChartData.metaData,
      treatmentListing:state.treatmentListing.treatmentListing,
      errorTreat: state.treatmentListing.errorTreat,
      iotTableData:state.iotChartData.iotTableData,
  }
}

const mapDispatchToProps = (dispatch)=> {
  return{
    addChart : (payload,ulbid) => dispatch(actionCreators.addChartData(payload,ulbid)),
    addChartSD : (payload,ulbId) => dispatch(actionCreators.addChartDataSD(payload,ulbId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddChartDialog)
