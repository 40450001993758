import React, { Component } from 'react'
import { connect } from 'react-redux';
import {Link, Switch} from 'react-router-dom'

import * as actionsCreators from  '../../store/actions/index';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import axios from '../../http-config';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment'
import MenuItem from '@material-ui/core/MenuItem';
import { DatePicker , MuiPickersUtilsProvider} from "@material-ui/pickers";
import Select from '@material-ui/core/Select';
import DateFnsUtils from '@date-io/date-fns';
import swal from 'sweetalert';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid, Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import ReactPaginate from 'react-paginate';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Fade from '@material-ui/core/Fade';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Card from "../Dashboard/Card/Card.js"
import CardHeader from "../Dashboard/Card/CardHeader.js";
import CardBody from '../Dashboard/Card/CardBody.js'
import clsx from 'clsx';
const BootstrapInput = withStyles((theme) => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor:"#fff",
      border: '1px solid #4CAF50',
      fontSize: 15,
      padding: '7px 26px 7px 20px',
      transition: theme.transitions.create(['border-color']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        'Open Sans',
        'sans-serif',
   
      ].join(','),
      '&:focus ': {
        borderRadius: 4,
            backgroundColor:"#fff !important"
   
      }
    },
  }))(InputBase);
var timer

class PropertyHistory extends Component {
    constructor(props){
        super(props)
        // var curr = new Date; // get current date
        // var first = (curr.getDate() - curr.getDay())+1; // First day is the day of the month - the day of the week
        // var firstdayweek =moment( new Date(curr.setDate(first)).toISOString()).format('MM-DD-YYYY');
        // var lastdayweek = moment(new Date(curr.setDate(curr.getDate()+6)).toISOString()).format('MM-DD-YYYY');
        this.state= {
            limit : 10,
            activePage : 0,
            page:1,
            fromDate: new Date().setMonth(new Date().getMonth() - 2),
            toDate: new Date(),
            currentSortId:'',
            sortObj : {
                key: '',
                value: ''
            },
            controls: {
                search: {
                    value: '',
                }
            },
            filterValue : '',
            filterValueStatus:'',
            weekDate:'',
            schedulePropertyCount: 0,
            CollectedPropertiesCount: 0,
            CollectedLoadsCount: 0,
            DisposedLoadsCount:0,
            ExpiredPropertiesCount:0,
            RefusedPropertiesCount:0,
            cancleProperties:0,
            inputValue:''
        }
    }


    componentDidMount(){
        let payload ={
            token : this.props.token,
            limit : this.state.limit,
            page : this.state.page,
            fromDate:moment(this.state.fromDate).format('YYYY-MM-DD'),
            toDate:moment(this.state.toDate).format('YYYY-MM-DD'),
            sortName:this.state.sortObj.key,
            sortValue :this.state.sortObj.value,
            searchQuery: this.state.controls.search.value,
            propertyTypeId: this.state.filterValue,
            status: this.state.filterValueStatus
        }
        this.props.onFetchPropertyHistoryList(payload)
        this.props.onFetchStatusHistory(payload)
        this.props.onPropertyType()
    }

 
    handlePageClick = (e) => {
        this.setState({
            activePage : e.selected,
            page : e.selected+1
        })
        const selectedPage = e.selected;
        let payload = {
            limit: this.state.limit,
            token: this.props.token,
            page:  selectedPage +1 ,
            fromDate:moment(this.state.fromDate).format('YYYY-MM-DD'),
            toDate:moment(this.state.toDate).format('YYYY-MM-DD'),
            sortName:this.state.sortObj.key,
            sortValue :this.state.sortObj.value,
            searchQuery: this.state.controls.search.value,
            propertyTypeId: this.state.filterValue,
            status: this.state.filterValueStatus
        }
        this.props.onFetchPropertyHistoryList(payload)
    };
    handleWeek = (e) => {
        this.setState({weekDate:e.target.value})
            this.setFromDate(new Date( e.target.value))
            const toweekDate = moment(new Date(e.target.value)).add(6, 'days').format('MM-DD-YYYY')
            this.setToDate(new Date(toweekDate))
            this.setState({fromDate : new Date( e.target.value)})
            this.setState({toDate: new Date(toweekDate)})
      
        }

    setFromDate =(date)=>{
        this.setState({fromDate:date,activePage:0}, () => {
            let payload = {
                token: this.props.token,
                page:this.state.page,
                limit:this.state.limit,
                fromDate:moment(this.state.fromDate).format('YYYY-MM-DD'),
                toDate:moment(this.state.toDate).format('YYYY-MM-DD'),
                sortName:this.state.sortObj.key,
                sortValue :this.state.sortObj.value,
                searchQuery: this.state.controls.search.value,
                propertyTypeId: this.state.filterValue,
                status: this.state.filterValueStatus
            }
            this.props.onFetchStatusHistory(payload)
            this.props.onFetchPropertyHistoryList(payload)
            
        })
    } 

    setToDate =(date)=>{
        this.setState({toDate:date,activePage:0}, () => {
            let payload = {
                page:this.state.page,
                limit:this.state.limit,
                token: this.props.token,
                fromDate:moment(this.state.fromDate).format('YYYY-MM-DD'),
                toDate:moment(this.state.toDate).format('YYYY-MM-DD'),
                sortName:this.state.sortObj.key,
                sortValue :this.state.sortObj.value,
                searchQuery: this.state.controls.search.value,
                propertyTypeId: this.state.filterValue,
                status: this.state.filterValueStatus
            }
            this.props.onFetchStatusHistory(payload)
            this.props.onFetchPropertyHistoryList(payload)
            
        })
    }
    handleSort = (e,sortBy) => {
        let sortObj
        if (this.state.sortObj.key === sortBy.accessor) {
            let value = (this.state.sortObj.value === 'desc') ? 'asc' : 'desc';
            sortObj = {
                key : sortBy.accessor,
                value: value
            }
        } 
        else {
            sortObj = {
                key : sortBy.accessor,
                value: 'desc'
            }    
        }
        this.setState({ sortObj: sortObj ,currentSortId:sortBy.accessor});
        let payload ={
            token : this.props.token,
            limit : this.state.limit,
            page : this.state.page,
            fromDate:moment(this.state.fromDate).format('YYYY-MM-DD'),
            toDate:moment(this.state.toDate).format('YYYY-MM-DD'),
            searchQuery: this.state.controls.search.value,
            propertyTypeId: this.state.filterValue,
            status: this.state.filterValueStatus,
            sortName: sortObj.key,
            sortValue : sortObj.value,
          
        }
        this.props.onFetchPropertyHistoryList(payload)
      }

      handleExport = () => {
        let payload = {
            page:this.state.page,
            limit:this.state.limit,
            token: this.props.token,
            fromDate:moment(this.state.fromDate).format('YYYY-MM-DD'),
            toDate:moment(this.state.toDate).format('YYYY-MM-DD'),
            sortName:this.state.sortObj.key,
            sortValue :this.state.sortObj.value,
            searchQuery: this.state.controls.search.value,
            propertyTypeId: this.state.filterValue,
            status: this.state.filterValueStatus
        }
        this.props.onExportHistory(payload)
    }


    handlePropertySearch = (e) => {
        e.preventDefault()
        let searchVal = this.state.controls.search.value
        this.setState({inputValue: searchVal})
        if (!!searchVal && searchVal.length >= 2) {
            let payload = {
                token : this.props.token,
                limit : this.state.limit,
                page : this.state.page,
                fromDate:moment(this.state.fromDate).format('YYYY-MM-DD'),
                toDate:moment(this.state.toDate).format('YYYY-MM-DD'),
                sortName:this.state.sortObj.key,
                sortValue :this.state.sortObj.value,
                searchQuery: this.state.controls.search.value,
                propertyTypeId: this.state.filterValue,
                status: this.state.filterValueStatus
              
            }
            this.props.onFetchStatusHistory(payload)
            this.props.onSearchProperty(payload) 
        } else {
         
            swal("Enter atleast 2 characters")
        }
    }
    inputChangedHadler = (event, controlName) => {
        const updatedControls = {
            ...this.state.controls,
            [controlName]: {
                ...this.state.controls[controlName],
                value: event.target.value,
            }
        }
        this.setState({ controls: updatedControls });
        if( updatedControls.search.value !== ''){
            let payload = {
                token: this.props.token,
                searchQuery:updatedControls.search.value,
                limit:this.state.limit,
                page:this.state.page,
                propertyTypeId: this.state.filterValue,
                sortName:this.state.sortObj.key,
                sortValue :this.state.sortObj.value,
                status: this.state.filterValueStatus,
                fromDate:moment(this.state.fromDate).format('YYYY-MM-DD'),
                toDate:moment(this.state.toDate).format('YYYY-MM-DD'),
                
            }
            this.props.onFetchStatusHistory(payload)
            this.props.onSearchProperty(payload) 
        }
        if( updatedControls.search.value === ''){
            let payload = {
                token: this.props.token,
                searchQuery:'',
                limit:this.state.limit,
                page:this.state.page,
                propertyTypeId: this.state.filterValue,
                sortName:this.state.sortObj.key,
                sortValue :this.state.sortObj.value,
                status: this.state.filterValueStatus,
                fromDate:moment(this.state.fromDate).format('YYYY-MM-DD'),
                toDate:moment(this.state.toDate).format('YYYY-MM-DD'),
                
            }
            // this.props.onFetchStatusHistory(payload)
            this.props.onSearchProperty(payload) 
        }
      
    }
    onClickGetFilterVals = (e, colObj) => { 
        this.setState({filterValue: e})
    let payload = {
        page:this.state.page,
        limit:this.state.limit,
        token: this.props.token,
        fromDate:moment(this.state.fromDate).format('YYYY-MM-DD'),
        toDate:moment(this.state.toDate).format('YYYY-MM-DD'),
        sortName:this.state.sortObj.key,
        sortValue :this.state.sortObj.value,
        searchQuery: this.state.controls.search.value,
        propertyTypeId: e,
        status: this.state.filterValueStatus
    }
    this.props.onFetchPropertyHistoryList(payload)
    }   
    onClickGetFilterValsStatus=(e )=>{
        this.setState({filterValueStatus: e})
        let payload = {
            page:this.state.page,
            limit:this.state.limit,
            token: this.props.token,
            fromDate:moment(this.state.fromDate).format('YYYY-MM-DD'),
            toDate:moment(this.state.toDate).format('YYYY-MM-DD'),
            sortName:this.state.sortObj.key,
            sortValue :this.state.sortObj.value,
            searchQuery: this.state.controls.search.value,
            propertyTypeId: this.state.filterValue,
            status: e
        }
        this.props.onFetchPropertyHistoryList(payload)
    }

    onClickRemoveFilter = ()=>{
        let payload ={
            token : this.props.token,
            limit : this.state.limit,
            page : 1,
            fromDate:moment(new Date().setMonth(new Date().getMonth() - 2)).format('YYYY-MM-DD'),
            toDate:moment(this.state.toDate).format('YYYY-MM-DD'),
            sortName:"",
            sortValue :'',
            searchQuery: '',
            propertyTypeId: '',
            status: ''
        }
        this.props.onFetchPropertyHistoryList(payload)
        this.props.onFetchStatusHistory(payload)
        this.setState({
            searchQuery:'', 
            controls:{
                search: {
                    value: '',
                         }
             },
            filterValue:'', 
            sortObj:{
                key: '',
                value: ''
            },
            fromDate: new Date().setMonth(new Date().getMonth() - 2) ,
            toDate: new Date(),
            filterValueStatus:''        
        })
    }
    render(){
    var curr = new Date; // get current date
    var first = (curr.getDate() - curr.getDay())+1; // First day is the day of the month - the day of the week
    var last = first + 6; // last day is the first day + 6

    var firstdayweek =moment( new Date(curr.setDate(first)).toISOString()).format('MM-DD-YYYY');
    var lastdayweek = moment(new Date(curr.setDate(last)).toISOString()).format('MM-DD-YYYY');

    var secondlastdayweek =moment( new Date(curr.setDate(new Date(firstdayweek).getDate()-1)).toISOString()).format('MM-DD-YYYY');
    var secondfirstdayweek =moment( new Date(curr.setDate(new Date(secondlastdayweek).getDate()-6)).toISOString()).format('MM-DD-YYYY');

    var thirdlastdayweek = moment(new Date(curr.setDate(new Date(secondfirstdayweek).getDate()-1)).toISOString()).format('MM-DD-YYYY');
    var thirdfirstdayweek =moment( new Date(curr.setDate(new Date(thirdlastdayweek).getDate()-6)).toISOString()).format('MM-DD-YYYY');

    var fourthlastdayweek = moment(new Date(curr.setDate(new Date(thirdfirstdayweek).getDate()-1)).toISOString()).format('MM-DD-YYYY');
    var fourthfirstdayweek =moment( new Date(curr.setDate(new Date(fourthlastdayweek).getDate()-6)).toISOString()).format('MM-DD-YYYY');
    let  propertiesHistory = !!this.props.propertyListing&&this.props.propertyListing
    // if(this.state.sortObj.value === 'ASC'){
    //     switch(this.state.sortObj.key){
    //         case "sr.no":
    //             let sortNo =this.props.propertyListing.sort(function(a, b) {
    //                 return a["sr.no"] - b["sr.no"];
    //             });
    //            propertiesHistory = sortNo
    //         break
    //         case "ward":
    //             let sortward =this.props.propertyListing.sort(function(a, b) {
    //                 return a.ward - b.ward;
    //             });
    //           propertiesHistory = sortward
    //          break
    //          case "zone":
    //             let sortZone =this.props.propertyListing.sort(function(a, b) {
    //                 return (a.zone > b.zone) ? 1 : (a.zone === b.zone) ? ((a.zone > b.zone) ? 1 : -1) : -1 
    //             });
    //            propertiesHistory = sortZone
    //           break
    //         case "propertyName":
    //             let sortpropertyName =this.props.propertyListing.sort(function(a, b) {
                   
    //                 return  (a.propertyName > b.propertyName) ? 1 : (a.propertyName === b.propertyName) ? ((a.propertyName > b.propertyName) ? 1 : -1) : -1 
    //             });
    //             propertiesHistory = sortpropertyName
    //             break
    //         case "propertyType":
    //             let sortType =this.props.propertyListing.sort(function(a, b) {
    //                 return  (a.type > b.type) ? 1 : (a.type === b.type) ? ((a.type > b.type) ? 1 : -1) : -1 
    //             });
    //             propertiesHistory = sortType
    //             break    
    //         case "scheduledDate":
    //             let sortScheduledDate =this.props.propertyListing.sort(function(a, b) {
    //                 return new Date(!!a&& !!a.scheduledDate? a.scheduledDate.split('/').reverse():a.scheduledDate) - new Date(!!b&& !!b.scheduledDate? b.scheduledDate.split('/').reverse():b.scheduledDate)
    //             });
    //             propertiesHistory = sortScheduledDate
    //             break

    //         case "refusedDate":
    //             let sortrefusedDate =this.props.propertyListing.sort(function(a, b) {
    //                 return new Date(!!a&& !!a.refusedDate? a.refusedDate.split('/').reverse():a.refusedDate) - new Date(!!b&& !!b.refusedDate? b.refusedDate.split('/').reverse():b.refusedDate)
    //             });
    //             propertiesHistory = sortrefusedDate
    //             break

    //         case "expiredDate":
    //             let sotexpiredDate =this.props.propertyListing.sort(function(a, b) {
    //                 return  new Date(!!b&& !!b.expiredDate? b.expiredDate.split('/').reverse():b.expiredDate) - new Date(!!a&& !!a.expiredDate? a.expiredDate.split('/').reverse():a.expiredDate)
    //             });
    //             propertiesHistory = sotexpiredDate
    //             break  
    //         case "cancelledDate":
    //             let sotcancelledDate =this.props.propertyListing.sort(function(a, b) {
    //                 return  new Date(!!b&& !!b.cancelledDate? b.cancelledDate.split('/').reverse():b.cancelledDate) - new Date(!!a&& !!a.cancelledDate? a.cancelledDate.split('/').reverse():a.cancelledDate)
    //             });
    //             propertiesHistory = sotcancelledDate
    //             break  
    //         case "collectionDate":
    //             let sortcollectionDate =this.props.propertyListing.sort(function(a, b) {
    //                 return new Date(!!a&& !!a.collectionDate? a.collectionDate.split('/').reverse():a.collectionDate) - new Date(!!b&& !!b.collectionDate? b.collectionDate.split('/').reverse():b.collectionDate)
    //             });
    //             propertiesHistory = sortcollectionDate
    //             break
    //         case "disposedDate":
    //             let sortDisposedDate =this.props.propertyListing.sort(function(a, b) {
    //                 return new Date(!!a&& !!a.disposedDate? a.disposedDate.split('/').reverse():a.disposedDate) - new Date(!!b&& !!b.disposedDate? b.disposedDate.split('/').reverse():b.disposedDate)
    //             });
    //             propertiesHistory = sortDisposedDate
    //             break
    //         case  "operatorName":
    //             let sortOperatorName =this.props.propertyListing.sort(function(a, b) {
    //                 return  (a.operatorName > b.operatorName) ? 1 : (a.operatorName === b.operatorName) ? ((a.operatorName > b.operatorName) ? 1 : -1) : -1 
    //             });
    //             propertiesHistory = sortOperatorName
    //             break
    //         case "truckNo":
    //             let sorttruckNo =this.props.propertyListing.sort(function(a, b) {
    //                 return  (a.truckNo > b.truckNo) ? 1 : (a.truckNo === b.truckNo) ? ((a.truckNo > b.truckNo) ? 1 : -1) : -1 
    //             });
    //             propertiesHistory = sorttruckNo
    //             break
    //         case "tfName":
    //             let sorttfName =this.props.propertyListing.sort(function(a, b) {
    //                 return  (a.tfName > b.tfName) ? 1 : (a.tfName === b.tfName) ? ((a.tfName > b.tfName) ? 1 : -1) : -1 
    //             });
    //             propertiesHistory = sorttfName
    //             break
    //         case "GPSLocation":
    //             let sortcoordinates =this.props.propertyListing.sort(function(a, b) {
    //                 return (a.coordinates > b.coordinates) ? 1 : (a.coordinates === b.coordinates) ? ((a.coordinates > b.coordinates) ? 1 : -1) : -1 
    //             });
    //             propertiesHistory = sortcoordinates
    //             break
    //         case "status":
    //             let sortstatus =this.props.propertyListing.sort(function(a, b) {
    //                 return (a.status > b.status) ? 1 : (a.status === b.status) ? ((a.status > b.status) ? 1 : -1) : -1 
    //             });
    //             propertiesHistory = sortstatus
    //             break
    //         case "serviceStatus":
    //             let sortserviceStatus =this.props.propertyListing.sort(function(a, b) {
    //                 return (a.serviceStatus > b.serviceStatus) ? 1 : (a.serviceStatus === b.serviceStatus) ? ((a.serviceStatus > b.serviceStatus) ? 1 : -1) : -1 
    //             });
    //             propertiesHistory = sortserviceStatus
    //             break
    //         default:
    //             break;
    //     }

    //     }
     
        // if(this.state.sortObj.value === 'DESC'){
        //     switch (this.state.sortObj.key) {
        //         case "sr.no":
        //             let sortNo =this.props.propertyListing.sort(function(a, b) {
        //                 return b["sr.no"] - a["sr.no"];
        //             });
        //             propertiesHistory = sortNo
        //             break;
        //         case "ward":
        //             let sortward =this.props.propertyListing.sort(function(a, b) {
        //                 return b.ward - a.ward;
        //             });
        //             propertiesHistory = sortward
        //             break
        //         case "zone":
        //             let sortZone =this.props.propertyListing.sort(function(a, b) {
        //                 return  (b.zone > a.zone) ? 1 : (b.zone === a.zone) ? ((b.zone > a.zone) ? 1 : -1) : -1 
        //             });
        //             propertiesHistory = sortZone
        //             break
        //         case  "propertyName":
        //             let sortpropertyName =this.props.propertyListing.sort(function(a, b) {
        //                 return  (b.propertyName > a.propertyName) ? 1 : (b.propertyName === a.propertyName) ? ((b.propertyName > a.propertyName) ? 1 : -1) : -1 
        //             });
        //             propertiesHistory = sortpropertyName
        //             break
            
        //         case "propertyType":
        //             let sortType =this.props.propertyListing.sort(function(a, b) {
        //                 return  (b.type > a.type) ? 1 : (b.type === a.type) ? ((b.type > a.type) ? 1 : -1) : -1 
        //             });
        //             propertiesHistory = sortType
        //             break
        //         case "scheduledDate":
        //             let sotward =this.props.propertyListing.sort(function(a, b) {
        //                 return  new Date(!!b&& !!b.scheduledDate? b.scheduledDate.split('/').reverse():b.scheduledDate) - new Date(!!a&& !!a.scheduledDate? a.scheduledDate.split('/').reverse():a.scheduledDate)
        //             });
        //             propertiesHistory = sotward
        //             break
        //         case "refusedDate":
        //             let sotRefused =this.props.propertyListing.sort(function(a, b) {
        //                 return  new Date(!!b&& !!b.refusedDate? b.refusedDate.split('/').reverse():b.refusedDate) - new Date(!!a&& !!a.refusedDate? a.refusedDate.split('/').reverse():a.refusedDate)
        //             });
        //             propertiesHistory = sotRefused
        //             break      

        //         case "expiredDate":
        //             let sotexpiredDate =this.props.propertyListing.sort(function(a, b) {
        //                 return  new Date(!!b&& !!b.expiredDate? b.expiredDate.split('/').reverse():b.expiredDate) - new Date(!!a&& !!a.expiredDate? a.expiredDate.split('/').reverse():a.expiredDate)
        //             });
        //             propertiesHistory = sotexpiredDate
        //             break      
        //         case "cancelledDate":
        //             let sotcancelledDate =this.props.propertyListing.sort(function(a, b) {
        //                 return  new Date(!!b&& !!b.cancelledDate? b.cancelledDate.split('/').reverse():b.cancelledDate) - new Date(!!a&& !!a.cancelledDate? a.cancelledDate.split('/').reverse():a.cancelledDate)
        //             });
        //             propertiesHistory = sotcancelledDate
        //             break      
        //         case "collectionDate":
        //             let sortcollectionDate =this.props.propertyListing.sort(function(a, b) {
        //                 return  new Date(!!b&& !!b.collectionDate? b.collectionDate.split('/').reverse():b.collectionDate) - new Date(!!a&& !!a.collectionDate? a.collectionDate.split('/').reverse():a.collectionDate)
        //             });
        //             propertiesHistory = sortcollectionDate
        //             break
        //         case  "disposedDate":
        //             let sortDisposedDate =this.props.propertyListing.sort(function(a, b) {
        //                 return new Date(!!b&& !!b.disposedDate? b.disposedDate.split('/').reverse():b.disposedDate) - new Date(!!a&& !!a.disposedDate? a.disposedDate.split('/').reverse():a.disposedDate)
        //             });
        //             propertiesHistory = sortDisposedDate
        //             break
        //         case "operatorName":
        //             let sortOperatorName =this.props.propertyListing.sort(function(a, b) {
        //                 return  (b.operatorName > a.operatorName) ? 1 : (b.operatorName === a.operatorName) ? ((b.operatorName > a.operatorName) ? 1 : -1) : -1 
        //             });
        //             propertiesHistory = sortOperatorName
        //             break
        //         case  "truckNo":
        //             let sorttruckNo =this.props.propertyListing.sort(function(a, b) {
        //                 return  (b.truckNo > a.truckNo) ? 1 : (b.truckNo === a.truckNo) ? ((b.truckNo > a.truckNo) ? 1 : -1) : -1 
        //             });
        //             propertiesHistory = sorttruckNo
        //             break
        //         case "tfName":
        //             let sorttfName =this.props.propertyListing.sort(function(a, b) {
        //                 return  (b.tfName > a.tfName) ? 1 : (b.tfName === a.tfName) ? ((b.tfName > a.tfName) ? 1 : -1) : -1 
        //             });
        //             propertiesHistory = sorttfName
        //             break  
        //         case "GPSLocation":
        //             let sortcoordinates =this.props.propertyListing.sort(function(a, b) {
        //                 return (b.coordinates > a.coordinates) ? 1 : (b.coordinates === a.coordinates) ? ((b.coordinates > a.coordinates) ? 1 : -1) : -1 
        //             });
        //             propertiesHistory = sortcoordinates
        //             break
        //         case "status":
        //             let sortstatus =this.props.propertyListing.sort(function(a, b) {
        //                 return (b.status > a.status) ? 1 : (b.status === a.status) ? ((b.status > a.status) ? 1 : -1) : -1 
        //             });
        //             propertiesHistory = sortstatus
        //             break
        //         case  "serviceStatus":
        //             let sortserviceStatus =this.props.propertyListing.sort(function(a, b) {
        //                 return (b.serviceStatus > a.serviceStatus) ? 1 : (b.serviceStatus === a.serviceStatus) ? ((b.serviceStatus > a.serviceStatus) ? 1 : -1) : -1 
        //             });
        //             propertiesHistory = sortserviceStatus
        //             break
        //         default:
        //             break;
        //     }
        // }
        const summaryHeaders = [
            {
                accessor: "id",
                filter: "false",
                header: "Sr.No",
                sortable: "false",
            },
            {
                accessor: "ward",
                filter: "false",
                header: "Ward",
                sortable: "false",
            },
            {
                accessor: "zone",
                filter: "Zone",
                header: "Zone",
                sortable: "false",
            },
            {
                accessor: "propertyName",
                filter: "false",
                header: "Property Name",
                sortable: "false",
            },
            {
                accessor: "propertyType",
                filter: "true",
                header: "Property Type",
                sortable: "false",
            },
            {
                accessor: "scheduledDate",
                filter: "false",
                header: "Scheduled Date",
                sortable: "false",
            },
            // {
            //     accessor: "scheduledTime",
            //     filter: "false",
            //     header: "Scheduled Time",
            //     sortable: "false",
            // }, 
            {
                accessor: "collectionDate",
                filter: "false",
                header: "Collection Date",
                sortable: "false",
            },
            {
                accessor: "collectionTime",
                filter: "false",
                header: "Collection Time",
                sortable: "false",
            }, 
            {
                accessor:"disposedDate",
                filter: "false",
                header: "Disposed Date",
                sortable: "false",
            },
            {
                accessor:"disposedTime",
                filter: "false",
                header: "Disposed Time",
                sortable: "false",
            },
            { header: "Refused Date", 
            accessor: "refusedDate",  
            filter: "false", 
            sortable: "false"
           },
           { header: "Refused Time", 
            accessor: "refusedTime", 
            filter: "false", 
            sortable: "false"
           },
           { 
           header: "Cancelled Date", 
           accessor: "cancelledDate", 
            filter: "false", 
            sortable: "false"
           },
           { header: "Cancelled Time", 
           accessor: "cancelledTime",
            filter: "false",
             sortable: "false", 
           },
           { header: "Expired Date", 
           accessor: "expiredDate", 
            filter: "false",
             sortable: "false"
           },
           { header: "Expired Time", 
           accessor: "expiredTime", 
           filter: "false",
            sortable: "false"
           },
            {
                accessor: "operatorName",
                filter: "false",
                header: "Operator Name",
                sortable: "false",
            },
            {
                accessor: "truckNo",
                filter: "false",
                header: "Truck No",
                sortable: "false",
            },
            {
                accessor: "tfName",
                filter: "false",
                header: "TF Name",
                sortable: "false",
            },
            {
                accessor: "GPSLocation",
                filter: "false",
                header: "GPS Location",
                sortable: "false",
            },
            {
                accessor:"status",
                filter:"false",
                header:"Status",
                sortable:"false"
            },
            {
                accessor:"CreatedBy",
                filter:"false",
                header:"Created By",
                sortable:"false"
            },
            
            {
                accessor:"serviceStatus",
                filter:"false",
                header:"Service Status",
                sortable:"false"
            }
          
           
        ]
         
    
        let updatedHeaders = summaryHeaders
        let filterValue
        let  filterValueStatus;
        const propertTypeLists = this.props.Type
        const selectData = !!propertTypeLists&&propertTypeLists.map((item,i)=>{
              return  <option key={i} value={item.Id}>
                    {item.Name}
                </option>
                
        })
      
        const headers =  !!updatedHeaders&&updatedHeaders.map(header => {   
            if(header.accessor == "propertyType"){
                header.header = 'Property Type'
                header.filter = true
            }  
            if(header.accessor == "status"){
                header.header = 'Status'
                header.filter = true
            }  
            let filterType
            let StatusType
            if(header.filter == true &&header.accessor == "propertyType" ){
                filterType = 
                <select 
                style={{
                    width: '16px',
                    borderColor:"white",
                    backgroundColor: 'LightGray',
                    outline:"none",
                    display: "flex",
                    alignItems: 'right',   
                  }}
                value={filterValue}
                onChange={(e) => {
                    this.onClickGetFilterVals(e.target.value, header)
                }}
                >
                    <option value="">All</option>
                    {selectData}
                </select>
            }  

            if(header.filter == true &&header.accessor == "status" ){
                StatusType = 
            <select 
            style={{
                width: '16px',
                borderColor:"white",
                backgroundColor: 'LightGray',
                outline:"none",
                display: "flex",
                alignItems: 'right',   
              }}
            value={filterValueStatus}
            onChange={(e) => {
                this.onClickGetFilterValsStatus(e.target.value, header)
            }}
            >           <option value="">All</option>
                       <option value="scheduled">Scheduled</option>
                       <option value="collected">Collected</option>
                       <option value="expired">Expired</option>
                       <option value="refused">Refused</option>
                       <option value="cancelled">Cancelled</option>
                       <option value="disposed">Disposed</option>
                </select>
         
            }
            return <TableCell key={header.header} className={"white-space"}>
                <div style={{
                                display: 'flex',
                                alignItems: 'end',
                                whiteSpace: 'break-spaces',
                                // height:'3em',
                                // paddingLeft:'10px',
                                // paddingRight:'10px'
                            }}>
                            <div onClick={(e) =>  this.handleSort(e,header)}>
                            {header.accessor == "propertyType"|| header.accessor === 'collectionTime' || header.accessor ==='disposedTime'|| header.accessor ==='status'|| header.accessor === 'refusedTime' ||  header.accessor ==='cancelledTime' ||  header.accessor ==="expiredTime"? '' :<div>{
                            this.state.currentSortId===header.accessor? this.state.sortObj.value ==='asc'?(<ArrowDropDownIcon fontSize="small"/>):(<ArrowDropUpIcon fontSize="small"/>) :(<ArrowDropDownIcon fontSize="small"/>)}
                            </div>}
                            </div>
                        {header.header}
                        {filterType}
                        {StatusType}
                        </div>
                    </TableCell>
        }
        );
        
        
        const properties =!!propertiesHistory&&propertiesHistory.map((property, j) => (
            <TableRow  key={j}>{
                !!updatedHeaders&&updatedHeaders.map((header, i) => {
                   if (header.accessor == 'scheduledDate'||header.accessor == 'collectedDate' || header.accessor ==="disposedDate"){
                            return <TableCell  style={{
                                whiteSpace: "normal",
                                wordWrap: "break-word"
                              }} key={i}>{ !!property&&property[header.accessor] ? property[header.accessor] : '-'}</TableCell>
                        }
                    else{
                        return <TableCell style={{
                            lineHeight: '20px',
                            alignItems:'right',
                            // paddingLeft:'10px',
                            padding:'10px',
                            whiteSpace: "normal",
                            wordWrap: "break-word",
                            // paddingRight:'10px'
                            }} className={"white-space"} >{property[header.accessor] == null || property[header.accessor] == 'null' || property[header.accessor] == undefined ? '-' : property[header.accessor]}</TableCell>
                    }
                       
                    })
                }
            </TableRow>
        ));

        var schedulePropertyCountt;
        var CollectedPropertiesCountt;
        var CollectedLoadsCountt;
        var DisposedLoadsCountt;
        var ExpiredPropertiesCountt;
        var currentPropertyCountSchedule;
        var CollectedPropertiesCounttCurrent;
    var RefusedPropertiesCountt;
    var canclePropertiesCount;
    var statusCancle;
    var statusRefused;
    var statusExpire;
    var DisposedCountt;
    var sD = !!this.props.status&&this.props.status.map((item)=>{
        if (item.status === 'disposed'){
            // statusExpire= item.Status
            DisposedCountt = item.Disposed
        }

        if (item.status === 'scheduled'){
            // statusExpire= item.Status
            schedulePropertyCountt = item.Scheduled
        }

        if (item.status === 'collected'){
            // statusExpire= item.Status
            CollectedPropertiesCounttCurrent = item.Collected
        }
        if (item.status === 'expired'){
            statusExpire= item.status
            ExpiredPropertiesCountt = item.Expired
        }
        if (item.status === 'cancelled'){
            statusCancle= item.status
            canclePropertiesCount= item.Cancelled
        }
        if (item.status === 'refused'){
            statusRefused= item.status
            RefusedPropertiesCountt = item.Refused
        }
    })
        return (
            <div>
            <div className="dashboard_right_content" style={{marginTop:'-4%'}}>
                <img className="dashboard_right_content_top_icon" src="images/Rectangle.svg" />
                    <div className="dashboard_right_content_top">
                    <h5> <Link to ={{pathname:"/schedule-property/allData" , fromHis: !!this.props.location&&this.props.location.fromDateHis, toDateHis: !!this.props.location&&this.props.location.todateHis }}className="email_link"><ArrowBackIosIcon></ArrowBackIosIcon></Link>History</h5>
                        <div className="dashboard_right_content_top_options">
                            {/* <div>
                            <FormControl style={{color: '#4CAF50',width:"200px", marginLeft: '13px'}}>
                         
                         <NativeSelect
                         value={this.state.weekDate}
                         onChange={this.handleWeek}
                         input={<BootstrapInput  />}
                         style={{color: '#4CAF50', fontFamily:'Open Sans, sans-serif'}}
                         >
                        <option value={firstdayweek}>Current Week</option>
                         <option value={secondfirstdayweek}>{`${moment(secondfirstdayweek).format('Do MMM')} To ${moment(secondlastdayweek).format('Do MMM')}`}</option>
                         <option value={thirdfirstdayweek}> {`${moment(thirdfirstdayweek).format('Do MMM')} To ${moment(thirdlastdayweek).format('Do MMM')}`}</option>
                         <option value={fourthfirstdayweek}>{`${moment(fourthfirstdayweek).format('Do MMM')} To ${moment(fourthlastdayweek).format('Do MMM')}`}</option>
                        

                         </NativeSelect>
                     </FormControl>
                            </div> */}
                       
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <span style={{fontSize: 'small',fontFamily: 'Open Sans, sans-serif'}}>&nbsp;&nbsp;&nbsp;&nbsp;</span> 
                            <DatePicker
                                variant="inline"
                                value={this.state.fromDate}  
                                onChange={this.setFromDate}
                                // disableFuture
                                format='dd MMM YYY'
                                autoOk
                                style={{width:'100%', borderBottom: 'none'}}
                                InputProps={{ style:{fontSize:'small', padding:'4px' ,height:'34px',marginTop:'2px',color: '#3DAB35',border: '1px solid #3DAB35', 
                                borderRadius: '4px', fontFamily: 'Open Sans, sans-serif'},disableUnderline: true}}/>
                                <span style={{fontSize: 'small',fontFamily: 'Open Sans, sans-serif'}}>&nbsp;&nbsp;to&nbsp;&nbsp;</span> 
                            <DatePicker
                                variant="inline"
                                value={this.state.toDate} 
                                onChange={this.setToDate} 
                                // disableFuture
                                minDate={this.state.fromDate}
                                format='dd MMM YYY'
                                autoOk
                                style={{width:'100%'}}
                                InputProps={{ style:{fontSize:'small', padding:'4px' ,height:'34px',color: '#3DAB35',border: '1px solid #3DAB35', 
                                marginRight: '13px',   borderRadius: '4px', fontFamily: 'Open Sans, sans-serif'}, disableUnderline: true}}/>
                        </MuiPickersUtilsProvider> 
                      

                        <div className="dashboard_right_content_top_search">
                            <Paper component="form">
                                <IconButton aria-label="menu">
                                    <img src="images/search_icon.svg" />
                                </IconButton>
                                <InputBase onChange={(event) => this.inputChangedHadler(event, 'search')} placeholder="Property Name" />
                                <Button onClick={this.handlePropertySearch} variant="contained" color="primary" type="submit">Search</Button>
                            </Paper>
                        </div>
                        <Button style={{width:'100%'}} onClick={this.handleExport}>Export</Button>
                        <Button onClick={this.onClickRemoveFilter}>Reset</Button> 
                        </div>
                       
                        
                    </div>
                    <div style={{color:'red',fontSize:'12px', textAlign:'right' , fontFamily:'Open Sans, sans-serif'}}>* Click "Search" button to refresh the table data.</div>
                </div>


                <div> 
                
                <Grid style={{marginTop:'50px'}} container spacing={1} >
                            
                            <Grid item xs={2}>
                            <Card >
                                <hr style={{border: "1px solid #3DAB35", marginLeft:"5%", width:"30px"}}></hr>  
                                <CardHeader color="warning" icon> 
                                    <CardBody>
                                    <h2  style = {{color:"black",fontSize:"15px", textAlign:'center'}}>Schedules</h2>
                                    
                                        <h2  style ={{color: "#3dab35",fontSize: "32px",minHeight: "5px",fontWeight: "1000",marginBottom: "10px", textAlign:'center', marginTop: '22px'}}
                                    >{!!schedulePropertyCountt?schedulePropertyCountt: this.state.schedulePropertyCount}</h2>  
                                     
                                
                                     
                                    </CardBody>          
                                </CardHeader> 
                            </Card>
                            </Grid> 
                
                        <Grid item xs={2}>
                        <Card>
                            <hr style={{border: "1px solid #3DAB35", marginLeft:"5%", width:"30px"}}></hr>  
                            <CardHeader color="warning"  icon>
                                <CardBody>
                                <h2  style = {{color:"black",fontSize:"15px", textAlign:'center'}} >Collected Schedules</h2>
                               
                                <h2  style ={{color: "#3dab35",fontSize: "32px",minHeight: "5px",fontWeight: "1000",textAlign:'center', marginTop: '22px'}} >
                                        {!!CollectedPropertiesCounttCurrent?CollectedPropertiesCounttCurrent:0}</h2>
               
                                 
                                </CardBody>          
                            </CardHeader> 
                        </Card>
                        </Grid>
                
           
                        <Grid item xs={2}>
                        <Card>
                            <hr style={{border: "1px solid #3DAB35", marginLeft:"5%", width:"30px"}}></hr>  
                            <CardHeader color="warning" icon>
                                <CardBody>
                                <h2  style = {{color:"black",fontSize:"15px", marginBottom: "10px", textAlign:'center'}}>Disposed Schedules</h2>
                                    <h2 className="card-text-style"  style ={{color: "#3dab35",fontSize: "32px",minHeight: "5px",fontWeight: "1000", textAlign:'center'}} >
                                        {!!DisposedCountt?DisposedCountt:this.state.DisposedLoadsCount}</h2>
                                  
                                </CardBody>          
                            </CardHeader> 
                        </Card>
                        </Grid>
    
                    <Grid item xs={2}>
                    <Card>
                        <hr style={{border: "1px solid #3DAB35", marginLeft:"5%", width:"30px"}}></hr>  
                        <CardHeader color="warning"  icon>
                            <CardBody>
                            <h2  style = {{color:"black",fontSize:"15px", textAlign:'center'}} >Expired Schedules</h2>


                            <Link to={!!statusExpire?{pathname:'/property-summary/'+statusExpire, fromDate:this.state.fromDate, toDate:this.state.toDate , url:'historydata'}:'/property-History/allHisorty'}  style={{textDecoration:'none' ,color:'#3DAB35'}}>  
                              <h2 className="card-text-style"  style ={{color: "#3dab35",fontSize: "32px",minHeight: "5px",fontWeight: "1000",textAlign:'center'}} >
                                {!!ExpiredPropertiesCountt?ExpiredPropertiesCountt:this.state.ExpiredPropertiesCount}</h2>
                                   </Link>
                               
                             
                            </CardBody>          
                        </CardHeader> 
                    </Card>
                    </Grid> 
                    {/* <Grid item xs={1}  >
                    <Card style={{width:'145px', }}>
                        <hr style={{border: "1px solid #3DAB35", marginLeft:"5%", width:"30px"}}></hr>  
                        <CardHeader color="warning" icon>
                            <CardBody>
                            <h2  style = {{color:"black",fontSize:"15px", textAlign:'center'}}>Expired Properties</h2>
                            <Link to={!!statusExpire?{pathname:'/property-summary/'+statusExpire, fromDate:this.state.fromDate, toDate:this.state.toDate}:'/schedule-property'}  style={{textDecoration:'none' ,color:'#3DAB35'}}>   <h2 className="card-text-style"   style ={{color: "#3dab35",fontSize: "32px",minHeight: "5px",fontWeight: "1000",textAlign:'center' }}>
                                    {!!ExpiredPropertiesCountt?ExpiredPropertiesCountt:this.state.ExpiredPropertiesCount}</h2>
                               </Link>
                            </CardBody>          
                        </CardHeader> 
                    </Card>
                    </Grid> */}
        
                        <Grid item xs={2} >
                        <Card >
                            <hr style={{border: "1px solid #3DAB35", marginLeft:"5%", width:"30px"}}></hr>  
                            <CardHeader color="warning" icon>
                                <CardBody>
                                <h2  style = {{color:"black",fontSize:"15px", textAlign:'center'}} >Refused Schedules</h2>
                        
                                <Link to={!!statusRefused?{pathname:'/property-summary/'+statusRefused, fromDate:this.state.fromDate, toDate:this.state.toDate, url:'historydata'}:'/property-History/allHisorty'}    style={{textDecoration:'none' ,color:'#3DAB35'}}>  
                                <h2 className="card-text-style"   style ={{color: "#3dab35",fontSize: "32px",minHeight: "5px",fontWeight: "1000", textAlign:'center'}} >
                                    {!!RefusedPropertiesCountt?RefusedPropertiesCountt:this.state.RefusedPropertiesCount}
                                    </h2>
                                </Link>
              
                           
                                </CardBody>          
                            </CardHeader> 
                        </Card>
                        </Grid>   
                        <Grid item xs={2}>
                        <Card>
                            <hr style={{border: "1px solid #3DAB35", marginLeft:"5%", width:"30px"}}></hr>  
                            <CardHeader color="warning" icon>
                                <CardBody>
                                 <h2  style = {{color:"black",fontSize:"15px", textAlign:'center'}} >Cancelled Schedules</h2>
                                 <Link to={!!statusCancle?{pathname:'/property-summary/'+statusCancle, fromDate:this.state.fromDate, toDate:this.state.toDate, url:'historydata'}:'/property-History/allHisorty'}    style={{textDecoration:'none' ,color:'#3DAB35'}}>  
                                 <h2 className="card-text-style"   style ={{color: "#3dab35",fontSize: "32px",minHeight: "5px",fontWeight: "1000", textAlign:'center'}} >
                                    {!!canclePropertiesCount?canclePropertiesCount:this.state.cancleProperties}</h2>
                                </Link>
                  
                                </CardBody>          
                            </CardHeader> 
                        </Card>
                        </Grid>   
        
                         
            </Grid>
            
                {/* {(!this.props.loadingData && properties.length ==0) && <div id="cover-spin"></div>} */}
                <div className="dashboard_listing scrollPrent" style={{marginTop: '-20px'}}>
                    <TableContainer component={Paper} className="tale" style={{paddingLeft:"15px",paddingRight:"15px"}}>
                         <Table stickyHeader aria-label="sticky table" className="table-sc-style" style={{ tableLayout: 'fixed', width:'200%', overflowX:'auto' }}>
                          
                            <TableHead >
                                <TableRow >
                                    {headers}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {this.props.propertyListing.length== 0 ? <TableRow style={{alignItems:'center', fontWeight:'bold', color:'gray' }}>
                                            No Records Found !!</TableRow>: properties}
                                 
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
               
               
                </div>
                {
            this.props.totalCount > 0 &&
                <ReactPaginate
                previousLabel={"Prev"}
                nextLabel={"Next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount = {this.props.totalCount/this.state.limit}
                marginPagesDisplayed={2}
                forcePage = {this.state.activePage}
                pageRangeDisplayed={5}
                style={{borderLeftWidth:'0px'}}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}/>
        }
            
        </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        token: state.auth.token,
        propertyListing:!!state.propertyListing.historyList&&state.propertyListing.historyList,
        totalCount : state.propertyListing.totalCountHistorty,
        Type : state.propertyListing.ListPropertyTypes,
        status:state.propertyListing.status,
        loadingData: state.propertyListing.loadingSuccess
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchPropertyHeaders: (payload) => dispatch(actionsCreators.fetchPropertyHeaders(payload)),
        onFetchPropertyHistoryList: (payload) => dispatch(actionsCreators.historyOfProperty(payload)),
        onExportHistory :(payload) => dispatch(actionsCreators.exportHistory(payload)),
        onSearchProperty:(payload)=>dispatch(actionsCreators.searchHistoryProperty(payload)),
        onPropertyType : ()=>dispatch(actionsCreators.propertyType()),
        onFetchStatusHistory :(payload)=> dispatch(actionsCreators.fetchStatusHistory(payload))
    }
}

export default  connect(mapStateToProps, mapDispatchToProps)(PropertyHistory);
