import React from 'react';
import { connect } from 'react-redux';
import PropertyForm from './PropertyForm'

function PropertyAdd(props) {
   
   const handleSubmit=(formdata)=>{
       
   }
        return(
        <div className="create_ulb">
            <div className="dashboard_top_path">
                <p><img src="images/left-arrow.svg" alt='pro' /> Property Management Module / <span>Create Property Management</span></p>
            </div>
            <div className="dashboard_right_content">
                <img className="dashboard_right_content_top_icon" src="images/Rectangle.svg" />
                <div className="dashboard_right_content_top">
                    <h5>Create Property Management</h5>
                </div>
                <div className="create_ulb_content_outer">
                    <div className="create_ulb_content">
                        
                       <PropertyForm handleSubmit={handleSubmit}/>
                       
                    </div>
                </div>
            </div>
        </div>
        )
 }
 const mapStateToProps = (state,props) => {
    return {
    }
}
const mapDispatchToProps = dispatch => {
    return {
       
    }
}
export default  connect(mapStateToProps, mapDispatchToProps)(PropertyAdd);

