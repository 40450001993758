import * as actionTypes from '../actions/actionsTypes';
import { updateObject } from '../utility';

const initialState = {
    propertyTypesEdit: [],
    count:0,
    totalCount: 0,
    loading: false
}

const fetchPropertyTypesEditStart = (state, action) => {
    return updateObject(state, {
        loading: true,
    });
}

const fetchPropertyTypesEditSuccess = (state, action) => {
    return updateObject(state, {
        propertyTypesEdit: action.propertyTypesEdit,
        loading: false,
    });
}

const fetchPropertyTypesEditFail = (state, action) => {
    return updateObject(state, {
        loading: false,
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_PROPERTYTYPESEDIT_START: return fetchPropertyTypesEditStart(state, action);
        case actionTypes.FETCH_PROPERTYTYPESEDIT_SUCCESS: return fetchPropertyTypesEditSuccess(state, action);
        case actionTypes.FETCH_PROPERTYTYPESEDIT_FAIL: return fetchPropertyTypesEditFail(state, action);
        default: return state;
    }
}

export default reducer;