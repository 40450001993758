
import * as actionTypes from '../../actions/actionsTypes';
import { updateObject } from '../../utility';
const initialState = {
    area:[],
    loading: false,
    totalCount:0
 
}
const fetchAreaListingStart = (state, action) => {
    return updateObject(state, {
        loading: true,
    });
}

const fetchAreaSucces = (state, action) => {
    return updateObject(state, {
        area:action.areaListing,
        totalCount:action.totalCount,
        loading: false,
    });
}

const fetchAreaListingFail = (state, action) => {
    return updateObject(state, {
        loading: false,
    })
}
const AreaListingReduce = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_AREA_START: return fetchAreaListingStart(state, action);
        case actionTypes.FETCH_AREA_SUCCESS: return fetchAreaSucces(state, action);
        case actionTypes.FETCH_AREA_FAIL: return fetchAreaListingFail(state, action);
   
        default: return state;
    }
}

export default AreaListingReduce;