import React, { Component } from 'react'
import { connect } from 'react-redux'
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { Grid, MenuItem } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from '../../http-config'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import { ULBADMIN, ADMIN} from '../../../src/config/constant'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import * as actionCreators from '../../store/actions/index'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
class UserForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            username: this.props.userName ? this.props.userName : "",
            email: this.props.email ? this.props.email : "",
            role: this.props.roleId ? this.props.roleId : "",
            ulb: this.props.ULBId ? this.props.ULBId : "",
            roleOptions: [],
            ulbOptions: [],
            usernameErr: "",
            emailErr: "",
            roleErr: "",
            ulbErr: "",
            roleName: this.props.role ? this.props.role : undefined,
            dashboard: this.props.isModuleAccess ? this.props.isModuleAccess.IsDashboardAccess : false,
            iotdashboard: this.props.isModuleAccess ? this.props.isModuleAccess.IsIOTDashboardAccess : false,
            statedashboard: this.props.isModuleAccess ? this.props.isModuleAccess.IsStateDashboardAccess : false,
            ulbManagement: this.props.isModuleAccess ? this.props.isModuleAccess.IsULBManagementAccess : false,
            roleManagement: this.props.isModuleAccess ? this.props.isModuleAccess.IsRoleManagementAccess : false,
            userManagement: this.props.isModuleAccess ? this.props.isModuleAccess.IsUserManagementAccess : false,
            clusterManagement: this.props.isModuleAccess ? this.props.isModuleAccess.IsClusterULBManagementAccess : false,
            propertyManagement: this.props.isModuleAccess ? this.props.isModuleAccess.IsPropertyManagementAccess : false,
            loadsRecords: this.props.isModuleAccess ? this.props.isModuleAccess.IsLoadRecordAccess : false,
            ScheduleProperties: this.props.isModuleAccess ? this.props.isModuleAccess.IsSchedulePropertyAccess : false,
            TreatmentFacilities: this.props.isModuleAccess ? this.props.isModuleAccess.IsTreatmentFacilityAccess : false,
            OperatorManagement: this.props.isModuleAccess ? this.props.isModuleAccess.IsOperatorManagementAccess : false,
            WardManagement: this.props.isModuleAccess ? this.props.isModuleAccess.IsWardManagementAccess : false,
            checkValue:false,
            selected: [],
            zonesOptions:[]
        }
    }
    componentDidMount() {
        const roleName = localStorage.getItem("roleName")
        if(roleName == ULBADMIN){
            axios({
                method: 'get',
                url: `/role/`,
                headers: { 'Authorization': `Bearer ${this.props.token}` }
            })
                .then((response) => {
    
                    this.setState({ roleOptions:response.data.data.filter((item)=> item.roleId != 6&&item.roleId != 2&&item.roleId != 1 )})
                })
        }
        else{
            axios({
                method: 'get',
                url: `/role/?admin=${true}`,
                headers: { 'Authorization': `Bearer ${this.props.token}` }
            })
                .then((response) => {
    
                    this.setState({ roleOptions:  response.data.data.filter((item)=> {
                        if(item.name == "State Dashboard"){
                            item.name = "State/Regional Officer"
                        }
                      return  item.roleId == 6} )})
                })
        }
      
        axios({
            method: 'get',
            url: `ulb`,
            headers: { 'Authorization': `Bearer ${this.props.token}` }
        })
            .then((response) => {
                this.setState({ ulbOptions: response.data.data.ulbs })
            })
    }
    handleSubmit = (e) => {
        e.preventDefault()
        const err = this.checkValidity()
        if (!err) {
            const props = this.props
            const formdata = {
                token: this.props.token,
                userName: this.state.username,
                email: this.state.email,
                roleId: this.state.role.roleId,
                ulbId: !(this.state.roleName == "State/Regional Officer") ? this.state.ulb : null,
                isModuleAccess: {
                    "IsDashboardAccess": this.state.dashboard,
                    "IsPropertyManagementAccess":this.state.roleName == "ULB officer"?true: this.state.propertyManagement,
                    "IsSchedulePropertyAccess":this.state.roleName == "ULB officer"?true: this.state.ScheduleProperties,
                    "IsTreatmentFacilityAccess": this.state.TreatmentFacilities,
                    "IsOperatorManagementAccess": this.state.OperatorManagement,
                    "IsLoadRecordAccess": this.state.loadsRecords,
                    "IsWardManagementAccess": this.state.WardManagement,
                    "IsIOTDashboardAccess": this.state.iotdashboard,
                    "IsStateDashboardAccess": this.state.statedashboard,
                    "IsULBManagementAccess": this.state.ulbManagement,
                    "IsRoleManagementAccess": this.state.roleManagement,
                    "IsUserManagementAccess": this.state.userManagement,
                    "IsClusterULBManagementAccess": this.state.clusterManagement
                }
            }
            this.props.onAddUser(formdata, props)
            this.handleClosep()
        }
    }
    handleChange = (e) => {
        if (e.target.name == 'role') {
            this.setState({
                "roleName": e.target.value.name,
                [e.target.name]: e.target.value
            }, () => {
                this.checkValidity()
            })
        } else {
            this.setState({ [e.target.name]: e.target.value }, () => {
                this.checkValidity()
            })
        }
    }

    checkValidity = () => {
        let isError = false
        if (!this.state.username) {
            isError = true
            this.setState({ usernameErr: '*This field is required' })
        }
        else if ((this.state.username.length > 0 && this.state.username.length < 3) || (this.state.username.length > 25)) {
            isError = true
            this.setState({ usernameErr: '*Name should be Minimum 3 characters and Maximum 25 characters' })
        }
        else {
            this.setState({ usernameErr: '' })
        }
        if (this.state.email) {
            var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
            if (!this.state.email.match(mailformat)) {
                isError = true
                this.setState({ emailErr: '*You have entered invalid email address' })
            }
            else {
                this.setState({ emailErr: '' })
            }
        }
        else if (!this.state.email) {
            this.setState({ emailErr: '*This field is required' })
        }
        if (!this.state.role) {
            isError = true
            this.setState({ roleErr: "*This field is required" })
        }
        else {
            this.setState({ roleErr: '' })
        }
        if (!(this.state.roleName == "State/Regional Officer") && !this.state.ulb) {
            isError = true
            this.setState({ ulbErr: "*This field is required" })
        }
        else {
            this.setState({ ulbErr: '' })
        }
        return isError
    }
    handleChecked = (e) => {
        this.setState({ [e.target.name]: e.target.checked })
    }

handleClosep =()=>{
    this.props.handleclose(false)
    this.setState({
        username: "",
        email: "",
        role: "",
        ulb:  "",
        // roleOptions: [],
        // ulbOptions: [],
        usernameErr: "",
        emailErr: "",
        roleErr: "",
        ulbErr: "",
        roleName: undefined,
        passwordErr:'',
        password:''
 })
}

    render() {
        return (
            <div>

<Dialog
        maxWidth={'md'}
        fullWidth={true} 
        open={this.props.idEdit ?this.props.openEdit: this.props.open}
        >
         <DialogTitle className="text-center border-none " style={{color:'#3dab35'}}><span style={{fontSize:'18px'}}>{this.props.idEdit? "Edit User" :("Add User")} </span></DialogTitle>
                <DialogContent>
                <div className="create_ulb_content_outer">
                    <div className="create_ulb_content">
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <FormControl>
                                    <label className="required" htmlFor="username">User Name</label>
                                    <TextField id="username"
                                        variant="outlined"
                                        placeholder="Enter User Name"
                                        name="username"
                                        value={this.state.username} 
                                        onChange={this.handleChange} />
                                    <div style={{ fontSize: 12, color: "red", fontFamily: "'Open Sans', sans-serif", marginTop: "1%" }}>{this.state.usernameErr}</div>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl>
                                    <label className="required" htmlFor="email">Email</label>
                                    <TextField id="email"
                                        variant="outlined"
                                        placeholder="Enter Email"
                                        name="email"
                                        value={this.state.email}
                                        onChange={this.handleChange} />
                                    <div style={{ fontSize: 12, color: "red", fontFamily: "'Open Sans', sans-serif", marginTop: "1%" }}>{this.state.emailErr}</div>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} >

                                <FormControl>
                                    <label className="required" htmlFor="role">Role</label>
                                    {
                                        this.props.unAssignable ?
                                            <TextField variant="outlined"
                                                value={this.props.role}
                                                disabled={true}
                                                style={{ width: '99%' }}
                                            />
                                            :
                                            <Select value={this.state.role}
                                                onChange={this.handleChange}
                                                name="role">
                                                {this.state.roleOptions.map((role) => (
                                                    <MenuItem value={role}>{role.name}</MenuItem>
                                                ))}
                                            </Select>
                                    }

                                    <div style={{ fontSize: 12, color: "red", fontFamily: "'Open Sans', sans-serif", marginTop: "1%" }}>{this.state.roleErr}</div>
                                </FormControl>
                            </Grid>
                            {!(this.state.roleName == "State/Regional Officer") &&
                                <Grid item xs={6}>
                                    <FormControl>
                                        <label className="required" htmlFor="ulb">ULB</label>
                                        <Select value={this.state.ulb}
                                            onChange={this.handleChange}
                                            name="ulb"
                                            style={{ width: '99%' }}>
                                            {this.state.ulbOptions.map((ulb) => (
                                                <MenuItem value={ulb.ulbId}>{ulb.name}</MenuItem>
                                            ))}
                                        </Select>
                                        <div style={{ fontSize: 12, color: "red", fontFamily: "'Open Sans', sans-serif", marginTop: "1%" }}>{this.state.ulbErr}</div>
                                    </FormControl>
                                </Grid>
                            }

                            {this.state.roleName != undefined &&this.state.roleName != "ULB officer"?
                                <Grid item xs={6}>
                                    <div className="scrollPrent">


                                        <FormControl>
                                            <label className="" htmlFor="modules">Modules Selection</label>
                                            <div className="hidescroll">

                                                {this.state.roleName == 'ULB Admin' || this.state.roleName == 'ULB Moderator' ?

                                                    <>
                                                        <FormControlLabel
                                                            control={<Checkbox style={{ color: 'green', fontFamily: 'Open sans-serif', transform: 'scale(1.5)', width: 48 }} 
                                                            checked={this.state.dashboard} onChange={this.handleChecked} name="dashboard" />}
                                                            label="Dashboard"
                                                        />

                                                        <FormControlLabel
                                                            control={<Checkbox style={{ color: 'green', fontFamily: 'Open sans-serif', transform: 'scale(1.5)', width: 48 }} checked={this.state.iotdashboard} onChange={this.handleChecked} name="iotdashboard" />}
                                                            label="IOT Dashboard"
                                                        />
                                                        <FormControlLabel
                                                            control={<Checkbox style={{ color: 'green', fontFamily: 'Open sans-serif', transform: 'scale(1.5)', width: 48 }} checked={this.state.propertyManagement} onChange={this.handleChecked} name="propertyManagement" />}
                                                            label="Property Management Module"
                                                        />
                                                        <FormControlLabel
                                                            control={<Checkbox style={{ color: 'green', fontFamily: 'Open sans-serif', transform: 'scale(1.5)', width: 48 }} checked={this.state.loadsRecords} onChange={this.handleChecked} name="loadsRecords" />}
                                                            label="Loads Records"
                                                        />

                                                        <FormControlLabel
                                                            control={<Checkbox style={{ color: 'green', fontFamily: 'Open sans-serif', transform: 'scale(1.5)', width: 48 }} checked={this.state.ScheduleProperties} onChange={this.handleChecked} name="ScheduleProperties" />}
                                                            label="Schedule Properties"
                                                        />

                                                        <FormControlLabel
                                                            control={<Checkbox style={{ color: 'green', fontFamily: 'Open sans-serif', transform: 'scale(1.5)', width: 48 }} checked={this.state.TreatmentFacilities} onChange={this.handleChecked} name="TreatmentFacilities" />}
                                                            label="Treatment Facilities"
                                                        />
                                                        <FormControlLabel
                                                            control={<Checkbox style={{ color: 'green', fontFamily: 'Open sans-serif', transform: 'scale(1.5)', width: 48 }} checked={this.state.OperatorManagement} onChange={this.handleChecked} name="OperatorManagement" />}
                                                            label="Operator Management"
                                                        />
                                                        <FormControlLabel
                                                            control={<Checkbox style={{ color: 'green', fontFamily: 'Open sans-serif', transform: 'scale(1.5)', width: 48 }} checked={this.state.WardManagement} onChange={this.handleChecked} name="WardManagement" />}
                                                            label="Ward Management"
                                                        />
                                                    </>
                                                    : this.state.roleName == 'Admin' ? <>
                                                        <FormControlLabel
                                                            control={<Checkbox style={{ color: 'green', fontFamily: 'Open sans-serif', transform: 'scale(1.5)', width: 48 }} checked={this.state.stateDashboard} onChange={this.handleChecked} name="stateDashboard" />}
                                                            label="State/Regional Officer"
                                                        />


                                                        <FormControlLabel
                                                            control={<Checkbox style={{ color: 'green', fontFamily: 'Open sans-serif', transform: 'scale(1.5)', width: 48 }} checked={this.state.ulbManagement} onChange={this.handleChecked} name="ulbManagement" />}
                                                            label="ULB Management"
                                                        />

                                                        <FormControlLabel
                                                            control={<Checkbox style={{ color: 'green', fontFamily: 'Open sans-serif' , transform: 'scale(1.5)', width: 48}} checked={this.state.roleManagement} onChange={this.handleChecked} name="roleManagement" />}
                                                            label="Role Management"
                                                        />
                                                        <FormControlLabel
                                                            control={<Checkbox style={{ color: 'green', fontFamily: 'Open sans-serif', transform: 'scale(1.5)', width: 48 }} checked={this.state.userManagement} onChange={this.handleChecked} name="userManagement" />}
                                                            label="User Management"
                                                        />
                                                        <FormControlLabel
                                                            control={<Checkbox style={{ color: 'green', fontFamily: 'Open sans-serif', transform: 'scale(1.5)', width: 48 }} checked={this.state.clusterManagement} onChange={this.handleChecked} name="clusterManagement" />}
                                                            label="Cluster Management"
                                                        />
                                                    </>
                                                        : this.state.roleName == 'State/Regional Officer' ? <>
                                                            <FormControlLabel
                                                                control={<Checkbox style={{ color: 'green', fontFamily: 'Open sans-serif', transform: 'scale(1.5)', width: 48 }} checked={this.state.stateDashboard} onChange={this.handleChecked} name="stateDashboard" />}
                                                                label="State/Regional Officer"
                                                            />
                                                            <FormControlLabel
                                                                control={<Checkbox style={{ color: 'green', fontFamily: 'Open sans-serif', transform: 'scale(1.5)', width: 48 }} checked={this.state.iotdashboard} onChange={this.handleChecked} name="iotdashboard" />}
                                                                label="IOT Dashboard"
                                                            />

                                                        </>  : this.state.roleName == 'Scheduler' ? <>
                                                        <FormControlLabel
                                                            control={<Checkbox style={{ color: 'green', fontFamily: 'Open sans-serif', transform: 'scale(1.5)', width: 48 }} checked={this.state.ScheduleProperties} onChange={this.handleChecked} name="ScheduleProperties" />}
                                                            label="Schedule Properties"
                                                        />
                                                        </>: <span style={{ color: "red" }}>!!! Assigned "Role" doesn't have access to web application module(s). !!!</span>}





                                            </div>
                                        </FormControl>
                                    </div>
                                </Grid> : ""}
                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <div className="create_ulb_buttons">
                                <Button className="login_submit" variant="outlined" color="primary" onClick={this.handleClosep}>{("Cancel")}</Button>

                                    <Button className="login_submit" variant="contained" color="primary" onClick={this.handleSubmit}>Save</Button>
                                </div>
                            </Grid>
                        </Grid>

                    </div>
                </div>

                </DialogContent></Dialog>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    token: state.auth.token
})

const mapDispatchToProps = dispatch => {
    return {
        onAddUser : (payload,props) => dispatch(actionCreators.addUser(payload,props))

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserForm)