import * as actionTypes from '../actionsTypes';
import axios from '../../../http-config';
import swal from 'sweetalert';
import * as User  from '../../../config/constant';

export const fetchAreaListingStart = () => {
    return {
        type: actionTypes.FETCH_AREA_START
    }
}
export const fetchAreaSucces =(response)=>{
    return {
        type : actionTypes.FETCH_AREA_SUCCESS,
        areaListing : response.data.areas,
        totalCount:response.totalCount
    }
}

export const fetchAreaListingFail = (error) => {
    return {
        type: actionTypes.FETCH_AREA_FAIL,
        error:error
    }
}

export const AreaList = (payload)=>{
    let token = localStorage.getItem('token');
    return dispatch=>{
        dispatch(fetchAreaListingStart());
        const url = `/area/?AreaName=${payload.searchQuery}&limit=${payload.limit}&page=${payload.page}&sortColumnName=${payload.sortName}&sortColumnType=${payload.sortValue}`
        axios.get(url, {headers:{
            "Authorization":`Bearer ${token}`
        }})
        .then(response=>{
            dispatch(fetchAreaSucces(response.data))
        })
        .catch(err=>{
            dispatch(fetchAreaListingFail(err))
        })
    }
}

export const addArea = (payload,props) => {
    let token= payload.token
    let body = {
        "name":payload.name,
        "name_tn":payload.name_tn
    }
    return (dispatch) => {
        const url = '/area/addArea'
        dispatch(fetchAreaListingStart())
        axios.post(url, body, { headers : { "Authorization":`Bearer ${token}`}})
        .then((response) => {
            const fetchedResponse = response.data.success;
            fetchedResponse && swal({
                title: "Area added successfully!",
                icon: "success",
                buttons: {
                    confirm: 'Ok'
                }
            })
            dispatch(AreaList({limit:10,page:1}))
            props.history.push('/area-management')
        }, 
        (err) => {
            swal({icon : "error" , title : err.response.data.error[0]["message"]}) 
        })
        .catch((err) => {
            dispatch(fetchAreaListingFail(err))
        })
    }
}

export const editArea = (formdata,props ,payload) => {
    let token= payload.token
    const id = formdata.id
    return (dispatch) => {
    
        dispatch(fetchAreaListingStart())
        const url = `/area/${id}`
        axios.put(url, formdata, {headers:{"Authorization":`Bearer ${token}`}})
        .then((response) => {
            const fetchedResponse = response.data.success;
            fetchedResponse && swal({
                title: "You have successfully updated the data!!!",
                icon: "success",
                buttons: {
                    confirm: 'Ok'
                }
            })
            dispatch(AreaList({searchQuery:payload.searchQuery, token: token, limit:10, page:payload.page, sortName:'', sortValue:''}))
        },
        (err) => {
            swal({icon : "error" , title : err.response.data.error[0]["message"]}) 
        })
        .catch((err) => {
            // dispatch(fetchUserListingFail(err))
        })
    }
}
// Delete Function 
export const deleteArea = (payload, props)=>{
    return dispatch =>{
        const url = `/area/${payload.propertyId}`
        axios.delete(url,{ headers: {'Authorization': `Bearer ${payload.token}`}})
        .then(function (response) {
            const fetchedResponse = response.data.success;
            dispatch(AreaList(payload,'','schedule'));
            fetchedResponse && swal({
                title: "You have Successfully Deleted Area!!!",
                icon: "success",
                buttons: {
                    confirm: 'Ok'
                }
              })
              dispatch(AreaList({token: payload.token, limit:10, page:payload.page, sortName:'', sortValue:'', searchQuery:""}))
              //   props.history.push('/area-management')  
              
        }, (err) => {
            dispatch(AreaList({token: payload.token, limit:10, page:payload.page, sortName:'', sortValue:'', searchQuery:""}))
            swal({icon : "error" , title : err.response.data.error[0]["message"]})  
        }).catch(err => {
            dispatch(fetchAreaListingFail(err));
        })
    }
   
}
export const searchAreaName=(payload)=>{
    return dispatch =>{
        dispatch(fetchAreaListingStart())
        const url = `/area/?AreaName=${payload.searchQuery}&limit=${payload.limit}&page=${payload.page}&sortColumnName=${payload.sortName}&sortColumnType=${payload.sortValue}`
        axios.get(url,{headers:{
            "Authorization":`Bearer ${payload.token}`
        }} ).then(response=>{
            response && response.data && response.data.data &&
            response.data.data.properties && 
            response.data.data.properties.length == 0 && 
            swal("No Records Found !", {
                icon: "warning",
              });
              dispatch(fetchAreaSucces(response.data))
            }).catch(err=>{
                dispatch(fetchAreaListingFail(err));
            })
    }
}


export const fetchAreaListingExport =(payload)=>{
    let token = payload.token
    const url = `/area/?AreaName=${payload.searchQuery}&sortColumnName=${payload.sortName}&sortColumnType=${payload.sortValue}&export=true`
    return dispatch=>{
        dispatch(fetchAreaListingStart())
        axios.get(
            url,
            {headers:{ "Authorization":`Bearer ${token}`
        }}).then(function (response) {
            let link1=response.data.data[0].file_path
            
            window.downloadFile = function (link) {
                window.open(link, '_blank');
            }
            window.downloadFile(link1)
          
        }).catch(error => {
        })
    }
 }