import React, { Component, useEffect } from 'react'
import { connect } from 'react-redux'
import ZoneForm from './ZoneForm'
import * as actionCreators from '../../store/actions/index'

const ZoneAdd = (props) => {
    const handleSubmit = (payload) => {
        props.onAddZone(payload,props)
    }
    return (
        <div>
            <div className="create_ulb">
                <div className="dashboard_top_path">
                    <p><img src="images/left-arrow.svg" /> Zone Management / <span>Create New Zone</span></p>
                </div>
                <div className="dashboard_right_content">
                    <img className="dashboard_right_content_top_icon" src="images/Rectangle.svg" />
                    <div className="dashboard_right_content_top">
                        <h5>Create New Zone</h5>
                    </div>
                    <div className="create_ulb_content_outer">
                        <div className="create_ulb_content">
                            <ZoneForm handleSubmit={handleSubmit}/>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    )
    
}

const mapStateToProps = (state) => ({
    token : state.auth.token
})

const mapDispatchToProps = (dispatch) => {
    return({
        onAddZone : (payload,props) => dispatch(actionCreators.addZone(payload,props))
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(ZoneAdd)